import React from 'react';
import PropTypes from 'prop-types'
import './style.scss';
import NoDataImg from 'assets/images/no-items-found.svg';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import ButtonCustom from '../button';
import { useHistory } from 'react-router-dom/cjs/react-router-dom';

const NoData = (props) => {
  const { loading = false, data, mainText, subText, buttonText, onClick, onlyMain = false } = props;
  const { t } = useTranslation();
  const history = useHistory()

  if (loading) {
    return null;
  }

  if (_.isArray(data) && data.length > 0) {
    return null;
  }

  const defaultOnClick = () => {
    history.push('/explore/all')
  }

  return (
    <div className='no-data-container'>
      <img className='no-data-container__img' src={NoDataImg} alt="no-data" />
      <div className='no-data-container__main-text'>{mainText || t('common.noResultFound')}</div>
      {
        !onlyMain &&
        <>
          <div className='no-data-container__sub-text'>{subText || t('common.noItemContent')}</div>
          <ButtonCustom color='blue' onClick={onClick ?? defaultOnClick}>
            {buttonText || t('activity.explore')}
          </ButtonCustom>
        </>
      }
    </div>
  )
};

NoData.propTypes = {
  loading: PropTypes.bool,
  data: PropTypes.any,
  mainText: PropTypes.string,
  subText: PropTypes.string,
  buttonText: PropTypes.string,
  onClick: PropTypes.func,
};

export default NoData;
