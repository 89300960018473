import React from 'react'
import { Modal } from 'antd'

import './style.scss'
import { useSelector, useDispatch } from 'react-redux'
import { get } from 'lodash'
import { useAccount, useChainId } from 'wagmi'

import { signWallet } from 'blockchain/utils'

import { loginApi } from 'store/login.slice'

import { SvgIcon } from 'components/modules'
import { loginWallet } from 'store/actions/user'
import { signWithCreate, toggleBtnCreate } from 'store/login.slice'
import { openRejectSignModal, closeRejectSignModal, closeRejectConnectModal } from 'store/modal.slice'
import { ButtonCustom } from '..'
import { useTranslation } from 'react-i18next'

const RejectSignModal = props => {
    const { t } = useTranslation();
    const modal = useSelector(state => state.modal)
    const { isClickBtnCreate } = useSelector(state => state.login)
    const openModalRejectSign = get(modal, 'openModalRejectSign')
    const openModalRejectConnect = get(modal, 'openModalRejectConnect')
    const { address } = useAccount();
    const chainId = useChainId();

    const dispatch = useDispatch()

    const onCancel = () => {
        dispatch(closeRejectSignModal())
        dispatch(closeRejectConnectModal())
        if (isClickBtnCreate) {
            dispatch(toggleBtnCreate());
        }
    }
    const handleTryAgain = () => {
        if (openModalRejectSign) {
            handleSignIn()
        } else {
            dispatch(loginWallet({ account: address.toLowerCase(), chainId }));
            dispatch(closeRejectConnectModal())
        }
    }
    const handleSignIn = async () => {
        try {
            const signature = await signWallet()
            const credentials = {
                walletAddress: address,
                signature
            }
            dispatch(closeRejectSignModal())

            await dispatch(loginApi(credentials))

            if (isClickBtnCreate) {
                dispatch(signWithCreate());
            }
        } catch (err) {
            dispatch(openRejectSignModal())
        }
    }

    return (
        <Modal
            className="reject-sign-modal"
            centered
            footer={null}
            open={openModalRejectSign || openModalRejectConnect}
        >
            <div className="modal-wrapper">
                <div>
                    <SvgIcon name="wrong-network-icon" />
                </div>
                <span className="title">{t('error.error')}</span>
                <span className="description" dangerouslySetInnerHTML={{ __html: t('error.rejectSign.description') }}>
                </span>

                <div className="btn-sign-wallet">
                    <ButtonCustom onClick={handleTryAgain} color="blue">
                        <span>{t('common.tryAgain')}</span>
                    </ButtonCustom>
                    <ButtonCustom onClick={onCancel} color="white" className="btn-disconnect">
                        <span>{t('common.cancel')}</span>
                    </ButtonCustom>
                </div>
            </div>
        </Modal>
    )
}

export default RejectSignModal
