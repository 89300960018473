import React, { useEffect, useState } from 'react'
import { Card, Avatar, Tooltip } from 'antd'
import { useHistory } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'

import { convertImageAvatar } from 'utils/image'
import { roundNumber } from 'utils/func'
import { convertTimeToDateTime } from 'utils/convertDate'
import { FILE_EXTENSION } from 'constants/index'
import IconBids from 'assets/icons/activity-bids-icon-color.svg'
import PlayGroupIcon from 'assets/icons/play-group-icon.svg'
import Mp3Icon from 'assets/icons/music-group-icon.svg'
import nftService from 'service/nftService'
import LoadingIcon from 'assets/icons/loading-icon.svg'
import CustomVerifyIcon from 'components/common/custom-verify-icon'
import AcceptOfferModalOpenForBid from 'pages/nft-detail/elements/Tabs/OffersForOpening/AcceptOfferModal'
import AcceptProcessOpenForBidModal from 'pages/nft-detail/elements/Tabs/OffersForOpening/AcceptProcessModal'
import { isUserAuthenticated } from 'utils/auth'
import { useAuth } from 'hooks/useAuth'
import { getPrice } from 'service/getPrice'
import { getNFTDetail } from 'store/actions/nft'
import { ButtonCustom } from 'components/common'
import { exportFileFormat } from 'utils/file'
import { useTranslation } from 'react-i18next'

const BidsActivity = props => {
    const { t } = useTranslation()
    const history = useHistory()
    const dispatch = useDispatch()
    const [, handleSignWallet] = useAuth()
    const { user, saleNft, displayPreviewImage, createdAt, walletAddress, isAcceptOffer, user2 } = props
    const nft = saleNft?.nft
    const { profile } = useSelector(state => state.user)
    const [imageUrlMetadata, setImageUrlMetadata] = useState(null)
    const [isDoneFetchImageUrlMetadata, setIsDoneFetchImageUrlMetadata] = useState(false)
    const [isOpenAcceptOffer, setIsOpenAcceptOffer] = useState(false)
    const [isOpenProcessModal, setIsOpenProcessModal] = useState(false)
    const [isDoneProcessing, setIsDoneProcessing] = useState(false)
    const [transferData, setTransferData] = useState({})
    const [exchangeRate, setExchangeRate] = useState(0)

    const handleClickUser = (e, address) => {
        e.stopPropagation()
        if (address === walletAddress) {
            e.preventDefault()
        }
    }

    useEffect(() => {
        const getRate = async () => {
            const rate = await getPrice(nft?.tokenType?.toUpperCase())
            setExchangeRate(rate)
        }

        getRate()
    }, [nft?.tokenType])

    useEffect(() => {
        const fetchImageUrlFromMetadata = async nftData => {
            let fullMetaDataUrl = `${nftData?.metadataUrl}/${nftData?.tokenId}`
            const [result] = await nftService.getImageUrlFromMetadata(fullMetaDataUrl)
            setIsDoneFetchImageUrlMetadata(true)

            if (result) {
                setImageUrlMetadata(result)
            }
        }

        if (nft?.metadataUrl) {
            fetchImageUrlFromMetadata(nft)
        }
    }, [])

    const DisplayImage = ({ nftData, displayPreviewImageSale }) => {
        if (!nftData?.metadataUrl) {
            return <img src={displayPreviewImageSale} alt="" />
        }
        if (nftData?.metadataUrl && !isDoneFetchImageUrlMetadata) {
            return <img src={LoadingIcon} alt="LoadingIcon" className="custom-loading-icon" />
        } else if (imageUrlMetadata && isDoneFetchImageUrlMetadata) {
            return <img src={imageUrlMetadata} alt="" />
        }
        return <img src={displayPreviewImageSale} alt="" />
    }

    const routerCollection = () => {
        history.push(`/nft/${nft?.collection?.id || nft?.collection?.address}:${nft?.tokenId}`)
    }

    const handleOpenAcceptOffer = async e => {
        e.stopPropagation()
        dispatch(
            getNFTDetail({ collectionAddress: nft?.collection?.id || nft?.collection?.address, tokenId: nft?.tokenId })
        )
        if (!isUserAuthenticated()) {
            const res = await handleSignWallet()
            if (res === false) return
        }
        setIsOpenAcceptOffer(true)
    }

    const onOpenProcessModal = (hash, collectionAddress, bidId) => {
        setIsOpenAcceptOffer(false)
        setTransferData({ hash, collectionAddress, bidId })
        setIsOpenProcessModal(true)
    }

    return (
        <>
            <div onClick={routerCollection} className="cursor-pointer" style={{ height: '100%' }}>
                <Card className="card-activity-custom">
                    <div className="card-activity-custom_item-image">
                        {/* <img src={displayPreviewImage} alt="" /> */}
                        <DisplayImage nftData={nft} displayPreviewImageSale={displayPreviewImage} />
                        {exportFileFormat(nft?.nftUrl) === FILE_EXTENSION.MP4 && (
                            <div className="card-activity-custom_icon-file-extension">
                                <img src={PlayGroupIcon} alt="PlayGroupIcon" />
                            </div>
                        )}
                        {exportFileFormat(nft?.nftUrl) === FILE_EXTENSION.MPEG && (
                            <div className="card-activity-custom_icon-file-extension">
                                <img src={Mp3Icon} alt="Mp3Icon" />
                            </div>
                        )}
                    </div>
                    <div className="card-activity-custom-content">
                        <div className="item-name">
                            <Tooltip overlayClassName="blue" placement="topLeft" title={nft?.title}>
                                <span>{nft?.title}</span>
                            </Tooltip>
                        </div>
                        <div className="card-activity-custom-content_type">
                            <img className="type-icon" src={IconBids} alt="" />
                            <span>{t('nftDetail.bids')}</span>
                        </div>
                        <div className="card-activity-custom-content_user">
                            <div className="card-activity-custom-content_user-wrapper">
                                <div className="d-flex">
                                    <span
                                        onClick={e => {
                                            history.push(
                                                user?.shortUrl ? `/${user?.shortUrl}` : `/user/${user?.walletAddress}`
                                            )
                                            handleClickUser(e, user?.walletAddress)
                                        }}
                                        className="cursor-pointer"
                                    >
                                        <div className="avatar">
                                            <Avatar alt="example" src={convertImageAvatar(user?.avatarImg)} />
                                            {user?.verification ? <CustomVerifyIcon className="icon" /> : null}
                                        </div>
                                    </span>
                                    <Tooltip
                                        overlayClassName="blue"
                                        placement="topLeft"
                                        title={user?.username || user?.walletAddress}
                                    >
                                        <span
                                            onClick={e => {
                                                history.push(
                                                    user?.shortUrl
                                                        ? `/${user?.shortUrl}`
                                                        : `/user/${user?.walletAddress}`
                                                )
                                                handleClickUser(e, user?.walletAddress)
                                            }}
                                            className="cursor-pointer"
                                        >
                                            <div className="user-name">
                                                <span>
                                                    {profile?.walletAddress &&
                                                    user?.walletAddress?.toLowerCase() === profile?.walletAddress?.toLowerCase()
                                                        ? 'You'
                                                        : user?.username !== null
                                                            ? user?.username
                                                            : user?.walletAddress}
                                                </span>
                                            </div>
                                        </span>
                                    </Tooltip>
                                </div>
                                <div className="d-flex">
                                    <span className="gray-text">{t('common.offered')}</span>
                                    <span className="price">
                                        {roundNumber(saleNft?.price) || 0} {saleNft?.currencyToken?.toUpperCase()}
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div className="card-activity-custom-content_date">
                            <span>{convertTimeToDateTime(createdAt)}</span>
                            {isAcceptOffer &&
                                profile?.walletAddress &&
                                user2?.walletAddress?.toLowerCase() === profile?.walletAddress?.toLowerCase() && (
                                    <ButtonCustom size="small" color="blue" onClick={handleOpenAcceptOffer}>
                                        {t('common.accept')}
                                    </ButtonCustom>
                                )}
                        </div>
                    </div>
                </Card>
            </div>
            {isOpenAcceptOffer && (
                <AcceptOfferModalOpenForBid
                    isOpen={isOpenAcceptOffer}
                    offerData={saleNft}
                    onCloseModal={() => setIsOpenAcceptOffer(false)}
                    onOpenProcessModal={onOpenProcessModal}
                    onCloseProcessModal={() => setIsOpenProcessModal(false)}
                />
            )}

            {isOpenProcessModal && (
                <AcceptProcessOpenForBidModal
                    isOpen={isOpenProcessModal}
                    hashTransaction={transferData?.hash}
                    collectionAddress={transferData?.collectionAddress}
                    bidId={transferData?.bidId}
                    onCloseModal={() => setIsOpenProcessModal(false)}
                    isDone={isDoneProcessing}
                />
            )}
        </>
    )
}

export default BidsActivity
