import {createSlice} from '@reduxjs/toolkit';
import { CATEGORIES_ICONS_MAPPING } from 'constants/';

const initialState = {
  data: [],
  error: null
};

export const categorySlice = createSlice({
  name: 'categories',
  initialState,
  reducers: {
    setCategories: (state, data) => {
      const categoriesMapping = data.payload.map(category => ({
        ...CATEGORIES_ICONS_MAPPING[category.name],
        ...category,
        value: category.id,
        label: category.name
      }));

      state.data = categoriesMapping;
    },
    setErrorCategories: (state, data) => {
      state.error = data.payload;
    },
  }
});

export const { setCategories, setErrorCategories } = categorySlice.actions;

export default categorySlice.reducer;
