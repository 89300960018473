import React, { useCallback, useContext, useEffect, useRef, useState } from 'react'
import { Dropdown, Form, Modal } from 'antd'
import { Label } from 'components/modules'
import { ButtonCustom, CustomAvatar, InputCustom, SelectCustom } from 'components/common'
import './styles.scss'
import nftService from 'service/nftService'
import * as message from 'utils/custom-message'
import { convertImageAvatar } from 'utils/image'
import { useOnClickOutside } from 'utils/hook'
import ArrowDown from 'assets/icons/arrow-down.svg'
import collectionService from 'service/collectionService'
import { ThemeContext } from 'ThemeProvider'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { isUserAuthenticated } from 'utils/auth'
import { useAuth } from 'hooks/useAuth'
import { useTranslation } from 'react-i18next'

const ReportModal = ({ visible, onCancel, nftId, collectionId }) => {
    const { t } = useTranslation();
    const { theme } = useContext(ThemeContext)
    const [form] = Form.useForm()
    const collectionDropdown = useRef(null)
    const buttonCollectionRef = useRef(null)
    const [reason, setReason] = useState('')
    const [isCollectionDropdown, setIsCollectionDropdown] = useState(false)
    const [listCollection, setListCollection] = useState([])
    const [collectionParam, setCollectionParam] = useState('')
    const [searchedCollection, setSearchedCollection] = useState([])
    const [listSelectedCollection, setListSelectedCollection] = useState('')
    const {
        profile: { walletAddress }
    } = useSelector(state => state.user) || {}
    const history = useHistory()
    const [, handleSignWallet] = useAuth()

    const [isSubmitting, setIsSubmitting] = useState(false)

    const handleCollectionClickOutsideDropdown = () => {
        setIsCollectionDropdown(false)
    }

    useOnClickOutside(collectionDropdown, handleCollectionClickOutsideDropdown, buttonCollectionRef)

    const option = [
        {
            id: 1,
            label: t('report.option.fakeOrScam'),
            value: 'fake_collection_or_possible_scam'
        },
        {
            id: 2,
            label: t('report.option.explicitContent'),
            value: 'explicit_and_sensitive_content'
        },
        {
            id: 3,
            label: t('report.option.spam'),
            value: 'spam'
        },
        {
            id: 4,
            label: t('report.option.mightBeStolen'),
            value: 'might_be_stolen'
        }
    ]

    const handleReportNft = async values => {
        setIsSubmitting(true)
        if (!walletAddress) {
            history.push('/connect')
        } else {
            if (!isUserAuthenticated()) {
                await handleSignWallet()
            }
            const [res, err] = await nftService.reportNft({
                nftId: nftId,
                ...(values.reason === option[0]?.value && { originalCollectionId: listSelectedCollection?.id }),
                reason: values.reason
            })
            if (res) {
                message.success(t('success.message.reportSuccessfully'))
                onCancel()
            }
            if (err) {
                message.error(t('error.message.failedToReport'))
            }
            setIsSubmitting(false)
        }
    }

    const handleSearchCollection = e => {
        const value = e.target.value
        setCollectionParam(value.toLowerCase())
        if (value.length > 0) {
            setSearchedCollection(listCollection.filter(c => c.name.toLowerCase().includes(value.toLowerCase())))
        } else {
            setSearchedCollection(listCollection)
        }
    }

    const handleClickCollectionFilter = useCallback(collection => {
        setListSelectedCollection(collection)
        setCollectionParam(collection?.name)
        setIsCollectionDropdown(false)
    }, [])

    const fetchCollection = useCallback(async () => {
        const [res, err] = await collectionService.getAllMiniCollection({
            limit: 0,
            userId: ''
        })
        if (res) {
            setListCollection(res.items)
            setSearchedCollection(res.items)
        }
        if (err) {
        }
    }, [])

    useEffect(() => {
        fetchCollection()
    }, [fetchCollection])


    const dropdownCollection = useCallback(() => {
        return (
            <div
                className={`dropdown-collection dropdown-selected-collection dropdown-explorer  ${theme === 'dark-theme' ? 'dropdown-explore-dark-antd' : ''
                    }`}
                ref={collectionDropdown}
            >
                <div className="collection-holder">
                    {searchedCollection?.map((collection, index) => {
                        return (
                            <div
                                className="collection-item-holder"
                                onClick={() => handleClickCollectionFilter(collection)}
                                key={index}
                            >
                                <div className="collection-name">
                                    <CustomAvatar
                                        src={convertImageAvatar(collection.thumbnailUrl)}
                                        isDefaultCollection={collection.thumbnailUrl ? false : true}
                                    />
                                    <div className="collection-name-text-holder original-collection-name">
                                        <span className="collection-name-text">{collection.name}</span>
                                        {collection?.id === listSelectedCollection?.id && <i className="ri-check-line"></i>}
                                    </div>
                                </div>
                            </div>
                        )
                    })}
                </div>
            </div>
        )
    }, [handleClickCollectionFilter, searchedCollection, theme, listSelectedCollection])

    return (
        <Modal
            visible={visible}
            onCancel={onCancel}
            footer={null}
            className={`report-modal`}
            bodyStyle={{
                backgroundColor: theme === 'dark-theme' && '#292828',
                borderRadius: 20
            }}
            closeIcon={<i className="ri-close-line" style={{ color: `${theme === 'dark-theme' ? 'white' : 'black'}` }}></i>}
        >
            <Form className="report-form" form={form} onFinish={handleReportNft}>
                <Form.Item>
                    <h3 className={`title ${theme === 'dark-theme' && 'report-text-dark'}`}>{t('report.title')}</h3>
                    <span className={`desc ${theme === 'dark-theme' && 'report-text-dark'}`}>
                        {t('report.desc')}
                    </span>
                </Form.Item>
                <Form.Item name="reason">
                    <SelectCustom
                        placeholder={t('report.placeholder')}
                        value={reason}
                        options={option}
                        onChange={e => setReason(e)}
                        className="reason-select"
                        getPopupContainer={triggerNode => triggerNode.parentNode}
                    />
                </Form.Item>
                {reason === option[0]?.value ? (
                    <Label
                        title={'Original collection'}
                        className={`${theme === 'dark-theme' && 'label-custom_wrapper-report'}`}
                    >
                        <Dropdown overlay={dropdownCollection} visible={isCollectionDropdown}>
                            <div
                                innerRef={buttonCollectionRef}
                                onClick={() => setIsCollectionDropdown(!isCollectionDropdown)}
                                className="collection-search-input-holder"
                            >
                                {listSelectedCollection && collectionParam && (
                                    <CustomAvatar
                                        className="avatar-collection-selected"
                                        src={convertImageAvatar(listSelectedCollection.thumbnailUrl)}
                                        isDefaultCollection={listSelectedCollection.thumbnailUrl ? false : true}
                                    />
                                )}
                                <InputCustom
                                    className={
                                        listSelectedCollection && collectionParam ? 'input-selected-collection' : ''
                                    }
                                    suffix={<img src={ArrowDown} alt="ArrowDown" />}
                                    value={collectionParam}
                                    placeholder="Select collection"
                                    onChange={handleSearchCollection}
                                />
                            </div>
                        </Dropdown>
                    </Label>
                ) : null}
                <ButtonCustom disabled={isSubmitting} fullWidth={true} type="submit" color="blue" className="btn-submit" htmlType="submit">
                    {t('common.submit')}
                </ButtonCustom>
                <ButtonCustom color='gray' fullWidth={true} onClick={onCancel}>
                    {t('common.cancel')}
                </ButtonCustom>
            </Form>
        </Modal>
    )
}

export default ReportModal
