import { Tooltip, Avatar } from 'antd'
import { CustomAvatar } from 'components/common'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import React, { useContext } from 'react'
import { useWindowSize } from 'react-use'
import { Link } from 'react-router-dom'

import { NETWORK_ICON } from 'constants/index'
import { shortenAddress, shortenNameNoti } from 'utils/func'
import { ThemeContext } from '../../../../../ThemeProvider'
import './style.scss'

const Owner = () => {
    const { theme } = useContext(ThemeContext)
    const { t } = useTranslation()
    const { collection, owners, creator } = useSelector(state => state.collectible?.data)
    const { width } = useWindowSize()

    const left = e => {
        if (width > 768) {
            return `${e * 20}px`
        } else {
            return `${e * 18}px`
        }
    }

    return (
        <>
            <div className={`group-avatar owner ${theme === 'dark-theme' ? 'dark-group-avatar' : ''}`}>
                <div>
                    <Tooltip title={collection?.networkType.toUpperCase()} overlayClassName="custom-tooltip">
                        <div className="custom-tolltip-inner">
                            <Avatar
                                className="blockchain-icon"
                                src={NETWORK_ICON[collection.networkType]}
                                size={width > 768 ? 40 : 32}
                            />
                            <div className="collection owner-feild-inner">
                                <p className="collection-name name">{collection.networkType.toUpperCase()}</p>
                                <p className="text">Blockchain</p>
                            </div>
                        </div>
                    </Tooltip>
                </div>
                <Link
                    to={`/collection/${collection?.shortUrl || collection?.id || collection?.address}`}
                    className="owner-feild"
                >
                    <Tooltip title={collection?.name} overlayClassName="custom-tooltip">
                        <div className="custom-tolltip-inner">
                            <CustomAvatar
                                verified={!!collection.isVerify}
                                src={collection?.bannerUrl}
                                size={width > 768 ? 40 : 32}
                            />
                            <div className="collection owner-feild-inner">
                                <p className="collection-name name">{shortenNameNoti(collection?.name)}</p>
                                <p className="text">{t('nftDetail.collection')}</p>
                            </div>
                        </div>
                    </Tooltip>
                </Link>

                <Link
                    className="owner-feild"
                    to={creator?.shortUrl ? `/${creator?.shortUrl}` : `/user/${creator?.walletAddress}`}
                >
                    <Tooltip
                        title={creator?.name ? creator?.name : creator?.walletAddress}
                        overlayClassName="custom-tooltip"
                    >
                        <div className="custom-tolltip-inner">
                            <CustomAvatar
                                src={creator?.avatarImg}
                                verified={!!creator?.isVerify}
                                alt="creator"
                                size={width > 768 ? 40 : 32}
                            />
                            <div className="creator owner-feild-inner">
                                <p className="creator-name name">
                                    {creator?.username
                                        ? shortenNameNoti(creator.username, 5)
                                        : shortenAddress(creator?.walletAddress, 5, 3)}
                                </p>
                                <p className="text">{t('nftDetail.creator')}</p>
                            </div>
                        </div>
                    </Tooltip>
                </Link>

                <div className="list-owner owner-feild">
                    {owners?.length > 0 &&
                        owners.slice(0, 1).map((item, index) => (
                            <Link
                                style={{ left: left(index), zIndex: index + 1 }}
                                className="owner-item"
                                key={index}
                                to={
                                    item.user?.shortUrl
                                        ? `/${item.user?.shortUrl}`
                                        : `/user/${item.user?.walletAddress}`
                                }
                            >
                                <Tooltip
                                    title={item.user?.name ? item.user?.name : item.user?.walletAddress}
                                    overlayClassName="custom-tooltip "
                                >
                                    <div className="custom-tolltip-inner">
                                        <CustomAvatar
                                            src={item.user?.avatarImg}
                                            verified={!!item.user?.isVerify}
                                            alt="creator"
                                            size={width > 768 ? 40 : 32}
                                        />
                                        <div className="creator owner-feild-inner">
                                            <p className="creator-name name">
                                                {item.user?.username
                                                    ? shortenNameNoti(item.user?.username, 5)
                                                    : shortenAddress(item.user?.walletAddress, 3, 2)}
                                            </p>
                                            <p className="text">{t("nftDetail.owner")}</p>
                                        </div>
                                    </div>
                                </Tooltip>
                            </Link>
                        ))}
                    <div>
                        {owners?.length > 1 && (
                            <Avatar.Group
                                maxCount={1}
                                maxStyle={{
                                    color: '#f56a00',
                                    backgroundColor: '#fde3cf',
                                    cursor: 'pointer',
                                    position: 'absolute',
                                    right: '0px',
                                    top: '4px'
                                }}
                                className="avatar-group-owner"
                            >
                                {owners.map((ownerGroup, index) => (
                                    <Link
                                        style={{ left: left(index), zIndex: index + 1 }}
                                        className="owner-item"
                                        key={index}
                                        to={
                                            ownerGroup.user?.shortUrl
                                                ? `/${ownerGroup.user?.shortUrl}`
                                                : `/user/${ownerGroup.user?.walletAddress}`
                                        }
                                    >
                                        <Tooltip
                                            title={
                                                <>
                                                    <span className="tooltip-customer-title__role">{t("nftDetail.owner")}:&nbsp;</span>
                                                    <span>
                                                        {ownerGroup?.user?.name
                                                            ? ownerGroup?.user?.name
                                                            : ownerGroup?.user?.walletAddress}
                                                    </span>
                                                </>
                                            }
                                            overlayInnerStyle={{
                                                textOverFlow: 'pre-line',
                                                overFlow: 'hidden',
                                                width: '100%'
                                            }}
                                        >
                                            <CustomAvatar
                                                src={ownerGroup.user?.avatarImg}
                                                verified={!!ownerGroup.user?.isVerify}
                                                alt="creator"
                                                size={width > 768 ? 40 : 32}
                                            />
                                        </Tooltip>
                                    </Link>
                                ))}
                            </Avatar.Group>
                        )}
                    </div>
                </div>
            </div>
        </>
    )
}

export default Owner
