import React, {
  useEffect,
  useState,
  useMemo,
  useCallback,
  useRef,
  useContext,
} from "react";
import { Menu, Divider } from "antd";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
// import { useIntersection } from "react-use";
// import ReactLoading from "react-loading";
import { Helmet } from "react-helmet";
import { useHistory, useParams } from "react-router-dom";
// import { useResizeWindow } from "utils/hook";
// import * as message from "utils/custom-message";
import {
  MAIN_TITLE,
  NETWORK_OPTIONS,
  // SCREEN_SIZE,
  // SORT_BY,
} from "constants/index";
// import nftService from "service/nftService";
// import { CardItem } from "components/modules";
import DefaultLayout from "components/layout/default-layout";
import {
  ButtonCustom,
  // CustomAvatar,
  InputCustom,
  // NoData,
  // RadioGroupCustom,
  SelectCustom,
} from "components/common";
import styles from "./style.module.scss";
import "./style.scss";
import HotCollections from "pages/homepage/hot-collections";
// import collectionService from "service/collectionService";
// import { convertImageAvatar } from "utils/image";
// import CustomSwitch from "components/common/switch";
import PolygonIcon from "assets/icons/polygon-icon.svg";
import FourSquaresIcon from "assets/icons/4squares-icon.svg";
// import LightingIcon from "assets/icons/lighting-icon.svg";
// import FilterIcon from "assets/icons/filter-icon.svg";
import SearchIcon from "assets/icons/search-icon-gray.svg";
import { ThemeContext } from "../../ThemeProvider";

// const STEP = 12;
const MAX_VALUE = 100000000000000;
// const optionFilter = [
//   { label: "Verified Only", key: "verification" },
//   { label: "Show NSFW", key: "nsfw" },
//   { label: "Show lazy minted", key: "lazyMinted" },
// ];

const SALES_TYPE = [
  {
    value: '',
    label: "All sale types",
  },
  {
    label: "Timed auction",
    value: "auction",
  },
  {
    label: "Fixed price",
    value: "fix_price",
  },
  {
    label: "Not for sale",
    value: "not_for_sale",
  },
  {
    label: "Open for offer",
    value: "open_for_offer",
  },
];

// const NFTCollection = ({
//   loading,
//   data,
//   handeLoadMore,
//   total,
//   nftsLikes,
//   isTablet,
// }) => {
//   const { t } = useTranslation();
//   const isLoadMore = useMemo(() => {
//     return data?.length < total;
//   }, [data?.length, total]);
//   const system = useSelector((state) => state?.system);
//   const [autoFetch, setAutoFetch] = useState(isTablet);

//   const anchorEl = useRef(null);
//   const intersection = useIntersection(anchorEl, {
//     root: null,
//     rootMargin: "0px",
//     threshold: 1,
//   });
//   const inView =
//     intersection &&
//     intersection.isIntersecting &&
//     intersection.intersectionRatio >= 1;
//   const infinity = autoFetch && inView && isLoadMore;

//   useEffect(() => {
//     if (infinity) {
//       handeLoadMore();
//     }
//     // eslint-disable-next-line react-hooks/exhaustive-deps
//   }, [infinity]);

//   if (!loading && data && data?.length === 0) {
//     return <NoData loading={loading} data={data} />;
//   }

//   return (
//     <div className={styles.exploreLists}>
//       <Row
//         className={styles.exploreRow}
//         gutter={[{ xs: 6, sm: 6, md: 20, lg: 20, xl: 20 }, 20]}
//       >
//         {data?.map((d, index) => (
//           <Col
//             xs={{ span: 24 }}
//             md={{ span: 12 }}
//             lg={{ span: 8 }}
//             xl={{ span: 8 }}
//             key={index}
//           >
//             <CardItem nftsLikes={nftsLikes} data={d} />
//           </Col>
//         ))}
//         <div ref={anchorEl}></div>
//       </Row>

//       {!loading && isLoadMore && !autoFetch && (
//         <ButtonCustom
//           className={styles.exploreListsBtn + " br-12"}
//           color="blue"
//           onClick={async () => {
//             if (autoFetch) return;
//             await handeLoadMore();
//             setAutoFetch(true);
//           }}
//         >
//           {t("common.loadingMore")}
//         </ButtonCustom>
//       )}
//       {loading && !system?.loading && (
//         <ReactLoading
//           className={styles.exploreListsBtn}
//           type="spinningBubbles"
//           color="#002979"
//         />
//       )}
//     </div>
//   );
// };

const Explore = () => {
  const { t, i18n } = useTranslation();
  const { theme } = useContext(ThemeContext);
  const params = useParams();
  const history = useHistory();
  const categories = useSelector((state) => state.categories?.data || []);
  const [page, setPage] = useState(1);
  const [categoryId, setCategoryId] = useState(null);
  const pricerangeDropdown = useRef(null);
  const collectionDropdown = useRef(null);
  const chainDropdown = useRef(null);
  const [priceFrom, setPriceFrom] = useState(null);
  const [priceTo, setPriceTo] = useState(null);
  const [listSelectedCollection, setListSelectedCollection] = useState([]);
  const [listSelectedChain, setListSelectedChain] = useState([]);
  const [networkType, setNetworkType] = useState("");
  const [collectionSearchInput, setCollectionSearchInput] = useState("");
  const [collectionSearch, setCollectionSearch] = useState("");
  const [saleTypes, setSaleTypes] = useState('')
  // const [loading, setLoading] = useState(false);
  // const [isTablet] = useResizeWindow(SCREEN_SIZE.tablet);
  // const { profile } = useSelector((state) => state.user);
  // const [itemUserLikes, setItemUserLike] = useState([]);
  // const [limit] = useState(STEP);
  // const [sortBy, setSortBy] = useState(SORT_BY[0].value);
  // const [nfts, setNfts] = useState([]);
  // const [total, setTotal] = useState(0);
  // const propertyDropdown = useRef(null);
  // const [listProperties, setListProperties] = useState([]);
  // const [listCollection, setListCollection] = useState([]);
  // const [searchedCollection, setSearchedCollection] = useState([]);
  // const [searchedProps, setSearchedProps] = useState([]);
  // const [propertyParam, setPropertyParam] = useState("");
  // const [collections, setCollections] = useState(null);
  // const [listSelectedProps, setListSelectedProps] = useState([]);
  // const [filterParams, setFilterParams] = useState({
  //   saleType: undefined,
  // });
  // const [filterByOption, setFilterByOption] = useState({
  //   verification: true,
  //   nsfw: false,
  //   lazyMinted: false,
  // });

  // const handeLoadMore = useCallback(() => {
  //   if (loading) {
  //     return;
  //   }
  //   const next = page * STEP;
  //   next < total && setPage(page + 1);
  // }, [loading, page, total]);

  const categoriesConverted = useMemo(() => {
    return [
      { id: -1, value: -1, label: t("category.allCategories"), code: "all" },
      ...[...categories].map((category) => {
        return {
          ...category,
          icon: category.icon,
          label: t(`category.${category.name}`),
          value: category.id,
        };
      }),
    ];
  }, [categories, i18n.language]);

  // const fetchNfts = useCallback(
  //   async (metadata) => {
  //     setLoading(true);
  //     const currentCategoryId = metadata?.categoryId || categoryId;
  //     const saleType =
  //       metadata?.saleType !== undefined
  //         ? metadata?.saleType
  //         : filterParams.saleType;
  //     const isVerified = metadata?.isReload
  //       ? metadata?.verification
  //       : filterByOption?.verification;
  //     const nsfw =
  //       metadata?.nsfw !== null ? metadata?.nsfw : filterByOption?.nsfw;
  //     const lazyMinted =
  //       metadata?.lazyMinted !== null
  //         ? metadata?.lazyMinted
  //         : filterByOption?.lazyMinted;
  //     const [data, err] = await nftService.getListNft({
  //       limit,
  //       page: metadata?.isReload ? 1 : page,
  //       categoryId: currentCategoryId === -1 ? "" : currentCategoryId,
  //       sort: (metadata?.sortBy !== 1 && metadata?.sortBy) || sortBy,
  //       saleTypes: saleType,
  //       startPrice: metadata?.isDeletePrice
  //         ? null
  //         : metadata?.priceFrom || priceFrom,
  //       endPrice: metadata?.isDeletePrice ? null : metadata?.priceTo || priceTo,
  //       properties: metadata?.resetProperties
  //         ? null
  //         : listSelectedProps.map((i) => i.ids)?.join(","),
  //       collectionIds:
  //         metadata?.collections === -1
  //           ? null
  //           : metadata?.collections || collections,
  //       networkTypes:
  //         metadata?.networkType === -1
  //           ? null
  //           : metadata?.networkType || networkType,
  //       isVerify: isVerified ? 1 : 0,
  //       isMinted: lazyMinted ? 1 : 0,
  //       nsfw: nsfw ? 1 : 0,
  //     });
  //     setLoading(false);
  //     if (err) {
  //       message.error(t("error.message.somethingWentWrong"));
  //       return;
  //     }
  //     setTotal(data.meta.totalItem);
  //     if (metadata?.isReload) {
  //       setNfts([...data?.items]);
  //       return;
  //     }
  //     setNfts([...nfts, ...data?.items]);
  //   },
  //   [
  //     categoryId,
  //     limit,
  //     page,
  //     sortBy,
  //     networkType,
  //     nfts,
  //     filterParams.saleType,
  //     collections,
  //     listSelectedProps,
  //     filterByOption,
  //     priceFrom,
  //     priceTo,
  //   ]
  // );

  // const fetchNftProperty = useCallback(async () => {
  //   const [res, err] = await nftService.getNftPropertyCollection({});
  //   if (res) {
  //     setListProperties(res);
  //     setSearchedProps(res);
  //   }
  //   if (err) {
  //   }
  // }, []);

  // useEffect(() => {
  //   fetchNftProperty();
  // }, [fetchNftProperty]);

  // const fetchCollection = useCallback(async () => {
  //   const [res, err] = await collectionService.getAllMiniCollection({
  //     limit: 0,
  //     page: 1,
  //     userId: "",
  //   });
  //   if (res) {
  //     setListCollection(res.items);
  //     setSearchedCollection(res.items);
  //   }
  //   if (err) {
  //   }
  // }, []);

  // useEffect(() => {
  //   fetchCollection();
  // }, [fetchCollection]);

  // useEffect(() => {
  //   if (!profile?.walletAddress) {
  //     setItemUserLike([]);
  //   }
  // }, [profile?.walletAddress]);

  useEffect(() => {
    if (params?.category && categoriesConverted.length > 1 && page === 1) {
      setPage(1);
      const categoryId = categoriesConverted.find(
        (c) => c.code === params?.category
      )?.id;
      setCategoryId(categoryId);
      // fetchNfts({ ...filterByOption, isReload: true, categoryId })
    }
    if (page && page !== 1) {
      // fetchNfts({ ...filterByOption })
    }
  }, [params?.category, categoriesConverted, page]);

  const handleChangeCategory = (value) => {
    if (value === -1) {
      history.push("/explore/all");
      return;
    }
    history.push(
      "/explore/" + categoriesConverted.find((c) => c.id === value)?.code
    );
  };

  const handleClearPriceClick = () => {
    setPriceFrom(null);
    setPriceTo(null);
    // fetchNfts({ isReload: true, isDeletePrice: true, ...filterByOption })
    setPage(1);
  };

  const handlePriceApplyClick = () => {
    // fetchNfts({ priceFrom, priceTo, isReload: true, ...filterByOption })
    setPage(1);
  };

  const handleClickCollectionFilter = (collection) => {
    //add collection to filter
    if (listSelectedCollection.includes(collection)) {
      setListSelectedCollection(
        listSelectedCollection.filter((c) => c !== collection)
      );
    } else {
      setListSelectedCollection([...listSelectedCollection, collection]);
    }
  };

  // const handleClickSortFilter = useCallback(
  //   (sort) => {
  //     setSortBy(sort);
  //     // fetchNfts({ sortBy: sort, isReload: true, ...filterByOption })
  //     setPage(1);
  //   },
  //   [fetchNfts, filterByOption]
  // );

  const handleClickChainFilter = (network) => {
    if (listSelectedChain.includes(network)) {
      setListSelectedChain(listSelectedChain.filter((n) => n !== network));
    } else {
      setListSelectedChain([...listSelectedChain, network]);
    }
  };

  const handleApplyCollectionFilter = () => {
    // setCollections(listSelectedCollection.map(c => c.id).join(','))
    // fetchNfts({
    //     collections: listSelectedCollection.map(c => c.id).join(',') || -1,
    //     isReload: true,
    //     ...filterByOption
    // })
    // setPage(1)
    setCollectionSearch(collectionSearchInput);
  };

  const handleApplyChainFilter = () => {
    setNetworkType(listSelectedChain.map((e) => e.value).join(","));
    // fetchNfts({
    //     networkType: listSelectedChain.map(c => c.value).join(',') || -1,
    //     isReload: true,
    //     ...filterByOption
    // })
    setPage(1);
  };

  const handleClearCollectionFilter = () => {
    // setListSelectedCollection([])
    // setNetworkType([])
    // fetchNfts({
    //     collections: -1,
    //     isReload: true,
    //     ...filterByOption
    // })
    // setPage(1)
    setCollectionSearchInput("");
    setCollectionSearch("");
    // setSearchedCollection(listCollection)
  };

  const handleClearChainFilter = () => {
    setListSelectedChain([]);
    // fetchNfts({
    //     networkType: '',
    //     isReload: true,
    //     ...filterByOption
    // })
    setPage(1);
  };

  const handleSearchCollection = (e) => {
    const value = e.target.value;
    setCollectionSearchInput(value.toLowerCase());
    // if (value.length > 0) {
    //     setSearchedCollection(listCollection.filter(c => c.name.toLowerCase().includes(value.toLowerCase())))
    // } else {
    //     setSearchedCollection(listCollection)
    // }
  };

  // const handleClickProperty = (item) => {
  //   if (listSelectedProps.includes(item)) {
  //     setListSelectedProps(listSelectedProps.filter((i) => i.id !== item.id));
  //   } else {
  //     setListSelectedProps([...listSelectedProps, item]);
  //   }
  // };

  // const handleSearchProperties = (e) => {
  //   setPropertyParam(e.target.value.toLowerCase());
  //   setSearchedProps(
  //     listProperties.filter((item) =>
  //       item.name.toLowerCase().includes(e.target.value.toLowerCase())
  //     )
  //   );
  // };

  // const handleClearPropertiesFilter = () => {
  //   setListSelectedProps([]);
  //   // fetchNfts({
  //   //     resetProperties: true,
  //   //     isReload: true,
  //   //     ...filterByOption
  //   // })
  //   setPropertyParam("");
  //   setSearchedProps(listProperties);
  // };

  // const handleFilterBy = useCallback(
  //   (key) => {
  //     setFilterByOption({ ...filterByOption, [key]: !filterByOption[key] });
  //     setPage(1);
  //     // fetchNfts({
  //     //     isReload: true,
  //     //     ...filterByOption,
  //     //     [key]: !filterByOption[key]
  //     // })
  //   },
  //   [fetchNfts, filterByOption]
  // );

  const onChangeValueInputPriceFrom = (
    value,
    maxValue = MAX_VALUE,
    decimal = 6
  ) => {
    if (value === ".") return setPriceFrom("");
    let number = value
      .toString()
      .replace(/[^0-9.]/g, "")
      .replace(/(\..*?)\..*/g, "$1");
    if (Number(number) >= maxValue) {
      number = number.slice(0, -1);
    }
    if (number.includes(".")) {
      const numString = number.toString().split(".");
      if (numString[1].length > decimal) {
        return setPriceFrom(number.substring(0, number.length - 1));
      }
    }
    setPriceFrom(number);
  };
  const onChangeValueInputPriceTo = (
    value,
    maxValue = MAX_VALUE,
    decimal = 6
  ) => {
    if (value === ".") return setPriceTo("");
    let number = value
      .toString()
      .replace(/[^0-9.]/g, "")
      .replace(/(\..*?)\..*/g, "$1");
    if (Number(number) >= maxValue) {
      number = number.slice(0, -1);
    }
    if (number.includes(".")) {
      const numString = number.toString().split(".");
      if (numString[1].length > decimal) {
        return setPriceTo(number.substring(0, number.length - 1));
      }
    }
    setPriceTo(number);
  };
  const dropdownPriceRange = useCallback(() => {
    return (
      <div
        className={`dropdown-collection dropdown-explorer dropdown-explore-light-range ${
          theme === "dark-theme" ? "dropdown-explore-dark-range" : ""
        }`}
        ref={pricerangeDropdown}
      >
        <div className="currency-select">
          <p className="pricerange-header">
            {t("explore.priceRangePlaceholder")}
          </p>
        </div>
        <Menu>
          <div
            className={`price-input-holder ${
              theme === "dark-theme" ? "dark-price-input-holder" : ""
            }`}
          >
            <InputCustom
              className="price-input"
              placeholder={t("common.from")}
              value={priceFrom}
              onChange={(e) => onChangeValueInputPriceFrom(e.target.value)}
            />
            <InputCustom
              className="price-input"
              placeholder={t("common.to")}
              value={priceTo}
              onChange={(e) => onChangeValueInputPriceTo(e.target.value)}
            />
          </div>
          <Divider />
          <div className="sale-type-filter-button">
            <ButtonCustom
              className="filter-btn"
              size="small"
              onClick={handleClearPriceClick}
            >
              {t("common.clear")}
            </ButtonCustom>
            <ButtonCustom
              className="filter-btn"
              size="small"
              color="blue"
              onClick={handlePriceApplyClick}
            >
              {t("common.apply")}
            </ButtonCustom>
          </div>
        </Menu>
      </div>
    );
  }, [priceFrom, priceTo, theme]);

  const dropdownCollection = useCallback(() => {
    return (
      <div
        className={`dropdown-collection dropdown-explorer  ${
          theme === "dark-theme" ? "dropdown-explore-dark-antd" : ""
        }`}
        ref={collectionDropdown}
      >
        <div className="collection-search-input-holder">
          <InputCustom
            prefix={<img src={SearchIcon} alt="search icon" />}
            value={collectionSearchInput}
            placeholder={t("common.search")}
            onChange={handleSearchCollection}
          />
        </div>
        {/* <div className="collection-holder">
                    {searchedCollection?.map((collection, index) => {
                        return (
                            <div
                                className="collection-item-holder"
                                onClick={() => handleClickCollectionFilter(collection)}
                                key={index}
                            >
                                <div className="collection-name">
                                    <CustomAvatar
                                        verified={collection.isVerify}
                                        src={convertImageAvatar(collection.bannerUrl)}
                                    />
                                    <div className="collection-name-text-holder">
                                        <span className="collection-name-text">
                                            {collection.title || collection.name}
                                        </span>
                                    </div>
                                </div>
                                <div>
                                    {listSelectedCollection?.includes(collection) && <i className="ri-check-line"></i>}
                                </div>
                            </div>
                        )
                    })}
                </div>
                <Divider /> */}
        <div className="sale-type-filter-button">
          <ButtonCustom
            className="filter-btn"
            size="small"
            onClick={handleClearCollectionFilter}
          >
            {t("common.clear")}
          </ButtonCustom>
          <ButtonCustom
            className="filter-btn"
            size="small"
            color="blue"
            onClick={handleApplyCollectionFilter}
          >
            {t("common.apply")}
          </ButtonCustom>
        </div>
      </div>
    );
  }, [collectionSearchInput]);

  const dropdownChain = useCallback(() => {
    return (
      <div
        className={`dropdown-collection dropdown-explorer  ${
          theme === "dark-theme" ? "dropdown-explore-dark-antd" : ""
        }`}
        ref={chainDropdown}
      >
        <div className="collection-holder">
          {NETWORK_OPTIONS?.map((network, index) => {
            return (
              <div
                className="collection-item-holder"
                onClick={() => handleClickChainFilter(network)}
                key={index}
              >
                <div className="collection-name">
                  <img
                    src={network.icon}
                    alt="chain logo"
                    className="chain-logo"
                  />
                  <div className="collection-name-text-holder">
                    <span className="collection-name-text">
                      {network.label}
                    </span>
                  </div>
                </div>
                <div>
                  {listSelectedChain?.includes(network) && (
                    <i className="ri-check-line"></i>
                  )}
                </div>
              </div>
            );
          })}
        </div>
        <Divider />
        <div className="sale-type-filter-button">
          <ButtonCustom
            className="filter-btn"
            size="small"
            onClick={handleClearChainFilter}
          >
            {t("common.clear")}
          </ButtonCustom>
          <ButtonCustom
            className="filter-btn"
            size="small"
            color="blue"
            onClick={handleApplyChainFilter}
          >
            {t("common.apply")}
          </ButtonCustom>
        </div>
      </div>
    );
  }, [listSelectedChain, theme]);

  // const dropdownProperties = useCallback(() => {
  //   return (
  //     <div
  //       className={`dropdown-collection dropdown-explorer ${
  //         theme === "dark-theme" ? "dropdown-explore-dark-antd" : ""
  //       }`}
  //       ref={propertyDropdown}
  //     >
  //       <div className="collection-search-input-holder">
  //         <InputCustom
  //           value={propertyParam}
  //           placeholder="Search properties"
  //           onChange={handleSearchProperties}
  //         />
  //       </div>
  //       <div className="collection-holder">
  //         {searchedProps?.map((property, index) => {
  //           return (
  //             <div
  //               className="property-filter-modal_item"
  //               key={index}
  //               onClick={() => handleClickProperty(property)}
  //             >
  //               <div className="property-filter-modal_item-left">
  //                 <div className="property-filter-modal_item-name">
  //                   {property.name}
  //                 </div>
  //                 <div className="property-filter-modal_item-value">{`${property?.value?.slice(
  //                   0,
  //                   4
  //                 )}${property?.value?.length > 4 ? "..." : ""}`}</div>
  //               </div>
  //               <div>
  //                 {listSelectedProps?.includes(property) && (
  //                   <i className="ri-check-line"></i>
  //                 )}
  //               </div>
  //             </div>
  //           );
  //         })}
  //       </div>
  //       <Divider />
  //       <div className="sale-type-filter-button">
  //         <ButtonCustom
  //           className="filter-btn"
  //           size="small"
  //           onClick={handleClearPropertiesFilter}
  //         >
  //           {t("common.clear")}
  //         </ButtonCustom>
  //         <ButtonCustom
  //           className="filter-btn"
  //           size="small"
  //           color="blue"
  //           onClick={handleApplyProperties}
  //         >
  //           {t("common.apply")}
  //         </ButtonCustom>
  //       </div>
  //     </div>
  //   );
  // }, [listSelectedProps, searchedProps, theme]);

  // const handleApplyProperties = () => {
  //   // fetchNfts({ isReload: true, ...filterByOption })
  //   setPage(1);
  // };

  // const dropDownVerification = useCallback(
  //   () => (
  //     <div
  //       className={`dropdown-collection dropdown-explorer dropdown-sortby ${
  //         theme === "dark-theme" ? "dropdown-explore-dark-antd" : ""
  //       }`}
  //     >
  //       <div className="collection-holder">
  //         <div className="filter-title">
  //           {t("common.sortAndFilter.sort.title")}
  //         </div>
  //         <RadioGroupCustom
  //           options={SORT_BY.map((item) => ({
  //             value: item.value,
  //             label: t(`common.sortAndFilter.sort.${item.value}`),
  //           }))}
  //           value={sortBy}
  //           onChange={handleClickSortFilter}
  //         />
  //         <div className="filter-title">
  //           {t("common.sortAndFilter.options.title")}
  //         </div>
  //         {optionFilter?.map((item, index) => (
  //           <div key={index} className="verification-holder">
  //             {t(`common.sortAndFilter.options.${item.key}`)}
  //             <CustomSwitch
  //               onChange={() => handleFilterBy(item.key)}
  //               value={filterByOption[item.key]}
  //               disableLabel={true}
  //               className="switch-filter"
  //             />
  //           </div>
  //         ))}
  //       </div>
  //     </div>
  //   ),

  //   [filterByOption, handleFilterBy, sortBy, handleClickSortFilter, theme]
  // );

  const saleTypeOptions = useMemo(() => {
    return [
      ...SALES_TYPE.map((saleType) => {
        return {
          ...saleType,
          label: saleType.value
            ? t(`saleType.${saleType.value}`)
            : t("saleType.allSaleTypes"),
          value: saleType.value,
        };
      }),
    ];
  }, [t, i18n.language]);

  return (
    <DefaultLayout>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{`${MAIN_TITLE} - Explore`}</title>
        <meta name="description" content={`${MAIN_TITLE} - Explore`} />
      </Helmet>
      <div className="explore-container">
        <div className={`explore-container__header ${styles.header}`}>
          <div className="container">
            <div className="title">{t("explore.title")}</div>
          </div>
        </div>
        <div className="filter-content">
          <div className="filter-right">
            <SelectCustom
              className="select left"
              categoryIcon={true}
              dropdownClassName="select__dropdown--chains"
              getPopupContainer={(triggerNode) => triggerNode.parentNode}
              dropdownRender={dropdownChain}
              placeholder={
                <span className="select-placeholder">
                  <img src={PolygonIcon} alt="polygon-icon" />
                  <span>{t("explore.blockchain")}</span>
                </span>
              }
            />
            <SelectCustom
              value={categoryId}
              onChange={handleChangeCategory}
              dropdownClassName="select__dropdown--categories"
              options={categoriesConverted}
              className="select left"
              showIconSvg={true}
              getPopupContainer={(triggerNode) => triggerNode.parentNode}
            />
            <SelectCustom
              className="select left"
              dropdownClassName="select__dropdown--collections"
              categoryIcon={true}
              getPopupContainer={(triggerNode) => triggerNode.parentNode}
              dropdownRender={dropdownCollection}
              placeholder={
                <span className="select-placeholder">
                  <img src={FourSquaresIcon} alt="4squares-icon" />
                  <span>{t("explore.collections")}</span>
                </span>
              }
            />
            <SelectCustom
              defaultValue={null}
              onChange={(value) => setSaleTypes(value)}
              options={saleTypeOptions}
              className="select left"
              categoryIcon={true}
              value={saleTypes}
              getPopupContainer={(triggerNode) => triggerNode.parentNode}
            />
            {/* <SelectCustom
                            className="select left"
                            dropdownClassName="select__dropdown--price-range"
                            categoryIcon={true}
                            getPopupContainer={triggerNode => triggerNode.parentNode}
                            dropdownRender={dropdownPriceRange}
                            placeholder={
                                <span className='select-placeholder'>
                                    <img src={LightingIcon} alt="light-icon" />
                                    <span>{t('explore.priceRange')}</span>
                                </span>
                            }
                        /> */}
            {/* <SelectCustom
                            className="select left"
                            dropdownClassName="select__dropdown--properties"
                            categoryIcon={true}
                            getPopupContainer={triggerNode => triggerNode.parentNode}
                            dropdownRender={dropdownProperties}
                            placeholder={t('explore.properties')}
                            />
                        <SelectCustom
                            className="select left"
                            dropdownClassName="select__dropdown--filter"
                            categoryIcon={true}
                            getPopupContainer={triggerNode => triggerNode.parentNode}
                            dropdownRender={dropDownVerification}
                            placeholder={
                                <span className='select-placeholder'>
                                    <img src={FilterIcon} alt="filter-icon" />
                                    <span>{t('common.sortAndFilter.title')}</span>
                                </span>
                            }
                        /> */}
          </div>
        </div>
        <HotCollections
          isExploreType
          gridView
          showLoadMore
          networkTypes={networkType}
          saleTypes={saleTypes}
          keyword={collectionSearch}
          categoryId={categoryId == -1 ? null : categoryId}
        />
        {/* <div className="explore-container__content">
                    <NFTCollection
                        loading={loading}
                        nftsLikes={itemUserLikes}
                        data={nfts}
                        total={total}
                        handeLoadMore={handeLoadMore}
                        isTablet={isTablet}
                    />
                </div> */}
      </div>
    </DefaultLayout>
  );
};

export default Explore;
