import React, { useState } from "react";
import { Alert, Button, Menu, Modal, Space } from "antd";
import { useTranslation } from "react-i18next";
import { ButtonCustom } from "components/common";
import { Link, useLocation } from "react-router-dom/cjs/react-router-dom";
import { createPortal } from "react-dom";
import env from "env";

import "./style.scss";

import SmartPocketIcon from "assets/images/smart-pocket-icon.svg";
import closeModalIcon from "assets/icons/cancel-icon.svg";
import { ReactComponent as SmartPocketGetIcon } from "assets/icons/smart-pocket-get-icon.svg";
import { ReactComponent as SmartPocketUseIcon } from "assets/icons/smart-pocket-use-icon.svg";
import { useConnectors, useDisconnect, useAccount } from "wagmi";
import { useDispatch, useSelector } from "react-redux";
import { logout } from "store/login.slice";

const noActiveMenuPath = ["/coming-soon"];

const Sidebar = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const dispatch = useDispatch();
  const connectors = useConnectors();
  const { disconnect } = useDisconnect();

  const currentPath = location.pathname;

  const { profile } = useSelector((state) => state.user);
  const { address } = useAccount();

  const [isModalLogoutOpen, setIsModalLogoutOpen] = useState(false);

  const handleOpenLogoutModal = () => {
    setIsModalLogoutOpen(true);
  };

  const handleCancel = () => {
    setIsModalLogoutOpen(false);
  };

  const handleDisconnect = async () => {
    setIsModalLogoutOpen(false);
    dispatch(logout({ disconnect, connectors }));
  };

  const _renderModalLogout = () => {
    return (
      <>
        <Modal
          className="logout-modal-container"
          title={t("sidebar.titleModalLogout")}
          destroyOnClose={true}
          visible={isModalLogoutOpen}
          closeIcon={
            <img
              style={{ marginLeft: "10px" }}
              src={closeModalIcon}
              alt="Close modal icon"
            />
          }
          footer={null}
          centered
          onOk={handleDisconnect}
          onCancel={handleCancel}
          width={"auto"}
        >
          <div className="button-modal-logout-container">
            <ButtonCustom className="cancelButton" onClick={handleCancel}>
              {t("sidebar.cancel")}
            </ButtonCustom>
            <ButtonCustom
              className="okButton"
              color="blue"
              onClick={handleDisconnect}
            >
              {t("sidebar.ok")}
            </ButtonCustom>
          </div>
        </Modal>
      </>
    );
  };

  return (
    <>
      <div className="sidebar">
        <div className="sidebar-menu">
          <Menu
            mode="inline"
            inlineIndent={0}
            defaultOpenKeys={["sub1"]}
            defaultSelectedKeys={
              noActiveMenuPath.includes(currentPath) ? [] : ["default"]
            }
          >
            <Menu.Item className="sidebar-title" icon={<SmartPocketGetIcon />}>
              <a href={env.metadata.url.get} target="_blank" rel="noreferrer">
                <span>{t("sidebar.get")}</span>
                <i className="ri-external-link-line flip"></i>
              </a>
            </Menu.Item>
            <Menu.SubMenu
              key="sub1"
              className="dropdown-sidebar"
              title={t("sidebar.use")}
              icon={<SmartPocketUseIcon />}
            >
              <Menu.Item key="default" style={{ paddingLeft: "20px" }}>
                <Link to="/">{t("sidebar.nftMarket")}</Link>
              </Menu.Item>
              <Menu.Item style={{ paddingLeft: "20px" }}>
                <Link to="/coming-soon">{t("sidebar.pointShop")}</Link>
              </Menu.Item>
              <Menu.Item style={{ paddingLeft: "20px" }}>
                <Link to="/coming-soon">{t("sidebar.shopCoupon")}</Link>
              </Menu.Item>
            </Menu.SubMenu>
          </Menu>
          <a href={env.metadata.url.wallet} target="_blank" rel="noreferrer">
            <ButtonCustom color="blue" style={{ width: "100%" }}>
              <img src={SmartPocketIcon} alt="smart pocket icon" />
              {t("sidebar.wallet")}
              <i className="ri-external-link-line flip"></i>
            </ButtonCustom>
          </a>
        </div>
        {profile?.walletAddress && address && (
          <ButtonCustom
            icon={<i className="ri-logout-box-line"></i>}
            variant="ghost"
            onClick={handleOpenLogoutModal}
          >
            {t("sidebar.logOut")}
          </ButtonCustom>
        )}
      </div>
      {createPortal(
        <div className="sidebar-mobile">
          <div className="sidebar-mobile__action">
            <a href={env.metadata.url.get} target="_blank" rel="noreferrer">
              <div className="sidebar-mobile__action__button">
                <SmartPocketGetIcon />
                {t("sidebar.get")}
              </div>
            </a>
            <a href={env.metadata.url.wallet} target="_blank" rel="noreferrer">
              <div className="wallet-button">
                <img src={SmartPocketIcon} alt="smart pocket icon" />
                {t("sidebar.wallet")}
              </div>
            </a>
            <Link to="/coming-soon">
              <div className="sidebar-mobile__action__button">
                <SmartPocketUseIcon />
                {t("sidebar.use")}
              </div>
            </Link>
          </div>
        </div>,
        document.body
      )}
      {_renderModalLogout()}
    </>
  );
};

export default Sidebar;
