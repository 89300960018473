import Request from "../request";
import { customAxios } from "request/customAxios";
import { getNFTImageName } from "utils/image";
import * as message from "utils/custom-message";
import {
    IMAGE_TYPE_UPLOAD,
    SORT_COLLECTION_VALUE,
    USER_IS_BANNED_CODE,
} from "constants/index";
import { SERVER_API_ENDPOINT } from "constants/envs";
import i18n from "translation/i18n";

const collectionService = {
    getListNftsCollection: async ({
        properties,
        shortUrl,
        verification,
        sortField,
        categoryId,
        limit,
        page,
        title,
        collectionAddress,
    }) => {
        try {
            const response = await Request.get(`${SERVER_API_ENDPOINT}/nft/all`, {
                ...(limit && { limit }),
                ...(page && { page }),
                ...(verification && { verification }),
                ...(sortField && { sortField }),
                ...(properties && properties.length && { properties }),
                ...(title && { title }),
                ...(collectionAddress && { collectionAddress }),
                ...(categoryId && { categoryId }),
            });
            if (response.data === USER_IS_BANNED_CODE) {
                message.error(i18n.t("error.message.userHasBeenBanned"));
                return [null, { message: "" }];
            }
            return [response.data, null];
        } catch (error) {
            console.error("getCollectionDetail", error.response);

            return [null, error];
        }
    },
    getInfoCollectionDetail: async (address) => {
        try {
            const response = await Request.get(
                `${SERVER_API_ENDPOINT}/collection/detail/${address}`
            );
            return [response.data, null];
        } catch (error) {
            console.error("getListOfCollection", error.response);
            return [null, error];
        }
    },

    getListOfCollection: async () => {
        try {
            const response = await Request.get(`${SERVER_API_ENDPOINT}/collection`);
            return [response.data, null];
        } catch (error) {
            console.error("getListOfCollection", error.response);
            return [null, error];
        }
    },

    checkCustomUrl: async (queryParam) => {
        try {
            const response = await Request.get(
                `${SERVER_API_ENDPOINT}/collection/check-short-url`,
                {
                    shortUrl: queryParam.shortUrl,
                }
            );
            return [response.data, null];
        } catch (error) {
            console.error("checkCustomUrl", error.response);
            return [null, error];
        }
    },

    postCollection: async (data) => {
        try {
            const response = await Request.post(
                `${SERVER_API_ENDPOINT}/collection`,
                data
            );
            return [response.data, null];
        } catch (error) {
            console.error("postCollection", error.response);
            return [null, error.response.data.message];
        }
    },

    updateImageCollection: async (data) => {
        const { id } = data;
        try {
            const response = await Request.patch(
                `${SERVER_API_ENDPOINT}/collection/image/${id}`,
                data
            );
            return [response.data, null];
        } catch (error) {
            return [null, error.response.data.message];
        }
    },

    getPresignUrlCollectionCover: async (data) => {
        const { imgFile } = data;
        const accessToken = localStorage.getItem("accessToken");
        const userId = localStorage.getItem("userId");
        let imageId = getNFTImageName(imgFile);
        try {
            const res = await customAxios({
                method: "get",
                url: `${SERVER_API_ENDPOINT}/upload/pre-signed/${IMAGE_TYPE_UPLOAD.COLLECTION}_${data?.collectionId}/${imageId}`,
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                },
            });
            return [res.data, null];
        } catch (error) {
            console.error("getPresignUrlCollectionCover", error.response);
            return [null, error];
        }
    },

    getPresignUrlCollectionAvatar: async (data) => {
        const { imgFile } = data;
        const accessToken = localStorage.getItem("accessToken");
        const userId = localStorage.getItem("userId");
        let imageId = getNFTImageName(imgFile);
        try {
            const res = await customAxios({
                method: "get",
                url: `${SERVER_API_ENDPOINT}/upload/pre-signed/${IMAGE_TYPE_UPLOAD.COLLECTION}_${data?.collectionId}/${imageId}`,
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                },
            });
            return [res.data, null];
        } catch (error) {
            console.error("getPresignUrlCollectionAvatar", error.response);
            return [null, error];
        }
    },

    uploadCollectionCoverImage: async (data) => {
        const type = data.imgFile.type;
        const extension = type.split("/")[1];

        const name = new Date().getTime();

        const accessToken = localStorage.getItem("accessToken");

        const config = {
            headers: {
                Authorization: accessToken,
                "x-amz-tagging": `token=${accessToken}&collective_id=${data.collectionId}&type=cover`,
                "Content-Type": type,
            },
        };
        try {
            // TBD: not sure this where to use this (BE need to provide api?)
            const response = await customAxios({
                method: "put",
                url: `${SERVER_API_ENDPOINT}/user-collective/${data.userId}/${data.collectionId}/${name}.${extension}`,
                data: data.imgFile,
                headers: config.headers,
            });
            return [response, null];
        } catch (error) {
            console.error("uploadCollectionCoverImage", error.response);
            return [null, error];
        }
    },

    getAllCollection: async ({
        limit = 12,
        page = 1,
        sortBy = SORT_COLLECTION_VALUE.RECENT,
        hasListPrice = true,
        hasOpenOffer = false,
        ownedByCreator = false,
        hasSold = false,
        userId,
        networkTypes,
        isVerify,
        notEmpty,
        onSaleStatus,
        nsfw,
        owner,
        saleTypes,
        keyword,
        categoryId,
    }) => {
        try {
            const response = await Request.get(
                `${SERVER_API_ENDPOINT}/collection/all`,
                {
                    ...(notEmpty && { notEmpty }),
                    ...(onSaleStatus && { onSaleStatus }),
                    page,
                    ...(userId && { userId }),
                    ...(limit && { limit }),
                    sortField: sortBy,
                    hasListPrice,
                    hasSold,
                    hasOpenOffer,
                    ownedByCreator,
                    ...(networkTypes && { networkTypes }),
                    ...(isVerify && { isVerify }),
                    nsfw,
                    owner,
                    saleTypes,
                    keyword: keyword ? keyword.trim() : "",
                    categoryId,
                }
            );
            return [response.data, null];
        } catch (error) {
            console.error("getAllCollection", error.response);
            return [null, error];
        }
    },

    getAllMiniCollection: async () => {
        try {
            const response = await Request.get(
                `${SERVER_API_ENDPOINT}/collection/all-mini`
            );
            return [response.data, null];
        } catch (error) {
            console.error("getAllMiniCollection", error.response);
            return [null, error];
        }
    },

    getHotCollection: async ({ limit = 12, offset = 0 }) => {
        try {
            const response = await Request.get(
                `${SERVER_API_ENDPOINT}/collection/hot?offset=${offset}&limit=${limit}`
            );
            return [response.data, null];
        } catch (error) {
            console.error("getHotCollection", error.response);
            return [null, error];
        }
    },

    getTrendingCollections: async (params) => {
        try {
            const response = await Request.get(
                `${SERVER_API_ENDPOINT}/collection/trending`,
                {
                    ...params
                }
            );
            return [response.data, null];
        } catch (error) {
            console.error("getTrendingCollections", error.response);
            return [null, error];
        }
    },
    getListRanking: async (params) => {
        try {
            const response = await Request.get(
                `${SERVER_API_ENDPOINT}/collection/leaderboard`,
                {
                    ...params
                }
            );
            return [response.data, null];
        } catch (error) {
            console.error("getListRanking", error.response);
            return [null, error];
        }
    },
    confirmVerified: async (collectionId, twitterUsername) => {
        try {
            const response = await Request.get(
                `${SERVER_API_ENDPOINT}/collection/twitter/verify/check/post`,
                {
                    collectionId,
                    name: twitterUsername,
                }
            );
            return [response.data, null];
        } catch (error) {
            console.error("confirmVerified", error.response);
            return [null, error];
        }
    },

    unLinkTwitter: async (id) => {
        try {
            const response = await Request.patch(
                `${SERVER_API_ENDPOINT}/collection/twitter/un-verify/${id}`
            );
            return [response.data, null];
        } catch (error) {
            console.error("unLinkTwitter", error.response);
            return [null, error];
        }
    },

    patchCollection: async (data) => {
        try {
            const id = data.id;
            delete data.id;
            const response = await Request.patch(
                `${SERVER_API_ENDPOINT}/collection/${id}`,
                data
            );
            return [response.data, null];
        } catch (error) {
            console.error("patchCollection", error.response);
            return [null, error];
        }
    },
};

export default collectionService;
