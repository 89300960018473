import React, { useEffect, useState } from 'react'
import { Card, Avatar, Tooltip } from 'antd'
import { Link, useHistory } from 'react-router-dom'
import { useSelector } from 'react-redux'

import { FILE_EXTENSION } from 'constants/index'
import { convertImageAvatar } from 'utils/image'
import { roundNumber } from 'utils/func'
import { convertTimeToDateTime } from 'utils/convertDate'
import PlayGroupIcon from 'assets/icons/play-group-icon.svg'
import Mp3Icon from 'assets/icons/music-group-icon.svg'
import { useResizeWindow } from 'utils/hook'
import nftService from 'service/nftService'
import LoadingIcon from 'assets/icons/loading-icon.svg'
import CustomVerifyIcon from 'components/common/custom-verify-icon'
import { exportFileFormat } from 'utils/file'
import { useTranslation } from 'react-i18next'

const PurchasesActivity = props => {
    const { t } = useTranslation();
    const { user, user2, saleNft, displayPreviewImage, createdAt, walletAddress } = props
    const nft = saleNft?.nft
    const { profile } = useSelector(state => state.user)

    const [imageUrlMetadata, setImageUrlMetadata] = useState(null)
    const [isDoneFetchImageUrlMetadata, setIsDoneFetchImageUrlMetadata] = useState(false)
    const history = useHistory()
    const handleClickUser = (e, address) => {
        e.stopPropagation()
        if (address === walletAddress) {
            e.preventDefault()
        }
    }
    const [isSmallScreen] = useResizeWindow(768)
    const [isMediumScreen] = useResizeWindow(992)
    const [isLargeScreen] = useResizeWindow(1400)

    useEffect(() => {
        const fetchImageUrlFromMetadata = async nftData => {
            let fullMetaDataUrl = `${nftData?.metadataUrl}/${nftData?.tokenId}`
            const [result] = await nftService.getImageUrlFromMetadata(fullMetaDataUrl)
            setIsDoneFetchImageUrlMetadata(true)

            if (result) {
                setImageUrlMetadata(result)
            }
        }

        if (nft?.metadataUrl) {
            fetchImageUrlFromMetadata(nft)
        }
    }, [])

    const DisplayImage = ({ nftData, displayPreviewImageSale }) => {
        if (!nftData?.metadataUrl) {
            return <img src={displayPreviewImageSale} alt="" />
        }
        if (nftData?.metadataUrl && !isDoneFetchImageUrlMetadata) {
            return <img src={LoadingIcon} alt="LoadingIcon" className="custom-loading-icon" />
        } else if (imageUrlMetadata && isDoneFetchImageUrlMetadata) {
            return <img src={imageUrlMetadata} alt="" />
        }
        return <img src={displayPreviewImageSale} alt="" />
    }

    const routerCollection = () => {
        history.push(`/nft/${nft?.collection?.id}:${nft?.tokenId}`)
    }
    return (
        <div onClick={routerCollection} className="cursor-pointer" style={{ height: '100%' }}>
            <Card className="card-activity-custom">
                <div className="card-activity-custom_item-image">
                    {/* <img src={convertImage(nft?.originImage)} alt="" /> */}
                    <DisplayImage nftData={nft} displayPreviewImageSale={displayPreviewImage} />
                    {exportFileFormat(nft?.nftUrl) === FILE_EXTENSION.MP4 && (
                        <div className="card-activity-custom_icon-file-extension">
                            <img src={PlayGroupIcon} alt="PlayGroupIcon" />
                        </div>
                    )}
                    {exportFileFormat(nft?.nftUrl) === FILE_EXTENSION.MPEG && (
                        <div className="card-activity-custom_icon-file-extension">
                            <img src={Mp3Icon} alt="Mp3Icon" />
                        </div>
                    )}
                </div>
                <div className="card-activity-custom-content">
                    <div className="item-name">
                        <Tooltip overlayClassName="blue" placement="topLeft" title={nft?.name}>
                            <span>{nft?.title}</span>
                        </Tooltip>
                    </div>
                    <div className="card-activity-custom-content_type">
                        <div className='d-flex align-items-center'>

                            <i className="ri-shopping-cart-2-line"></i>
                            <span>{t("activity.purchasedBy")}</span>
                        </div>
                        <div className='d-flex align-items-center'>
                            <Link
                                to={user?.shortUrl ? `/${user?.shortUrl}` : `/user/${user?.walletAddress}`}
                                onClick={e => handleClickUser(e, user?.walletAddress)}
                            >
                                <div className="avatar">
                                    <Avatar alt="example" src={convertImageAvatar(user?.avatarImg)} />
                                    {user?.isVerify ? <CustomVerifyIcon className="icon" /> : null}
                                </div>
                            </Link>
                            <Tooltip
                                overlayClassName="blue"
                                placement="topLeft"
                                title={user?.username || user?.walletAddress}
                            >
                                <Link
                                    to={user?.shortUrl ? `/${user?.shortUrl}` : `/user/${user?.walletAddress}`}
                                    onClick={e => handleClickUser(e, user?.walletAddress)}
                                >
                                    <div
                                        className="user-name"
                                        style={{
                                            marginRight: 0,
                                            maxWidth:
                                                roundNumber(saleNft?.price).toString().length >= 12
                                                    ? '22px'
                                                    : roundNumber(saleNft?.price).toString().length <= 6
                                                        ? '90px'
                                                        : '67px'
                                        }}
                                    >
                                        {/* <span>{user?.username !== null ? user?.username : user?.walletAddress}</span> */}
                                        <span>
                                            {profile?.walletAddress && user?.walletAddress?.toLowerCase() === profile?.walletAddress?.toLowerCase()
                                                ? t("activity.you")
                                                : user?.username !== null
                                                    ? user?.username
                                                    : user?.walletAddress}
                                        </span>
                                    </div>
                                </Link>
                            </Tooltip>
                            {!isSmallScreen && !isLargeScreen && (
                                <>
                                    <span className="gray-text">{t("common.for")}</span>
                                    <span className="price">
                                        {roundNumber(saleNft?.price) || 0} {saleNft?.currencyToken.toUpperCase()}
                                    </span>{' '}
                                </>
                            )}
                        </div>
                    </div>
                    <div className="card-activity-custom-content_user">
                        <div className="card-activity-custom-content_user-wrapper">
                            <div className="d-flex">
                                {isSmallScreen ? (
                                    <>
                                        <span className="gray-text">{t('common.for')}</span>
                                        <span className="price">
                                            {roundNumber(saleNft?.price) || 0} {saleNft?.currencyToken?.toUpperCase()}
                                        </span>{' '}
                                    </>
                                ) : (
                                    isLargeScreen &&
                                    !isMediumScreen && (
                                        <>
                                            <span className="gray-text">{t('common.for')}</span>
                                            <span className="price">
                                                {roundNumber(saleNft?.price) || 0}{' '}
                                                {saleNft?.currencyToken?.toUpperCase()}
                                            </span>{' '}
                                        </>
                                    )
                                )}
                            </div>
                            <div className="d-flex">
                                <span className="gray-text">{t('common.from')}</span>
                                <Link
                                    to={user2?.shortUrl ? `/${user2?.shortUrl}` : `/user/${user2?.walletAddress}`}
                                    onClick={e => handleClickUser(e, user2?.walletAddress)}
                                >
                                    <div className="avatar" style={{ marginLeft: 8 }}>
                                        <Avatar alt="example" src={convertImageAvatar(user2?.avatarImg)} />
                                        {user2?.isVerify ? <CustomVerifyIcon className="icon" /> : null}
                                    </div>
                                </Link>
                                <Tooltip
                                    overlayClassName="blue"
                                    placement="topLeft"
                                    title={user2?.name || user2?.walletAddress}
                                >
                                    <Link
                                        to={user2?.shortUrl ? `/${user2?.shortUrl}` : `/user/${user2?.walletAddress}`}
                                        onClick={e => handleClickUser(e, user2?.walletAddress)}
                                    >
                                        <div className="user-name">
                                            {/* <span>{user2?.name !== null ? user2?.name : user2?.walletAddress}</span> */}
                                            <span>
                                                {profile?.walletAddress && user2?.walletAddress?.toLowerCase() === profile?.walletAddress?.toLowerCase()
                                                    ? t('activity.you')
                                                    : user2?.username !== null
                                                        ? user2?.username
                                                        : user2?.walletAddress}
                                            </span>
                                        </div>
                                    </Link>
                                </Tooltip>
                            </div>
                        </div>
                    </div>
                    <div className="card-activity-custom-content_date">
                        <span>{convertTimeToDateTime(createdAt)}</span>
                    </div>
                </div>
            </Card>
        </div>
    )
}

export default PurchasesActivity
