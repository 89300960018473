import React, { useState, useCallback, useRef } from 'react'
import { useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { Modal, Form, Col } from 'antd'
import { useChainId } from 'wagmi'

import { CONTRACT_TYPES, genFactoryContract, getTransactionReceipt } from 'blockchain/instance'
import { ButtonCustom, TooltipCustom } from 'components/common'
import { InputCustom } from 'components/common'
import { Label } from 'components/modules'
import { DisplayImagePicker } from './components'
import { createCollectionPending, createCollectionSuccess, deployCollectionPending, deployCollectionSuccess, resetCollection } from 'store/collection.slice'
import collectionService from 'service/collectionService'
import systemConfigService from 'service/systemService'
import nftService from 'service/nftService'
import * as message from 'utils/custom-message'
import { ERROR_CODE_USER_DENIED_METAMASK, COLLECTIBLE_CODE, COLLECTIBLE_TYPE } from 'constants/index'
import './style.scss'
import { CONTRACT_ADDRESS, NETWORKS, NETWORK_ID_TYPE, SERVER_API_ENDPOINT } from 'constants/envs'
import { changeNetwork } from 'blockchain/utils'
import CustomSwitch from 'components/common/switch'

import DefaultAvt from 'assets/images/default-collection-profile.svg'

const MAX_VALUE = 100000000000000

const CreateCollectionModal = ({
    onClose,
    onCollectionFollowStepModalClose,
    onCollectionFollowStepModalOpen,
    collectibleType,
    networkType,
    visible,
    ...restProps
}) => {
    const { t } = useTranslation()
    const chainId = useChainId()
    const [form] = Form.useForm()
    const [isFormError, setIsFormError] = useState(false)
    const dispatch = useDispatch()

    const isSwitchingNetwork = useRef(false);

    const urlValidator = async (rules, value) => {
        if (value) {
            const [profile] = await collectionService.checkCustomUrl({ shortUrl: value })
            const { isExisting } = profile
            if (isExisting) return Promise.reject(t('createCollection.urlTaken'))

            const [data] = await systemConfigService.getSystemConfig()
            if (data.restrictCollection) {
                const isMatchRestrictedUrl = data.restrictCollection.includes(value)
                if (isMatchRestrictedUrl) return Promise.reject('Url is on restricted list')
            }
        }
        return Promise.resolve()
    }

    const lessThan30Validator = useCallback((rule, value) => {
        if (!isNaN(value) && value > 30) {
            return Promise.reject(t('createNFT.field_royalties_rule_min', {min: 30}))
        }

        return Promise.resolve()
    }, [])

    const onChangeValueInput = (value, name, maxValue = MAX_VALUE, decimal = 6) => {
        if (value === '.') return form.setFieldsValue({ [name]: null })
        let number = value
            .toString()
            .replace(/[^0-9.]/g, '')
            .replace(/(\..*?)\..*/g, '$1')
        if (Number(number) >= maxValue) {
            number = number.slice(0, -1)
        }
        if (number.includes('.')) {
            const numString = number.toString().split('.')
            if (numString[1].length > decimal) {
                return form.setFieldsValue({ [name]: number.substring(0, number.length - 1) })
            }
        }
        form.setFieldsValue({ [name]: number })
    }

    const handleDeployCollection = async values => {
        try {
            if (NETWORK_ID_TYPE[chainId] !== networkType && !isSwitchingNetwork.current) {
                isSwitchingNetwork.current = true;
                await changeNetwork(networkType)
                isSwitchingNetwork.current = false;
            }

            if (collectibleType === COLLECTIBLE_TYPE.SINGLE) {
                const transactionHash = await genFactoryContract(CONTRACT_TYPES.WRITE)(CONTRACT_ADDRESS[networkType].factory, {
                    functionName: 'newCollection',
                    args: [
                        values?.displayName,
                        values?.token_symbol,
                        `${SERVER_API_ENDPOINT}/nft/metadata/${values?.shortUrl}/`,
                        `${SERVER_API_ENDPOINT}/nft/metadata/${values?.shortUrl}/`
                    ]
                });

                dispatch(deployCollectionPending());
                const receipt = await getTransactionReceipt(transactionHash, NETWORKS[networkType].id);
                const data = {
                    collectionAddress: receipt?.logs[0]?.address,
                    transactionHash: transactionHash
                }
                return [data, null]
            } else {
                const transactionHash = await genFactoryContract(CONTRACT_TYPES.WRITE)(CONTRACT_ADDRESS[networkType].factory, {
                    functionName: 'newCollectionMultipleSupply',
                    args: [
                        values?.displayName,
                        values?.token_symbol,
                        `${SERVER_API_ENDPOINT}/nft/metadata/${values?.shortUrl}/`,
                        `${SERVER_API_ENDPOINT}/nft/metadata/${values?.shortUrl}/`
                    ]
                })

                dispatch(deployCollectionPending())
                const receipt = await getTransactionReceipt(transactionHash, NETWORKS[networkType].id);
                const data = {
                    collectionAddress: receipt?.logs[0]?.address,
                    transactionHash: transactionHash
                }
                return [data, null]
            }
        } catch (error) {
            return [null, error]
        }
    }

    const handleCreateCollection = async () => {
        try {
            const values = await form.validateFields()

            onCollectionFollowStepModalOpen()

            const [data, error] = await handleDeployCollection(values)

            if (error) {
                if (error?.code === ERROR_CODE_USER_DENIED_METAMASK) {
                    message.error(t('createCollection.errorRejectSign'))
                    return onCollectionFollowStepModalClose()
                }
                return onCollectionFollowStepModalClose()
            }

            if (data) {
                dispatch(deployCollectionSuccess())
                dispatch(createCollectionPending())
                const collectionData = {
                    title: values?.displayName.trim(),
                    name: values?.displayName.trim(),
                    shortUrl: values?.shortUrl,
                    description: values?.description,
                    symbol: values?.token_symbol,
                    hashTransaction: data.transactionHash,
                    address: data.collectionAddress,
                    networkType,
                    type: COLLECTIBLE_CODE[collectibleType],
                    royalty: values?.royalty ? Number(values?.royalty) : null,
                    nsfw: !!values.nsfw,
                    externalLink: values.personalSite,
                    telegramLink: values.instagramName,
                    twitterLink: values.twitterName,
                    mediumLink: values.mediumName,
                    website: values.personalSite
                }

                const [response, err] = await collectionService.postCollection(collectionData)

                if (err) {
                    message.error(t('error.message.failedToCreateCollection'))
                    return onCollectionFollowStepModalClose()
                }

                if (response) {
                    const collectionId = response.id
                    await uploadImageCollection({ collectionId, imgFile: values.displayImage })
                }

                message.success(t('success.message.collectionCreatedSuccessfully'))
                dispatch(resetCollection())
                dispatch(createCollectionSuccess())
                onCollectionFollowStepModalClose()
                onClose()
                form.resetFields()
            }
        } catch (err) {
            setIsFormError(true)
            return
        }
    }

    const uploadImageCollection = async ({ collectionId, imgFile }) => {
        const uploadParams = {
            userId: localStorage.getItem('userId'),
            accessToken: localStorage.getItem('accessToken'),
            collectionId,
            imgFile
        }

        const [response, error] = await collectionService.getPresignUrlCollectionAvatar(uploadParams)

        const [, errorUpload] = await nftService.putNftImage({
            imgFile: uploadParams.imgFile,
            collectionId: uploadParams.collectionId,
            uploadUrl: response?.upload_url
        })

        await collectionService.updateImageCollection({
            id: uploadParams.collectionId,
            bannerUrl: response?.path,
            thumbnailUrl: response?.path
        })

        if (error || errorUpload) {
            message.error(t('error.message.failedToUploadCollectionAvt', { err: error || errorUpload }))
        }
    }

    const handleCloseModal = () => {
        onClose()
        onCollectionFollowStepModalClose()
        form.resetFields()
    }

    const removeError = useCallback(() => {
        setIsFormError(false)
    }, [])

    const getText = useCallback(key => t(`editProfile.${key}`), [t])

    const getErrorSocial = useCallback(key => t(`editProfile.${key}Invalid`), [t])

    const validatorUserSocial = (rules, value) => {
        const regex = /^[a-zA-Z0-9-_.]+$/.test(value)
        if (!regex && value) {
            return Promise.reject(getErrorSocial(rules.field))
        } else return Promise.resolve()
    }

    return (
        <Modal
            onCancel={onClose}
            className="create-collection_custom"
            footer={null}
            centered
            visible={visible}
            {...restProps}
        >
            <div className="create-collection_header">
                <span className="create-collection_title">{t('createCollection.title')}</span>
            </div>
            <Form form={form} onFieldsChange={removeError}>
                <div className="create-collection_block">
                    <Form.Item
                        rules={[{ required: true, message: t('createCollection.field_displayimage_rule_required') }]}
                        name="displayImage"
                    >
                        <DisplayImagePicker placeholder={DefaultAvt} />
                    </Form.Item>
                </div>

                <div className="create-collection_block">
                    <Label title={t('createCollection.field_displayname')}>
                        <Form.Item
                            rules={[
                                { required: true, message: t('createCollection.field_displayname_rule_required') },
                                { max: 32, message: t('createCollection.field_displayname_rule_max') }
                            ]}
                            name="displayName"
                        >
                            <InputCustom
                                className="input-custom-bg"
                                placeholder={t('createCollection.field_displayname_placeholder')}
                            />
                        </Form.Item>
                    </Label>
                </div>

                <div className="create-collection_block">
                    <Label title={t('createCollection.field_shorturl')}>
                        <Form.Item
                            rules={[
                                { required: true, message: t('createCollection.field_shorturl_rule_required') },
                                { max: 50, message: t('createCollection.field_shorturl_max_length') },
                                {
                                    pattern: new RegExp('^[a-zA-Z0-9-_.~]*$'),
                                    message: t('createCollection.urlCharacterError')
                                },
                                {
                                    validator: urlValidator
                                }
                            ]}
                            name="shortUrl"
                        >
                            <InputCustom
                                className="input-custom-bg"
                                prefix={<span className="prefix">{t('createCollection.domain')}</span>}
                                placeholder={t('createCollection.field_shorturl_placeholder')}
                            />
                        </Form.Item>
                    </Label>
                </div>

                <div className="create-collection_block">
                    <Label title={t('createCollection.field_tokensymbol')}>
                        <Form.Item
                            name="token_symbol"
                            rules={[
                                { required: true, message: t('createCollection.field_tokensymbol_rule_required') },
                                { max: 10, message: t('createCollection.field_tokensymbol_rule_max') }
                            ]}
                        >
                            <InputCustom
                                className="input-custom-bg"
                                placeholder={t('createCollection.field_tokensymbol_placeholder')}
                            />
                        </Form.Item>
                    </Label>
                </div>

                <div className="create-collection_block">
                    <Label title={t('createNFT.field_royalties')} description={t('createNFT.field_royalties_desc')}>
                        <Form.Item
                            rules={[
                                { required: true, message: t('createNFT.field_royalties_required') },
                                { validator: lessThan30Validator }
                            ]}
                            name="royalty"
                        >
                            <InputCustom
                                onChange={e => onChangeValueInput(e.target.value, 'royalty', 999, 2)}
                                placeholder={t('createNFT.field_royalties_placeholder')}
                            />
                        </Form.Item>
                    </Label>
                </div>

                <div className="create-collection_block">
                    <Label title={t('createCollection.field_description')}>
                        <Form.Item
                            name="description"
                            rules={[{ max: 1000, message: t('createCollection.field_description_rule_max') }]}
                        >
                            <InputCustom
                                type='textarea'
                                className="input-custom-bg"
                                rows={4}
                                placeholder={t('createCollection.field_description_placeholder')}
                            />
                        </Form.Item>
                    </Label>
                </div>

                {/* Social Media */}
                <Col>
                    <div>
                        <h3 className="header-title">{t('createNFT.yourSocialMedia')}</h3>
                    </div>
                    <div className="input-group">
                        <div className="form-list-social">
                            <div className="form-item-social">
                                <p className="label">Twitter</p>
                                <Form.Item
                                    name="twitterName"
                                    rules={[
                                        { max: 1000, message: getText('urlLongError') },
                                        { validator: validatorUserSocial }
                                    ]}
                                >
                                    <InputCustom placeholder={t("editProfile.socialPlaceholder", {socialNetwork: "Twitter"})} />
                                </Form.Item>
                            </div>
                            <div className="form-item-social">
                                <p className="label">Instagram</p>
                                <Form.Item
                                    name="instagramName"
                                    rules={[
                                        { max: 1000, message: getText('urlLongError') },
                                        { validator: validatorUserSocial }
                                    ]}
                                >
                                    <InputCustom placeholder={t("editProfile.socialPlaceholder", {socialNetwork: "Instagram"})} />
                                </Form.Item>
                            </div>
                            <div className="form-item-social">
                                <p className="label">Medium</p>
                                <Form.Item
                                    name="mediumName"
                                    rules={[
                                        { max: 1000, message: getText('urlLongError') },
                                        { validator: validatorUserSocial }
                                    ]}
                                >
                                    <InputCustom placeholder={t("editProfile.socialPlaceholder", {socialNetwork: "Medium"})} />
                                </Form.Item>
                            </div>
                        </div>
                        <div>
                            <p className="label">{t('common.personalSite')}</p>
                            <Form.Item
                                name="personalSite"
                                rules={[
                                    { max: 1000, message: getText('urlLongError') },
                                    {
                                        pattern: new RegExp(
                                            '^(?:(?:http(?:s)?|ftp)://)(?:\\S+(?::(?:\\S)*)?@)?(?:(?:[a-z0-9\u00a1-\uffff](?:-)*)*(?:[a-z0-9\u00a1-\uffff])+)(?:\\.(?:[a-z0-9\u00a1-\uffff](?:-)*)*(?:[a-z0-9\u00a1-\uffff])+)*(?:\\.(?:[a-z0-9\u00a1-\uffff]){2,})(?::(?:\\d){2,5})?(?:/(?:\\S)*)?$'
                                        ),
                                        message: getText('invalidUrl')
                                    }
                                ]}
                            >
                                <InputCustom placeholder={getText('personalSitePlaceholder')} />
                            </Form.Item>
                        </div>
                    </div>
                </Col>
                {/* Social Media */}

                <div className="create-collection_block">
                    <Label
                        title={t('createCollection.field_nsfw')}
                        subDescription={
                            <>
                                {t('createCollection.description_field_nsfw')}{' '}
                                <TooltipCustom title={t('createCollection.tooltip_nsfw')}>
                                    <i className="ri-error-warning-line"></i>
                                </TooltipCustom>
                            </>
                        }
                    >
                        <div>
                            <Form.Item name="nsfw">
                                <CustomSwitch disableLabel={true} />
                            </Form.Item>
                        </div>
                    </Label>
                </div>
                <div className="create-collection_btnGroups">
                    <ButtonCustom
                        color="blue"
                        fullWidth={true}
                        disabled={isFormError}
                        onClick={handleCreateCollection}
                        className="create-collection_btn mr br-16"
                    >
                        {t('createCollection.btn_upload')}
                    </ButtonCustom>
                    <ButtonCustom color="gray" onClick={handleCloseModal} className="create-collection_btn mr br-16">
                        {t('createCollection.btn_cancel')}
                    </ButtonCustom>
                </div>
                {isFormError && (
                    <p className="create-collection-fix-all-error"> {t('createNFT.text_fix_all_error')} </p>
                )}
            </Form>
        </Modal>
    )
}

export default CreateCollectionModal
