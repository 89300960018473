import { createAsyncThunk } from '@reduxjs/toolkit';

import saleNftService from 'service/saleNftService'
import * as message from 'utils/custom-message'
import nftService from 'service/nftService'
import { changeMultipleSellPage, changeOfferPage, changeOpenForOfferPage, changeTradingHistoryFilter, changeTradingHistoryPage, clearNftDetail, goToSales, setMultipleSellFailure, setMultipleSellLoading, setMultipleSellSuccess, setNftDetailFailure, setNftDetailLoading, setNftDetailSuccess, setOfferFailure, setOfferLoading, setOfferSuccess, setTradingHistoryFailure, setTradingHistoryLoading, setTradingHistorySucess, updateNftDetailFailure, updateNftDetailLoading } from 'store/collectible.slice'
import { setGlobalLoading, setCloseGlobalLoading } from 'store/system.slice'
import i18n from 'translation/i18n';

export const getNFTDetail = createAsyncThunk(
    'nft/getNFTDetail',
    async ({ collectionAddress, tokenId }, thunkApi) => {
        const { dispatch } = thunkApi;

        dispatch(setNftDetailLoading());
        dispatch(setGlobalLoading());
        const [res, err] = await nftService.getDetail({ collectionAddress, tokenId })
        dispatch(setCloseGlobalLoading())
        if (res) {
            dispatch(setNftDetailSuccess(res));
        } else if (err) {
            dispatch(setNftDetailFailure(err));
            message.error(err?.message)
        }
    }
);

export const updateNFTDetail = createAsyncThunk(
    'nft/updateNFTDetail',
    async (params, thunkApi) => {
        const { dispatch } = thunkApi;
        dispatch(updateNftDetailLoading());
        const [res, err] = await saleNftService.putOnSale(params)
        if (res) {
            dispatch(setNftDetailLoading());
            message.success(i18n.t('success.message.putNFTOnMarketSuccess'))
        } else if (err) {
            dispatch(updateNftDetailFailure(err));
            message.error(err.response.data.message)
        }
    }
);

export const clearDetail = createAsyncThunk(
    'nft/clearDetail',
    async (_params, thunkApi) => {
        const { dispatch } = thunkApi;
        dispatch(clearNftDetail());
    }
);

export const getTradingHistory = createAsyncThunk(
    'nft/getTradingHistory',
    async (params, thunkApi) => {
        const { dispatch } = thunkApi;

        dispatch(setTradingHistoryLoading());
        const [history, err] = await saleNftService.getTradingHistory(params)
        if (history) {
            dispatch(setTradingHistorySucess(history));
        } else if (err) {
            dispatch(setTradingHistoryFailure(err));
        }
    }
);

export const changeHistoryFilter = createAsyncThunk(
    'nft/changeHistoryFilter',
    async ({value}, thunkApi) => {
        const { dispatch } = thunkApi;
        dispatch(changeTradingHistoryFilter(value));
    }
)

export const changePageHistory = createAsyncThunk(
    'nft/changePageHistory',
    async ({value}, thunkApi) => {
        const { dispatch } = thunkApi;
        dispatch(changeTradingHistoryPage(value));
    }
);

export const changePageOffer = createAsyncThunk(
    'nft/changePageOffer',
    async ({value}, thunkApi) => {
        const { dispatch } = thunkApi;
        dispatch(changeOfferPage(value));
    }
);

export const gotoSalesTab = createAsyncThunk(
    'nft/gotoSalesTab',
    async ({value}, thunkApi) => {
        const { dispatch } = thunkApi;
        dispatch(goToSales(value));
    }
);

export const getOfferList = createAsyncThunk(
    'nft/getOfferList',
    async (params, thunkApi) => {
        const { dispatch } = thunkApi;
        dispatch(setOfferLoading());
        const [offer, err] = await saleNftService.getOffers(params)
        if (offer) {
            dispatch(setOfferSuccess(offer));
        } else if (err) {
            dispatch(setOfferFailure(err));
        }
    }
);

export const changePageOfferOpenBid = createAsyncThunk(
    'nft/changePageOfferOpenBid',
    async ({value}, thunkApi) => {
        const { dispatch } = thunkApi;
        dispatch(changeOpenForOfferPage(value));
    }
);

export const getNftSales = createAsyncThunk(
    'nft/getNftSales',
    async (params, thunkApi) => {
        const { dispatch } = thunkApi;
        dispatch(setMultipleSellLoading());
        const [offer, err] = await saleNftService.getNftSales(params)
        if (offer) {
            dispatch(setMultipleSellSuccess(offer));
        } else if (err) {
            dispatch(setMultipleSellFailure(err));
        }
    }
);

export const changePageMultipleSelling = createAsyncThunk(
    'nft/changePageMultipleSelling',
    async ({value}, thunkApi) => {
        const { dispatch } = thunkApi;
        dispatch(changeMultipleSellPage(value));
    }
);
