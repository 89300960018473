import React, { useEffect, useMemo, useRef, useState } from 'react'
import { Modal } from 'antd'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { useChainId } from 'wagmi'
import { useTranslation } from 'react-i18next'

import * as message from 'utils/custom-message'
import {
    checkApprovedCollection,
    checkUserHasProxy,
    genTokenIdForMainStore,
    handleUpdateRoyaltyNFT,
    handleUserApproveERC20,
    isUserApprovedERC20,
    signPutDataOnSale
} from 'blockchain/utils'
import { CONTRACT_ADDRESS } from 'constants/envs'
import { ERROR_CODE_USER_DENIED_METAMASK, PROCESS_STATUS, PAYMENT_TOKEN } from 'constants/index'
import nftService from 'service/nftService'
import saleNftService from 'service/saleNftService'
import CheckedIcon from 'assets/icons/checked-outline-icon.svg'
import './style.scss'

const FixPriceModal = ({
    onClose,
    data,
    listOfCollections,
    uploadFile,
    previewFile,
    idCollectionSelected,
    ...restProps
}) => {
    const chainId = useChainId();
    const history = useHistory()
    const { t } = useTranslation();

    const [isCreatingNFTStatus, setIsCreatingNFTStatus] = useState(PROCESS_STATUS.NOT_RUN)
    const [isApprovingTokenStatus, setIsApprovingTokenStatus] = useState(PROCESS_STATUS.NOT_RUN)
    const [isApprovedCollectionStatus, setIsApprovedCollectionStatus] = useState(PROCESS_STATUS.NOT_RUN)
    const [isUserRegisteredProxyStatus, setIsUserRegisteredProxyStatus] = useState(PROCESS_STATUS.NOT_RUN)
    const [isWaitingForSignStatus, setIsWaitingForSignStatus] = useState(PROCESS_STATUS.NOT_RUN)
    const [isSettingRoyaltyNFTStatus, setIsSettingRoyaltyNFTStatus] = useState(PROCESS_STATUS.NOT_RUN)
    const { profile, data: userData } = useSelector(state => state.user) || {}

    const isCreatingNft = useRef(false);

    const collection = useMemo(
        () => listOfCollections.find(c => c.address === data.collectionAddress),
        [listOfCollections, data.collectionAddress]
    );

    const checkUserHasProxySC = async () => {
        setIsUserRegisteredProxyStatus(PROCESS_STATUS.PENDING)
        const [, errRegister] = await checkUserHasProxy(
            CONTRACT_ADDRESS[data.networkType].registry,
            profile.walletAddress || userData?.account,
            chainId,
            data.networkType
        )

        if (errRegister) {
            if (errRegister?.code === ERROR_CODE_USER_DENIED_METAMASK) {
                message.error(t('error.message.declinedActionWallet'))
            } else {
                message.error(t('error.message.somethingWentWrong'))
            }
            onClose()
            return false
        }
        setIsUserRegisteredProxyStatus(PROCESS_STATUS.FULLFIL)
        return true
    }

    const checkApproveCollection = async () => {
        setIsApprovedCollectionStatus(PROCESS_STATUS.PENDING)
        const [, err] = await checkApprovedCollection(
            {
                userAddress: profile.walletAddress,
                registryContractAddress: CONTRACT_ADDRESS[data.networkType].registry,
                collectionAddress: data.collectionAddress
            },
            chainId,
            data.networkType
        )
        setIsApprovedCollectionStatus(PROCESS_STATUS.FULLFIL)

        if (err) {
            if (err?.code === ERROR_CODE_USER_DENIED_METAMASK) {
                message.error(t('error.message.declinedActionWallet'))
            } else {
                message.error(t('error.message.somethingWentWrong'))
            }
            onClose()
            return false
        }
        return true
    }

    const handleUserApproveToken = async tokenType => {
        setIsApprovingTokenStatus(PROCESS_STATUS.PENDING)

        const isApproved = await isUserApprovedERC20(
            CONTRACT_ADDRESS[data.networkType].proxy,
            CONTRACT_ADDRESS[data.networkType][tokenType],
            profile.walletAddress,
            chainId,
            data.networkType
        )
        if (isApproved) {
            setIsApprovingTokenStatus(PROCESS_STATUS.FULLFIL)
            return true
        }
        const [, approveError] = await handleUserApproveERC20(
            CONTRACT_ADDRESS[data.networkType].proxy,
            CONTRACT_ADDRESS[data.networkType][tokenType],
            chainId,
            data.networkType,
        )
        if (approveError) {
            if (approveError?.code === ERROR_CODE_USER_DENIED_METAMASK) {
                onClose()
                message.error(t('error.message.declinedActionWallet'))
            }
            return false
        }
        setIsApprovingTokenStatus(PROCESS_STATUS.FULLFIL)
        return true
    }

    const signPutDataOnSaleSC = async putOnSaleParams => {
        setIsWaitingForSignStatus(PROCESS_STATUS.PENDING)
        const [putOnSale, error] = await signPutDataOnSale(putOnSaleParams, chainId)

        if (error) {
            onClose()
            if (error.code === ERROR_CODE_USER_DENIED_METAMASK) {
                return message.error(t('error.message.declinedActionWallet'))
            }
            return message.error(t('error.message.declinedActionWallet'))
        }
        setIsWaitingForSignStatus(PROCESS_STATUS.FULLFIL)
        return putOnSale
    }

    const createNFT = async () => {
        if (isCreatingNft.current) {
            return;
        }
        isCreatingNft.current = true;
        const newData = { ...data }
        const tokenId = genTokenIdForMainStore(profile.walletAddress, newData.numberOfCopies)
        newData.collectionId = collection?.id

        const isCheckUserHasProxySCSuccess = await checkUserHasProxySC()
        if (!isCheckUserHasProxySCSuccess) return

        if (collection?.isImport) {
            const isApproveCollection = await checkApproveCollection()
            if (!isApproveCollection) return
        }

        if (
            data.tokenType !== PAYMENT_TOKEN.BNB &&
            data.tokenType !== PAYMENT_TOKEN.ETH &&
            data.tokenType !== PAYMENT_TOKEN.MATIC
        ) {
            const isHandleUserApproveTokenSuccess = await handleUserApproveToken(data.tokenType)
            if (!isHandleUserApproveTokenSuccess) return
        }

        if (Number(data.royalty) !== Number(collection?.royalty)) {
            setIsSettingRoyaltyNFTStatus(PROCESS_STATUS.PENDING)
            const [, errSetRoyalty] = await handleUpdateRoyaltyNFT(
                {
                    tokenId,
                    collectionAddress: collection?.address,
                    fee: Number(data.royalty),
                    userAddress: profile.walletAddress
                },
                CONTRACT_ADDRESS[data.networkType].setRoyalty,
                chainId,
                collection?.networkType
            )
            if (errSetRoyalty) {
                if (errSetRoyalty?.code === ERROR_CODE_USER_DENIED_METAMASK) {
                    message.error(t('error.message.declinedActionWallet'))
                } else {
                    message.error(t('error.message.declinedActionWallet'))
                }
                onClose()
                return
            }

            setIsSettingRoyaltyNFTStatus(PROCESS_STATUS.FULLFIL)
        }

        const putOnSaleParams = {
            makerAddress: profile.walletAddress,
            collectionAddress: data.collectionAddress,
            price: data.price,
            tokenType: data.tokenType,
            saleType: data.type,
            royalty: newData.royalty,
            quantity: newData.numberOfCopies,
            maxQuantity: newData.numberOfCopies,
            nftType: newData.nftType,
            tokenId,
            networkType: newData.networkType,
            isExternalCollection: collection?.isImport
        }

        const dataPutOnSale = await signPutDataOnSaleSC({ ...putOnSaleParams })
        if (!dataPutOnSale) return

        setIsCreatingNFTStatus(PROCESS_STATUS.PENDING)

        const [createNFTData, errCreateNFT] = await nftService.createNft({ ...newData, ...putOnSaleParams })
        if (errCreateNFT) {
            onClose()
            message.error(t('error.message.creatingCollectibleFailed'), errCreateNFT)
            return
        }

        const [, errorPutDataOnSale] = await saleNftService.putOnSale({
            ...newData,
            ...putOnSaleParams,
            currencyToken: newData.tokenType,
            metadata: dataPutOnSale,
            price: String(newData.price),
            nftId: createNFTData?.id
        })

        if (errorPutDataOnSale) {
            message.error(t('error.message.creatingCollectibleFailed'), errCreateNFT)
            onClose()
            return
        }

        const paramsUploadImage = {
            imgFile: uploadFile,
            nftId: createNFTData?.id
        }

        let previewUrl = ''
        let mainUrl = ''
        if (previewFile) {
            const [resPreview] = await nftService.getUploadPreviewVideo({
                nftId: createNFTData?.id,
                imgFile: previewFile
            })
            previewUrl = resPreview.path
            const previewImgId = resPreview?.path?.replace('input', 'output')
            await nftService.putNftImage({
                imgFile: previewFile,
                nftId: createNFTData?.id,
                uploadUrl: resPreview?.upload_url
            })

            if (uploadFile?.type === 'audio/mpeg') {
                const [resAudio] = await nftService.getMp3PresignUrl({
                    ...paramsUploadImage,
                    previewImgId
                })
                await nftService.putNftImage({
                    ...paramsUploadImage,
                    previewImgId,
                    uploadUrl: resAudio?.upload_url
                })
                mainUrl = resAudio.path
            } else {
                const [resVideo] = await nftService.getUploadVideo({
                    ...paramsUploadImage,
                    previewImgId
                })
                await nftService.putNftImage({
                    ...paramsUploadImage,
                    previewImgId,
                    uploadUrl: resVideo?.upload_url
                })
                mainUrl = resVideo.path
            }
        } else {
            const [resPresign] = await nftService.getUploadGIFImgUrl(paramsUploadImage)

            await nftService.putNftImage({
                nftId: createNFTData?.id,
                imgFile: uploadFile,
                uploadUrl: resPresign?.upload_url
            })
            mainUrl = resPresign.path
        }

        await nftService.updateNftMedia({
            id: createNFTData.id,
            nftUrl: mainUrl,
            nftImagePreview: previewUrl
        })
        setIsCreatingNFTStatus(PROCESS_STATUS.FULLFIL)

        history.push(`/nft/${idCollectionSelected}:${tokenId}`)
        message.success(t('success.message.collectibleCreatedSuccessfully'))
        onClose()
    }

    useEffect(() => {
        if (!data) return
        createNFT()
    }, [data])

    if (!data) {
        return null
    }

    return (
        <Modal className="create-nft-flow_custom" footer={null} closable={false} centered visible={true} {...restProps}>
            <div className="create-nft-flow_header">
                <span className="create-nft-flow_title">{t('createNFT.followStep')}</span>
            </div>
            <div className="create-nft-steps">
                <div className="create-nft-step">
                    <div className="create-nft-loading">
                        {isUserRegisteredProxyStatus === PROCESS_STATUS.PENDING && (
                            <div className="create-nft-step_loading" />
                        )}
                        {isUserRegisteredProxyStatus === PROCESS_STATUS.FULLFIL && (
                            <img className="create-nft-step_icon" src={CheckedIcon} alt="checked-outline-icon" />
                        )}
                    </div>

                    <div className="create-nft-step_content">
                        <span className="create-nft-step_content__title">{t('createNFT.step.init.title')}</span>
                        <span className="create-nft-step_content__desc">
                            {t('createNFT.step.init.desc')}
                        </span>
                    </div>
                </div>

                {collection?.isImport && (
                    <div className="create-nft-step">
                        <div className="create-nft-loading">
                            {isApprovedCollectionStatus === PROCESS_STATUS.PENDING && (
                                <div className="create-nft-step_loading" />
                            )}
                            {isApprovedCollectionStatus === PROCESS_STATUS.FULLFIL && (
                                <img className="create-nft-step_icon" src={CheckedIcon} alt="checked-outline-icon" />
                            )}
                        </div>
                        <div className="create-nft-step_content">
                            <span className="create-nft-step_content__title">{t('createNFT.step.approve.title')}</span>
                            <span className="create-nft-step_content__desc">
                                {t('createNFT.step.approve.desc')}
                            </span>
                        </div>
                    </div>
                )}

                {data.tokenType !== PAYMENT_TOKEN.BNB &&
                    data.tokenType !== PAYMENT_TOKEN.ETH &&
                    data.tokenType !== PAYMENT_TOKEN.MATIC && (
                        <div className="create-nft-step">
                            <div className="create-nft-loading">
                                {isApprovingTokenStatus === PROCESS_STATUS.PENDING && (
                                    <div className="create-nft-step_loading" />
                                )}
                                {isApprovingTokenStatus === PROCESS_STATUS.FULLFIL && (
                                    <img
                                        className="create-nft-step_icon"
                                        src={CheckedIcon}
                                        alt="checked-outline-icon"
                                    />
                                )}
                            </div>

                            <div className="create-nft-step_content">
                                <span className="create-nft-step_content__title">
                                    {t('createNFT.step.approveToken.title', { tokenType: data.tokenType.toUpperCase() })}
                                </span>
                                <span className="create-nft-step_content__desc">
                                    {t('createNFT.step.approveToken.desc', { tokenType: data.tokenType.toUpperCase() })}
                                </span>
                            </div>
                        </div>
                    )}

                {Number(data.royalty) !== Number(collection?.royalty) && (
                    <div className="create-nft-step">
                        <div className="create-nft-loading">
                            {isSettingRoyaltyNFTStatus === PROCESS_STATUS.PENDING && (
                                <div className="create-nft-step_loading" />
                            )}
                            {isSettingRoyaltyNFTStatus === PROCESS_STATUS.FULLFIL && (
                                <img className="create-nft-step_icon" src={CheckedIcon} alt="checked-outline-icon" />
                            )}
                        </div>
                        <div className="create-nft-step_content">
                            <span className="create-nft-step_content__title">{t('createNFT.step.setting.title')}</span>
                            <span className="create-nft-step_content__desc" style={{ marginBottom: '10px' }}>
                                {t('createNFT.step.setting.desc')}
                            </span>
                        </div>
                    </div>
                )}

                <div className="create-nft-step">
                    <div className="create-nft-loading">
                        {isWaitingForSignStatus === PROCESS_STATUS.PENDING && (
                            <div className="create-nft-step_loading" />
                        )}
                        {isWaitingForSignStatus === PROCESS_STATUS.FULLFIL && (
                            <img className="create-nft-step_icon" src={CheckedIcon} alt="checked-outline-icon" />
                        )}
                    </div>
                    <div className="create-nft-step_content">
                        <span className="create-nft-step_content__title">{t('createNFT.step.confirm.title')}</span>
                        <span className="create-nft-step_content__desc">
                            {t('createNFT.step.confirm.desc')}
                        </span>
                    </div>
                </div>

                <div className="create-nft-step">
                    <div className="create-nft-loading">
                        {isCreatingNFTStatus === PROCESS_STATUS.PENDING && <div className="create-nft-step_loading" />}
                        {isCreatingNFTStatus === PROCESS_STATUS.FULLFIL && (
                            <img className="create-nft-step_icon" src={CheckedIcon} alt="checked-outline-icon" />
                        )}
                    </div>

                    <div className="create-nft-step_content">
                        <span className="create-nft-step_content__title">{t('createNFT.step.create.title')}</span>
                        <span className="create-nft-step_content__desc">{t('createNFT.step.create.desc')}</span>
                    </div>
                </div>
            </div>
            <p className="warning">
                {t('createNFT.warning')}
            </p>
        </Modal>
    )
}

export default FixPriceModal
