import React from 'react'
import { Modal } from 'antd'
import { useSelector, useDispatch } from 'react-redux'
import { get } from 'lodash'

import { SvgIcon } from 'components/modules'
import './style.scss'
import { closeWrongNetworkModal } from 'store/modal.slice'


const ConnectWrongNetwork = () => {
    const modal = useSelector(state => state.modal)
    const openModalWrongNetwork = get(modal, 'openModalWrongNetwork')
    const dispatch = useDispatch()

    const handleCancel = () => {
        dispatch(closeWrongNetworkModal())
    }

    return (
        <Modal
            className="wrong-network-modal"
            closable={true}
            centered
            footer={null}
            open={openModalWrongNetwork}
            onCancel={handleCancel}
        >
            <div className="modal-wrapper">
                <div>
                    <SvgIcon name="wrong-network-icon" />
                </div>
                <span className="title">Wrong network</span>
                <span className="description">
                    Looks like you connected to unsupported network.
                    <br />
                    Change network to ETH Mainnet, BSC Mainnet or Polygon Mainnet
                </span>
            </div>
        </Modal>
    )
}

export default ConnectWrongNetwork
