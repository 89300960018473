import React from 'react'
import { Modal } from 'antd'

import './style.scss'
import { useSelector, useDispatch } from 'react-redux'
import { get } from 'lodash'

import { signWallet } from 'blockchain/utils'

import { loginApi } from 'store/login.slice'

import { SvgIcon } from 'components/modules'
import { useAccount } from 'wagmi'
import { openLockMetamaskModal, closeLockMetamaskModal } from 'store/modal.slice'
import { ButtonCustom } from '..'

const ErrorLockMetamask = () => {
    const modal = useSelector(state => state.modal)

    const { address } = useAccount();
    const openModalRejectSign = get(modal, 'openModalLockMetamask')

    const dispatch = useDispatch()

    const onCancel = () => {
        dispatch(closeLockMetamaskModal())
    }

    const handleSignIn = async () => {
        try {
            const signature = await signWallet()
            const credentials = {
                walletAddress: address,
                signature
            }
            dispatch(closeLockMetamaskModal())

            await dispatch(loginApi(credentials))
        } catch (err) {
            dispatch(openLockMetamaskModal())
        }
    }

    return (
        <Modal className="lock-metamask-modal" centered footer={null} open={openModalRejectSign}>
            <div className="modal-wrapper">
                <div>
                    <SvgIcon name="wrong-network-icon" />
                </div>
                <span className="title">Error</span>
                <span className="description">
                    Invalid parameters: must provide an Ethereum address. <a href="/how-it-works">Contact Support</a>
                </span>

                <div className="btn-sign-wallet">
                    <ButtonCustom onClick={handleSignIn} color="blue">
                        <span>Try Again</span>
                    </ButtonCustom>
                    <ButtonCustom onClick={onCancel} color="white" className="btn-disconnect">
                        <span>Cancel</span>
                    </ButtonCustom>
                </div>
            </div>
        </Modal>
    )
}

export default ErrorLockMetamask
