import { Modal } from 'antd'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector, useDispatch } from 'react-redux'
import { useChainId } from 'wagmi'

import { ButtonCustom } from 'components/common'
import * as message from 'utils/custom-message'
import {
    getNFTDetail,
    getTradingHistory,
    changePageOfferOpenBid,
    changePageHistory,
    getOfferList
} from 'store/actions/nft'
import { handleCancelBid } from 'blockchain/utils'
import { ERROR_CODE_USER_DENIED_METAMASK, SOCKET_EVENTS } from 'constants/index'
import { socket } from 'config'
import './style.scss'

const BidCancelModal = ({ onCloseModal, data }) => {
    const { t } = useTranslation()
    const dispatch = useDispatch()
    const [loading, setLoading] = useState(false)
    const chainId = useChainId();
    const { id: userId } = useSelector(state => state.user?.profile)
    const { tokenId, id: nftId, collection } = useSelector(state => state.collectible?.data)

    const onConfirmCancelBid = async () => {
        setLoading(true)
        const [, errorCancelBidMetamask] = await handleCancelBid({ ...data.signatureSale }, chainId)

        if (errorCancelBidMetamask) {
            onCloseModal()
            if (errorCancelBidMetamask?.code === ERROR_CODE_USER_DENIED_METAMASK)
                return message.error(t('error.message.declinedActionWallet'))
            return message.error(
                t('error.message.cannotContinueAction')
            )
        }
    }

    const cancelBidSuccess = () => {
        onCloseModal()
        message.success(t('success.message.bidCanceled'))
        dispatch(getNFTDetail({ collectionAddress: collection.id || collection.address, tokenId }))
        dispatch(changePageOfferOpenBid({ value: 1 }))
        dispatch(changePageHistory({ value: 1 }))
        dispatch(getTradingHistory({ nftId: nftId }))
        dispatch(getOfferList({ nftId: nftId }))
    }

    useEffect(() => {
        if (socket) {
            socket.on(SOCKET_EVENTS.CANCEL_OFFER_OR_BID, res => {
                if (res?.data?.userId === userId && res?.data?.sellHash === data.sellHash) {
                    cancelBidSuccess()
                }
            })
        }
        return () => socket.off(SOCKET_EVENTS.CANCEL_OFFER_OR_BID)
    }, [])

    return (
        <Modal
            visible={true}
            onOk={onCloseModal}
            onCancel={!loading && onCloseModal}
            footer={null}
            closable={false}
            className={'custom-process-modal'}
        >
            <div className="cancel-bid-modal">
                <p>{t('nftDetail.cancelBid')}</p>
                <ButtonCustom fullWidth={true} color="blue" onClick={onCloseModal} disabled={loading}>
                    {t('nftDetail.never')}
                </ButtonCustom>
                <ButtonCustom
                    loading={loading}
                    fullWidth={true}
                    color="white"
                    onClick={onConfirmCancelBid}
                    disabled={loading}
                >
                    {t('nftDetail.cancel')}
                </ButtonCustom>
            </div>
        </Modal>
    )
}

export default BidCancelModal
