import { SERVER_API_ENDPOINT } from 'constants/envs'
import Request from '../request'

const activityService = {
    getDataActivities: async ({ page = 1, limit = 10, activityType, walletAddress, isFollowing }) => {
        // TODO
        try {
            const response = await Request.get(`${SERVER_API_ENDPOINT}/notification`, {
                page,
                limit,
                ...(activityType && { activityType }),
                ...(walletAddress && { walletAddress }),
                ...(isFollowing && { isFollowing })
            })
            return [response.data, null]
        } catch (error) {
            console.error('getDataActivities', error.response)
            return [null, error]
        }
    }
}

export default activityService
