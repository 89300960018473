import { SERVER_API_ENDPOINT } from 'constants/envs'
import Request from '../request'

const categoryService = {
    get: async () => {
        try {
            const response = await Request.get(`${SERVER_API_ENDPOINT}/category/all`)
            return [response.data.categories, null]
        } catch (error) {
            console.error('categoryService', error.response)
            return [null, error]
        }
    }
}

export default categoryService
