import React, { useState, useEffect, useMemo } from 'react'
import BigNumber from 'bignumber.js'
import { useWindowSize } from 'react-use'
import { useTranslation } from 'react-i18next'
import { useSelector, useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import moment from 'moment'
import { Tooltip } from 'antd'
import { useChainId } from 'wagmi'

import { ButtonCustom } from 'components/common'
import CountDown from './CountDown'
import BidModal from './BidModal'
import CancelModal from '../CancelModal'
import BidSuccessModal from './BidSuccessModal'
import { isUserAuthenticated } from 'utils/auth'
import { getPrice } from 'service/getPrice'
import shrinkAddress from 'utils/shrinkAddress'
import * as message from 'utils/custom-message'
import { getNFTDetail, getTradingHistory, getOfferList, changePageHistory, changePageOffer } from 'store/actions/nft'
import { ERROR_CODE_USER_DENIED_METAMASK, TOKEN_ICON } from 'constants/index'
import { handleCancelListingOrder } from 'blockchain/utils'

const Auction = ({ isOwner, signWallet }) => {
    const { t } = useTranslation()
    const history = useHistory()
    const dispatch = useDispatch()
    const { width } = useWindowSize()
    const chainId = useChainId();
    const { profile } = useSelector(state => state.user)
    const { bestBidSale, id, nftSales, collection, tokenId } = useSelector(state => state.collectible?.data)
    const [exchangeRate, setExchangeRate] = useState(0)
    const [isOpenBidModal, setIsOpenBidModal] = useState(false)
    const [isOpenCancelModal, setIsOpenCancelModal] = useState(false)
    const [isOpenBidSuccessModal, setIsOpenBidSuccessModal] = useState(false)
    const [isCancelingAuction, setIsCancelingAuction] = useState(false)

    const nftSale = useMemo(() => {
        if (nftSales && nftSales[0]) return nftSales[0]
    }, [nftSales])

    const isStarted = Boolean(moment().unix() >= nftSale.startDate)
    const minimumBid = useMemo(() => Number(nftSale.price), [nftSale.price])

    const openBidSuccessModal = () => {
        setIsOpenBidModal(false)
        setIsOpenBidSuccessModal(true)
    }

    const onClickCancelSale = async () => {
        if (!isUserAuthenticated()) {
            const res = await signWallet()
            if (res === false) return
        }
        setIsOpenCancelModal(true)
    }
    const onClickPlaceBid = async () => {
        if (!profile?.id) {
            return history.push({ pathname: '/connect', state: { from: 'collectible' } })
        }
        if (!isUserAuthenticated()) {
            const res = await signWallet()
            if (res === false) return
        }
        setIsOpenBidModal(true)
    }

    const onCancelAuction = async () => {
        setIsCancelingAuction(true)
        const [, error] = await handleCancelListingOrder(nftSale.signatureSale, chainId)

        if (error) {
            setIsOpenCancelModal(false)
            if (error?.code === ERROR_CODE_USER_DENIED_METAMASK)
                return message.error(t('error.message.declinedActionWallet'))

            return message.error(t('error.message.buyerDoesNotHaveEnoughMoney'))
        }

        message.success(t('success.message.cancelListingSuccessfully'))
        setIsOpenCancelModal(false)
        setIsCancelingAuction(false)
        dispatch(getNFTDetail({ collectionAddress: collection.id || collection.address, tokenId }))
        dispatch(changePageOffer({value: 1}))
        dispatch(changePageHistory({value: 1}))
        await dispatch(getTradingHistory({ nftId: id }))
        await dispatch(getOfferList({ nftId: id }))
    }

    useEffect(() => {
        const getRate = async () => {
            const rate = await getPrice(nftSale.currencyToken?.toUpperCase())
            setExchangeRate(rate)
        }
        getRate()
    }, [nftSale.currencyToken])

    return (
        <div className="bid">
            <div className="bid-auction">
                <div className="bid-left">
                    <p>
                        {t(bestBidSale ? 'nftDetail.highestBidBy' : 'nftDetail.minBidBy')}{' '}
                        <span
                            className="cursor-pointer"
                            onClick={() =>
                                history.push(
                                    bestBidSale?.user?.shortUrl
                                        ? `/${bestBidSale?.user?.shortUrl}`
                                        : `/user/${bestBidSale?.user?.walletAddress}`
                                )
                            }
                        >
                            {bestBidSale ? (
                                <Tooltip
                                    title={
                                        bestBidSale?.user?.username
                                            ? bestBidSale?.user?.username
                                            : bestBidSale?.user?.walletAddress
                                    }
                                >
                                    {bestBidSale?.user?.username
                                        ? bestBidSale?.user?.username
                                        : shrinkAddress(bestBidSale?.user?.walletAddress)}
                                </Tooltip>
                            ) : null}
                        </span>
                    </p>
                    <div className="bid-info">
                        {width > 768 && (
                            <Tooltip title={nftSale.currencyToken?.toUpperCase()}>
                                <img className="token-icon" src={TOKEN_ICON[nftSale.currencyToken]} alt="Token Icon" />
                            </Tooltip>
                        )}

                        <div className="price-info">
                            <p>
                                {`${BigNumber(bestBidSale ? bestBidSale.price : minimumBid)
                                    .decimalPlaces(6)
                                    .toFormat()
                                    .toString()} ${nftSale.currencyToken?.toUpperCase()}`}{' '}
                            </p>
                            <p>
                                &nbsp;
                                {`/ $${BigNumber((bestBidSale ? bestBidSale.price : minimumBid) * exchangeRate)
                                    .decimalPlaces(2)
                                    .toFormat()
                                    .toString()}`}
                            </p>
                        </div>
                    </div>
                </div>
                {isStarted ? (
                    <div className="bid-right">
                        <p>{t('nftDetail.auctionEndsIn')}</p>
                        <CountDown till={nftSale.expireDate} />
                    </div>
                ) : (
                    <div className="bid-right">
                        <p>{t('nftDetail.auctionStartsIn')}</p>
                        <CountDown till={nftSale.startDate} />
                    </div>
                )}
            </div>
            {isOwner ? (
                <div className="action-buy">
                    <ButtonCustom
                        fullWidth
                        size="lg"
                        isBuble
                        color="blue"
                        className="action-btn br-12"
                        onClick={onClickCancelSale}
                    >
                        {t('nftDetail.cancel')}
                    </ButtonCustom>
                </div>
            ) : (
                isStarted && (
                    <div className="action-buy">
                        <ButtonCustom
                            fullWidth
                            size="lg"
                            isBuble
                            color="blue"
                            className="action-btn br-12"
                            onClick={onClickPlaceBid}
                        >
                            {t('nftDetail.placeABid')}
                        </ButtonCustom>
                    </div>
                )
            )}

            <CancelModal
                isOpen={isOpenCancelModal}
                onCloseModal={() => setIsOpenCancelModal(false)}
                onConfirmCancelSale={onCancelAuction}
                isAuction={true}
                isCanceling={isCancelingAuction}
            />

            {isOpenBidModal && (
                <BidModal
                    isOpen={isOpenBidModal}
                    onCloseModal={() => setIsOpenBidModal(false)}
                    openBidSuccessModal={openBidSuccessModal}
                    exchangeRate={exchangeRate}
                    minimumBid={minimumBid}
                    previousBid={bestBidSale}
                />
            )}
            {isOpenBidSuccessModal && (
                <BidSuccessModal isOpen={isOpenBidSuccessModal} onCloseModal={() => setIsOpenBidSuccessModal(false)} />
            )}
        </div>
    )
}

export default Auction
