import React from 'react'
import { MAIN_TITLE } from 'constants/index'
import { Helmet } from 'react-helmet'
import DefaultLayout from '../../components/layout/default-layout'

import './style.scss'

const Market = () => {
    return (
        <DefaultLayout>
            <Helmet>
                <meta charSet="utf-8" />
                <title>{`${MAIN_TITLE} - Marketplace`}</title>
                <meta name="description" content={`${MAIN_TITLE} - Martketplace`} />
            </Helmet>
            <div className="page-market">
                <h1>market page</h1>
            </div>
        </DefaultLayout>
    )
}

export default Market
