import React from "react";
import { Dropdown, Menu } from "antd";
import {
  FacebookShareButton,
  TelegramShareButton,
  TwitterShareButton,
} from "react-share";
import { useTranslation } from "react-i18next";
import ShareIcon from "assets/icons/ShareIcon";

import "./styles.scss";
import ButtonCustom from "../button";
import LinkShare from "assets/images/link-share.png";
import TelegramShare from "assets/images/tele-share.png";
import TwitterShare from "assets/images/twitter-share.png";

import copy from "copy-to-clipboard";
import * as message from "utils/custom-message";

const ShareCustom = ({ content, url, title = "", horizontalMode = false }) => {
  const { t } = useTranslation();

  const copyAddressToClipboard = () => {
    copy(url);
    message.success(t("common.copySuccess"));
  };

  const dropdownShare = () => {
    return (
      <Menu
        mode={"horizontal"}
        className={`menu-icon menu-share ${
          horizontalMode ? "horizontal-mode" : ""
        }`}
      >
        <Menu.Item>
          <TwitterShareButton url={content}>
            {/* <i className="ri-twitter-x-line icon" /> */}
            <img src={TwitterShare} />
          </TwitterShareButton>
        </Menu.Item>
        {/* <Menu.Item>
          <FacebookShareButton url={url}>
            <i className="ri-facebook-fill icon" />
          </FacebookShareButton>
        </Menu.Item> */}
        <Menu.Item>
          <TelegramShareButton url={content}>
            {/* <i className="ri-telegram-fill icon" /> */}
            <img src={TelegramShare} />
          </TelegramShareButton>
        </Menu.Item>
        <Menu.Item>
          {/* <FacebookShareButton url={url}> */}
          <div onClick={copyAddressToClipboard}>
            <img src={LinkShare} />
          </div>
          {/* </FacebookShareButton> */}
        </Menu.Item>
      </Menu>
    );
  };

  return (
    <Dropdown
      trigger={["click"]}
      overlay={dropdownShare}
      placement="bottomCenter"
    >
      {/* <div className="cursor-pointer">
                <i className="ri-share-line"></i>
            </div> */}
      <ButtonCustom
        style={{ width: "auto" }}
        color={"white"}
        variant="outline-sub"
        className="user-info-btn"
      >
        <ShareIcon />
        <span>{t("common.share")}</span>
      </ButtonCustom>
    </Dropdown>
  );
};

export default ShareCustom;
