import React, { useCallback, useContext, useEffect, useState, useMemo } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { Card, Avatar, Tooltip } from 'antd'
import { useTranslation } from 'react-i18next'
import { Link, useHistory } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-use'
import Modal from 'antd/lib/modal/Modal'
import moment from 'moment'

import { SvgIcon } from '../index'
import { ButtonCustom, TooltipCustom } from 'components/common'
import { convertImage, convertImageAvatar } from 'utils/image'
import { roundNumber, shortenAddress, shortenNameNoti } from 'utils/func'
import HeartIcon from 'assets/icons/heart-icon-small.svg'
import FullHeartIcon from 'assets/icons/full-heart-icon.svg'
import PlayGroupIcon from 'assets/icons/play-group-icon.svg'
import Mp3Icon from 'assets/icons/music-group-icon.svg'
import { FILE_EXTENSION, NETWORK_ICON, NFT_TYPE, SALE_TYPE } from 'constants/index'
import { isUserAuthenticated } from 'utils/auth'
import * as message from 'utils/custom-message'
import { signWallet } from 'blockchain/utils'
import userService from 'service/userService'
import { useAuth } from 'hooks/useAuth'
import nftService from 'service/nftService'
import CustomVerifyIcon from 'components/common/custom-verify-icon'
import CountDown from 'pages/nft-detail/elements/Overview/Auction/CountDown'
import { exportFileFormat } from 'utils/file'
import { ThemeContext } from '../../../ThemeProvider'
import './style.scss'
import { setLoginFailure, setLoginLoading, setLoginSuccess } from 'store/login.slice'

const CardItem = ({ data, className, disable, isPreview, walletAddress, verification = false }) => {
    const { theme } = useContext(ThemeContext)
    const history = useHistory()
    const location = useLocation()
    const { t } = useTranslation()
    const dispatch = useDispatch()
    const {
        id,
        nftUrl,
        creator,
        owners,
        collection,
        nftImagePreview,
        tokenId,
        title,
        liked,
        nftSales,
        minFixPrice,
        bestFixPrice,
        bestOfferSale,
        bestBidSale,
        maxQuantity,
        likes
    } = data || {}

    const { profile } = useSelector(state => state.user)
    const userProfile = useSelector(state => state.user)
    const [counter, setCounter] = useState(null)
    const [isStartTimeAfterCurrent, setIsStartTimeAfterCurrent] = useState(false)
    const [currentTime, setCurrentTime] = useState(moment().unix())
    const [isLike, setIsLike] = useState(liked)
    const [countLike, setCountLike] = useState(0)
    const [, handleSignWallet] = useAuth()
    const [visibleModal, setVisibleModal] = useState(false)

    const auctionRunning = useMemo(() => {
        if (nftSales.length) {
            return nftSales.find(n => n.type === SALE_TYPE.ENGLISH_AUCTION)
        }
    }, [nftSales])

    useEffect(() => {
        if (counter) {
            const timer = setInterval(() => {
                if (counter - currentTime > 0) {
                    setCounter(counter - 1)
                }
                if (counter - currentTime === 0) {
                    if (!isStartTimeAfterCurrent) {
                        setCounter(0)
                    } else {
                        setCounter(0)
                        setIsStartTimeAfterCurrent(false)
                    }
                }
            }, 1000)

            return () => {
                clearInterval(timer)
            }
        }
    }, [counter, isStartTimeAfterCurrent, currentTime])

    useEffect(() => {
        if (auctionRunning && auctionRunning?.expireDate && auctionRunning?.expireDate - currentTime > 0) {
            if (Number(auctionRunning?.startDate) > currentTime) {
                setIsStartTimeAfterCurrent(true)
                setCounter(Number(auctionRunning?.startDate))
                return
            }
            setIsStartTimeAfterCurrent(false)
            setCounter(Number(auctionRunning?.expireDate))
        } else {
            setCounter(null)
        }
    }, [auctionRunning, currentTime, isStartTimeAfterCurrent])

    useEffect(() => {
        setCurrentTime(moment().unix())
    }, [auctionRunning, isStartTimeAfterCurrent])

    useEffect(() => {
        setCountLike(likes)
    }, [likes])

    const likeNft = async () => {
        const [res, err] = await nftService.likeNft({ id })
        if (err) {
            return message.error(t(`error.message.${err.message}`))
        } else {
            setIsLike(!isLike)
            return setCountLike(res.totalLikes)
        }
    }
    const handleLoginAndLikeItem = async credentials => {
        const [data, error] = await userService.login(credentials)

        if (data && data?.accessToken) {
            localStorage.setItem('accessToken', data?.accessToken)
            localStorage.setItem('userId', data?.userId)

            dispatch(setLoginSuccess(data));
            return await likeNft()
        }

        if (error) {
            dispatch(setLoginFailure(error.response.status));
        }
    }
    const handleSignIn = async () => {
        try {
            const signature = await signWallet()

            const credentials = {
                walletAddress: userProfile?.profile?.walletAddress,
                signature
            }

            dispatch(setLoginLoading());
            setVisibleModal(false)
            return await handleLoginAndLikeItem(credentials)
        } catch (err) {
            setVisibleModal(true)
        }
    }

    const handleClickCollection = e => {
        e.stopPropagation()
        history.push(`/collection/${collection?.shortUrl || collection?.id || collection?.address}`)
    }

    const handleLikeNft = async e => {
        e.preventDefault()
        e.stopPropagation()
        if (!profile?.id) {
            if (location?.pathname?.includes('user')) {
                return history.push({ pathname: '/connect', state: { from: '/user' } })
            } else if (location?.pathname?.includes('collection')) {
                return history.push({ pathname: '/connect', state: { from: '/collection' } })
            } else if (location?.pathname?.length >= 2) {
                return history.push({ pathname: '/connect', state: { from: '/:customUrl' } })
            } else {
                return history.push({ pathname: '/connect', state: { from: '/' } })
            }
        }
        if (!isUserAuthenticated()) {
            const res = await handleSignWallet()
            if (res === false) return
        }
        await likeNft()
    }
    const renderPrice = useCallback(() => {
        if (collection.type === NFT_TYPE.ERC721) {
            if (nftSales[0]) {
                if (nftSales[0].type === SALE_TYPE.OPEN_FOR_OFFER || nftSales[0].type === SALE_TYPE.ENGLISH_AUCTION) {
                    if (bestBidSale || bestOfferSale) {
                        return (
                            <div className="card-item-custom-content_footer__highest-price">
                                <span className="card-item-custom-content_footer__highest-bid">{t('collection.highestBid')}</span>
                                <span
                                    className={`card-item-custom-content_footer__price ${theme === 'dark-theme' ? 'card-item-custom-content_footer__price___dark' : ''
                                        }`}
                                >
                                    {roundNumber(bestBidSale?.price || bestOfferSale?.price)}{' '}
                                    {(bestBidSale?.currencyToken || bestOfferSale.currencyToken).toUpperCase()}
                                    <Tooltip title={'Available for purchase'}>
                                        <span className="card-item-custom-content_footer__number"> 1/1</span>
                                    </Tooltip>
                                </span>
                            </div>
                        )
                    }
                    if (nftSales[0].type === SALE_TYPE.ENGLISH_AUCTION) {
                        return (
                            <div className="card-item-custom-content_footer__highest-price">
                                <span className="card-item-custom-content_footer__highest-bid">{t('collection.minimumBid')}</span>
                                <span
                                    className={`card-item-custom-content_footer__price ${theme === 'dark-theme' ? 'card-item-custom-content_footer__price___dark' : ''
                                        }`}
                                >
                                    {roundNumber(nftSales[0].price)} {nftSales[0].currencyToken.toUpperCase()}
                                    <Tooltip title={'Available for purchase'}>
                                        <span className="card-item-custom-content_footer__number"> 1/1</span>
                                    </Tooltip>
                                </span>
                            </div>
                        )
                    }
                    if (nftSales[0].type === SALE_TYPE.OPEN_FOR_OFFER) {
                        return (
                            <>
                                <div className="line-empty" />
                                <span className="card-item-custom-content_footer__make-an-offer">{t('collection.makeAnOffer')}</span>
                            </>
                        )
                    }
                }
                return (
                    <>
                        <div className='price-container'>
                            <span className="card-item-custom-content_footer__block-price">{t('nftDetail.floor')}</span>
                            <span
                                className={`card-item-custom-content_footer__price ${theme === 'dark-theme' ? 'card-item-custom-content_footer__price___dark' : ''
                                    }`}
                            >
                                {roundNumber(minFixPrice)} {'$'}{' '}
                                {/* <Tooltip title={'Available for purchase'}>
                                    <span className="card-item-custom-content_footer__number"> 1/1</span>
                                </Tooltip> */}
                            </span>
                        </div>
                        <div className='price-container'>
                            <span className="card-item-custom-content_footer__block-price">{t('nftDetail.lastPrice')}</span>
                            <span
                                className={`card-item-custom-content_footer__price ${theme === 'dark-theme' ? 'card-item-custom-content_footer__price___dark' : ''
                                    }`}
                            >
                                {/** TODO: add last price */}
                                {roundNumber(bestFixPrice?.price)} {bestFixPrice?.currencyToken.toUpperCase()}{' '}
                            </span>
                        </div>
                    </>
                )
            }
            return (
                <>
                    <div className="line-empty" />
                    <span className="card-item-custom-content_footer__notForSale">{t('common.notForSale')}</span>
                </>
            )
        }
        if (collection.type === NFT_TYPE.ERC1155) {
            if (
                nftSales.some(
                    nftSale => nftSale.type === SALE_TYPE.OPEN_FOR_OFFER || nftSale.type === SALE_TYPE.FIX_PRICE
                )
            ) {
                return (
                    <>
                        <div className="line-empty" />
                        <span className="card-item-custom-content_footer__make-an-offer">
                            {t('collection.makeAnOfferOrBuy')}{' '}
                            <Tooltip title={'Available for purchase'}>
                                <span className="card-item-custom-content_footer__make-an-offer__available">
                                    {nftSales.reduce((prev, cur) => prev + cur.quantity, 0)}/{maxQuantity}
                                </span>
                            </Tooltip>
                        </span>
                    </>
                )
            }
            return (
                <>
                    <div className="line-empty" />
                    <span className="card-item-custom-content_footer__notForSale">{t('common.notForSale')}</span>
                </>
            )
        }
    }, [t, bestFixPrice, bestOfferSale, collection.type, nftSales, theme, bestBidSale, maxQuantity])

    const renderTimeLeft = useCallback(() => {
        if (!auctionRunning) return null
        if (isStartTimeAfterCurrent) {
            return (
                <div className="card-item-custom-content_auction">
                    <div>
                        <SvgIcon name="time-restore-icon" />
                        <span className="card-item-custom-content_auction__left">{t('times.startIn')}</span>
                    </div>
                    <span className="card-item-custom-content_auction__counter-container">
                        <CountDown isExtraSmall={true} till={auctionRunning?.startDate} />
                    </span>
                </div>
            )
        }

        return (
            <div className="card-item-custom-content_auction">
                <div className="d-flex">
                    <SvgIcon name="time-restore-icon" />
                    <span className="card-item-custom-content_auction__left">{t('times.left')}</span>
                </div>
                <span className="card-item-custom-content_auction__counter-container">
                    <CountDown isExtraSmall={true} till={auctionRunning?.expireDate} />
                </span>
            </div>
        )
    }, [isStartTimeAfterCurrent, t, auctionRunning])

    const handleClickUser = (e, ownerNft) => {
        if (ownerNft === walletAddress) {
            e.preventDefault()
            return
        }
        history.push(ownerNft?.shortUrl ? `/${ownerNft?.shortUrl}` : `/user/${ownerNft?.walletAddress}`)
    }

    if (!data) {
        return null
    }

    const routerCollection = id => {
        if (!disable) history.push(`/nft/${collection?.id || collection?.address}:${tokenId}`)
    }

    return (
        <Card
            className={classNames({
                'card-item-custom': true,
                [className]: !!className,
                'card-item-dark': theme === 'dark-theme'
            })}
        >
            <div className="card-item-custom-header">
                {/* <div className="card-item-owner-creator-wrapper">
                    <div className="card-item-owner">
                        {owners?.length === 1 ? (
                            <>
                                <TooltipCustom
                                    className="card-item-custom-usersGroupItem"
                                    title={
                                        <>
                                            <span className="tooltip-customer-title__role">{t('card.owner')}:&nbsp;</span>
                                            <span>
                                                {owners[0]?.username
                                                    ? owners[0].username
                                                    : shortenAddress(owners[0]?.walletAddress, 7, 3)}
                                            </span>
                                        </>
                                    }
                                    placement="top"
                                >
                                    <Link
                                        to={
                                            (!disable || isPreview) &&
                                            (owners[0]?.walletAddress
                                                ? owners[0]?.shortUrl
                                                    ? `/user/${owners[0]?.shortUrl}`
                                                    : `/user/${owners[0]?.walletAddress}`
                                                : creator?.shortUrl
                                                    ? `/user/${creator?.shortUrl}`
                                                    : `/user/${creator?.walletAddress}`)
                                        }
                                    >
                                        <div className="card-item-custom-users_avatar_wrapper">
                                            <div className="card-item-custom-users_avatar_wrapper-verify">
                                                <Avatar
                                                    className="card-item-custom-users_avatar"
                                                    alt="example"
                                                    src={convertImageAvatar(owners[0]?.avatarImg)}
                                                />
                                                {owners[0]?.isVerify && (
                                                    <CustomVerifyIcon className="card-item-custom-users_icon__last" />
                                                )}
                                            </div>
                                        </div>
                                    </Link>
                                </TooltipCustom>
                            </>
                        ) : (
                            <Avatar.Group
                                maxCount={2}
                                maxStyle={{
                                    color: '#f56a00',
                                    backgroundColor: '#fde3cf',
                                    cursor: 'pointer',
                                    marginLeft: 0
                                }}
                            >
                                {owners?.map((ownerNft, index) => {
                                    return (
                                        <TooltipCustom
                                            key={index}
                                            className="card-item-custom-usersGroupItem"
                                            title={
                                                <>
                                                    <span className="tooltip-customer-title__role">{t('card.owner')}:&nbsp;</span>
                                                    <span>
                                                        {ownerNft?.username
                                                            ? ownerNft?.username
                                                            : shortenAddress(ownerNft?.walletAddress, 7, 3)}
                                                    </span>
                                                </>
                                            }
                                            placement="top"
                                        >
                                            <Avatar
                                                className="card-item-custom-users_avatar"
                                                alt="example"
                                                src={convertImageAvatar(ownerNft?.avatarImg)}
                                                onClick={e => handleClickUser(e, (!disable || isPreview) && ownerNft)}
                                            />
                                            <div className="card-item-custom-users_avatar_wrapper">
                                                {ownerNft?.isVerify && (
                                                    <CustomVerifyIcon className="card-item-custom-users_icon__last" />
                                                )}
                                            </div>
                                        </TooltipCustom>
                                    )
                                })}
                            </Avatar.Group>
                        )}
                    </div>
                    <div className="card-item-creator">
                        {creator && (
                            <>
                                <TooltipCustom
                                    className="card-item-custom-usersGroupItem"
                                    title={
                                        <>
                                            <span className="tooltip-customer-title__role">{t('card.creator')}:&nbsp;</span>
                                            <span>
                                                {creator?.username || shortenAddress(creator?.walletAddress, 7, 3)}
                                            </span>
                                        </>
                                    }
                                    placement="top"
                                >
                                    <Link
                                        to={
                                            (!disable || isPreview) &&
                                            (creator?.shortUrl
                                                ? `/user/${creator?.shortUrl}`
                                                : `/user/${creator?.walletAddress}`)
                                        }
                                    >
                                        <div className="card-item-custom-users_avatar_wrapper">
                                            <div className="card-item-custom-users_avatar_wrapper-verify">
                                                <Avatar
                                                    className="card-item-custom-users_avatar"
                                                    alt="example"
                                                    src={convertImageAvatar(creator?.avatarImg)}
                                                />
                                                {creator?.isVerify && (
                                                    <CustomVerifyIcon className="card-item-custom-users_icon__last" />
                                                )}
                                            </div>
                                        </div>
                                    </Link>
                                </TooltipCustom>
                            </>
                        )}
                    </div>
                </div> */}
                <div className="card-item-custom-header_avatar">
                    <Link to={!disable && `/nft/${collection?.id || collection?.address}:${tokenId}`}>
                        <Avatar
                            className="card-item-custom-avatar"
                            alt="item"
                            src={
                                exportFileFormat(nftUrl) === FILE_EXTENSION.MP4 ||
                                    exportFileFormat(nftUrl) === FILE_EXTENSION.MPEG
                                    ? convertImage(nftImagePreview)
                                    : convertImage(nftImagePreview || nftUrl)
                            }
                        />
                    </Link>
                    {exportFileFormat(nftUrl) === FILE_EXTENSION.MP4 && (
                        <div className="card-item-custom-header_videoIcon">
                            <img src={PlayGroupIcon} alt="PlayGroupIcon" />
                        </div>
                    )}
                    {exportFileFormat(nftUrl) === FILE_EXTENSION.MPEG && (
                        <div className="card-item-custom-header_videoIcon">
                            <img src={Mp3Icon} alt="Mp3Icon" />
                        </div>
                    )}

                    <div className="card-item-custom-header-like" onClick={e => !disable && handleLikeNft(e)}>
                        {isLike && !disable ? (
                            <img src={FullHeartIcon} className="heart-icon" alt="full-heart-icon" />
                        ) : (
                            <img src={HeartIcon} className="heart-icon" alt="heart-icon" />
                        )}
                        {!!Number(countLike) && (
                            <span className="card-item-custom-header-like__count">{countLike}</span>
                        )}
                    </div>
                    {renderTimeLeft()}
                </div>
            </div>
            <div onClick={() => routerCollection(id)} className="cursor-pointer">
                <div className="card-item-custom-content">
                    {title && (
                        <div className="card-item-custom-content_title">
                            <span
                                className={`card-item-custom-content_title__title ${theme === 'dark-theme' ? 'card-item-custom-content_title__title___dark' : ''
                                    }`}
                            >
                                {title}
                            </span>

                            {/* <TooltipCustom title={collection.networkType}>
                                <Avatar src={NETWORK_ICON[collection.networkType]} />
                            </TooltipCustom> */}
                        </div>
                    )}
                    {/* <div className="card-item-custom-content_header">
                        <div
                            className={`card-item-custom-users ${!!auctionRunning && 'card-item-custom-users-resize'}`}
                        >
                            <div className="card-item-custom-usersGroup">
                                {collection && (
                                    <TooltipCustom
                                        className="card-item-custom-usersGroupItem"
                                        title={
                                            <>
                                                <span className="tooltip-customer-title__role">{t('card.collection')}:&nbsp;</span>
                                                <span>{collection?.title || collection?.name}</span>
                                            </>
                                        }
                                        overlayInnerStyle={{
                                            whiteSpace: 'pre-wrap',
                                            width: '100%',
                                            overFlow: 'hidden'
                                        }}
                                    >
                                        <div onClick={handleClickCollection}>
                                            {' '}
                                            <div className="card-item-custom-users_avatar_wrapper">
                                                <Avatar
                                                    className="card-item-custom-users_avatar"
                                                    alt="example"
                                                    src={convertImage(collection?.bannerUrl)}
                                                />
                                                {collection?.isVerify || verification ? (
                                                    <CustomVerifyIcon className="card-item-custom-users_verify" />
                                                ) : null}
                                                <div className={`name ${theme === 'dark-theme' ? 'name-dark' : ''}`}>
                                                    {shortenNameNoti(collection?.title || collection?.name, 30)}
                                                </div>
                                            </div>
                                        </div>
                                    </TooltipCustom>
                                )}
                            </div>
                        </div>
                    </div> */}
                    <div className="card-item-custom-content_footer">
                        <div className="card-item-custom-content_footer__block right">{renderPrice()}</div>
                    </div>
                </div>
            </div>

            {/* {collection.type === NFT_TYPE.ERC1155 && <div className="card-item-custom-content_multi-border"></div>} */}

            <Modal className="reject-sign-modal" centered footer={null} open={visibleModal}>
                <div className="modal-wrapper">
                    <div>
                        <SvgIcon name="wrong-network-icon" />
                    </div>
                    <span className="title">{t('error.error')}</span>
                    <span className="description">
                        {t('error.message.rejectedRequest')}{' '}
                        <a href="/how-it-works">{t('common.contactSupport')}</a>
                    </span>

                    <div className="btn-sign-wallet">
                        <ButtonCustom onClick={handleSignIn} color="blue">
                            <span>{t('editProfile.tryAgain')}</span>
                        </ButtonCustom>
                        <ButtonCustom onClick={() => setVisibleModal(false)} color="white" className="btn-disconnect">
                            <span>{t('common.cancel')}</span>
                        </ButtonCustom>
                    </div>
                </div>
            </Modal>
        </Card>
    )
}

CardItem.propTypes = {
    data: PropTypes.object.isRequired,
    className: PropTypes.string,
    disable: PropTypes.bool,
    isPreview: PropTypes.bool
}

export default CardItem
