import React from 'react'
import moment from 'moment'
import PropTypes from 'prop-types'
import FlipCountdown from 'react-flip-countdown-tony-nguyen'
import './style.scss'

const CountDown = ({ till, isSmall = false, isExtraSmall = false }) => {
    return (
        <div
            className={`countdown-container ${isSmall && 'countdown-container-small'} ${
                isExtraSmall && 'countdown-container-extra-small'
            }`}
        >
            <FlipCountdown
                size="large"
                dayTitle={' '}
                hourTitle={' '}
                secondTitle={' '}
                minuteTitle={' '}
                monthTitle={' '}
                hideMonth={moment().diff(moment.unix(till), 'months') >= 0}
                hideYear
                hideDay={moment().diff(moment.unix(till), 'days') >= 0}
                endAtZero
                endAt={moment.unix(till).format('YYYY-MM-DD HH:mm:ss')} // Date/Time
            />
        </div>
    )
}

CountDown.propTypes = {
    till: PropTypes.string
}

export default CountDown
