import env from 'env/index'
import { NETWORK_TYPES } from '.'

export const SERVER_DOMAIN = env.metadata.server.url
export const SERVER_API_ENDPOINT = env.metadata.server.urlV1
export const AWS_API_ENDPOINT = env.metadata.server.gatewayUrl
export const AWS_CLOUDFRONT_ENDPOINT = env.metadata.server.cloudfrontUrl

export const NETWORK_ID_SUPPORT_ADDRESS = {
    [env.metadata.networks.bsc.id]: env.metadata.addresses.bsc,
    [env.metadata.networks.polygon.id]: env.metadata.addresses.polygon,
    [env.metadata.networks.ethereum.id]: env.metadata.addresses.ethereum
}

export const NETWORK_ID_TYPE = {
    [env.metadata.networks.bsc.id]: NETWORK_TYPES.bsc,
    [env.metadata.networks.polygon.id]: NETWORK_TYPES.polygon,
    [env.metadata.networks.ethereum.id]: NETWORK_TYPES.ethereum
}

export const NETWORKS_SUPPORT = [
    env.metadata.networks.bsc.id,
    env.metadata.networks.polygon.id,
    env.metadata.networks.ethereum.id
]

export const CONTRACT_ADDRESS = { ...env.metadata.addresses }

export const NETWORKS = { ...env.metadata.networks }
