import React, { useEffect, useState } from 'react'
import { convertImage } from 'utils/image'
import { FILE_EXTENSION } from 'constants/index'
import nftService from 'service/nftService'
import MusicIcon from 'assets/icons/music-icon.svg'
import VideoPlayIcon from 'assets/icons/video-play-icon.svg'
import LoadingIcon from 'assets/icons/loading-icon.svg'
import { exportFileFormat } from 'utils/file'

const SearchItemImage = ({ item }) => {
    const { metadataUrl, tokenId, nftImagePreview, nftUrl } = item
    const [imageUrlMetadata, setImageUrlMetadata] = useState(null)
    const [isDoneFetchImageUrlMetadata, setIsDoneFetchImageUrlMetadata] = useState(false)
    const fileExtension = exportFileFormat(nftUrl)
    useEffect(() => {
        const fetchImageUrlFromMetadata = async metadataUrl => {
            const fullMetaDataUrl = `${metadataUrl}/${tokenId}`

            const [res] = await nftService.getImageUrlFromMetadata(fullMetaDataUrl)
            setIsDoneFetchImageUrlMetadata(true)
            if (res) {
                setImageUrlMetadata(res)
            }
        }

        if (metadataUrl) {
            fetchImageUrlFromMetadata(metadataUrl)
        }
    }, [metadataUrl])

    if (metadataUrl && !isDoneFetchImageUrlMetadata) {
        return <img src={LoadingIcon} alt="LoadingIcon" className="custom-loading-icon" />
    } else if (fileExtension === FILE_EXTENSION.MP4) {
        return (
            <>
                <img alt="item" src={convertImage(nftImagePreview)} />
                <img className="search-video-icon" src={VideoPlayIcon} alt="" />
            </>
        )
    } else if (fileExtension === FILE_EXTENSION.MPEG) {
        return (
            <>
                <img alt="item" src={imageUrlMetadata ? imageUrlMetadata : convertImage(nftImagePreview)} />
                <img className="search-music-icon" src={MusicIcon} alt="" />
            </>
        )
    } else
        return (
            <>
                <img alt="item" src={imageUrlMetadata ? imageUrlMetadata : convertImage(nftUrl)} />
            </>
        )
}

export default SearchItemImage
