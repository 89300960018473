import { Card, Avatar } from "antd";
import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";

import CollectionDefault from "assets/images/smapocke.png";
import { convertImageAvatar, convertImageCover } from "utils/image";
import { shortenNameNoti, shortenAddress } from "utils/func";
import CustomTooltip from "components/common/tooltip";
import { NETWORK_ICON } from "constants/index";
import { CustomAvatar, TooltipCustom } from "components/common";
import { roundingNumber } from "utils/formatNumber";
import CustomVerifyIcon from "components/common/custom-verify-icon";
import "./style.scss";
import { NETWORKS } from "constants/envs";
import { t } from "i18next";
import { useLocation } from "react-router-dom/cjs/react-router-dom";

const CardCollectionTwo = ({ isExploreType = false, data }) => {
  const {
    id,
    name,
    title,
    networkType,
    shortUrl,
    nftsCount,
    nfts,
    creator,
    floorPrice,
    thumbnailUrl,
    bannerUrl,
    address,
    owners,
    volumeTraded24h,
  } = data;

  let location = useLocation();

  if (!data) return null;

  const _renderOwner = () => {
    if (isExploreType) {
      return null;
    }

    return creator?.address ? (
      <span className="card-collection-footer__created-by">
        <CustomTooltip
          overlayInnerStyle={{
            whiteSpace: "pre-wrap",
            width: "100%",
            overFlow: "hidden",
            maxWidth: "220px",
          }}
          placement={"topRight"}
          title={creator?.shortUrl || creator?.address}
        >
          <Link
            to={
              creator?.shortUrl
                ? `/user/${creator?.shortUrl}`
                : `/user/${creator?.address}`
            }
            className={`${creator?.isVerify ? "avatar-verify" : ""}`}
          >
            <CustomAvatar
              className={`${creator?.isVerify ? "verify-icon" : ""}`}
              src={creator?.avatarImg}
              verified={creator?.isVerify}
              verifiedClassName="verified"
            />
            {creator?.shortUrl
              ? `@${shortenNameNoti(creator?.shortUrl, 4)}`
              : shortenAddress(creator?.address, 2, 2)}
          </Link>
        </CustomTooltip>
      </span>
    ) : (
      <CustomTooltip
        overlayInnerStyle={{
          whiteSpace: "pre-wrap",
          width: "100%",
          overFlow: "hidden",
        }}
        title="SmartPocket"
      >
        <div className="default-owner">
          <div className="avatar-default-collection">
            <img
              className="avatar-owner-default"
              src={CollectionDefault}
              alt="Avatar default"
            />
            <CustomVerifyIcon className="verified-default" />
          </div>
          <div className="default-title">SmartPocket</div>
        </div>
      </CustomTooltip>
    );
  };

  return (
    <Card className="card-collection-custom">
      <Link to={`/collection/${shortUrl || id || address}`}>
        <div
          className={`card-collection-custom-header ${
            location?.pathname === "/" &&
            "card-collection-custom-header-homepage"
          }`}
        >
          <img
            className={`card-collection-image ${
              !bannerUrl && !thumbnailUrl ? "default" : ""
            }`}
            alt="collection"
            src={convertImageCover(bannerUrl || thumbnailUrl)}
          />
        </div>
        {!isExploreType && (
          <div className="card-collection__list-nft">
            {nfts.slice(0, 3).map((item, index) => (
              <Fragment key={index}>
                {item?.nftImagePreview ? (
                  <img
                    className="nft-avatar"
                    alt="Preview"
                    src={convertImageAvatar(item.nftImagePreview)}
                  />
                ) : item?.nftUrl ? (
                  <img
                    className="nft-avatar"
                    alt="Preview"
                    src={convertImageAvatar(item.nftUrl)}
                  />
                ) : (
                  <div className="nft-default" />
                )}
              </Fragment>
            ))}
          </div>
        )}
      </Link>
      <div className="card-collection-infor">
        <Link to={`/collection/${shortUrl || id || address}`}>
          <span className="card-collection-infor__name">
            <CustomTooltip
              overlayInnerStyle={{
                whiteSpace: "pre-wrap",
                width: "100%",
                overFlow: "hidden",
              }}
              title={`@${title || name}`}
            >
              {shortenNameNoti(title || name, 20)}
            </CustomTooltip>
          </span>
        </Link>
        <span className="blockchain-icon">
          <TooltipCustom title={networkType}>
            <Avatar src={NETWORK_ICON[networkType]} />
          </TooltipCustom>
        </span>
      </div>
      <div className="card-collection-footer">
        <div className="card-collection-footer-info">
          <div className="info">
            {/** TODO: translation */}
            <div className="info-title">{t('nftDetail.floor')}</div>
            <div className="info-value">
              {`${floorPrice > 0 ? `${roundingNumber(floorPrice, 6)} $` : "--"}`}
            </div>
          </div>
          <div className="info">
            {/** TODO: integrate value, translation */}
            <div className="info-title">{t('nftDetail.24hVol')}</div>
            <div className="info-value">
              {`${volumeTraded24h > 0 ? `${roundingNumber(volumeTraded24h, 6)} $` : "--"}`}
            </div>
          </div>
          {/* <span className="card-collection-footer__total-nft">
                        {nftsCount > 1 ? `${nftsCount} items · By` : `${nftsCount} item · By`}
                    </span> */}
          {isExploreType && (
            <div className="info">
              <div className="info-title">{t("listCollection.owners")}</div>
              <div className="info-value">{owners}</div>
            </div>
          )}
          {_renderOwner()}
        </div>
      </div>
    </Card>
  );
};

CardCollectionTwo.propTypes = {
  data: PropTypes.object.isRequired,
  className: PropTypes.string,
  disable: PropTypes.bool,
};

export default CardCollectionTwo;
