import React, { useEffect, useRef } from "react";
import { useDispatch } from "react-redux";
import { withRouter } from "react-router-dom";
import BigNumber from "bignumber.js";
import { watchAccount, watchChainId } from "@wagmi/core";

import { getSystemConfig, init } from "./store/actions/init";
import { loginWallet } from "store/actions/user";
import { useEagerConnect } from "hooks/useEagerConnect";
import { NETWORK_ID_TYPE } from "constants/envs";
import { getAmountToken } from "utils/index";
import env from "env/index";
import { useAccount, useBlockNumber, useChainId, useWatchBlocks } from "wagmi";
import { wagmiConfig } from "blockchain/wagmi";
import {
  closeWrongNetworkModal,
  openWrongNetworkModal,
} from "store/modal.slice";
import { updateBalance } from "store/user.slice";

BigNumber.config({ EXPONENTIAL_AT: 50, RANGE: 500 });

const AppContainer = (props) => {
  const dispatch = useDispatch();
  const { address } = useAccount();
  const chainId = useChainId();
  const { error } = useBlockNumber();
  const triedEager = useEagerConnect();

  const isConnected = triedEager && Boolean(address);

  const isInitiated = useRef(false);

  useEffect(() => {
    if (!error) {
      return;
    }

    if (error.name === "SwitchChainError") {
      dispatch(openWrongNetworkModal());
      return;
    }

    dispatch(closeWrongNetworkModal());
  }, [error]);

  useWatchBlocks({
    enabled: address && chainId,
    chainId,
    blockTag: "latest",
    onBlock: async () => {
      // new block is
      if (!address || !chainId) {
        return;
      }

      const tokenAmount = await getAmountToken(chainId, address);

      dispatch(
        updateBalance({
          ...tokenAmount,
        })
      );
    },
    pollingInterval: 15_000,
  });

  useEffect(() => {
    if (!isConnected) {
      return;
    }

    const unwatchAccount = watchAccount(wagmiConfig, {
      onChange: (data) => {
        if (!data.address) {
          return;
        }
        localStorage.removeItem("accessToken");
        localStorage.removeItem("userId");
        data.address &&
          dispatch(
            loginWallet({ account: data.address.toLowerCase(), chainId })
          );
      },
    });

    const unwatchChainId = watchChainId(wagmiConfig, {
      onChange: (chainId) => {
        if (!chainId) {
          return;
        }
        dispatch(loginWallet({ account: address, chainId }));
      },
    });

    return () => {
      unwatchAccount();
      unwatchChainId();
    };
  }, [isConnected, address, chainId]);

  useEffect(() => {
    if (!isInitiated.current) {
      isInitiated.current = true;
      localStorage.setItem(
        "networkType",
        NETWORK_ID_TYPE[env.metadata.networks.ethereum.id]
      );
      dispatch(init());
      dispatch(getSystemConfig());
    }
  }, []);

  return <div>{props.children}</div>;
};

export default withRouter(AppContainer);
