import React, { useContext, useState } from 'react'
import PropTypes from 'prop-types'
import { Drawer } from 'antd'
import { useTranslation } from 'react-i18next'

import { ButtonCustom } from 'components/common'
import { SvgIcon } from 'components/modules'
import './style.scss'
import { ThemeContext } from '../../../ThemeProvider'

const FilterMobileCustom = props => {
    const { theme } = useContext(ThemeContext)
    const { handleSetActiveFilter, type, listFilter } = props
    const { t } = useTranslation()
    const [displayDrawer, setDisplayDrawer] = useState(false)
    const [currentActiveFilter, setCurrentActiveFilter] = useState('All')

    return (
        <div className={`mobile-activity-filter ${theme === 'dark-theme' ? 'dark-mobile-activity-filter' : ''}`}>
            <ButtonCustom
                color="blue"
                className="button-filter-mobile default-container"
                onClick={() => setDisplayDrawer(true)}
            >
                {t('activity.filters')}: {currentActiveFilter}
            </ButtonCustom>
            <Drawer
                className="filter-drawer"
                title="Filter"
                placement="bottom"
                closable={false}
                onClose={() => setDisplayDrawer(false)}
                open={displayDrawer}
                key="bottom"
            >
                <div className="d-flex filter-container">
                    {listFilter?.map((item, index) => (
                        <ButtonCustom
                            key={index}
                            color={type === item.id ? 'blue' : 'white'}
                            iconLeft={
                                item?.name ? (
                                    <SvgIcon name={type === item.id ? item?.nameActive : item?.name} />
                                ) : (
                                    item?.icon && <i className={item?.icon} />
                                )
                            }
                            onClick={() => {
                                if (type === item.id) {
                                    handleSetActiveFilter(-1)
                                    setCurrentActiveFilter('All')
                                    return
                                }
                                handleSetActiveFilter(item?.id)
                                setCurrentActiveFilter(item?.filterTitle)
                            }}
                            className="button-filter"
                        >
                            {item?.filterTitle}
                        </ButtonCustom>
                    ))}
                </div>
                <ButtonCustom className="show-button" color="blue" onClick={() => setDisplayDrawer(false)}>
                    {t('activity.show')}
                </ButtonCustom>
            </Drawer>
        </div>
    )
}

FilterMobileCustom.propTypes = {
    name: PropTypes.string,
    filterTitle: PropTypes.any,
    handleSetActiveFilter: PropTypes.func,
    type: PropTypes.number,
    isShowAllDefault: PropTypes.bool
}

export default FilterMobileCustom
