import React, { useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { useChainId } from 'wagmi'

import { ButtonCustom } from 'components/common'
import MakeOfferModal from './modal/PidForm'
import CancelModal from './modal/Cancel'
import MakeOfferSuccessModal from './modal/MakeOfferSuccess'
import { isUserAuthenticated } from 'utils/auth'
import * as message from 'utils/custom-message'
import { ERROR_CODE_USER_DENIED_METAMASK, SOCKET_EVENTS } from 'constants/index'
import { handleCancelListingOrder } from 'blockchain/utils'
import { socket } from 'config'
import './style.scss'
import { useTranslation } from 'react-i18next'
import { getNFTDetail } from 'store/actions/nft'

const OpenForPid = ({ isOwner, signWallet }) => {
    const { t } = useTranslation();
    const history = useHistory()
    const chainId = useChainId();
    const dispatch = useDispatch()
    const { nftSales, id, collection, tokenId } = useSelector(state => state.collectible.data)
    const { profile } = useSelector(state => state.user)
    const [isOpenCancelModal, setIsOpenCancelModal] = useState(false)
    const [isOpenMakeOfferModal, setIsOpenMakeOfferModal] = useState(false)
    const [isOpenOfferSuccessModal, setIsOpenOfferSuccessModal] = useState(false)
    const [isCancelingSale, setIsCancelingSale] = useState(false)

    const handleCancelOpenOffer = async () => {
        if (!isUserAuthenticated()) {
            const res = await signWallet()
            if (!res) return
        }
        setIsOpenCancelModal(true)
    }

    const nftSale = useMemo(() => {
        if (nftSales && nftSales[0]) {
            return nftSales[0]
        }
    }, [nftSales])

    const handleMakeOffer = async () => {
        if (!profile?.id) {
            return history.push({ pathname: '/connect' })
        }
        if (!isUserAuthenticated()) {
            const res = await signWallet()
            if (!res) return
        }
        setIsOpenMakeOfferModal(true)
    }

    const handleOnConfirmCancel = async () => {
        setIsCancelingSale(true)

        const [, error] = await handleCancelListingOrder(nftSale.signatureSale, chainId)

        if (error) {
            setIsOpenCancelModal(false)
            setIsCancelingSale(false)
            if (error?.code === ERROR_CODE_USER_DENIED_METAMASK)
                return message.error(t('error.message.declinedActionWallet'))
            return message.error(
                t('error.message.cannotContinueAction')
            )
        }
    }

    useEffect(() => {
        if (socket) {
            socket.on(SOCKET_EVENTS.CANCEL_INTERNAL_SALE, res => {
                if (res?.data?.userId === profile?.id && res?.data?.nftId === id) {
                    setIsOpenCancelModal(false)
                    setIsCancelingSale(false)
                    dispatch(getNFTDetail({ collectionAddress: collection.id || collection.address, tokenId }))
                    message.success(t('success.message.cancelSuccessfully'))
                }
            })
        }
        return () => socket.off(SOCKET_EVENTS.CANCEL_INTERNAL_SALE)
    }, [profile?.id, id, tokenId, collection, dispatch])

    return (
        <div className="open-for-bid-container">
            <div className="open-for-bid-container__card">
                <div className="open-for-bid-container__card--title">{t('nftDetail.openForBid')}</div>

                {isOwner ? (
                    <div className="action-buy">
                        <ButtonCustom
                            isBuble
                            fullWidth
                            color="white"
                            className="action-btn br-12"
                            onClick={handleCancelOpenOffer}
                        >
                            {t('nftDetail.cancelOpenOffer')}
                        </ButtonCustom>
                    </div>
                ) : (
                    <div className="action-buy">
                        <ButtonCustom
                            fullWidth
                            isBuble
                            size="lg"
                            color="blue"
                            className="action-btn br-12"
                            onClick={handleMakeOffer}
                        >
                            {t('nftDetail.makeAnOffer')}
                        </ButtonCustom>
                    </div>
                )}
            </div>

            {isOpenCancelModal && (
                <CancelModal
                    onConfirm={handleOnConfirmCancel}
                    onCloseModal={() => setIsOpenCancelModal(false)}
                    isCanceling={isCancelingSale}
                />
            )}

            {isOpenMakeOfferModal && (
                <MakeOfferModal
                    onCloseModal={() => setIsOpenMakeOfferModal(false)}
                    openOfferSuccessModal={() => setIsOpenOfferSuccessModal(true)}
                />
            )}

            {isOpenOfferSuccessModal && (
                <MakeOfferSuccessModal onCloseModal={() => setIsOpenOfferSuccessModal(false)} />
            )}
        </div>
    )
}

export default OpenForPid
