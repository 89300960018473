import React, { useCallback, useEffect, useRef } from 'react'
import { useParams, useHistory } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { useWindowSize } from 'react-use'
import { Helmet } from 'react-helmet'

import { ButtonCustom } from 'components/common'
import {
    getNFTDetail,
    clearDetail,
    changePageHistory,
    getTradingHistory,
    changePageOffer,
    getOfferList,
    changePageMultipleSelling,
    getNftSales
} from 'store/actions/nft'
import DefaultLayout from 'components/layout/default-layout'
import { Overview, Related, Tabs } from './elements'
import { MAIN_TITLE, SOCKET_EVENTS } from 'constants/index'
import { getDecodeNftUrl } from 'utils/index'
import { socket } from 'config'
import './style.scss'

const NFTDetail = () => {
    const { path } = useParams()
    const { width } = useWindowSize()
    const { t } = useTranslation()
    const dispatch = useDispatch()
    const history = useHistory()
    const profile = useSelector(state => state.user.profile)
    const { error, data } = useSelector(state => state.collectible)
    const tabRef = useRef(null)

    useEffect(() => {
        const { collectionAddress, tokenId } = getDecodeNftUrl(path)
        dispatch(getNFTDetail({ collectionAddress, tokenId }))
        return () => dispatch(clearDetail())
    }, [profile?.id, path, dispatch])

    const scrollToTab = useCallback(() => {
        if (tabRef.current) {
            tabRef.current.scrollIntoView({ behavior: 'smooth', block: 'center'})
            const tabList = tabRef.current.getElementsByClassName('ant-tabs-tab');
            setTimeout(() => tabList[1].click(), 500)
        }
    }, [])

    useEffect(() => {
        if (socket) {
            socket.on(SOCKET_EVENTS.TRANSFER_NFT_SUCCESS, res => {
                if (
                    (res?.data?.toUserId === profile?.id || res?.data?.fromUserId === profile?.id) &&
                    res?.data?.nftId === data.id
                ) {
                    if (!data.tokenId || !data?.collection?.address) return
                    dispatch(changePageMultipleSelling({ value: 1 }))
                    dispatch(getNftSales({ nftId: data.id }))

                    dispatch(changePageHistory({ value: 1 }))
                    dispatch(getTradingHistory({ nftId: data.id }))

                    dispatch(changePageOffer({ value: 1 }))
                    dispatch(getOfferList({ nftId: data.id }))

                    dispatch(
                        getNFTDetail({
                            collectionAddress: data?.collection?.id || data?.collection?.address,
                            tokenId: data.tokenId
                        })
                    )
                }
                return () => socket.off(SOCKET_EVENTS.TRANSFER_NFT_SUCCESS)
            })
        }
    }, [profile?.id, data.id, dispatch, data?.collection?.address, data?.collection?.id, data.tokenId])

    useEffect(() => {
        if (socket) {
            socket.on(SOCKET_EVENTS.CANCEL_INTERNAL_SALE, res => {
                if (res?.data?.userId === profile?.id && res?.data?.nftId === data.id) {
                    dispatch(changePageHistory({ value: 1 }))
                    dispatch(getTradingHistory({ nftId: data.id }))

                    dispatch(changePageOffer({ value: 1 }))
                    dispatch(getOfferList({ nftId: data.id }))

                    dispatch(getNftSales({ nftId: data.id }))
                    dispatch(changePageMultipleSelling({ value: 1 }))

                    dispatch(getNFTDetail({ collectionAddress: data?.collection?.id, tokenId: data.tokenId }))
                }
            })
        }
        return () => socket.off(SOCKET_EVENTS.CANCEL_INTERNAL_SALE)
    }, [profile?.id, data.id, dispatch, data?.collection?.id, data.tokenId])

    return (
        <DefaultLayout>
            <Helmet>
                <meta charSet="utf-8" />
                <title>{`${MAIN_TITLE} - ${data.name}`}</title>
                <meta name="description" content={data.description} />
            </Helmet>
            {!error ? (
                data?.id && data?.collection ? (
                    <div className="detail-container">
                        <Overview scrollToTab={scrollToTab} />
                        <Tabs tabRef={tabRef} />
                        <Related nftId={data?.id} collectionData={data?.collection} />
                    </div>
                ) : (
                    <div className="detail-placeholder" />
                )
            ) : (
                <div className="not-found-container">
                    <h2 className="not-found-title">{t('pageNotFound')}</h2>
                    <p className="not-found-description">{t('pageNotFound_description')}</p>
                    <ButtonCustom onClick={() => history.goBack()} color="blue">
                        {t('common.goBack')}
                    </ButtonCustom>
                </div>
            )}
        </DefaultLayout>
    )
}

export default NFTDetail
