import React, { useCallback, useState, useEffect, useMemo } from 'react'
import classNames from 'classnames'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import moment from 'moment'
import { Form } from 'antd'
import { omit } from 'lodash'
import { Helmet } from 'react-helmet'

import DefaultLayout from 'components/layout/default-layout'
import { CardItemPreview } from 'components/modules'
import {
    InputCustom,
    DraggerCustom,
    SwitchCustom,
    ButtonCustom,
    SelectCustom,
    DateTimeModal,
} from 'components/common'
import { Label } from 'components/modules'
import * as message from 'utils/custom-message'
import {
    MarketPlaceBtnGroups,
    FixPriceModal,
    OpenForBidModal,
    CreateCollectionModal,
    CollectionFollowStepModal,
    CreateCollectionSelected,
    NotForSaleModal,
    AuctionStepModal
} from './components'
import { START_DATE_OPTIONS, EXPIRE_DATE_OPTIONS } from './constant'
import {
    SALE_TYPE,
    COLLECTIBLE_TYPE,
    MAIN_TITLE,
    NFT_TYPE,
    NETWORK_OPTIONS,
    PAYMENT_OPTIONS,
    PAYMENT_OPTIONS_AUCTION
} from 'constants/index'
import collectionService from 'service/collectionService'
import { isUserAuthenticated } from 'utils/auth'
import { formatNumberToFix } from 'utils/formatNumber'
import './style.scss'
import './responsive.scss'
import { getPrice } from 'service/getPrice'
import { setGlobalLoading, setCloseGlobalLoading } from 'store/system.slice'

const MAX_VALUE = 100000000000000
const PropertiesFieldGroup = ({ form }) => {
    const { t } = useTranslation()
    const [current, setCurrent] = useState(1)
    const currentPropertiesType = form.getFieldValue('propertiesType')
    const currentPropertiesValue = form.getFieldValue('propertiesValue')

    useEffect(() => {
        if (currentPropertiesType?.[current - 1] && currentPropertiesValue?.[current - 1]) {
            setCurrent(current + 1)
        }

        if ((!currentPropertiesType?.[current - 2] || !currentPropertiesValue?.[current - 2]) && current > 1) {
            setCurrent(current - 1)
        }
    }, [currentPropertiesType, currentPropertiesValue, current])

    return (
        <>
            {Array.from(Array(current).keys()).map(index => (
                <div key={index} className="create-nft-properties">
                    <Form.Item
                        rules={[
                            {
                                required: !!form.getFieldValue(['propertiesValue', index]),
                                message: t('createNFT.field_properties_type_rule_required')
                            },
                            { max: 200, message: t('createNFT.field_properties_rule_max') }
                        ]}
                        className="create-nft-properties_block mr"
                        name={['propertiesType', index]}
                    >
                        <InputCustom placeholder={t('createNFT.field_properties_type_placeholder')} />
                    </Form.Item>
                    <Form.Item
                        rules={[
                            {
                                required: !!form.getFieldValue(['propertiesType', index]),
                                message: t('createNFT.field_properties_value_rule_required')
                            },
                            { max: 200, message: t('createNFT.field_properties_rule_max') }
                        ]}
                        className="create-nft-properties_block ml"
                        name={['propertiesValue', index]}
                    >
                        <InputCustom placeholder={t('createNFT.field_properties_value_placeholder')} />
                    </Form.Item>
                </div>
            ))}
        </>
    )
}

const CreateNft = props => {
    const {
        match: { params }
    } = props

    const dispatch = useDispatch()
    const history = useHistory()
    const { MARKET_FEE } = useSelector(state => state.system)
    const { t } = useTranslation()
    const categories = useSelector(state => state.categories?.data) || []
    const { profile } = useSelector(state => state.user)
    const { createSuccess } = useSelector(state => state.collection)
    const [isShowFixPriceModal, setIsShowFixPriceModal] = useState(false)
    const [isCreateCollectionModalVisible, setCreateCollectionModalVisible] = useState(false)
    const [isCollectionFollowStepModalVisible, setCollectionFollowStepModalVisible] = useState(false)
    const [isShowNotForSaleModal, setIsShowNotForSaleModal] = useState(false)
    const [isShowAuctionModal, setIsShowAuctionModal] = useState(false)
    const [isShowOpenForBid, setIsShowOpenForBid] = useState(false)
    const [isShowUnlockableContent, setIsShowUnlockableContent] = useState(false)
    const [listOfCollections, setListOfCollections] = useState([])
    const [networkType, setNetworkType] = useState(NETWORK_OPTIONS[0].value)
    const [collectionAddress, setCollectionAddress] = useState('')
    const [uploadFile, setUploadFile] = useState(null)
    const [previewFile, setPreviewFile] = useState(null)
    const [formValues, setFormValues] = useState(null)
    const [isFormError, setIsFormError] = useState(false)
    const [collectibleType, setCollectibleType] = useState(params.type)
    const [tokenType, setTokenType] = useState(PAYMENT_OPTIONS[networkType][0].value)
    const [floorPrice, setFloorPrice] = useState(0)
    const [form] = Form.useForm()

    const marketPlaceValue = form.getFieldValue('type')
    const priceValue = form.getFieldValue('price')
    const uploadValue = form.getFieldValue('upload')
    const isMarketPlaceValue = form.getFieldValue('onSaleStatus')
    const minimumBidPriceValue = form.getFieldValue('minimumBid')
    const minimumBidStartDateValue = form.getFieldValue('startDate')
    const minimumBidExpireDateValue = form.getFieldValue('expireDate')
    const titleValue = form.getFieldValue('name')
    const numberOfCopies = form.getFieldValue('numberOfCopies')
    const [idCollectionSelect, setIdCollectionSelect] = useState('')

    const renderMinimumBidStartDateValue = useMemo(() => {
        if (marketPlaceValue === SALE_TYPE.ENGLISH_AUCTION && minimumBidStartDateValue) {
            if (typeof minimumBidStartDateValue === 'number') {
                return moment().unix()
            }

            return moment(minimumBidStartDateValue).unix()
        }

        return null
    }, [minimumBidStartDateValue, marketPlaceValue])

    const renderMinimumBidExpireDateValue = useMemo(() => {
        if (marketPlaceValue === SALE_TYPE.ENGLISH_AUCTION && minimumBidExpireDateValue && minimumBidStartDateValue) {
            if (typeof minimumBidStartDateValue === 'string') {
                if (moment(minimumBidStartDateValue).unix() < moment().unix()) {
                    return null
                }
            }
            if (typeof minimumBidExpireDateValue === 'number') {
                if (typeof minimumBidStartDateValue === 'string') {
                    return moment(minimumBidStartDateValue).add(minimumBidExpireDateValue, 'days').unix()
                }
                return moment().add(minimumBidExpireDateValue, 'days').unix()
            }
            return moment(minimumBidExpireDateValue).unix()
        }

        return null
    }, [minimumBidExpireDateValue, marketPlaceValue, minimumBidStartDateValue])

    const convertData = {
        name: titleValue,
        onSaleStatus: Number(isMarketPlaceValue),
        tokenType,
        price: marketPlaceValue === SALE_TYPE.FIX_PRICE ? priceValue : null,
        totalLikes: 0,
        placeholderImage: uploadValue,
        ownerNfts: [],
        type: marketPlaceValue,
        nftType: collectibleType === COLLECTIBLE_TYPE.SINGLE ? NFT_TYPE.ERC721 : NFT_TYPE.ERC1155,
        user: profile,
        collection: listOfCollections.find(collection => collection.address === collectionAddress),
        fileExtension: uploadFile && uploadFile?.type,
        numberOfCopies: numberOfCopies || 1,
        networkType,
        auctions:
            marketPlaceValue === SALE_TYPE.ENGLISH_AUCTION && isMarketPlaceValue
                ? {
                    expireDate: renderMinimumBidExpireDateValue,
                    startDate:
                        typeof minimumBidStartDateValue === 'string'
                            ? renderMinimumBidStartDateValue
                            : moment().unix(),
                    minBid: minimumBidPriceValue,
                    tokenType
                }
                : null
    }

    const receiveFee = useMemo(
        () => (priceValue ? formatNumberToFix(priceValue - MARKET_FEE * priceValue) : 0),
        [priceValue, MARKET_FEE]
    )

    const switchCollectibleType = () => {
        const type = collectibleType === COLLECTIBLE_TYPE.SINGLE ? COLLECTIBLE_TYPE.MULTIPLE : COLLECTIBLE_TYPE.SINGLE
        setCollectibleType(type)
        history.push('/create/' + type)
    }

    useEffect(() => {
        // TODO
        const checkAuth = async () => {
            if (!profile?.walletAddress) {
                history.push('/connect')
            } else {
                if (!isUserAuthenticated()) {
                    dispatch(setGlobalLoading()) // TODO
                    history.push('/')
                    dispatch(setCloseGlobalLoading()) // TODO
                }
            }
        }
        checkAuth()
    }, [history, profile?.walletAddress, dispatch])

    const onCollectionFollowStepModalClose = () => {
        setCollectionFollowStepModalVisible(false)
    }

    const onCollectionFollowStepModalOpen = () => {
        setCollectionFollowStepModalVisible(true)
    }

    const onCreateCollectionModalOpen = useCallback(() => {
        setCreateCollectionModalVisible(true)
    }, [])

    const onMinimumBidStartDateChange = useCallback(value => {
        form.setFieldsValue({ startDate: value })
        form.validateFields(['startDate', 'expireDate'])
    }, [])

    const onMinimumBidStartDateValidator = useCallback(() => {
        if (isNaN(minimumBidStartDateValue)) {
            if (moment().unix() > moment(minimumBidStartDateValue).unix()) {
                return Promise.reject(t('createNFT.field_startDate_rule_after_present'))
            }
        }
        return Promise.resolve()
    }, [minimumBidStartDateValue, t])

    const onMinimumBidExpireDateChange = useCallback(value => {
        form.setFieldsValue({ expireDate: value })
        form.validateFields(['expireDate', 'startDate'])
    }, [])

    const onMinimumBidExpireDateValidator = useCallback(() => {
        if (isNaN(minimumBidExpireDateValue)) {
            if (moment().unix() >= moment(minimumBidExpireDateValue).unix()) {
                return Promise.reject(t('createNFT.field_expireDate_rule_after_present'))
            }
        }
        if (isNaN(minimumBidExpireDateValue) && isNaN(minimumBidStartDateValue)) {
            if (moment(minimumBidStartDateValue).unix() >= moment(minimumBidExpireDateValue).unix()) {
                return Promise.reject(t('createNFT.field_expireDate_rule_after'))
            }
        }

        return Promise.resolve()
    }, [minimumBidExpireDateValue, minimumBidStartDateValue, t])

    const notZeroValidator = useCallback(
        async (rule, value) => {
            const priceRate = await getPrice(tokenType.toUpperCase())

            if (value && floorPrice && Number(value) * priceRate <= floorPrice) {
                return Promise.reject(t('error.message.priceFloorPrice', { floorPrice }))
            }
            if (value && Number(value) === 0) {
                return Promise.reject(t('createNFT.field_price_rule_invalid'))
            }
            return Promise.resolve()
        },
        [t, tokenType, floorPrice]
    )

    const onFinish = useCallback(
        values => {
            values = { ...values, alternativeText: '' }
            const convertProperties = []
            if (Array.isArray(values.propertiesType) && Array.isArray(values.propertiesValue)) {
                values.propertiesType.forEach((property, index) => {
                    if (property) {
                        convertProperties.push({
                            name: property,
                            value: values.propertiesValue[index]
                        })
                    }
                })
            }
            const convertValues = {
                ...values,
                status: 0,
                onSaleStatus: values.onSaleStatus ? 1 : 0,
                tokenType,
                userId: profile?.id,
                nftType: collectibleType === COLLECTIBLE_TYPE.SINGLE ? NFT_TYPE.ERC721 : NFT_TYPE.ERC1155,
                royalty: Number(values.royalty),
                properties: convertProperties,
                networkType,
                collectionAddress,
                collectionId: idCollectionSelect,
                name: values?.name.trim(),
                isUnlockableContent: isShowUnlockableContent,
                ...(isShowUnlockableContent && { unlockableContent: values?.unlockContent || '' })
            }

            if (convertValues['price']) {
                convertValues['price'] = Number(convertValues['price'])
            }
            if (convertValues['categoryIds']) {
                convertValues['categoryIds'] = [convertValues['categoryIds']]
            }

            convertValues['numberOfCopies'] = Number(convertValues['numberOfCopies']) || 1

            if (convertValues['minimumBid']) {
                convertValues['minimumBid'] = Number(convertValues['minimumBid'])
            }

            if (convertValues['expireDate']) {
                if (convertValues['startDate'] && convertValues['expireDate']) {
                    if (typeof convertValues['expireDate'] === 'number') {
                        if (typeof convertValues['startDate'] === 'string') {
                            convertValues['expireDate'] = moment(convertValues['startDate'])
                                .add(convertValues['expireDate'], 'days')
                                .unix()
                        } else {
                            convertValues['expireDate'] = moment().add(convertValues['expireDate'], 'days').unix()
                        }
                    } else {
                        convertValues['expireDate'] = moment(convertValues['expireDate']).unix()
                    }
                }
            }

            if (convertValues['startDate']) {
                if (typeof convertValues['startDate'] === 'number') {
                    convertValues['startDate'] = moment().unix()
                } else {
                    convertValues['startDate'] = moment(convertValues['startDate']).unix()
                }
            }

            setFormValues(omit(convertValues, ['propertiesType', 'propertiesValue', 'tokenType_Bid', 'upload']))

            if (values.onSaleStatus) {
                switch (marketPlaceValue) {
                    case SALE_TYPE.FIX_PRICE:
                        setIsShowFixPriceModal(true)
                        return
                    case SALE_TYPE.ENGLISH_AUCTION:
                        setIsShowAuctionModal(true)
                        return
                    case SALE_TYPE.OPEN_FOR_OFFER:
                        setIsShowOpenForBid(true)
                        return
                    default:
                        break
                }
            } else {
                setIsShowNotForSaleModal(true)
            }
        },
        [
            profile,
            setFormValues,
            marketPlaceValue,
            collectibleType,
            tokenType,
            networkType,
            collectionAddress,
            isShowUnlockableContent,
            idCollectionSelect
        ]
    )

    const onChangeValueInput = (value, name, maxValue = MAX_VALUE, decimal = 6) => {
        if (value === '.') return form.setFieldsValue({ [name]: null })
        let number = value
            .toString()
            .replace(/[^0-9.]/g, '')
            .replace(/(\..*?)\..*/g, '$1')
        if (Number(number) >= maxValue) {
            number = number.slice(0, -1)
        }
        if (number.includes('.')) {
            const numString = number.toString().split('.')
            if (numString[1].length > decimal) {
                return form.setFieldsValue({ [name]: number.substring(0, number.length - 1) })
            }
        }
        form.setFieldsValue({ [name]: number })
    }

    const handleChangeCollection = collection => {
        const collectionSelected = listOfCollections.find(item => item?.id === collection?.id)
        setCollectionAddress(collection?.address)
        setIdCollectionSelect(collection?.id)
        setFloorPrice(Number(collectionSelected?.floorPrice))
        form.setFieldsValue({ royalty: collectionSelected?.royalty })

        if (!priceValue) return
        if (marketPlaceValue === SALE_TYPE.ENGLISH_AUCTION) {
            form.validateFields(['minimumBid'])
        } else if (marketPlaceValue === SALE_TYPE.FIX_PRICE) {
            form.validateFields(['price'])
        }
    }

    const removeError = useCallback(() => {
        setIsFormError(false)
    }, [])

    const getPriceCurrencyValue = useMemo(() => {
        return PAYMENT_OPTIONS[networkType].find(option => option.value === tokenType)?.label
    }, [networkType, tokenType])

    const renderTokenType = useCallback(
        options => {
            return (
                <SelectCustom
                    className="select-custom-bg"
                    placeholder={'networkType'}
                    options={options}
                    onChange={e => setTokenType(e)}
                    value={tokenType}
                />
            )
        },
        [tokenType]
    )

    const onFinishFailed = () => {
        setIsFormError(true)
    }

    const setOnSwitchAble = () => {
        setIsFormError(false)
    }

    const setSwitchUnlockableContent = value => {
        setIsShowUnlockableContent(value)
    }

    useEffect(() => {
        if (listOfCollections && listOfCollections.length) {
            const collectionSelected = listOfCollections.find(item => item.address === collectionAddress)
            setFloorPrice(Number(collectionSelected?.floorPrice))
        }
    }, [collectionAddress, listOfCollections])

    useEffect(() => {
        if (!profile.walletAddress) {
            history.push({
                pathname: '/connect',
                state: {
                    from: '/create-nft'
                }
            })
        }
    }, [profile.walletAddress, history])

    useEffect(() => {
        if (marketPlaceValue === SALE_TYPE.ENGLISH_AUCTION) {
            setTokenType(PAYMENT_OPTIONS_AUCTION[networkType][0].value)
        } else if (marketPlaceValue === SALE_TYPE.FIX_PRICE) {
            setTokenType(PAYMENT_OPTIONS[networkType][0].value)
        }
    }, [marketPlaceValue, setTokenType, networkType, form])

    useEffect(() => {
        const fetchListCollections = async () => {
            dispatch(setGlobalLoading())
            const [dataCollection, err] = await collectionService.getListOfCollection()
            if (err) {
                message.error(t('error.message.failToFetchCollection'))
            } else if (dataCollection) {
                setListOfCollections(dataCollection.data)
            }
            dispatch(setCloseGlobalLoading())
        }
        if (isUserAuthenticated()) {
            fetchListCollections()
        }
    }, [createSuccess, dispatch])

    useEffect(() => {
        if (listOfCollections && listOfCollections[0]) {
            const collectionAddressDefault = listOfCollections.filter(c => {
                const type = collectibleType === COLLECTIBLE_TYPE.SINGLE ? NFT_TYPE.ERC721 : NFT_TYPE.ERC1155
                return c.networkType === networkType && c.type === type
            })[0]
            setIdCollectionSelect(collectionAddressDefault?.id)
            setCollectionAddress(collectionAddressDefault?.address)
            handleChangeCollection(collectionAddressDefault)
        }
    }, [listOfCollections, networkType, collectibleType]);

    return (
        <DefaultLayout>
            <Helmet>
                <meta charSet="utf-8" />
                <title>{`${MAIN_TITLE} - create ${collectibleType}`}</title>
                <meta name="description" content={`${MAIN_TITLE} - ${collectibleType}`} />
            </Helmet>
            <div className="create-nft-wrapper">
                <div className="create-nft-header">
                    <span className="create-nft-header_title">
                        {t('createNFT.createCollectible', { type: collectibleType === COLLECTIBLE_TYPE.SINGLE ? t('createNFT.single') : t('createNFT.multiple') })}
                    </span>
                </div>
                <div className="create-nft-container">
                    <div style={{ marginBottom: 20, display: 'flex', justifyContent: 'space-between' }}>
                        <SelectCustom
                            className="select left"
                            dropdownClassName="select__dropdown--chains"
                            placeholder={t('form.select_placeholder')}
                            value={networkType}
                            options={NETWORK_OPTIONS}
                            name="networkType"
                            onChange={value => {
                                setNetworkType(value)
                                setTokenType(PAYMENT_OPTIONS[value][0].value)
                                setCollectionAddress('')
                            }}
                        />
                        <ButtonCustom
                            className='switch-type-btn'
                            size="md"
                            iconRight={<i className="ri-arrow-right-line"></i>}
                            onClick={() => switchCollectibleType()}
                        >
                            {t('createNFT.switchType',
                                {
                                    type: collectibleType === COLLECTIBLE_TYPE.SINGLE ?
                                        t('createNFT.multiple') : t('createNFT.single')
                                }
                            )}
                        </ButtonCustom>
                    </div>
                    <div className="create-nft-form_wrapper">
                        <Form
                            name="createNftForm"
                            form={form}
                            className="create-nft-form"
                            onFieldsChange={removeError}
                            onFinish={onFinish}
                            onFinishFailed={onFinishFailed}
                        >
                            {() => (
                                <>
                                    <div className="create-nft-block">
                                        <Label title={t('createNFT.field_uploadFile')}>
                                            <Form.Item
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: t('createNFT.field_uploadFile_rule_required')
                                                    }
                                                ]}
                                                name="upload"
                                            >
                                                <DraggerCustom
                                                    setPreviewFile={setPreviewFile}
                                                    setUploadFile={setUploadFile}
                                                />
                                            </Form.Item>
                                        </Label>
                                    </div>

                                    <div className="create-nft-marketPlace">
                                        <div className="create-nft-marketPlace_header">
                                            <div className="create-nft-marketPlace_label">
                                                <span className="create-nft-marketPlace_label__title">
                                                    {t('createNFT.field_putOnMartketplace')}
                                                </span>
                                                <span className="create-nft-marketPlace_label__desc">
                                                    {t('createNFT.field_putOnMartketplace_desc')}
                                                </span>
                                            </div>
                                            <Form.Item initialValue={true} name="onSaleStatus">
                                                <SwitchCustom />
                                            </Form.Item>
                                        </div>
                                        {isMarketPlaceValue && (
                                            <Form.Item initialValue={SALE_TYPE.FIX_PRICE} name="type">
                                                <MarketPlaceBtnGroups
                                                    isCreateNft={true}
                                                    options={SALE_TYPE}
                                                    collectibleType={collectibleType}
                                                />
                                            </Form.Item>
                                        )}
                                    </div>

                                    {/* put on sale fixed price */}
                                    {isMarketPlaceValue && marketPlaceValue === SALE_TYPE.FIX_PRICE && (
                                        <div className="create-nft-block">
                                            <Label
                                                title={t('createNFT.field_price')}
                                                description={
                                                    <span dangerouslySetInnerHTML={{
                                                        __html: t('createNFT.priceDescription', {
                                                            marketFee: `${MARKET_FEE * 100}%`,
                                                            receiveFeeAndCurrency: `${receiveFee} ${getPriceCurrencyValue}`
                                                        })
                                                    }}>
                                                    </span>
                                                }
                                            >
                                                <div className="create-nft-price">
                                                    <Form.Item
                                                        rules={[
                                                            {
                                                                required: true,
                                                                message: t('createNFT.field_price_rule_required')
                                                            },
                                                            { validator: notZeroValidator }
                                                        ]}
                                                        name="price"
                                                        validateTrigger="onBlur"
                                                        className="create-nft-price_input"
                                                    >
                                                        <InputCustom
                                                            onChange={e => onChangeValueInput(e.target.value, 'price')}
                                                            placeholder={t('createNFT.field_price_placeholder')}
                                                        />
                                                    </Form.Item>
                                                    {renderTokenType(PAYMENT_OPTIONS[networkType])}
                                                </div>
                                            </Label>
                                        </div>
                                    )}

                                    {/* auction price */}
                                    {isMarketPlaceValue && marketPlaceValue === SALE_TYPE.ENGLISH_AUCTION && (
                                        <>
                                            <div className="create-nft-block">
                                                <Label
                                                    title={t('createNFT.field_minimumBid')}
                                                    description={t('createNFT.field_minimumBid_desc')}
                                                >
                                                    <div className="create-nft-minimumBid">
                                                        <Form.Item
                                                            rules={[
                                                                {
                                                                    required: true,
                                                                    message: t('createNFT.field_minimumBid_rule_required')
                                                                },
                                                                { validator: notZeroValidator }
                                                            ]}
                                                            name="minimumBid"
                                                            validateTrigger="onBlur"
                                                            className="create-nft-minimumBid_price_input"
                                                        >
                                                            <InputCustom
                                                                onChange={e =>
                                                                    onChangeValueInput(e.target.value, 'minimumBid')
                                                                }
                                                                placeholder={t('createNFT.field_minimumBid_placeholder')}
                                                                className="create-nft-minimumBid_price_input_custom input-custom-bg"
                                                            />
                                                        </Form.Item>
                                                        {renderTokenType(PAYMENT_OPTIONS_AUCTION[networkType])}
                                                    </div>
                                                </Label>
                                            </div>
                                            <div className="create-nft-block">
                                                <div className="create-nft-minimumBid create-nft-minimumBid-col">
                                                    <Label
                                                        title={t('createNFT.field_startDate')}
                                                        className="create-nft-minimumBid_label"
                                                    >
                                                        <Form.Item
                                                            rules={[
                                                                { required: true, message: t('form.required') },
                                                                { validator: onMinimumBidStartDateValidator }
                                                            ]}
                                                            name="startDate"
                                                            initialValue={1}
                                                            className="create-nft-startDate"
                                                        >
                                                            <SelectCustom
                                                                className="select-custom-bg"
                                                                placeholder={t('form.select_placeholder')}
                                                                options={START_DATE_OPTIONS.map(option => ({ ...option, label: t('startDateOption.' + option.label) }))}
                                                            />
                                                        </Form.Item>
                                                    </Label>
                                                    <Label
                                                        title={t('createNFT.field_expireDate')}
                                                        className="create-nft-minimumBid_label expireDate_label"
                                                    >
                                                        <Form.Item
                                                            rules={[
                                                                { required: true, message: t('form.required') },
                                                                { validator: onMinimumBidExpireDateValidator }
                                                            ]}
                                                            name="expireDate"
                                                            initialValue={1}
                                                            className="create-nft-expireDate"
                                                        >
                                                            <SelectCustom
                                                                className="select-custom-bg"
                                                                placeholder={t('form.select_placeholder')}
                                                                options={EXPIRE_DATE_OPTIONS.map(option => ({ ...option, label: t('expiredDateOption.' + option.label) }))}
                                                            />
                                                        </Form.Item>
                                                    </Label>
                                                </div>
                                            </div>
                                        </>
                                    )}

                                    <div className="create-nft-block">
                                        <Label
                                            title={t('createNFT.field_unlockable_content')}
                                            onSwichAble={setOnSwitchAble}
                                            switchable
                                            isUnlockableContent
                                            setSwitch={setSwitchUnlockableContent}
                                        >
                                            <Form.Item
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: t('createNFT.field_unlockable_content_rule_required')
                                                    },
                                                    { max: 1000, message: t('createNFT.field_unlockable_content_rule_max') }
                                                ]}
                                                name="unlockContent"
                                            >
                                                <InputCustom
                                                    placeholder={t('createNFT.field_unlockable_content_placeholder')}
                                                />
                                            </Form.Item>
                                        </Label>
                                    </div>

                                    <div className="create-nft-block">
                                        <Label title={t('createNFT.chooseCollection')}>
                                            <CreateCollectionSelected
                                                collectibleType={collectibleType}
                                                onCreateCollection={onCreateCollectionModalOpen}
                                                listOfCollections={listOfCollections}
                                                networkType={networkType}
                                                onChange={handleChangeCollection}
                                                value={idCollectionSelect}
                                            />
                                        </Label>
                                    </div>

                                    {categories && (
                                        <div className="create-nft-block">
                                            <Label
                                                title={t('createNFT.field_category')}
                                                description={t('createNFT.field_category_subDesc')}
                                            >
                                                <Form.Item
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message: t('createNFT.field_cagtegory_rule_required')
                                                        }
                                                    ]}
                                                    name="categoryIds"
                                                >
                                                    <SelectCustom
                                                        options={categories.map(category => {
                                                            return {
                                                                ...category,
                                                                label: t(`category.${category?.label}`),
                                                            }
                                                        })}
                                                        showIconSvg={true}
                                                        fieldSelect={'id'}
                                                        placeholder={`${t('form.select_category')}`}
                                                    />
                                                </Form.Item>
                                            </Label>
                                        </div>
                                    )}

                                    <div className="create-nft-block">
                                        <Label
                                            title={t('createNFT.field_royalties')}
                                            description={t('createNFT.field_royalties_desc')}
                                        >
                                            <Form.Item
                                                name="royalty"
                                                rules={[
                                                    {
                                                        validator: (rule, value) => {
                                                            if (!isNaN(value) && value > 30) {
                                                                return Promise.reject(
                                                                    t('createNFT.field_royalties_rule_min', { min: 30 })
                                                                )
                                                            }
                                                            return Promise.resolve()
                                                        }
                                                    }
                                                ]}
                                            >
                                                <InputCustom
                                                    placeholder={t('createNFT.field_royalties_placeholder')}
                                                    onChange={e => onChangeValueInput(e.target.value, 'royalty', 999, 2)}
                                                />
                                            </Form.Item>
                                        </Label>
                                    </div>

                                    <div className="create-nft-block">
                                        <Label title={t('createNFT.field_title')}>
                                            <Form.Item
                                                rules={[
                                                    { required: true, message: t('createNFT.field_title_rule_required') },
                                                    { max: 200, message: t('createNFT.field_title_rule_max') }
                                                ]}
                                                name="name"
                                            >
                                                <InputCustom placeholder={t('createNFT.field_title_placeholder')} />
                                            </Form.Item>
                                        </Label>
                                    </div>

                                    {/* description NFT input */}
                                    <div className="create-nft-block">
                                        <Label
                                            title={t('createNFT.field_description')}
                                            description={t('createNFT.field_description_desc')}
                                        >
                                            <Form.Item
                                                name="description"
                                                initialValue=""
                                                rules={[{ max: 1000, message: t('createNFT.field_desciption_rule_max') }]}
                                            >
                                                <InputCustom
                                                    type='textarea'
                                                    autoSize={true}
                                                    placeholder={t('createNFT.field_description_placeholder')}
                                                />
                                            </Form.Item>
                                        </Label>
                                    </div>

                                    {collectibleType === COLLECTIBLE_TYPE.MULTIPLE && (
                                        <div className="create-nft-block">
                                            <Label title={t('createNFT.numberOfCopies')} description={t('createNFT.amountOfTokens')}>
                                                <Form.Item
                                                    rules={[
                                                        { required: true, message: 'Number of copies cannot be blank' },
                                                        {
                                                            validator: (rule, value) => {
                                                                if (!isNaN(value) && value <= 0) {
                                                                    return Promise.reject(
                                                                        'Number of copies must be larger than or equal to 1'
                                                                    )
                                                                }

                                                                return Promise.resolve()
                                                            }
                                                        }
                                                    ]}
                                                    name="numberOfCopies"
                                                >
                                                    <InputCustom
                                                        onChange={e =>
                                                            onChangeValueInput(
                                                                e.target.value,
                                                                'numberOfCopies',
                                                                999999999999,
                                                                0
                                                            )
                                                        }
                                                        placeholder="E. g. 10"
                                                    />
                                                </Form.Item>
                                            </Label>
                                        </div>
                                    )}

                                    <div className="create-nft-block">
                                        <Label title={t('createNFT.field_properties')}>
                                            <PropertiesFieldGroup form={form} />
                                        </Label>
                                    </div>

                                    <ButtonCustom
                                        color="blue"
                                        fullWidth={true}
                                        type="submit"
                                        htmlType="submit"
                                        disabled={isFormError}
                                        className='br-16'
                                    >
                                        {t('createNFT.btn_create')}
                                    </ButtonCustom>
                                    {isFormError && (
                                        <p className="create-nft-fix-all-error"> {t('createNFT.text_fix_all_error')} </p>
                                    )}
                                </>
                            )}
                        </Form>
                        <div className="create-nft-form_preview__wrap">
                            <Label title={t('createNFT.text_preview')} className="create-nft-form_sticky">
                                <div
                                    className={classNames({
                                        'create-nft-form_preview': true,
                                        'create-nft-form_preview_isCard': !!uploadValue
                                    })}
                                >
                                    {uploadValue ? (
                                        <CardItemPreview
                                            disable
                                            isPreview
                                            className="create-nft-form_cart-item"
                                            data={convertData}
                                        />
                                    ) : (
                                        <span className="create-nft-form_preview__title">
                                            {t('createNFT.text_preview_placeholder')}
                                        </span>
                                    )}
                                </div>
                            </Label>
                        </div>
                    </div>
                </div>

                {isShowFixPriceModal && (
                    <FixPriceModal
                        onClose={() => setIsShowFixPriceModal(false)}
                        data={formValues}
                        listOfCollections={listOfCollections}
                        uploadFile={uploadFile}
                        previewFile={previewFile} //previewFile for upload mp3,mp4
                        idCollectionSelected={idCollectionSelect}
                    />
                )}

                {isShowOpenForBid && (
                    <OpenForBidModal
                        onClose={() => setIsShowOpenForBid(false)}
                        data={formValues}
                        listOfCollections={listOfCollections}
                        uploadFile={uploadFile}
                        previewFile={previewFile}
                        idCollectionSelected={idCollectionSelect}
                    />
                )}

                {isShowAuctionModal && (
                    <AuctionStepModal
                        onClose={() => setIsShowAuctionModal(false)}
                        data={formValues}
                        listOfCollections={listOfCollections}
                        uploadFile={uploadFile}
                        previewFile={previewFile} //previewFile for upload mp3,mp4
                        idCollectionSelected={idCollectionSelect}
                    />
                )}

                {isShowNotForSaleModal && (
                    <NotForSaleModal
                        onClose={() => setIsShowNotForSaleModal(false)}
                        data={formValues}
                        listOfCollections={listOfCollections}
                        uploadFile={uploadFile}
                        previewFile={previewFile} //previewFile for upload mp3,mp4
                        idCollectionSelected={idCollectionSelect}
                    />
                )}

                {isCollectionFollowStepModalVisible && (
                    <CollectionFollowStepModal visible={isCollectionFollowStepModalVisible} />
                )}

                <CreateCollectionModal
                    visible={isCreateCollectionModalVisible}
                    onClose={() => setCreateCollectionModalVisible(false)}
                    onCollectionFollowStepModalClose={onCollectionFollowStepModalClose}
                    onCollectionFollowStepModalOpen={onCollectionFollowStepModalOpen}
                    collectibleType={collectibleType}
                    networkType={networkType}
                />

                {minimumBidStartDateValue === 0 && (
                    <DateTimeModal
                        onChange={onMinimumBidStartDateChange}
                        visible={true}
                        disabledDate={time => {
                            return moment(time).isBefore(moment(minimumBidExpireDateValue).subtract(6, 'M'))
                        }}
                    />
                )}
                {minimumBidExpireDateValue === 0 && (
                    <DateTimeModal
                        onChange={onMinimumBidExpireDateChange}
                        visible={true}
                        disabledDate={time => {
                            if (typeof minimumBidStartDateValue === 'number')
                                return moment(time).isAfter(moment().add(6, 'M'))
                            return moment(time).isAfter(moment(minimumBidStartDateValue).add(6, 'M'))
                        }}
                    />
                )}
            </div>
        </DefaultLayout>
    )
}

export default CreateNft
