import React, {useMemo} from 'react';
import {useDispatch} from 'react-redux';
import {useConnect, useChainId, useAccount} from 'wagmi';

import {useResizeWindow} from 'utils/hook'
import * as message from 'utils/custom-message'
import MetaMask from 'assets/icons/metamask.svg';
import {loginWallet} from 'store/actions/user';
import {getMetaMask} from 'blockchain/utils';
import {NETWORKS} from 'constants/envs';
import env from 'env/index';
import { setCloseLoadingConnectWallet, setLoadingConnectWallet } from 'store/login.slice';
import { useTranslation } from 'react-i18next';

const MetaMaskCard = (props) => {
  const {t}= useTranslation();
  const {connectors, networkType} = props;
  const dispatch = useDispatch();
  const {connect} = useConnect();
  const chainId = useChainId();
  const account = useAccount();
  const [isMobile] = useResizeWindow(768)

  const metamask_deeplink = env.metadata.metamaskDeepLink;

  const selectedChainId = useMemo(() => NETWORKS[networkType].id, [networkType]);

  const connector = useMemo(() => {
    return getMetaMask(connectors);
  }, [connectors]);

  const onClickConnect = async () => {
    if (!connector) {
      if (isMobile && metamask_deeplink) {
        window.open(metamask_deeplink);
        return
      } else {
        dispatch(setCloseLoadingConnectWallet());
        window.open('https://metamask.io/download.html');
        return;
      }
    }

    try {
      dispatch(setLoadingConnectWallet());
      if (account.address && chainId) {
        dispatch(setCloseLoadingConnectWallet());
        dispatch(loginWallet({account: account.address.toLowerCase(), chainId}));
        return;
      }

      // await changeNetwork(networkType);
      connect({ connector, chainId: selectedChainId }, {
        onSuccess: async ({accounts, chainId}) => {
          dispatch(setCloseLoadingConnectWallet());
          dispatch(loginWallet({account: accounts[0].toLowerCase(), chainId}));
        },
        onError: (error) => {
          dispatch(setCloseLoadingConnectWallet());
          message.error(error.message.toString().split("\n")[0]);
        }
      });
    } catch (error) {
      dispatch(setCloseLoadingConnectWallet());
      message.error(error.message);
    }
  };

  return (
    <div className="connect-option"
      onClick={onClickConnect}>
      <img src={MetaMask}
        alt="metamask-icon"
        name="metamask-icon"
        className="icon"/>
      <span className="title-wallet">MetaMask</span>
      <span className="description-wallet">
        {t('connectWallet.metamask.desc')}
      </span>
    </div>
  );
};

export default MetaMaskCard;
