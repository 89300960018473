import React, {
  useState,
  useRef,
  useEffect,
  useCallback,
  useMemo,
  useContext,
} from "react";
import { Avatar, Button, Col, Form, Modal, Row, Tooltip } from "antd";
import { useSelector, useDispatch } from "react-redux";
import { useWindowSize } from "react-use";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import coverPlaceholder2 from "assets/images/cover-placeholder2.png";
import DefaultLayout from "components/layout/default-layout";
import {
  editUserProfile,
  setUpdateProfileState,
  updateUserAvatar,
  updateUserCover,
  closeUploadCover,
  confirmVerified,
} from "store/actions/user";
import { ButtonCustom, InputCustom } from "components/common";
import { SvgIcon } from "components/modules";
import { isUserAuthenticated } from "utils/auth";
import * as message from "utils/custom-message";
import AvatarPlaceholder from "assets/images/avatar-default-new-ui.png";
import CheckIcon from "assets/icons/check.png";
import Upload from "assets/icons/upload-2-line.png";
import Delete from "assets/icons/delete-bin-line.png";
import userService from "service/userService";
import "./style.scss";

import { useAuth } from "hooks/useAuth";
import { MAIN_TITLE, VERIFI_STATUS } from "constants/index";
import { Helmet } from "react-helmet";
import { SCREEN_SIZE } from "constants/index";
import env from "env/index";
import { AWS_CLOUDFRONT_ENDPOINT } from "constants/envs";
import { ThemeContext } from "ThemeProvider";
import {
  clearErrorVerifyTwitter,
  setCurrentUserProfile,
  setUserGetProfileSuccess,
  unlinkTwitter,
} from "store/user.slice";
import { shortenAddress } from "utils/func";

const COVER_MAX_SIZE = 15728640;
const AVATAR_MAX_SIZE = 5242880;
const AVATAR_SIZE = {
  width: 300,
  height: 300,
};
const COVER_SIZE = {
  width: 1440,
  height: 260,
};
const AVATAR = {
  big: 160,
  small: 100,
};

const EditProfile = () => {
  const { theme } = useContext(ThemeContext);
  const { t } = useTranslation();
  const { width } = useWindowSize();
  const uploadFileBtn = useRef();
  const socialFirt = useRef();
  const uploadFileCoverBtn = useRef();
  const [avatar, setAvatar] = useState("");
  const [isError, setIsError] = useState(false);
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [showMore, setShowMore] = useState(false);
  const [isDeleteaVata, setDeleteAvata] = useState(false);
  const [isShowLinkTwitter, setIsShowLinkTwitter] = useState(true);
  const [twitterUserName, setTwitterUserName] = useState("");
  const [customUrlValue, setCustomUrlValue] = useState(false);
  const [, handleSignWallet] = useAuth();

  const history = useHistory();
  const dispatch = useDispatch();

  const {
    updateProfileSuccess,
    error,
    previewAvatar,
    uploadingCoverImage,
    verifyTwitterStatus,
  } = useSelector((state) => state.user);
  const {
    username,
    email,
    description,
    shortUrl,
    id: profileId,
    websiteUrl,
    twitterUrl,
    telegramUrl,
    discordUrl,
    tiktokUrl,
    youtubeUrl,
    mediumUrl,
    facebookUrl,
    instagramUrl,
    avatarImg,
    coverImg,
    isVerifyTwitter,
    verifyStatus,
    customUrl,
    walletAddress,
  } = useSelector((state) => state.user.profile);

  const accountStore = useSelector(
    (state) => state?.user?.profile?.walletAddress
  );
  const verify_link = env.metadata.verifyLink;
  const tweetTwitter = env.metadata.tweetTwitterLink;
  const [form] = Form.useForm();
  const initialFormValues = useMemo(() => {
    return {
      name: username,
      customUrl: shortUrl,
      bio: description,
      personalSite: websiteUrl,
      email,
      twitterName: twitterUrl,
      tiktokName: tiktokUrl,
      telegramName: telegramUrl,
      youtubeName: youtubeUrl,
      mediumName: mediumUrl,
      facebookName: facebookUrl,
      discordName: discordUrl,
      instagramName: instagramUrl,
    };
  }, [
    description,
    shortUrl,
    email,
    username,
    websiteUrl,
    twitterUrl,
    telegramUrl,
    discordUrl,
    tiktokUrl,
    youtubeUrl,
    mediumUrl,
    facebookUrl,
    instagramUrl,
  ]);

  const getText = useCallback((key) => t(`editProfile.${key}`), [t]);
  const getErrorSocial = useCallback(
    (key) => t(`editProfile.${key}Invalid`),
    [t]
  );
  const onClickUpload = () => uploadFileBtn.current.click();

  const validateAvatar = (extension) => {
    const acceptableFormats = ["jpg", "png", "jpeg", "gif"];
    if (acceptableFormats.includes(extension)) {
      return true;
    }
    return false;
  };

  const onChangeAvatar = (e) => {
    setDeleteAvata(false);
    const file = e.target.files[0];
    let img = new Image();
    img.src = window.URL.createObjectURL(file);
    if (!file) return;
    img.onload = async () => {
      if (!validateAvatar(file.type.split("/")[1])) {
        return message.error(getText("fileNotSupported"));
      }
      if (file?.size > AVATAR_MAX_SIZE) {
        uploadFileBtn.current.value = "";
        return message.error(t("error.message.fileSizeExceeded", { size: 5 }));
      }
      if (img.width < AVATAR_SIZE?.width || img.height < AVATAR_SIZE.height) {
        uploadFileBtn.current.value = "";
        return message.error(
          t("error.message.fileSizeAtLeast", { width: 300, height: 300 })
        );
      }
      setAvatar(file);
    };
  };

  const deleteAvata = () => {
    setDeleteAvata(true);
    uploadFileBtn.current.value = "";
  };

  const getAvatarPlaceHolder = () => {
    if (avatar && !isDeleteaVata) return URL.createObjectURL(avatar);
    if (previewAvatar && !isDeleteaVata) return previewAvatar;
    if (avatarImg && !isDeleteaVata)
      return `${AWS_CLOUDFRONT_ENDPOINT}/${avatarImg}`;
    return AvatarPlaceholder;
  };

  const removeError = () => {
    setIsError(false);
  };

  const onFinishFailed = () => {
    setIsError(true);
  };

  const handleUploadAvatar = async () => {
    const userId = localStorage.getItem("userId");
    const uploadParams = {
      userId,
      imgFile: avatar,
    };
    return dispatch(updateUserAvatar(uploadParams));
  };

  const handleUpdateUserProfile = (values) => {
    if (values.email === email) delete values.email;
    if (values.customUrl === shortUrl) delete values.customUrl;

    for (let obj in values) {
      if (values[obj] === "") {
        values[obj] = null;
      }
    }

    return dispatch(editUserProfile(values));
  };

  const onFinish = async (values) => {
    dispatch(setUpdateProfileState({ state: null }));
    setIsOpenModal(true);

    const data = {
      shortUrl: values.customUrl,
      email: values.email,
      phoneNumber: "000",
      description: values.bio,
      username: values?.name?.trim(),
      websiteUrl: values.personalSite,
      twitterUrl: values.twitterName,
      telegramUrl: values.telegramName,
      discordUrl: values.discordName,
      facebookUrl: values.facebookName,
      instagramUrl: values.instagramName,
      mediumUrl: values.mediumName,
      youtubeUrl: values.youtubeName,
      tiktokUrl: values.tiktokName,
    };

    removeError();
    if (!isUserAuthenticated()) {
      const res = await handleSignWallet();
      if (res === false) return setIsOpenModal(false);
    }

    if (avatar) {
      await handleUploadAvatar();
    }
    if (isDeleteaVata) values.avatarUrl = null;
    if (updateProfileSuccess !== false) {
      return handleUpdateUserProfile(data);
    }
  };

  const urlValidator = async (rules, value) => {
    setCustomUrlValue(!!value);
    if (!value && !form.getFieldValue("email")) {
      form.setFields([{ name: "email", errors: null }]);
    }
    if (value) {
      const res = await userService.checkCustomUrl({ shortUrl: value });
      if (res[0]?.isExisting) {
        return Promise.reject(getText("urlTaken"));
      }
    }

    return Promise.resolve();
  };

  const emailValidator = async (rules, value) => {
    if (value) {
      const isValidFormat =
        // eslint-disable-next-line no-control-regex
        /(?:[\w!#$%&'*+/=?^_`{|}~-]+(?:\.[\w!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[\w](?:[\w-]*[\w])?\.)+[\w](?:[\w-]*[\w])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[\w-]*[\w]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/.test(
          value
        );
      if (isValidFormat) {
        if (
          value.split("@")[0].length > 64 ||
          value.split("@")[1].length > 255 ||
          value.split(".")[value.split(".").length - 1].length < 2
        ) {
          return Promise.reject(getText("invalidEmail"));
        } else {
          const res = await userService.checkEmail({ email: value });
          if (res[0]?.isExisting) {
            return Promise.reject(getText("emailTaken"));
          }
        }
      } else {
        return Promise.reject(getText("invalidEmail"));
      }

      return Promise.resolve();
    }

    return Promise.resolve();
  };

  const validatorUserSocial = (rules, value) => {
    const regex = /^[a-zA-Z0-9-_.]+$/.test(value);
    if (!regex && value) {
      return Promise.reject(getErrorSocial(rules.field));
    } else return Promise.resolve();
  };

  useEffect(() => {
    dispatch(setUpdateProfileState({ state: null }));
    setIsOpenModal(false);
    if (updateProfileSuccess === true) {
      history.push(shortUrl ? `/user/${shortUrl}` : `/user/${accountStore}`);
      message.success(getText("profileUpdated"));
    } else if (updateProfileSuccess === false) {
      message.error(error ? error.message : getText("updateProfileFailed"));
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accountStore, dispatch, error, getText, history, updateProfileSuccess]);

  const onClickUploadCover = () => uploadFileCoverBtn.current.click();

  const onChangeCover = async (e) => {
    const file = e.target.files[0];
    let img = new Image();
    img.src = window.URL.createObjectURL(file);
    if (!file) return;
    img.onload = async () => {
      if (!validateAvatar(file.type.split("/")[1])) {
        return message.error(t("editProfile.fileNotSupported"));
      }
      if (file?.size > COVER_MAX_SIZE) {
        uploadFileCoverBtn.current.value = "";
        return message.error(t("error.message.fileSizeExceeded", { size: 15 }));
      }
      if (img.width < COVER_SIZE?.width || img.height < COVER_SIZE.height) {
        uploadFileCoverBtn.current.value = "";
        return message.error(
          t("error.message.fileSizeAtLeast", { width: 1440, height: 260 })
        );
      }
      await handleUploadCoverImage(file);
    };
  };

  const handleUploadCoverImage = async (file) => {
    if (!isUserAuthenticated()) {
      const res = await handleSignWallet();
      if (res === false) return;
    }
    const uploadParams = {
      userId: profileId,
      imgFile: file,
    };

    dispatch(
      updateUserCover({
        params: uploadParams,
        account: { walletAddress: accountStore },
      })
    );
  };

  useEffect(() => {
    dispatch(closeUploadCover());
  }, []);

  useEffect(() => {
    if (!verifyTwitterStatus && error) {
      message.error(t("profile.verifyTwitterError"));
    }
    return () => {
      dispatch(clearErrorVerifyTwitter());
    };
  }, [dispatch, error, t, verifyTwitterStatus]);

  useEffect(() => {
    form.setFieldsValue(initialFormValues);
  }, [form, initialFormValues, accountStore]);

  const setShowMoreSocial = () => {
    if (showMore) socialFirt.current.scrollIntoView();
    setShowMore(!showMore);
  };

  const handleGetVerifiLink = useCallback(() => {
    if (verify_link) {
      window.open(verify_link);
    }
  }, [verify_link]);

  const handleRedirectVerifiTwitter = useCallback(() => {
    setIsShowLinkTwitter(false);
    const url = window.location.origin;
    const param = `I’m verifying my Twitter account with @Smartpocket. Check out my profile: ${url}/${
      customUrl ? customUrl : `user/${accountStore}`
    }`;
    window.open(`${tweetTwitter}?text=${param}`, "_blank");
  }, [customUrl, accountStore, tweetTwitter]);

  const handleConfirmVerified = useCallback(async () => {
    if (!accountStore) {
      history.push("/connect");
    } else {
      if (!isUserAuthenticated()) {
        const res = await handleSignWallet();
        if (res === false) return;
      }
      await dispatch(
        confirmVerified({ data: form.getFieldValue("twitterName") })
      );
      const [profileRes] = await userService.getUserInfo(accountStore);
      if (profileRes) {
        dispatch(setCurrentUserProfile(profileRes));
        dispatch(setUserGetProfileSuccess(profileRes));
      }
    }
  }, [accountStore, dispatch, form, handleSignWallet, history, accountStore]);

  const handleRemoveTwitterAccount = async () => {
    if (!isUserAuthenticated()) {
      const res = await handleSignWallet();
      if (res === false) return;
    }

    const [res, err] = await userService.unLinkTwitter();
    if (res) {
      form.setFieldsValue({ twitterName: "" });
      dispatch(unlinkTwitter());
      setTwitterUserName("");
      setIsShowLinkTwitter(true);
      const [profileRes] = await userService.getUserInfo(accountStore);
      if (profileRes) {
        dispatch(setCurrentUserProfile(profileRes));
        dispatch(setUserGetProfileSuccess(profileRes));
      }
      message.success(t("success.message.unverifiedTwitterSuccessfully"));
    } else if (err) {
      return message.error(t("error.message.errorWhenUnlinkingTwitter"));
    }
  };

  useEffect(() => {
    if (twitterUrl) {
      setTwitterUserName(twitterUrl);
    }
  }, [twitterUrl]);

  return (
    <DefaultLayout isActiveFooter={width > SCREEN_SIZE.tablet}>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{`${MAIN_TITLE} - Edit profile`}</title>
        <meta name="description" content={`${MAIN_TITLE} - Edit profile`} />
      </Helmet>
      <div className="update-profile-container">
        <div className="text-edit-title">{t("editProfile.editProfile")}</div>
        <div className="container-profile">
          <div className="container-edit-profile-banner">
            <img
              src={
                coverImg
                  ? `${AWS_CLOUDFRONT_ENDPOINT}/${coverImg}`
                  : coverPlaceholder2
              }
              alt=""
              className="cover-picture"
            />
            <div>
              {/* <ButtonCustom className="btn-edit" onClick={onClickUploadCover}>
                <span>{t("profile.editCover")}</span>
              </ButtonCustom> */}
              <div className="container-replace-banner">
                <div className="container-text-banner">
                  <div>{t("editProfile.replaceBannerImage")}</div>
                  <div>{t("editProfile.optimalBannerSize")}</div>
                </div>
                <ButtonCustom
                  // className="btn-edit"
                  onClick={onClickUploadCover}
                  style={{
                    width: "140px",
                    height: "36px",
                    borderRadius: "12px",
                  }}
                  color="blue"
                  type="submit"
                >
                  {/* <span>{getText("updateProfile")}</span> */}
                  <span>{t("editProfile.replaceImage")}</span>
                </ButtonCustom>
                <input
                  type="file"
                  id="actual-btn"
                  ref={uploadFileCoverBtn}
                  onChange={onChangeCover}
                  hidden
                />
              </div>
            </div>
          </div>
          <div className="container-select-profile-avatar">
            <Avatar
              size={width > SCREEN_SIZE.tablet ? AVATAR.small : AVATAR.small}
              icon={<img alt="avatar" src={getAvatarPlaceHolder()} />}
            />
            <div className="container-modify-avatar">
              <div className="address-text">
                <Tooltip title={walletAddress}>
                  <h5>{`${shortenAddress(walletAddress, 6, 6)}`}</h5>
                </Tooltip>
              </div>
              <div className="container-button-action-avata">
                <ButtonCustom
                  iconLeft={
                    <img
                      style={{ width: "20px", height: "20px" }}
                      src={Upload}
                      alt="Upload"
                    />
                  }
                  style={{ width: "auto" }}
                  color={"white"}
                  onClick={onClickUpload}
                  variant="outline-sub"
                >
                  <span>{getText("chooseFile")}</span>
                </ButtonCustom>
                <input
                  type="file"
                  id="actual-btn"
                  ref={uploadFileBtn}
                  onChange={onChangeAvatar}
                  hidden
                />
                <ButtonCustom
                  iconLeft={
                    <img
                      style={{ width: "20px", height: "20px" }}
                      src={Delete}
                      alt="Delete"
                    />
                  }
                  style={{ width: "auto" }}
                  color={"white"}
                  onClick={deleteAvata}
                  variant="outline-sub"
                >
                  <span>{t("editProfile.delete")}</span>
                </ButtonCustom>
              </div>
            </div>
          </div>
          <div className="container-basic-infomation">
            <Form
              form={form}
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
              onFieldsChange={() => removeError()}
              initialValues={initialFormValues}
              className="update-basic-profile-form"
            >
              <Row className="profile-form-row-antd">
                <Col md={12} sx={24}>
                  <div>
                    <span className="header-title">
                      {t("editProfile.basicInfo")}
                    </span>
                  </div>
                  <div className="input-group">
                    <div>
                      <p className="label">{getText("displayName")}</p>
                      <Form.Item
                        name="name"
                        rules={[
                          { max: 15, min: 4, message: getText("nameError") },
                        ]}
                      >
                        <InputCustom
                          size={"36px"}
                          placeholder={getText("displayNamePlaceholder")}
                        />
                      </Form.Item>
                    </div>
                    <div>
                      <p className="label">{getText("userName")}</p>
                      <Form.Item
                        name="customUrl"
                        rules={[
                          { max: 15, message: getText("customUrlLongError") },
                          {
                            pattern: new RegExp("^[a-zA-Z0-9_]*$"),
                            message: getText("urlCharacterError"),
                          },
                          {
                            // validator: debounce(urlValidator, 2000)
                            validator: urlValidator,
                          },
                        ]}
                      >
                        <InputCustom
                          placeholder={getText("customURLPlaceholder")}
                          prefix={
                            <span className="prefix">{getText("domain")}</span>
                          }
                        ></InputCustom>
                      </Form.Item>
                    </div>
                    <div>
                      <Row justify="space-between">
                        <span className="label">{getText("email")}</span>
                        <span>{t("editProfile.emailSubLine")}</span>
                      </Row>
                      <Form.Item
                        name="email"
                        rules={[
                          {
                            max: 320,
                            message: getText("emailLongError"),
                          },
                          {
                            validator: emailValidator,
                          },
                          {
                            required: customUrlValue,
                            message: getText("requiredEmail"),
                          },
                        ]}
                      >
                        <InputCustom
                          placeholder={getText("emailPlaceholder")}
                        />
                      </Form.Item>
                    </div>
                    <div>
                      <Row justify="space-between">
                        <span className="label">
                          {t("editProfile.verification")}
                        </span>
                        <ButtonCustom
                          color="blue"
                          iconRight={
                            <img
                              style={{ marginLeft: "8px", marginRight: 0 }}
                              src={CheckIcon}
                              alt="check"
                            />
                          }
                          disabled={verifyStatus === VERIFI_STATUS.APPROVE}
                          onClick={handleGetVerifiLink}
                          className="btn-get-verifi"
                        >
                          {verifyStatus === VERIFI_STATUS.APPROVE
                            ? t("editProfile.verified")
                            : t("editProfile.getVerified")}
                        </ButtonCustom>
                      </Row>
                      <div className="get-verifi-description">
                        {verifyStatus === VERIFI_STATUS.NONE ? (
                          <span>{t("editProfile.verificationSub")}</span>
                        ) : null}
                      </div>
                      {/* <Button
                                                        loading={loading}
                                                        disabled={verifyStatus !== VERIFI_STATUS.NONE}
                                                        onClick={handleConfirmVerified}
                                                        className="btn-get-verifi"
                                                    >
                                                        {verifyStatus === VERIFI_STATUS.PENDING ? 'Confirmed' : 'Confirm'}
                                                    </Button> */}
                    </div>
                    <div>
                      <p className="label">{getText("bio")}</p>
                      <Form.Item
                        name="bio"
                        rules={[{ max: 1000, message: getText("bioError") }]}
                      >
                        <InputCustom
                          type="textarea"
                          placeholder={getText("bioPlaceholder")}
                          className="input-text-area"
                          style={{ minHeight: "130px" }}
                        />
                      </Form.Item>
                    </div>
                  </div>
                </Col>
                <Col md={12} sx={24}>
                  <div>
                    <span className="header-title">
                      {t("editProfile.onTheWeb")}
                    </span>
                  </div>
                  <div className="input-group">
                    <div
                      className={
                        showMore
                          ? "scroll form-list-social"
                          : "form-list-social"
                      }
                    >
                      <div ref={socialFirt} className="form-item-social">
                        <p className="label">Facebook</p>
                        <Form.Item
                          name="facebookName"
                          rules={[
                            { max: 1000, message: getText("urlLongError") },
                            { validator: validatorUserSocial },
                          ]}
                        >
                          <InputCustom placeholder={t("editProfile.socialPlaceholder", {socialNetwork: "Facebook"})} />
                        </Form.Item>
                      </div>
                      <div className="form-item-social">
                        <Row justify="space-between">
                          <p className="label">Twitter</p>
                          <div>
                            {isVerifyTwitter &&
                            twitterUserName &&
                            twitterUrl === twitterUserName ? (
                              <div className="twitter-verified">
                                {t("editProfile.verified")}
                              </div>
                            ) : (
                              <>
                                {!isShowLinkTwitter && (
                                  <Button
                                    className={`btn-verifi-twitter ${
                                      theme === "dark-theme" &&
                                      "btn-verifi-twitter-dark"
                                    }`}
                                    onClick={handleConfirmVerified}
                                    type="link"
                                  >
                                    {t("editProfile.check")}
                                  </Button>
                                )}
                                <Button
                                  className={`${
                                    isShowLinkTwitter ? "btn-link-default" : ""
                                  } btn-verifi-twitter ${
                                    theme === "dark-theme" &&
                                    "btn-verifi-twitter-dark"
                                  } `}
                                  onClick={handleRedirectVerifiTwitter}
                                  type="link"
                                >
                                  {isShowLinkTwitter
                                    ? t("editProfile.link")
                                    : t("editProfile.tweetAgain")}
                                </Button>
                              </>
                            )}
                          </div>
                        </Row>

                        <Form.Item
                          name="twitterName"
                          rules={[
                            { max: 1000, message: getText("urlLongError") },
                            { validator: validatorUserSocial },
                          ]}
                        >
                          <InputCustom
                            onChange={(e) => setTwitterUserName(e.target.value)}
                            placeholder={t("editProfile.socialPlaceholder", {socialNetwork: "Twitter"})}
                          />
                        </Form.Item>

                        {twitterUrl &&
                          isVerifyTwitter &&
                          (theme === "dark-theme" ? (
                            <i
                              className="ri-close-fill remove-twitter-icon"
                              style={{ color: "#fff" }}
                              onClick={handleRemoveTwitterAccount}
                            ></i>
                          ) : (
                            <i
                              className="ri-close-fill remove-twitter-icon"
                              onClick={handleRemoveTwitterAccount}
                            ></i>
                          ))}
                      </div>
                      <div className="form-item-social">
                        <p className="label">Telegram</p>
                        <Form.Item
                          name="telegramName"
                          rules={[
                            { max: 1000, message: getText("urlLongError") },
                            { validator: validatorUserSocial },
                          ]}
                        >
                          <InputCustom placeholder={t("editProfile.socialPlaceholder", {socialNetwork: "Telegram"})} />
                        </Form.Item>
                      </div>
                      <div className="form-item-social">
                        <p className="label">Youtube</p>
                        <Form.Item
                          name="youtubeName"
                          rules={[
                            { max: 1000, message: getText("urlLongError") },
                            { validator: validatorUserSocial },
                          ]}
                        >
                          <InputCustom placeholder={t("editProfile.socialPlaceholder", {socialNetwork: "Youtube"})} />
                        </Form.Item>
                      </div>
                      <div className="form-item-social">
                        <p className="label">Discord</p>
                        <Form.Item
                          name="discordName"
                          rules={[
                            { max: 1000, message: getText("urlLongError") },
                            { validator: validatorUserSocial },
                          ]}
                        >
                          <InputCustom placeholder={t("editProfile.socialPlaceholder", {socialNetwork: "Discord"})} />
                        </Form.Item>
                      </div>
                      <div className="form-item-social">
                        <p className="label">Instagram</p>
                        <Form.Item
                          name="instagramName"
                          rules={[
                            { max: 1000, message: getText("urlLongError") },
                            { validator: validatorUserSocial },
                          ]}
                        >
                          <InputCustom placeholder={t("editProfile.socialPlaceholder", {socialNetwork: "Instagram"})} />
                        </Form.Item>
                      </div>
                      <div className="form-item-social">
                        <p className="label">Medium</p>
                        <Form.Item
                          name="mediumName"
                          rules={[
                            { max: 1000, message: getText("urlLongError") },
                            { validator: validatorUserSocial },
                          ]}
                        >
                          <InputCustom placeholder={t("editProfile.socialPlaceholder", {socialNetwork: "Medium"})} />
                        </Form.Item>
                      </div>
                      <div className="form-item-social">
                        <p className="label">Tiktok</p>
                        <Form.Item
                          name="tiktokName"
                          rules={[
                            { max: 1000, message: getText("urlLongError") },
                            { validator: validatorUserSocial },
                          ]}
                        >
                          <InputCustom placeholder={t("editProfile.socialPlaceholder", {socialNetwork: "Tiktok"})} />
                        </Form.Item>
                      </div>
                      {/* <ButtonCustom color="blue" className="btn-show-more" onClick={setShowMoreSocial}>
                                                    {showMore ? 'Hidden' : 'Show more'}
                                                </ButtonCustom> */}
                    </div>
                    <ButtonCustom
                      color="blue"
                      className="btn-show-more"
                      onClick={setShowMoreSocial}
                    >
                      {showMore
                        ? t("profile.hidden")
                        : t("editProfile.showMore")}
                    </ButtonCustom>
                    <div>
                      <p className="label">{getText("personalSite")}</p>
                      <Form.Item
                        name="personalSite"
                        rules={[
                          { max: 1000, message: getText("urlLongError") },
                          {
                            pattern: new RegExp(
                              "^(?:(?:http(?:s)?|ftp)://)(?:\\S+(?::(?:\\S)*)?@)?(?:(?:[a-z0-9\u00a1-\uffff](?:-)*)*(?:[a-z0-9\u00a1-\uffff])+)(?:\\.(?:[a-z0-9\u00a1-\uffff](?:-)*)*(?:[a-z0-9\u00a1-\uffff])+)*(?:\\.(?:[a-z0-9\u00a1-\uffff]){2,})(?::(?:\\d){2,5})?(?:/(?:\\S)*)?$"
                            ),
                            message: getText("invalidUrl"),
                          },
                        ]}
                      >
                        <InputCustom
                          placeholder={getText("personalSitePlaceholder")}
                        />
                      </Form.Item>
                    </div>
                  </div>
                </Col>
              </Row>
              <p className="sub">{getText("personalSiteSub")}</p>
              <p className="label">{getText("guide")}</p>
              <ButtonCustom
                style={{
                  marginTop: "32px",
                  borderRadius: "16px",
                  marginBottom: "28px",
                }}
                color="blue"
                type="submit"
                htmlType="submit"
                disabled={isError}
              >
                <span>{getText("updateProfile")}</span>
              </ButtonCustom>
              {isError && (
                <p className="err-underline">{getText("fixError")}</p>
              )}
            </Form>
            <Modal
              visible={isOpenModal}
              footer={null}
              closable={false}
              keyboard={false}
              className="modal-custom"
            >
              <SvgIcon name="loading-icon" className="loading-icon" />
              <p>{getText("updatingProfile")}</p>
            </Modal>
            <Modal
              className="profile-updating-cover-modal"
              centered
              footer={null}
              visible={uploadingCoverImage}
            >
              <div className="profile-modal-wrapper">
                <span className="title">{t("profile.updateCover")}</span>
                <span className="description">{t("profile.uploading")}</span>
              </div>
            </Modal>
          </div>
        </div>
      </div>
    </DefaultLayout>
  );
};

export default EditProfile;
