import './style.scss'
import React from 'react'
import { Input, InputNumber } from 'antd'
import PropTypes from 'prop-types'

import Password from 'antd/lib/input/Password'

const { TextArea } = Input

const InputCustom = props => {
    const {
        name,
        className,
        style,
        formatter,
        type,
        placeholder,
        rules,
        addonAfter,
        addonBefore,
        allowClear,
        bordered,
        defaultValue,
        disabled,
        id,
        maxLength,
        prefix,
        size,
        suffix,
        value,
        onChange,
        onPressEnter,
        children,
        parser,
        max,
        min,
        autoComplete,
        visibilityToggle,
        defaultType,
        autoResize,
        variant = 'outline',
        ...restProps
    } = props

    let overrideClassNames = ['ant-input-custom']

    const initArgs = () => {
        if (className) {
            overrideClassNames.push(className)
        }

        switch (variant) {
            case 'outline':
                overrideClassNames.push('ant-input-custom--outline');
                break;
            case 'filled':
                overrideClassNames.push('ant-input-custom--filled');
                break;
            default:
        }
    }

    initArgs()

    const renderComp = () => {
        if (type === 'password') {
            return (
                <Password
                    name={name}
                    className={overrideClassNames.join(' ')}
                    style={style}
                    formatter={formatter}
                    type={type}
                    placeholder={placeholder}
                    rules={rules}
                    addonAfter={addonAfter}
                    addonBefore={addonBefore}
                    allowClear={allowClear}
                    bordered={bordered}
                    defaultValue={defaultValue}
                    disabled={disabled}
                    id={id}
                    maxLength={maxLength}
                    prefix={prefix}
                    size={size}
                    suffix={suffix}
                    value={value}
                    onChange={onChange}
                    onPressEnter={onPressEnter}
                    parser={parser}
                    autoComplete={autoComplete}
                    visibilityToggle={visibilityToggle}
                    {...restProps}
                >
                    {children}
                </Password>
            )
        }
        if (type === 'number') {
            return (
                <InputNumber
                    name={name}
                    className={overrideClassNames.join(' ')}
                    style={style}
                    formatter={formatter}
                    placeholder={placeholder}
                    rules={rules}
                    addonAfter={addonAfter}
                    addonBefore={addonBefore}
                    allowClear={allowClear}
                    bordered={bordered}
                    defaultValue={defaultValue}
                    disabled={disabled}
                    id={id}
                    maxLength={maxLength}
                    prefix={prefix}
                    size={size}
                    suffix={suffix}
                    value={value}
                    onChange={onChange}
                    onPressEnter={onPressEnter}
                    parser={parser}
                    max={max}
                    min={min}
                    autoComplete={autoComplete}
                    {...restProps}
                >
                    {children}
                </InputNumber>
            )
        }
        if (type === 'textarea') {
            return (
                <TextArea
                    name={name}
                    className={overrideClassNames.join(' ')}
                    style={style}
                    formatter={formatter}
                    placeholder={placeholder}
                    rules={rules}
                    allowClear={allowClear}
                    bordered={bordered}
                    defaultValue={defaultValue}
                    disabled={disabled}
                    id={id}
                    maxLength={maxLength}
                    prefix={prefix}
                    size={size}
                    suffix={suffix}
                    value={value}
                    onChange={onChange}
                    onPressEnter={onPressEnter}
                    parser={parser}
                    max={max}
                    min={min}
                    autoComplete={autoComplete}
                    autoSize={autoResize}
                    {...restProps}
                >
                    {children}
                </TextArea>
            )
        } else {
            return (
                <Input
                    name={name}
                    className={overrideClassNames.join(' ')}
                    style={style}
                    formatter={formatter}
                    type={defaultType}
                    placeholder={placeholder}
                    rules={rules}
                    addonAfter={addonAfter}
                    addonBefore={addonBefore}
                    allowClear={allowClear}
                    bordered={bordered}
                    defaultValue={defaultValue}
                    disabled={disabled}
                    id={id}
                    maxLength={maxLength}
                    prefix={prefix}
                    size={size}
                    suffix={suffix}
                    value={value}
                    onChange={onChange}
                    onPressEnter={onPressEnter}
                    parser={parser}
                    max={max}
                    min={min}
                    autoComplete={autoComplete}
                    {...restProps}
                >
                    {children}
                </Input>
            )
        }
    }

    return renderComp()
}

InputCustom.propTypes = {
    name: PropTypes.string,
    children: PropTypes.any,
    formatter: PropTypes.any,
    className: PropTypes.string,
    style: PropTypes.any,
    type: PropTypes.string,
    placeholder: PropTypes.string,
    rules: PropTypes.array,
    addonAfter: PropTypes.node,
    addonBefore: PropTypes.node,
    allowClear: PropTypes.bool,
    bordered: PropTypes.bool,
    defaultValue: PropTypes.any,
    disabled: PropTypes.bool,
    id: PropTypes.string,
    maxLength: PropTypes.number,
    prefix: PropTypes.node,
    size: PropTypes.string,
    suffix: PropTypes.node,
    value: PropTypes.any,
    onChange: PropTypes.func,
    onPressEnter: PropTypes.func,
    parser: PropTypes.func,
    customStyle: PropTypes.any,
    max: PropTypes.number,
    min: PropTypes.number,
    autoComplete: PropTypes.string,
    defaultType: PropTypes.string
}

export default InputCustom
