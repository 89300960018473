import { Menu, Affix, Tooltip } from 'antd'
import { useHistory } from 'react-router-dom'
import React, { useContext, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import _ from 'lodash'
import copy from 'copy-to-clipboard'
import { useChainId, useConnectors, useDisconnect } from 'wagmi'
import * as message from 'utils/custom-message'

import { shortenAddress } from 'utils/func'
import env from 'env/index'
import { TOKEN_ICON, PAYMENT_TOKEN } from 'constants/index'
import { ThemeContext } from '../../../../../ThemeProvider'
import '../style.scss'
import { NFT_STATUS } from 'store/constants/nft'
import { logout } from 'store/login.slice'

const DropdownMenu = props => {
    const { theme } = useContext(ThemeContext)
    const { handleMenuClick, currenciesAmount } = props
    const { t } = useTranslation()
    const { profile } = useSelector(state => state.user)
    const history = useHistory()
    const chainId = useChainId();
    const [isCopy, setIsCopy] = useState(false)
    const dispatch = useDispatch()
    const connectors = useConnectors();
    const { disconnect } = useDisconnect()

    const handleDisconnect = async (e) => {
        e.stopPropagation();
        dispatch(logout({ disconnect, connectors }));
    };

    const handleRedirectMyItems = () => {
        if (profile.username || profile.walletAddress) {
            history.push({
                pathname: profile?.shortUrl ? `/${profile?.shortUrl}` : `/user/${profile.walletAddress}`,
                state: { activeOwnerTab: true }
            })
            return
        }

        history.push('/connect')
    }

    const handleRedirectMyFavorites = () => {
        if (profile.username || profile.walletAddress) {
            history.push({
                pathname: profile?.shortUrl ? `/${profile?.shortUrl}` : `/user/${profile.walletAddress}`,
                state: { activeOwnerTab: true, activeTabSub: NFT_STATUS.FAVORITE }
            })
            return
        }

        history.push('/connect')
    }

    const handleRedirectMyCollections = () => {
        if (profile.username || profile.walletAddress) {
            history.push({
                pathname: profile?.shortUrl ? `/${profile?.shortUrl}` : `/user/${profile.walletAddress}`,
                state: { activeOwnerTab: true, activeTab: '6' }
            })
            return
        }

        history.push('/connect')
    }

    const copyAddressAccount = () => {
        if (isCopy) {
            message.error(t('error.message.dontClickCopy'))
            return
        }
        setIsCopy(true)
        copy(profile.walletAddress || profile.username)
        message.success(t('common.copySuccess'))
        setTimeout(() => {
            setIsCopy(false)
        }, 3500)
    }

    return (
        <Affix offsetTop={78}>
            <div className={`dropdown-menu ${theme === 'dark-theme' ? 'dark-dropdown-menu' : ''}`}>
                <Menu className="balance-list" onClick={handleMenuClick}>
                    <Menu.Item key="1" className="user-address">
                        <Tooltip title={profile.username || profile.walletAddress} placement="topLeft">
                            <div className="address" onClick={copyAddressAccount}>
                                {shortenAddress(profile.walletAddress, 10, 7)}
                                <i className="ri-file-copy-line"></i>
                            </div>
                        </Tooltip>
                    </Menu.Item>
                    {profile.name ? null : (
                        <Menu.Item key="2" className="action-item">
                            <div className="set-display-name" onClick={() => history.push('/edit-profile')}>
                                {t('header.menu.setDisplayName')}
                            </div>
                        </Menu.Item>
                    )}
                    {chainId === env.metadata.networks.bsc.id ? (
                        <>
                            <Menu.Item key="3" className="banlance-item">
                                <div className="balance d-flex align-items-center">
                                    <img src={TOKEN_ICON[PAYMENT_TOKEN.BNB]} className="avatar" alt="" />
                                    <div className="balance-price">
                                        <div className="title">{t('header.menu.balance')}</div>
                                        <div className="price">
                                            {parseFloat(currenciesAmount.nativeToken)
                                                ? Number(parseFloat(currenciesAmount.nativeToken).toFixed(6))
                                                : '0.00'}{' '}
                                            BNB
                                        </div>
                                    </div>
                                </div>
                            </Menu.Item>
                            <Menu.Item key="4" className="banlance-item">
                                <div className="balance d-flex align-items-center">
                                    <img src={TOKEN_ICON[PAYMENT_TOKEN.WBNB]} className="avatar" alt="" />
                                    <div className="balance-price">
                                        <div className="title">{t('header.menu.balance')}</div>
                                        <div className="price">
                                            {parseFloat(currenciesAmount.wbnbBalance)
                                                ? Number(parseFloat(currenciesAmount.wbnbBalance).toFixed(6))
                                                : '0.00'}{' '}
                                            WBNB
                                        </div>
                                    </div>
                                </div>
                            </Menu.Item>
                            <Menu.Item key="5" className="banlance-item">
                                <div className="balance d-flex align-items-center">
                                    <img src={TOKEN_ICON[PAYMENT_TOKEN.BUSD]} className="avatar" alt="" />
                                    <div className="balance-price">
                                        <div className="title">{t('header.menu.balance')}</div>
                                        <div className="price">
                                            {parseFloat(currenciesAmount.busdBalance)
                                                ? Number(parseFloat(currenciesAmount.busdBalance).toFixed(6))
                                                : '0.00'}{' '}
                                            BUSD
                                        </div>
                                    </div>
                                </div>
                            </Menu.Item>
                            <Menu.Item key="6" className="banlance-item">
                                <div className="balance d-flex align-items-center">
                                    <img src={TOKEN_ICON[PAYMENT_TOKEN.SP]} className="avatar" alt="" />
                                    <div className="balance-price">
                                        <div className="title">{t('header.menu.balance')}</div>
                                        <div className="price">
                                            {parseFloat(currenciesAmount.spBalance)
                                                ? Number(parseFloat(currenciesAmount.spBalance).toFixed(6))
                                                : '0.00'}{' '}
                                            SP
                                        </div>
                                    </div>
                                </div>
                            </Menu.Item>
                        </>
                    ) : chainId === env.metadata.networks.ethereum.id ? (
                        <>
                            <Menu.Item key="3" className="banlance-item">
                                <div className="balance d-flex align-items-center">
                                    <img src={TOKEN_ICON[PAYMENT_TOKEN.ETH]} className="avatar" alt="" />
                                    <div className="balance-price">
                                        <div className="title">{t('header.menu.balance')}</div>
                                        <div className="price">
                                            {parseFloat(currenciesAmount.nativeToken)
                                                ? Number(parseFloat(currenciesAmount.nativeToken).toFixed(6))
                                                : '0.00'}{' '}
                                            ETH
                                        </div>
                                    </div>
                                </div>
                            </Menu.Item>
                            <Menu.Item key="4" className="banlance-item">
                                <div className="balance d-flex align-items-center">
                                    <img src={TOKEN_ICON[PAYMENT_TOKEN.WETH]} className="avatar" alt="" />
                                    <div className="balance-price">
                                        <div className="title">{t('header.menu.balance')}</div>
                                        <div className="price">
                                            {parseFloat(currenciesAmount.wethBalance)
                                                ? Number(parseFloat(currenciesAmount.wethBalance).toFixed(6))
                                                : '0.00'}{' '}
                                            WETH
                                        </div>
                                    </div>
                                </div>
                            </Menu.Item>
                            <Menu.Item key="5" className="banlance-item">
                                <div className="balance d-flex align-items-center">
                                    <img src={TOKEN_ICON[PAYMENT_TOKEN.USDT]} className="avatar" alt="" />
                                    <div className="balance-price">
                                        <div className="title">{t('header.menu.balance')}</div>
                                        <div className="price">
                                            {parseFloat(currenciesAmount.usdtBalance)
                                                ? Number(parseFloat(currenciesAmount.usdtBalance).toFixed(6))
                                                : '0.00'}{' '}
                                            USDT
                                        </div>
                                    </div>
                                </div>
                            </Menu.Item>
                        </>
                    ) : chainId === env.metadata.networks.polygon.id ? (
                        <>
                            <Menu.Item key="3" className="banlance-item">
                                <div className="balance d-flex align-items-center">
                                    <img src={TOKEN_ICON[PAYMENT_TOKEN.MATIC]} className="avatar" alt="" />
                                    <div className="balance-price">
                                        <div className="title">{t('header.menu.balance')}</div>
                                        <div className="price">
                                            {parseFloat(currenciesAmount.nativeToken)
                                                ? Number(parseFloat(currenciesAmount.nativeToken).toFixed(6))
                                                : '0.00'}{' '}
                                            MATIC
                                        </div>
                                    </div>
                                </div>
                            </Menu.Item>
                            <Menu.Item key="4" className="banlance-item">
                                <div className="balance d-flex align-items-center">
                                    <img src={TOKEN_ICON[PAYMENT_TOKEN.WETH]} className="avatar" alt="" />
                                    <div className="balance-price">
                                        <div className="title">{t('header.menu.balance')}</div>
                                        <div className="price">
                                            {parseFloat(currenciesAmount.wethBalance)
                                                ? Number(parseFloat(currenciesAmount.wethBalance).toFixed(6))
                                                : '0.00'}{' '}
                                            WETH
                                        </div>
                                    </div>
                                </div>
                            </Menu.Item>
                        </>
                    ) : null}

                    <Menu.Divider />
                    <Menu.Item key="10" className="item-redirect">
                        <div className="d-flex flex-row align-items-center" onClick={handleRedirectMyItems}>
                            <i className="ri-landscape-line"></i>
                            <span style={{ marginLeft: '4px' }}>{t('header.menu.myItems')}</span>
                        </div>
                    </Menu.Item>
                    <Menu.Item key="11" className="item-redirect">
                        <div className="d-flex flex-row align-items-center" onClick={handleRedirectMyFavorites}>
                            <i className="ri-service-line"></i>
                            <span style={{ marginLeft: '4px' }}>{t('header.headerNav.myFav')}</span>
                        </div>
                    </Menu.Item>
                    <Menu.Item key="12" className="item-redirect">
                        <div className="d-flex flex-row align-items-center" onClick={handleRedirectMyCollections}>
                            <i className="ri-layout-3-line"></i>
                            <span style={{ marginLeft: '4px' }}>{t('header.headerNav.myCollections')}</span>
                        </div>
                    </Menu.Item>

                    <Menu.Item key="13" className="item-redirect">
                        <div className="d-flex" onClick={() => history.push('/edit-profile')}>
                            <i className="ri-pencil-line"></i>
                            <span style={{ marginLeft: '4px' }}>{t('header.menu.editProfile')}</span>
                        </div>
                    </Menu.Item>
                    <Menu.Item key="14" className="item-redirect">
                        <div onClick={handleDisconnect} className="d-flex">
                            <i className="ri-logout-circle-line"></i>
                            <span style={{ marginLeft: '4px' }}> {t('header.menu.disconnect')} </span>
                        </div>
                    </Menu.Item>
                </Menu>
            </div>
        </Affix>
    )
}

export default DropdownMenu
