import React from 'react'
import { Modal, DatePicker } from 'antd'
import moment from 'moment'

//style
import styles from './style.module.scss'

const DateTimePicker = ({ visible, onClose, onChange, disabledDate }) => {
    const handlePicker = value => {
        if (value && onChange) {
            onChange(moment(value._d)?.format('YYYY-MM-DD HH:mm:ss'))
        }
    }

    return (
        <Modal
            className={styles.dateTimeModal}
            onCancel={() => {
                onChange(moment()?.format('YYYY-MM-DD hh:mm:ss'))
            }}
            visible={visible}
        >
            {visible && (
                <DatePicker
                    disabledDate={disabledDate}
                    className={styles.dateTimeModal}
                    showTime
                    open={visible}
                    onOk={handlePicker}
                />
            )}
        </Modal>
    )
}

export default DateTimePicker
