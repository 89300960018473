import React, { useState, useEffect, useCallback, useMemo } from 'react'
import { useSelector } from 'react-redux'
import { Row, Col, Checkbox, Modal, Tooltip } from 'antd'
import BigNumber from 'bignumber.js'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { useWindowSize } from 'react-use'
import { useChainId } from 'wagmi'
import { SOCKET_EVENTS } from 'constants/index'
import { socket } from 'config'

import { ButtonCustom } from 'components/common'
import * as message from 'utils/custom-message'
import { ERROR_CODE_USER_DENIED_METAMASK, FILE_EXTENSION } from 'constants/index'
import { handleAtomicMatchForAcceptBid, isUserApprovedERC20, handleUserApproveERC20 } from 'blockchain/utils'
import { CONTRACT_ADDRESS } from 'constants/envs'
import { exportFileFormat } from 'utils/file'
import './style.scss'
import { convertImage } from 'utils/image'

const AcceptOfferModal = ({ onCloseModal, onOpenProcessModal, offerData, onCloseProcessModal }) => {
    const { t } = useTranslation()
    const { width } = useWindowSize()
    const chainId = useChainId();
    const { previewImage, collection, title, nftUrl, nftImagePreview, royalty, id: nftId } = useSelector(
        state => state.collectible.data
    )
    const profile = useSelector(state => state.user?.profile)
    const { MARKET_FEE } = useSelector(state => state.system)
    const [isCheckTerm, setIsCheckTerm] = useState(false)
    const [isLoading, setIsLoading] = useState(false)
    const [isApprovedERC20Token, setIsApprovedERC20Token] = useState(false)
    const [isApprovingERC20Token, setIsApprovingERC20Token] = useState(false)

    const { price, id, currencyToken, signatureSale, quantity } = offerData

    const marketFee = useMemo(
        () => (signatureSale?.takerRelayerFee ? signatureSale?.takerRelayerFee / 10000 : MARKET_FEE),
        [MARKET_FEE, signatureSale?.takerRelayerFee]
    )

    const onCheckTerm = () => {
        setIsCheckTerm(!isCheckTerm)
    }

    const onAccept = async () => {
        setIsLoading(true)

        const [result, errorAtomicMatch] = await handleAtomicMatchForAcceptBid({ ...signatureSale }, chainId)
        if (errorAtomicMatch) {
            onCloseModal()
            if (errorAtomicMatch?.code === ERROR_CODE_USER_DENIED_METAMASK) {
                return message.error(t('error.message.declinedActionWallet'))
            }
            return message.error(t('error.message.buyerDoesNotHaveEnoughMoney'))
        }

        if (!result?.hash) return

        onOpenProcessModal(result?.hash, collection?.address, id)
    }

    const getDisplayImage = () => {
        const fileExtension = exportFileFormat(nftUrl)
        if (fileExtension === FILE_EXTENSION.MP4 || fileExtension === FILE_EXTENSION.MPEG)
            return convertImage(previewImage)
        return convertImage(nftUrl)
    }

    const onApproveERC20 = async () => {
        setIsApprovingERC20Token(true)
        const [, error] = await handleUserApproveERC20(
            CONTRACT_ADDRESS[collection.networkType].proxy,
            CONTRACT_ADDRESS[collection.networkType][currencyToken],
            chainId,
            collection.networkType,
        )
        if (error) {
            if (error?.code === ERROR_CODE_USER_DENIED_METAMASK) {
                message.error(t('error.message.declinedActionWallet'))
            }
            message.error(t('error.message.somethingWentWrong'))
            onCloseModal()
            return
        }
        setIsApprovingERC20Token(false)
        setIsApprovedERC20Token(true)
    }

    const checkApproveERC20 = useCallback(async () => {
        const isUserApprovedERC20Token = await isUserApprovedERC20(
            CONTRACT_ADDRESS[collection.networkType].proxy,
            CONTRACT_ADDRESS[collection.networkType][currencyToken],
            profile.walletAddress,
            chainId,
            collection.networkType
        )
        setIsApprovedERC20Token(isUserApprovedERC20Token)
    }, [profile.walletAddress, currencyToken, collection.networkType, chainId])

    useEffect(() => {
        checkApproveERC20()
    }, [checkApproveERC20])

    useEffect(() => {
        if (socket) {
            socket.on(SOCKET_EVENTS.TRANSFER_NFT_SUCCESS, res => {
                if (
                    (res?.data?.toUserId === profile?.id || res?.data?.fromUserId === profile?.id) &&
                    res?.data?.nftId === nftId
                ) {
                    onCloseProcessModal()
                }
                return () => socket.off(SOCKET_EVENTS.TRANSFER_NFT_SUCCESS)
            })
        }
    }, [profile?.id, nftId, onCloseProcessModal])

    return (
        <Modal
            visible={true}
            onOk={onCloseModal}
            onCancel={onCloseModal}
            footer={null}
            closable={false}
            className={'custom-buy-modal'}
        >
            <div className="accept-modal-container">
                <p className="checkout-title">{t('nftDetail.acceptOffer')}</p>
                <Col className="checkout-detail">
                    <div className="checkout-detail__header">
                        <p className="bold-text">{t('nftDetail.item')}</p>
                    </div>
                    {width >= 576 ? (
                        <Row align="center" justify="space-between">
                            <div>
                                <div className="nft-info">
                                    {(nftUrl || previewImage) && (
                                        <img alt="asset" src={getDisplayImage()} className="asset-img" />
                                    )}
                                    <div>
                                        <p className="collection-text">
                                            <span>{`${t('nftDetail.collection')} `}</span>
                                            <Link
                                                to={`/collection/${collection?.shortUrl || collection?.id || collection?.address
                                                    }`}
                                            >
                                                {collection?.name}
                                            </Link>
                                        </p>
                                        <p className="nft-info-name">{title}</p>
                                        <p className="royalty-display">
                                            <span className="text">Royalties:</span>
                                            <span className="royalty">{` ${parseFloat(
                                                (Number(royalty) || Number(collection.royalty) * 1).toFixed(2)
                                            )}%`}</span>
                                            <Tooltip title={t('nftDetail.royalties')} overlayClassName="custom-tooltip">
                                                <i className="ri-error-warning-line"></i>
                                            </Tooltip>
                                        </p>
                                    </div>
                                </div>

                                <p className="bold-text">{t('nftDetail.fees')}</p>
                            </div>

                            <div>
                                <p>{`${BigNumber(price * (quantity || 1))
                                    .decimalPlaces(6)
                                    .toFormat()
                                    .toString()} ${currencyToken.toUpperCase()}`}</p>
                            </div>
                        </Row>
                    ) : (
                        <div className="nft-info-mobile">
                            <div>
                                {(nftUrl || nftImagePreview) && (
                                    <img alt="asset" src={getDisplayImage()} className="asset-img" />
                                )}
                                <div>
                                    <p>{title}</p>
                                    <div>
                                        <p className="collection-text">
                                            <span>{`${t('nftDetail.collection')} `}</span>
                                            <Link to={`/collection/${collection?.shortUrl}`}>{collection?.name}</Link>
                                            <p className="royalty-display">
                                                <span className="text">Royalties:</span>
                                                <span className="royalty">{` ${parseFloat(
                                                    (Number(royalty) || Number(collection.royalty) * 1).toFixed(2)
                                                )}%`}</span>
                                                <Tooltip
                                                    title={t('nftDetail.royalties')}
                                                    overlayClassName="custom-tooltip"
                                                >
                                                    <i className="ri-error-warning-line"></i>
                                                </Tooltip>
                                            </p>
                                        </p>
                                        <p>
                                            <span className="bold-text">{`${BigNumber(price * (quantity || 1))
                                                .decimalPlaces(6)
                                                .toFormat()
                                                .toString()} ${currencyToken.toUpperCase()}`}</span>
                                        </p>
                                        <p className="bold-text">{t('nftDetail.fees')}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                    <Row align="center" justify="space-between">
                        <p className="light-text">{t('nftDetail.serviceFee')}</p>
                        <div>
                            <p className="light-text">{`${BigNumber(price * (quantity || 1) * marketFee)
                                .decimalPlaces(8)
                                .toFormat()
                                .toString()} ${currencyToken.toUpperCase()}`}</p>
                        </div>
                    </Row>
                    <Row align="center" justify="space-between">
                        <p className="bold-text">{t('nftDetail.totalEarnings')}</p>
                        <div>
                            <p>{`${BigNumber(price * (quantity || 1) * (1 - marketFee))
                                .decimalPlaces(8)
                                .toFormat()
                                .toString()} ${currencyToken.toUpperCase()}`}</p>
                        </div>
                    </Row>
                    <Row align="center" justify="space-between">
                        <div className="term-of-service">
                            <Checkbox checked={isCheckTerm} onChange={onCheckTerm} />
                            <span className="term-of-service-text">
                                <span>{t('nftDetail.agree')}</span>
                                <a href={`https://smapocke.com/TermsofUse`} target="_blank" rel="noreferrer">
                                    {t(`nftDetail.termOfService`)}
                                </a>
                            </span>
                        </div>
                    </Row>
                </Col>

                {isApprovedERC20Token ? (
                    <ButtonCustom color="blue" disabled={!isCheckTerm} onClick={onAccept} loading={isLoading}>
                        <span>{t('nftDetail.accept')}</span>
                    </ButtonCustom>
                ) : (
                    <ButtonCustom
                        color="blue"
                        disabled={isApprovingERC20Token}
                        onClick={onApproveERC20}
                        loading={isApprovingERC20Token}
                    >
                        {isApprovingERC20Token ? t('common.approving') : t('common.confirmApprove')}
                    </ButtonCustom>
                )}
            </div>
        </Modal>
    )
}

export default AcceptOfferModal
