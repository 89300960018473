import React, {
  useEffect,
  useState,
  useCallback,
  useRef,
  useMemo,
  useContext,
} from "react";
import { useIntersection } from "react-use";
import { Menu, Drawer, Modal, Affix, Dropdown } from "antd";
import {
  Link,
  NavLink,
  useHistory,
  useLocation,
  useRouteMatch,
} from "react-router-dom";
import { useAccountEffect, useConnectors, useDisconnect } from "wagmi";
import _ from "lodash";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import ReactLoading from "react-loading";
import { ThemeContext } from "../../../../ThemeProvider";
import { ButtonCustom, NoData } from "components/common";
import { OutSideClick, SvgIcon } from "components/modules";
import { isUserAuthenticated } from "utils/auth";
import userService from "service/userService";
// import SearchIcon from 'assets/icons/search.svg'
// import IconMenu from 'assets/icons/menu-icon.svg'
// import DarkIconMenu from 'assets/icons/dark-menu-icon.svg'
// import noItem from 'assets/images/notfound.svg'
import { useResizeWindow } from "utils/hook";
import socket from "config/socket";
import { SOCKET_EVENT_ACTIVITY, ACTIVITY_STATUS } from "constants/index";
import { useAuth } from "hooks/useAuth";
import notificationService from "service/notificationService";
import Logo from "../header/components/Logo";
import {
  NotificationBids,
  NotificationFollow,
  NotificationPurchase,
  SearchHeaderBar,
  SearchHeaderBarMobile,
  NotificationLike,
  RenderHeaderButton,
} from "./components";
import { listSocial } from "../footer";
import "./style.scss";
import NotificationListing from "./components/NotificationListing";
import NotificationAccept from "./components/NotificationAccept";
import LanguageSwitcher from "./components/LanguageSwitcher";
import { setGlobalLoading, setCloseGlobalLoading } from "store/system.slice";
import {
  setCurrentUserProfile,
  setUserGetProfileSuccess,
} from "store/user.slice";

import { ReactComponent as MyProfile } from "assets/icons/my-profile-icon.svg";
import { ReactComponent as Explore } from "assets/icons/menu-explore-icon.svg";
import { ReactComponent as Collection } from "assets/icons/collection-icon.svg";
import { ReactComponent as Notice } from "assets/icons/menu-notice-icon.svg";
import { logout } from "store/login.slice";
import SmallLeftIcon from "assets/icons/SmallLeftIcon";
import { TABS } from "pages/profile";

const NOTIFICATION_STEP = 10;

const EXPLORE = "explore";

const { SubMenu } = Menu;

const DropdownNotification = ({
  setIsNotification,
  asyncGetCountNotification,
  setIsShowNotification,
}) => {
  const { theme } = useContext(ThemeContext);
  const location = useLocation();
  const { t } = useTranslation();
  const [noties, setNoties] = useState(null);
  const [loadingMore, setLoadingMore] = useState(false);
  const [loading, setLoading] = useState(false);
  const [total, setTotal] = useState(0);
  const [countNotification, setCountNotification] = useState(NOTIFICATION_STEP);
  const history = useHistory();
  const walletAddress = localStorage.getItem("wallet-address");

  const [refAnchor, setRefAnchor] = useState(false);

  const anchorEl = useRef(null);
  const intersection = useIntersection(anchorEl, {
    root: null,
    rootMargin: "0px",
    threshold: 1,
  });
  const inView =
    intersection &&
    intersection.isIntersecting &&
    intersection.intersectionRatio >= 1;

  const isLoadMore = useMemo(() => {
    return noties?.length < total;
  }, [noties?.length, total]);

  const infinity = inView && isLoadMore;

  const dispatch = useDispatch();
  const { profile } = useSelector((state) => state.user);

  useEffect(() => {
    if (!refAnchor) return;
  }, [refAnchor]);

  useEffect(() => {
    if (infinity) {
      handleLoadMore();
    }
  }, [infinity]);

  const handleLoadMore = async () => {
    if (loading) {
      return;
    }
    const next = countNotification + NOTIFICATION_STEP;
    const _notificationCount =
      next > total ? total : countNotification + NOTIFICATION_STEP;
    setCountNotification(_notificationCount);
    setLoadingMore(true);
    const [data, err] = await notificationService.getNotification(
      { page: 1, limit: _notificationCount },
      walletAddress
    );
    if (data) {
      setNoties(data?.items);
      setTotal(data?.meta.totalItem);
    }

    if (err) {
      //err handle
    }
    setLoadingMore(false);
  };

  //check having new notification

  const asyncGetNotification = useCallback(async () => {
    setLoading(true);
    const [data] = await notificationService.getNotification(
      { page: 1, limit: countNotification },
      walletAddress
    );
    if (data) {
      setNoties(data?.items);
      setTotal(data?.meta?.totalItem);
    }
    setLoading(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(
    () => {
      asyncGetNotification();
    }, // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  //call api readed notification
  const onClickNoti = useCallback(
    async (id, callback) => {
      setIsShowNotification(false);
      dispatch(setGlobalLoading());
      await notificationService.readNotification(id);
      asyncGetCountNotification();
      dispatch(setCloseGlobalLoading());
      callback && callback();
    }, // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const onClickAllNoti = useCallback(
    async () => {
      await notificationService.readAllNotification();
      asyncGetCountNotification();
    }, // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const RenderType = (action, noty) => {
    switch (action) {
      case ACTIVITY_STATUS.PURCHASE:
        return <NotificationPurchase noty={noty} onClickNoti={onClickNoti} />;
      case ACTIVITY_STATUS.FOLLOW:
        return <NotificationFollow noty={noty} onClickNoti={onClickNoti} />;
      case ACTIVITY_STATUS.LIKE:
        return <NotificationLike noty={noty} onClickNoti={onClickNoti} />;
      case ACTIVITY_STATUS.OPEN_FOR_OFFER:
      case ACTIVITY_STATUS.BID:
        return <NotificationBids noty={noty} onClickNoti={onClickNoti} />;
      case ACTIVITY_STATUS.LISTING:
        return <NotificationListing noty={noty} onClickNoti={onClickNoti} />;
      case ACTIVITY_STATUS.ACCEPT_BID_PURCHASE:
      case ACTIVITY_STATUS.ACCEPT_OFFER_PURCHASE:
        return (
          <NotificationAccept
            noty={noty}
            onClickNoti={onClickNoti}
            type={action}
          />
        );
      default:
        return <NotificationFollow noty={noty} onClickNoti={onClickNoti} />;
    }
  };
  if (loading) {
    return (
      <div className="dropdown-notify">
        <div className="dropdown-notify-loading">
          <ReactLoading type="spinningBubbles" color="#002979" />
        </div>
      </div>
    );
  }
  if (!noties) {
    return null;
  }

  const handleViewAll = () => {
    onClickAllNoti();
    // history.push({ pathname: '/activity', state: { headType: 1 } })

    history.push({
      pathname: profile?.shortUrl
        ? `/${profile?.shortUrl}`
        : `/user/${profile.walletAddress}`,
      search: `?tab=${TABS.ACTIVITY}`,
      state: { activeOwnerTab: true },
    });
  };

  return (
    <div
      className={`dropdown-notify ${theme === "dark-theme" ? "dark-dropdown-notify" : ""
        }`}
    >
      <div className="notification-header">
        <h3 style={{ marginBottom: 5 }} className="noti-title">
          {t("common.notifications")}
        </h3>
        <ButtonCustom
          size="small"
          color="blue"
          onClick={handleViewAll}
          className="notifi-custom-btn"
        >
          {t("common.viewAll")}
        </ButtonCustom>
      </div>
      {noties && noties.length > 0 ? (
        <Menu>
          {noties?.map((noty, key) => (
            <Menu.Item
              className={`box-noti ${noty?.isRead ? "" : "not-read-noti"}`}
              key={key}
              style={{
                borderBottom:
                  noties && noties.length === 1
                    ? "none"
                    : "1px solid rgba(163, 174, 208, 0.3)",
              }}
            >
              {RenderType(noty?.transactionType, noty)}
            </Menu.Item>
          ))}
        </Menu>
      ) : (
        <NoData mainText={t("header.menu.noNoti")} onlyMain />
      )}
      <div
        style={{ position: "relative", top: "-10px" }}
        ref={(el) => {
          anchorEl.current = el;
          setRefAnchor(!!el);
        }}
      ></div>
      {loadingMore && (
        <ReactLoading
          className="loading-more"
          height="50px"
          width="50px"
          type="spinningBubbles"
          color="#002979"
        />
      )}
    </div>
  );
};

const Notification = ({ isMobile }) => {
  const { theme } = useContext(ThemeContext);
  const { profile } = useSelector((state) => state.user) || {};
  const [isNotification, setIsNotification] = useState(false);
  const [isShowNotification, setIsShowNotification] = useState(false);
  const [displayDrawerNoti, setDisplayDrawerNoti] = useState(false);
  const history = useHistory();
  const [, handleSignWallet] = useAuth();

  const toogleNotification = useCallback(async () => {
    if (!profile.walletAddress) {
      history.push("/connect");
    } else {
      if (!isUserAuthenticated()) {
        await handleSignWallet();
        await asyncGetCountNotification();
      }
    }
    setIsShowNotification(!isShowNotification);
  }, [profile.walletAddress, handleSignWallet, history, isShowNotification]);

  const toogleNotificationMobile = useCallback(async () => {
    if (!profile.walletAddress) {
      history.push("/connect");
    } else {
      if (!isUserAuthenticated()) {
        await handleSignWallet();
      }
      setDisplayDrawerNoti(!displayDrawerNoti);
    }
  }, [profile.walletAddress, displayDrawerNoti, handleSignWallet, history]);

  useEffect(() => {
    setIsShowNotification(false);
  }, [profile.walletAddress]);

  const outSideClick = useCallback(() => {
    setIsShowNotification(false);
  }, []);

  //check having new notification
  const asyncGetCountNotification = useCallback(async () => {
    const accessToken = localStorage.getItem("accessToken");
    if (!accessToken) return;

    const [data] = await notificationService.getCountNotification();
    if (data?.notifications > 0) {
      setIsNotification(true);
    } else {
      setIsNotification(false);
    }
  }, []);

  useEffect(() => {
    asyncGetCountNotification();
    if (socket) {
      socket.on(SOCKET_EVENT_ACTIVITY, (data) => {
        if (data.data.user === profile.id) {
          setIsNotification(true);
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [profile.id]);

  if (isMobile) return;

  return !isMobile ? (
    <div
      className={`dropdown-notification ${theme === "dark-theme" ? "dark-dropdown-notification" : ""
        }`}
    >
      <OutSideClick onOutsideClick={outSideClick}>
        <div className="notification-alert" onClick={toogleNotification}>
          {isNotification ? (
            <i className="ri-notification-3-line red-dot" />
          ) : (
            <i className="ri-notification-3-line" />
          )}
        </div>
        {isShowNotification && (
          <div className="dropdown-notification-menu">
            <DropdownNotification
              setIsNotification={setIsNotification}
              asyncGetCountNotification={asyncGetCountNotification}
              setIsShowNotification={setIsShowNotification}
            />
          </div>
        )}
      </OutSideClick>
    </div>
  ) : (
    <Affix offsetBottom={0}>
      <div className="dropdown-notification">
        <div className="notification-alert" onClick={toogleNotificationMobile}>
          {isNotification ? (
            <i className="ri-notification-3-line red-dot" />
          ) : (
            <i className="ri-notification-3-line" />
          )}
        </div>
        {displayDrawerNoti && (
          <Drawer
            className="dropdown-notification-menu"
            height={490}
            closable={false}
            placement="bottom"
            onClose={() => setDisplayDrawerNoti(false)}
            visible={displayDrawerNoti}
            key="noti"
          >
            <DropdownNotification
              setIsNotification={setIsNotification}
              asyncGetCountNotification={asyncGetCountNotification}
            />
          </Drawer>
        )}
      </div>
    </Affix>
  );
};

const HeaderNav = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { profile, currentUserProfile } = useSelector((state) => state.user);
  const categories = useSelector((state) => state.categories?.data || []).map(
    (category) => ({
      ...category,
      label: t(`category.${category.label}`),
    })
  );
  const accountStore = useSelector(
    (state) => state?.user?.profile?.walletAddress
  );
  const history = useHistory();
  const router = useRouteMatch();
  const { toggleTheme, theme } = useContext(ThemeContext);
  const [isMobile] = useResizeWindow(1050);
  const [isSmallMobile] = useResizeWindow(430);
  const connectors = useConnectors();
  const { disconnect } = useDisconnect();

  const [, handleSignWallet] = useAuth({
    afterLogin: { redirect: "/choose-type" },
  });

  const [visible, setVisible] = useState(false);
  const [isVisibleUniswapModal, setIsVisibleUniswapModal] = useState(false);
  const [isShowModalWETH, setIsShowModalWETH] = useState(false);
  const [isShowSearchbar, setIsShowSearchbar] = useState(false);

  const getUserProfile = async () => {
    const [profileRes, errRes] = await userService.getUserInfo(
      profile?.walletAddress
    );
    if (profileRes) {
      dispatch(setCurrentUserProfile(profileRes));
      dispatch(setUserGetProfileSuccess(profileRes));
    }
  };

  useAccountEffect({
    onDisconnect: () => {
      dispatch(logout({ disconnect, connectors }));
    },
  });

  useEffect(() => {
    if (profile?.walletAddress) {
      getUserProfile();
    }
  }, [profile?.walletAddress]);

  const MainAppLink = [
    {
      to: "/",
      type: "home",
      title: t("header.headerNav.home"),
      altTitle: "Go to Home",
    },
    {
      icon: <Explore />,
      to: "/explore/all",
      type: "explore",
      title: t("header.headerNav.explore"),
      altTitle: "Go to Explore",
    },
    {
      icon: <Collection />,
      to: "/collections",
      title: t("header.headerNav.collections"),
      altTitle: "Go to Collections",
    },
    // {
    //     to: '',
    //     type: 'stats',
    //     title: t('header.headerNav.stats'),
    //     altTitle: 'Go to Top Stats'
    // },
    // {
    //     to: '',
    //     type: 'resource',
    //     title: t('header.headerNav.resource'),
    //     altTitle: 'Go to How it works'
    // }
  ];

  const MainAppLinkMobile = [
    {
      icon: <MyProfile />,
      to: accountStore
        ? !!currentUserProfile.shortUrl &&
          currentUserProfile.shortUrl === profile?.shortUrl
          ? `/${currentUserProfile?.shortUrl}`
          : !!profile?.shortUrl
            ? `/${profile?.shortUrl}`
            : `/user/${accountStore}`
        : `/user/owner`,
      title: t("header.headerNav.myProfile"),
      altTitle: "Go to my profile",
    },
    {
      icon: <Explore />,
      to: "/explore/all",
      type: "explore",
      title: t("header.headerNav.explore"),
      altTitle: "Go to Explore",
    },
    {
      icon: <Collection />,
      to: "/collections",
      title: t("header.headerNav.collections"),
      altTitle: "Go to Collections",
    },
    {
      icon: <Notice />,
      to: (profile?.shortUrl ? `/${profile?.shortUrl}` : `/user/${profile.walletAddress}`) + `?tab=${TABS.ACTIVITY}`,
      title: t("header.headerNav.notice"),
      altTitle: "Go to notice",
    },
  ];

  const dropDownExplore = useCallback(() => {
    return (
      <Menu
        className={`dropdown-explore ${theme === "dark-theme" ? "dropdown-explore-dark" : ""
          }`}
      >
        <Menu.Item>
          <Link to={"/explore/all"}>{t("header.headerNav.all")}</Link>
        </Menu.Item>
        {categories.map((item, index) => (
          <Menu.Item key={index}>
            <Link to={{ pathname: "/explore/" + item.code }}>
              <SvgIcon name={item?.icon} /> {item.label}
            </Link>
          </Menu.Item>
        ))}
      </Menu>
    );
  }, [categories, theme]);

  const dropDownStats = () => {
    return (
      <Menu
        className={`dropdown-explore ${theme === "dark-theme" ? "dropdown-explore-dark" : ""
          }`}
      >
        <Menu.Item>
          <Link to="/stats">{t("header.headerNav.ranking")}</Link>
        </Menu.Item>
        <Menu.Item>
          <Link to="/activity">{t("header.headerNav.activity")}</Link>
        </Menu.Item>
      </Menu>
    );
  };

  const dropDownResource = useCallback(() => {
    return (
      <Menu
        className={`dropdown-explore ${theme === "dark-theme" ? "dropdown-explore-dark" : ""
          }`}
      >
        <Menu.Item>
          <Link to="/snail-token"> {t("header.headerNav.snailToken")}</Link>
        </Menu.Item>
        <Menu.Item>
          <Link to="/about"> {t("header.headerNav.aboutUs")}</Link>
        </Menu.Item>
        <Menu.Item>
          <Link to="/help-center"> {t("header.headerNav.helpCenter")}</Link>
        </Menu.Item>
        <Menu.Item>
          <Link to="/how-it-work">{t("header.headerNav.howItWorks")}</Link>
        </Menu.Item>
        {/* <Menu.Item>
                    <a href="/Snail_House_Privacy_Policy.pdf" target="_blank">
                        {t('header.headerNav.privacy')}
                    </a>
                </Menu.Item>
                <Menu.Item>
                    <a href="/Snail_House_Terms.pdf" target="_blank">
                        {t('header.headerNav.termOfUse')}
                    </a>
                </Menu.Item> */}
      </Menu>
    );
  }, [t, theme]);

  const handleClickCreateNft = async () => {
    if (!accountStore) {
      history.push("/connect");
    } else {
      if (!isUserAuthenticated()) {
        await handleSignWallet();
      } else {
        history.push("/choose-type");
      }
    }
  };

  const activeMenuMobile = () => {
    setVisible(!visible);
    window.scrollTo(0, 0);
  };

  const onClose = () => {
    setVisible(false);
  };

  useEffect(() => {
    if (isShowSearchbar) {
      document.body.style.overflow = "hidden";
    }
    return () => {
      document.body.style.overflow = "auto";
    };
  }, [isShowSearchbar]);

  const onClickGoBack = () => {
    if (history.location.key) {
      history.goBack();
      return;
    }

    history.push("/");
  };

  const handleDisconnect = async () => {
    dispatch(logout({ disconnect, connectors }));
  };

  return (
    <div className="header-page">
      <div className="container">
        {!isMobile ? (
          <>
            <div className="header-page__container">
              <div className="d-flex align-items-center logo-input">
                <NavLink className="my-auto logo-pace-art" to="/">
                  <Logo />
                </NavLink>
                <div className="d-flex align-items-center search-input">
                  {location.pathname !== "/" && (
                    <ButtonCustom
                      variant="outline-sub"
                      className="back-button"
                      onClick={onClickGoBack}
                    >
                      <SmallLeftIcon />
                    </ButtonCustom>
                  )}
                  <div className="search-box">
                    <SearchHeaderBar />
                    <div className="search-box-icon">
                      <i className="ri-search-line"></i>
                    </div>
                  </div>
                </div>
              </div>
              <div className="user-login-notify my-auto d-flex">
                <div className="header-page__nav">
                  <div className="links d-flex">
                    {MainAppLink.map((link, index) => {
                      return link?.to ? (
                        <NavLink
                          key={index}
                          className="link-item"
                          to={{
                            pathname: link.to,
                            state: {
                              from: router?.path?.includes("user")
                                ? `/user/${accountStore}`
                                : "/",
                            },
                          }}
                          activeClassName="active-link"
                          exact
                        >
                          {link.title}
                        </NavLink>
                      ) : link?.type === EXPLORE ? (
                        <Dropdown
                          key={index}
                          trigger={["click"]}
                          overlay={dropDownExplore}
                          getPopupContainer={(triggerNode) =>
                            triggerNode.parentNode
                          }
                        >
                          <div className="link-item link-item-header">
                            {link.title}
                          </div>
                        </Dropdown>
                      ) : link?.type === "stats" ? (
                        <Dropdown
                          key={index}
                          trigger={["click"]}
                          overlay={dropDownStats}
                          getPopupContainer={(triggerNode) =>
                            triggerNode.parentNode
                          }
                        >
                          <div className="link-item link-item-header">
                            {link.title}
                          </div>
                        </Dropdown>
                      ) : (
                        <Dropdown
                          key={index}
                          trigger={["click"]}
                          overlay={dropDownResource}
                          getPopupContainer={(triggerNode) =>
                            triggerNode.parentNode
                          }
                        >
                          <div className="link-item link-item-header">
                            {link.title}
                          </div>
                        </Dropdown>
                      );
                    })}
                  </div>
                </div>
                <div className="header-page__act">
                  <LanguageSwitcher />
                  <div className="notification-icon cursor-pointer">
                    <Notification isMobile={isMobile} />
                  </div>
                  {/* <div onClick={toggleTheme} className="btn-toggle-theme">
                                        {theme === 'dark-theme' ? (
                                            <i className="ri-sun-line"></i>
                                        ) : (
                                            <i className="ri-contrast-2-line"></i>
                                        )}
                                    </div> */}
                  <div className="button-wrapper">
                    <div className="connect-button">
                      <RenderHeaderButton
                        setIsShowModalWETH={setIsShowModalWETH}
                        setIsVisibleUniswapModal={setIsVisibleUniswapModal}
                      />
                    </div>
                    <ButtonCustom
                      variant="outline"
                      className="btn-create"
                      onClick={handleClickCreateNft}
                      style={{ height: 40 }}
                    >
                      <i className="ri-add-large-line"></i>
                      <span>{t("header.createItem")}</span>
                    </ButtonCustom>
                  </div>
                </div>
              </div>
            </div>

            <ModalWhatIsWETH
              isShowModalWETH={isShowModalWETH}
              setIsShowModalWETH={setIsShowModalWETH}
              t={t}
            />
          </>
        ) : (
          <>
            {isShowSearchbar ? (
              <div className="search search-header-mobile">
                <SearchHeaderBarMobile
                  onClose={() => setIsShowSearchbar(false)}
                  isSearchbarOnHeaderMobile
                  onCloseSearchbar={() => setIsShowSearchbar(false)}
                />
              </div>
            ) : (
              <div className="header-page__mobile d-flex justify-content-space-between">
                <NavLink className="logo-container" to="/">
                  <Logo />
                </NavLink>
                <div
                  className={`mobile-nav cursor-pointer ${visible ? "bg-active" : ""
                    }`}
                >
                  <Drawer
                    height={"100%"}
                    placement={"right"}
                    onClose={onClose}
                    visible={visible}
                    key={"right"}
                    className={`drawer-menu-mobile ${theme === "dark-theme" ? "dark-drawer-menu-mobile" : ""
                      }`}
                  >
                    <SearchHeaderBarMobile onClose={onClose} />
                    <Menu mode="inline" className="menu-mobile">
                      {MainAppLinkMobile.map((link, index) => (
                        <>
                          {!!index && <Menu.Divider key={index} />}
                          <Menu.Item className="cursor-pointer" key={index} icon={link.icon} onClick={() => history.push(link.to)}>
                            <NavLink
                              className="link-item"
                              to={link.to}
                              activeClassName="active-link"
                              exact
                            >
                              <div className="link-title">
                                {link.title}
                                <i className="ri-arrow-right-s-line"></i>
                              </div>
                            </NavLink>
                          </Menu.Item>
                        </>
                      ))}
                    </Menu>
                    <div className="menu-footer">
                      <div className="menu-footer-action">
                        {!profile.walletAddress && <div
                          className={`connect-button-mobile ${theme === "dark-theme"
                            ? "dark-connect-button-mobile"
                            : ""
                            }`}
                        >
                          <RenderHeaderButton
                            isShowConnect={true}
                            setIsShowModalWETH={setIsShowModalWETH}
                            setIsVisibleUniswapModal={setIsVisibleUniswapModal}
                          />
                        </div>}
                        <div className="btn-create-mobile">
                          <ButtonCustom
                            variant="outline"
                            style={{
                              height: 40,
                            }}
                            className="btn-search"
                            onClick={handleClickCreateNft}
                          >
                            <i className="ri-add-large-line"></i>
                            <span>{t("header.createItem")}</span>
                          </ButtonCustom>
                        </div>
                      </div>
                      {!!profile.walletAddress && <ButtonCustom
                        icon={<i className="ri-logout-box-line"></i>}
                        variant="ghost"
                        onClick={handleDisconnect}
                      >
                        {t('sidebar.logOut')}
                      </ButtonCustom>}
                    </div>
                  </Drawer>
                </div>
                <div className="user-option d-flex align-items-center justify-content-center">
                  <div className="notify-icon cursor-pointer">
                    {((!profile.walletAddress && isSmallMobile) ||
                      !isSmallMobile) && (
                        <ButtonCustom
                          type="gray"
                          variant="outline"
                          style={{ height: 40 }}
                          className="btn-search ant-btn-small"
                          onClick={() => setIsShowSearchbar(true)}
                        >
                          <i className="ri-search-line"></i>
                        </ButtonCustom>
                      )}
                    <Notification isMobile={isMobile} />
                  </div>

                  <div className="connect-button-mobile">
                    <RenderHeaderButton
                      setIsShowModalWETH={setIsShowModalWETH}
                      setIsVisibleUniswapModal={setIsVisibleUniswapModal}
                    />
                  </div>
                  <div className="btn-create-mobile">
                    <ButtonCustom
                      variant="outline"
                      style={{ height: 40 }}
                      className="btn-search"
                      onClick={handleClickCreateNft}
                    >
                      <i
                        className="ri-add-large-line"
                        style={{ marginRight: 0 }}
                      ></i>
                    </ButtonCustom>
                  </div>
                  <div
                    className="menu-icon my-auto"
                    onClick={() => {
                      activeMenuMobile();
                      setIsShowSearchbar(false);
                    }}
                  >
                    <i className="ri-menu-fill"></i>
                    {/* <img src={theme === 'dark-theme' ? DarkIconMenu : IconMenu} alt="" /> */}
                  </div>
                </div>

                <ModalWhatIsWETH
                  isShowModalWETH={isShowModalWETH}
                  setIsShowModalWETH={setIsShowModalWETH}
                  t={t}
                />
              </div>
            )}
          </>
        )}
      </div>
    </div>
  );
};

const ModalWhatIsWETH = ({ isShowModalWETH, setIsShowModalWETH, t }) => {
  const topRef = useRef();
  const handleScroll = () => {
    topRef.current.scrollIntoView();
  };
  return (
    <Modal
      closable={false}
      className="modal_weth_container"
      title={t("header.menu.whatIsWETHModal.modalTitle")}
      open={isShowModalWETH}
      centered
      onCancel={() => {
        handleScroll();
        setIsShowModalWETH(false);
      }}
      wrapClassName={"wrap-model-weth"}
      footer={null}
    >
      <div className="modal_weth_content">
        <p ref={topRef}>{t("header.menu.whatIsWETHModal.des_first")}</p>
        <p
          dangerouslySetInnerHTML={{
            __html: t("header.menu.whatIsWETHModal.des_second"),
          }}
        ></p>
      </div>
      <ButtonCustom
        className="btn-continute"
        color="blue"
        onClick={() => {
          handleScroll();
          setIsShowModalWETH(false);
        }}
      >
        {t("header.menu.whatIsWETHModal.modalNameButton")}
      </ButtonCustom>
    </Modal>
  );
};

export default HeaderNav;
