import React, { useState, useEffect } from 'react'

import { convertImage } from 'utils/image'
import { FILE_EXTENSION } from 'constants/index'
import MusicIcon from 'assets/icons/music-icon.svg'
import VideoPlayIcon from 'assets/icons/video-play-icon.svg'
import nftService from 'service/nftService'
import { exportFileFormat } from 'utils/file'

const NotificationNftImage = ({ noty }) => {
    const { metadataUrl, tokenId, nftUrl, nftImagePreview } = noty?.nft || {}
    const [imageUrlMetadata, setImageUrlMetadata] = useState(null)
    useEffect(() => {
        const fetchImageUrlFromMetadata = async metadataUrl => {
            const fullMetaDataUrl = `${metadataUrl}/${tokenId}`

            const [res] = await nftService.getImageUrlFromMetadata(fullMetaDataUrl)
            if (res) {
                setImageUrlMetadata(res)
            }
        }

        if (metadataUrl) {
            fetchImageUrlFromMetadata(metadataUrl)
        }
    }, [metadataUrl, tokenId])

    if (exportFileFormat(nftUrl) === FILE_EXTENSION.MP4) {
        return (
            <>
                <img alt="item" src={convertImage(nftImagePreview)} />
                <img className="icon-checked" src={VideoPlayIcon} alt="" />
            </>
        )
    } else if (exportFileFormat(nftUrl) === FILE_EXTENSION.MPEG) {
        return (
            <>
                <img alt="item" src={convertImage(nftImagePreview)} />
                <img className="icon-checked" src={MusicIcon} alt="" />
            </>
        )
    } else
        return (
            <>
                <img alt="item" src={imageUrlMetadata ? imageUrlMetadata : convertImage(noty?.nft?.nftUrl)} />
            </>
        )
}

export default NotificationNftImage
