import { Tooltip } from 'antd'
import { Link, useHistory } from 'react-router-dom'

import { getCustomDiffTime } from 'utils/convertDate'
import { shortenAddress, shortenNameNoti } from 'utils/func'
import { convertImageAvatar } from 'utils/image'
import CustomVerifyIcon from 'components/common/custom-verify-icon'
import React, { useContext } from 'react'
import { ThemeContext } from '../../../../../ThemeProvider'
import { useTranslation } from 'react-i18next'

const NotificationLike = ({ noty, onClickNoti }) => {
    const { t } = useTranslation();
    const { theme } = useContext(ThemeContext)
    const history = useHistory()
    const nft = noty?.like?.infoLike

    const routerChange = () => {
        onClickNoti(noty?.id, () => {
            if (noty?.like?.account?.shortUrl || noty?.like?.account?.walletAddress) {
                history.push(
                    noty?.like?.account?.shortUrl
                        ? `/user/${noty?.like?.account?.shortUrl}`
                        : `/user/${noty?.like?.account?.walletAddress}`
                )
            }
        })
    }

    const routerNft = e => {
        e.stopPropagation()
        onClickNoti(noty?.id)
        nft?.collection?.id && nft?.tokenId && history.push(`/nft/${nft?.collection?.id}:${nft?.tokenId}`)
    }

    return (
        <div className="cursor-pointer">
            <div className="d-flex" onClick={routerChange}>
                <div className="item-image my-auto">
                    <img src={convertImageAvatar(noty?.like?.account?.avatarImg)} alt="item" />
                    {noty?.like?.account?.isVerify ? <CustomVerifyIcon className="icon-checked" /> : null}
                </div>
                <div className={`my-auto ${theme === 'dark-theme' ? 'dark-my-auto' : ''}`}>
                    <div>
                        <Tooltip
                            title={noty?.like?.account?.username || noty?.like?.account?.walletAddress}
                            placement="topLeft"
                        >
                            <div>
                                <span className="text-highlight">
                                    {noty?.like?.account?.username
                                        ? shortenNameNoti(noty?.like?.account?.username)
                                        : shortenAddress(noty?.like?.account?.walletAddress, 4)}
                                </span>
                            </div>
                        </Tooltip>
                        <span className="">
                            {t('header.menu.likedYour')}{' '}
                            <span className="liked-nft-name" onClick={routerNft}>
                                {nft?.title}
                            </span>{' '}
                            {t('header.menu.collectible')}
                        </span>
                    </div>
                    <div className="time-noti">{getCustomDiffTime(noty?.createdAt)}</div>
                </div>
            </div>
        </div>
    )
}

export default NotificationLike
