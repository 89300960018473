import React from 'react'
import metamskLogo from '../../../assets/images/metamask-logo.png'
import { Row, Modal } from 'antd'

import './style.scss'
import { useDispatch, useSelector } from 'react-redux'
import { get } from 'lodash'
import { closeMetamaskModal } from 'store/metamask.slice'

const InstallMetameask = props => {
    const dispatch = useDispatch()
    const metamask = useSelector(state => state.metamask)
    const open = get(metamask, 'open')
    const onCancel = () => {
        dispatch(closeMetamaskModal())
    }

    return (
        <Modal
            open={open}
            title="Connect to a wallet"
            centered={true}
            footer={null}
            className="install-metameask"
            onCancel={onCancel}
        >
            <a className="" href="https://metamask.io/" target="_blank" rel="noreferrer">
                <Row justify="space-between" className="install-metameask__link install-metameask__wrap">
                    <span className="title">Install MetaMask</span>
                    <img className="install-metameask__logo" src={metamskLogo} alt="logo-metamask" />
                </Row>
            </a>
        </Modal>
    )
}

export default InstallMetameask
