import React from 'react'
import PropTypes from 'prop-types'

import { Tabs } from 'antd'

//style
import './style.scss'

const { TabPane } = Tabs

const CustomTabs = ({ children, defaultActiveKey, onChange, onTabClick, centered, ...restProps }) => {
    return (
        <Tabs
            className="tabs-custom"
            defaultActiveKey={defaultActiveKey}
            onChange={onChange}
            centered={centered}
            onTabClick={onTabClick}
            {...restProps}
        >
            {children}
        </Tabs>
    )
}

CustomTabs.TabPane = TabPane

CustomTabs.propTypes = {
    defaultActiveKey: PropTypes.string,
    centered: PropTypes.bool,
    onChange: PropTypes.func,
    onTabClick: PropTypes.func
}

export default CustomTabs
