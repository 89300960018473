import React, { useContext, useEffect, useState } from "react";
import { Card, Avatar, Tooltip } from "antd";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";

import { FILE_EXTENSION } from "constants/index";
import { convertImageAvatar } from "utils/image";
import { convertTimeToDateTime } from "utils/convertDate";
import IconLikes from "assets/icons/activity-likes-icon-color.svg";
import PlayGroupIcon from "assets/icons/play-group-icon.svg";
import Mp3Icon from "assets/icons/music-group-icon.svg";
import nftService from "service/nftService";
import LoadingIcon from "assets/icons/loading-icon.svg";
import CustomVerifyIcon from "components/common/custom-verify-icon";
import { exportFileFormat } from "utils/file";
import { ThemeContext } from "ThemeProvider";
import { useTranslation } from "react-i18next";

const LikesActivity = (props) => {
  const { t } = useTranslation();
  const { theme } = useContext(ThemeContext);
  const history = useHistory();
  const { user, nft, displayPreviewImage, createdAt, walletAddress } = props;
  const { profile } = useSelector((state) => state.user);
  const [imageUrlMetadata, setImageUrlMetadata] = useState(null);
  const [isDoneFetchImageUrlMetadata, setIsDoneFetchImageUrlMetadata] =
    useState(false);
  const handleClickUser = (e, address) => {
    if (address === walletAddress) {
      e.preventDefault();
    }
  };

  useEffect(() => {
    const fetchImageUrlFromMetadata = async (nftData) => {
      let fullMetaDataUrl = `${nftData?.metadataUrl}/${nftData?.tokenId}`;
      const [result] = await nftService.getImageUrlFromMetadata(
        fullMetaDataUrl
      );
      setIsDoneFetchImageUrlMetadata(true);

      if (result) {
        setImageUrlMetadata(result);
      }
    };

    if (nft?.metadataUrl) {
      fetchImageUrlFromMetadata(nft);
    }
  }, []);

  const DisplayImage = ({ nftData, displayPreviewImageSale }) => {
    if (!nftData?.metadataUrl) {
      return <img src={displayPreviewImageSale} alt="" />;
    }
    if (nftData?.metadataUrl && !isDoneFetchImageUrlMetadata) {
      return (
        <img
          src={LoadingIcon}
          alt="LoadingIcon"
          className="custom-loading-icon"
        />
      );
    } else if (imageUrlMetadata && isDoneFetchImageUrlMetadata) {
      return <img src={imageUrlMetadata} alt="" />;
    }
    return <img src={displayPreviewImageSale} alt="" />;
  };

  const routerCollection = () => {
    history.push(`/nft/${nft?.collection?.id}:${nft?.tokenId}`);
  };

  return (
    <div onClick={routerCollection} className="cursor-pointer" style={{ height: '100%' }}>
      <Card className="card-activity-custom">
        <div className="card-activity-custom_item-image">
          {/* <img src={displayPreviewImage} alt="" /> */}
          <DisplayImage
            nftData={nft}
            displayPreviewImageSale={displayPreviewImage}
          />

          {exportFileFormat(nft?.nftUrl) === FILE_EXTENSION.MP4 && (
            <div className="card-activity-custom_icon-file-extension">
              <img src={PlayGroupIcon} alt="PlayGroupIcon" />
            </div>
          )}
          {exportFileFormat(nft?.nftUrl) === FILE_EXTENSION.MPEG && (
            <div className="card-activity-custom_icon-file-extension">
              <img src={Mp3Icon} alt="Mp3Icon" />
            </div>
          )}
        </div>
        <div className="card-activity-custom-content">
          <div className="item-name">
            <Tooltip
              overlayClassName="blue"
              placement="topLeft"
              title={nft?.title}
            >
              <span>{nft?.title}</span>
            </Tooltip>
          </div>
          <div className="card-activity-custom-content_type">
            <img className="type-icon" src={IconLikes} alt="" />
            <span>{t('activity.likedBy')}</span>
            {/* <span>{t('cardActivity.transfer')}</span> */}
          </div>
          <div className="card-activity-custom-content_user">
            <div className="card-activity-custom-content_user-wrapper">
              <div className="d-flex">
                <span
                  onClick={(e) => {
                    e.stopPropagation();
                    handleClickUser(e, user?.walletAddress);
                    history.push(
                      user?.shortUrl
                        ? `/${user?.shortUrl}`
                        : `/user/${user?.walletAddress}`
                    );
                  }}
                  className="cursor-pointer"
                >
                  <div className="avatar">
                    <Avatar
                      alt="example"
                      src={convertImageAvatar(user?.avatarImg)}
                    />
                    {user?.isVerify ? (
                      <CustomVerifyIcon className="icon" />
                    ) : null}
                  </div>
                </span>
                <Tooltip
                  overlayClassName="blue"
                  placement="topLeft"
                  title={user?.username || user?.walletAddress}
                >
                  <span
                    onClick={(e) => {
                      e.stopPropagation();
                      handleClickUser(e, user?.walletAddress);
                      history.push(
                        user?.shortUrl
                          ? `/${user?.shortUrl}`
                          : `/user/${user?.walletAddress}`
                      );
                    }}
                    className="cursor-pointer"
                  >
                    <div
                      className={`user-name-long ${
                        theme === "dark-theme" && "dark-user-name-long"
                      }`}
                    >
                      {/* <span>{user?.username !== null ? user?.username : user?.walletAddress}</span> */}
                      <span>
                        {profile?.walletAddress &&
                        user?.walletAddress === profile?.walletAddress
                          ? t("activity.you")
                          : user?.username !== null
                          ? user?.username
                          : user?.walletAddress}
                      </span>
                    </div>
                  </span>
                </Tooltip>
              </div>
            </div>
          </div>
          <div className="card-activity-custom-content_date">
            <span>{convertTimeToDateTime(createdAt)}</span>
          </div>
        </div>
      </Card>
    </div>
  );
};

export default LikesActivity;
