import React from 'react'
import { Modal } from 'antd'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'

import checkedOutlineIcon from 'assets/icons/checked-outline-icon.svg'
import './style.scss'

const CollectionFollowStepModal = ({ visible, onClose, ...restProps }) => {
    const { isConfirmDeploy, isDeployPending, isDeploySuccess, createPending, createSuccess } = useSelector(
        state => state.collection
    )
    const { t } = useTranslation()

    return (
        <Modal
            onCancel={onClose}
            className="create-nft-flow_custom"
            footer={null}
            centered
            closable={false}
            visible={visible}
            {...restProps}
        >
            <div className="create-nft-flow_header">
                <span className="create-nft-flow_title">{t('modalCollectionFollowStep.title')}</span>
            </div>
            <div className="create-nft-steps">
                <div className="create-nft-step">
                    {isConfirmDeploy ? (
                        <img className="create-nft-step_icon" src={checkedOutlineIcon} alt="checked-confirm-icon" />
                    ) : (
                        <div className="create-nft-step_loading" />
                    )}

                    <div className="create-nft-step_content">
                        <span className="create-nft-step_content__title">
                            {t('modalCollectionFollowStep.waitConfirm')}
                        </span>
                        <span className="create-nft-step_content__desc">
                            {t('modalCollectionFollowStep.confirmDescription')}
                        </span>
                    </div>
                </div>
                <div className="create-nft-step">
                    {isConfirmDeploy ? (
                        isDeployPending ? (
                            <div className="create-nft-step_loading" />
                        ) : isDeploySuccess ? (
                            <img className="create-nft-step_icon" src={checkedOutlineIcon} alt="checked-deploy-icon" />
                        ) : null
                    ) : null}

                    <div className={`create-nft-step_content ${isConfirmDeploy ? '' : 'waiting-status'}`}>
                        <span className="create-nft-step_content__title">
                            {t('modalCollectionFollowStep.deployContract')}
                        </span>
                        <span className="create-nft-step_content__desc">
                            {t('modalCollectionFollowStep.deployContractDescription')}
                        </span>
                    </div>
                </div>

                <div className="create-nft-step">
                    {isDeploySuccess ? (
                        createPending ? (
                            <div className="create-nft-step_loading" />
                        ) : createSuccess ? (
                            <img className="create-nft-step_icon" src={checkedOutlineIcon} alt="checked-create-icon" />
                        ) : null
                    ) : null}

                    <div className={`create-nft-step_content ${isDeploySuccess ? '' : 'waiting-status'}`}>
                        <span className="create-nft-step_content__title">
                            {t('modalCollectionFollowStep.createCollection')}
                        </span>
                        <span className="create-nft-step_content__desc">
                            {t('modalCollectionFollowStep.createNewCollection')}
                        </span>
                    </div>
                </div>
            </div>
            <p className="warning">
                {t('createNFT.collectionWarning')}
            </p>
        </Modal>
    )
}

export default CollectionFollowStepModal
