import React, { useContext, useEffect, useState } from 'react'
import { Card, Avatar, Tooltip } from 'antd'
import { useHistory } from 'react-router-dom'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'

import { FILE_EXTENSION } from 'constants/index'
import { convertImageAvatar } from 'utils/image'
import { roundNumber } from 'utils/func'
import { convertTimeToDateTime } from 'utils/convertDate'
import IconListing from 'assets/icons/activity-listing-icon-color.svg'
import PlayGroupIcon from 'assets/icons/play-group-icon.svg'
import Mp3Icon from 'assets/icons/music-group-icon.svg'
import nftService from 'service/nftService'
import LoadingIcon from 'assets/icons/loading-icon.svg'
import CustomVerifyIcon from 'components/common/custom-verify-icon'
import { exportFileFormat } from 'utils/file'
import { ThemeContext } from '../../../ThemeProvider'
import { useTranslation } from 'react-i18next'

const ListingsActivity = props => {
    const { t } = useTranslation()
    const { theme } = useContext(ThemeContext)
    const history = useHistory()
    const { user, saleNft, displayPreviewImageSale, createdAt, walletAddress } = props
    const { profile } = useSelector(state => state.user)
    const [imageUrlMetadata, setImageUrlMetadata] = useState(null)
    const [isDoneFetchImageUrlMetadata, setIsDoneFetchImageUrlMetadata] = useState(false)
    const handleClickUser = (e, address) => {
        e.stopPropagation()
        if (address === walletAddress) {
            e.preventDefault()
        }
    }

    useEffect(() => {
        const fetchImageUrlFromMetadata = async nftData => {
            let fullMetaDataUrl = `${nftData?.metadataUrl}/${nftData?.tokenId}`
            const [result] = await nftService.getImageUrlFromMetadata(fullMetaDataUrl)
            setIsDoneFetchImageUrlMetadata(true)

            if (result) {
                setImageUrlMetadata(result)
            }
        }

        if (saleNft?.nft?.metadataUrl) {
            fetchImageUrlFromMetadata(saleNft?.nft)
        }
    }, [])

    const DisplayImage = ({ nftData, displayPreviewImageSale }) => {
        if (!nftData?.metadataUrl) {
            return <img src={displayPreviewImageSale} alt="" />
        }
        if (nftData?.metadataUrl && !isDoneFetchImageUrlMetadata) {
            return <img src={LoadingIcon} alt="LoadingIcon" className="custom-loading-icon" />
        } else if (imageUrlMetadata && isDoneFetchImageUrlMetadata) {
            return <img src={imageUrlMetadata} alt="" />
        }
        return <img src={displayPreviewImageSale} alt="" />
    }

    const routerCollection = () => {
        history.push(`/nft/${saleNft?.nft?.collection?.id}:${saleNft?.nft?.tokenId}`)
    }
    return (
        <div onClick={routerCollection} id={saleNft?.nft?.id} className="cursor-pointer" style={{ height: '100%' }}>
            <Card className="card-activity-custom">
                <div className="card-activity-custom_item-image">
                    <DisplayImage nftData={saleNft?.nft} displayPreviewImageSale={displayPreviewImageSale} />
                    {exportFileFormat(saleNft?.nft?.nftUrl) === FILE_EXTENSION.MP4 && (
                        <div className="card-activity-custom_icon-file-extension">
                            <img src={PlayGroupIcon} alt="PlayGroupIcon" />
                        </div>
                    )}
                    {exportFileFormat(saleNft?.nft?.nftUrl) === FILE_EXTENSION.MPEG && (
                        <div className="card-activity-custom_icon-file-extension">
                            <img src={Mp3Icon} alt="Mp3Icon" />
                        </div>
                    )}
                </div>
                <div className="card-activity-custom-content">
                    <div className="item-name">
                        <Tooltip overlayClassName="blue" placement="topLeft" title={saleNft?.nft?.title}>
                            <span>{saleNft?.nft?.title}</span>
                        </Tooltip>
                    </div>
                    <div className="card-activity-custom-content_type">
                        <img className="type-icon" src={IconListing} alt="" />
                        <span>{t("activity.listedBy")}</span>
                    </div>
                    <div
                        className={`card-activity-custom-content_user ${theme === 'dark-theme' ? 'dark-card-activity-custom-content_user' : ''
                            }`}
                    >
                        <div className="card-activity-custom-content_user-wrapper">
                            <div className="d-flex">
                                <span
                                    onClick={e => {
                                        handleClickUser(e, user?.walletAddress)
                                        history.push(
                                            user?.shortUrl ? `/${user?.shortUrl}` : `/user/${user?.walletAddress}`
                                        )
                                    }}
                                    className="cursor-pointer"
                                >
                                    <div className="avatar">
                                        <Avatar alt="example" src={convertImageAvatar(user?.avatarImg)} />
                                        {user?.isVerify ? <CustomVerifyIcon className="icon" /> : null}
                                    </div>
                                </span>
                                <Tooltip
                                    overlayClassName="blue"
                                    placement="topLeft"
                                    title={user?.username || user?.walletAddress}
                                >
                                    <span
                                        onClick={e => {
                                            handleClickUser(e, user?.walletAddress)
                                            history.push(
                                                user?.shortUrl ? `/${user?.shortUrl}` : `/user/${user?.walletAddress}`
                                            )
                                        }}
                                        className="cursor-pointer"
                                    >
                                        <div className="user-name">
                                            <span>
                                                {profile?.walletAddress &&
                                                    user?.walletAddress?.toLowerCase() === profile?.walletAddress?.toLowerCase()
                                                    ? t('activity.you')
                                                    : user?.username !== null
                                                        ? user?.username
                                                        : user?.walletAddress}
                                            </span>
                                        </div>
                                    </span>
                                </Tooltip>
                            </div>
                            <div className="d-flex">
                                <span className="gray-text">{t('common.for')}</span>
                                <span className="price">
                                    {roundNumber(saleNft?.price) || 0} {saleNft?.currencyToken?.toUpperCase()}
                                </span>
                            </div>
                        </div>
                    </div>
                    <div className="card-activity-custom-content_date">
                        <span>{convertTimeToDateTime(createdAt)}</span>
                    </div>
                </div>
            </Card>
        </div>
    )
}

ListingsActivity.propTypes = {
    user: PropTypes.object,
    saleNft: PropTypes.object,
    displayPreviewImageSale: PropTypes.string
}

export default ListingsActivity
