import {createSlice} from '@reduxjs/toolkit';

const initialState = {
  keySearch: '',
  resultSearch: ''
}

export const searchSlice = createSlice({
  name: 'search',
  initialState,
  reducers: {
    setKeySearch: (state, data) => {
      state.keySearch = data.payload;
    },
    setResultSearch: (state, data) => {
      state.resultSearch = data.payload;
    },
  }
});

export const {setKeySearch, setResultSearch} = searchSlice.actions;

export default searchSlice.reducer;
