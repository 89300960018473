import React from 'react'
import { Modal } from 'antd'
import { useSelector, useDispatch } from 'react-redux'
import { ButtonCustom } from 'components/common'
import {
    getNFTDetail,
    getTradingHistory,
    getOfferList,
    changePageHistory,
    changePageOfferOpenBid
} from 'store/actions/nft'
import './style.scss'

const MakeOfferSuccess = ({ onCloseModal }) => {
    const dispatch = useDispatch()
    const { tokenId, collection, id } = useSelector(state => state.collectible?.data)

    const onClickOk = async () => {
        onCloseModal()
        await dispatch(getNFTDetail({ tokenId, collectionAddress: collection.id || collection.address }))
        dispatch(changePageOfferOpenBid({value: 1}))
        dispatch(changePageHistory({value: 1}))
        await dispatch(getTradingHistory({ nftId: id }))
        await dispatch(getOfferList({ nftId: id }))
    }

    return (
        <Modal visible={true} footer={null} closable={false} className="custom-process-modal">
            <div className="bid-success-modal">
                <p>Your offer has been placed</p>
                <p>Click OK to continue.</p>
                <ButtonCustom fullWidth color="blue" onClick={onClickOk}>
                    OK
                </ButtonCustom>
            </div>
        </Modal>
    )
}

export default MakeOfferSuccess
