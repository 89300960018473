import BigNumber from 'bignumber.js'

export function shortenAddress(address = '', start = 10, chars = 4) {
    return `${address.substring(0, start)}...${address.substring(address.length - chars)}`
}

export function shortenPersonalSite(personalSite = '', start = 30, chars = 10) {
    return `${personalSite.substring(0, start)}...${personalSite.substring(personalSite.length - chars)}`
}
export function shortenName(name = '', start = 10) {
    if (name.length <= start) {
        return name
    }
    return `${name.substring(0, start)}...`
}
export function shortenNameNoti(name = '', start = 10) {
    return name?.length <= start ? `${name.substring(0, start)}` : `${name.substring(0, start)}...`
}
export const roundNumber = (price, params = 6) => {
    if (!price) return 0
    if (price < 1) return price

    return new BigNumber(price).decimalPlaces(params).toFormat().toString()
}

export const makeNumberTwoDigit = number => {
    return number < 10 ? `0${number}` : number
}

export const getBase64 = (img, callback) => {
    const reader = new FileReader()
    reader.addEventListener('load', () => callback(reader.result))
    reader.readAsDataURL(img)
}

export const capitalizeLetter = (value) => {
    if (!value) {
        return '';
    }
    return value.charAt(0).toUpperCase() + value.slice(1);
};
