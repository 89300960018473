import React, { useCallback, useContext, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { Card, Avatar } from 'antd'
import { useTranslation } from 'react-i18next'
import { Link, useHistory } from 'react-router-dom'
import { useSelector } from 'react-redux'
import moment from 'moment'

import { SvgIcon } from '../index'
import { TooltipCustom } from 'components/common'

import { FILE_VIDEO_EXTENSION, NETWORK_ICON, NFT_TYPE, SALE_TYPE } from 'constants/index'
import { convertImage, convertImageAvatar } from 'utils/image'
import { roundNumber, shortenAddress } from 'utils/func'
import HeartIcon from 'assets/icons/heart-icon-small.svg'
import FullHeartIcon from 'assets/icons/full-heart-icon.svg'
import PlayGroupIcon from 'assets/icons/play-group-icon.svg'
import Mp3Icon from 'assets/icons/music-group-icon.svg'
import { isUserAuthenticated } from 'utils/auth'
import * as message from 'utils/custom-message'
import { useAuth } from 'hooks/useAuth'
import CustomVerifyIcon from 'components/common/custom-verify-icon'
import CountDown from 'pages/nft-detail/elements/Overview/Auction/CountDown'
import nftService from 'service/nftService'
import './style.scss'
import { ThemeContext } from '../../../ThemeProvider'

const CardItemPreview = ({ data, className, disable, isPreview, nftsLikes = [] }) => {
    const { theme } = useContext(ThemeContext)
    const history = useHistory()
    const { t } = useTranslation()
    const {
        id,
        name,
        type,
        price,
        totalLikes,
        placeholderImage,
        smallImage,
        auctions,
        user,
        ownerNfts,
        collection,
        nftType,
        tokenType,
        onSaleStatus,
        fileExtension,
        networkType
    } = data || {}
    const { profile } = useSelector(state => state.user)
    const [counter, setCounter] = useState(null)
    const [isStartTimeAfterCurrent, setIsStartTimeAfterCurrent] = useState(false)
    const [currentTime, setCurrentTime] = useState(moment().unix())
    const [isLike, setIsLike] = useState(false)
    const [countLike, setCountLike] = useState(0)
    const [, handleSignWallet] = useAuth()
    const startTimeValue = auctions && auctions?.startDate ? Number(auctions?.startDate) : currentTime

    useEffect(() => {
        if (counter) {
            let timer = null

            if (isStartTimeAfterCurrent) {
                timer = setInterval(() => {
                    if (counter - currentTime > 0) {
                        setCounter(counter - 1)
                    }
                }, 1000)
            } else {
                timer = setInterval(() => {
                    if (counter - startTimeValue > 0) {
                        setCounter(counter - 1)
                    }
                }, 1000)
            }

            return () => {
                clearInterval(timer)
            }
        }
    }, [counter, startTimeValue, isStartTimeAfterCurrent, currentTime])

    useEffect(() => {
        if (
            type === SALE_TYPE.ENGLISH_AUCTION &&
            auctions &&
            auctions?.expireDate &&
            auctions?.expireDate - startTimeValue > 0
        ) {
            if (startTimeValue > currentTime) {
                setIsStartTimeAfterCurrent(true)
                setCounter(startTimeValue)
                return
            }
            setCounter(Number(auctions?.expireDate))
            setIsStartTimeAfterCurrent(false)
        } else {
            setCounter(null)
        }
    }, [auctions?.expireDate, startTimeValue, type, currentTime, auctions])

    useEffect(() => {
        setCurrentTime(moment().unix())
    }, [auctions])

    useEffect(() => {
        const idNftLikes = nftsLikes?.map(ele => ele?.id)
        if (idNftLikes.includes(id)) {
            setIsLike(true)
        } else {
            setIsLike(false)
        }
    }, [nftsLikes, id, profile?.walletAddress])
    useEffect(() => {
        setCountLike(totalLikes)
    }, [totalLikes])
    const likeNft = async () => {
        const [res, err] = await nftService.likeNft({ id })
        if (err) {
            return message.error(t(`error.message.${err.message}`))
        } else {
            setIsLike(!isLike)
            return setCountLike(res.totalLikes)
        }
    }

    const handleLikeNft = async e => {
        e.preventDefault()
        e.stopPropagation()
        if (!profile?.id) {
            return history.push({ pathname: '/connect', state: { from: '/' } })
        }
        if (!isUserAuthenticated()) {
            const res = await handleSignWallet()
            if (res === false) return
        }
        await likeNft()
    }
    const renderPrice = useCallback(() => {
        if (onSaleStatus === 0) {
            return <span className="card-item-custom-content_footer__notForSale">{t('common.notForSale')}</span>
        }

        if (onSaleStatus === 1) {
            if (type === SALE_TYPE.OPEN_FOR_OFFER) {
                return <span className="card-item-custom-content_footer__make-an-offer">{t('collection.makeAnOffer')}</span>
            }
            if (nftType === NFT_TYPE.ERC1155) {
                return <span className="card-item-custom-content_footer__make-an-offer">{t('collection.makeAnOfferOrBuy')}</span>
            }

            if (type === SALE_TYPE.ENGLISH_AUCTION && auctions) {
                return (
                    <div className="card-item-custom-content_footer__highest-price">
                        <span className="card-item-custom-content_footer__highest-bid">{t('collection.minimumBid')}</span>
                        <span className="card-item-custom-content_footer__price">
                            {roundNumber(auctions?.minBid) || ''} {auctions?.tokenType.toUpperCase()}
                        </span>
                    </div>
                )
            }

            return (
                <>
                    <span
                        className={`card-item-custom-content_footer__price ${!name ? 'card-item-custom-content_footer__price___more' : ''
                            }`}
                    >
                        {roundNumber(price) || ''} {tokenType.toUpperCase()}
                    </span>
                </>
            )
        }

        return null
    }, [auctions, onSaleStatus, price, tokenType, t, type, nftType, name])

    const renderTimeLeft = useCallback(() => {
        if (isStartTimeAfterCurrent) {
            return (
                <div className="card-item-custom-content_auction">
                    <SvgIcon name="time-restore-icon" />
                    <span className="card-item-custom-content_auction__left">{t('times.startIn')}</span>
                    <span className="card-item-custom-content_auction__counter-container">
                        <CountDown isExtraSmall till={auctions?.startDate} />
                    </span>
                </div>
            )
        }

        return (
            <div className="card-item-custom-content_auction">
                <SvgIcon name="time-restore-icon" />
                <span className="card-item-custom-content_auction__left">{t('times.left')}</span>
                <span className="card-item-custom-content_auction__counter-container">
                    <CountDown isExtraSmall till={auctions?.expireDate} />
                </span>
            </div>
        )
    }, [isStartTimeAfterCurrent, t, auctions])

    if (!data) {
        return null
    }

    console.log({user})

    return (
        <Card
            className={`${classNames({ 'card-item-preview-custom': true, [className]: !!className })} ${theme === 'dark-theme' ? 'dark-card-item-preview-custom' : ''
                }`}
        >
            <div className="card-item-custom-header">
                <div className="card-item-owner-creator-wrapper">
                    <div className="card-item-owner">
                        {ownerNfts && (
                            <>
                                <TooltipCustom
                                    className="card-item-custom-usersGroupItem"
                                    title={
                                        <>
                                            <span className="tooltip-customer-title__role">Owner:&nbsp;</span>
                                            <span>
                                                {isPreview
                                                    ? user?.name || shortenAddress(user?.walletAddress)
                                                    : ownerNfts?.user?.name ||
                                                    shortenAddress(
                                                        ownerNfts?.user?.walletAddress || user?.walletAddress
                                                    )}
                                            </span>
                                        </>
                                    }
                                    placement="top"
                                >
                                    <Link
                                        to={
                                            (!disable || isPreview) &&
                                            (ownerNfts?.user?.walletAddress
                                                ? ownerNfts?.user?.shortUrl
                                                    ? `/${ownerNfts?.user?.shortUrl}`
                                                    : `/user/${ownerNfts?.user?.walletAddress}`
                                                : user?.shortUrl
                                                    ? `/${user?.shortUrl}`
                                                    : `/user/${user?.walletAddress}`)
                                        }
                                    >
                                        <div className="card-item-custom-users_avatar_wrapper">
                                            <Avatar
                                                className="card-item-custom-users_avatar"
                                                alt="example"
                                                src={convertImageAvatar(ownerNfts?.user?.avatarImg || user?.avatarImg)}
                                            />
                                            <CustomVerifyIcon className="card-item-custom-users_icon__last" />
                                        </div>
                                    </Link>
                                </TooltipCustom>
                            </>
                        )}
                    </div>
                    <div className="card-item-creator">
                        {user && (
                            <>
                                <TooltipCustom
                                    className="card-item-custom-usersGroupItem"
                                    title={
                                        <>
                                            <span className="tooltip-customer-title__role">Creator:&nbsp;</span>
                                            <span>{user?.name || shortenAddress(user?.walletAddress)}</span>
                                        </>
                                    }
                                    placement="top"
                                >
                                    <Link
                                        to={
                                            (!disable || isPreview) &&
                                            (user?.shortUrl ? `/${user?.shortUrl}` : `/user/${user?.walletAddress}`)
                                        }
                                    >
                                        <div className="card-item-custom-users_avatar_wrapper">
                                            <Avatar
                                                className="card-item-custom-users_avatar"
                                                alt="example"
                                                src={convertImageAvatar(user?.avatarImg)}
                                            />
                                            <CustomVerifyIcon className="card-item-custom-users_icon__last" />
                                        </div>
                                    </Link>
                                </TooltipCustom>
                            </>
                        )}
                    </div>
                </div>
                <div className="card-item-custom-header_avatar">
                    <Avatar
                        className="card-item-custom-avatar"
                        alt="item"
                        src={placeholderImage ? placeholderImage : convertImage(smallImage)}
                    />
                    {fileExtension === FILE_VIDEO_EXTENSION.MP4 && (
                        <div className="card-item-custom-header_videoIcon">
                            <img src={PlayGroupIcon} alt="PlayGroupIcon" />
                        </div>
                    )}
                    {fileExtension === FILE_VIDEO_EXTENSION.MPEG && (
                        <div className="card-item-custom-header_videoIcon">
                            <img src={Mp3Icon} alt="Mp3Icon" />
                        </div>
                    )}
                    <div className="card-item-custom-header-like" onClick={e => !disable && handleLikeNft(e)}>
                        {isLike && !disable ? (
                            <img src={FullHeartIcon} className="heart-icon" alt="full-heart-icon" />
                        ) : (
                            <img src={HeartIcon} className="heart-icon" alt="heart-icon" />
                        )}
                        <span className="card-item-custom-header-like__count">{countLike}</span>
                    </div>
                    {type === SALE_TYPE.ENGLISH_AUCTION && auctions && counter && counter > 0 ? renderTimeLeft() : null}
                </div>
            </div>
            <div
                className={`card-item-custom-content ${theme === 'dark-theme' ? 'dark-card-item-custom-content' : ''}`}
            >
                <div className="card-item-custom-content_header">
                    <div className="card-item-custom-users">
                        <div className="card-item-custom-usersGroup">
                            {collection && (
                                <TooltipCustom
                                    className="card-item-custom-usersGroupItem"
                                    title={
                                        <>
                                            <span className="tooltip-customer-title__role">Collection:&nbsp;</span>
                                            <span>{collection?.name}</span>
                                        </>
                                    }
                                    placement="top"
                                >
                                    <Link
                                        to={
                                            (!disable || isPreview) &&
                                            `/collection/${collection?.shortUrl || collection?.id || collection?.address
                                            }`
                                        }
                                    >
                                        <div className="card-item-custom-users_avatar_wrapper">
                                            <Avatar
                                                className="card-item-custom-users_avatar"
                                                alt="example"
                                                src={convertImage(collection?.bannerUrl)}
                                            />{' '}
                                            <span>{collection?.title || collection?.name}</span>
                                            {collection?.isVerify ? (
                                                <CustomVerifyIcon className="card-item-custom-users_icon__last" />
                                            ) : null}
                                        </div>
                                    </Link>
                                </TooltipCustom>
                            )}
                        </div>
                    </div>
                </div>
                <div className="card-item-custom-content_title">
                    <div className="card-item-custom-content_title__title">
                        <span>{name}</span>
                    </div>
                    <TooltipCustom title={networkType}>
                        <Avatar src={NETWORK_ICON[networkType]} />
                    </TooltipCustom>
                </div>
                <div className="card-item-custom-content_footer">
                    <div className="card-item-custom-content_footer__block right">{renderPrice()}</div>
                </div>
            </div>
        </Card>
    )
}

CardItemPreview.propTypes = {
    data: PropTypes.object.isRequired,
    className: PropTypes.string,
    disable: PropTypes.bool,
    isPreview: PropTypes.bool
}

export default CardItemPreview
