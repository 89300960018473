import "./style.scss";
import styles from "./style.module.scss";
import LightSearchIcon from "assets/icons/search-icon-mobile-blue.svg";
import { InputCustom, RadioGroupCustom, SelectCustom } from "components/common";

//constants
import { SORT_BY } from "constants/index";
import CustomSwitch from "components/common/switch";
import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

const optionFilter = [
  { label: "Verified Only", key: "verification" },
  { label: "Show NSFW", key: "nsfw" },
  { label: "Show lazy minted", key: "lazyMinted" },
];

const SearchFilterNft = ({
  filter,
  handleFilterBy,
  filterByOption,
  handleChangeSearch,
  handleChangeSort,
}) => {
  const { t } = useTranslation();
  // const [labelPlaceholderInput, setLabelPlaceholderInput] =
  //   useState(t(`common.sortAndFilter.title`));

  // useEffect(() => {
  //   if (filter) {
  //     const filterData = SORT_BY.filter((sort) => sort.value === filter);
  //     setLabelPlaceholderInput(filterData[0]?.label);
  //   }
  // }, [filter]);

  const currentFilter = SORT_BY.find((item) => item.value === filter) || SORT_BY[0].label;
  const labelPlaceholderInput = filter ? t(`common.sortAndFilter.sort.${currentFilter.value}`) : t(`common.sortAndFilter.title`);

  const convertedOptionFilter = optionFilter.map((item) => ({
    ...item,
    label: t(`common.sortAndFilter.options.${item.key}`),
  }))

  const dropDownSortBy = useCallback(
    () => (
      <div className="dropdown-collection dropdown-explorer dropdown-explorer dropdown-sortby">
        <div className="collection-holder">
          <div className="filter-title">
            {t("common.sortAndFilter.sort.title")}
          </div>
          <RadioGroupCustom
            options={SORT_BY.map((item) => ({
              value: item.value,
              label: t(`common.sortAndFilter.sort.${item.value}`),
            }))}
            value={filter ? filter : SORT_BY[0].value}
            onChange={handleChangeSort}
          />
          <div className="filter-title">
            {t("common.sortAndFilter.options.title")}
          </div>

          {convertedOptionFilter?.map((item, index) => (
            <div key={index} className="verification-holder">
              {item.label}
              <CustomSwitch
                onChange={() => handleFilterBy(item.key)}
                value={filterByOption[item.key]}
                disableLabel={true}
                className="switch-filter"
              />
            </div>
          ))}
        </div>
      </div>
    ),

    [filter, handleFilterBy, filterByOption, convertedOptionFilter]
  );

  return (
    <>
      <div className="collection-detail-filters container">
        <div className={`search ${styles.searchBox}`}>
          <i className="searchIcon ri-search-line"></i>
          <InputCustom
            className={`search-box ${styles.search}`}
            placeholder={t("profile.searchInput")}
            // value={keyword}
            // onPressEnter={handleSearchMobile}
            onChange={handleChangeSearch}
          />
        </div>
        <SelectCustom
          className={`all-categories-button ${styles.select} ${styles.select_filter}`}
          // placeholder={t(`common.sortAndFilter.sort.${sortBy}`)}
          // placeholder={filter ? filter : "Sort & Filter"}
          placeholder={labelPlaceholderInput}
          categoryIcon={true}
          dropdownClassName="all-categories-button__dropdown--filter"
          dropdownRender={dropDownSortBy}
        />
      </div>
    </>
  );
};

export default SearchFilterNft;
