import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { Row, Col, message } from "antd";
import { useSelector } from "react-redux";
import ReactLoading from "react-loading";

import { CardItem } from "components/modules";
import { useIntersection } from "react-use";
import { ButtonCustom, NoData } from "components/common";
import { useTranslation } from "react-i18next";
import nftService from "service/nftService";
import { NFT_STATUS } from "store/constants/nft";

import "./style.scss";
import SearchFilterNft from "../ListCustom/SearchFilterNft";

const LIMIT = 8;

const LikedTab = ({ addressUrl, active, walletAddress }) => {
  const [list, setList] = useState([]);
  const [page, setPage] = useState(1);
  const [total, setTotal] = useState(0);
  const [autoFetch, setAutoFetch] = useState(false);
  const [loading, setLoading] = useState(false);
  const anchorEl = useRef(null);
  const { t } = useTranslation();
  const system = useSelector((state) => state?.system);
  const intersection = useIntersection(anchorEl, {
    root: null,
    rootMargin: "0px",
    threshold: 1,
  });
  const inView =
    intersection?.isIntersecting && intersection.intersectionRatio >= 1;

  const [filter, setFilter] = useState();
  const [filterByOption, setFilterByOption] = useState({
    verification: false,
    nsfw: false,
    lazyMinted: false,
  });
  const [searchText, setSearchText] = useState("");
  const [dependencySearchingState, setDependencySearchingState] =
    useState(false);

  const isLoadMore = useMemo(() => {
    return page * LIMIT < total;
  }, [total, page]);

  const infinity = autoFetch && isLoadMore && inView;

  const handleLoadMore = useCallback(
    () => {
      if (loading) {
        return;
      }
      const isLoadMore = page * LIMIT < total;

      if (isLoadMore) {
        setPage((prev) => prev + 1);
        setDependencySearchingState(true);
      }
    }, // eslint-disable-next-line react-hooks/exhaustive-deps
    [total, loading]
  );

  //get item of user address liked

  useEffect(() => {
    setList([]);
    setTotal(0);
    setPage(1);
    setAutoFetch(false);
  }, [addressUrl, active, walletAddress]);

  useEffect(() => {
    let timer = setTimeout(() => {
      setList([]);
      setTotal(0);
      setPage(1);
      setAutoFetch(false);
      setDependencySearchingState(true);
    }, 1000);

    return () => clearTimeout(timer);
  }, [searchText, filter, filterByOption]);

  //get item of user login liked

  // useEffect(() => {
  //   if (infinity) {
  //     handleLoadMore();
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [infinity]);

  const fetchListNft = async () => {
    setLoading(true);
    let data = {
      page,
      limit: LIMIT,
      sort: filter,
      walletAddress,
      type: active,
      // isVerify: filterByOption?.verification ? 1 : 0,
      // nsfw: filterByOption?.nsfw ? 1 : 0,
      // isMinted: filterByOption?.lazyMinted ? 1 : 0,
      isVerify: filterByOption?.verification ? 1 : "",
      nsfw: filterByOption?.nsfw ? 1 : "",
      isMinted: filterByOption?.lazyMinted ? 1 : "", // the previous value is number 0, not empty string "", but i see use 0 can not get all nft api by default when visiting the site my profile
      title: searchText,
    };

    const [res, err] = await nftService.getListNft(data);
    if (res) {
      setList([...list, ...res?.items]);
      setTotal(res?.meta?.totalItem);
    } else if (err) message.error(t("error.message.somethingWentWrong"));

    setDependencySearchingState(false);
    setLoading(false);
  };

  useEffect(() => {
    if (page && dependencySearchingState) {
      fetchListNft();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, dependencySearchingState]); // init dependencies [page]

  const handleChangeSort = (value) => {
    setFilter(value);
  };

  const handleFilterBy = useCallback(
    (key) => {
      setFilterByOption({ ...filterByOption, [key]: !filterByOption[key] });
    },
    [filterByOption]
  );

  const handleChangeSearch = (e) => {
    setSearchText(e.target.value);
  };

  return (
    <div className="liked-container">
      <div className="filter-option">
        <SearchFilterNft
          filter={filter}
          handleFilterBy={handleFilterBy}
          filterByOption={filterByOption}
          handleChangeSearch={handleChangeSearch}
          handleChangeSort={handleChangeSort}
        />
      </div>
      <Row gutter={[{ xs: 6, sm: 6, md: 20, lg: 20, xl: 20 }, 20]}>
        {list.map((item) => {
          return (
            <Col key={item.id} xxl={6} xl={6} lg={8} md={12} sm={12} xs={24}>
              <CardItem
                className="card-item"
                data={item}
                walletAddress={walletAddress}
              />
            </Col>
          );
        })}
        {!list?.length && !loading && <NoData />}
      </Row>
      {isLoadMore && !loading && (
        <div className="d-flex justify-content-center mt-40">
          <ButtonCustom
            color="blue"
            className="btn-load"
            onClick={async () => {
              if (autoFetch) return;
              await handleLoadMore();
              // setAutoFetch(true);
            }}
          >
            {t("profile.loadMore")}
          </ButtonCustom>
        </div>
      )}
      {loading && !system?.loading && (
        <div className="d-flex justify-content-center mt-40">
          <ReactLoading
            className="loading-more"
            type="spinningBubbles"
            color="#002979"
          />
        </div>
      )}
      <div ref={anchorEl}></div>
    </div>
  );
};

export default LikedTab;
