import { useTranslation } from "react-i18next"
import { TRADING_ACTIONS } from "constants/index";

const TABS_PERIOD = {
    ALL: 'ALL',
    LAST1H: 'LAST1H',
    LAST6H: 'LAST6H',
    LAST24H: 'LAST24H',
    TODAY: 'TODAY',
    LAST7DAYS: 'LAST7DAYS',
    LAST30DAYS: 'LAST30DAYS',
};

export const useMultiLanguageConstant = () => {
    const { t, i18n } = useTranslation();

    const periodSelections = [
        { value: TABS_PERIOD.LAST1H, label: t('common.period.one_hour') },
        { value: TABS_PERIOD.LAST6H, label: t('common.period.six_hour') },
        { value: TABS_PERIOD.LAST24H, label: t('common.period.one_day') },
        { value: TABS_PERIOD.LAST7DAYS, label: t('common.period.seven_day') },
        { value: TABS_PERIOD.ALL, label: t('common.period.all') },
    ]

    const timeRange = [
        { value: '', label: t('common.timeRange.ALL') },
        { value: 'TODAY', label: t('common.timeRange.TODAY') },
        { value: 'LAST7DAYS', label: t('common.timeRange.LAST7DAYS') },
        { value: 'LAST30DAYS', label: t('common.timeRange.LAST30DAYS') }
    ]

    const listFilterActivity = [
        {
            id: 'LISTING',
            // name: 'activity-listing-icon',
            // nameActive: 'activity-listing-icon-active',
            icon: 'ri-file-list-2-line',
            filterTitle: t('activity.listings')
        },
        {
            id: 'PURCHASE',
            // icon: 'ri-shopping-cart-2-line',
            // nameActive: 'activity-purchases-icon-active',
            icon: 'ri-shopping-cart-2-line',
            filterTitle: t('activity.purchases')
        },
        {
            id: 'LIKED',
            // name: 'activity-likes-icon',
            // nameActive: 'activity-likes-icon-active',
            icon: 'ri-heart-line',
            filterTitle: t('activity.likes')
        },
        {
            id: 'FOLLOW',
            // name: 'activity-following-icon',
            // nameActive: 'activity-following-icon-active',
            icon: 'ri-user-follow-line',
            filterTitle: t('activity.followings')
        },
        {
            id: 'BID',
            // name: 'activity-bids-icon',
            // nameActive: 'activity-bids-icon-active',
            icon: 'ri-auction-line',
            filterTitle: t('activity.bids')
        }
    ]

    const tradingActions = TRADING_ACTIONS.map(action => ({
        ...action,
        label: t(`tradingActions.${action.value ? action.value : 'all'}`)
    }))

    return { periodSelections, timeRange, listFilterActivity, tradingActions }
}