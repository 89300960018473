import { 
  createConfig, 
  fallback,
  http,
} from '@wagmi/core'
import {
  // localhost, develop, uat
  bscTestnet,
  sepolia,
  polygonAmoy,
  // production
  bsc,
  mainnet,
} from 'wagmi/chains';
import { metaMask, walletConnect } from 'wagmi/connectors';

import env from 'env/index';
import { NETWORKS } from 'constants/envs';

// Configure Wagmi
const configNetworks = Object.keys(NETWORKS).map(key => NETWORKS[key]);

const chains = [bscTestnet, sepolia, polygonAmoy, bsc, mainnet].filter(chain => {
return configNetworks.some(network => network.id === chain.id);
});

const getTransports = () => {
  const transports = {};
  chains.forEach(chain => {
    const chainId = chain.id;
    const networkConfig = configNetworks.find(network => network.id === chainId);

    const rpcUrls = networkConfig ? networkConfig.rpcUrls : chain.rpcUrls.default.http;
    transports[chainId] = fallback(rpcUrls.map(rpcUrl => http(rpcUrl)));
  });

  return transports;
};

export const wagmiConfig = createConfig({
  chains: chains,
  connectors: [
  // injected(),
  metaMask(),
  walletConnect({
    projectId: env.metadata.walletconnectProjectId,
  }),
  ],
  transports: getTransports(),
})
