import { useContext } from 'react'
import ButtonCustom from '../button'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import { ThemeContext } from 'ThemeProvider'

import './style.scss'

import SmartPocketComingSoon from 'assets/images/smart-pocket-coming-soon.svg'

export default function ComingSoon({ directUrl = '' }) {
    const { theme } = useContext(ThemeContext)
    const history = useHistory()
    const { t } = useTranslation()

    const handleDirectUrl = () => {
        if (directUrl) {
            history.push(directUrl)
        } else {
            history.goBack()
        }
    }

    return (
        <div className="coming-soon-container">
            <img src={SmartPocketComingSoon} alt="coming soon" />
            <div className='comingSoon-content'>
                <h2 className={`comingSoon-title ${theme === 'dark-theme' ? 'text-ac-dark' : ''}`}>
                    {t('comingSoon_title')}
                </h2>
                <p className="comingSoon-description">{t('comingSoon_description')}</p>
            </div>
            <ButtonCustom onClick={handleDirectUrl} color="blue">
                {t('common.browseMarketplace')}
            </ButtonCustom>
        </div>
    )
}