import React, {
  useEffect,
  useState,
  useCallback,
  useMemo,
  useRef,
} from "react";
import { Link } from "react-router-dom";
import { useIntersection } from "react-use";
import ReactLoading from "react-loading";
import { useSelector } from "react-redux";

import { ButtonCustom } from "components/common";

import { shortenAddress } from "utils/func";
import { TooltipCustom } from "components/common";

import * as message from "utils/custom-message";
import userService from "service/userService";
import CustomVerifyIcon from "components/common/custom-verify-icon";
import { convertImageAvatar } from "utils/image";

const STEP = 100;

const ModalContent = (props) => {
  const {
    profileId,
    walletAddress,
    list,
    handleModalCancel,
    t,
    checkFollowed,
    handleFollow,
    getCurrentList,
    type,
    total,
    disabledState,
  } = props;
  const [currentList, setCurrentList] = useState(list?.data);

  const system = useSelector((state) => state?.system);
  const [loading, setLoading] = useState(false);
  const anchorEl = useRef(null);
  const intersection = useIntersection(anchorEl, {
    root: null,
    rootMargin: "0px",
    threshold: 1,
  });
  const inView =
    intersection &&
    intersection.isIntersecting &&
    intersection.intersectionRatio >= 1;
  const [followingCount, setFollowingCount] = useState(STEP);
  const [followerCount, setFollowerCount] = useState(STEP);

  const isLoadMore = useMemo(() => {
    return currentList?.length < total;
  }, [currentList?.length, total]);

  const infinity = inView && isLoadMore;

  const handeLoadMore = useCallback(
    () => {
      if (loading) {
        return;
      }
      if (type === "following") {
        const next = followingCount + STEP;
        const _itemCount = next > total ? total : followingCount + STEP;
        setFollowingCount(_itemCount);
      }
      if (type === "follower") {
        const next = followerCount + STEP;
        const _itemCount = next > total ? total : followerCount + STEP;
        setFollowerCount(_itemCount);
      }
    }, // eslint-disable-next-line react-hooks/exhaustive-deps
    [total, loading]
  );

  useEffect(() => {
    if (infinity) {
      handeLoadMore();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [infinity]);

  useEffect(
    () => {
      setCurrentList([]);
      getCurrentList();
      setFollowingCount(STEP);
      setFollowerCount(STEP);
    }, // eslint-disable-next-line react-hooks/exhaustive-deps
    [walletAddress]
  );

  useEffect(
    // eslint-disable-next-line react-hooks/exhaustive-deps
    () => {
      const getFollowList = async () => {
        setLoading(true);
        let res = null;
        let err = null;
        if (type === "following") {
          const [response, errRes] = await userService.getFollowingList(
            walletAddress,
            1,
            followingCount
          );
          if (response) res = response;
          else err = errRes;
        } else if (type === "follower") {
          const [response, errRes] = await userService.getFollowerList(
            walletAddress,
            1,
            followerCount
          );
          if (response) res = response;
          else err = errRes;
        }
        if (res) {
          setCurrentList(res.items);
        } else if (err) {
          message.error(t("error.message.somethingWentWrong"));
        }
        setLoading(false);
      };
      getFollowList();
    }, // eslint-disable-next-line react-hooks/exhaustive-deps
    [followingCount, followerCount, walletAddress]
  );

  return (
    <div className="user-list-container">
      {currentList
        ? currentList.map((user, index) => {
            return (
              <div key={index} className="current-following">
                <Link
                  to={
                    user?.shortUrl
                      ? `/user/${user?.shortUrl}`
                      : `/user/${user?.walletAddress}`
                  }
                  onClick={handleModalCancel}
                >
                  <div className="avatar">
                    <img src={convertImageAvatar(user.avatarImg)} alt="" />
                    {user?.isVerify && (
                      <CustomVerifyIcon className="verify-icon" />
                    )}
                  </div>
                </Link>
                <div className="information">
                  <TooltipCustom
                    title={
                      user?.username ? (
                        <span>{user?.username}</span>
                      ) : (
                        <span>{user?.walletAddress}</span>
                      )
                    }
                    placement="top"
                  >
                    <Link
                      to={
                        user?.shortUrl
                          ? `/user/${user?.shortUrl}`
                          : `/user/${user?.walletAddress}`
                      }
                      onClick={handleModalCancel}
                    >
                      <div className="user-name">
                        <p>
                          {user?.username
                            ? user?.username.length > 8
                              ? `${user?.username.substring(0, 8)}...`
                              : user?.username
                            : shortenAddress(user?.walletAddress, 4)}
                        </p>
                      </div>
                    </Link>
                  </TooltipCustom>
                  <div className="number-follow">
                    {(user?.totalOfFollowers || 0) +
                      ` ${t("profile.followersLowercase")}`}
                  </div>
                </div>
                {checkFollowed(user?.accountId) ? (
                  <ButtonCustom
                    color="gray"
                    className="btn-unfollow"
                    onClick={() => handleFollow(user?.walletAddress)}
                    disabled={
                      disabledState === user?.walletAddress ? true : false
                    }
                  >
                    {t("profile.unfollow")}
                  </ButtonCustom>
                ) : (
                  user?.accountId !== profileId && (
                    <ButtonCustom
                      color="blue"
                      className="btn-follow"
                      onClick={() => handleFollow(user?.walletAddress)}
                      disabled={
                        disabledState === user?.walletAddress ? true : false
                      }
                    >
                      {t("profile.followProfile")}
                    </ButtonCustom>
                  )
                )}
              </div>
            );
          })
        : ``}
      {loading && !system?.loading && (
        <ReactLoading
          className="loading-more"
          height="50px"
          width="50px"
          type="spinningBubbles"
          color="#002979"
        />
      )}
      <div ref={anchorEl}></div>
    </div>
  );
};

export default ModalContent;
