import React, { useState, useCallback, useEffect, useMemo, memo } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector, useDispatch } from 'react-redux'
import { Modal, Form, Button, Row } from 'antd'

import { InputCustom, ButtonCustom } from 'components/common'
import collectionService from 'service/collectionService'
import { isUserAuthenticated } from 'utils/auth'
import { useAuth } from 'hooks/useAuth'
import * as message from 'utils/custom-message'
import env from 'env/index'
import './styles.scss'
import { clearErrorVerifyTwitterCollection, confirmVerifiedCollection, unlinkTwitterCollection } from 'store/collection.slice'

const EditSocialLinkModal = ({ onCloseEditSocialLinkModal, visible, collectionData, reloadCollection }) => {
    const [form] = Form.useForm()
    const { t } = useTranslation()
    const [, handleSignWallet] = useAuth()
    const dispatch = useDispatch()

    const { error, collection, verifyTwitterStatus } = useSelector(state => state.collection)
    const { isVerifyTwitter, twitterLink } = collectionData

    const [isFormError, setIsFormError] = useState(false)
    const [mediumName, setMediumName] = useState('')
    const [twitterName, setTwitterName] = useState('')
    const [twitterUserName, setTwitterUserName] = useState('')
    const [instagramName, setInstagramName] = useState('')
    const [personalSiteName, setPersonalSiteName] = useState('')
    const [isShowLinkTwitter, setIsShowLinkTwitter] = useState(true)
    const socialChanelUser = useMemo(
        () => [
            {
                name: 'Twitter',
                url: collectionData.twitterLink,
                verified: isVerifyTwitter
            },
            {
                name: 'Instagram',
                url: collectionData.instagramLink
            },
            {
                name: 'Medium',
                url: collectionData.mediumLink
            },
            {
                name: 'Personal Site',
                url: collectionData.website
            }
        ],
        [
            collectionData.twitterLink,
            collectionData.instagramLink,
            collectionData.mediumLink,
            collectionData.website,
            isVerifyTwitter
        ]
    )
    const twitterChannel = socialChanelUser?.find(item => item.name === 'Twitter' && item?.url)

    const getText = useCallback(key => t(`editProfile.${key}`), [t])

    const getErrorSocial = useCallback(key => t(`editProfile.${key}Invalid`), [t])

    const validatorUserSocial = (rules, value) => {
        const regex = /^[a-zA-Z0-9-_.]+$/.test(value)
        if (!regex && value) {
            return Promise.reject(getErrorSocial(rules.field))
        } else return Promise.resolve()
    }

    const removeError = useCallback(() => {
        setIsFormError(false)
    }, [])

    const initialFormValues = useMemo(() => {
        return {
            twitterName,
            mediumName,
            instagramName,
            personalSiteName
        }
    }, [twitterName, mediumName, instagramName, personalSiteName])

    const handleUpdateSocialLinks = async () => {
        try {
            const values = await form.validateFields()
            const [res, err] = await collectionService.patchCollection({
                id: collectionData?.id,
                twitterLink: values.twitterName,
                instagramLink: values.instagramName,
                mediumLink: values.mediumName,
                website: values.personalSiteName
            })

            if (res) {
                reloadCollection()
            }

            if (err) {
                return message.error(t('error.message.failToUpdateCollection', { err }))
            }
            onCloseEditSocialLinkModal()
        } catch (error) {
            setIsFormError(true)
            return
        }

        setIsShowLinkTwitter(true)
    }

    const handleRedirectVerifyTwitter = useCallback(() => {
        setIsShowLinkTwitter(false)
        const url = window.location.origin
        const param = `I’m verifying my Twitter account with @Smartpocket. Check out my collection: ${url}/collection/${collectionData?.shortUrl}`
        window.open(`${env.metadata.tweetTwitterLink}?text=${param}`, '_blank')
    }, [collectionData?.shortUrl])

    const handleConfirmVerified = useCallback(async () => {
        setIsShowLinkTwitter(false)
        if (!isUserAuthenticated()) {
            const res = await handleSignWallet()
            if (res === false) return
        }

        await dispatch(confirmVerifiedCollection({
            address: collectionData?.id,
            twitterUsername: form.getFieldValue('twitterName'),
        }));
        if (verifyTwitterStatus) {
            reloadCollection()
        }
    }, [form, handleSignWallet, dispatch, verifyTwitterStatus, reloadCollection])

    const onCloseModal = () => {
        onCloseEditSocialLinkModal()
        form.resetFields()
        setTwitterName(twitterChannel?.url)

        setTwitterUserName(twitterChannel?.url)
        setIsShowLinkTwitter(true)
    }

    const handleRemoveTwitterAccount = async () => {
        const [res, err] = await collectionService.unLinkTwitter(collectionData?.id)

        if (res) {
            setTwitterName('')
            setTwitterUserName('')
            dispatch(unlinkTwitterCollection());
            form.setFieldsValue({ twitterName: '' })
            setIsShowLinkTwitter(true)
            reloadCollection()
        }

        if (err) {
            return message.error(t('error.message.failToUpdateCollection', { err }))
        }
    }

    useEffect(() => {
        if (socialChanelUser) {
            socialChanelUser.forEach(element => {
                switch (element.name) {
                    case 'Twitter':
                        setTwitterName(element.url)
                        setTwitterUserName(element.url)
                        break
                    case 'Medium':
                        setMediumName(element.url)
                        break
                    case 'Instagram':
                        setInstagramName(element.url)
                        break
                    case 'Personal Site':
                        setPersonalSiteName(element.url)
                        break
                    default:
                        break
                }
            })
        }
    }, [collectionData, socialChanelUser])

    useEffect(() => {
        if (!verifyTwitterStatus && error) {
            message.error(t('profile.verifyTwitterError'))
        }
        return () => {
            dispatch(clearErrorVerifyTwitterCollection());
        }
    }, [dispatch, error, t, verifyTwitterStatus])

    useEffect(() => {
        if (collectionData?.twitterLink) {
            setTwitterUserName(collectionData?.twitterLink)
        }
    }, [collectionData?.twitterLink])

    return (
        <>
            <Modal centered visible={visible} footer={null} onCancel={onCloseModal}>
                <Form form={form} onFieldsChange={removeError} initialValues={initialFormValues}>
                    <div>
                        <h3 className="header-title">{t('editProfile.yourSocialMedia')}</h3>
                    </div>
                    <div className="input-group-social">
                        <div>
                            <div className="form-item-social">
                                <Row justify="space-between">
                                    <p className="label">Twitter</p>
                                    <div>
                                        {isVerifyTwitter && twitterUserName && twitterLink === twitterUserName ? (
                                            <div className="twitter-verified-collection">{t('editProfile.verified')}</div>
                                        ) : (
                                            <>
                                                {!isShowLinkTwitter && (
                                                    <Button
                                                        className="btn-verifi-twitter"
                                                        onClick={handleConfirmVerified}
                                                        type="link"
                                                    >
                                                        {t('editProfile.check')}
                                                    </Button>
                                                )}
                                                <Button
                                                    className={`${isShowLinkTwitter ? 'btn-link-default' : ''
                                                        } btn-verifi-twitter`}
                                                    onClick={handleRedirectVerifyTwitter}
                                                    type="link"
                                                >
                                                    {isShowLinkTwitter ? t('editProfile.link') : t('editProfile.tweetAgain')}
                                                </Button>
                                            </>
                                        )}
                                    </div>
                                </Row>
                                <Form.Item
                                    name="twitterName"
                                    rules={[
                                        { max: 1000, message: getText('urlLongError') },
                                        { validator: validatorUserSocial }
                                    ]}
                                >
                                    <InputCustom
                                        placeholder={t("editProfile.socialPlaceholder", {socialNetwork: "Twitter"})}
                                        onChange={e => setTwitterUserName(e.target.value)}
                                    />
                                </Form.Item>
                                {twitterLink && isVerifyTwitter && (
                                    <i
                                        className="ri-close-fill remove-twitter-icon"
                                        onClick={handleRemoveTwitterAccount}
                                    ></i>
                                )}
                            </div>
                            <div className="form-item-social">
                                <p className="label">Instagram</p>
                                <Form.Item
                                    name="instagramName"
                                    rules={[
                                        { max: 1000, message: getText('urlLongError') },
                                        { validator: validatorUserSocial }
                                    ]}
                                >
                                    <InputCustom placeholder={t("editProfile.socialPlaceholder", {socialNetwork: "Instagram"})} />
                                </Form.Item>
                            </div>
                            <div className="form-item-social">
                                <p className="label">Medium</p>
                                <Form.Item
                                    name="mediumName"
                                    rules={[
                                        { max: 1000, message: getText('urlLongError') },
                                        { validator: validatorUserSocial }
                                    ]}
                                >
                                    <InputCustom placeholder={t("editProfile.socialPlaceholder", {socialNetwork: "Medium"})} />
                                </Form.Item>
                            </div>
                        </div>
                        <div>
                            <p className="label">{t('editProfile.personalSite')}</p>
                            <Form.Item
                                name="personalSiteName"
                                rules={[
                                    { max: 1000, message: getText('urlLongError') },
                                    {
                                        pattern: new RegExp(
                                            '^(?:(?:http(?:s)?|ftp)://)(?:\\S+(?::(?:\\S)*)?@)?(?:(?:[a-z0-9\u00a1-\uffff](?:-)*)*(?:[a-z0-9\u00a1-\uffff])+)(?:\\.(?:[a-z0-9\u00a1-\uffff](?:-)*)*(?:[a-z0-9\u00a1-\uffff])+)*(?:\\.(?:[a-z0-9\u00a1-\uffff]){2,})(?::(?:\\d){2,5})?(?:/(?:\\S)*)?$'
                                        ),
                                        message: getText('invalidUrl')
                                    }
                                ]}
                            >
                                <InputCustom placeholder={getText('personalSitePlaceholder')} />
                            </Form.Item>
                        </div>
                    </div>
                    <div className="update-social_btnGroups">
                        <ButtonCustom
                            color="blue"
                            fullWidth={true}
                            disabled={isFormError}
                            onClick={handleUpdateSocialLinks}
                            className="update-social_btn mr"
                        >
                            {t('common.update')}
                        </ButtonCustom>
                        <ButtonCustom color="white" onClick={onCloseModal} className="update-social_btn mr">
                            {t('common.cancel')}
                        </ButtonCustom>
                    </div>
                </Form>
            </Modal>
        </>
    )
}

export default memo(EditSocialLinkModal)
