import { useHistory } from 'react-router-dom'
import React, { useContext } from 'react'
import { getCustomDiffTime } from 'utils/convertDate'
import { shortenNameNoti } from 'utils/func'
import { ThemeContext } from '../../../../../ThemeProvider'
import '../style.scss'
import NotificationNftImage from './NotificationNftImage'
import { useTranslation } from 'react-i18next'

const NotificationListing = ({ noty, onClickNoti }) => {
    const { t } = useTranslation();
    const { theme } = useContext(ThemeContext)
    const nftHistory = noty?.nftHistory
    const history = useHistory()
    const routerChange = () => {
        onClickNoti(noty?.id, () => {
            if (nftHistory?.nft?.collection?.id && nftHistory?.nft?.tokenId) {
                history.push(`/nft/${nftHistory?.nft?.collection?.id}:${nftHistory?.nft?.tokenId}`)
            }
        })
    }
    return (
        <div onClick={routerChange} className="cursor-pointer">
            <div className="d-flex">
                <div className="item-image my-auto">
                    <NotificationNftImage noty={nftHistory} />
                </div>
                <div className={`my-auto ${theme === 'dark-theme' ? 'dark-my-auto' : ''}`}>
                    <div>
                        <span className="text-highlight">You</span>
                        <span className="action">
                            <span>{t('header.menu.listedANft')}:</span>
                            <span className="text-highlight"> {shortenNameNoti(nftHistory?.nft?.title)}</span>
                        </span>
                    </div>
                    <div className="time-noti">{getCustomDiffTime(noty?.createdAt)}</div>
                </div>
            </div>
        </div>
    )
}

export default NotificationListing
