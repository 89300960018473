// Format currency with seprator
export const currencyFormat = (value, currency = '$') => {
    //add seprator to value
    if (!value) return '$0.00'
    return `${currency}${abbreviateNumber(parseFloat(value).toFixed(2).toString(), 1)}`
}

export const abbreviateNumber = (value, decimals = 0) => {
    if (!value) return 0
    if (value >= 1000000000) return `${(value / 1000000000).toFixed(decimals)}B`
    if (value >= 1000000) return `${(value / 1000000).toFixed(decimals)}M`
    if (value >= 1000) return `${(value / 1000).toFixed(decimals)}K`
    return `${parseFloat(value).toFixed(decimals)}`
}

export const addCommaToNumber = value => {
    if (!value) return 0
    return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
}
