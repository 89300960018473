import { SERVER_API_ENDPOINT } from 'constants/envs'
import Request from '../request'

const auctionService = {
    makeOffer: async ({ quantity, nftSaleId, metadata, nftId, toAddress, currencyToken, price }) => {
        try {
            const response = await Request.post(`${SERVER_API_ENDPOINT}/offer-sale-nft`, {
                sellHash: metadata.hash,
                quantity,
                nftSaleId,
                metadata,
                nftId,
                toAddress,
                currencyToken,
                price
            })
            return [response, null]
        } catch (error) {
            console.error('makeOffer', error.response)
            return [null, error]
        }
    }
}

export default auctionService
