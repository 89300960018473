import React, {
  useEffect,
  useState,
  useCallback,
  useRef,
  useMemo,
  useContext,
} from "react";
import { Divider } from "antd";
// import ReactLoading from "react-loading";
import { useTranslation } from "react-i18next";
// import { useIntersection } from "react-use";

// import * as message from "utils/custom-message";
// import { CardItem } from "components/modules";
// import noItem from "assets/images/notfound.svg";
import { NETWORK_OPTIONS } from "constants/index";
// import nftService from "service/nftService";
// import styles from "./style.module.scss";
// import CustomSwitch from "components/common/switch";
import "./styles.scss";
import {
  ButtonCustom,
  // CustomAvatar,
  InputCustom,
  // NoData,
  SelectCustom,
} from "components/common";
// import { useOnClickOutside, useResizeWindow } from "utils/hook";
import { useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
// import collectionService from "service/collectionService";
// import { convertImageAvatar } from "utils/image";
import PolygonIcon from "assets/icons/polygon-icon.svg";
import FourSquaresIcon from "assets/icons/4squares-icon.svg";
// import LightingIcon from "assets/icons/lighting-icon.svg";
// import FilterIcon from "assets/icons/filter-icon.svg";
// import BlockchainIcon from "assets/icons/blockchain-icon.png";
// import PriceRangeIcon from "assets/icons/dollar-price.png";
import SearchIcon from "assets/icons/search-icon-gray.svg";
// import PolygonIcon from "assets/icons/blockchain-filter-icon.svg";
// import SaleTypeIcon from 'assets/icons/saleType-filter-icon.svg'
// import CategoriesIcon from 'assets/icons/categories-filter-icon.svg'
// import PriceIcon from 'assets/icons/price-filter-icon.svg'
// import SortIcon from 'assets/icons/sort-filter-icon.svg'
// import CollectionIcon from 'assets/icons/collection-filter-icon.svg'
import { ThemeContext } from "../../../ThemeProvider";
import HotCollections from "../hot-collections";
// const LIMIT = 8;

// const optionFilter = [
//   { label: "Verified Only", key: "verification" },
//   { label: "Show NSFW", key: "nsfw" },
//   { label: "Show lazy minted", key: "lazyMinted" },
// ];

const SALES_TYPE = [
  {
    value: '',
    label: "All sale types",
  },
  {
    label: "Timed auction",
    value: "auction",
  },
  {
    label: "Fixed price",
    value: "fix_price",
  },
  {
    label: "Not for sale",
    value: "not_for_sale",
  },
  {
    label: "Open for offer",
    value: "open_for_offer",
  },
];

// const NoItemFound = () => {
//     const { t } = useTranslation()
//     return (
//         <div className="no-item-container">
//             <img src={noItem} alt="" />
//             <p>{t('common.noItem')}</p>
//             <p>{t('common.noItemContent')}</p>
//         </div>
//     )
// }

// const NFTCollection = ({ loading, data, handeLoadMore, total, isTablet }) => {
//     const { t } = useTranslation()

//     const isLoadMore = useMemo(() => {
//         return data?.length < total
//     }, [data?.length, total])
//     const system = useSelector(state => state?.system)
//     const anchorEl = useRef(null)

//     if (data && data?.length === 0) {
//         return <NoData data={data} />
//     }

//     return (
//         <div className={styles.exploreListsRow}>
//             <Row className={styles.exploreRow} gutter={[{ xs: 6, sm: 6, md: 20, lg: 20, xl: 20 }, 20]}>
//                 {data?.map((d, index) => (
//                     <Col xs={{ span: 24 }} md={{ span: 12 }} lg={{ span: 8 }} xl={{ span: 6 }} key={index}>
//                         <CardItem data={d} />
//                     </Col>
//                 ))}
//                 <div ref={anchorEl}></div>
//             </Row>

//             {(isLoadMore && !loading) || isTablet ? (
//                 <ButtonCustom
//                     className="homepage-explore-btn-load br-12"
//                     color="blue"
//                     onClick={async () => {
//                         await handeLoadMore()
//                     }}
//                 >
//                     {t('common.loadingMore')}
//                 </ButtonCustom>
//             ) : null}
//             {loading && !system?.loading && (
//                 <div className={styles.boxLoading}>
//                     <ReactLoading className={styles.exploreListsBtn} type="spinningBubbles" color="#002979" />
//                 </div>
//             )}
//         </div>
//     )
// }

const Explore = () => {
  const { t, i18n } = useTranslation();
  const { theme } = useContext(ThemeContext);
  const params = useParams();
  const history = useHistory();

  const collectionDropdown = useRef(null);
  const chainDropdown = useRef(null);

  const categories = useSelector((state) => state.categories?.data || []);
  const [categoryId, setCategoryId] = useState(null);
  const [listSelectedChain, setListSelectedChain] = useState([]);
  const [networkType, setNetworkType] = useState();
  const [collectionSearchInput, setCollectionSearchInput] = useState("");
  const [collectionSearch, setCollectionSearch] = useState("");
  const [saleTypes, setSaleTypes] = useState('')
  // const [loading, setLoading] = useState(false);
  // const [isTablet] = useResizeWindow(SCREEN_SIZE.tablet);
  // const [isShowIconSort] = useResizeWindow(578);
  // const [limit] = useState(8);
  // const [page, setPage] = useState(1);
  // const [sortBy, setSortBy] = useState(SORT_BY[0].value);
  // const [nfts, setNfts] = useState([]);
  // const [total, setTotal] = useState(0);
  // const [isPriceRangeDropdown, setIsPriceRangeDropdown] = useState(false);
  // const [isCollectionDropdown, setIsCollectionDropdown] = useState(false);
  // const [isChainDropdown, setIsChainDropdown] = useState(false);
  // const pricerangeDropdown = useRef(null);
  // const propertyDropdown = useRef(null);
  // const buttonPriceRangeRef = useRef(null);
  // const buttonCollectionRef = useRef(null);
  // const buttonChainRef = useRef(null);
  // const buttonPropertyRef = useRef(null);
  // const [priceFrom, setPriceFrom] = useState(null);
  // const [priceTo, setPriceTo] = useState(null);
  // const [listCollection, setListCollection] = useState([]);
  // const [searchedCollection, setSearchedCollection] = useState([]);
  // const [listSelectedCollection, setListSelectedCollection] = useState([]);
  // const [collectionParam, setCollectionParam] = useState("");
  // const [collections, setCollections] = useState(null);
  // const [filterParams, setFilterParams] = useState({
  //   saleType: undefined,
  // });

  // const [filterByOption, setFilterByOption] = useState({
  //   verification: true,
  //   nsfw: false,
  //   lazyMinted: false,
  // });
  // const [selectedSort, setSelectedSort] = useState(SORT_BY[0]);

  // const handlePriceRangeClickOutsideDropdown = () => {
  //   setIsPriceRangeDropdown(false);
  // };

  // const handleCollectionClickOutsideDropdown = () => {
  //   setIsCollectionDropdown(false);
  // };

  // const handleChainClickOutsideDropdown = () => {
  //   setIsChainDropdown(false);
  // };

  // const handlePropertyClickOutsideDropdown = () => {};

  // useOnClickOutside(
  //   pricerangeDropdown,
  //   handlePriceRangeClickOutsideDropdown,
  //   buttonPriceRangeRef
  // );
  // useOnClickOutside(
  //   collectionDropdown,
  //   handleCollectionClickOutsideDropdown,
  //   buttonCollectionRef
  // );
  // useOnClickOutside(
  //   chainDropdown,
  //   handleChainClickOutsideDropdown,
  //   buttonChainRef
  // );
  // useOnClickOutside(
  //   propertyDropdown,
  //   handlePropertyClickOutsideDropdown,
  //   buttonPropertyRef
  // );

  const categoriesConverted = useMemo(() => {
    return [
      { id: -1, value: -1, label: t("category.allCategories"), code: "all" },
      ...[...categories].map((category) => {
        return {
          ...category,
          icon: category.icon,
          label: t(`category.${category.name}`),
          value: category.id,
        };
      }),
    ];
  }, [categories, i18n.language]);

  // const handeLoadMore = useCallback(() => {
  //   if (loading) {
  //     return;
  //   }
  //   const next = page * LIMIT;
  //   next < total && setPage(page + 1);
  // }, [loading, page, total]);

  // const fetchNfts = useCallback(
  //   async (metadata) => {
  //     setLoading(true);
  //     const currentCategoryId = metadata?.categoryId || categoryId;
  //     const saleType =
  //       metadata?.saleType !== undefined
  //         ? metadata?.saleType
  //         : filterParams.saleType;
  //     const isVerified = metadata?.isReload
  //       ? metadata?.verification
  //       : filterByOption?.verification;
  //     const nsfw =
  //       metadata?.nsfw !== null ? metadata?.nsfw : filterByOption?.nsfw;
  //     const lazyMinted =
  //       metadata?.lazyMinted !== null
  //         ? metadata?.lazyMinted
  //         : filterByOption?.lazyMinted;
  //     const [data, err] = await nftService.getListNft({
  //       limit,
  //       page: metadata?.isReload ? 1 : page,
  //       categoryId: currentCategoryId === -1 ? "" : currentCategoryId,
  //       sort: (metadata?.sortBy !== 1 && metadata?.sortBy) || sortBy,
  //       saleTypes: saleType,
  //       startPrice: metadata?.priceFrom || priceFrom,
  //       endPrice: metadata?.priceTo || priceTo,
  //       collectionIds:
  //         metadata?.collections === -1
  //           ? null
  //           : metadata?.collections || collections,
  //       networkTypes:
  //         metadata?.networkType === -1
  //           ? null
  //           : metadata?.networkType || networkType,
  //       isVerify: isVerified ? 1 : 0,
  //       isMinted: lazyMinted ? 1 : 0,
  //       nsfw: nsfw ? 1 : 0,
  //     });
  //     setLoading(false);
  //     if (err) {
  //       message.error(t("error.message.somethingWentWrong"));
  //       return;
  //     }
  //     setTotal(data?.meta?.totalItem);
  //     if (metadata?.isReload || page === 1) {
  //       setNfts([...data?.items]);
  //       return;
  //     }
  //     setNfts([...nfts, ...data?.items]);
  //   },
  //   [
  //     categoryId,
  //     limit,
  //     page,
  //     sortBy,
  //     networkType,
  //     nfts,
  //     filterParams.saleType,
  //     collections,
  //     filterByOption,
  //     priceFrom,
  //     priceTo,
  //   ]
  // );

  // const handleFilterBy = useCallback(
  //   (key) => {
  //     setFilterByOption({ ...filterByOption, [key]: !filterByOption[key] });
  //     setPage(1);
  //     // fetchNfts({
  //     //     isReload: true,
  //     //     ...filterByOption,
  //     //     [key]: !filterByOption[key]
  //     // })
  //   },
  //   [filterByOption, fetchNfts]
  // );

  // const fetchCollection = useCallback(async () => {
  //   const [res, err] = await collectionService.getAllMiniCollection({
  //     limit: 0,
  //     page: 1,
  //     userId: "",
  //   });
  //   if (res) {
  //     setListCollection(res.items);
  //     setSearchedCollection(res.items);
  //   }
  //   if (err) {
  //   }
  // }, []);

  // useEffect(() => {
  //   fetchCollection();
  // }, [fetchCollection]);

  // const handleClickSortFilter = useCallback(
  //   (sort) => {
  //     setSelectedSort(sort);
  //     setSortBy(sort.value);
  //     // fetchNfts({ sortBy: sort.value, isReload: true, ...filterByOption })
  //     setPage(1);
  //   },
  //   [fetchNfts, filterByOption]
  // );

  // const dropDownSortBy = useCallback(
  //   () => (
  //     <div
  //       className={`dropdown-collection dropdown-explorer dropdown-sortby ${
  //         theme === "dark-theme" ? "dropdown-explore-dark-antd" : ""
  //       }`}
  //     >
  //       <div className="collection-holder">
  //         <div className="filter-title">
  //           {t("common.sortAndFilter.sort.title")}
  //         </div>
  //         {SORT_BY.map((sort, index) => {
  //           return (
  //             <div
  //               className="collection-item-holder"
  //               onClick={() => handleClickSortFilter(sort)}
  //               key={index}
  //             >
  //               <div className="collection-name">
  //                 <div className="collection-name-text-holder">
  //                   <span className="collection-name-text">
  //                     {t(`common.sortAndFilter.sort.${sort.value}`)}
  //                   </span>
  //                 </div>
  //               </div>
  //               <div>
  //                 {selectedSort.value === sort.value && (
  //                   <i className="ri-check-line"></i>
  //                 )}
  //               </div>
  //             </div>
  //           );
  //         })}
  //         <div className="filter-title">
  //           {t("common.sortAndFilter.options.title")}
  //         </div>
  //         {optionFilter?.map((item, index) => (
  //           <div key={index} className="verification-holder">
  //             {t(`common.sortAndFilter.options.${item.key}`)}
  //             <CustomSwitch
  //               onChange={() => handleFilterBy(item.key)}
  //               value={filterByOption[item.key]}
  //               disableLabel={true}
  //               className="switch-filter"
  //             />
  //           </div>
  //         ))}
  //       </div>
  //     </div>
  //   ),

  //   [
  //     filterByOption,
  //     handleClickSortFilter,
  //     handleFilterBy,
  //     selectedSort.value,
  //     theme,
  //   ]
  // );
  //bug rerender
  useEffect(() => {
    // setPage(1);
    setCategoryId(
      categoriesConverted.find((c) => c.code === params?.category)?.id
    );
  }, [params?.category]);

  const handleChangeCategory = (value) => {
    setCategoryId(categoriesConverted.find((c) => c.id === value)?.id);
  };

  // const handleClearPriceClick = () => {
  //   setPriceFrom(null);
  //   setPriceTo(null);
  // };

  // const handlePriceApplyClick = () => {
  //   // fetchNfts({ priceFrom, priceTo, isReload: true, ...filterByOption })
  //   setPage(1);
  //   setIsPriceRangeDropdown(false);
  // };

  // const handleClickCollectionFilter = (collection) => {
  //   //add collection to filter
  //   if (listSelectedCollection.includes(collection)) {
  //     setListSelectedCollection(
  //       listSelectedCollection.filter((c) => c !== collection)
  //     );
  //   } else {
  //     setListSelectedCollection([...listSelectedCollection, collection]);
  //   }
  // };

  const handleClickChainFilter = (network) => {
    if (listSelectedChain.includes(network)) {
      setListSelectedChain(listSelectedChain.filter((n) => n !== network));
    } else {
      setListSelectedChain([...listSelectedChain, network]);
    }
  };

  // const handleApplyCollectionFilter = () => {
  //   setCollections(listSelectedCollection.map((c) => c.id).join(","));
  //   // fetchNfts({
  //   //     collections: listSelectedCollection.map(c => c.id).join(',') || -1,
  //   //     isReload: true,
  //   //     ...filterByOption
  //   // })
  //   setPage(1);
  //   setIsCollectionDropdown(false);
  // };

  const handleApplyCollectionFilter = () => {
    setCollectionSearch(collectionSearchInput);
  };

  const handleApplyChainFilter = () => {
    setNetworkType(listSelectedChain.map((e) => e.value).join(","));
    // fetchNfts({
    //     networkType: listSelectedChain.map(c => c.value).join(',') || -1,
    //     isReload: true,
    //     ...filterByOption
    // })
    // setPage(1);
    // setIsChainDropdown(false);
  };

  // const handleClearCollectionFilter = () => {
  //   setListSelectedCollection([]);
  //   setNetworkType([]);
  //   // fetchNfts({
  //   //     collections: -1,
  //   //     isReload: true,
  //   //     ...filterByOption
  //   // })
  //   setPage(1);
  //   setCollectionParam("");
  //   setSearchedCollection(listCollection);
  // };

  const handleClearCollectionFilter = () => {
    setCollectionSearchInput("");
    setCollectionSearch("");
  };

  const handleClearChainFilter = () => {
    setListSelectedChain([]);
    // setPage(1);
    // fetchNfts({
    //     networkType: '',
    //     isReload: true,
    //     ...filterByOption
    // })
  };

  // const handleSearchCollection = (e) => {
  //   const value = e.target.value;
  //   setCollectionParam(value.toLowerCase());
  //   if (value.length > 0) {
  //     setSearchedCollection(
  //       listCollection.filter((c) =>
  //         c.name.toLowerCase().includes(value.toLowerCase())
  //       )
  //     );
  //   } else {
  //     setSearchedCollection(listCollection);
  //   }
  // };

  const handleSearchCollection = (e) => {
    const value = e.target.value;
    setCollectionSearchInput(value.toLowerCase());
  };

  // const onChangeValueInputPriceFrom = (
  //   value,
  //   maxValue = 1000000000,
  //   decimal = 6
  // ) => {
  //   if (value === ".") return setPriceFrom("");
  //   let number = value
  //     .toString()
  //     .replace(/[^0-9.]/g, "")
  //     .replace(/(\..*?)\..*/g, "$1");
  //   if (Number(number) >= maxValue) {
  //     number = number.slice(0, -1);
  //   }
  //   if (number.includes(".")) {
  //     const numString = number.toString().split(".");
  //     if (numString[1].length > decimal) {
  //       return setPriceFrom(number.substring(0, number.length - 1));
  //     }
  //   }
  //   setPriceFrom(number);
  // };
  // const onChangeValueInputPriceTo = (
  //   value,
  //   maxValue = 1000000000,
  //   decimal = 6
  // ) => {
  //   if (value === ".") return setPriceTo("");
  //   let number = value
  //     .toString()
  //     .replace(/[^0-9.]/g, "")
  //     .replace(/(\..*?)\..*/g, "$1");
  //   if (Number(number) >= maxValue) {
  //     number = number.slice(0, -1);
  //   }
  //   if (number.includes(".")) {
  //     const numString = number.toString().split(".");
  //     if (numString[1].length > decimal) {
  //       return setPriceTo(number.substring(0, number.length - 1));
  //     }
  //   }
  //   setPriceTo(number);
  // };
  // const dropdownPriceRange = useCallback(() => {
  //   return (
  //     <div
  //       className={`dropdown-collection dropdown-explorer dropdown-explore-light-range ${
  //         theme === "dark-theme" ? "dropdown-explore-dark-range" : ""
  //       }`}
  //       ref={pricerangeDropdown}
  //     >
  //       <Menu>
  //         <div className="currency-select">
  //           <p className="pricerange-header">
  //             {t("explore.priceRangePlaceholder")}
  //           </p>
  //         </div>
  //         <div className="price-input-holder">
  //           <InputCustom
  //             className="price-input"
  //             placeholder={t("common.from")}
  //             value={priceFrom}
  //             onChange={(e) => onChangeValueInputPriceFrom(e.target.value)}
  //           />
  //           <InputCustom
  //             className="price-input"
  //             placeholder={t("common.to")}
  //             value={priceTo}
  //             onChange={(e) => onChangeValueInputPriceTo(e.target.value)}
  //           />
  //         </div>
  //         <Divider />
  //         <div className="sale-type-filter-button">
  //           <ButtonCustom
  //             isCustomRadius
  //             className="filter-btn"
  //             size="small"
  //             onClick={handleClearPriceClick}
  //           >
  //             {t("common.clear")}
  //           </ButtonCustom>
  //           <ButtonCustom
  //             isCustomRadius
  //             className="filter-btn"
  //             size="small"
  //             color="blue"
  //             onClick={handlePriceApplyClick}
  //           >
  //             {t("common.apply")}
  //           </ButtonCustom>
  //         </div>
  //       </Menu>
  //     </div>
  //   );
  // }, [priceFrom, priceTo, theme]);

  const dropdownChain = useCallback(() => {
    return (
      <div
        className={`dropdown-collection dropdown-explorer ${
          theme === "dark-theme" ? "dropdown-explore-dark-antd" : ""
        }`}
        ref={chainDropdown}
      >
        <div className="collection-holder">
          {NETWORK_OPTIONS?.map((network, index) => {
            return (
              <div
                className="collection-item-holder"
                onClick={() => handleClickChainFilter(network)}
                key={index}
              >
                <div className="collection-name">
                  <img
                    src={network.icon}
                    alt="chain logo"
                    className="chain-logo"
                  />
                  <div className="collection-name-text-holder">
                    <span className="collection-name-text">
                      {network.label}
                    </span>
                  </div>
                </div>
                <div>
                  {listSelectedChain?.includes(network) && (
                    <i className="ri-check-line"></i>
                  )}
                </div>
              </div>
            );
          })}
        </div>
        <Divider />
        <div className="sale-type-filter-button">
          <ButtonCustom
            // isCustomRadius
            // className="filter-btn btn-clear"
            size="small"
            color="gray"
            onClick={handleClearChainFilter}
          >
            {t("common.clear")}
          </ButtonCustom>
          <ButtonCustom
            isCustomRadius
            className="filter-btn"
            size="small"
            color="blue"
            onClick={handleApplyChainFilter}
          >
            {t("common.apply")}
          </ButtonCustom>
        </div>
      </div>
    );
  }, [listSelectedChain, theme]);

  useEffect(() => {
    if (categoriesConverted.length) {
      setCategoryId(-1);
    }
  }, [categoriesConverted.length]);

  // const dropdownCollection = useCallback(() => {
  //   return (
  //     <div
  //       className={`dropdown-collection dropdown-explorer ${
  //         theme === "dark-theme" ? "dropdown-explore-dark-antd" : ""
  //       }`}
  //       ref={collectionDropdown}
  //     >
  //       <div className="collection-search-input-holder">
  //         <InputCustom
  //           value={collectionParam}
  //           placeholder={t("explore.searchCollection")}
  //           onChange={handleSearchCollection}
  //         />
  //       </div>
  //       <div className="collection-holder">
  //         {searchedCollection?.map((collection, index) => {
  //           return (
  //             <div
  //               className="collection-item-holder"
  //               onClick={() => handleClickCollectionFilter(collection)}
  //               key={index}
  //             >
  //               <div className="collection-name">
  //                 <CustomAvatar
  //                   verified={collection.isVerify}
  //                   src={convertImageAvatar(collection.bannerUrl)}
  //                 />
  //                 <div className="collection-name-text-holder">
  //                   <span className="collection-name-text">
  //                     {collection.title || collection.name}
  //                   </span>
  //                 </div>
  //               </div>
  //               <div>
  //                 {listSelectedCollection?.includes(collection) && (
  //                   <i className="ri-check-line"></i>
  //                 )}
  //               </div>
  //             </div>
  //           );
  //         })}
  //       </div>
  //       <Divider />
  //       <div className="sale-type-filter-button">
  //         <ButtonCustom
  //           isCustomRadius
  //           className="filter-btn"
  //           size="small"
  //           onClick={handleClearCollectionFilter}
  //         >
  //           {t("common.clear")}
  //         </ButtonCustom>
  //         <ButtonCustom
  //           className="filter-btn"
  //           isCustomRadius
  //           size="small"
  //           color="blue"
  //           onClick={handleApplyCollectionFilter}
  //         >
  //           {t("common.apply")}
  //         </ButtonCustom>
  //       </div>
  //     </div>
  //   );
  // }, [listSelectedCollection, searchedCollection, theme, collectionParam]);

  const dropdownCollection = useCallback(() => {
    return (
      <div
        className={`dropdown-collection dropdown-explorer  ${
          theme === "dark-theme" ? "dropdown-explore-dark-antd" : ""
        }`}
        ref={collectionDropdown}
      >
        <div className="collection-search-input-holder">
          <InputCustom
            prefix={<img src={SearchIcon} alt="search icon" />}
            value={collectionSearchInput}
            placeholder={t("common.search")}
            onChange={handleSearchCollection}
          />
        </div>
        <div className="sale-type-filter-button">
          <ButtonCustom
            // className="filter-btn"
            size="small"
            color="gray"
            onClick={handleClearCollectionFilter}
          >
            {t("common.clear")}
          </ButtonCustom>
          <ButtonCustom
            className="filter-btn"
            size="small"
            color="blue"
            onClick={handleApplyCollectionFilter}
          >
            {t("common.apply")}
          </ButtonCustom>
        </div>
      </div>
    );
  }, [collectionSearchInput]);

  const saleTypeOptions = useMemo(() => {
    return [
      ...SALES_TYPE.map((saleType) => {
        return {
          ...saleType,
          label: saleType.value
            ? t(`saleType.${saleType.value}`)
            : t("saleType.allSaleTypes"),
          value: saleType.value,
        };
      }),
    ];
  }, [t, i18n.language]);

  const onClickShowMore = () => history.push("/explore/all");

  return (
    <div className="home-explore">
      <div className="home-page-category__header homepage-explore__header">
        <h2>{t("homepage.explore.title")}</h2>
        <div className="sort-filter">
          <div className="homepage-explore-filter-by">
            <SelectCustom
              className="select left"
              categoryIcon={true}
              dropdownClassName="select__dropdown--chains"
              getPopupContainer={(triggerNode) => triggerNode.parentNode}
              dropdownRender={dropdownChain}
              placeholder={
                <span className="select-placeholder">
                  <img src={PolygonIcon} alt="polygon-icon" />
                  <span>{t("explore.blockchain")}</span>
                </span>
              }
            />
            <SelectCustom
              value={categoryId}
              onChange={handleChangeCategory}
              options={categoriesConverted}
              dropdownClassName="select__dropdown--categories"
              className="select left"
              showIconSvg={true}
              getPopupContainer={(triggerNode) => triggerNode.parentNode}
            />
            <SelectCustom
              className="select left"
              dropdownClassName="select__dropdown--collections"
              categoryIcon={true}
              getPopupContainer={(triggerNode) => triggerNode.parentNode}
              dropdownRender={dropdownCollection}
              placeholder={
                <span className="select-placeholder">
                  <img src={FourSquaresIcon} alt="4squares-icon" />
                  <span>{t("explore.collections")}</span>
                </span>
              }
            />
            <SelectCustom
              defaultValue={null}
              onChange={(value) => setSaleTypes(value)}
              options={saleTypeOptions}
              className="select left"
              categoryIcon={true}
              value={saleTypes}
              getPopupContainer={(triggerNode) => triggerNode.parentNode}
            />
            {/* <SelectCustom
              className="select left"
              dropdownClassName="select__dropdown--price-range"
              categoryIcon={true}
              getPopupContainer={(triggerNode) => triggerNode.parentNode}
              dropdownRender={dropdownPriceRange}
              placeholder={
                <span className="select-placeholder">
                  <img src={PriceRangeIcon} alt="light-icon" />
                  <span>{t("explore.priceRange")}</span>
                </span>
              }
            />
            <SelectCustom
              className="select left"
              dropdownClassName="select__dropdown--filter"
              categoryIcon={true}
              getPopupContainer={(triggerNode) => triggerNode.parentNode}
              dropdownRender={dropDownSortBy}
              placeholder={
                <span className="select-placeholder">
                  <img src={FilterIcon} alt="filter-icon" />
                  <span>{t("common.sortAndFilter.title")}</span>
                </span>
              }
            /> */}
          </div>
        </div>
      </div>
      <HotCollections
        isExploreType
        networkTypes={networkType}
        saleTypes={saleTypes}
        keyword={collectionSearch}
        amountItemDefault={3.4}
        categoryId={categoryId == -1 ? null : categoryId}
      />
      {/* <NFTCollection
                loading={loading}
                data={nfts}
                total={total}
                handeLoadMore={handeLoadMore}
                isTablet={isTablet}
            /> */}
      <ButtonCustom variant="outline" onClick={onClickShowMore}>
        {t("editProfile.showMore")}
      </ButtonCustom>
    </div>
  );
};

export default Explore;
