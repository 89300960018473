import React from 'react'
import { createContext, useState, useCallback } from 'react'

export const ThemeContext = createContext(null)

const ThemeProvider = ({ children }) => {
    const [theme, setTheme] = useState(localStorage.getItem('theme') ? localStorage.getItem('theme') : 'light-theme')

    const toggleTheme = useCallback(() => {
        setTheme(curr => (curr === 'light-theme' ? 'dark-theme' : 'light-theme'))
        localStorage.setItem('theme', localStorage.getItem('theme') === 'light-theme' ? 'dark-theme' : 'light-theme')
    }, [])
    return <ThemeContext.Provider value={{ theme, toggleTheme }}>{children}</ThemeContext.Provider>
}

export default ThemeProvider
