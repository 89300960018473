import React from 'react'
import PropTypes from 'prop-types'
import { ButtonCustom } from 'components/common'
import { SvgIcon } from 'components/modules'
import './style.scss'

const FilterCustom = props => {
    const { handleSetActiveFilter, type, listFilter, centered, alignCenter = true } = props

    return (
        <div className={`filter-types-container ${!alignCenter && 'container-align-left'}`}>
            <div className={`d-flex ${centered && 'justify-content-center'} filter-button-container`}>
                {listFilter?.map((item, index) => (
                    <ButtonCustom
                        key={index}
                        // color={item?.id === type ? 'blue' : 'white'}
                        iconLeft={
                            item?.name ? (
                                <SvgIcon name={item?.id === type ? item?.nameActive : item?.name} />
                            ) : (
                                <i className={item?.icon} />
                            )
                        }
                        onClick={() => (item?.id === type ? handleSetActiveFilter() : handleSetActiveFilter(item?.id))}
                        className="button-filter"
                        style={{ width: 'auto' }}
                        variant={item?.id === type ? 'outline' : 'outline-sub'}
                    >
                        <span>{item?.filterTitle}</span>
                    </ButtonCustom>
                ))}
            </div>
        </div>
    )
}

FilterCustom.propTypes = {
    name: PropTypes.string,
    filterTitle: PropTypes.any,
    handleSetActiveFilter: PropTypes.func,
    type: PropTypes.number
}

export default FilterCustom
