import { Button, Form, Input, notification } from 'antd'
import DefaultLayout from 'components/layout/default-layout'
import { MAIN_TITLE } from 'constants/index'
import React, { useContext } from 'react'
import { Helmet } from 'react-helmet'
import userService from 'service/userService'
import { ThemeContext } from 'ThemeProvider'
import Banner3 from '../../assets/images/about/banner3.png'
import Icon from '../../assets/images/about/icon.png'
import './styles.scss'
import * as message from 'utils/custom-message'
import { useTranslation } from 'react-i18next'
const HelpCenter = () => {
    const { t } = useTranslation();
    const { theme } = useContext(ThemeContext)
    const [form] = Form.useForm()

    const onFinish = async values => {
        try {
            await userService.postUserContact(values)
            message.success(t('success.message.receivedMessage'))
            form.resetFields()
        } catch (error) {
            message.error(t('error.message.somethingWentWrong'))
        }
    }
    return (
        <DefaultLayout>
            <Helmet>
                <meta charSet="utf-8" />
                <title>{`${MAIN_TITLE} - About`}</title>
                <meta name="description" content={`${MAIN_TITLE} - About`} />
            </Helmet>

            <div className="container">
                <div className="about-holder">
                    <div className="text-holder about-content-container">
                        <div className="video-holder">
                            <img src={Banner3} alt="logo-icon" />
                        </div>
                    </div>
                    <div className="box-support-us  about-content-container">
                        <div className="box-sp-form">
                            <Form form={form} name="nest-messages" onFinish={onFinish}>
                                <Form.Item name="name" rules={[{ required: true }]}>
                                    <Input placeholder={t('about.form.fullName')} />
                                </Form.Item>
                                <Form.Item
                                    name="email"
                                    rules={[
                                        { required: true, type: 'email', message: t('error.invalidEmail') }
                                    ]}
                                >
                                    <Input placeholder={t('about.form.enterEmailAddress')} />
                                </Form.Item>
                                <Form.Item name="subject" rules={[{ required: true }]}>
                                    <Input placeholder={t('about.form.enterSubject')} />
                                </Form.Item>
                                <Form.Item name="message" rules={[{ required: true }]}>
                                    <Input.TextArea placeholder={t('about.form.contactFor')} />
                                </Form.Item>
                                <Form.Item>
                                    <Button
                                        style={{
                                            background:'#EE811A !important'
                                        }}
                                        type="primary"
                                        htmlType="submit"
                                    >
                                        {t('common.send')}
                                    </Button>
                                </Form.Item>
                            </Form>
                        </div>
                        <div className="box-info-us">
                            <div className={`title-info-us ${theme === 'light-theme' && 'text-light'}`}>
                                {t('helpCenter.howCanHelp')}
                            </div>
                            <p className={`des-info-us ${theme === 'light-theme' && 'text-light'}`}>
                                {t('helpCenter.letUsGoTogether')}
                            </p>
                            <div className="info-us">
                                <div>
                                    <img src={Icon} alt="icon" />
                                </div>
                                <div className="box-info">
                                    <div className={`info1 ${theme === 'light-theme' && 'text-light'}`}>
                                        {t('helpCenter.princetownBranch')}
                                    </div>
                                    <div className={`info2 ${theme === 'light-theme' && 'text-light'}`}>
                                        info@smapocke.com
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </DefaultLayout>
    )
}

export default HelpCenter
