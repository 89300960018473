import React, {useMemo} from 'react';

import WalletconnectAppIcon from 'assets/icons/walletconnect.svg';
import {NETWORKS} from 'constants/envs';
import * as message from 'utils/custom-message'
import { useConnect } from 'wagmi';
import { loginWallet } from 'store/actions/user';
import { useDispatch } from 'react-redux';
import { getWalletConnect } from 'blockchain/utils';
import { setCloseLoadingConnectWallet } from 'store/login.slice';
import { useTranslation } from 'react-i18next';

const WalletConnectCard = (props) => {
  const {t} = useTranslation()
  const {connectors, networkType} = props;

  const {connect} = useConnect();
  const dispatch = useDispatch();

  const chainId = useMemo(() => NETWORKS[networkType].id, [networkType]);

  const connector = useMemo(() => {
    return getWalletConnect(connectors);
  }, [connectors]);

  const onClickConnect = async () => {
    if (!connector) {
      return;
    }

    connect({ connector, chainId }, {
      onSuccess: async ({accounts, chainId}) => {
        dispatch(setCloseLoadingConnectWallet());
        dispatch(loginWallet({account: accounts[0].toLowerCase(), chainId}));
      },
      onError: (error) => {
        message.error(error.message.toString().split("\n")[0]);
      }
    });
  };

  return (
    <div className="connect-option wallet-connect"
      onClick={onClickConnect}>
      <img src={WalletconnectAppIcon}
        alt="wallet-app-icon"
        name="wallet-app-icon"
        className="icon"/>

      <span className="title-wallet">Wallet Connect</span>
      <span className="description-wallet">
        {t('connectWallet.walletConnect.desc')}
      </span>
    </div>
  );
};

export default WalletConnectCard;
