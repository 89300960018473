import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'

import { ButtonCustom } from 'components/common'
import SaleModal from './SaleModal'
import SaleStepModal from './SaleStepModal'
import BidStepModal from './BidStepModal'
import { isUserAuthenticated } from 'utils/auth'

const NotForSale = ({ signWallet, owner }) => {
    const { t } = useTranslation()
    const [isOpenSaleModal, setIsOpenSaleModal] = useState(false)
    const [isOpenSaleStepModal, setIsOpenSaleStepModal] = useState(false)
    const [isOpenBidStepModal, setIsOpenBidStepModal] = useState(false)
    const [saleData, setSaleData] = useState()
    const [offerPidData, setOfferPidData] = useState()

    const onClickPutOnSale = async () => {
        if (!isUserAuthenticated()) {
            const res = await signWallet()
            if (!res) return
        }
        setIsOpenSaleModal(true)
    }
    const onCloseSaleModal = () => {
        setIsOpenSaleModal(false)
    }
    const onOpenSaleStepModal = data => {
        setIsOpenSaleModal(false)
        setSaleData(data)
        setIsOpenSaleStepModal(true)
    }

    const onOpenBidStepModal = data => {
        setIsOpenSaleModal(false)
        setOfferPidData(data)
        setIsOpenBidStepModal(true)
    }

    return (
        <div className="bid">
            <p className="sale-text">This item can be put on sale, either with a fixed price or an open offer.</p>
            <ButtonCustom fullWidth size="lg" color="blue" className="action-btn br-12" onClick={onClickPutOnSale}>
                {t('nftDetail.listForSale')}
            </ButtonCustom>

            {isOpenSaleModal && (
                <SaleModal
                    owner={owner}
                    onCloseModal={onCloseSaleModal}
                    onOpenSaleStepModal={onOpenSaleStepModal}
                    onOpenBidStepModal={onOpenBidStepModal}
                />
            )}

            {isOpenSaleStepModal && <SaleStepModal onClose={() => setIsOpenSaleStepModal(false)} saleData={saleData} />}

            {isOpenBidStepModal && (
                <BidStepModal onClose={() => setIsOpenBidStepModal(false)} saleData={offerPidData} />
            )}
        </div>
    )
}

export default NotForSale
