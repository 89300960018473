import React from 'react'
import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import DefaultLayout from 'components/layout/default-layout'
import { ButtonCustom } from 'components/common'
import './style.scss'

const NotFound = () => {
    const { t } = useTranslation()
    const history = useHistory()
    return (
        <DefaultLayout>
            <div className="not-found-container">
                <h2 className="not-found-title">{t('pageNotFound')}</h2>
                <p className="not-found-description">{t('pageNotFound_description')}</p>
                <ButtonCustom onClick={() => history.goBack()} color="blue">
                    Go Back
                </ButtonCustom>
            </div>
        </DefaultLayout>
    )
}

export default NotFound
