import React from 'react'
import { message } from 'antd'
import SuccessIcon from 'assets/icons/success-message-icon.svg'
import ErrorIcon from 'assets/icons/error-message-icon.svg'
const success = content => {
    message.success({
        content: content,
        icon: <img src={SuccessIcon} className="custom-message-icon" alt="SuccessIcon" />,
        className: 'custom-message--success'
    })
}

let errorMessages = {}

const error = content => {
    if (!content) return
    if (!errorMessages[content]) {
        errorMessages[content] = content

        message
            .error({
                content: content,
                icon: <img src={ErrorIcon} alt="error-message-icon" className="custom-message-icon" />,
                className: 'custom-message--error',
                duration: 2
            })
            .then(() => {
                delete errorMessages[content]
            })
    }
}

const info = content => {
    message.info({ content: content, className: 'custom-message--info' })
}

const loading = content => {
    message.loading({ content: content })
}

export { success, error, info, loading }
