import { Tooltip } from 'antd'
import { Link, useHistory } from 'react-router-dom'

import { getCustomDiffTime } from 'utils/convertDate'
import { shortenAddress, shortenNameNoti } from 'utils/func'
import { convertImageAvatar } from 'utils/image'
import CustomVerifyIcon from 'components/common/custom-verify-icon'
import React, { useContext } from 'react'
import { ThemeContext } from '../../../../../ThemeProvider'
import '../style.scss'
import { useTranslation } from 'react-i18next'

const NotificationFollow = ({ noty, onClickNoti }) => {
    const { t } = useTranslation()
    const { theme } = useContext(ThemeContext)
    const history = useHistory()
    const routerChange = () => {
        onClickNoti(noty?.id, () => {
            if (noty?.follow?.follower?.shortUrl || noty?.follow?.follower?.walletAddress) {
                history.push(`/user/${noty?.follow?.follower?.shortUrl || noty?.follow?.follower?.walletAddress}`)
            }
        })
    }
    return (
        <div onClick={routerChange} className="cursor-pointer">
            <div className="d-flex">
                <div className="item-image my-auto">
                    <img src={convertImageAvatar(noty?.follow?.follower?.avatarImg)} alt="item" />
                    {noty?.follow?.follower?.isVerify ? <CustomVerifyIcon className="icon-checked" /> : null}
                </div>
                <div className={`my-auto ${theme === 'dark-theme' ? 'dark-my-auto' : ''}`}>
                    <div>
                        <Tooltip
                            title={noty?.follow?.follower?.username || noty?.follow?.follower?.walletAddress}
                            placement="topLeft"
                        >
                            <span className="text-highlight">
                                {noty?.follow?.follower?.username
                                    ? shortenNameNoti(noty?.follow?.follower?.username)
                                    : shortenAddress(noty?.follow?.follower?.walletAddress, 4)}{' '}
                            </span>
                        </Tooltip>
                        <span className="action">{t('header.menu.followedYou')}</span>
                    </div>
                    <div className="time-noti">{getCustomDiffTime(noty?.createdAt)}</div>
                </div>
            </div>
        </div>
    )
}

export default NotificationFollow
