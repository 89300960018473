import React, { useState, useEffect, useCallback, useContext } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { Tooltip, Row, message } from 'antd'
import copy from 'copy-to-clipboard'

import { shortenAddress } from 'utils/func'
import shrinkAddress from 'utils/shrinkAddress'
import { useResizeWindow } from 'utils/hook'
import { COLLECTIBLE_CODE, COLLECTIBLE_TYPE, NFT_TYPE, SCREEN_SIZE } from 'constants/index'
import { NETWORKS } from 'constants/envs'
import nftService from 'service/nftService'
import './style.scss'
import { ThemeContext } from '../../../../../ThemeProvider'
import { setGlobalLoading, setCloseGlobalLoading } from 'store/system.slice'

const Attributes = () => {
    const { theme } = useContext(ThemeContext)
    const { t } = useTranslation()
    const { owners, tokenId, collection } = useSelector(state => state.collectible?.data)
    const [copyText, setCopyText] = useState(tokenId)
    const [isLarge] = useResizeWindow(SCREEN_SIZE.xl)
    const [nftProperties, setNftProperties] = useState([])
    const dispatch = useDispatch()

    const getUserNftProperties = useCallback(
        async data => {
            if (data?.collectionId && data?.tokenId) {
                dispatch(setGlobalLoading())
                const [propertiesRes] = await nftService.getNftProperties(data)
                dispatch(setCloseGlobalLoading())
                if (propertiesRes) {
                    return setNftProperties(propertiesRes)
                }
                message.error(t('error.message.somethingWentWrong'))
            }
        },
        [dispatch]
    )
    useEffect(() => {
        getUserNftProperties({ tokenId, collectionId: collection?.id })
    }, [getUserNftProperties, tokenId, collection])

    const copyToClipboard = tokenId => {
        setCopyText('Copied!')
        copy(tokenId || '')
        setTimeout(() => {
            setCopyText(tokenId)
        }, 1500)
    }

    if (!owners) return null

    return (
        <>
            {nftProperties?.length > 0 && (
            <div className={`details-tab__container ${theme === 'dark-theme' ? 'dark-details-tab__container' : ''}`}>
                <span>{t('nftDetail.properties')}</span>

                <div className="details-tab__properties">
                <Row>
                    {nftProperties.map((item, index) => (
                    <div className="details-tab__property" key={index}>
                        <p>{item.name}</p>
                        <p style={{ color: theme === 'dark-theme' ? 'white' : 'inherit' }}>{item.value}</p>
                    </div>
                    ))}
                </Row>
                </div>
            </div>
            )}
        </>
    )
}

export default Attributes
