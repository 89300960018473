import React, { useState, useContext } from 'react'
import { Dropdown, Drawer } from 'antd'
import { Link, useRouteMatch } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { useAccount, useChainId } from 'wagmi'

import { ThemeContext } from '../../../../../ThemeProvider'
import { ButtonCustom } from 'components/common'
import { shortenAddress, shortenName } from 'utils/func'
import IconAvatarDefault from 'assets/icons/avatar-default.png'
import { useResizeWindow } from 'utils/hook'
import { DropdownMenu } from '.'
import { AWS_CLOUDFRONT_ENDPOINT } from 'constants/envs'
import { getAmountToken } from 'utils/index'
import { updateBalance } from 'store/user.slice'
import { useHistory } from 'react-router-dom/cjs/react-router-dom'

const RenderHeaderButton = ({ setIsShowModalWETH, setIsVisibleUniswapModal, isShowConnect = false }) => {
    const { t } = useTranslation()
    const dispatch = useDispatch()
    const router = useRouteMatch()
    const { theme } = useContext(ThemeContext)
    const { profile } = useSelector(state => state.user)
    const [displayDrawerAccount, setDisplayDrawerAccount] = useState(false)
    const [visibleMenuUser, setVisibleMenuUser] = useState(false)
    const [currenciesAmount, setCurrenciesAmount] = useState({})
    const { address } = useAccount();
    const chainId = useChainId();
    const history = useHistory();

    const [isMobileHeader] = useResizeWindow(768)
    const currentAvatar = profile?.avatarImg || ''
    const userName = profile?.username
        ? shortenName(profile?.username, 10)
        : shortenAddress(profile?.walletAddress, 5, 3)

    const handleVisibleChange = async flag => {
        setVisibleMenuUser(flag)

        if (flag) {
            const tokenAmount = await getAmountToken(chainId, address)
            setCurrenciesAmount(tokenAmount)
            dispatch(updateBalance({ ...tokenAmount }));
        }
    }
    const handleMenuClick = async e => {
        if (e.key === '6' || e.key === '7' || e.key === '8' || e.key === '2') {
            const tokenAmount = await getAmountToken(chainId, address)
            setCurrenciesAmount(tokenAmount)
            dispatch(updateBalance({
                ...tokenAmount
            }));
            setVisibleMenuUser(false)
        }
    }

    const handleTest = data => {
        setDisplayDrawerAccount(data)
    }

    const handleRedirectMyProfile = () => {
        if (profile.username || profile.walletAddress) {
            history.push({
                pathname: profile?.shortUrl ? `/${profile?.shortUrl}` : `/user/${profile.walletAddress}`,
                state: { activeOwnerTab: true }
            })
            return
        }

        history.push('/connect')
    }

    if (profile?.walletAddress && address) {
        return !isMobileHeader ? (

            <ButtonCustom color="white" size='md' className="btn-account btn-account-wallet" onClick={handleRedirectMyProfile}>
                <div className="text-center">
                    <span>{userName}</span>
                    {currentAvatar ? (
                        <img className="avatar" alt="" src={`${AWS_CLOUDFRONT_ENDPOINT}/${currentAvatar}`} />
                    ) : (
                        <img className="avatar" src={IconAvatarDefault} alt="" />
                    )}
                </div>
            </ButtonCustom>
        ) : (
            <>
                <div className="btn-account-mobile" onClick={handleRedirectMyProfile}>
                    {currentAvatar ? (
                        <img
                            className="avatar"
                            style={{ marginRight: 0 }}
                            alt=""
                            src={`${AWS_CLOUDFRONT_ENDPOINT}/${currentAvatar}`}
                        />
                    ) : (
                        <img
                            alt=""
                            className="avatar"
                            style={{ marginRight: '0px !important' }}
                            src={IconAvatarDefault}
                        />
                    )}
                </div>
                {displayDrawerAccount && (
                    <Drawer
                        className={`dropdown-account ${theme === 'dark-theme' ? `dropdown-account-dark` : ''}`}
                        height={profile?.username ? 490 : 550}
                        closable={false}
                        placement="bottom"
                        onClose={() => setDisplayDrawerAccount(false)}
                        open={true}
                        key="ava"
                    >
                        <DropdownMenu
                            setDisplayDrawerAccount={setDisplayDrawerAccount}
                            handleMenuClick={handleMenuClick}
                            setVisibleMenuUser={setVisibleMenuUser}
                            setIsShowModalWETH={setIsShowModalWETH}
                            setIsVisibleUniswapModal={setIsVisibleUniswapModal}
                            currenciesAmount={currenciesAmount}
                        />
                    </Drawer>
                )}
            </>
        )
    }
    return !isMobileHeader ? (
        <Link to={{ pathname: '/connect', state: { from: router?.path } }}>
            <ButtonCustom
                style={{ display: 'flex', height: 40, alignItem: 'center' }}
                color="blue"
                className="btn-submit"
                iconLeft={<i className="ri-wallet-3-line"></i>}
            >
                {t('header.connectWallet')}
            </ButtonCustom>
        </Link>
    ) : (
        <Link className="text-white" to={{ pathname: '/connect', state: { from: router?.path } }}>
            <ButtonCustom
                color="blue"
                className="btn-submit"
                style={{ height: 40, width: 'fit-content' }}
                iconLeft={<i className="ri-wallet-3-line"></i>}
            >
                {isShowConnect && t('header.connectWallet')}
            </ButtonCustom>
        </Link>
    )
}

export default RenderHeaderButton
