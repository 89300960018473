/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useAccount, useConnect } from 'wagmi'
import { getMetaMask, getWalletConnect, METAMASK, WALLETCONNECT } from 'blockchain/utils'
import { setCloseLoadingConnectWallet } from 'store/login.slice'
import { loginWallet } from 'store/actions/user'

export const useEagerConnect = () => {
    const loggedAccount = useSelector(state => state?.user?.profile?.walletAddress);
    const { connectors, connect, chainId } = useConnect();
    const { address } = useAccount();
    const dispatch = useDispatch();
    
    const [tried, setTried] = useState(false);

    useEffect(() => {
        if (address || !loggedAccount) {
            return;
        }
        if (loggedAccount && !tried) {
            const isWc = localStorage.getItem('wagmi.recentConnectorId') === `"${WALLETCONNECT}"`;
            const isMetaMask = localStorage.getItem('wagmi.recentConnectorId') === `"${METAMASK}"`;
            if (!isWc && !isMetaMask) {
                setTried(true);
                return;
            }

            const connector = isWc ? getWalletConnect(connectors) : getMetaMask(connectors);
            connect({ connector, chainId }, {
                onSuccess: async ({_accounts, chainId}) => {
                    dispatch(setCloseLoadingConnectWallet());
                    dispatch(loginWallet({account: loggedAccount.toLowerCase(), chainId}));
                    setTried(true);
                },
                onError: (error) => {
                    setTried(true);
                    message.error(error.message.toString().split("\n")[0]);
                }
            });
        }
    }, [address, loggedAccount]) // intentionally only running on mount (make sure it's only mounted once :))

    return tried
}
