import React, { useEffect, useState, useCallback, useContext } from 'react'
import ReactLoading from 'react-loading'
import { Row, Col } from 'antd'
import { CardItem } from 'components/modules'

import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import * as message from 'utils/custom-message'
import noItem from 'assets/images/notfound.svg'
import styles from './style.module.scss'
import { ButtonCustom } from 'components/common'
import './styles.scss'

import nftService from 'service/nftService'
import { ThemeContext } from '../../../../ThemeProvider'

const NoItemFound = () => {
    const { t } = useTranslation()
    return (
        <div className="no-item-container">
            <img src={noItem} alt="" />
            <p>{t('common.noItem')}</p>
            <p>{t('common.noItemContent')}</p>
        </div>
    )
}

const NFTCollection = ({ loading, data, collectionData }) => {
    const {t} = useTranslation();
    if (Array.isArray(data) && data?.length === 0) {
        return <NoItemFound />
    }

    return (
        <div>
            <Row className={styles.exploreListsRow} gutter={[{ xs: 6, sm: 6, md: 20, lg: 20, xl: 20 }, 20]}>
                {data?.map((d, index) => (
                    <Col xs={{ span: 24 }} md={{ span: 12 }} lg={{ span: 8 }} xl={{ span: 6 }} key={index}>
                        <CardItem data={d} />
                    </Col>
                ))}
            </Row>
            {!loading && (
                <ButtonCustom color="blue" className={styles.viewAll + ' br-12'}>
                    <Link
                        to={`/collection/${collectionData?.shortUrl || collectionData?.id || collectionData?.address}`}
                        className={styles.buttonViewAll}
                    >
                        {t('nftDetail.viewCollection')}
                    </Link>
                </ButtonCustom>
            )}
        </div>
    )
}

const Related = ({ collectionData, nftId }) => {
    const {t} = useTranslation();
    const { theme } = useContext(ThemeContext)
    const [loading, setLoading] = useState(false)
    const [nfts, setNfts] = useState(null)

    const fetchNfts = useCallback(async () => {
        if (collectionData?.address) {
            setLoading(true)
            const [data, err] = await nftService.getListNft({
                page: 1,
                limit: 4,
                collectionAddress: collectionData?.id,
                ignoreNftId: Number(nftId)
            })
            if (data) setNfts(data?.items)
            if (err) {
                message.error(t('error.message.somethingWentWrong'))
            }
            setLoading(false)
        }
    }, [collectionData, nftId])

    useEffect(() => {
        fetchNfts()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [collectionData?.address, nftId])

    if (nfts?.length === 0) {
        return null
    }

    return (
        <div className="related-list">
            <div className="container">
                <div className="home-page-category__header">
                    <p className={`related-header ${theme === 'dark-theme' ? 'dark-related-header' : ''}`}>
                        {t('nftDetail.moreFromThisCollection')}{' '}
                    </p>
                </div>
                <div className='collection-container'>
                    <NFTCollection loading={loading} data={nfts} collectionData={collectionData} />
                </div>
                {loading && (
                    <div className={styles.boxLoading}>
                        <ReactLoading className={styles.exploreListsBtn} type="spinningBubbles" color="#002979" />
                    </div>
                )}

            </div>
        </div>
    )
}

export default Related
