import {createSlice} from '@reduxjs/toolkit';
import { TRADING_ACTIONS } from 'constants/';

const initialState = {
  data: {},
  tradingHistory: [],
  historyFilter: TRADING_ACTIONS[0].value,
  offers: [],
  nftSales: [],
  loading: false,
  loadingHistory: false,
  loadingOffer: false,
  pageOffer: 1,
  pageHistory: 1,
  loadingOfferOpenPid: false,
  pageOfferOpenBid: 1,
  loadingSellMultiple: false,
  pageSellMultiple: 1,
  error: null,
  errorUpdateNft: null,
  errorHistory: null,
  isGotoSale: false
};

export const collectibleSlice = createSlice({
  name: 'collectible',
  initialState,
  reducers: {
    setNftDetailLoading: (state) => {
      state.loading = true;
      state.error = null;
    },
    setNftDetailSuccess: (state, data) => {
      state.data = {...data.payload};
      state.loading = false;
    },
    setNftDetailFailure: (state, data) => {
      state.error = data.payload;
      state.loading = false;
    },
    updateDescriptionSuccess: (state, data) => {
      state.data.description = data.payload;
      state.loading = false;
    },
    updateNftDetailLoading: (state) => {
      state.loading = true;
    },
    updateNftDetailSuccess: (state, data) => {
      state.data = data.payload;
      state.loading = false;
    },
    updateNftDetailFailure: (state, data) => {
      state.errorUpdateNft = data.payload;
      state.loading = false; 
    },
    clearNftDetail: (state) => {
      state.data = { id: state.data?.id, collection: state.data?.collection, tokenId: state?.data?.tokenId };
      state.tradingHistory = [];
      state.offers = [];
    },
    setTradingHistoryLoading: (state) => {
      state.loadingHistory = true;
    },
    setTradingHistorySucess: (state, data) => {
      state.tradingHistory = data.payload;
      state.loadingHistory = false;
    },
    setTradingHistoryFailure: (state, data) => {
      state.errorHistory = data.payload;
      state.loadingHistory = false;
    },
    changeTradingHistoryFilter: (state, data) => {
      state.historyFilter = data?.payload;
    },
    setOfferLoading: (state) => {
      state.loadingOffer = true;
    },
    setOfferSuccess: (state, data) => {
      state.offers = data.payload;
      state.loadingOffer = false;
    },
    setOfferFailure: (state, data) => {
      state.errorHistory = data.payload;
      state.loadingOffer = false;
    },
    changeTradingHistoryPage: (state, data) => {
      state.pageHistory = data.payload;
    },
    changeOfferPage: (state, data) => {
      state.pageOffer = data.payload;
    },
    goToSales: (state, data) => {
      state.isGotoSale = data.payload;
    },
    setOfferOpenPidFailure: (state, data) => {
      state.errorHistory = data.payload;
      state.loadingOfferOpenPid = false;
    },
    setOfferOpenPidLoading: (state) => {
      state.loadingOfferOpenPid = true;
    },
    changeOpenForOfferPage: (state, data) => {
      state.pageOfferOpenBid = data.payload;
    },
    setMultipleSellSuccess: (state, data) => {
      state.nftSales = data.payload;
      state.loadingSellMultiple = false;
    },
    setMultipleSellFailure: (state, data) => {
      state.nftSales = data.payload;
      state.loadingSellMultiple = false;
    },
    setMultipleSellLoading: (state, data) => {
      state.loadingSellMultiple = true;
    },
    changeMultipleSellPage: (state, data) => {
      state.pageSellMultiple = data.payload;
    }
  },
});

export const {
  changeMultipleSellPage,
  changeOfferPage,
  changeOpenForOfferPage,
  changeTradingHistoryFilter,
  changeTradingHistoryPage,
  clearNftDetail,
  goToSales,
  setMultipleSellFailure,
  setMultipleSellLoading,
  setMultipleSellSuccess,
  setNftDetailFailure,
  setNftDetailLoading,
  setNftDetailSuccess,
  setOfferFailure,
  setOfferLoading,
  setOfferOpenPidFailure,
  setOfferOpenPidLoading,
  setOfferSuccess,
  setTradingHistoryFailure,
  setTradingHistoryLoading,
  setTradingHistorySucess,
  updateDescriptionSuccess,
  updateNftDetailFailure,
  updateNftDetailLoading,
  updateNftDetailSuccess,
} = collectibleSlice.actions;

export default collectibleSlice.reducer;
