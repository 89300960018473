import { ButtonCustom } from 'components/common';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';

const Categories = () => {
  const history = useHistory();
  const { t } = useTranslation();
  const { data } = useSelector(state => state.categories);

  const onClickShowMore = () => history.push('/explore/all');

  const convertedData = data.map(category => ({
    ...category,
    label: t(`category.${category.label}`),
  }));


  return (
    <div className="categories">
        <div className="home-page-category__header explore-trending-collection">
            <h2>{t('homepage.category.title')}</h2>
            <div className="button-holder">
                <Link className="link-view-all" to={`/explore/all`}>
                    {t("homepage.category.view")}
                </Link>
            </div>
        </div>
        <div className="categories__list">
          {convertedData.map(category => (
            <div key={category.id} className="categories__list__item" onClick={() => history.push(`/explore/${category.code}`)}>
              <img src={category.img} alt={category.label} />
              <span>{category.label}</span>
            </div>
          ))}
        </div>
        <ButtonCustom variant="outline" onClick={onClickShowMore}>
          {t('editProfile.showMore')}
        </ButtonCustom>
    </div>
  );
};

export default Categories;
