import { Modal } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { get } from "lodash";
import { useAccount, useChainId } from "wagmi";

import React from "react";
import { getAmountToken } from "utils/index";
import "./style.scss";
import { useState } from "react";
import { setCloseLoadingConnectWallet } from "store/login.slice";
import { closeTermOfServiceModal } from "store/modal.slice";
import {
  setLoginWalletFailure,
  setLoginWalletSuccess,
  setUserGetProfileSuccess,
} from "store/user.slice";
import userService from "service/userService";
import { ButtonCustom, InputCustom } from "..";
import { useTranslation } from "react-i18next";

const TermOfService = () => {
  const {t} = useTranslation();
  const dispatch = useDispatch();
  const modal = useSelector((state) => state.modal);
  const [code, setCode] = useState("");
  const [error, setError] = useState("");

  const { address } = useAccount();
  const chainId = useChainId();

  const open = get(modal, "open");

  const onCancel = () => {
    dispatch(setLoginWalletFailure(""));
    dispatch(closeTermOfServiceModal());
    dispatch(setCloseLoadingConnectWallet());
  };

  const handleOk = async () => {
    const tokenAmount = await getAmountToken(chainId, address);
    const response = await userService.signup({
      walletAddress: address.toLowerCase(),
      code,
    });
    const newUserData = response[0];
    const errorSignupUser = response[1];

    if (newUserData) {
      setError("");
      dispatch(setUserGetProfileSuccess(newUserData));
      dispatch(setLoginWalletSuccess({ ...tokenAmount }));
      dispatch(closeTermOfServiceModal());
    }
    if (errorSignupUser) {
      setError(errorSignupUser?.response?.data?.message);
    }
    setCode("");
    dispatch(setCloseLoadingConnectWallet());
  };

  return (
    <Modal
      footer={null}
      closable={false}
      visible={open}
      title={t("termOfService.title")}
      centered={true}
      className="term-of-service__modal"
    >
      <div className="modal-wrapper">
        <span className="title">{t("termOfService.content.title")}</span>
        <p className="description">
        {t("termOfService.content.desc")}{" "}
          <a
            href={`https://smapocke.com/TermsofUse`}
            rel="noreferrer"
            target="_blank"
            className="term-of-service"
          >
            {t("termOfService.title")}
          </a>{" "}
          {t("termOfService.content.and")}{" "}
          <a
            href={"https://smapocke.com/PrivacyPolicy"}
            rel="noreferrer"
            target="_blank"
            className="term-of-service"
          >
            {t("header.headerNav.privacy")}.
          </a>
        </p>
        <div className="reference-code">
          <p className="reference-content">
            {t("termOfService.content.referContent")}
          </p>
          <InputCustom
            placeholder="Referral code"
            onChange={(e) => setCode(e?.target?.value)}
          />
          {error && <p className="reference-error">{error}</p>}
        </div>

        <div className="btn-term-of-service">
          <ButtonCustom onClick={handleOk} color="blue">
            {t("termOfService.signIn")}
          </ButtonCustom>
          <ButtonCustom
            onClick={onCancel}
            color="white"
            className="btn-disconnect"
          >
            {t("termOfService.disconnect")}
          </ButtonCustom>
        </div>
      </div>
    </Modal>
  );
};

export default TermOfService;
