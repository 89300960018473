import React from 'react';
import { Col, Row } from 'antd'
import { Helmet } from 'react-helmet'

import DefaultLayout from 'components/layout/default-layout'
import { MAIN_TITLE } from 'constants/index'
import SingleIcon from 'assets/icons/single-collectible.png'
import MultipleIcon from 'assets/icons/multiple-collectible.png'
import { CreateTypeCard } from './create-type-card'
import './style.scss'
import { useTranslation } from 'react-i18next';

const ChooseCollectibleType = () => {
    const { t } = useTranslation();

    const createList = [
        {
            type: 'single',
            title: t('chooseType.single.title'),
            describe: t('chooseType.single.desc'),
            button: t('chooseType.single.btn'),
            icon: SingleIcon,
            route: 'single'
        },
        {
            type: 'multiple',
            title: t('chooseType.multiple.title'),
            describe: t('chooseType.multiple.desc'),
            button: t('chooseType.multiple.btn'),
            icon: MultipleIcon,
            route: 'multiple'
        }
    ]

    return (
        <>
            <Helmet>
                <meta charSet="utf-8" />
                <title>{`${MAIN_TITLE} - Choose Collectible Type`}</title>
            </Helmet>
            <DefaultLayout>
                <div className="create-type-container">
                    <Row className="align-items-center justify-content-center create-type-header">
                        <span className="title">{t('chooseType.title')}</span>
                        <Col span={24} className="text-center create-type">
                            <p className="create-type__text">
                                {t('chooseType.desc')}
                            </p>
                        </Col>
                    </Row>
                    <div className="create-type-content">
                    <Row justify="center" gutter={[24, 24]}>
                        {createList.map((item, index) => {
                            return (
                                <Col
                                    key={index}
                                    sm={24}
                                    xs={24}
                                    md={24}
                                    lg={12}
                                    xl={12}
                                    xxl={12}
                                    className="text-center"
                                >
                                    <CreateTypeCard item={item} />
                                </Col>
                            )
                        })}
                    </Row>
                    </div>
                </div>
            </DefaultLayout>
        </>
    )
}

export default ChooseCollectibleType
