import { SERVER_API_ENDPOINT } from 'constants/envs'
import Request from '../request'

const saleNftService = {
    putOnSale: async data => {
        const {
            startDate,
            quantity,
            type,
            action,
            expireDate,
            currencyToken,
            replacementPattern,
            nftId,
            price,
            metadata
        } = data

        try {
            const res = await Request.post(`${SERVER_API_ENDPOINT}/nft-sale`, {
                startDate,
                quantity,
                type,
                action,
                expireDate,
                currencyToken,
                replacementPattern,
                nftId,
                price,
                sellHash: metadata.hash,
                metadata
            })
            return [res, null]
        } catch (error) {
            console.error('putOnSale', error.response)
            return [null, error]
        }
    },
    getTopArtists: async ({ day, isSeller }) => {
        try {
            const res = await Request.get(`${SERVER_API_ENDPOINT}/nft-history/artists`, {
                time: day,
                isSeller
            })
            return [res?.data?.items, null]
        } catch (error) {
            console.error('getTopArtists', error.response)
            return [null, error]
        }
    },

    getTradingHistory: async ({ nftId, page = 1, limit = 5, type }) => {
        try {
            const res = await Request.get(`${SERVER_API_ENDPOINT}/nft-history/${nftId}`, {
                page,
                limit,
                type
            })
            return [res?.data, null]
        } catch (error) {
            console.error('getTradingHistory', error.response)
            return [null, error]
        }
    },

    getOffers: async ({ nftId, page = 1, limit = 5 }) => {
        try {
            const res = await Request.get(`${SERVER_API_ENDPOINT}/offer-sale-nft/offers/${nftId}`, {
                page,
                limit
            })
            return [res?.data, null]
        } catch (error) {
            console.error('getOffers', error.response)
            return [null, error]
        }
    },

    getNftSales: async ({ nftId, page = 1, limit = 5 }) => {
        try {
            const res = await Request.get(`${SERVER_API_ENDPOINT}/nft-sale/by-nft/${nftId}`, {
                page,
                limit
            })
            return [res?.data, null]
        } catch (error) {
            console.error('getNftSales', error.response)
            return [null, error]
        }
    }
}

export default saleNftService
