import { createAsyncThunk } from '@reduxjs/toolkit';

import categoryService from 'service/categoryService'
import systemConfigService from 'service/systemService'
import env from 'env/index'
import { SERVICE_FEE_ADDRESS, SERVICE_FEE_PERCENT } from 'constants/local-storage-keys'
import { isAddress } from 'ethers'
import { setCategories, setErrorCategories } from 'store/category.slice'
import { setGlobalLoading, setCloseGlobalLoading, setSystemConfig } from 'store/system.slice'

export const init = createAsyncThunk(
    'init',
    async (_params, thunkApi) => {
        const { dispatch } = thunkApi;
        const [data, error] = await categoryService.get()
        dispatch(setGlobalLoading())
        if (data) {
            dispatch(setCategories(data));
        }
        if (error) {
            dispatch(setErrorCategories(error));
        }
        dispatch(setCloseGlobalLoading())
    },
);

export const getSystemConfig = createAsyncThunk(
    'init/getSystemConfig',
    async (_params, thunkApi) => {
        const { dispatch } = thunkApi;

        dispatch(setGlobalLoading())
        const [data] = await systemConfigService.getSystemConfig()

        localStorage.setItem(
            SERVICE_FEE_ADDRESS,
            isAddress(data?.addressServiceFee) ? data?.addressServiceFee : env.metadata.recipientFeeAddress
        )
        localStorage.setItem(SERVICE_FEE_PERCENT, data?.serviceFee || 2)

        dispatch(setSystemConfig({
            MARKET_FEE: data?.serviceFee / 100
        }));
        dispatch(setCloseGlobalLoading())
    },
);
