import BigNumber from 'bignumber.js';
import { parseUnits, BrowserProvider, JsonRpcSigner, ethers } from 'ethers';
import { useMemo } from 'react';
import { useConnectorClient } from 'wagmi';
import { getConnectorClient } from '@wagmi/core'
import { wagmiConfig } from './wagmi';

export const convertPriceToBigDecimals = (price, decimal) => {
    const res = parseUnits(price.toString(), decimal)
    return res.toString()
}

export const multiply = (a, b) => {
    const commonMultiplier = 1000000

    a *= commonMultiplier
    b *= commonMultiplier

    return (a * b) / (commonMultiplier * commonMultiplier)
}

export const convertBigNumberValueToNumber = (weiBalance, decimal) => {
    const number = new BigNumber(weiBalance || 0).div(
        new BigNumber(10).exponentiatedBy(decimal),
      );
    return Number(number.toString());
}



export function clientToSigner(client) {
  const { account, chain, transport } = client
  const network = {
    chainId: chain.id,
    name: chain.name,
    ensAddress: chain.contracts?.ensRegistry?.address,
  }
  const provider = new BrowserProvider(transport, network)
  const signer = new JsonRpcSigner(provider, account.address)
  return signer
}

export const getEtherSigner = async ({chainId} = {}) => {
  const client = await getConnectorClient(wagmiConfig, {
    chainId,
  });
  return client ? clientToSigner(client) : undefined;
};

/** Hook to convert a viem Wallet Client to an ethers.js Signer. */
export function useEthersSigner({ chainId } = {}) {
  const { data: client } = useConnectorClient({ chainId })
  return useMemo(() => (client ? clientToSigner(client) : undefined), [client])
}

export const getContractInstanceEther = async (ABIContract, contractAddress) => {
  const signer = await getEtherSigner();

  return new ethers.Contract(contractAddress, ABIContract, signer)
}
