import React, { useRef, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { Link, useLocation } from 'react-router-dom'
import { Helmet } from 'react-helmet'
import useQuery from 'utils/useQuery'
import { Tabs } from 'antd'
import { useTranslation } from 'react-i18next'

import DefaultLayout from '../../components/layout/default-layout'
// import Community from './community'
// import TopNfts from './top-nfts'
import Explore from './explore'
// import HotSellers from './hot-sellers'
// import HotBuyers from './hot-buyers'
import HotCollections from './hot-collections'
import { MAIN_TITLE, NETWORK_OPTIONS } from 'constants/index'
// import TopArtwork from './top-artwork'
// import TopCollections from './top-collections'
import './style.scss'
import GoldenGlobeIcon from 'assets/icons/golden-globe.svg';
import Collections from './collections'
import Categories from './categories'

const HomePage = () => {
    const location = useLocation()
    const BLOCK_UNIX = 60
    const exploreRef = useRef(null)
    const query = useQuery()
    const { data } = useSelector(state => state.user);

    const { t } = useTranslation();

    const [networkType, setNetworkType] = useState('');

    const scrollToExplore = () => {
        window?.scroll({ top: exploreRef?.current?.offsetTop - BLOCK_UNIX, behavior: 'smooth' })
    }

    const scrollToTop = () => {
        window?.scrollTo({ top: 0, behavior: 'smooth' })
    }

    useEffect(() => {
        if (query.get('category')) {
            scrollToExplore()
        }
    }, [query])

    useEffect(() => {
        if (location?.state?.isScrollToExplore) {
            scrollToExplore()
        }
    }, [location?.state?.isScrollToExplore])

    useEffect(() => {
        scrollToTop()
    }, [])

    return (
        <DefaultLayout>
            <Helmet>
                <meta charSet="utf-8" />
                <title>{`${MAIN_TITLE}`}</title>
                <meta name="description" content={data.description} />
            </Helmet>
            <div className="home-page">
                {/* <TopNfts /> */}
                <Tabs
                    defaultActiveKey={networkType}
                    onChange={e => setNetworkType(e)}
                    className="network-tabs"
                >
                    <Tabs.TabPane
                        tab={
                            <span className="label">
                                <img src={GoldenGlobeIcon} alt="All Chains" />
                                <span>{'All Chains'}</span>
                            </span>
                        }
                        key={''}
                    />
                    {NETWORK_OPTIONS.map(network => {
                        return (
                            <Tabs.TabPane
                                tab={
                                    <span className="label">
                                        <img src={network.icon} alt={network.label} />
                                        <span>{network.label}</span>
                                    </span>
                                }
                                key={network.value}
                            />
                        )
                    })}
                </Tabs>
                <div className="trending-collections">
                    <div className="home-page-category__header explore-trending-collection">
                        <h2>{t('homepage.trendingCollection.title')}</h2>
                        <div className="button-holder">
                            <Link className="link-view-all" to={`/collections`}>
                                {t("common.viewAll")}
                            </Link>
                        </div>
                    </div>
                    <HotCollections networkTypes={networkType} />
                </div>
                <Collections networkTypes={networkType} />
                {/* <TopCollections /> */}
                <div ref={exploreRef} id="explore">
                    <Explore networkTypes={networkType} />
                </div>
                <Categories />
                {/* <div className="box-hot-sell-buy">
                    <div className="container">
                        <div className="hot-buy-sell-content">
                            <HotSellers />
                            <HotBuyers />
                        </div>
                        <div className="row-bt"></div>
                    </div>
                </div>
                <TopArtwork />
                <Community /> */}
            </div>
        </DefaultLayout>
    )
}

export default HomePage
