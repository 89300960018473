import React from "react";
import { Helmet } from "react-helmet";
import DefaultLayout from "components/layout/default-layout";
import { MAIN_TITLE } from "constants/index";
import "./style.scss";

import CollectionsProfile from "pages/profile/components/Collections";
import { useTranslation } from "react-i18next";

const Collections = () => {
  const { t } = useTranslation();
  return (
    <DefaultLayout>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{`${MAIN_TITLE} - Collections`}</title>
        <meta name="description" content={`${MAIN_TITLE} - Collections`} />
      </Helmet>
      <div className="collection-container">
        <div className={`collection-container__header`}>
          <div className="container">
            <div className="title">{t("exploreCollection.title")}</div>
          </div>
        </div>
      </div>
      <CollectionsProfile />
    </DefaultLayout>
  );
};

export default Collections;
