import React, { useCallback, useEffect, useState, useContext } from 'react'
import ReactLoading from 'react-loading'
import { useDispatch, useSelector } from 'react-redux'
import { AutoComplete, Card, Tooltip } from 'antd'
import { shortenAddress } from 'utils/func'
import { debounce } from 'lodash'
import { useTranslation } from 'react-i18next'
import { Link, useHistory } from 'react-router-dom'

import * as message from 'utils/custom-message'
import nftService from 'service/nftService'
import noItem from 'assets/images/notfound.svg'
import SearchItemImage from './SearchItemImage'
import { ButtonCustom, CustomAvatar } from 'components/common'
import { roundingNumber } from 'utils/formatNumber'
import { ThemeContext } from '../../../../../ThemeProvider'
import { setKeySearch, setResultSearch } from 'store/search.slice'
import { useLocation } from 'react-router-dom/cjs/react-router-dom'

const { Option } = AutoComplete

const SearchHeaderBar = () => {
    const { t } = useTranslation()
    const history = useHistory()
    const dispatch = useDispatch()
    const { theme } = useContext(ThemeContext)
    const system = useSelector(state => state?.system)
    const { keySearch, resultSearch } = useSelector(state => state.search)
    const [keyword, setKeyword] = useState(keySearch)
    const [result, setResult] = useState()
    const location = useLocation();

    const [loading, setLoading] = useState(false)

    useEffect(() => {
        if (!location.pathname.includes('/search')) {
            setKeyword('')
        }
    }, [location])

    const handleChangeSearch = value => {
        setKeyword(value)
        debounceSearch(value)
    }

    useEffect(() => {
        if (resultSearch) {
            setResult(resultSearch)
        }
    }, [resultSearch])

    const debounceSearch = debounce(function (value) {
        handleSearch(value)
        dispatch(setKeySearch(value));
    }, 300)

    const handleSearch = useCallback(
        async keyword => {
            setLoading(true)
            const [data, err] = await nftService.searchAll({ keyword })
            if (data) {
                setResult(data)
                if (keyword) {
                    dispatch(setResultSearch(data));
                }
            }
            if (err) {
                message.error(t('error.message.somethingWentWrong'))
            }
            setLoading(false)
        },
        [dispatch]
    )

    const handleClearSearch = useCallback(() => {
        setKeyword(null)
    }, [])

    const redirectSearchResult = useCallback(() => {
        history.push(`/search/${keyword}`)
    }, [history, keyword])

    const handleKeyDown = useCallback(
        e => {
            if (e.key === 'Enter') {
                redirectSearchResult()
            }
        },
        [redirectSearchResult]
    )

    const handleOpenDropDown = useCallback(
        open => {
            open && keyword && handleSearch(keyword)
        },
        [keyword, handleSearch]
    )

    return (
        <AutoComplete
            onChange={handleChangeSearch}
            placeholder={t('header.searchInput')}
            dropdownClassName={`dropdown-search-header ${theme === 'light-theme' ? 'dropdown-search-header-light' : 'dropdown-search-header-dark'
                }`}
            dropdownMatchSelectWidth={false}
            allowClear
            onClear={handleClearSearch}
            filterOption={false}
            value={keyword}
            onKeyDown={handleKeyDown}
            onDropdownVisibleChange={handleOpenDropDown}
        >
            {loading && !system?.loading ? (
                <Option key="loading" style={{ padding: 24 }}>
                    <div className="d-flex justify-content-center align-items-center">
                        <ReactLoading
                            className="btn-load d-flex justify-content-center align-items-center"
                            type="spinningBubbles"
                            color="#002979"
                        />
                    </div>
                </Option>
            ) : !keyword ? null : result &&
                (result?.nfts?.items?.length > 0 ||
                    result?.collections?.items?.length > 0 ||
                    result?.accounts?.items?.length > 0) ? (
                <>
                    <Option key="collection" value={keyword} className="title">
                        {t('search.tab_collections')}
                    </Option>
                    {result?.collections?.items?.slice(0, 3)?.map((item, index) => (
                        <Option className="list-items-search" key={`collection_${index}`} value={keyword}>
                            <Link to={`/collection/${item?.shortUrl || item?.id || item?.address}`}>
                                <Card>
                                    <div className="user-avatar">
                                        <CustomAvatar verifiedClassName={"verified-icon"} verified={!!item?.isVerify} src={item?.bannerUrl} />
                                    </div>
                                    <div className="card-search-body">
                                        <Tooltip overlayClassName="blue" placement="topLeft" title={item?.name}>
                                            <p className="item-name">{item?.name}</p>
                                        </Tooltip>
                                        <p className="gray-text">
                                            {t('search.by',
                                                {
                                                    creator: item?.creator?.shortUrl
                                                        ? `@${item?.creator?.shortUrl}`
                                                        : item?.creator?.walletAddress
                                                            ? shortenAddress(item?.creator?.walletAddress)
                                                            : 'SmartPocket'
                                                })}
                                        </p>
                                    </div>
                                </Card>
                            </Link>
                        </Option>
                    ))}
                    <Option key="nfts" value={keyword} className="title">
                        {t('search.tab_items')}
                    </Option>
                    {result?.nfts?.items?.slice(0, 3)?.map((item, index) => (
                        <Option className="list-items-search" key={`nfts_${index}`} value={keyword}>
                            <Link to={`/nft/${item?.collection?.id}:${item?.tokenId}`}>
                                <Card>
                                    <div className="card-search-avatar">
                                        <SearchItemImage item={item} />
                                    </div>
                                    <div className="card-search-body">
                                        <Tooltip overlayClassName="blue" placement="topLeft" title={item?.title}>
                                            <p className="item-name">{item?.title}</p>
                                        </Tooltip>
                                    </div>
                                </Card>
                            </Link>
                        </Option>
                    ))}

                    <Option key="users" value={keyword} className="title">
                        {t('search.tab_users')}
                    </Option>
                    {result?.accounts?.items?.slice(0, 3)?.map((item, index) => (
                        <Option className="list-items-search" key={`users_${index}`} value={keyword}>
                            <Link to={item?.shortUrl ? `/user/${item?.shortUrl}` : `/user/${item?.walletAddress}`}>
                                <Card>
                                    <div className="user-avatar">
                                        <CustomAvatar verifiedClassName={"verified-icon"} verified={!!item?.isVerify} src={item?.avatarImg} />
                                    </div>
                                    <div className="card-search-body">
                                        <Tooltip
                                            overlayClassName="blue"
                                            placement="topLeft"
                                            title={item?.username ? item?.username : item?.walletAddress}
                                        >
                                            <p className="item-name">
                                                {item?.username || shortenAddress(item?.walletAddress)}
                                            </p>
                                        </Tooltip>
                                        <p className="gray-text">{roundingNumber(item?.followers?.length)} {t(`profile.followersLowercase`)}</p>
                                    </div>
                                </Card>
                            </Link>
                        </Option>
                    ))}
                    <Option key="view-more" value={keyword} className="title">
                        <ButtonCustom className="btn-view-all" onClick={redirectSearchResult}>
                            {t('search.allResults')}
                        </ButtonCustom>
                    </Option>
                </>
            ) : (
                <Option className={`title text-center ${theme === 'dark-theme' ? 'dark-title' : ''}`}>
                    <img src={noItem} alt="" />
                    <p>{t('search.noItemsFound')}</p>
                </Option>
            )}
        </AutoComplete>
    )
}

export default SearchHeaderBar
