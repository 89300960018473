import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'

import { ButtonCustom } from 'components/common'
import SaleModal from './SaleModal'
import SaleStepModal from './SaleStepModal'
import BidStepModal from './BidStepModal'
import AuctionStepModal from './AuctionStepModal'
import { isUserAuthenticated } from 'utils/auth'

const NotForSale = ({ isOwner, signWallet }) => {
    const { t } = useTranslation()
    const [isOpenSaleModal, setIsOpenSaleModal] = useState(false)
    const [isOpenSaleStepModal, setIsOpenSaleStepModal] = useState(false)
    const [isOpenBidStepModal, setIsOpenBidStepModal] = useState(false)
    const [isOpenAuctionStepModal, setIsOpenAuctionStepModal] = useState(false)
    const [saleData, setSaleData] = useState()
    const [auctionData, setAuctionData] = useState()
    const [offerPidData, setOfferPidData] = useState()
    const onClickPutOnSale = async () => {
        if (!isUserAuthenticated()) {
            const res = await signWallet()
            if (!res) return
        }
        setIsOpenSaleModal(true)
    }
    const onCloseSaleModal = () => {
        setIsOpenSaleModal(false)
    }
    const onOpenSaleStepModal = data => {
        setIsOpenSaleModal(false)
        setSaleData(data)
        setIsOpenSaleStepModal(true)
    }
    const onOpenAuctionStepModal = data => {
        setIsOpenSaleModal(false)
        setAuctionData(data)
        setIsOpenAuctionStepModal(true)
    }

    const onOpenBidStepModal = data => {
        setIsOpenSaleModal(false)
        setOfferPidData(data)
        setIsOpenBidStepModal(true)
    }

    if (isOwner)
        return (
            <div className="bid">
                <p className="sale-text">{t('nftDetail.saleText')}</p>
                <ButtonCustom fullWidth size="lg" color="blue" className="action-btn br-12" onClick={onClickPutOnSale}>
                    {t('nftDetail.listForSale')}
                </ButtonCustom>

                {isOpenSaleModal && (
                    <SaleModal
                        onCloseModal={onCloseSaleModal}
                        onOpenSaleStepModal={onOpenSaleStepModal}
                        onOpenAuctionStepModal={onOpenAuctionStepModal}
                        onOpenBidStepModal={onOpenBidStepModal}
                    />
                )}

                {isOpenSaleStepModal && (
                    <SaleStepModal
                        isOpen={isOpenSaleStepModal}
                        onClose={() => setIsOpenSaleStepModal(false)}
                        saleData={saleData}
                    />
                )}

                {isOpenBidStepModal && (
                    <BidStepModal
                        isOpen={isOpenBidStepModal}
                        onClose={() => setIsOpenBidStepModal(false)}
                        saleData={offerPidData}
                    />
                )}
                {isOpenAuctionStepModal && (
                    <AuctionStepModal onClose={() => setIsOpenAuctionStepModal(false)} saleData={auctionData} />
                )}
            </div>
        )

    return (
        <div className="not-sale-container">
            <ButtonCustom fullWidth size="lg" color="blue" className="not-sale-btn br-12" disabled={true}>
                {t('nftDetail.notForSale')}
            </ButtonCustom>
        </div>
    )
}

export default NotForSale
