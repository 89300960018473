import React, { useEffect, useCallback, useContext } from 'react'
import { Row, Col, Tooltip, Avatar } from 'antd'
import { useTranslation } from 'react-i18next'
import { useSelector, useDispatch } from 'react-redux'
import { useWindowSize } from 'react-use'
import ReactLoading from 'react-loading'
import { Link, useHistory } from 'react-router-dom'
import BigNumber from 'bignumber.js'

import shrinkAddress from 'utils/shrinkAddress'
import { NoData, SelectCustom } from 'components/common'
import { getCustomDiffTime } from 'utils/convertDate'

import NoItem from 'assets/images/notfound.svg'
import OutLinkIcon from 'assets/icons/out-link.svg'
import { getTradingHistory, changeHistoryFilter, changePageHistory } from 'store/actions/nft'
import { PaginationCustom } from 'components/common'
import { NETWORKS } from 'constants/envs'
import { ThemeContext } from '../../../../../ThemeProvider'
import './style.scss'
import { useMultiLanguageConstant } from 'hooks/useMultiLanguageConstant'
import { TRADING_ACTIONS } from 'constants/'
import CustomVerifyIcon from 'components/common/custom-verify-icon'
import { convertImageAvatar } from 'utils/image'

const TradingHistory = () => {
    const { theme } = useContext(ThemeContext)
    const history = useHistory()
    const { t } = useTranslation()
    const dispatch = useDispatch()
    const { width } = useWindowSize()
    const collectible = useSelector(state => state.collectible?.data) || {}
    const profile = useSelector(state => state.user?.profile) || {}
    const { tradingHistory, pageHistory, historyFilter } = useSelector(state => state.collectible)
    const isLoadingHistory = useSelector(state => state.collectible?.loadingHistory)
    const { tradingActions } = useMultiLanguageConstant()

    const onChangePage = value => dispatch(changePageHistory({ value }))

    const onChangeActionFilter = value => {
        dispatch(changeHistoryFilter({ value }))
    }

    const getHistory = useCallback(async () => {
        if (collectible?.id) {
            const params = {
                nftId: collectible?.id,
                type: historyFilter,
                page: pageHistory,
                limit: 5
            }
            dispatch(getTradingHistory(params))
        }
    }, [historyFilter, collectible?.id, dispatch, pageHistory])

    useEffect(() => {
        getHistory()
    }, [getHistory, pageHistory, collectible?.id])

    useEffect(() => {
        dispatch(changePageHistory({ value: 1 }))
    }, [historyFilter, dispatch])

    useEffect(() => {
        dispatch(changeHistoryFilter({ value: TRADING_ACTIONS[0].value }))
    }, [history.location.pathname, dispatch])

    return (
        <div
            className={`trading-history__container ${theme === 'dark-theme' ? 'dark-trading-history__container' : ''}`}
        >
            <div className='paginate-filter-container'>
                <PaginationCustom
                    value={pageHistory}
                    onChangePage={onChangePage}
                    total={tradingHistory?.meta?.totalItem}
                />
                <SelectCustom
                    className="select ant-white"
                    options={tradingActions}
                    value={historyFilter}
                    onChange={onChangeActionFilter}
                    dropdownClassName="filter-event-dropdown"
                />
            </div>

            {isLoadingHistory ? (
                <ReactLoading type="spinningBubbles" color="#002979" className="trading-history__loading" />
            ) : tradingHistory?.items?.length > 0 ? (
                <>
                    {width > 992 ? (
                        <div
                            className={`trading-history__table ${theme === 'dark-theme' ? 'dark-trading-history__table' : ''
                                }`}
                        >
                            <Row align="center" justify="space-between">
                                <Col>{t('common.type')}</Col>
                                <Col>{t('common.from')}</Col>
                                <Col>{t('common.quantity')}</Col>
                                <Col>{t('nftDetail.price')}</Col>
                                <Col>{t('nftDetail.dateTime')}</Col>
                            </Row>
                            {tradingHistory?.items?.map((item, index) => (
                                <Row align="center" justify="space-between" key={index}>
                                    <Col>
                                        <div className="trading-history__event-name">
                                            <span>
                                                {tradingActions.find(i => i?.value === item?.activityType)?.label}
                                            </span>
                                        </div>
                                    </Col>
                                    <Col>
                                        {item?.fromUser?.walletAddress ? (
                                            <div className="d-flex user-info">
                                                <span
                                                    onClick={e => {
                                                        history.push(
                                                            item?.fromUser?.shortUrl ? `/${item?.fromUser?.shortUrl}` : `/user/${item?.fromUser?.walletAddress}`
                                                        )
                                                        handleClickUser(e, item?.fromUser?.walletAddress)
                                                    }}
                                                    className="cursor-pointer"
                                                >
                                                    <div className="avatar">
                                                        <Avatar alt="example" src={convertImageAvatar(item?.fromUser?.avatarImg)} />
                                                        {item?.fromUser?.isVerify ? <CustomVerifyIcon className="icon" /> : null}
                                                    </div>
                                                </span>
                                                <Tooltip
                                                    title={
                                                        item?.fromUser?.username
                                                            ? item?.fromUser?.username
                                                            : item?.fromUser?.walletAddress
                                                    }
                                                    overlayClassName="custom-tooltip"
                                                >
                                                    <Link
                                                        to={
                                                            item?.fromUser?.shortUrl
                                                                ? `/${item?.fromUser?.shortUrl}`
                                                                : `/user/${item?.fromUser?.walletAddress}`
                                                        }
                                                    >
                                                        {item?.fromUser?.walletAddress === profile?.walletAddress
                                                            ? t('nftDetail.you')
                                                            : item?.fromUser?.username
                                                                ? item?.fromUser?.username
                                                                : shrinkAddress(item?.fromUser?.walletAddress)}
                                                    </Link>
                                                </Tooltip>
                                            </div>
                                        ) : (
                                            '--'
                                        )}
                                    </Col>
                                    <Col>{item.quantity ? item.quantity : '--'}</Col>
                                    <Col>
                                        {!!Number(item?.price)
                                            ? `${BigNumber(item?.price * 1 || 0)
                                                .decimalPlaces(8)
                                                .toFormat()
                                                .toString()} ${item?.currencyToken?.toUpperCase()}`
                                            : '--'}
                                    </Col>
                                    {/* <Col>
                                        {item?.toUser?.walletAddress ? (
                                            <Tooltip
                                                title={
                                                    item?.toUser?.username
                                                        ? item?.toUser?.username
                                                        : item?.toUser?.walletAddress
                                                }
                                                overlayClassName="custom-tooltip"
                                            >
                                                <Link
                                                    to={
                                                        item?.toUser?.shortUrl
                                                            ? `/${item?.toUser?.shortUrl}`
                                                            : `/user/${item?.toUser?.walletAddress}`
                                                    }
                                                >
                                                    {item?.toUser?.walletAddress === profile?.walletAddress
                                                        ? t('nftDetail.you')
                                                        : item?.toUser?.username
                                                        ? item?.toUser?.username
                                                        : shrinkAddress(item?.toUser?.walletAddress)}
                                                </Link>
                                            </Tooltip>
                                        ) : (
                                            ''
                                        )}
                                    </Col> */}
                                    <Col>
                                        {item?.transactionHash ? (
                                            <Tooltip title={t('nftDetail.viewOnExplorer')}>
                                                <a
                                                    className="trading-history__date-link"
                                                    target="_blank"
                                                    rel="noreferrer"
                                                    href={`${NETWORKS[collectible.collection?.networkType]
                                                        .blockExplorerUrls[0]
                                                        }/tx/${item?.transactionHash}`}
                                                >
                                                    <span>{getCustomDiffTime(item?.createdAt)}</span>
                                                    <img alt="out-link" src={OutLinkIcon} />
                                                </a>
                                            </Tooltip>
                                        ) : (
                                            <span>{getCustomDiffTime(item?.createdAt)}</span>
                                        )}
                                    </Col>
                                </Row>
                            ))}
                        </div>
                    ) : (
                        <div className="trading-history__table-mobile">
                            {tradingHistory?.items.map((item, index) => (
                                <Col key={index}>
                                    <Row align="center" justify="space-between">
                                        <Col>{t('common.type')}</Col>
                                        <Col>
                                            <div className="trading-history__event-name">
                                                <img
                                                    alt="action"
                                                    src={
                                                        tradingActions.find(i => i?.value === item?.activityType)?.icon
                                                    }
                                                />
                                                <span>
                                                    {tradingActions.find(i => i?.value === item?.activityType)?.label}
                                                </span>
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row align="center" justify="space-between">
                                        <Col>{t('common.from')}</Col>
                                        <Col>
                                            {item?.fromUser && (
                                                <div className="d-flex user-info">
                                                    <span
                                                        onClick={e => {
                                                            history.push(
                                                                item?.fromUser?.shortUrl ? `/${item?.fromUser?.shortUrl}` : `/user/${item?.fromUser?.walletAddress}`
                                                            )
                                                            handleClickUser(e, item?.fromUser?.walletAddress)
                                                        }}
                                                        className="cursor-pointer"
                                                    >
                                                        <div className="avatar">
                                                            <Avatar alt="example" src={convertImageAvatar(item?.fromUser?.avatarImg)} />
                                                            {item?.fromUser?.isVerify ? <CustomVerifyIcon className="icon" /> : null}
                                                        </div>
                                                    </span>
                                                    <Tooltip
                                                        title={
                                                            item?.fromUser?.username
                                                                ? item?.fromUser?.username
                                                                : item?.fromUser?.walletAddress
                                                        }
                                                        overlayClassName="custom-tooltip"
                                                    >
                                                        <Link
                                                            to={
                                                                item?.fromUser?.shortUrl
                                                                    ? `/${item?.fromUser?.shortUrl}`
                                                                    : `/user/${item?.fromUser?.walletAddress}`
                                                            }
                                                        >
                                                            {item?.fromUser?.walletAddress === profile?.walletAddress
                                                                ? t('nftDetail.you')
                                                                : item?.fromUser?.username
                                                                    ? item?.fromUser?.username
                                                                    : shrinkAddress(item?.fromUser?.walletAddress)}
                                                        </Link>
                                                    </Tooltip>
                                                </div>
                                            )}
                                        </Col>
                                    </Row>
                                    <Row align="center" justify="space-between">
                                        <Col>{t('common.quantity')}</Col>
                                        <Col>{item.quantity ? item.quantity : "--"}</Col>
                                    </Row>
                                    <Row align="center" justify="space-between">
                                        <Col>{t('nftDetail.price')}</Col>
                                        <Col>
                                            {!!Number(item?.price)
                                                ? `${BigNumber(item?.price * 1 || 0)
                                                    .decimalPlaces(8)
                                                    .toFormat()
                                                    .toString()} ${item?.currencyToken?.toUpperCase()}`
                                                : '--'}
                                        </Col>
                                    </Row>
                                    <Row align="center" justify="space-between">
                                        <Col>{t('nftDetail.dateTime')}</Col>
                                        <Col>
                                            {item?.transactionHash ? (
                                                <Tooltip title={t('nftDetail.viewOnExplorer')}>
                                                    <a
                                                        className="trading-history__date-link"
                                                        target="_blank"
                                                        rel="noreferrer"
                                                        href={`${NETWORKS[collectible.collectible.networkType]
                                                            .blockExplorerUrls[0]
                                                            }/tx/${item?.transactionHash}`}
                                                    >
                                                        <span>{getCustomDiffTime(item?.createdAt)}</span>
                                                        <img alt="out-link" src={OutLinkIcon} />
                                                    </a>
                                                </Tooltip>
                                            ) : (
                                                <span>{getCustomDiffTime(item?.createdAt)}</span>
                                            )}
                                        </Col>
                                    </Row>
                                </Col>
                            ))}
                        </div>
                    )}
                </>
            ) : (
                <NoData />
            )}
        </div>
    )
}

export default TradingHistory
