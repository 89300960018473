import {createSlice, createAsyncThunk} from '@reduxjs/toolkit';
import { getMetaMask, getWalletConnect, METAMASK, WALLETCONNECT } from 'blockchain/utils';
import userService from 'service/userService';
import { logoutWallet } from './user.slice';

const initialState = {
  loginData: {},
  loading: false,
  error: '',
  isClickBtnCreate: false,
  isSignWithCreate: false,
  isLoadingConnectWallet: false
};

export const loginApi = createAsyncThunk(
  'login/loginApi',
  async (credentials, thunkApi) => {
    const { dispatch } = thunkApi;
    dispatch(setLoginLoading());

    const [data, error] = await userService.login({
        ...credentials,
        walletAddress: credentials?.walletAddress?.toLowerCase()
    })
    if (data && data?.accessToken) {
        localStorage.setItem('accessToken', data?.accessToken)
        localStorage.setItem('userId', data?.userId)

        dispatch(setLoginSuccess(data));
        return
    }

    if (error) {
        dispatch(setLoginFailure(error.response.status));
    }
  },
);

export const logout = createAsyncThunk(
  'login/logoutApi',
  async ({ disconnect, connectors }, thunkApi) => {
    const { dispatch } = thunkApi;
    const isWc = localStorage.getItem('wagmi.recentConnectorId') === `"${WALLETCONNECT}"`;
    const isMetaMask = localStorage.getItem('wagmi.recentConnectorId') === `"${METAMASK}"`;

    if (!isWc && !isMetaMask) {
        return;
    }

    dispatch(logoutWallet());
    disconnect({ connector: isWc ? getWalletConnect(connectors) : getMetaMask(connectors) }, {
      onSuccess: () => {
          localStorage.removeItem('accessToken');
          localStorage.removeItem('userId');
          localStorage.removeItem('AddressServiceFee');
          localStorage.removeItem('networkType');
      }
  });
  },
);

export const loginSlice = createSlice({
  name: 'login',
  initialState,
  reducers: {
    setLoginLoading: (state) => {
      state.loading = true;
    },
    setLoginSuccess: (state, data) => {
      state.loading = false;
      state.loginData = data.payload;
    },
    setLoginFailure: (state, data) => {
      state.loading = false;
      state.error = data.payload;
    },
    toggleBtnCreate: (state) => {
      state.isClickBtnCreate = !state.isClickBtnCreate;
    },
    signWithCreate: (state) => {
      state.isSignWithCreate = !state.isSignWithCreate;
    },
    setLoadingConnectWallet: (state) => {
      state.isLoadingConnectWallet = true;
    },
    setCloseLoadingConnectWallet: (state) => {
      state.isLoadingConnectWallet = false;
    },
  },
});

export const { setCloseLoadingConnectWallet, setLoadingConnectWallet, setLoginFailure, setLoginLoading, setLoginSuccess, signWithCreate, toggleBtnCreate } = loginSlice.actions;

export default loginSlice.reducer;
