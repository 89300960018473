import React from 'react'
import { useContext, useEffect } from 'react'
import { Button } from 'antd'
import PropTypes from 'prop-types'

import 'styles/variables.scss'
import './style.scss'
import { ThemeContext } from 'ThemeProvider'

const ButtonCustom = props => {
    const { theme } = useContext(ThemeContext)
    const {
        className,
        disabled,
        htmlType,
        icon,
        iconLeft,
        iconRight,
        loading,
        onClick,
        children,
        color,
        shape,
        size,
        fullWidth = false,
        innerRef,
        isBuble,
        variant,
        ...restProps
    } = props

    let overrideClassNames = ['ant-btn-custom']

    const renderContent = () => {
        return (
            <>
                {typeof iconLeft !== 'undefined' ? iconLeft : ''}
                {children}
                {typeof iconRight !== 'undefined' ? iconRight : ''}
            </>
        )
    }

    const initArgs = () => {
        switch (color) {
            case 'blue':
                overrideClassNames.push('ant-btn-color-blue');
                break;
            case 'white':
                overrideClassNames.push('ant-btn-color-white');
                break;
            case 'gray':
                overrideClassNames.push('ant-btn-color-gray');
                break;
            case 'red':
                overrideClassNames.push('ant-btn-color-red');
                break;
            case 'dark':
                overrideClassNames.push('ant-btn-color-dark');
                break;
            case 'grad':
                overrideClassNames.push('ant-btn-gradien');
                break;
            default:
        }

        switch (size) {
            case 'lg':
                overrideClassNames.push('ant-btn-lg');
                break;
            case 'md':
                overrideClassNames.push('ant-btn-md');
                break;
            case 'small':
                overrideClassNames.push('ant-btn-small');
                break;
            case 'xs':
                overrideClassNames.push('ant-btn-xs');
                break;
            default:
        }

        if (shape === 'square') {
            overrideClassNames.push('ant-btn-shape-square')
        }

        if (fullWidth) {
            overrideClassNames.push('ant-btn-fullwidth')
        }

        if (isBuble) {
            overrideClassNames.push('ant-btn-buble')
        }
        if (children === 'Clear' && theme === 'dark-theme') {
            overrideClassNames.push('btn-clear-dark')
        }
        if (className) {
            overrideClassNames.push(className)
        }

        switch (variant) {
            case 'outline':
                overrideClassNames.push('ant-btn-outline');
                break;
            case 'outline-sub':
                overrideClassNames.push('ant-btn-outline-sub');
                break;
            case 'gradient':
                overrideClassNames.push('ant-btn-gradient');
                break;
            default:
                overrideClassNames.push('ant-btn-ghost');
                break;
        }
    }

    initArgs()

    useEffect(() => {
        let myBtns = document.querySelectorAll('.ant-btn-buble')
        myBtns.forEach(btn => {
            btn.addEventListener('click', () => {
                myBtns.forEach(b => b.classList.remove('animate'))
                btn.classList.add('animate')
                setTimeout(() => {
                    btn.classList.remove('animate')
                }, 700)
            })
        })
    }, [overrideClassNames])

    return (
        <Button
            className={overrideClassNames.join(' ')}
            disabled={disabled}
            htmlType={htmlType}
            icon={icon}
            loading={loading}
            onClick={onClick}
            ref={innerRef}
            {...restProps}
        >
            {renderContent()}
        </Button>
    )
}

ButtonCustom.propTypes = {
    children: PropTypes.any,
    color: PropTypes.string,
    className: PropTypes.string,
    style: PropTypes.any,
    disabled: PropTypes.bool,
    htmlType: PropTypes.string,
    icon: PropTypes.node,
    iconLeft: PropTypes.node,
    iconRight: PropTypes.node,
    loading: PropTypes.any,
    onClick: PropTypes.func,
    shape: PropTypes.string,
    size: PropTypes.string,
    fullWidth: PropTypes.bool
}

export default ButtonCustom
