import {createSlice} from '@reduxjs/toolkit';

const initialState = {
  open: false
};

export const metamaskSlice = createSlice({
  name: 'metamask',
  initialState,
  reducers: {
    openMetamaskModal: (state) => {
      state.open = true;
    },
    closeMetamaskModal: (state) => {
      state.open = false;
    }
  },
});

export const {closeMetamaskModal, openMetamaskModal} = metamaskSlice.actions;

export default metamaskSlice.reducer;
