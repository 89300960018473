import { Card, Divider } from 'antd'
import React, { useCallback } from 'react'
import { useHistory } from 'react-router-dom'

import { ButtonCustom } from 'components/common'

export const CreateTypeCard = props => {
    const { item } = props
    const history = useHistory()

    const handleClickCreate = useCallback(() => {
        history.push('/create/' + item.route)
    }, [history, item.route])

    return (
        <Card className="create-type-content__card">
            <div className="d-flex justify-content-center create-type-content-holder align-items-center">
                <div className="img-container mt-3">
                    <img src={item.icon} alt="type" />
                </div>
                <h3 className="mt-3">{item.title}</h3>
                <p className="mt-3">{item.describe}</p>
                <Divider />
                <ButtonCustom color="blue" className="btn-grad mt-3 br-12" onClick={handleClickCreate}>
                    {item.button}
                </ButtonCustom>
            </div>
        </Card>
    )
}
