import React, { useCallback, useContext, useEffect, useState } from 'react'
import ReactLoading from 'react-loading'
import { useDispatch, useSelector } from 'react-redux'
import { Card, Tooltip, Input } from 'antd'
import { debounce } from 'lodash'
import { useTranslation } from 'react-i18next'
import { Link, useHistory } from 'react-router-dom'

import nftService from 'service/nftService'
import * as message from 'utils/custom-message'
import { shortenAddress, shortenNameNoti } from 'utils/func'
import SearchIcon from 'assets/icons/search-icon-mobile-blue.svg'
import ClosedIcon from 'assets/icons/closed-icon.svg'
import SearchItemImage from './SearchItemImage'
import { CustomAvatar, NoData } from 'components/common'
import { roundingNumber } from 'utils/formatNumber'
import { ThemeContext } from '../../../../../ThemeProvider'
import noItem from 'assets/images/notfound.svg'
import '../style.scss'
import { setKeySearch, setResultSearch } from 'store/search.slice'

const SearchHeaderBarMobile = ({ onClose, isSearchbarOnHeaderMobile, onCloseSearchbar }) => {
    const { theme } = useContext(ThemeContext)
    const { t } = useTranslation()
    const dispatch = useDispatch()
    const history = useHistory()
    const system = useSelector(state => state?.system)
    const { keySearch, resultSearch } = useSelector(state => state.search)

    const [keyword, setKeyword] = useState(keySearch)
    const [result, setResult] = useState([])
    const [loading, setLoading] = useState(false)
    const [isDisplayResult, setIsDisplayResult] = useState(false)

    const handleSearch = async keyword => {
        setLoading(true)
        const [data, err] = await nftService.searchAll({ keyword })
        if (data) {
            setResult(data)
            setIsDisplayResult(true)
            if (keyword) {
                dispatch(setResultSearch(data));
            }
        }
        if (err) {
            message.error(t('error.message.somethingWentWrong'))
        }
        setLoading(false)
    }

    const hanldeChangeSearch = e => {
        setKeyword(e.target.value)
        debounceSearch(e.target.value)
        dispatch(setKeySearch(e.target.value));
    }

    const debounceSearch = debounce(function (value) {
        if (!value) {
            handleCloseSearch()
        } else handleSearch(value)
    }, 300)

    const handleCloseSearch = useCallback(() => {
        setIsDisplayResult(false)
        setResult({})
        setKeyword(null)
        dispatch(setResultSearch({}));
        dispatch(setKeySearch(null));
    }, [dispatch])

    const redirectSearchResult = useCallback(() => {
        history.push(`/search/${keyword}`)
        onClose()
    }, [history, keyword, onClose])

    const handleKeyDown = useCallback(
        e => {
            if (e.key === 'Enter') {
                redirectSearchResult()
            }
        },
        [redirectSearchResult]
    )

    useEffect(() => {
        if (resultSearch) {
            setResult(resultSearch)
        }
    }, [resultSearch])

    return (
        <div
            className={`search-mobile ${theme === 'dark-theme' ? 'dark-search-mobile' : ''}`}
            style={{ width: isSearchbarOnHeaderMobile ? '88vw' : '100%' }}
        >
            <div className='search-mobile__container' style={{ display: 'flex', alignItems: 'center' }}>
                {isSearchbarOnHeaderMobile && (
                    <div className="circle" onClick={onCloseSearchbar}>
                        <i className="ri-close-circle-line close-search-btn"></i>
                    </div>
                )}
                <div className="search-mobile__header">
                    <Input
                        placeholder={t('header.searchInput')}
                        onChange={hanldeChangeSearch}
                        className="search-mobile__header__input"
                        autoFocus
                        value={keyword}
                        onKeyDown={handleKeyDown}
                    />
                    <i className="ri-search-line icon-search"></i>
                    {keyword && <img className="icon-closed" src={ClosedIcon} alt="" onClick={handleCloseSearch} />}
                </div>
            </div>

            <div
                className={
                    result?.nfts?.items?.length > 0 ||
                    result?.collections?.items?.length > 0 ||
                    result?.accounts?.items?.length > 0 ||
                    isDisplayResult
                        ? `dropdown-search-header ${
                              isSearchbarOnHeaderMobile ? 'dropdown-search-header__unset-bottom' : ''
                          }`
                        : ''
                }
            >
                <div
                    style={{
                        height: isSearchbarOnHeaderMobile ? '100vh' : 'unset'
                    }}
                >
                    {loading && !system?.loading ? (
                        <div style={{ padding: 24 }}>
                            <div className="d-flex align-items-center justify-content-center">
                                <ReactLoading
                                    className="btn-load d-flex justify-content-center align-items-center"
                                    type="spinningBubbles"
                                    color="#002979"
                                />
                            </div>
                        </div>
                    ) : !keyword ? null : result &&
                      (result?.nfts?.items?.length > 0 ||
                          result?.collections?.items?.length > 0 ||
                          result?.accounts?.items?.length > 0) ? (
                        <>
                            <div className="result-search-header">
                                <div className="title">Collections</div>
                                <div onClick={redirectSearchResult} className="mobile-view-all">
                                    All results
                                </div>
                            </div>
                            {result?.collections?.items?.slice(0, 3)?.map((item, index) => (
                                <div className="list-items-search" key={`collection_${index}`}>
                                    <Link
                                        to={`/collection/${item?.shortUrl || item?.id || item?.address}`}
                                        onClick={onClose}
                                    >
                                        <Card>
                                            <div className="user-avatar">
                                                <CustomAvatar verified={!!item?.isVerify} src={item?.bannerUrl} />
                                            </div>
                                            <div className="card-search-body">
                                                <Tooltip overlayClassName="blue" placement="topLeft" title={item?.name}>
                                                    <p className="item-name">{shortenNameNoti(item?.name, 25)}</p>
                                                </Tooltip>
                                                <p className="gray-text">
                                                    by{' '}
                                                    {item?.creator?.shortUrl
                                                        ? `@${item?.creator?.shortUrl}`
                                                        : shortenAddress(item?.creator?.walletAddress)}
                                                </p>
                                            </div>
                                        </Card>
                                    </Link>
                                </div>
                            ))}

                            <div className="title">Items</div>

                            {result?.nfts?.items?.slice(0, 2).map((item, index) => (
                                <div key={index} className="list-items-search">
                                    <Link to={`/nft/${item?.collection?.id}:${item?.tokenId}`} onClick={onClose}>
                                        <Card>
                                            <div className="card-search-avatar">
                                                <SearchItemImage item={item} />
                                            </div>
                                            <div className="card-search-body">
                                                <Tooltip
                                                    overlayClassName="blue"
                                                    placement="topLeft"
                                                    title={item?.title}
                                                >
                                                    <p className="item-name">{shortenNameNoti(item?.title, 25)}</p>
                                                </Tooltip>
                                            </div>
                                        </Card>
                                    </Link>
                                </div>
                            ))}
                            <div className="title">Users</div>
                            {result?.accounts?.items?.slice(0, 2)?.map((item, index) => (
                                <div className="list-items-search" key={`users_${index}`}>
                                    <Link
                                        to={item?.shortUrl ? `/${item?.shortUrl}` : `/user/${item?.walletAddress}`}
                                        onClick={onClose}
                                    >
                                        <Card>
                                            <div className="user-avatar">
                                                <CustomAvatar verified={item?.isVerify} src={item?.avatarImg} />
                                            </div>
                                            <div className="card-search-body">
                                                <Tooltip
                                                    overlayClassName="blue"
                                                    placement="topLeft"
                                                    title={item?.username}
                                                >
                                                    <p className="item-name">{item?.username}</p>
                                                </Tooltip>
                                                <p className="gray-text">
                                                    {roundingNumber(item?.followers?.length)} followers
                                                </p>
                                            </div>
                                        </Card>
                                    </Link>
                                </div>
                            ))}
                        </>
                    ) : (
                        <NoData />
                    )}
                </div>
            </div>
        </div>
    )
}

export default SearchHeaderBarMobile
