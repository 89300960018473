import React, { useEffect, useState, useMemo } from 'react'
import { useHistory } from 'react-router-dom'
import { useSelector } from 'react-redux'
import Modal from 'antd/lib/modal/Modal'

import CheckedIcon from 'assets/icons/checked-outline-icon.svg'
import { genTokenIdForMainStore, handleUpdateRoyaltyNFT } from 'blockchain/utils'
import nftService from 'service/nftService'
import * as message from 'utils/custom-message'
import './style.scss'
import { ERROR_CODE_USER_DENIED_METAMASK, PROCESS_STATUS } from 'constants/index'
import { CONTRACT_ADDRESS } from 'constants/envs'
import { useChainId } from 'wagmi'
import { useTranslation } from 'react-i18next'

const FlowStepNotForSale = ({
    onClose,
    data,
    listOfCollections,
    uploadFile,
    previewFile,
    idCollectionSelected,
    ...restProps
}) => {
    const {t} = useTranslation();
    const history = useHistory()
    const chainId = useChainId();
    const [isCreateCollectibleStatus, setIsCreateCollectibleStatus] = useState(PROCESS_STATUS.NOT_RUN)
    const [isSettingRoyaltyNFTStatus, setIsSettingRoyaltyNFTStatus] = useState(PROCESS_STATUS.NOT_RUN)
    const { profile } = useSelector(state => state.user)

    const collection = useMemo(
        () => listOfCollections.find(c => c.address === data.collectionAddress),
        [listOfCollections, data.collectionAddress]
    )

    const createNFTNotForSale = async () => {
        const newData = { ...data }

        const tokenId = await genTokenIdForMainStore(profile.walletAddress, newData.numberOfCopies)

        if (Number(data.royalty) !== Number(collection.royalty)) {
            setIsSettingRoyaltyNFTStatus(PROCESS_STATUS.PENDING)
            const [, errSetRoyalty] = await handleUpdateRoyaltyNFT(
                {
                    tokenId,
                    collectionAddress: collection.address,
                    fee: Number(data.royalty),
                    userAddress: profile.walletAddress
                },
                CONTRACT_ADDRESS[data.networkType].setRoyalty,
                chainId,
                collection.networkType
            )
            if (errSetRoyalty) {
                if (errSetRoyalty?.code === ERROR_CODE_USER_DENIED_METAMASK) {
                    message.error(t('error.message.declinedActionWallet'))
                } else {
                    message.error(t('error.message.somethingWentWrong'))
                }
                onClose()
                return
            }

            setIsSettingRoyaltyNFTStatus(PROCESS_STATUS.FULLFIL)
        }

        setIsCreateCollectibleStatus(PROCESS_STATUS.PENDING)
        const [createNFTData, errCreateNFT] = await nftService.createNft({
            ...newData,
            tokenId,
            maxQuantity: newData.numberOfCopies
        })
        if (errCreateNFT) {
            onClose()
            return message.error(t('error.message.creatingCollectibleFailed'), errCreateNFT)
        }
        const paramsUploadImage = {
            imgFile: uploadFile,
            nftId: createNFTData?.id
        }

        let previewUrl = ''
        let mainUrl = ''
        if (previewFile) {
            const [resPreview] = await nftService.getUploadPreviewVideo({
                nftId: createNFTData?.id,
                imgFile: previewFile
            })
            previewUrl = resPreview.path
            const previewImgId = resPreview?.path?.replace('input', 'output')
            await nftService.putNftImage({
                imgFile: previewFile,
                nftId: createNFTData?.id,
                uploadUrl: resPreview?.upload_url
            })
            if (uploadFile?.type === 'audio/mpeg') {
                const [resAudio] = await nftService.getMp3PresignUrl({
                    ...paramsUploadImage,
                    previewImgId
                })
                await nftService.putNftImage({
                    ...paramsUploadImage,
                    previewImgId,
                    uploadUrl: resAudio?.upload_url
                })
                mainUrl = resAudio.path
            } else {
                const [resVideo] = await nftService.getUploadVideo({
                    ...paramsUploadImage,
                    previewImgId
                })
                await nftService.putNftImage({
                    ...paramsUploadImage,
                    previewImgId,
                    uploadUrl: resVideo?.upload_url
                })
                mainUrl = resVideo.path
            }
        } else {
            const [resPresign] = await nftService.getUploadGIFImgUrl(paramsUploadImage)

            await nftService.putNftImage({
                nftId: createNFTData?.id,
                imgFile: uploadFile,
                uploadUrl: resPresign?.upload_url
            })
            mainUrl = resPresign.path
        }
        await nftService.updateNftMedia({
            id: createNFTData.id,
            nftUrl: mainUrl,
            nftImagePreview: previewUrl
        })
        setIsCreateCollectibleStatus(PROCESS_STATUS.FULLFIL)

        history.push(`/nft/${idCollectionSelected || data?.collectionAddress}:${tokenId}`)
        message.success(t('success.message.collectibleCreatedSuccessfully'))
        onClose()
    }

    useEffect(() => {
        createNFTNotForSale()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <Modal className="flow-step-not-for-sale" footer={null} centered visible={true} {...restProps}>
            <div className="not-for-sale_header">
                <span className="not-for-sale_title">{t('createNFT.followStep')}</span>
            </div>
            <div className="create-nft-steps">
                {Number(data.royalty) !== Number(collection.royalty) && (
                    <div className="create-nft-step">
                        <div className="create-nft-loading">
                            {isSettingRoyaltyNFTStatus === PROCESS_STATUS.PENDING && (
                                <div className="create-nft-step_loading" />
                            )}
                            {isSettingRoyaltyNFTStatus === PROCESS_STATUS.FULLFIL && (
                                <img className="create-nft-step_icon" src={CheckedIcon} alt="checked-outline-icon" />
                            )}
                        </div>
                        <div className="create-nft-step_content">
                            <span className="create-nft-step_content__title">{t('createNFT.step.setting.title')}</span>
                            <span className="create-nft-step_content__desc" style={{ marginBottom: '10px' }}>
                                {t('createNFT.step.setting.desc')}
                            </span>
                        </div>
                    </div>
                )}

                <div className="create-nft-step">
                    <div className="create-nft-loading">
                        {isCreateCollectibleStatus === PROCESS_STATUS.PENDING && (
                            <div className="create-nft-step_loading" />
                        )}
                        {isCreateCollectibleStatus === PROCESS_STATUS.FULLFIL && (
                            <img className="create-nft-step_icon" src={CheckedIcon} alt="checked-outline-icon" />
                        )}
                    </div>
                    <div className="create-nft-step_content">
                        <span className="create-nft-step_content__title">{t('createNFT.step.create.title')}</span>
                        <span className="create-nft-step_content__desc" style={{ marginBottom: '10px' }}>
                            {t('createNFT.step.create.desc')}
                        </span>
                    </div>
                </div>
            </div>

            <p className="warning">
                {t('createNFT.warning')}
            </p>
        </Modal>
    )
}

export default FlowStepNotForSale
