import React, { useState, useEffect, useMemo, useCallback } from 'react'
import { useSelector } from 'react-redux'
import { Row, Col, Checkbox, Modal, Form } from 'antd'
import BigNumber from 'bignumber.js'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { useChainId } from 'wagmi'

import { ButtonCustom, InputCustom, SelectCustom } from 'components/common'
import { SvgIcon } from 'components/modules'
import * as message from 'utils/custom-message'
import { roundNumber } from 'utils/func'
import { convertBigNumberValueToNumber } from 'blockchain/ether'
import { isUserApprovedERC20, handleUserApproveERC20, getERC20AmountBalance, buildDataBid } from 'blockchain/utils'
import { ERROR_CODE_USER_DENIED_METAMASK, FILE_EXTENSION, SCREEN_SIZE, DECIMAL_TOKENS } from 'constants/index'
import { useResizeWindow } from 'utils/hook'
import { CONTRACT_ADDRESS } from 'constants/envs'
import { getPrice } from 'service/getPrice'
import { exportFileFormat } from 'utils/file'
import auctionService from 'service/auctionService'
import './style.scss'
import { convertImage } from 'utils/image'
import DefaultImage from 'assets/images/default.svg'

const PAYMENT_OPTIONS = {
    ethereum: [
        { value: 'usdt', label: 'USDT' },
        { value: 'weth', label: 'WETH' }
    ],
    bsc: [
        { value: 'sp', label: 'SP' },
        { value: 'wbnb', label: 'WBNB' },
        { value: 'busd', label: 'BUSD' }
    ],
    polygon: [{ value: 'weth', label: 'WETH' }]
}

const MakeAnOfferModal = ({ onCloseModal, openOfferSuccessModal }) => {
    const { t } = useTranslation()
    const chainId = useChainId();
    const { id, collection, title, tokenId, nftImagePreview, nftUrl, nftSales } = useSelector(
        state => state.collectible.data
    )
    const [isCheckTerm, setIsCheckTerm] = useState(false)
    const { walletAddress } = useSelector(state => state.user?.profile)
    const { MARKET_FEE } = useSelector(state => state.system)
    const [isEnoughBalance, setIsEnoughBalance] = useState(false)
    const [price, setPrice] = useState(0)
    const [isMobile] = useResizeWindow(SCREEN_SIZE.mobile)
    const [tokenType, setTokenType] = useState(PAYMENT_OPTIONS[collection.networkType][0].value)
    const [isApprovingOfferToken, setIsApprovingOfferToken] = useState(false)
    const [isMakingOffer, setIsMakingOffer] = useState(false)
    const [userBalance, setUserBalance] = useState()
    const [isLessThanFloorPrice, setIsLessThanFloorPrice] = useState(false)
    const [form] = Form.useForm()

    const onCheckTerm = () => {
        setIsCheckTerm(!isCheckTerm)
    }

    const nftSale = useMemo(() => {
        if (nftSales && nftSales[0]) {
            return nftSales[0]
        }
    }, [nftSales])

    const total = useMemo(() => {
        return BigNumber(price * (1 + MARKET_FEE) * Number(1))
            .decimalPlaces(8)
            .toString()
    }, [price, MARKET_FEE])

    const createOffer = async () => {
        setIsMakingOffer(true)

        setIsApprovingOfferToken(true)
        const isApprovedSuccess = await handleApproveOfferToken()
        setIsApprovingOfferToken(false)
        if (!isApprovedSuccess) return

        const [dataBuildParams, errorBuildParams] = await buildDataBid(
            {
                bidderAddress: walletAddress,
                collectionAddress: collection?.address,
                collectionId: collection?.id,
                price,
                tokenType,
                nftId: id,
                quantity: 1,
                tokenId,
                networkType: collection.networkType,
                isExternalCollection: collection.isImport,
                nftType: collection.type
            },
            chainId
        )
        if (errorBuildParams) {
            onCloseModal()
            if (errorBuildParams?.code === ERROR_CODE_USER_DENIED_METAMASK) {
                message.error(t('error.message.declinedActionWallet'))
            } else {
                message.error(t('error.message.cannotContinueAction'))
            }
            return
        }

        const [, errorMetaData] = await auctionService.makeOffer({
            price: Number(price),
            metadata: dataBuildParams,
            currencyToken: tokenType,
            quantity: 1,
            nftId: id,
            nftSaleId: nftSale.id
        })
        if (errorMetaData) {
            return message.error(t('error.message.placeBidFailedReload'))
        }

        onCloseModal()
        openOfferSuccessModal()
    }

    const handleApproveOfferToken = async () => {
        const isApproved = await isUserApprovedERC20(
            CONTRACT_ADDRESS[collection.networkType].proxy,
            CONTRACT_ADDRESS[collection.networkType][tokenType],
            walletAddress,
            chainId,
            collection.networkType
        )
        if (isApproved) {
            return true
        }
        const [, approveError] = await handleUserApproveERC20(
            CONTRACT_ADDRESS[collection.networkType].proxy,
            CONTRACT_ADDRESS[collection.networkType][tokenType],
            chainId,
            collection.networkType,
        )
        if (approveError) {
            if (approveError?.code === ERROR_CODE_USER_DENIED_METAMASK) {
                onCloseModal()
                message.error(t('error.message.declinedActionWallet'))
            }
            return false
        }
        return true
    }

    const getDisplayImage = () => {
        const fileExtension = exportFileFormat(nftUrl)
        if (fileExtension === FILE_EXTENSION.MP4 || fileExtension === FILE_EXTENSION.MPEG)
            return convertImage(nftImagePreview)
        return convertImage(nftUrl)
    }

    const priceValidator = useCallback(
        async (rule, value) => {
            if (value && Number(value) > Number(userBalance))
                return Promise.reject(t('error.message.priceWithBalance'))
            if (value && Number(value) === 0) return Promise.reject(t('error.message.priceZero'))

            const priceRate = await getPrice(tokenType.toUpperCase())

            if (value && Number(collection.floorPrice) && Number(value) * priceRate < Number(collection.floorPrice)) {
                setIsLessThanFloorPrice(true)
                return Promise.reject(t('error.message.priceFloorPrice', { floorPrice: Number(collection.floorPrice) }))
            }
            setIsLessThanFloorPrice(false)
            return Promise.resolve()
        },
        [tokenType, collection.floorPrice, userBalance]
    )

    const onChangeValueInput = (value, name, decimal = 6, cb) => {
        if (value === '.') return form.setFieldsValue({ [name]: null })
        const number = value
            .toString()
            .replace(/[^0-9.]/g, '')
            .replace(/(\..*?)\..*/g, '$1')
        if (number.includes('.')) {
            const numString = number.toString().split('.')
            if (numString[1].length > decimal) {
                cb(number.substring(0, numString[0].length + decimal + 1))
                return form.setFieldsValue({ [name]: number.substring(0, numString[0].length + decimal + 1) })
            }
        }

        cb(number)
        form.setFieldsValue({ [name]: number })
    }

    useEffect(() => {
        const checkBalance = async walletAddress => {
            const [amount] = await getERC20AmountBalance(
                CONTRACT_ADDRESS[collection.networkType][tokenType],
                walletAddress,
                chainId,
                collection.networkType
            )
            const balance = convertBigNumberValueToNumber(amount, DECIMAL_TOKENS[collection.networkType][tokenType])

            setUserBalance(balance)
            setIsEnoughBalance(Number(balance) > parseFloat(price * (1 + MARKET_FEE)))
        }
        if (walletAddress) {
            checkBalance(walletAddress)
        }
    }, [walletAddress, tokenType, price, collection.networkType, chainId, MARKET_FEE])

    useEffect(() => {
        form.validateFields(['price'])
    }, [userBalance, form])

    return (
        <Modal
            visible={true}
            onOk={onCloseModal}
            onCancel={onCloseModal}
            footer={null}
            closable={false}
            className="bid-form-modal"
        >
            <Form form={form}>
                <div className="bid-form-modal-container">
                    <p className="checkout-title">{t('nftDetail.makeAnOffer')}</p>
                    <Col className="checkout-detail">
                        <div className="checkout-detail__header">
                            <p className="bold-text gray">{t('nftDetail.item')}</p>
                            <p>
                                {`${t('common.walletBalance')}: ${isNaN(userBalance)
                                    ? t('common.loading')
                                    : `${roundNumber(userBalance)} ${tokenType.toUpperCase()}`
                                    }`}
                            </p>
                        </div>

                        {isMobile ? (
                            <div className="nft-info-mobile">
                                {nftImagePreview || nftUrl ? (
                                    <img alt="asset" src={getDisplayImage()} className="asset-img" />
                                ) : (
                                    <img alt="icon" src={DefaultImage} />
                                )}
                                <div>
                                    <p>{title}</p>
                                    <div>
                                        <p className="collection-text">
                                            <span>{`${t('nftDetail.collection')} `}</span>
                                            <Link
                                                to={`/collection/${collection?.shortUrl || collection?.id || collection?.address
                                                    }`}
                                            >
                                                {collection?.name}
                                            </Link>
                                        </p>
                                    </div>
                                </div>
                            </div>
                        ) : (
                            <Row align="center" justify="space-between">
                                <div className="nft-info">
                                    {nftImagePreview || nftUrl ? (
                                        <img alt="asset" src={getDisplayImage()} className="asset-img" />
                                    ) : (
                                        <img alt="icon" src={DefaultImage} />
                                    )}
                                    <div>
                                        <p className="collection-text">
                                            <span>{`${t('nftDetail.collection')} `}</span>
                                            <Link
                                                to={`/collection/${collection?.shortUrl || collection?.id || collection?.address
                                                    }`}
                                            >
                                                {collection?.name}
                                            </Link>
                                        </p>
                                        <p>{title}</p>
                                    </div>
                                </div>
                            </Row>
                        )}

                        <Row align="center" justify="space-between">
                            <p className="light-text label-input-form gray">{t('nftDetail.price')}</p>
                            <div className="price-container">
                                <Form.Item
                                    rules={[
                                        {
                                            required: true,
                                            message: t('error.message.priceRequired')
                                        },
                                        { validator: priceValidator }
                                    ]}
                                    initialValue={price}
                                    name="price"
                                    validateTrigger="onBlur"
                                    className="price-input"
                                >
                                    <InputCustom
                                        onChange={e => {
                                            onChangeValueInput(e.target.value, 'price', 6, p => setPrice(p))
                                        }}
                                        value={price}
                                        placeholder={t('nftDetail.enterPriceValue')}
                                    />
                                </Form.Item>
                                <Form.Item
                                    name="tokenType"
                                    className="select-currency"
                                    initialValue={PAYMENT_OPTIONS[collection.networkType][0].value}
                                >
                                    <SelectCustom
                                        options={PAYMENT_OPTIONS[collection.networkType]}
                                        onChange={e => setTokenType(e)}
                                    />
                                </Form.Item>
                            </div>
                        </Row>
                        <Row align="center" justify="space-between" className="border-top">
                            <p className="light-text gray">{t('nftDetail.subtotal')}</p>
                            <div>
                                <p className="light-text">{`${BigNumber(price || 0)
                                    .decimalPlaces(8)
                                    .toFormat()
                                    .toString()} ${tokenType.toUpperCase()}`}</p>
                            </div>
                        </Row>
                        <Row align="center" justify="space-between">
                            <p className="light-text gray">{t('nftDetail.serviceFee')}</p>
                            <div>
                                <p className="light-text">{`${BigNumber(price * MARKET_FEE)
                                    .decimalPlaces(8)
                                    .toFormat()
                                    .toString()} ${tokenType.toUpperCase()}`}</p>
                            </div>
                        </Row>
                        <Row align="center" justify="space-between">
                            <p className="bold-text gray">{t('nftDetail.total')}</p>
                            <div>
                                <p>{`${BigNumber(price * (1 + MARKET_FEE))
                                    .decimalPlaces(8)
                                    .toFormat()
                                    .toString()} ${tokenType.toUpperCase()}`}</p>
                            </div>
                        </Row>
                        <Row align="center" justify="space-between">
                            <div className="term-of-service">
                                <Checkbox checked={isCheckTerm} onChange={onCheckTerm} />
                                <span className="term-of-service-text">
                                    <span>{t('nftDetail.agree')}</span>
                                    <a href={`https://smapocke.com/TermsofUse`} target="_blank" rel="noreferrer">
                                        {t(`nftDetail.termOfService`)}
                                    </a>
                                </span>
                            </div>
                        </Row>
                    </Col>

                    {!isEnoughBalance && <p className="error-balance">{t('error.message.notEnoughBalance')}</p>}

                    <ButtonCustom
                        color="blue"
                        disabled={
                            !isCheckTerm ||
                            !isEnoughBalance ||
                            isMakingOffer ||
                            !Number(total) ||
                            isApprovingOfferToken ||
                            isLessThanFloorPrice
                        }
                        onClick={createOffer}
                        loading={isMakingOffer}
                    >
                        {isApprovingOfferToken ? (
                            <span>{t('common.approvingToken', { tokenType: tokenType.toUpperCase() })}</span>
                        ) : (
                            <span>{t('common.makeOffer')}</span>
                        )}
                    </ButtonCustom>
                </div>
            </Form>
        </Modal>
    )
}

export default MakeAnOfferModal
