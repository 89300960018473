import { configureStore, combineReducers } from '@reduxjs/toolkit';
import storage from 'redux-persist/lib/storage';
import { persistStore, persistReducer } from 'redux-persist';

import alert from './alert.slice';
import category from './category.slice';
import collectible from './collectible.slice';
import login from './login.slice';
import collection from './collection.slice';
import metamask from './metamask.slice';
import modal from './modal.slice';
import search from './search.slice';
import signup from './signup.slice';
import system from './system.slice';
import user from './user.slice';

const persistConfig = {
  key: 'root',
  storage,
  whitelist: ['user'],
};

const userPersistConfig = {
  key: 'user',
  storage,
  blacklist: ['previewAvatar']
};

const reducers = combineReducers({
  alert,
  categories: category,
  collectible,
  login,
  collection,
  metamask,
  modal,
  search,
  signup,
  system,
  user: persistReducer(userPersistConfig, user),
});

const rootReducer = persistReducer(persistConfig, reducers);

export const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) => getDefaultMiddleware({ serializableCheck: false }),
});

export const persistor = persistStore(store);