import React, { useCallback, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { SwitchCustom } from 'components/common'
import './style.scss'

const Label = ({
    title,
    children,
    description,
    subDescription,
    className,
    switchable,
    onSwichAble,
    isUnlockableContent,
    setSwitch
}) => {
    const labelClass = classNames({ 'label-custom_wrapper': true, [className]: !!className })

    const [isSwitch, setIsSwitch] = useState(true)

    const onSwitchChange = useCallback(
        value => {
            switchable && setIsSwitch(value)
            if (!value) {
                onSwichAble()
            }
        },
        [switchable, onSwichAble]
    )
    useEffect(() => {
        if (isUnlockableContent) {
            setIsSwitch(false)
        }
    }, [isUnlockableContent])

    useEffect(() => {
        setSwitch && setSwitch(isSwitch)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isSwitch])

    return (
        <div className={labelClass}>
            <div className="label-custom_title_wrap">
                <span className="label-custom_title">{title}</span>
                {switchable && <SwitchCustom onChange={onSwitchChange} value={isSwitch} />}
            </div>
            {subDescription && <span className="label-custom_subdesc">{subDescription}</span>}
            {/* {isSwitch
                ? children
                : React.cloneElement(children?.props?.children, {
                    disabled: true
                  })} */}
            {isSwitch && children}
            {description && <span className="label-custom_desc">{description}</span>}
        </div>
    )
}

Label.propTypes = {
    title: PropTypes.string.isRequired,
    description: PropTypes.any,
    subDescription: PropTypes.string,
    className: PropTypes.string,
    switchable: PropTypes.bool
}

export default Label
