import { ButtonCustom } from 'components/common'
import ComingSoon from 'components/common/coming-soon'
import DefaultLayout from 'components/layout/default-layout'
import { MAIN_TITLE } from 'constants/index'
import React, { useContext } from 'react'
import { Helmet } from 'react-helmet'

const ComingSoonPage = () => {
    return (
        <DefaultLayout>
            <Helmet>
                <meta charSet="utf-8" />
                <title>{`${MAIN_TITLE} - Coming Soon`}</title>
                <meta name="description" content={`${MAIN_TITLE} - Coming Soon`} />
            </Helmet>
            <ComingSoon />
        </DefaultLayout>
    )
}

export default ComingSoonPage
