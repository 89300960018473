import React, { useEffect, useState, useRef, useCallback, useMemo, useContext } from 'react'
import ReactLoading from 'react-loading'
import { useHistory, useLocation } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { useIntersection } from 'react-use'
import { Col, Row } from 'antd'
import { Helmet } from 'react-helmet'
import { useResizeWindow } from 'utils/hook'
import DefaultLayout from 'components/layout/default-layout'
import { CardActivity, FilterCustom, FilterMobileCustom } from 'components/modules'
import { ButtonCustom, NoData } from 'components/common'
import noItem from 'assets/images/notfound.svg'
import activityService from 'service/activityService'
import * as message from 'utils/custom-message'
import { MAIN_TITLE, SCREEN_SIZE } from 'constants/index'
import './style.scss'
import { ThemeContext } from 'ThemeProvider'
import { useMultiLanguageConstant } from 'hooks/useMultiLanguageConstant'
import { LIST_FILTER_ACTIVITY } from 'store/constants/activity'

const STEP = 10
const HEAD_TYPE = {
    all: 0,
    myActivity: 1,
    following: 2
}

const Activity = () => {
    const { t } = useTranslation()
    const history = useHistory()

    const { profile } = useSelector(state => state.user)
    const [isTablet] = useResizeWindow(SCREEN_SIZE.tablet)
    const [limit, setLimit] = useState(STEP)
    const [page] = useState(1)
    const [type, setType] = useState()
    const [dataActivities, setDataActivities] = useState([])
    const [total, setTotal] = useState(0)
    const [loading, setLoading] = useState(false)
    const [autoFetch, setAutoFetch] = useState(false)
    const system = useSelector(state => state?.system)
    const anchorEl = useRef(null)
    const location = useLocation()
    const [tab, setTab] = useState(location?.state?.headType || 0)
    const intersection = useIntersection(anchorEl, { root: null, rootMargin: '0px', threshold: 1 })
    const inView = intersection && intersection.isIntersecting && intersection.intersectionRatio >= 1
    const { listFilterActivity } = useMultiLanguageConstant();

    const isLoadMore = useMemo(() => {
        return dataActivities?.length < total
    }, [dataActivities?.length, total])

    const infinity = autoFetch && inView && isLoadMore

    const handleLoadMore = useCallback(
        () => {
            if (loading) {
                return
            }
            const next = limit + STEP
            const _activityCount = next > total ? total : limit + STEP
            setLimit(_activityCount)
        }, // eslint-disable-next-line react-hooks/exhaustive-deps
        [total, loading]
    )
    useEffect(() => {
        if (infinity) {
            handleLoadMore()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [infinity])

    const fetchDataActivities = useCallback(async () => {
        setLoading(true)
        const [res, err] = await activityService.getDataActivities({
            page,
            limit,
            ...(tab === HEAD_TYPE.myActivity && { walletAddress: profile?.walletAddress }),
            ...(tab === HEAD_TYPE.following && { isFollowing: 1 }),
            activityType: type
        })
        if (res) {
            setDataActivities(res?.items)
            setTotal(res?.meta.totalItem)
        }
        if (err) {
            message.error(t('error.message.somethingWentWrong'))
        }
        setLoading(false)
    }, [limit, page, tab, type, profile?.walletAddress])

    const handleSetType = id => {
        setType(id)
        window.scrollTo({ behavior: 'smooth', top: 0 })
    }

    const changeHeadTabType = tab => {
        setTab(tab)
        setType()
    }

    useEffect(() => {
        fetchDataActivities()
    }, [limit, page, tab, type, fetchDataActivities, profile.walletAddress])

    useEffect(() => {
        setLimit(10)
        if (isTablet) setAutoFetch(true)
        else setAutoFetch(false)
        setLoading(false)
    }, [type, tab, isTablet])

    useEffect(() => {
        if (!profile?.walletAddress) changeHeadTabType(HEAD_TYPE.all)
    }, [profile?.walletAddress])

    return (
        <DefaultLayout>
            <Helmet>
                <meta charSet="utf-8" />
                <title>{`${MAIN_TITLE} - Activity`}</title>
                <meta name="description" content={`${MAIN_TITLE} - Activity`} />
            </Helmet>
            <div className="activity-container">
                <div className="activity-container_header">
                    <div className="title">{t('activity.header')}</div>
                    <div className="filter-container">
                        <div className={`filter-container-head ${!profile?.walletAddress ? 'min-width-unset' : ''}`}>
                            <ButtonCustom
                                size="small"
                                className="tab-btn"
                                color={tab === HEAD_TYPE.all && 'blue'}
                                onClick={() => changeHeadTabType(HEAD_TYPE.all)}
                            >
                                {t('common.all')}
                            </ButtonCustom>
                            {profile?.walletAddress && (
                                <>
                                    <ButtonCustom
                                        size="small"
                                        className="tab-btn"
                                        color={tab === HEAD_TYPE.following && 'blue'}
                                        onClick={() => changeHeadTabType(HEAD_TYPE.following)}
                                    >
                                        {t('activity.following')}
                                    </ButtonCustom>
                                    <ButtonCustom
                                        size="small"
                                        className="tab-btn"
                                        color={tab === HEAD_TYPE.myActivity && 'blue'}
                                        onClick={() => changeHeadTabType(HEAD_TYPE.myActivity)}
                                    >
                                        {t('activity.myActivity')}
                                    </ButtonCustom>
                                </>
                            )}
                        </div>

                        <div className="filter-container-type">
                            <FilterCustom
                                handleSetActiveFilter={handleSetType}
                                type={type}
                                listFilter={listFilterActivity}
                            />
                        </div>
                    </div>
                </div>
                <div className="activity-container_content">
                    <div>
                        {dataActivities?.length > 0 ? (
                            <>
                                <ActivityContent
                                    data={dataActivities}
                                    loading={loading}
                                    handleLoadMore={handleLoadMore}
                                    total={total}
                                />
                                {isLoadMore && !autoFetch && (
                                    <div className="d-flex justify-content-center">
                                        <ButtonCustom
                                            color="blue"
                                            className="btn-load br-12"
                                            onClick={async () => {
                                                if (autoFetch) return
                                                await handleLoadMore()
                                                setAutoFetch(true)
                                            }}
                                        >
                                            {t('common.loadingMore')}
                                        </ButtonCustom>
                                    </div>
                                )}
                                {loading && !system?.loading && (
                                    <div className="d-flex justify-content-center">
                                        <ReactLoading className="btn-load" type="spinningBubbles" color="#002979" />
                                    </div>
                                )}
                            </>
                        ) : (
                            <NoData mainText={t('activity.noActivity')} subText={t('activity.noActivityContent')} onClick={() => history.push('/')}/>
                            // <NoActivity />
                        )}
                        <div
                            ref={el => {
                                anchorEl.current = el
                            }}
                        />
                    </div>
                </div>
            </div>
        </DefaultLayout>
    )
}

//User Activity
const ActivityContent = props => {
    const { data } = props

    return (
        <Row className="activity-content-row" gutter={[{ lg: 16, xl: 24, xxl: 24 }, 24]}>
            {data?.map((activity, index) => {
                return (
                    <Col key={index} xxl={12} xl={12} lg={12} md={24} sm={24} xs={24}>
                        <CardActivity className="card-activity" data={activity} />
                    </Col>
                )
            })}
        </Row>
    )
}

//Display if user has no activity
const NoActivity = () => {
    const { t } = useTranslation()
    const history = useHistory()
    const { theme } = useContext(ThemeContext)

    return (
        <div className="no-activity-container">
            <img className='no-activity-container-img' src={noItem} alt="" />
            <div className={`content-item-title ${theme === 'dark-theme' ? 'text-ac-dark' : ''}`}>
                {t('activity.noActivity')}
            </div>
            <div className={`content-item-description ${theme === 'dark-theme' ? 'text-ac-dark' : ''}`}>
                {t('activity.noActivityContent')}
            </div>
            <ButtonCustom color="blue" className="btn-browse" onClick={() => history.push('/')}>
                {t('activity.explore')}
            </ButtonCustom>
        </div>
    )
}

export default Activity
