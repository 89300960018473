export const START_DATE_OPTIONS = [
    { value: 1, label: 'Right after listing' },
    { value: 0, label: 'Pick specific date' }
]

export const EXPIRE_DATE_OPTIONS = [
    { value: 1, label: '1 day' },
    { value: 3, label: '3 days' },
    { value: 5, label: '5 days' },
    { value: 7, label: '7 days' },
    { value: 0, label: 'Pick specific date' }
]
