import { ButtonCustom } from 'components/common'
import DefaultLayout from 'components/layout/default-layout'
import { MAIN_TITLE } from 'constants/index'
import React, { useContext } from 'react'
import { Helmet } from 'react-helmet'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import { ThemeContext } from 'ThemeProvider'
import '../not-found/style.scss'

const Error = () => {
    const { theme } = useContext(ThemeContext)
    const history = useHistory()
    const { t } = useTranslation()

    return (
        <DefaultLayout>
            <Helmet>
                <meta charSet="utf-8" />
                <title>{`${MAIN_TITLE} - Error`}</title>
                <meta name="description" content={`${MAIN_TITLE} - Error`} />
            </Helmet>
            <div className="not-found-container">
                <h2 className={`not-found-title ${theme === 'dark-theme' ? 'text-ac-dark' : ''}`}>
                    {t('pageWrong_title')}
                </h2>
                <p className="not-found-description">{t('pageNotFound_description')}</p>
                <ButtonCustom onClick={() => history.goBack()} color="blue">
                    {t('common.goBack')}
                </ButtonCustom>
            </div>
        </DefaultLayout>
    )
}

export default Error
