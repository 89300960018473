import React from 'react';
import PropTypes, { any } from 'prop-types'
import { Radio } from 'antd';
import './style.scss';
import classNames from 'classnames';

const RadioGroupCustom = (props) => {
  const { className, value, onChange, options } = props;

  const onChangeRadio = (value) => onChange(value);

  return (
    <Radio.Group 
      value={value} 
      onChange={(event) => onChangeRadio(event.target.value)}
      className={classNames('radio-group-custom', className)}
    >
      {options.map((option) => (
          <Radio key={option.value} value={option.value}>
              {option.label}
          </Radio>
      ))}
    </Radio.Group>
  );
};

RadioGroupCustom.propTypes = {
  className: PropTypes.string,
  value: PropTypes.any,
  onChange: PropTypes.func,
  options: PropTypes.arrayOf(PropTypes.shape({
    value: any,
    label: any,
  })),
}

export default RadioGroupCustom;