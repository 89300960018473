import { useTranslation } from 'react-i18next'

import {ReactComponent as PriceTagIcon} from 'assets/icons/price-tag-icon.svg'
import {ReactComponent as TraceIcon} from 'assets/icons/trace-icon.svg'
import {ReactComponent as TimeAuctionIcon} from 'assets/icons/time-auction-icon.svg'
import WhitePriceTagIcon from 'assets/icons/white-fixed-price.svg'
import TimeRestoreIcon from 'assets/icons/time-restore-icon.svg'
import WhiteTimeRestoreIcon from 'assets/icons/WhiteTimeRestoreIcon.svg'
import { COLLECTIBLE_TYPE } from 'constants/index'

import './style.scss'
import React, { useContext } from 'react'
import { ThemeContext } from 'ThemeProvider'

const MarketPlaceBtnGroups = ({ onChange, options, value, collectibleType, isShowAuction = true, isCreateNft }) => {
    const { t } = useTranslation()
    const { theme } = useContext(ThemeContext)

    return (
        <div className="create-nft-marketPlace_btnGroup">
            <div
                className={`create-nft-marketPlace_btn fixed-price-btn ${value === options.FIX_PRICE && 'active'}`}
                onClick={() => onChange(options.FIX_PRICE)}
            >
                <PriceTagIcon className="create-nft-marketPlace_btn__icon" />
                <span>{t('createNFT.field_putOnMartketplace_fixedPrice')}</span>
            </div>
            <div
                className={`create-nft-marketPlace_btn ml ${value === options.OPEN_FOR_OFFER && 'active'}`}
                onClick={() => onChange(options.OPEN_FOR_OFFER)}
            >
                <TraceIcon className="create-nft-marketPlace_btn__icon" />
                <span>{t('createNFT.openForBids')}</span>
            </div>
            {isShowAuction && (
                <div
                    className={`create-nft-marketPlace_btn ml ${
                        collectibleType === COLLECTIBLE_TYPE.MULTIPLE
                            ? 'disabled'
                            : value === options.ENGLISH_AUCTION && 'active'
                    }`}
                    onClick={() => {
                        if (collectibleType === COLLECTIBLE_TYPE.MULTIPLE) return
                        onChange(options.ENGLISH_AUCTION)
                    }}
                >
                    <TimeAuctionIcon className="create-nft-marketPlace_btn__icon" />
                    <span>{t('createNFT.field_putOnMartketplace_timesAuction')}</span>
                </div>
            )}
        </div>
    )
}

export default MarketPlaceBtnGroups
