import axios from "axios";

import { customAxios } from "request/customAxios";
import { getNFTImageName, getNFTVideoName } from "utils/image";
import { AWS_API_ENDPOINT, SERVER_API_ENDPOINT } from "constants/envs";
import { IMAGE_TYPE_UPLOAD, USER_IS_BANNED_CODE } from "constants/index";
import * as message from "utils/custom-message";
import Request from "../request";
import i18n from "translation/i18n";

const nftService = {
  getDetail: async ({ collectionAddress, tokenId }) => {
    try {
      const response = await Request.get(
        `${SERVER_API_ENDPOINT}/nft/detail/${Number(
          collectionAddress
        )}/${tokenId}`
      );
      if (response.data === USER_IS_BANNED_CODE) {
        message.error(i18n.t("error.message.userHasBeenBanned"));
        return [null, { message: "" }];
      }
      return [response.data, null];
    } catch (error) {
      console.error("getDetail", error.response);
      return [null, error];
    }
  },
  likeNft: async ({ id }) => {
    try {
      const response = await Request.post(`${SERVER_API_ENDPOINT}/like`, {
        targetId: id,
      });
      return [response.data, null];
    } catch (error) {
      console.error("likeNft", error.response);
      return [null, error];
    }
  },
  getListNft: async ({
    limit = 12,
    page = 1,
    categoryId,
    sort = "recently",
    saleTypes,
    startPrice,
    endPrice,
    properties,
    collectionIds,
    isOnlyAuction,
    isVerify,
    networkTypes,
    nsfw,
    isMinted,
    ignoreNftId,
    collectionAddress,
    type,
    walletAddress,
    isOnSaleStatus,
    title,
  }) => {
    try {
      const response = await Request.get(`${SERVER_API_ENDPOINT}/nft/all`, {
        limit,
        page,
        categoryId,
        saleTypes,
        startPrice,
        endPrice,
        properties,
        collectionIds,
        isOnlyAuction,
        networkTypes,
        sortField: sort,
        isVerify,
        nsfw,
        collectionAddress,
        isMinted,
        addressType: type,
        ignoreNftId,
        walletAddress,
        title,
        ...(isOnSaleStatus && { isOnSaleStatus }),
      });
      return [response.data, null];
    } catch (error) {
      console.error("getListNft", error.response);
      return [null, error];
    }
  },

  createNft: async (data) => {
    const {
      properties,
      categoryIds,
      tokenId,
      maxQuantity,
      description,
      isUnlockableContent,
      unlockableContent,
      name,
      royalty,
      collectionAddress,
      collectionId,
    } = data;
    try {
      const response = await Request.post(`${SERVER_API_ENDPOINT}/nft`, {
        categoryIds,
        tokenId,
        maxQuantity,
        description,
        properties,
        title: name,
        royalty,
        collectionId,
        isUnlockableContent,
        unlockableContent,
      });
      return [response.data, null];
    } catch (error) {
      return [null, error];
    }
  },

  updateNft: async (data) => {
    const { description, id } = data;
    try {
      const response = await Request.put(
        `${SERVER_API_ENDPOINT}/nft/edit/information/${id}`,
        {
          description,
        }
      );
      return [response.data, null];
    } catch (error) {
      return [null, error];
    }
  },

  updateNftMedia: async (data) => {
    const { id, nftUrl, nftImagePreview } = data;
    try {
      const response = await Request.put(
        `${SERVER_API_ENDPOINT}/nft/update-image/${id}`,
        {
          nftUrl,
          nftImagePreview,
        }
      );
      return [response.data, null];
    } catch (error) {
      console.error("updateNft", error.response);
      return [null, error];
    }
  },
  // TBD: Not use function?
  uploadImage: async (data) => {
    const { imgFile, nftId } = data;

    const type = imgFile.type;

    const extension = type.split("/")[1];
    const name = new Date().getTime();

    const accessToken = localStorage.getItem("accessToken");
    const userId = localStorage.getItem("userId");
    let headers = {
      "x-amz-tagging": data.previewImgId
        ? `token=Bearer ${accessToken}&nft_id=${nftId}&preview_img_id=${data.previewImgId}`
        : `token=Bearer ${accessToken}&nft_id=${nftId}`,
      "Content-Type": imgFile.type,
    };

    try {
      const res = await customAxios({
        method: "put",
        url: `${AWS_API_ENDPOINT}/nft-image/${userId}/${name}.${extension}`,
        data: imgFile,
        headers: {
          Authorization: `Bearer ${accessToken}`,
          ...headers,
        },
      });

      return [res.data, null];
    } catch (error) {
      console.error("uploadImage", error.response);
      return [null, error];
    }
  },
  getUploadGIFImgUrl: async (data) => {
    const { imgFile, nftId } = data;
    const accessToken = localStorage.getItem("accessToken");
    const userId = localStorage.getItem("userId");
    let imageId = getNFTImageName(imgFile);
    try {
      const res = await customAxios({
        method: "get",
        url: `${SERVER_API_ENDPOINT}/upload/pre-signed/${IMAGE_TYPE_UPLOAD.NFT_IMG}/${imageId}`,
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });
      return [res.data, null];
    } catch (error) {
      console.error("getUploadGIFImgUrl", error.response);
      return [null, error];
    }
  },
  // TBD: preview image?
  getUploadPreviewVideo: async (data) => {
    const { imgFile, nftId } = data;
    const accessToken = localStorage.getItem("accessToken");
    const userId = localStorage.getItem("userId");
    let imageId = getNFTImageName(imgFile);
    try {
      const res = await customAxios({
        method: "get",
        url: `${SERVER_API_ENDPOINT}/upload/pre-signed/${IMAGE_TYPE_UPLOAD.NFT_VIDEO}/${imageId}`,
        // params: {
        //     imageId,
        //     nftId: nftId,
        //     type: 'preview'
        // },
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });
      return [res.data, null];
    } catch (error) {
      console.error("getUploadPreviewVideo", error.response);
      return [null, error];
    }
  },
  getMp3PresignUrl: async (data) => {
    const { imgFile, nftId } = data;
    const accessToken = localStorage.getItem("accessToken");
    const userId = localStorage.getItem("userId");
    let imageId = getNFTImageName(imgFile);
    try {
      const res = await customAxios({
        method: "get",
        url: `${SERVER_API_ENDPOINT}/upload/pre-signed/${IMAGE_TYPE_UPLOAD.NFT_AUDIO}/${imageId}`,
        params: {
          imageId,
          nftId: nftId,
          type: "audio",
        },
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });
      return [res.data, null];
    } catch (error) {
      console.error("getMp3PresignUrl", error.response);
      return [null, error];
    }
  },
  getUploadVideo: async (data) => {
    const { imgFile, nftId } = data;
    const accessToken = localStorage.getItem("accessToken");
    const userId = localStorage.getItem("userId");
    const imageId = getNFTVideoName(imgFile);
    try {
      const res = await customAxios({
        method: "get",
        url: `${SERVER_API_ENDPOINT}/upload/pre-signed/${IMAGE_TYPE_UPLOAD.NFT_VIDEO}/${imageId}`,
        params: {
          imageId,
          nftId: nftId,
          preview_img_id: data.previewImgId,
          type: "video",
        },
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });
      return [res.data, null];
    } catch (error) {
      console.error("getUploadVideo", error.response);
      return [null, error];
    }
  },
  putNftImage: async (data) => {
    const { imgFile, nftId, collectionId } = data;
    const accessToken = localStorage.getItem("accessToken");

    let headers = {
      // 'x-amz-tagging': data.previewImgId
      //     ? `token=Bearer ${accessToken}&nft_id=${nftId}&collectionId=${collectionId}&preview_img_id=${data.previewImgId}`
      //     : `token=Bearer ${accessToken}&nft_id=${nftId}&collectionId=${collectionId}`,
      "Content-Type": imgFile.type,
    };
    try {
      const res = await customAxios({
        method: "put",
        url: data?.uploadUrl,
        data: data?.imgFile,
        headers,
      });

      return [res.data, null];
    } catch (error) {
      console.error("putNftImage", error.response);
      return [null, error];
    }
  },
  // TBD: for what?
  getVideoUrl: async (data) => {
    const res = await axios.get(
      `${AWS_API_ENDPOINT}/nft-image/${data.userId}/pre-signed/?imageId=${data.imageId}&type=video`
    );
    return res.data;
  },

  getNftFeatured: async () => {
    try {
      const response = await Request.get(`${SERVER_API_ENDPOINT}/nft/featured`);
      return [response.data, null];
    } catch (error) {
      console.error("getNftFeatured", error.response);
      return [null, error];
    }
  },
  getUnlockContent: async (id) => {
    try {
      const response = await Request.get(
        `${SERVER_API_ENDPOINT}/nft/unlock-able-content/${id}`
      );
      return [response.data, null];
    } catch (error) {
      console.error("getUnlockContent", error.response);
      return [null, error];
    }
  },
  searchAll: async ({ keyword }) => {
    try {
      const response = await Request.get(
        `${SERVER_API_ENDPOINT}/search?query=${keyword}`
      );
      return [response.data, null];
    } catch (error) {
      console.error("searchAll", error.response);
      return [null, error];
    }
  },
  getNftsSearch: async ({ keyword, limit, page }) => {

    try {
      const response = await Request.get(
        `${SERVER_API_ENDPOINT}/nft/all`, {
        page,
        limit,
        title: keyword,
      }
      );
      return [response.data, null];
    } catch (error) {
      console.error("getNftsSearch", error.response);
      return [null, error];
    }
  },
  getCollectionsSearch: async ({ keyword, limit, page }) => {
    try {
      const response = await Request.get(
        `${SERVER_API_ENDPOINT}/collection/all`,
        {
          page,
          limit,
          keyword,
        }
      );
      return [response.data, null];
    } catch (error) {
      console.error("getCollectionsSearch", error.response);
      return [null, error];
    }
  },
  getUsersSearch: async ({ keyword, limit, page }) => {
    try {
      const response = await Request.get(
        `${SERVER_API_ENDPOINT}/account/search/information`, {
        page,
        limit,
        keyword
      }
      );
      return [response.data, null];
    } catch (error) {
      console.error("getUsersSearch", error.response);
      return [null, error];
    }
  },
  getImageUrlFromMetadata: async (metadataUrl) => {
    try {
      const response = await customAxios({
        method: "get",
        url: metadataUrl,
      });
      const metaData = response?.data;
      return [metaData?.image, null];
    } catch (error) {
      console.error("getImageUrlFromMetadata", error.response);
      return [null, error];
    }
  },
  getNftPropertyCollection: async ({ collectionId }) => {
    try {
      const response = await Request.get(
        `${SERVER_API_ENDPOINT}/properties-nft`,
        { collectionId }
      );
      return [response.data, null];
    } catch (error) {
      console.error("getNftPropertyCollection", error.response);
      return [null, error];
    }
  },
  reportNft: async (data) => {
    try {
      const response = await Request.post(
        `${SERVER_API_ENDPOINT}/report`,
        data
      );
      return [response, null];
    } catch (error) {
      console.error("reportNft", error.response);
      return [null, error];
    }
  },
  getNftProperties: async ({ collectionId, tokenId }) => {
    try {
      const response = await Request.get(
        `${SERVER_API_ENDPOINT}/nft/detail-properties/${Number(
          collectionId
        )}/${tokenId}`
      );
      return [response.data, null];
    } catch (error) {
      console.error("getNftProperties", error.response);
      return [null, error];
    }
  },
};

export default nftService;
