import { useEffect, useRef, useState } from 'react'

export const useResizeWindow = (innerWidth = 575) => {
    const [isLessThan, setIsLessThan] = useState(window.innerWidth <= innerWidth)

    useEffect(() => {
        const windowResizeListener = window.addEventListener('resize', () => {
            setIsLessThan(window.innerWidth <= innerWidth)
        })

        return () => window.removeEventListener('resize', windowResizeListener)
    }, [innerWidth])

    return [isLessThan]
}

export const useOnClickOutside = (ref, handler, exceptionRef) => {
    useEffect(() => {
        const listener = event => {
            if (!ref.current || ref.current.contains(event.target) || exceptionRef?.current?.contains(event.target)) {
                return
            }

            handler(event)
        }

        document.addEventListener('mousedown', listener)
        return () => {
            document.removeEventListener('mousedown', listener)
        }
    }, [ref, handler, exceptionRef])
}
