import { NETWORK_ID_SUPPORT_ADDRESS } from 'constants/envs'
import { convertBigNumberValueToNumber } from 'blockchain/ether'
import { getERC20AmountBalance } from 'blockchain/utils'
import { DECIMALS, NETWORK_TYPES } from 'constants/index'
import env from 'env/index'
import { getBalance } from 'blockchain/instance'

export const getAmountToken = async (chainId, account) => {
    const tokenAmount = {}
    const nativeBalance = await getBalance(account, chainId);
    tokenAmount.nativeToken = nativeBalance;

    if (chainId === env.metadata.networks.bsc.id) {
        const [balanceRes] = await getERC20AmountBalance(
            NETWORK_ID_SUPPORT_ADDRESS[chainId].wbnb,
            account,
            chainId,
            NETWORK_TYPES.bsc
        )
        tokenAmount.wbnbBalance = convertBigNumberValueToNumber(balanceRes, DECIMALS.WBNB_DECIMALS)

        const [spBalanceRes] = await getERC20AmountBalance(
            NETWORK_ID_SUPPORT_ADDRESS[chainId].sp,
            account,
            chainId,
            NETWORK_TYPES.bsc
        )
        tokenAmount.spBalance = convertBigNumberValueToNumber(spBalanceRes, DECIMALS.SP_DECIMALS)

        const [busdBalanceRes] = await getERC20AmountBalance(
            NETWORK_ID_SUPPORT_ADDRESS[chainId].busd,
            account,
            chainId,
            NETWORK_TYPES.bsc
        )
        tokenAmount.busdBalance = convertBigNumberValueToNumber(busdBalanceRes, DECIMALS.BUSD_DECIMALS)
    } else if (chainId === env.metadata.networks.ethereum.id) {
        // Support SP token on eth
        const [spBalanceRes] = await getERC20AmountBalance(
            NETWORK_ID_SUPPORT_ADDRESS[chainId].sp,
            account,
            chainId,
            NETWORK_TYPES.ethereum
        )
        tokenAmount.spBalance = convertBigNumberValueToNumber(spBalanceRes, DECIMALS.SP_DECIMALS)

        const [wethBalanceRes] = await getERC20AmountBalance(
            NETWORK_ID_SUPPORT_ADDRESS[chainId].weth,
            account,
            chainId,
            NETWORK_TYPES.ethereum
        )
        tokenAmount.wethBalance = convertBigNumberValueToNumber(wethBalanceRes, DECIMALS.WETH_DECIMALS)

        const [usdtBalanceRes] = await getERC20AmountBalance(
            NETWORK_ID_SUPPORT_ADDRESS[chainId].usdt,
            account,
            chainId,
            NETWORK_TYPES.ethereum
        )
        tokenAmount.usdtBalance = convertBigNumberValueToNumber(usdtBalanceRes, DECIMALS.USDT_DECIMALS)
    } else if (chainId === env.metadata.networks.polygon.id) {
        // Support SP token on polygon
        const [spBalanceRes] = await getERC20AmountBalance(
            NETWORK_ID_SUPPORT_ADDRESS[chainId].sp,
            account,
            chainId,
            NETWORK_TYPES.polygon
        )
        tokenAmount.spBalance = convertBigNumberValueToNumber(spBalanceRes, DECIMALS.SP_DECIMALS)

        const [wethBalanceRes] = await getERC20AmountBalance(
            NETWORK_ID_SUPPORT_ADDRESS[chainId].weth,
            account,
            chainId,
            NETWORK_TYPES.polygon
        )
        tokenAmount.wethBalance = convertBigNumberValueToNumber(wethBalanceRes, DECIMALS.WETH_DECIMALS)
    }

    return tokenAmount
}

export const getDecodeNftUrl = path => {
    const result = {}
    if (`${path}`.includes(':')) {
        result.collectionAddress = `${path}`.split(':')[0]
        result.tokenId = `${path}`.split(':')[1]
    }
    if (Number(path)) {
        result.id = path
    }
    return result
}
