import React, { useState, useEffect, useCallback } from 'react'
import { Tabs, Tooltip } from 'antd'
import { useTranslation } from 'react-i18next'
import { useSelector, useDispatch } from 'react-redux'

import { NFT_TYPE } from 'constants/index'
import {
    changePageHistory,
    changePageMultipleSelling,
    changePageOffer,
    getNftSales,
    getOfferList,
    getTradingHistory,
    gotoSalesTab
} from 'store/actions/nft'
import FireGIF from 'assets/images/fire.gif'
import InfoIcon from 'assets/icons/info-icon.svg'
import TradingHistory from './TradingHistory'
import Details from './Details'
import Attributes from './Attributes'
import MultipleSell from './MultipleSell'
import OffersForOpenBid from './OffersForOpening'
import './style.scss'
import Description from './Description'
import Attribute from './Attribute'

const { TabPane } = Tabs

const TABS = {
    SPECIFICATIONS: 'SPECIFICATIONS',
    LISTINGS: 'LISTINGS',
    OFFERS: 'OFFERS',
    ACTIVITY: 'ACTIVITY',
    HISTORY: 'HISTORY',
    DETAIL: 'DETAIL',
    SELL: 'SELL'
}

const DetailTabs = props => {
    const { tabRef } = props
    const { t } = useTranslation()
    const dispatch = useDispatch()
    const { collection, id } = useSelector(state => state.collectible?.data)
    const { items: nftSales } = useSelector(state => state?.collectible?.nftSales)
    const { pageOffer, pageSellMultiple, pageOfferOpenBid } = useSelector(state => state?.collectible)
    const { walletAddress } = useSelector(state => state.user?.profile) || {}
    const { isGotoSale } = useSelector(state => state.collectible)
    const [activeTab, setActiveTab] = useState(TABS.SPECIFICATIONS)

    useEffect(() => {
        if (isGotoSale) {
            setActiveTab(TABS.SELL)
        }
        return () => {
            dispatch(gotoSalesTab({ value: false }))
        }
    }, [dispatch, isGotoSale])

    const getListMultipleSell = useCallback(async () => {
        dispatch(getNftSales({ nftId: id, page: pageSellMultiple, limit: 5 }))
    }, [dispatch, id, pageSellMultiple])

    const getList = useCallback(async () => {
        dispatch(getOfferList({ nftId: id, page: pageOffer, limit: 5 }))
    }, [dispatch, id, pageOffer])

    const getListOfferOpenList = useCallback(async () => {
        const params = { nftId: id, page: pageOfferOpenBid, limit: 5 }
        dispatch(getOfferList(params))
    }, [dispatch, id, pageOfferOpenBid])

    useEffect(() => {
        getList()
        getListMultipleSell()
        getListOfferOpenList()
    }, [getList, getListMultipleSell, getListOfferOpenList])

    useEffect(() => {
        if (activeTab === TABS.HISTORY) {
            dispatch(changePageHistory({ value: 1 }))
            dispatch(getTradingHistory({ nftId: id }))
        }
        if (activeTab === TABS.OFFERS) {
            dispatch(changePageOffer({ value: 1 }))
            dispatch(getOfferList({ nftId: id }))
        }
        if (activeTab === TABS.SELL) {
            dispatch(getNftSales({ nftId: id }))
            dispatch(changePageMultipleSelling({ value: 1 }))
        }
    }, [activeTab, dispatch, id, walletAddress])

    return (
        <div className="detail-tabs-container" ref={tabRef}>
            <Tabs activeKey={activeTab} centered onChange={key => setActiveTab(key)}>
                <TabPane tab={t('nftDetail.specifications')} key={TABS.SPECIFICATIONS}>
                    <Attributes />
                    <Details />
                    <Description />
                </TabPane>
                {collection.type === NFT_TYPE.ERC1155 && nftSales?.length > 0 && 
                    <TabPane tab={t('nftDetail.listings')} key={TABS.LISTINGS}>
                        <MultipleSell />
                    </TabPane>
                }

                {/* {collection.type === NFT_TYPE.ERC1155 && nftSales?.length > 0 && (
                    <TabPane tab={t("activity.sales")} key={TABS.SELL}>
                        <MultipleSell />
                    </TabPane>
                )} */}

                <TabPane
                    tab={t('nftDetail.offers')}
                    key={TABS.OFFERS}
                >
                    <OffersForOpenBid />
                </TabPane>
                <TabPane tab={t('nftDetail.activity')} key={TABS.HISTORY}>
                    <TradingHistory />
                </TabPane>
            </Tabs>
        </div>
    )
}

export default DetailTabs
