import {createSlice} from '@reduxjs/toolkit';

const initialState = {
  loading: false,
  MARKET_FEE: 0
}

export const systemSlice = createSlice({
  name: 'system',
  initialState,
  reducers: {
    setGlobalLoading: (state) => {
      state.loading = true;
    },
    setCloseGlobalLoading: (state) => {
      state.loading = false;
    },
    setSystemConfig: (state, data) => {
      state = {...state, ...data.payload};
    },
  }
});

export const {setGlobalLoading, setCloseGlobalLoading, setSystemConfig} = systemSlice.actions;

export default systemSlice.reducer;
