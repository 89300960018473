import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

//components
import { Checkbox } from 'antd'

//css
import './style.scss'

const CustomCheckbox = ({
    className,
    checked,
    defaultChecked,
    disabled,
    onChange,
    autoFocus,
    children,
    ...restProps
}) => {
    return (
        <Checkbox
            className={classNames({ 'checkbox-custom': true }, { [className]: !!className })}
            checked={checked}
            autoFocus={autoFocus}
            disabled={disabled}
            onChange={onChange}
            {...restProps}
        >
            {children}
        </Checkbox>
    )
}

CustomCheckbox.Group = ({
    className,
    defaultValue,
    disabled,
    onChange,
    name,
    options,
    value,
    children,
    ...restProps
}) => {
    return (
        <Checkbox.Group
            className={classNames({ 'checkbox-group-custom': true }, { [className]: !!className })}
            name={name}
            options={options}
            onChange={onChange}
            {...restProps}
        >
            {children}
        </Checkbox.Group>
    )
}

CustomCheckbox.propTypes = {
    className: PropTypes.string,
    children: PropTypes.any,
    checked: PropTypes.bool,
    disabled: PropTypes.bool,
    defaultChecked: PropTypes.any,
    onChange: PropTypes.func,
    autoFocus: PropTypes.bool
}

CustomCheckbox.Group.propTypes = {
    className: PropTypes.string,
    defaultValue: PropTypes.array,
    disabled: PropTypes.bool,
    onChange: PropTypes.func,
    name: PropTypes.string,
    options: PropTypes.any,
    value: PropTypes.any,
    children: PropTypes.any
}

export default CustomCheckbox
