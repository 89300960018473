import React, { useMemo } from 'react'
import './styles.scss'
import { ButtonCustom, SelectCustom } from 'components/common'
import PropTypes from 'prop-types'
import NextIcon from 'assets/icons/next-icon.svg'
import PrevIcon from 'assets/icons/prev-icon.svg'

import { useTranslation } from 'react-i18next'
import { range } from 'lodash'

export const DOTS = 0;

const CustomPagination = ({ pageSize = 5, total = 0, siblingCount = 2, value, onChangePage }) => {
    const { t } = useTranslation();
    const totalPageCount = Math.ceil(total / pageSize)
    const options = []
    for (let i = 1; i <= totalPageCount; i++) {
        options.push({ value: i, label: i })
    }
    const onClickNext = () => onChangePage(value + 1)
    const onClickPrev = () => onChangePage(value - 1)

    const paginationRange = useMemo(() => {
        const totalPageCount = Math.ceil(total / pageSize);

        // Pages count is determined as siblingCount + firstPage + lastPage + value + 2*DOTS
        const totalPageNumbers = siblingCount + 5;

        /*
          If the number of pages is less than the page numbers we want to show in our
          paginationComponent, we return the range [1..totalPageCount]
        */
        if (totalPageNumbers >= totalPageCount) {
            return range(1, totalPageCount + 1);
        }

        const leftSiblingIndex = Math.max(value - siblingCount, 1);
        const rightSiblingIndex = Math.min(value + siblingCount, totalPageCount);

        /*
          We do not want to show dots if there is only one position left 
          after/before the left/right page count as that would lead to a change if our Pagination
          component size which we do not want
        */
        const shouldShowLeftDots = leftSiblingIndex >= 3;
        const shouldShowRightDots = rightSiblingIndex <= totalPageCount - 2;

        const firstPageIndex = 1;
        const lastPageIndex = totalPageCount;

        if (!shouldShowLeftDots && shouldShowRightDots) {
            const leftItemCount = value + siblingCount;
            const leftRange = range(1, leftItemCount);

            return [...leftRange, DOTS, totalPageCount];
        }

        if (shouldShowLeftDots && !shouldShowRightDots) {
            const rightItemCount = 1 + siblingCount + totalPageCount - value;
            const rightRange = range(totalPageCount - rightItemCount + 1, totalPageCount + 1);
            return [firstPageIndex, DOTS, ...rightRange];
        }

        if (shouldShowLeftDots && shouldShowRightDots) {
            const middleRange = range(leftSiblingIndex, rightSiblingIndex + 1);

            return [firstPageIndex, DOTS, ...middleRange, DOTS, lastPageIndex];
        }
    }, [pageSize, total, value])

    const currentStartIndex = pageSize * (value - 1) + 1
    const currentEndIndex = Math.min(total, pageSize * value)

    if(total <= pageSize) return null;

    return (
        <div className="custom-pagination__container">
            <div className='custom-pagination__info'>
                <span>{t('common.paginationText', { start: currentStartIndex, end: currentEndIndex, total })}</span>
            </div>
            <div className='custom-pagination__button-list'>
                <ButtonCustom
                    variant="ghost"
                    className="custom-pagination__button"
                    iconLeft={<img src={PrevIcon} alt="PrevIcon" />}
                    disabled={value === 1}
                    onClick={onClickPrev}
                >
                </ButtonCustom>
                {paginationRange.map((page, index) => {
                    if (page === DOTS) {
                        return <i key={index} className="ri-more-fill"></i>
                    }
                    return <span 
                                key={index} 
                                className={`custom-pagination__page cursor-pointer ${page === value ? 'active' : ''}`} 
                                onClick={() => onChangePage(page)}
                            >
                                {page}
                            </span>
                })}
                <ButtonCustom
                    variant="ghost"
                    className="custom-pagination__button"
                    iconRight={<img src={NextIcon} alt="NextIcon" />}
                    disabled={value === totalPageCount}
                    onClick={onClickNext}
                >
                </ButtonCustom>
            </div>
        </div>
    )
}

CustomPagination.propTypes = {
    pageSize: PropTypes.number,
    total: PropTypes.number
}

export default CustomPagination
