import React, {
  useCallback,
  useEffect,
  useState,
  useRef,
  useContext,
} from "react";
import { useWindowSize } from "react-use";
import { Link, useHistory, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { Tabs, Modal, Col, Row, Tooltip, Avatar } from "antd";
import copy from "copy-to-clipboard";

import { shortenAddress, shortenNameNoti } from "utils/func";
import * as message from "utils/custom-message";
import { isUserAuthenticated } from "utils/auth";
import {
  ButtonCustom,
  NoData,
  ShareCustom,
  TabsCustom,
} from "components/common";
import { setUpdateProfileState, closeUploadCover } from "store/actions/user";
import avatarPlaceholder from "assets/images/avatar-default-new-ui.png";
import coverPlaceholder2 from "assets/images/cover-placeholder2.png";
import closeModalIcon from "assets/icons/cancel-icon.svg";
import UserIcon from "assets/icons/User.png";
import CopyIcon from "assets/icons/Copy.png";
import EditIcon from "assets/icons/edit.png";
import DefaultLayout from "components/layout/default-layout";
import userService from "service/userService";
import { NoItemFound, ModalContent, ListCustom } from "./components";
import LikedTab from "./components/LikedTab";
import ActivityProfile from "./components/Activity";
import CollectionsProfile from "./components/Collections";
import { useAuth } from "hooks/useAuth";
import "./style.scss";
import { Helmet } from "react-helmet";
import { MAIN_TITLE, SOCIAL_LINK } from "constants/index";
import TiktokIcon from "assets/icons/tiktok.svg";
import { AWS_CLOUDFRONT_ENDPOINT } from "constants/envs";
import CustomVerifyIcon from "components/common/custom-verify-icon";
import { useMemo } from "react";
import { useResizeWindow } from "utils/hook";
import { roundingNumber } from "utils/formatNumber";
import { NFT_STATUS } from "store/constants/nft";
import { ThemeContext } from "../../ThemeProvider";
import { setGlobalLoading, setCloseGlobalLoading } from "store/system.slice";
import { setCurrentUserProfile } from "store/user.slice";
import CustomTabs from "components/common/tabs";
import Refferal from "./components/Refferal";
import { useQuery } from "@tanstack/react-query";

export const TABS = {
  COLLECTED: "OWNER",
  REFERRAL: "REFERRAL",
  FAVORITE: "FAVORITE",
  ACTIVITY: "ACTIVITY",
};

const { TabPane } = Tabs;

function changeIndexTwitterAndInsta(arr) {
  // Move Index Twitter
  const twitterIndex = arr?.findIndex((item) => item?.name === "Twitter");
  arr?.splice(0, 0, arr?.splice(twitterIndex, 1)[0]);

  // Move Index Instagram
  const instagramIndex = arr?.findIndex((item) => item?.name === "Instagram");
  arr?.splice(1, 0, arr?.splice(instagramIndex, 1)[0]);

  return arr;
}

export const Profile = (props) => {
  const { theme } = useContext(ThemeContext);
  const { t } = useTranslation();
  const [isMobile] = useResizeWindow(767);
  const [, handleSignWallet] = useAuth();

  const { width } = useWindowSize();

  const addressUrl = props.match.params.address;
  const customUrl = props.match.params.customUrl;

  const history = useHistory();
  const dispatch = useDispatch();
  const { profile, uploadCoverImageSuccess, currentUserProfile } = useSelector(
    (state) => state.user
  );
  const { loading } = useSelector((state) => state.system);
  const [isOwner, setIsOwner] = useState(false);
  const [isUserExist, setIsUserExist] = useState(true);
  const [userProfile, setUserProfile] = useState({});
  const [userNftProfile, setUserNftProfile] = useState({});
  const [disabledState, setDisabledState] = useState(false);
  const [showMore, setShowMore] = useState(true);

  const [ownerFollowingList, setOwnerFollowingList] = useState({
    data: [],
    total: 0,
  });
  const [followingList, setFollowingList] = useState({ data: [], total: 0 });
  const [followerList, setFollowerList] = useState({ data: [], total: 0 });
  const isFirstTimeLoad = useRef(true);
  // const [isCopy, setIsCopy] = useState(false);

  const [activeTab, setActiveTab] = useState();
  const [activeTabSub, setActiveTabSub] = useState(NFT_STATUS.ON_SALE);

  const socialChanelUser = [
    {
      name: "Twitter",
      url: userProfile.twitterUrl,
    },
    {
      name: "Instagram",
      url: userProfile.instagramUrl,
    },
    {
      name: "Medium",
      url: userProfile.mediumUrl,
    },
    {
      name: "Tiktok",
      url: userProfile.tiktokUrl,
    },
    {
      name: "Youtube",
      url: userProfile.youtubeUrl,
    },

    {
      name: "Telegram",
      url: userProfile.telegramUrl,
    },
    {
      name: "Discord",
      url: userProfile.discordUrl,
    },
    {
      name: "Facebook",
      url: userProfile.facebookUrl,
    },
  ];
  const socialChanel = changeIndexTwitterAndInsta(
    socialChanelUser.filter((item) => item?.url)
  );

  //Count data
  const {
    numberNftsOnSale,
    nftByCreated,
    nftByOwned,
    nftByLike,
    numberNftsNotForSale,
  } = userNftProfile || 0;
  const { numberOfFollowing, numberOfFollowers } = userProfile || 0;

  //Handle following modal
  const [isFollowingVisible, setIsFollowingVisible] = useState(false);
  const { location } = props;
  const showFollowing = () => {
    if (width < 576) document?.body?.classList?.add("hidden-scroll");
    setIsFollowingVisible(true);
    getCurrentFollowingList();
  };

  function useQuery() {
    const { search } = useLocation();

    return React.useMemo(() => new URLSearchParams(search), [search]);
  }
  let query = useQuery();

  const tabPaneQuery = query.get("tab");

  //handle query params tabPaneQuery
  useEffect(() => {
    if (tabPaneQuery) {
      setActiveTab(tabPaneQuery);
    } else {
      setActiveTab(TABS.COLLECTED);
    }
  }, [tabPaneQuery]);

  const getCurrentFollowingList = async () => {
    if (userProfile?.id) {
      const [response, errRes] = await userService.getFollowingList(
        userProfile?.walletAddress
      );
      if (response) {
        setFollowingList({
          ...followingList,
          data: response.items,
          total: response?.meta?.totalItem,
        });
      } else if (errRes) {
        message.error(t("error.message.somethingWentWrong"));
      }
    }
  };

  const handleFollowingCancel = () => {
    if (width < 576) document?.body?.classList?.remove("hidden-scroll");
    setIsFollowingVisible(false);
  };

  //Check authitencation on opening modal
  const checkAuthentication = async () => {
    if (!isUserAuthenticated()) {
      const data = await handleSignWallet();
      if (data === false) return;
    }
  };

  //Handle followers modal
  const [isFollowersVisible, setIsFollowersVisible] = useState(false);

  const showFollowers = () => {
    if (width < 576) document?.body?.classList?.add("hidden-scroll");
    setIsFollowersVisible(true);
    getCurrentFollowerList();
  };

  const getCurrentFollowerList = async () => {
    if (userProfile?.id) {
      const [res, errorRes] = await userService.getFollowerList(
        userProfile?.walletAddress
      );
      if (res)
        setFollowerList({
          ...followerList,
          data: res.items,
          total: res?.meta?.totalItem,
        });
      else if (errorRes) {
        message.error(t("error.message.somethingWentWrong"));
      }
    }
  };

  const handleFollowersCancel = () => {
    if (width < 576) document?.body?.classList?.remove("hidden-scroll");
    setIsFollowersVisible(false);
  };

  // Copy user ethereum address to clipboard
  const copyAddressToClipboard = () => {
    // if (isCopy) {
    //   message.error(t("error.message.dontClickCopyRepeatedly"));
    //   return;
    // }
    // setIsCopy(true);
    copy(userProfile.walletAddress);
    message.success(t("common.copySuccess"));
    // setTimeout(() => {
    //   setIsCopy(false);
    // }, 3500);
  };

  // Copy user Referral to clipboard
  const copyReferralToClipboard = () => {
    // if (isCopy) {
    //   message.error(t("error.message.dontClickCopyRepeatedly"));
    //   return;
    // }
    // setIsCopy(true);
    copy(profile?.code);
    message.success(t("profile.copyReferralSuccess"));
    // setTimeout(() => {
    //   setIsCopy(false);
    // }, 3500);
  };

  //Get user profile function
  const getUserProfile = useCallback(
    async (data) => {
      let profile = null;
      let err = null;
      if (isFirstTimeLoad.current) {
        dispatch(setGlobalLoading());
        isFirstTimeLoad.current = false;
      }
      if (data?.walletAddress) {
        const [profileRes, errRes] = await userService.getUserInfo(
          data?.walletAddress
        );
        profile = profileRes;
        err = errRes;
      } else {
        const [profileRes, errRes] = await userService.getUserInfo(
          data?.customUrl
        );
        profile = profileRes;
        err = errRes;
      }
      if (profile) {
        setIsUserExist(true);
        setUserProfile(profile);
        dispatch(setCurrentUserProfile(profile));
        dispatch(setCloseGlobalLoading());
      } else if (err) {
        setIsUserExist(false);
        dispatch(setCloseGlobalLoading());
      }
    },
    [dispatch]
  );

  //Get nft of user profile function
  const getUserNftProfile = useCallback(
    async (data) => {
      let profile = null;
      let err = null;
      if (isFirstTimeLoad.current) {
        dispatch(setGlobalLoading());
        isFirstTimeLoad.current = false;
      }
      if (data?.walletAddress) {
        const [profileRes, errRes] = await userService.getUserNftInfo(
          data?.walletAddress
        );
        profile = profileRes;
        err = errRes;
      } else {
        const [profileRes, errRes] = await userService.getUserNftInfo(
          data?.customUrl
        );
        profile = profileRes;
        err = errRes;
      }
      if (profile) {
        setUserNftProfile(profile);
        dispatch(setCloseGlobalLoading());
      } else if (err) {
        dispatch(setCloseGlobalLoading());
      }
    },
    [dispatch]
  );

  const handleSetUser = useCallback(
    (isUserExist = true, dataUser) => {
      if (isUserExist) {
        setIsOwner(true);
        setIsUserExist(true);
        getUserProfile(dataUser);
        getUserNftProfile(dataUser);
      } else {
        setIsOwner(false);
        getUserProfile(dataUser);
        getUserNftProfile(dataUser);
      }
    },
    [getUserProfile, getUserNftProfile]
  );

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [currentUserProfile]);

  // useEffect(() => {
  //   if (history?.location?.state?.activeOwnerTab) {
  //     setActiveTabSub(NFT_STATUS.OWNER);
  //   }
  //   return () => setActiveTabSub(NFT_STATUS.ON_SALE);
  // }, [history?.location?.state?.activeOwnerTab]);

  useEffect(() => {
    if (location.pathname === "/user/owner") {
      history.push({
        pathname: "/connect",
        state: {
          from: "/owner",
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location?.pathname]);

  useEffect(() => {
    dispatch(setUpdateProfileState({ state: null }));
  }, [dispatch, uploadCoverImageSuccess, addressUrl, customUrl]);

  useEffect(() => {
    if (addressUrl) {
      if (
        addressUrl?.toLowerCase() === profile?.walletAddress?.toLowerCase() ||
        addressUrl === profile?.shortUrl
      ) {
        handleSetUser(true, { walletAddress: addressUrl });
      } else {
        handleSetUser(false, { walletAddress: addressUrl });
      }
    } else {
      if (customUrl === profile.shortUrl) {
        handleSetUser(true, { customUrl });
      } else {
        handleSetUser(false, { customUrl });
      }
    }
    // if (location?.state?.activeTabSub && !location?.state?.activeTab) {
    //   setActiveTabSub(location?.state?.activeTabSub);
    // }
    // if (location?.state?.activeTab) {
    //   setActiveTab(location?.state?.activeTab);
    //   setActiveTabSub(location?.state?.activeTabSub);
    // } else {
    //   setActiveTab(NFT_STATUS[2]);
    // }

    window.history.replaceState({}, document.title);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    props.match.params,
    profile.walletAddress,
    addressUrl,
    dispatch,
    getUserProfile,
    getUserNftProfile,
    customUrl,
    profile.shortUrl,
    handleSetUser,
    uploadCoverImageSuccess,
  ]);

  useEffect(() => {
    dispatch(closeUploadCover());
  }, []);

  // Get user following, follower list function

  const fetchFollowingList = async () => {
    if (profile?.walletAddress) {
      const [ownerResponse, ownerErr] = await userService.getFollowingList(
        profile?.walletAddress
      );
      if (ownerResponse) {
        const limit = ownerResponse?.total;
        const [ownerRes, ownerError] = await userService.getFollowingList(
          profile?.walletAddress,
          1,
          limit
        );
        if (ownerRes) {
          setOwnerFollowingList({
            ...ownerFollowingList,
            data: ownerRes?.items,
            total: ownerRes?.meta?.totalItem,
          });
        } else if (ownerError)
          message.error(t("error.message.somethingWentWrong"));
      } else if (ownerErr) message.error(t("error.message.somethingWentWrong"));
    }
  };

  useEffect(() => {
    fetchFollowingList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [profile?.walletAddress]);

  const handleFollow = async (walletAddress) => {
    if (!profile?.walletAddress) {
      if (width < 576) document?.body?.classList?.remove("hidden-scroll");
      history.push({
        pathname: "/connect",
        state: {
          from: "/user",
        },
      });
    } else {
      setDisabledState(walletAddress);
      await checkAuthentication();
      const [res, err] = await userService.followUser(walletAddress);
      if (res) {
      } else if (err) {
        setDisabledState("");
        return;
      }
      const [ownerResponse, ownerErr] = await userService.getFollowingList(
        profile?.walletAddress
      );
      if (ownerResponse) {
        const limit = ownerResponse?.total;
        const [ownerRes, ownerError] = await userService.getFollowingList(
          profile?.walletAddress,
          1,
          limit
        );
        if (ownerRes) {
          setOwnerFollowingList({
            ...ownerFollowingList,
            data: ownerRes?.items,
            total: ownerRes?.meta?.totalItem,
          });
        } else if (ownerError)
          message.error(t("error.message.somethingWentWrong"));
      } else if (ownerErr) message.error(t("error.message.somethingWentWrong"));
      await getUserProfile(userProfile);
      setDisabledState("");
    }
  };
  const checkFollowed = (id) => {
    for (let i = 0; i < ownerFollowingList?.data?.length; i++) {
      if (id === ownerFollowingList?.data[i]?.followingId) {
        return true;
      }
    }
    return false;
  };

  useEffect(() => {
    if (!profile?.walletAddress) setOwnerFollowingList([]);
  }, [profile?.walletAddress]);

  const renderIconSocial = (name) => {
    switch (name) {
      case "Twitter":
        return <i class="ri-twitter-x-line" />;
      case "Instagram":
        return <i className="ri-instagram-line" />;
      case "Facebook":
        return <i className="ri-facebook-circle-fill" />;
      case "Discord":
        return <i className="ri-discord-line" />;
      case "Medium":
        return <i className="ri-medium-line" />;
      case "Tiktok":
        return <i class="ri-tiktok-line"></i>;
      case "Telegram":
        return <i className="ri-telegram-line" />;
      case "Youtube":
        return <i className="ri-youtube-line" />;
      default:
        break;
    }
  };

  const renderLinkSocial = (name) => {
    switch (name) {
      case "Twitter":
        return SOCIAL_LINK.TWITTER;
      case "Instagram":
        return SOCIAL_LINK.INSTAGRAM;
      case "Facebook":
        return SOCIAL_LINK.FACEBOOK;
      case "Discord":
        return SOCIAL_LINK.DISCORD;
      case "Medium":
        return SOCIAL_LINK.MEDIUM;
      case "Tiktok":
        return SOCIAL_LINK.TIKTOK;
      case "Telegram":
        return SOCIAL_LINK.TELEGRAM;
      case "Youtube":
        return SOCIAL_LINK.YOUTUBE;
      default:
        break;
    }
  };

  const toggleShowMore = () => {
    setShowMore(!showMore);
  };

  return (
    <DefaultLayout
      isActiveFooter={width <= 992 && activeTab === "5" ? false : true}
    >
      <Helmet>
        <meta charSet="utf-8" />
        <title>{`${MAIN_TITLE} - Profile`}</title>
        <meta name="description" content={`${MAIN_TITLE} - Profile`} />
      </Helmet>
      {loading ? (
        <div className="blank-user-profile"></div>
      ) : isUserExist ? (
        <>
          <div className="container-profile">
            <div className="user-cover">
              <div className="profile-container_user-cover">
                <img
                  src={
                    !isOwner
                      ? userProfile?.coverImg
                        ? `${AWS_CLOUDFRONT_ENDPOINT}/${userProfile?.coverImg}`
                        : coverPlaceholder2
                      : profile?.coverImg
                      ? `${AWS_CLOUDFRONT_ENDPOINT}/${profile?.coverImg}`
                      : coverPlaceholder2
                  }
                  alt=""
                  className="cover-picture"
                />
              </div>
            </div>

            <div className={`profile-container_user-info`}>
              <div className="user-info-holder">
                <div className="user-avatar">
                  <div className="avatar-container">
                    <img
                      className={
                        !userProfile?.avatarImg ? "avatar-default" : ""
                      }
                      src={
                        userProfile?.avatarImg
                          ? `${AWS_CLOUDFRONT_ENDPOINT}/${userProfile?.avatarImg}`
                          : avatarPlaceholder
                      }
                      alt=""
                    />
                    {userProfile?.isVerify ? <CustomVerifyIcon /> : null}
                  </div>
                  <div className="container-user-info">
                    <div className="container-user-tabs">
                      <div className="user-avatar-info">
                        {userProfile?.username ? (
                          <Tooltip title={userProfile?.username}>
                            <h5>{`${shortenNameNoti(
                              userProfile?.username,
                              20
                            )}`}</h5>
                          </Tooltip>
                        ) : width < 567 ? (
                          <Tooltip title={userProfile?.walletAddress}>
                            <h5 className="shrink-title">{`${shortenAddress(
                              isOwner && profile?.walletAddress
                                ? profile?.walletAddress
                                : userProfile?.walletAddress,
                              6,
                              6
                            )}`}</h5>
                          </Tooltip>
                        ) : (
                          <Tooltip title={userProfile?.walletAddress}>
                            <h5>{`${shortenAddress(
                              isOwner && profile?.walletAddress
                                ? profile?.walletAddress
                                : userProfile?.walletAddress,
                              6,
                              6
                            )}`}</h5>
                          </Tooltip>
                        )}
                        {userProfile?.shortUrl && (
                          <Tooltip title={userProfile?.shortUrl}>
                            <h5 className="short-url">
                              @{shortenNameNoti(userProfile?.shortUrl, 13)}
                            </h5>
                          </Tooltip>
                        )}
                      </div>
                      <div className="general-information">
                        <div className="user-info-tabs">
                          <ButtonCustom
                            onClick={copyAddressToClipboard}
                            iconRight={
                              <img
                                style={{ width: "20px", height: "20px" }}
                                src={CopyIcon}
                                alt="Copy"
                              />
                            }
                            style={{ width: "auto" }}
                            color={"white"}
                            variant="outline-sub"
                            className="user-info-btn"
                          >
                            <span className="text-address-copy">
                              {shortenAddress(
                                isOwner && profile?.walletAddress
                                  ? profile?.walletAddress
                                  : userProfile?.walletAddress,
                                6,
                                6
                              )}
                            </span>
                          </ButtonCustom>
                          <ButtonCustom
                            onClick={(e) => {
                              e.preventDefault();
                              showFollowing();
                            }}
                            iconLeft={
                              <img
                                style={{ width: "20px", height: "20px" }}
                                src={UserIcon}
                                alt="Following"
                              />
                            }
                            style={{ width: "auto" }}
                            color={"white"}
                            variant="outline-sub"
                            className="user-info-btn"
                          >
                            <span>
                              {t("profile.following")}
                              {": "}
                              {numberOfFollowing
                                ? roundingNumber(numberOfFollowing)
                                : `0`}{" "}
                            </span>
                          </ButtonCustom>
                          <ButtonCustom
                            onClick={(e) => {
                              e.preventDefault();
                              showFollowers();
                            }}
                            iconLeft={
                              <img
                                style={{ width: "20px", height: "20px" }}
                                src={UserIcon}
                                alt="Follower"
                              />
                            }
                            style={{ width: "auto" }}
                            color={"white"}
                            variant="outline-sub"
                            className="user-info-btn"
                          >
                            <span>
                              {t("profile.followersCamelcase")}
                              {": "}
                              {numberOfFollowers
                                ? roundingNumber(numberOfFollowers)
                                : `0`}{" "}
                            </span>
                          </ButtonCustom>
                        </div>
                        <div
                          className={`information ${
                            theme === "dark-theme" ? "dark-information" : ""
                          }`}
                        >
                          {isOwner ? (
                            <Link to="/edit-profile">
                              {" "}
                              <ButtonCustom
                                iconLeft={
                                  <img
                                    style={{ width: "20px", height: "20px" }}
                                    src={EditIcon}
                                    alt="Upload"
                                  />
                                }
                                style={{ width: "auto" }}
                                color={"white"}
                                variant="outline-sub"
                                className="user-info-btn"
                              >
                                <span>{t("profile.edit")}</span>
                              </ButtonCustom>
                            </Link>
                          ) : checkFollowed(userProfile?.id) ? (
                            <div className="follow-btn">
                              <ButtonCustom
                                color={"white"}
                                variant="outline-sub"
                                className="user-info-btn"
                                style={{ width: "auto" }}
                                onClick={() =>
                                  handleFollow(userProfile?.walletAddress)
                                }
                                disabled={
                                  disabledState === userProfile?.id
                                    ? true
                                    : false
                                }
                              >
                                <span>{t("profile.unfollow")}</span>
                              </ButtonCustom>
                            </div>
                          ) : (
                            <div className="follow-btn">
                              <ButtonCustom
                                color={"white"}
                                variant="outline-sub"
                                className="user-info-btn"
                                style={{ width: "auto" }}
                                onClick={() =>
                                  handleFollow(userProfile?.walletAddress)
                                }
                                disabled={
                                  disabledState === userProfile?.id
                                    ? true
                                    : false
                                }
                              >
                                <span>{t("profile.followProfile")}</span>
                              </ButtonCustom>
                            </div>
                          )}

                          <div className="button-holder">
                            <div className="share-icon-container">
                              <ShareCustom
                                content={window.location.href}
                                url={window.location.href}
                                horizontalMode={true}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="social-info-user">
                      {socialChanel?.length > 0 && (
                        <Avatar.Group
                          maxCount={2}
                          className="social-list"
                          maxStyle={{
                            color: "#1890ff",
                            backgroundColor: "#afc4e23a",
                            cursor: "pointer",
                            marginLeft: 0,
                          }}
                        >
                          {socialChanel?.map((item, index) => (
                            <Tooltip key={index} title={item?.url}>
                              <div className="social-item">
                                <a
                                  href={`${renderLinkSocial(item?.name)}/${
                                    item?.url
                                  }`}
                                  target="_blank'"
                                  rel="noopener"
                                  className={
                                    item?.name === "Twitter" &&
                                    !userProfile?.isVerifyTwitter
                                      ? "unverified-twitter-link"
                                      : ""
                                  }
                                >
                                  {renderIconSocial(item?.name)}
                                  {item?.url
                                    ? `${shortenNameNoti(
                                        item?.url,
                                        isMobile ? 11 : 13
                                      )}`
                                    : ""}
                                </a>
                              </div>
                            </Tooltip>
                          ))}
                        </Avatar.Group>
                      )}

                      {userProfile?.personalSite && (
                        <div className="personal-site-container">
                          <Tooltip title={userProfile?.personalSite}>
                            <a
                              className="personal-site"
                              href={userProfile?.personalSite}
                              target="_blank"
                              rel="noreferrer"
                            >
                              <i className="ri-global-line"></i>
                              {shortenNameNoti(userProfile?.personalSite, 20)}
                            </a>
                          </Tooltip>
                        </div>
                      )}
                    </div>
                    <div className="about-description">
                      {userProfile.description?.length > 120 ? (
                        <div>
                          {showMore ? (
                            userProfile.description?.substring(0, 60) + "..."
                          ) : (
                            <p>{userProfile.description}</p>
                          )}
                          <span className="expandBtn" onClick={toggleShowMore}>
                            {showMore
                              ? t("collection.readMore")
                              : t("collection.showLess")}
                          </span>
                        </div>
                      ) : (
                        <p className="desc-text">{userProfile.description}</p>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="line-filter" />

            <div
              className="collection-tabs"
              style={{
                display: "flex",
                justifyContent: "flex-start",
                width: "100%",
                padding: "0 28px",
                overflowX: "auto",
              }}
            >
              <TabsCustom
                defaultActiveKey={activeTab}
                onChange={(key) => {
                  history.push({
                    search: `?tab=${key}`, // query string
                    state: {
                      // location state
                      update: true,
                    },
                  });
                  // setActiveTab(key);
                }}
              >
                <TabPane tab={t("profile.collected")} key={TABS.COLLECTED} />
                <TabPane tab={t("profile.referral")} key={TABS.REFERRAL} />
                <TabPane tab={t("profile.favorite")} key={TABS.FAVORITE} />
                <TabPane tab={t("profile.activity")} key={TABS.ACTIVITY} />
              </TabsCustom>
            </div>

            {/* <div className="profile-container_tabs-container">
                            <Row
                                gutter={[
                                    { xs: 0, sm: 2, md: 36 },
                                    { xs: 0, sm: 16, md: 36 }
                                ]}
                                className="profile-container_tabs-container_row profile-tab-main"
                            >
                                <Col span={24} sm={24} xs={24} md={24} lg={24} xl={24}>
                                    <Tabs
                                        activeKey={activeTab}
                                        centered
                                        className={`tabs ${theme === 'dark-theme' ? 'dark-tabs' : ''}`}
                                        onChange={key => setActiveTab(key)}
                                    >
                                        <TabPane tab={t('profile.creations')} key={2}>
                                            <div>
                                                <Tabs
                                                    activeKey={activeTabSub}
                                                    centered
                                                    className={`${width > 767 ? 'container' : ''} tabs tabs-inner`}
                                                    onChange={key => setActiveTabSub(key)}
                                                >
                                                    <TabPane tab={t('profile.onSale')} key={NFT_STATUS.ON_SALE}>
                                                        {numberNftsOnSale ? (
                                                            <ListCustom
                                                                active={activeTabSub}
                                                                userId={userProfile?.id}
                                                                walletAddress={userProfile?.walletAddress}
                                                            />
                                                        ) : (
                                                            <NoItemFound />
                                                        )}
                                                    </TabPane>
                                                    <TabPane tab={t('profile.owned')} key={NFT_STATUS.OWNER}>
                                                        {nftByOwned ? (
                                                            <ListCustom
                                                                active={activeTabSub}
                                                                userId={userProfile?.id}
                                                                walletAddress={userProfile?.walletAddress}
                                                            />
                                                        ) : (
                                                            <NoItemFound />
                                                        )}
                                                    </TabPane>
                                                    <TabPane tab={t('profile.created')} key={NFT_STATUS.CREATOR}>
                                                        {nftByCreated ? (
                                                            <ListCustom
                                                                active={activeTabSub}
                                                                userId={userProfile?.id}
                                                                walletAddress={userProfile?.walletAddress}
                                                            />
                                                        ) : (
                                                            <NoItemFound />
                                                        )}
                                                    </TabPane>
                                                    <TabPane tab={t('profile.liked')} key={NFT_STATUS.FAVORITE}>
                                                        {nftByLike ? (
                                                            <LikedTab
                                                                active={activeTabSub}
                                                                addressUrl={userProfile?.walletAddress}
                                                                walletAddress={userProfile?.walletAddress}
                                                            />
                                                        ) : (
                                                            <NoItemFound />
                                                        )}
                                                    </TabPane>
                                                    <TabPane tab={t('profile.notForSale')} key={NFT_STATUS.NOT_FOR_SALE}>
                                                        {numberNftsNotForSale ? (
                                                            <ListCustom
                                                                active={activeTabSub}
                                                                userId={userProfile?.id}
                                                                walletAddress={userProfile?.walletAddress}
                                                            />
                                                        ) : (
                                                            <NoItemFound />
                                                        )}
                                                    </TabPane>
                                                </Tabs>
                                            </div>
                                        </TabPane>

                                        <TabPane tab={t('profile.activity')} key="5">
                                            <ActivityProfile
                                                active={activeTab}
                                                addressUrl={userProfile?.walletAddress}
                                                isOwner={isOwner}
                                            />
                                        </TabPane>
                                        <TabPane tab={t('profile.collections')} key="6">
                                            <CollectionsProfile
                                                ownerAddress={userProfile?.walletAddress}
                                                isBackgroundWhite={false}
                                                active={activeTab}
                                                userId={userProfile?.id}
                                            />
                                        </TabPane>
                                    </Tabs>
                                </Col>
                            </Row>
                        </div>  */}

            <div className="profile-container_modals-container">
              <Modal
                className="following-container"
                title={t("profile.following")}
                destroyOnClose={true}
                visible={isFollowingVisible}
                onCancel={handleFollowingCancel}
                closeIcon={<img src={closeModalIcon} alt="Close modal icon" />}
                footer={null}
                centered
              >
                {followingList?.data?.length > 0 ? (
                  <ModalContent
                    profileId={profile?.id}
                    walletAddress={userProfile?.walletAddress}
                    list={followingList}
                    handleModalCancel={handleFollowingCancel}
                    showList={showFollowing}
                    t={t}
                    checkFollowed={checkFollowed}
                    handleFollow={handleFollow}
                    getCurrentList={getCurrentFollowingList}
                    type="following"
                    total={followingList?.total}
                    disabledState={disabledState}
                  />
                ) : (
                  <div
                    style={{
                      fontSize: 18,
                      fontWeight: 400,
                      textAlign: "center",
                    }}
                  >
                    {t("common.noResultToShow")}
                  </div>
                )}
              </Modal>
              <Modal
                className="followers-container"
                title={t("profile.followersCamelcase")}
                destroyOnClose={true}
                visible={isFollowersVisible}
                onCancel={handleFollowersCancel}
                closeIcon={<img src={closeModalIcon} alt="Close modal icon" />}
                footer={null}
                centered
              >
                {followerList?.data?.length > 0 ? (
                  <ModalContent
                    profileId={profile?.id}
                    walletAddress={userProfile?.walletAddress}
                    list={followerList}
                    handleModalCancel={handleFollowersCancel}
                    showList={showFollowers}
                    t={t}
                    checkFollowed={checkFollowed}
                    handleFollow={handleFollow}
                    getCurrentList={getCurrentFollowerList}
                    type="follower"
                    total={followerList?.total}
                    disabledState={disabledState}
                  />
                ) : (
                  <div
                    style={{
                      fontSize: 18,
                      fontWeight: 400,
                      textAlign: "center",
                    }}
                  >
                    {t("common.noResultToShow")}
                  </div>
                )}
              </Modal>
            </div>
          </div>
          <div className="container-tab-data">
            {activeTab === TABS.COLLECTED &&
              (nftByOwned ? (
                <ListCustom
                  active={activeTab}
                  userId={userProfile?.id}
                  walletAddress={userProfile?.walletAddress}
                />
              ) : (
                // <NoItemFound />
                <NoData />
              ))}
            {activeTab === TABS.REFERRAL &&
              (profile ? (
                <Refferal
                  profile={profile}
                  copyReferralToClipboard={copyReferralToClipboard}
                />
              ) : (
                <div></div>
              ))}
            {activeTab === TABS.FAVORITE &&
              (nftByLike ? (
                <LikedTab
                  active={activeTab}
                  addressUrl={userProfile?.walletAddress}
                  walletAddress={userProfile?.walletAddress}
                />
              ) : (
                // <NoItemFound />
                <NoData />
              ))}
            {activeTab === TABS.ACTIVITY && (
              <ActivityProfile
                addressUrl={userProfile?.walletAddress}
                isOwner={isOwner}
                walletAddress={
                  isOwner && profile?.walletAddress
                    ? profile?.walletAddress
                    : userProfile?.walletAddress
                }
              />
            )}
          </div>
        </>
      ) : (
        <div className="not-found-container">
          <h2 className="not-found-title">{t("pageNotFound")}</h2>
          <p className="not-found-description">
            {t("pageNotFound_description")}
          </p>
          <ButtonCustom onClick={() => history.goBack()} color="blue">
            {t("common.goBack")}
          </ButtonCustom>
        </div>
      )}
    </DefaultLayout>
  );
};

export default Profile;
