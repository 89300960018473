import Request from '../request'
import { customAxios } from 'request/customAxios'
import { getNFTImageName } from 'utils/image'
import { AWS_API_ENDPOINT, SERVER_API_ENDPOINT } from 'constants/envs'
import { USER_IS_BANNED_CODE } from 'constants/index'
import * as message from 'utils/custom-message'
import i18n from 'translation/i18n'

const userService = {
    login: async credentials => {
        try {
            const response = await Request.post(`${SERVER_API_ENDPOINT}/account/login`, credentials)
            return [response.data, null]
        } catch (error) {
            console.error('login', error.response)
            return [null, error]
        }
    },

    getPublicUserInfo: async credentials => {
        try {
            const response = await Request.get(
                `${SERVER_API_ENDPOINT}/account/check?walletAddress=${credentials.walletAddress}`
            )
            return [response.data, null]
        } catch (error) {
            console.error('getPublicUserInfo', error.response)
            return [null, error]
        }
    },

    signup: async credentials => {
        try {
            const response = await Request.post(`${SERVER_API_ENDPOINT}/account/register`, credentials)
            return [response.data, null]
        } catch (error) {
            message.error(error?.response?.data?.message)
            console.error('signup', error.response)
            return [null, error]
        }
    },

    editUserProfile: async data => {
        try {
            const res = await Request.patch(`${SERVER_API_ENDPOINT}/account`, data)
            return [res.data, null]
        } catch (error) {
            console.error('editUserProfile', error.response)
            return [null, error.response.data]
        }
    },
    updateUserImage: async data => {
        try {
            const res = await Request.patch(`${SERVER_API_ENDPOINT}/account/image`, data)
            return [res.data, null]
        } catch (error) {
            console.error('editUserProfile', error.response)
            return [null, error.response.data]
        }
    },
    getUserInfo: async queryParam => {
        try {
            const response = await Request.get(`${SERVER_API_ENDPOINT}/account/${queryParam}`)
            if (response.data === USER_IS_BANNED_CODE) {
                message.error(i18n.t('error.message.userHasBeenBanned'))
                return [null, { message: '' }]
            }
            return [response.data, null]
        } catch (error) {
            console.error('getUserInfo', error.response)
            return [null, error]
        }
    },
    getUserNftInfo: async address => {
        try {
            const response = await Request.get(`${SERVER_API_ENDPOINT}/nft/artist-profile/count-nft`, { address })
            if (response.data === USER_IS_BANNED_CODE) {
                message.error(i18n.t('error.message.userHasBeenBanned'))
                return [null, { message: '' }]
            }
            return [response.data, null]
        } catch (error) {
            console.error('getUserNftInfo', error.response)
            return [null, error]
        }
    },
    getUserByCustomUrl: async queryParam => {
        try {
            const response = await Request.get(`${SERVER_API_ENDPOINT}/account?customUrl=${queryParam.customUrl}`)
            return [response.data, null]
        } catch (error) {
            console.error('getUserByCustomUrl', error.response)
            return [null, error]
        }
    },
    confirmVerified: async queryParam => {
        try {
            const response = await Request.get(`${SERVER_API_ENDPOINT}/account/twitter/verify/check/post`, {
                username: queryParam
            })
            return [response.data, null]
        } catch (error) {
            console.error('confirmVerified', error.response)
            return [null, error]
        }
    },
    unLinkTwitter: async () => {
        try {
            const response = await Request.patch(`${SERVER_API_ENDPOINT}/account/unlink/twitter`)
            return [response.data, null]
        } catch (error) {
            console.error('unLinkTwitter', error.response)
            return [null, error]
        }
    },
    checkCustomUrl: async queryParam => {
        try {
            const response = await Request.get(
                `${SERVER_API_ENDPOINT}/account/check-info/username?shortUrl=${queryParam.shortUrl}`
            )
            return [response.data, null]
        } catch (error) {
            console.error('checkCustomUrl', error.response)
            return [null, error]
        }
    },
    checkEmail: async queryParam => {
        try {
            const response = await Request.get(
                `${SERVER_API_ENDPOINT}/account/check-info/email?email=${queryParam.email}`
            )
            return [response.data, null]
        } catch (error) {
            console.error('checkEmail', error.response)
            return [null, error]
        }
    },
    uploadAvatar: async ({ userId, imgFile }) => {
        const { type } = imgFile
        const extension = type.split('/')[1]

        const name = new Date().getTime()

        const accessToken = localStorage.getItem('accessToken')

        const config = {
            headers: {
                Authorization: accessToken,
                'x-amz-tagging': `token=${accessToken}`,
                'Content-Type': type
            }
        }
        try {
            const response = await customAxios({
                method: 'put',
                url: `${AWS_API_ENDPOINT}/user-avatar/${userId}/${name}.${extension}`,
                data: imgFile,
                headers: config.headers
            })
            return [response, null]
        } catch (error) {
            console.error('uploadAvatar', error.response)
            return [null, error]
        }
    },

    uploadCoverImage: async ({ userId, imgFile }) => {
        const { type } = imgFile
        const extension = type.split('/')[1]

        const name = new Date().getTime()

        const accessToken = localStorage.getItem('accessToken')

        const config = {
            headers: {
                Authorization: accessToken,
                'x-amz-tagging': `token=${accessToken}&type=cover`,
                'Content-Type': type
            }
        }
        try {
            const response = await customAxios({
                method: 'put',
                url: `${AWS_API_ENDPOINT}/user-avatar/${userId}/${name}.${extension}`,
                data: imgFile,
                headers: config.headers
            })
            return [response, null]
        } catch (error) {
            console.error('uploadCoverImage', error.response)
            return [null, error]
        }
    },
    followUser: async walletAddress => {
        try {
            const response = await Request.post(`${SERVER_API_ENDPOINT}/follow`, { walletAddress })
            return [response.data, null]
        } catch (error) {
            console.error('followUser', error.response)
            return [null, error]
        }
    },

    getFollowingList: async (address, page = 1, limit = 100) => {
        try {
            const response = await Request.get(`${SERVER_API_ENDPOINT}/follow/following/${address}`, {
                page,
                limit
            })
            return [response.data, null]
        } catch (error) {
            console.error('getFollowingList', error.response)
            return [null, error]
        }
    },

    getFollowerList: async (address, page = 1, limit = 100) => {
        try {
            const response = await Request.get(`${SERVER_API_ENDPOINT}/follow/followers/${address}`, {
                page,
                limit
            })
            return [response.data, null]
        } catch (error) {
            console.error('getFollowerList', error.response)
            return [null, error]
        }
    },

    getUploadAvatarUrl: async data => {
        const { imgFile, type } = data
        const accessToken = localStorage.getItem('accessToken')
        // const userId = localStorage.getItem('userId')
        let imageId = getNFTImageName(imgFile)
        try {
            const res = await customAxios({
                method: 'get',
                url:  `${SERVER_API_ENDPOINT}/upload/pre-signed/${type}/${imageId}`,
                headers: {
                    Authorization: `Bearer ${accessToken}`
                }
            })
            return [res.data, null]
        } catch (error) {
            console.error('getUploadAvatarUrl', error.response)
            return [null, error]
        }
    },

    postReceiveEmailUpdate: async data => {
        try {
            const response = await Request.post(`${SERVER_API_ENDPOINT}/subscribe`, data)
            return [response.data, null]
        } catch (error) {
            console.error('postReceiveEmailUpdate', error.response)
            return [null, error]
        }
    },

    postUserContact: async data => {
        try {
            const response = await Request.post(`${SERVER_API_ENDPOINT}/contact`, data)
            return [response.data, null]
        } catch (error) {
            return [null, error]
        }
    }
}

export default userService
