import DefaultImage from 'assets/images/default.svg'
import DefaultImageSmapoke from 'assets/images/smapocke.png'
import DefaultImageAvatar from 'assets/icons/avatar-default.svg'
import DefaultImageCover from 'assets/images/cover-placeholder.png'
import DefaultCollectionCover from 'assets/images/default-bg-collection.jpg'
import { AWS_CLOUDFRONT_ENDPOINT } from 'constants/envs'

export const convertImage = (image, isCustomImageDefault = false) => {
    if (image?.includes('http')) {
        return image
    } else if (image?.includes('ipfs://ipfs/')) {
        const ipfsIndex = image.indexOf('//ipfs/')
        const urlHash = image.substring(ipfsIndex + 7)
        return `https://ipfs.io/ipfs/${urlHash}`
    } else if (image?.includes('ipfs://')) {
        const ipfsIndex = image.indexOf('//')
        const urlHash = image.substring(ipfsIndex + 2)
        return `https://ipfs.io/ipfs/${urlHash}`
    }
    return image ? `${AWS_CLOUDFRONT_ENDPOINT}/${image}` : isCustomImageDefault ? DefaultImageSmapoke : DefaultImage
}

export const convertImageAvatar = image => {
    if (image?.includes('https') || image?.includes('http')) {
        return image
    } else if (image?.includes('ipfs://ipfs/')) {
        const ipfsIndex = image.indexOf('//ipfs/')
        const urlHash = image.substring(ipfsIndex + 7)
        return `https://ipfs.io/ipfs/${urlHash}`
    } else if (image?.includes('ipfs://')) {
        const ipfsIndex = image.indexOf('//')
        const urlHash = image.substring(ipfsIndex + 2)
        return `https://ipfs.io/ipfs/${urlHash}`
    }
    return image ? `${AWS_CLOUDFRONT_ENDPOINT}/${image}` : DefaultImageAvatar
}

export const convertImageCover = image => {
    if (image?.includes('https') || image?.includes('http')) {
        return image
    }
    return image ? `${AWS_CLOUDFRONT_ENDPOINT}/${image}` : DefaultImageCover
}

export const convertCollectionCover = image => {
    if (image?.includes('https') || image?.includes('http')) {
        return image
    }
    return image ? `${AWS_CLOUDFRONT_ENDPOINT}/${image}` : DefaultCollectionCover
}

export const getNFTImageName = imgFile => {
    let { type } = imgFile
    let name = new Date().getTime()
    let extension = type.split('/')[1]

    return `${name}.${extension}`
}

export const getNFTVideoName = imgFile => {
    let type = imgFile.name
    let name = new Date().getTime()
    let result = type.split('.')
    return `${name}.${result[result.length - 1]}`
}
