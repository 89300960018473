import React, {
  useEffect,
  useState,
  useCallback,
  useMemo,
  useRef,
  useContext,
} from "react";
import { useTranslation } from "react-i18next";
import { Row, Col, Dropdown, Button } from "antd";
import { useIntersection } from "react-use";
import ReactLoading from "react-loading";
import { useSelector } from "react-redux";

import {
  ButtonCustom,
  InputCustom,
  NoData,
  SelectCustom,
} from "components/common";
import { CardItem } from "components/modules";
import * as message from "utils/custom-message";
import nftService from "service/nftService";
import "./style.scss";
import styles from "./style.module.scss";
import ArrowDown from "assets/icons/arrow-down.svg";
import LightSearchIcon from "assets/icons/search-icon-mobile-blue.svg";

//constants
import { SORT_BY } from "constants/index";
import CustomSwitch from "components/common/switch";
import { ThemeContext } from "ThemeProvider";
import NoItemFound from "pages/search/components/NotFound";
import SearchFilterNft from "./SearchFilterNft";

const optionFilter = [
  { label: "Verified Only", key: "verification" },
  { label: "Show NSFW", key: "nsfw" },
  { label: "Show lazy minted", key: "lazyMinted" },
];

const LIMIT = 12;

const ListCustom = (props) => {
  const { active, userId, walletAddress } = props;
  const { t } = useTranslation();
  const { theme } = useContext(ThemeContext);
  const [list, setList] = useState([]);
  const [page, setPage] = useState(1); // init: {current: 1}
  const [total, setTotal] = useState(0);

  const system = useSelector((state) => state?.system);
  const [autoFetch, setAutoFetch] = useState(false);
  const [loading, setLoading] = useState(false);
  const anchorEl = useRef(null);
  const intersection = useIntersection(anchorEl, {
    root: null,
    rootMargin: "0px",
    threshold: 1,
  });
  const inView =
    intersection &&
    intersection.isIntersecting &&
    intersection.intersectionRatio >= 1;
  const [filter, setFilter] = useState();
  const [filterByOption, setFilterByOption] = useState({
    verification: false,
    nsfw: false,
    lazyMinted: false,
  });
  const [searchText, setSearchText] = useState("");
  const [dependencySearchingState, setDependencySearchingState] =
    useState(false);

  const isLoadMore = useMemo(() => {
    return list?.length < total;
  }, [list?.length, total]);

  const infinity = autoFetch && inView && isLoadMore;

  const handleLoadMore = useCallback(
    () => {
      if (loading) {
        return;
      }
      const isLoadMore = page * LIMIT < total;

      if (isLoadMore) {
        setPage((prev) => prev + 1);
        setDependencySearchingState(true);
      }
    }, // eslint-disable-next-line react-hooks/exhaustive-deps
    [total, loading]
  );

  useEffect(() => {
    setList([]);
    setTotal(0);
    setPage(1);
    setAutoFetch(false);
  }, [userId, active, walletAddress]);

  useEffect(() => {
    let timer = setTimeout(() => {
      setList([]);
      setTotal(0);
      setPage(1);
      setAutoFetch(false);
      setDependencySearchingState(true);
    }, 1000);

    return () => clearTimeout(timer);
  }, [searchText, filter, filterByOption]);

  // useEffect(() => {
  //   if (infinity) {
  //     handleLoadMore();
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [infinity]);

  const fetchListNft = async () => {
    setLoading(true);
    let data = {
      page,
      limit: LIMIT,
      sort: filter,
      walletAddress,
      type: active,
      // isVerify: filterByOption?.verification ? 1 : 0,
      // nsfw: filterByOption?.nsfw ? 1 : 0,
      // isMinted: filterByOption?.lazyMinted ? 1 : 0,
      isVerify: filterByOption?.verification ? 1 : "",
      nsfw: filterByOption?.nsfw ? 1 : "",
      isMinted: filterByOption?.lazyMinted ? 1 : "", // the previous value is number 0, not empty string "", but i see use 0 can not get all nft api by default when visiting the site my profile
      title: searchText,
    };
    if (userId) {
      const [res, err] = await nftService.getListNft(data);
      if (res) {
        setList([...list, ...res?.items]);
        setTotal(res?.meta?.totalItem);
      } else if (err) message.error(t("error.message.somethingWentWrong"));
    }
    setDependencySearchingState(false);
    setLoading(false);
  };

  useEffect(() => {
    if (page && userId && dependencySearchingState) {
      fetchListNft();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, userId, dependencySearchingState]); // init dependencies [page]

  const handleChangeSort = (value) => {
    setFilter(value);
  };

  const handleFilterBy = useCallback(
    (key) => {
      setFilterByOption({ ...filterByOption, [key]: !filterByOption[key] });
    },
    [filterByOption]
  );

  const handleChangeSearch = (e) => {
    setSearchText(e.target.value);
  };

  const dropDownSortBy = useCallback(
    () => (
      <div
        className={`dropdown-collection dropdown-explorer dropdown-explorer dropdown-sortby ${theme === "dark-theme" ? "dropdown-explore-dark-antd" : ""
          }`}
      >
        <div className="collection-holder">
          <div className="filter-title">{t('common.sortAndFilter.sort.title')}</div>
          {SORT_BY.map((sort, index) => {
            return (
              <div
                className="collection-item-holder"
                onClick={() => handleChangeSort(sort)}
                key={index}
              >
                <div className="collection-name">
                  <div className="collection-name-text-holder">
                    <span className="collection-name-text">{sort.label}</span>
                  </div>
                </div>
                <div>
                  {filter === sort.value && <i className="ri-check-line"></i>}
                </div>
              </div>
            );
          })}
          <div className="filter-title">{t('common.sortAndFilter.options.title')}</div>

          {optionFilter?.map((item, index) => (
            <div key={index} className="verification-holder">
              {item.label}
              <CustomSwitch
                onChange={() => handleFilterBy(item.key)}
                value={filterByOption[item.key]}
                disableLabel={true}
                className="switch-filter"
              />
            </div>
          ))}
        </div>
      </div>
    ),

    [filter, handleFilterBy, filterByOption, theme]
  );

  const _renderItemsPanel = () => (
    <>
      <div className="collection-detail-filters container">
        <div className={`search ${styles.searchBox}`}>
          <i className="searchIcon ri-search-line"></i>
          <InputCustom
            className={`search-box ${styles.search}`}
            placeholder={t("header.searchInput")}
            // value={keyword}
            // onPressEnter={handleSearchMobile}
            onChange={handleChangeSearch}
          />
        </div>
        <SelectCustom
          className={`all-categories-button ${styles.select} ${styles.select_filter}`}
          // placeholder={t(`common.sortAndFilter.sort.${sortBy}`)}
          placeholder={filter ? filter : "Sort & Filter"}
          categoryIcon={true}
          dropdownClassName="all-categories-button__dropdown--filter"
          dropdownRender={dropDownSortBy}
        />
      </div>
      {/* {loading && (
        <ReactLoading
          className="collection-list-btn"
          type="spinningBubbles"
          color="#002979"
        />
      )} */}
    </>
  );

  return (
    <div className="list-container">
      <div className="filter-option">
        {/* <Dropdown trigger={["click"]} overlay={dropDownSortBy}>
          <Button
            className={`btn-filter btn-filter-custom ${
              theme === "dark-theme" ? "btn-filter-custom-dark" : ""
            }`}
          >
            Sort&Filter <img src={ArrowDown} alt="ArrowDown" />
          </Button>
        </Dropdown> */}
        {/* {_renderItemsPanel()} */}
        <SearchFilterNft
          filter={filter}
          handleFilterBy={handleFilterBy}
          filterByOption={filterByOption}
          handleChangeSearch={handleChangeSearch}
          handleChangeSort={handleChangeSort}
        />
      </div>
      <Row
        gutter={[{ xs: 6, sm: 6, md: 20, lg: 20, xl: 20 }, 20]}
        className="list-inner"
      >
        {list?.map((item) => {
          return (
            <Col key={item.id} xxl={6} xl={6} lg={8} md={12} sm={12} xs={24}>
              <CardItem
                className=""
                data={item}
                walletAddress={walletAddress}
              />
            </Col>
          );
        })}
        {/* {!list?.length && !loading && <NoItemFound />} */}
        {!list?.length && !loading && <NoData />}
      </Row>
      {isLoadMore && !loading && (
        <div className="d-flex justify-content-center">
          <ButtonCustom
            color="blue"
            className="btn-load"
            onClick={async () => {
              if (autoFetch) return;
              await handleLoadMore();
              // setAutoFetch(true);
            }}
          >
            {t("profile.loadMore")}
          </ButtonCustom>
        </div>
      )}
      {loading && !system?.loading && (
        <div className="d-flex justify-content-center">
          <ReactLoading
            className="loading-more"
            type="spinningBubbles"
            color="#002979"
          />
        </div>
      )}
      <div ref={anchorEl}></div>
    </div>
  );
};

export default ListCustom;
