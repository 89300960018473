import { Col, Row } from 'antd'
import ReactLoading from 'react-loading'
import { CardUser } from 'components/modules'
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import nftService from 'service/nftService'
import NoItemFound from '../NotFound'
import * as message from 'utils/custom-message'
import { useResizeWindow } from 'utils/hook'
import { SCREEN_SIZE } from 'constants/index'
import { useIntersection } from 'react-use'
import { ButtonCustom, NoData } from 'components/common'
import { useTranslation } from 'react-i18next'

const STEP = 12
const UsersGroups = ({ keyword }) => {
    const { t } = useTranslation();
    const [isTablet] = useResizeWindow(SCREEN_SIZE.tablet)
    const anchorEl = useRef(null)
    const [users, setUsers] = useState([])
    const [total, setTotal] = useState(0)
    const [loading, setLoading] = useState(false)
    const [limit] = useState(12)
    const [page, setPage] = useState(1)
    const [autoFetch, setAutoFetch] = useState(isTablet)

    const isLoadMore = useMemo(() => {
        return users?.length < total
    }, [users, total])
    const intersection = useIntersection(anchorEl, { root: null, rootMargin: '0px', threshold: 1 })
    const inView = intersection && intersection.isIntersecting && intersection.intersectionRatio >= 1
    const infinity = autoFetch && inView && isLoadMore

    const handleSearch = useCallback(
        async dataProp => {
            setLoading(true)
            const [data, err] = await nftService.getUsersSearch({
                keyword: dataProp?.keyword || keyword,
                limit: dataProp?.limit || limit,
                page: dataProp?.isReload ? 1 : dataProp?.page || page
            })
            if (data) {
                if (dataProp?.isReload) {
                    setUsers(data?.items)
                } else {
                    setUsers([...users, ...data?.items])
                }
                setLoading(false)
                setTotal(data?.meta?.totalItem)
            }
            if (err) {
                setLoading(false)
                message.error(t('error.message.somethingWentWrong'))
            }
        },
        [keyword, limit, page, users]
    )

    const handeLoadMore = useCallback(() => {
        if (loading) {
            return
        }
        const next = page * STEP
        next < total && setPage(page + 1)
        next < total && handleSearch({ page: page + 1 })
    }, [loading, page, total])

    useEffect(() => {
        if (infinity) {
            handeLoadMore()
        }
    }, [infinity])

    useEffect(() => {
        handleSearch({ keyword: keyword || "", page: 1, isReload: true })
        setPage(1)
    }, [keyword])

    return (
        <div className="search-groups-container">
            <Row gutter={[{ xs: 6, sm: 6, md: 20, lg: 20, xl: 20 }, 20]}>
                {!users || (Array.isArray(users) && users.length === 0) && !loading ? (
                    <NoData />
                ) : (
                    users.map((d, index) => (
                        <Col xs={{ span: 24 }} md={{ span: 12 }} lg={{ span: 8 }} xl={{ span: 6 }} key={index}>
                            <CardUser data={d} />
                        </Col>
                    ))
                )}
            </Row>
            <div ref={anchorEl}></div>
            {isLoadMore && !autoFetch && (
                <div className="w-100 btn-load-more d-flex justify-content-center align-items-center">
                    <ButtonCustom
                        className="collection-list-btn br-12"
                        color="blue"
                        onClick={async () => {
                            if (autoFetch) return
                            await handeLoadMore()
                            setAutoFetch(true)
                        }}
                    >
                        {t('common.loadingMore')}
                    </ButtonCustom>
                </div>
            )}
            {loading && (
                <div className="w-100 d-flex justify-content-center align-items-center">
                    <ReactLoading
                        className="btn-load d-flex justify-content-center align-items-center"
                        type="spinningBubbles"
                        color="#002979"
                    />
                </div>
            )}
        </div>
    )
}

export default UsersGroups
