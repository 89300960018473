import React, { useContext, useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Link } from 'react-router-dom'
import ReactLoading from 'react-loading'
import { useTranslation } from 'react-i18next'
import { Modal } from 'antd'
import { Helmet } from 'react-helmet'
import { Tabs } from 'antd'
import { useAccount, useChainId, useConnect } from 'wagmi';

import { loginWallet } from 'store/actions/user'
import Logo from 'assets/images/connect-logo.svg'
import DefaultLayout from 'components/layout/default-layout'
import { ButtonCustom } from 'components/common'
import { MAIN_TITLE, NETWORK_OPTIONS } from 'constants/index'
import './style.scss'
import { ThemeContext } from '../../ThemeProvider'
import MetaMaskCard from './MetaMaskCard'
import WalletConnectCard from './WalletConnectCard'

const { TabPane } = Tabs

const ConnectWallet = props => {
    const { theme } = useContext(ThemeContext)
    const { t } = useTranslation()
    const [networkType, setNetworkType] = useState(localStorage.getItem('networkType') || NETWORK_OPTIONS[0].value)
    const { address } = useAccount();
    const chainId = useChainId();
    const { connectors } = useConnect();


    const dispatch = useDispatch()

    const { profile, currentUserProfile } = useSelector(state => state.user)
    const { isLoadingConnectWallet } = useSelector(state => state.login)
    const collectible = useSelector(state => state.collectible.data)
    const collection = useSelector(state => state.collection.collection)

    useEffect(() => {
        if (!address || !chainId) {
            return;
        }

        dispatch(loginWallet({account: address?.toLowerCase(), chainId}));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [address, chainId])

    useEffect(() => {
        const { location, history } = props
        if (profile?.walletAddress && address) {
            if (location?.state?.from.includes('user')) {
                history.push(currentUserProfile?.address ? `/user/${currentUserProfile?.address}` : `/`)
            } else if (location?.state?.from?.includes('customUrl')) {
                history.push(currentUserProfile?.shortUrl ? `${currentUserProfile?.shortUrl}` : `/`)
            } else if (location?.state?.from?.includes('owner')) {
                history.push(`/user/${profile?.walletAddress}`)
            } else if (location?.state?.from?.includes('following')) {
                history.push('/following')
            } else if (location?.state?.from?.includes('edit')) {
                history.push('/edit-profile')
            } else if (location?.state?.from?.includes('collectible')) {
                history.push(
                    collectible?.id
                        ? `/nft/${collectible?.collection?.id || collectible?.collection?.address}:${
                              collectible?.tokenId || location?.state?.tokenId
                          }`
                        : `/`
                )
            } else if (location?.state?.from?.includes('collection')) {
                history.push(`/collection/${collection?.shortUrl || collection?.id || collection?.address}`)
            } else if (location?.state?.from?.includes('activity')) {
                history.push(`/activity`)
            } else if (location?.state?.from?.includes('staking')) {
                history.push(`/staking`)
            } else {
                history.push('/')
            }
        }
    }, [
        profile?.walletAddress,
        props,
        dispatch,
        profile,
        collectible,
        collection,
        currentUserProfile?.address,
        currentUserProfile?.customUrl,
        currentUserProfile?.shortUrl,
        address
    ])

    const [isModalVisible, setIsModalVisible] = useState(false)

    const showModal = () => {
        setIsModalVisible(true)
    }

    const handleCancel = () => {
        setIsModalVisible(false)
    }

    // return (
    //     <DefaultLayout>
    //         <Helmet>
    //             <meta charSet="utf-8" />
    //             <title>{`${MAIN_TITLE} - Connect wallet`}</title>
    //             <meta name="description" content={`${MAIN_TITLE} - Connect wallet`} />
    //         </Helmet>
    //         <div className="connect-waller__container">
    //             <div className="nav-link">
    //                 <ButtonCustom color={theme === 'dark-theme' ? 'dark' : 'white'}>
    //                     <Link to="/">{t('connectWallet.backToHome')}</Link>
    //                 </ButtonCustom>
    //             </div>
    //             <div className="main">
    //                 <img className="logo" src={Logo} alt="" />
    //                 <p className="title">Connect your wallet</p>
    //                 <div>
    //                     <span className="description">
    //                         Connect with one of available wallet providers or create a new wallet.
    //                     </span>
    //                     <span className="walletQA" onClick={showModal}>
    //                         {' '}
    //                         What is a wallet?
    //                     </span>
    //                 </div>
    //                 <Tabs
    //                     defaultActiveKey={networkType}
    //                     onChange={e => {
    //                         setNetworkType(e)
    //                         localStorage.setItem('networkType', e)
    //                     }}
    //                     className={`network-tabs ${theme === 'dark-theme' ? 'dark-network-tabs' : ''}`}
    //                 >
    //                     {NETWORK_OPTIONS.map(network => {
    //                         return (
    //                             <TabPane
    //                                 tab={
    //                                     <span className="label">
    //                                         <img src={network.icon} alt={network.label} />
    //                                         <span>{network.label}</span>
    //                                     </span>
    //                                 }
    //                                 key={network.value}
    //                             />
    //                         )
    //                     })}
    //                 </Tabs>
    //                 <div className="connect-wrapper">
    //                     <MetaMaskCard connectors={connectors} networkType={networkType} />
    //                     <WalletConnectCard connectors={connectors} networkType={networkType} />
    //                 </div>
    //             </div>

    //             <Modal className="wallet-info" centered footer={null} onCancel={handleCancel} visible={isModalVisible}>
    //                 <h1>What is a wallet?</h1>
    //                 <p>
    //                     Wallets are used to send, receive, and store digital assets like Ether. Wallets come in many
    //                     forms. They are either built into your browser, an extension added to your browser, a piece of
    //                     hardware plugged into your computer, or even an app on your phone.
    //                 </p>
    //             </Modal>
    //             <Modal
    //                 closable={false}
    //                 className="custom-loading-modal"
    //                 centered
    //                 footer={null}
    //                 visible={isLoadingConnectWallet}
    //             >
    //                 <ReactLoading type="spinningBubbles" color="#002979" />
    //             </Modal>
    //         </div>
    //     </DefaultLayout>
    // )
    return (
        <DefaultLayout>
            <Helmet>
                <meta charSet="utf-8" />
                <title>{`${MAIN_TITLE} - Connect wallet`}</title>
                <meta name="description" content={`${MAIN_TITLE} - Connect wallet`} />
            </Helmet>
            <div className="connect-waller__container">
                <div className="main">
                    <p className="title">{t('connectWallet.title')}</p>
                    <div className="connect-content">
                        <span className="description">
                            {t('connectWallet.description')}
                        </span>
                        <span className="walletQA" onClick={showModal}>
                            {' '}
                            {t('connectWallet.whatIsWallet')}
                        </span>
                    </div>
                    <Tabs
                        defaultActiveKey={networkType}
                        onChange={e => {
                            setNetworkType(e)
                            localStorage.setItem('networkType', e)
                        }}
                        className={`network-tabs ${theme === 'dark-theme' ? 'dark-network-tabs' : ''}`}
                    >
                        {NETWORK_OPTIONS.map(network => {
                            return (
                                <TabPane
                                    tab={
                                        <span className="label">
                                            <img src={network.icon} alt={network.label} />
                                            <span>{network.label}</span>
                                        </span>
                                    }
                                    key={network.value}
                                />
                            )
                        })}
                    </Tabs>
                    <div className="connect-wrapper">
                        <MetaMaskCard connectors={connectors} networkType={networkType} />
                        <WalletConnectCard connectors={connectors} networkType={networkType} />
                    </div>
                </div>

                <Modal className="wallet-info" centered footer={null} onCancel={handleCancel} visible={isModalVisible}>
                    <h1>{t('connectWallet.whatIsWallet')}</h1>
                    <p>
                        {t('connectWallet.walletAnswer')}
                    </p>
                </Modal>
                <Modal
                    closable={false}
                    className="custom-loading-modal"
                    centered
                    footer={null}
                    visible={isLoadingConnectWallet}
                >
                    <ReactLoading type="spinningBubbles" color="#002979" />
                </Modal>
            </div>
        </DefaultLayout>
    )
}

export default ConnectWallet
