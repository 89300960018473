import React, { useState, useEffect, useCallback, useContext, useMemo } from 'react'
import { useWindowSize } from 'react-use'
import ReactLoading from 'react-loading'
import { Row, Col, Tooltip, Avatar } from 'antd'
import { Link, useHistory } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { useChainId } from 'wagmi'
import BigNumber from 'bignumber.js'

import * as message from 'utils/custom-message'
import { ERROR_CODE_USER_DENIED_METAMASK, SOCKET_EVENTS } from 'constants/index'
import CancelModal from './CancelModal'
import ProcessBuyModal from './FixPrice/ProcessModal'
import BuyModalFixPrice from './FixPrice/BuyModal'
import OfferFormModal from './OpenForOffer/PidForm'
import MakeOfferSuccessModal from './OpenForOffer/MakeOfferSuccess'
import NoItem from 'assets/images/no-item.png'
import { handleCancelListingOrder } from 'blockchain/utils'
import shrinkAddress from 'utils/shrinkAddress'
import { ButtonCustom, NoData } from 'components/common'
import { PaginationCustom } from 'components/common'
import { isUserAuthenticated } from 'utils/auth'
import { useAuth } from 'hooks/useAuth'
import { socket } from 'config'
import { getNftSales, changePageMultipleSelling } from 'store/actions/nft'

import './style.scss'
import { ThemeContext } from '../../../../../ThemeProvider'
import { getPrice } from 'service/getPrice'
import CustomVerifyIcon from 'components/common/custom-verify-icon'
import { convertImageAvatar } from 'utils/image'

const getRate = async (currencyToken) => {
    const price = await getPrice(currencyToken)
    return price;
}

const Offers = () => {
    const { theme } = useContext(ThemeContext)
    const { t } = useTranslation()
    const dispatch = useDispatch()
    const history = useHistory()
    const chainId = useChainId();
    const { width } = useWindowSize()
    const [, handleSignWallet] = useAuth()
    const { id } = useSelector(state => state?.collectible?.data)
    const { items: nftSales, meta } = useSelector(state => state?.collectible?.nftSales)
    const { pageSellMultiple, loadingSellMultiple } = useSelector(state => state?.collectible)
    const { profile } = useSelector(state => state.user)
    const [isShowBuyFixPriceProcessing, setIsShowBuyFixPriceProcessing] = useState(false)
    const [isShowDoneBuyFixPriceProcessing, setIsShowDoneBuyFixPriceProcessing] = useState(false)
    const [buyFixPriceTransferData, setBuyFixPriceTransferData] = useState({})
    const [actionMetadata, setActionMetadata] = useState(null)
    const [isOpenBuyFixPrice, setIsOpenBuyFixPrice] = useState(false)
    const [isCancelFixPrice, setIsCancelFixPrice] = useState(false)
    const [isCancelingFixPrice, setIsCancelingFixPrice] = useState(false)
    const [isShowOfferModal, setIsShowOfferModal] = useState(false)
    const [isShowSuccessOfferModal, setIsShowSuccessOfferModal] = useState(false)
    const [cancelMetadata, setCancelMetadata] = useState(null)

    const [renderNftSales, setRenderNftSales] = useState([])

    useEffect(() => {
        if (nftSales && nftSales.length) {
            const handleExchange = async () => {
                const convertedList = await Promise.all(nftSales?.map(async (nftSale) => {
                    const exchangeRate = await getRate(nftSale.currencyToken)
                    return {
                        ...nftSale,
                        exchangedPrice: nftSale.price * exchangeRate
                    }
                }))
                setRenderNftSales([...convertedList])
            }
            handleExchange();
        }
    }, [meta])

    const isOwner = useCallback(
        sale => {
            return sale?.user?.id === profile?.id
        },
        [profile?.id]
    )

    const onConfirmCancelFixPrice = async () => {
        setIsCancelingFixPrice(true)

        const [, error] = await handleCancelListingOrder(cancelMetadata.signatureSale, chainId)

        if (error) {
            setIsCancelFixPrice(false)
            setIsCancelingFixPrice(false)
            if (error?.code === ERROR_CODE_USER_DENIED_METAMASK) {
                return message.error(t('error.message.declinedActionWallet'))
            }
            return message.error(
                t('error.message.cannotContinueAction')
            )
        }
    }

    const cancelSaleSuccess = () => {
        message.success(t('success.message.cancelSuccessfully'))
    }

    const handleMakeAnOffer = async bid => {
        setActionMetadata(bid)
        if (!profile?.id) {
            return history.push({ pathname: '/connect', state: { from: 'collectible' } })
        }
        if (!isUserAuthenticated()) {
            const res = await handleSignWallet()
            if (res === false) return
        }
        setIsShowOfferModal(true)
    }

    const handleOpenFixPrice = async bid => {
        setActionMetadata(bid)
        if (!profile?.id) {
            return history.push({ pathname: '/connect', state: { from: 'collectible' } })
        }
        if (!isUserAuthenticated()) {
            const res = await handleSignWallet()
            if (res === false) return
        }
        setIsOpenBuyFixPrice(true)
    }

    const handleCancelListing = async bid => {
        setCancelMetadata(bid)
        if (!profile?.id) {
            return history.push({ pathname: '/connect', state: { from: 'collectible' } })
        }
        if (!isUserAuthenticated()) {
            const res = await handleSignWallet()
            if (res === false) return
        }

        if (bid.price) {
            setIsCancelFixPrice(true)
            return
        }
    }

    const onChangePage = value => dispatch(changePageMultipleSelling({ value }))

    const getList = async () => {
        dispatch(getNftSales({ nftId: id, page: pageSellMultiple, limit: 5 }))
    }

    useEffect(() => {
        getList()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pageSellMultiple, id, profile?.walletAddress])

    useEffect(() => {
        if (socket) {
            socket.on(SOCKET_EVENTS.CANCEL_INTERNAL_SALE, res => {
                if (res?.data?.userId === profile?.id && res?.data?.nftId === id) {
                    cancelSaleSuccess()
                    setIsCancelingFixPrice(false)
                    setIsCancelFixPrice(false)
                }
            })
        }
        return () => socket.off(SOCKET_EVENTS.CANCEL_INTERNAL_SALE)
    }, [profile?.id, dispatch, id])

    useEffect(() => {
        if (socket) {
            socket.on(SOCKET_EVENTS.TRANSFER_NFT_SUCCESS, res => {
                if (
                    (res?.data?.toUserId === profile?.id || res?.data?.fromUserId === profile?.id) &&
                    res?.data?.nftId === id
                ) {
                    setIsShowDoneBuyFixPriceProcessing(false)
                    setIsShowBuyFixPriceProcessing(false)
                }
                return () => socket.off(SOCKET_EVENTS.TRANSFER_NFT_SUCCESS)
            })
        }
    }, [profile?.id, id, dispatch])

    return (
        <div className="listings__container">
            {loadingSellMultiple ? (
                <ReactLoading type="spinningBubbles" color="#002979" className="listings__loading" />
            ) : nftSales?.length > 0 ? (
                <>
                    <PaginationCustom total={meta.totalItem} value={pageSellMultiple} onChangePage={onChangePage} />
                    {width > 992 ? (
                        <div className={`listings__table ${theme === 'dark-theme' ? 'dark-offers__table' : ''}`}>
                            <Row align="center" justify="space-between">
                                <Col>{t('common.price')}</Col>
                                <Col>{t('nftDetail.usdPrice')}</Col>
                                <Col>{t('common.quantity')}</Col>
                                <Col>{t('nftDetail.expiration')}</Col>
                                <Col>{t('common.from')}</Col>
                                <Col>{t('common.buy')}</Col>
                            </Row>
                            {renderNftSales?.map(nftSale => (
                                <Row align="center" justify="space-between" key={nftSale?.id}>
                                    <Col>
                                        {!!Number(nftSale?.price)
                                            ? `${BigNumber(nftSale?.price * 1)
                                                .decimalPlaces(8)
                                                .toFormat()
                                                .toString()}
                                                ${nftSale.currencyToken?.toUpperCase()}`
                                            : '--'}
                                    </Col>
                                    <Col>
                                        {!!Number(nftSale?.exchangedPrice)
                                            ? `$ ${BigNumber(nftSale?.exchangedPrice)
                                                .decimalPlaces(8)
                                                .toFormat()
                                                .toString()}`
                                            : '--'}
                                    </Col>
                                    <Col>{nftSale?.quantity}</Col>
                                    <Col>{nftSale?.expireDate}</Col>
                                    <Col>
                                        {nftSale?.user && (
                                            <div className="d-flex user-info">
                                                <span
                                                    onClick={e => {
                                                        history.push(
                                                            nftSale?.user?.shortUrl ? `/${nftSale?.user?.shortUrl}` : `/user/${nftSale?.user?.walletAddress}`
                                                        )
                                                        handleClickUser(e, nftSale?.user?.walletAddress)
                                                    }}
                                                    className="cursor-pointer"
                                                >
                                                    <div className="avatar">
                                                        <Avatar alt="example" src={convertImageAvatar(nftSale?.user?.avatarImg)} />
                                                        {nftSale?.user?.isVerify ? <CustomVerifyIcon className="icon" /> : null}
                                                    </div>
                                                </span>
                                                <Tooltip
                                                    title={
                                                        nftSale?.user?.username
                                                            ? nftSale?.user?.username
                                                            : nftSale?.user?.walletAddress
                                                    }
                                                    overlayClassName="custom-tooltip"
                                                >
                                                    <Link
                                                        to={
                                                            nftSale?.user?.shortUrl
                                                                ? `/${nftSale?.user?.shortUrl}`
                                                                : `/user/${nftSale?.user?.walletAddress}`
                                                        }
                                                    >
                                                        {nftSale?.user?.walletAddress === profile?.walletAddress
                                                            ? t('nftDetail.you')
                                                            : nftSale?.user?.username
                                                                ? nftSale?.user?.username
                                                                : shrinkAddress(nftSale?.user?.walletAddress)}
                                                    </Link>
                                                </Tooltip>
                                            </div>)
                                        }
                                    </Col>
                                    <Col>
                                        {isOwner(nftSale) ? (
                                            <ButtonCustom
                                                color="gray"
                                                isBuble
                                                className="listings__buttons br-12 cancel-button"
                                                onClick={() => {
                                                    handleCancelListing(nftSale)
                                                }}
                                            >
                                                {t('common.cancel')}
                                            </ButtonCustom>
                                        ) : !!Number(nftSale.price) ? (
                                            <div className='action-col'>
                                                {/* <ButtonCustom
                                                    className='listings__buttons br-12'
                                                    color='blue'
                                                >
                                                    {t("nftDetail.buyWith", { currency: 'SP' })}
                                                </ButtonCustom> */}
                                                <ButtonCustom
                                                    color='blue'
                                                    className="listings__buttons br-12"
                                                    onClick={() => {
                                                        handleOpenFixPrice(nftSale)
                                                    }}
                                                >
                                                    {t('common.buyNow')}
                                                </ButtonCustom>
                                            </div>
                                        ) : (
                                            <ButtonCustom
                                                color="blue"
                                                isBuble
                                                className="listings__buttons br-12"
                                                onClick={() => handleMakeAnOffer(nftSale)}
                                            >
                                                {t('common.makeOffer')}
                                            </ButtonCustom>
                                        )}
                                    </Col>
                                </Row>
                            ))}
                        </div>
                    ) : (
                        <div className="listings__table-mobile">
                            {renderNftSales.map((nftSale, index) => (
                                <Col key={index}>
                                    <Row align="center" justify="space-between">
                                        <Col>{t('nftDetail.price')}</Col>
                                        <Col>
                                            {!!Number(nftSale?.price)
                                                ? `${BigNumber(nftSale?.price * 1)
                                                    .decimalPlaces(8)
                                                    .toFormat()
                                                    .toString()}
                                                ${nftSale.currencyToken?.toUpperCase()}`
                                                : '--'}
                                        </Col>
                                    </Row>
                                    <Row align="center" justify="space-between">
                                        <Col>{t('nftDetail.usdPrice')}</Col>
                                        <Col>
                                            {!!Number(nftSale?.exchangedPrice)
                                                ? `$ ${BigNumber(nftSale?.exchangedPrice)
                                                    .decimalPlaces(8)
                                                    .toFormat()
                                                    .toString()}`
                                                : '--'}
                                        </Col>
                                    </Row>
                                    <Row align="center" justify="space-between">
                                        <Col>{t('common.quantity')}</Col>
                                        <Col>{nftSale.quantity}</Col>
                                    </Row>
                                    <Row align="center" justify="space-between">
                                        <Col>{t('nftDetail.expiration')}</Col>
                                        <Col>{nftSale.expireDate}</Col>
                                    </Row>
                                    <Row align="center" justify="space-between">
                                        <Col>{t('common.from')}</Col>
                                        <Col>
                                            {nftSale?.user && (
                                                <div className="d-flex user-info">
                                                    <span
                                                        onClick={e => {
                                                            history.push(
                                                                nftSale?.user?.shortUrl ? `/${nftSale?.user?.shortUrl}` : `/user/${nftSale?.user?.walletAddress}`
                                                            )
                                                            handleClickUser(e, nftSale?.user?.walletAddress)
                                                        }}
                                                        className="cursor-pointer"
                                                    >
                                                        <div className="avatar">
                                                            <Avatar alt="example" src={convertImageAvatar(nftSale?.user?.avatarImg)} />
                                                            {nftSale?.user?.isVerify ? <CustomVerifyIcon className="icon" /> : null}
                                                        </div>
                                                    </span>
                                                    <Tooltip
                                                        title={
                                                            nftSale?.user?.username
                                                                ? nftSale?.user?.username
                                                                : nftSale?.user?.walletAddress
                                                        }
                                                        overlayClassName="custom-tooltip"
                                                    >
                                                        <Link
                                                            to={
                                                                nftSale?.user?.shortUrl
                                                                    ? `/${nftSale?.user?.shortUrl}`
                                                                    : `/user/${nftSale?.user?.walletAddress}`
                                                            }
                                                        >
                                                            {nftSale?.user?.walletAddress === profile?.walletAddress
                                                                ? t('nftDetail.you')
                                                                : nftSale?.user?.username
                                                                    ? nftSale?.user?.username
                                                                    : shrinkAddress(nftSale?.user?.walletAddress)}
                                                        </Link>
                                                    </Tooltip>
                                                </div>)}
                                        </Col>
                                    </Row>

                                    <Row align="center" justify="space-between">
                                        <Col>
                                            {isOwner(nftSale) ? (
                                                <ButtonCustom
                                                    isBuble
                                                    color="gray"
                                                    className="listings__buttons br-12"
                                                    onClick={() => {
                                                        handleCancelListing(nftSale)
                                                    }}
                                                >
                                                    {t('common.cancel')}
                                                </ButtonCustom>
                                            ) : !!Number(nftSale.price) ? (
                                                <ButtonCustom
                                                    isBuble
                                                    color="blue"
                                                    className="listings__buttons br-12"
                                                    onClick={() => {
                                                        handleOpenFixPrice(nftSale)
                                                    }}
                                                >
                                                    {t('common.buyNow')}
                                                </ButtonCustom>
                                            ) : (
                                                <ButtonCustom
                                                    isBuble
                                                    color="blue"
                                                    className="listings__buttons br-12"
                                                    onClick={() => handleMakeAnOffer(nftSale)}
                                                >
                                                    {t('common.makeOffer')}
                                                </ButtonCustom>
                                            )}
                                        </Col>
                                    </Row>
                                </Col>
                            ))}
                        </div>
                    )}
                </>
            ) : (
                <div className="listings__no-item">
                    <NoData mainText={t('nftDetail.noRecords')} />
                </div>
            )}

            {isCancelFixPrice && (
                <CancelModal
                    onConfirmCancelSale={onConfirmCancelFixPrice}
                    onCloseModal={() => setIsCancelFixPrice(false)}
                    isCanceling={isCancelingFixPrice}
                />
            )}
            {isOpenBuyFixPrice && (
                <BuyModalFixPrice
                    onCloseModal={() => setIsOpenBuyFixPrice(false)}
                    onOpenProcessModal={e => {
                        setBuyFixPriceTransferData({ hash: e })
                        setIsShowBuyFixPriceProcessing(true)
                        setIsOpenBuyFixPrice(false)
                        setIsShowDoneBuyFixPriceProcessing(false)
                    }}
                    onCloseProcessModal={() => setIsShowBuyFixPriceProcessing(false)}
                    setIsDoneProcessing={value => setIsShowDoneBuyFixPriceProcessing(value)}
                    buyFixPriceMetadata={actionMetadata}
                />
            )}
            {isShowBuyFixPriceProcessing && (
                <ProcessBuyModal
                    isOpen={isShowBuyFixPriceProcessing}
                    onCloseModal={() => {
                        setIsShowDoneBuyFixPriceProcessing(false)
                        setIsShowBuyFixPriceProcessing(false)
                    }}
                    hashTransaction={buyFixPriceTransferData?.hash}
                    isDone={isShowDoneBuyFixPriceProcessing}
                />
            )}

            {isShowOfferModal && (
                <OfferFormModal
                    actionMetadata={actionMetadata}
                    onCloseModal={() => setIsShowOfferModal(false)}
                    openOfferSuccessModal={() => {
                        setIsShowSuccessOfferModal(true)
                    }}
                />
            )}
            {isShowSuccessOfferModal && (
                <MakeOfferSuccessModal onCloseModal={() => setIsShowSuccessOfferModal(false)} />
            )}
        </div>
    )
}

export default Offers
