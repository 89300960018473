import { useTranslation } from "react-i18next";
import "./style.scss";
import RefferalImage from "assets/images/refferal-myprofile.png";

const Refferal = ({ profile, copyReferralToClipboard }) => {
  const { t } = useTranslation();
  return (
    <div className="container-refferal">
      <h1 className="title">{t("profile.referralTitle")}</h1>
      <div className="image-container">
        <img alt="Refferal" src={RefferalImage} />
      </div>
      <div className="container-list-refferal">
        <div className="refferal">
          <div className="title-refferal">{t("profile.referralCode")}:</div>
          <div className="content-refferal-container">
            <div className="content-refferal">
              <span>{profile?.code}</span>
              <span onClick={copyReferralToClipboard}>
                <i class="ri-file-copy-line"></i>
              </span>
            </div>
          </div>
        </div>
        <div className="refferal">
          <div className="title-refferal">
            {t("profile.numberOfReferrals")}:
          </div>
          <div className="content-refferal-container">
            <div className="content-refferal">
              <span>{profile?.countReferralCode}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Refferal;
