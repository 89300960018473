import React from 'react'
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom'

import CollectionListPart from 'pages/profile/components/Collections/CollectionList.part';

const Collections = ({ networkTypes }) => {
  const { t } = useTranslation();

  const _renderHeader = () => (
    <div className="home-page-category__header explore-trending-collection">
        <h2>{t('homepage.collections.title')}</h2>
        <div className="button-holder">
            <Link className="link-view-all" to={`/collections`}>
                {t("common.viewAll")}
            </Link>
        </div>
    </div>
  );

  return (
    <div className="home-collections collection-container">
      {_renderHeader()}
      <CollectionListPart networkType={networkTypes} limit={10} />
    </div>
  )
};

export default Collections;
