import {
    readContract,
    writeContract,
    signMessage as signMessageWagmi,
    estimateGas as estimateGasWagmi,
    getTransaction as getTransactionWagmi,
    waitForTransactionReceipt,
    getBalance as getBalanceWagmi
} from '@wagmi/core'
import { parseGwei } from 'viem'

import ExchangeABI from 'blockchain/abi/exchange.json'
import RegistryABI from 'blockchain/abi/Registry.json'
import MultipleCollectableABI from 'blockchain/abi/multipleCollectable.json'
import SingleCollectableABI from 'blockchain/abi/singleCollectable.json'
import ERC20ABI from 'blockchain/abi/ERC20.json'
import FactoryABI from 'blockchain/abi/factory.json'
import SetterRoyaltyABI from 'blockchain/abi/setterRoyalty.json'
import { wagmiConfig } from './wagmi'
import { NETWORKS } from 'constants/envs'
import { convertBigNumberValueToNumber, getContractInstanceEther } from './ether'

export const CONTRACT_TYPES = {
    READ: 'read',
    WRITE: 'write',
}

export const estimateGas = (to, value) => estimateGasWagmi(wagmiConfig, { to, value });

export const signMessage = (message, connector) => signMessageWagmi(wagmiConfig, { message, connector });

export const getTransaction = (tx, chainId) => getTransactionWagmi(wagmiConfig, { hash: tx, chainId });

export const getTransactionReceipt = (tx, chainId) => waitForTransactionReceipt(wagmiConfig, { hash: tx, chainId });

/**
 *
 * @param {string} address
 * @param {'wei' | 'kwei' | 'mwei' | 'gwei' | 'szabo' | 'finney' | 'ether'} unit
 * @returns
 */
export const getBalance = async (address, chainId, unit = 'ether') => {
    const nativeToken = await getBalanceWagmi(wagmiConfig, { address, chainId, unit });

    const network = Object.keys(NETWORKS).find(network => NETWORKS[network].id === chainId);

    if (!network) {
        throw new Error(`getBalance: chainId {${chainId}} not found`);
    }

    return convertBigNumberValueToNumber(nativeToken.value, network.nativeCurrency?.decimals || 18);
};

export const getReadContract = (abi, contractAddress, params) => {
    const { functionName, args, account } = params;

    return readContract(wagmiConfig, {
        abi: abi,
        address: contractAddress,
        functionName,
        args,
        account,
    });
}

export const getWriteContract = (abi, contractAddress, params) => {
    const { functionName, args, account, value, gas } = params;

    return writeContract(wagmiConfig, {
        abi: abi,
        address: contractAddress,
        functionName,
        args,
        account,
        value,
        gas: gas ? parseGwei(gas.toString()) : undefined,
    });
}

export const genMainContractEther = (type) => (contractAddress, params) => {
    return type === CONTRACT_TYPES.READ
        ? getReadContract(ExchangeABI, contractAddress, params)
        : getWriteContract(ExchangeABI, contractAddress, params);
}

export const multipleCollectableContract = (type) => (contractAddress, params) => {
    return type === CONTRACT_TYPES.READ
        ? getReadContract(MultipleCollectableABI, contractAddress, params)
        : getWriteContract(MultipleCollectableABI, contractAddress, params);
}

export const singleCollectableContract = (type) => (contractAddress, params) => {
    return type === CONTRACT_TYPES.READ
        ? getReadContract(SingleCollectableABI, contractAddress, params)
        : getWriteContract(SingleCollectableABI, contractAddress, params);
}

export const genRegistryContractEther = (type) => (contractAddress, params) => {
    return type === CONTRACT_TYPES.READ
        ? getReadContract(RegistryABI, contractAddress, params)
        : getWriteContract(RegistryABI, contractAddress, params);
}

export const genERC20PaymentContract = (type) => (contractAddress, params) => {
    return type === CONTRACT_TYPES.READ
        ? getReadContract(ERC20ABI, contractAddress, params)
        : getWriteContract(ERC20ABI, contractAddress, params);
}

export const genFactoryContract = (type) => (contractAddress, params) => {
    return type === CONTRACT_TYPES.READ
        ? getReadContract(FactoryABI, contractAddress, params)
        : getWriteContract(FactoryABI, contractAddress, params);
}

export const genSetterRoyaltyABIContract = (type) => (contractAddress, params) => {
    return type === CONTRACT_TYPES.READ
        ? getReadContract(SetterRoyaltyABI, contractAddress, params)
        : getWriteContract(SetterRoyaltyABI, contractAddress, params);
}

export const getExchangeContract = (contractAddress) => {
    return getContractInstanceEther(ExchangeABI, contractAddress);
};

export const getErc20Contract = (contractAddress) => {
    return getContractInstanceEther(ERC20ABI, contractAddress);
};

export const getRegistryContract = (contractAddress) => {
    return getContractInstanceEther(RegistryABI, contractAddress);
};

export const getRoyaltyContract = (contractAddress) => {
    return getContractInstanceEther(SetterRoyaltyABI, contractAddress);
};

export const getFactoryContract = (contractAddress) => {
    return getContractInstanceEther(FactoryABI, contractAddress);
};
