import React from 'react'
import { PersistGate } from 'redux-persist/integration/react'
import { Provider } from 'react-redux'
import 'antd/dist/antd.css'
import 'remixicon/fonts/remixicon.css'
import { ConfigProvider } from 'antd';
import { useTranslation } from 'react-i18next'
import ThemeProvider from './ThemeProvider'
import createRoutes from './routes'
import { store, persistor } from './store/index';

import jaJP from 'antd/lib/locale/ja_JP';
import enUS from 'antd/lib/locale/en_US';
import zhCN from 'antd/lib/locale/zh_CN';

const getAntdLocale = (currentI18n) => {
    switch (currentI18n) {
        case 'en':
            return enUS;
        case 'jp':
            return jaJP;
        case 'cn':
            return zhCN;
        default:
            return enUS;
    }

}

const App = () => {
    const { i18n } = useTranslation()
    return (
        <Provider store={store}>
            <ThemeProvider>
                <PersistGate loading={null} persistor={persistor}>
                    <ConfigProvider locale={getAntdLocale(i18n.language)}>
                        {createRoutes()}
                    </ConfigProvider>
                </PersistGate>
            </ThemeProvider>
        </Provider>
    )
}

export default App
