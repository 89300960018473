import React, { useContext } from "react";

import { Select } from "antd";
import PropTypes from "prop-types";
import { ThemeContext } from "../../../ThemeProvider";
import "./styles.scss";
import ArrowDown from "assets/icons/arrow-down-black.svg";

const { Option } = Select;

const SelectCustom = (props) => {
  const { theme } = useContext(ThemeContext);
  const {
    showIconSvg,
    fieldSelect,
    name,
    allowClear,
    autoClearSearchValue,
    autoFocus,
    bordered,
    clearIcon,
    defaultActiveFirstOption,
    defaultOpen,
    defaultValue,
    dropdownClassName,
    dropdownMatchSelectWidth,
    dropdownRender,
    dropdownStyle,
    filterOption,
    getPopupContainer,
    labelInValue,
    listHeight,
    loading,
    maxTagCount,
    maxTagPlaceholder,
    maxTagTextLength,
    menuItemSelectedIcon,
    mode,
    notFoundContent,
    open,
    optionFilterProp,
    optionLabelProp,
    options,
    placeholder,
    removeIcon,
    searchValue,
    showArrow,
    showSearch,
    size,
    suffixIcon,
    tagRender,
    tokenSeparators,
    value,
    virtual,
    onBlur,
    onChange,
    onClear,
    onDeselect,
    onDropdownVisibleChange,
    onFocus,
    onInputKeyDown,
    onMouseEnter,
    onMouseLeave,
    onPopupScroll,
    onSearch,
    onSelect,
    className,
    disabled,
    title,
    key,
    label,
    classNames = [],
    onClick,
    prefixIcon,
    prefixLabel,
    categoryIcon,
    color,
    prefixIconSvg,
  } = props;

  let overrideClassNames = ["ant-select-custom"];
  let overrideDropdownClassNames = ["custom-dropdown"];
  const initArgs = () => {
    if (classNames.length > 0) {
      overrideClassNames = overrideClassNames.concat(classNames);
    }

    if (dropdownClassName) {
      overrideDropdownClassNames.push(dropdownClassName);
    }

    if (theme === "dark-theme") {
      overrideDropdownClassNames.push("custom-dropdown-dark");
      overrideClassNames.push("ant-custom-select-dark");
    }

    if (color === "dark") {
      overrideClassNames.push("dark");
    }
  };

  initArgs();

  const renderSelectOptionWithIcon = (option) => {
    if (categoryIcon) {
      try {
        const icon = require(`assets/icons/${option.icon}.svg`);
        if (icon) {
          return {
            __html: `<img className="select-custom_option__icon small-icon" src=${icon.default}>${option.label}`,
          };
        }
      } catch {
        console.error(`Icon ${option.icon} not found`);
      }
    }
    return {
      __html: `<img className="select-custom_option__icon" src="${
        showIconSvg ? option.img : option.icon
      }"/>${option.label}`,
    };
  };

  return (
    <div
      className={`select-custom-wrapper ${
        prefixIconSvg && "select-custom-wrapper-prefix__icon"
      }`}
    >
      {prefixIcon && (
        <div
          className={`select-custom_prefix ${
            theme === "dark-theme" ? "select-custom_prefix__dark" : ""
          }`}
        >
          <img
            src={prefixIcon}
            className="select-custom_prefix__icon"
            alt="prefix-icon"
          />
          {prefixLabel && <span>{prefixLabel}</span>}
        </div>
      )}
      {prefixIconSvg && (
        <div
          className={`select-custom_prefix select-custom_prefix-svg ${
            theme === "dark-theme" ? "select-custom_prefix__dark" : ""
          }`}
        >
          {prefixIconSvg}
        </div>
      )}
      <Select
        name={name}
        allowClear={allowClear}
        autoClearSearchValue={autoClearSearchValue}
        autoFocus={autoFocus}
        bordered={bordered}
        clearIcon={clearIcon}
        defaultActiveFirstOption={defaultActiveFirstOption}
        defaultOpen={defaultOpen}
        defaultValue={defaultValue}
        disabled={disabled}
        dropdownClassName={overrideDropdownClassNames.join(" ")}
        dropdownMatchSelectWidth={dropdownMatchSelectWidth}
        dropdownRender={dropdownRender}
        dropdownStyle={dropdownStyle}
        filterOption={filterOption}
        getPopupContainer={getPopupContainer}
        labelInValue={labelInValue}
        listHeight={listHeight}
        loading={loading}
        maxTagCount={maxTagCount}
        maxTagPlaceholder={maxTagPlaceholder}
        maxTagTextLength={maxTagTextLength}
        menuItemSelectedIcon={menuItemSelectedIcon}
        mode={mode}
        notFoundContent={notFoundContent}
        open={open}
        optionFilterProp={optionFilterProp}
        optionLabelProp={optionLabelProp}
        placeholder={placeholder}
        removeIcon={removeIcon}
        searchValue={searchValue}
        showArrow={showArrow}
        showSearch={showSearch}
        size={size}
        suffixIcon={suffixIcon || <img src={ArrowDown} alt="ArrowDown" />}
        tagRender={tagRender}
        tokenSeparators={tokenSeparators}
        value={value}
        virtual={virtual}
        onBlur={onBlur}
        onChange={onChange}
        onClear={onClear}
        onDeselect={onDeselect}
        onDropdownVisibleChange={onDropdownVisibleChange}
        onFocus={onFocus}
        onInputKeyDown={onInputKeyDown}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
        onPopupScroll={onPopupScroll}
        onSearch={onSearch}
        onSelect={onSelect}
        title={title}
        key={key}
        label={label}
        className={overrideClassNames.join(" ") + ` ${className}`}
        onClick={onClick}
      >
        {Array.isArray(options) &&
          options.map((option) => (
            <Option
              key={`${option.value}-${option.label}`}
              className={`select-custom_option ${
                theme === "dark-theme" ? "select-custom_option-dark" : ""
              }`}
              value={fieldSelect === "id" ? option.id : option.value}
            >
              {option.icon ? (
                <div
                  className={`select-custom_option__icon__wrapper ${
                    theme === "dark-theme"
                      ? "select-custom_option__icon__wrapper-dark"
                      : ""
                  }`}
                  dangerouslySetInnerHTML={renderSelectOptionWithIcon(option)}
                />
              ) : (
                <span>{option.label}</span>
              )}
            </Option>
          ))}
      </Select>
    </div>
  );
};

SelectCustom.propTypes = {
  name: PropTypes.any,
  allowClear: PropTypes.any,
  autoClearSearchValue: PropTypes.any,
  autoFocus: PropTypes.any,
  bordered: PropTypes.any,
  clearIcon: PropTypes.any,
  defaultActiveFirstOption: PropTypes.any,
  defaultOpen: PropTypes.any,
  defaultValue: PropTypes.any,
  disabled: PropTypes.any,
  dropdownClassName: PropTypes.any,
  dropdownMatchSelectWidth: PropTypes.any,
  dropdownRender: PropTypes.any,
  dropdownStyle: PropTypes.any,
  filterOption: PropTypes.any,
  getPopupContainer: PropTypes.any,
  labelInValue: PropTypes.any,
  listHeight: PropTypes.any,
  loading: PropTypes.any,
  maxTagCount: PropTypes.any,
  maxTagPlaceholder: PropTypes.any,
  maxTagTextLength: PropTypes.any,
  menuItemSelectedIcon: PropTypes.any,
  mode: PropTypes.any,
  notFoundContent: PropTypes.any,
  open: PropTypes.any,
  optionFilterProp: PropTypes.any,
  optionLabelProp: PropTypes.any,
  options: PropTypes.any,
  placeholder: PropTypes.any,
  removeIcon: PropTypes.any,
  searchValue: PropTypes.any,
  showArrow: PropTypes.any,
  showSearch: PropTypes.any,
  size: PropTypes.any,
  suffixIcon: PropTypes.any,
  tagRender: PropTypes.any,
  tokenSeparators: PropTypes.any,
  value: PropTypes.any,
  virtual: PropTypes.any,
  onBlur: PropTypes.any,
  onChange: PropTypes.any,
  onClear: PropTypes.any,
  onDeselect: PropTypes.any,
  onDropdownVisibleChange: PropTypes.any,
  onFocus: PropTypes.any,
  onInputKeyDown: PropTypes.any,
  onMouseEnter: PropTypes.any,
  onMouseLeave: PropTypes.any,
  onPopupScroll: PropTypes.any,
  onSearch: PropTypes.any,
  onSelect: PropTypes.any,
  className: PropTypes.any,
  title: PropTypes.any,
  key: PropTypes.any,
  label: PropTypes.any,
  onClick: PropTypes.func,
  classNames: PropTypes.any,
  children: PropTypes.any,
  prefixIcon: PropTypes.any,
  prefixLabel: PropTypes.any,
};

export default SelectCustom;
