import React, { useState, useEffect, useContext, useRef } from 'react'
import { useWindowSize } from 'react-use'
import ReactLoading from 'react-loading'
import { Row, Col, Tooltip, Avatar } from 'antd'
import { Link } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import BigNumber from 'bignumber.js'

import AcceptOfferModal from './AcceptOfferModal'
import BidCancelModal from './BidCancelModal'
import AcceptProcessModal from './AcceptProcessModal'
import NoItem from 'assets/images/notfound.svg'
import shrinkAddress from 'utils/shrinkAddress'
import { ButtonCustom, NoData } from 'components/common'
import RightArrow from 'assets/icons/right-arrow.svg'
import { PaginationCustom } from 'components/common'
import { isUserAuthenticated } from 'utils/auth'
import { useAuth } from 'hooks/useAuth'
import { getOfferList, changePageOfferOpenBid } from 'store/actions/nft'
import './style.scss'
import { ThemeContext } from '../../../../../ThemeProvider'
import { getPrice } from 'service/getPrice'
import CustomVerifyIcon from 'components/common/custom-verify-icon'
import { convertImageAvatar } from 'utils/image'

const getRate = async (currencyToken) => {
    const price = await getPrice(currencyToken)
    return price;
}

const Offers = () => {
    const { theme } = useContext(ThemeContext)
    const { t } = useTranslation()
    const dispatch = useDispatch()
    const { width } = useWindowSize()
    const [, handleSignWallet] = useAuth()
    const { id } = useSelector(state => state?.collectible?.data)
    const { items: offers, meta } = useSelector(state => state?.collectible?.offers)
    const { pageOfferOpenBid, loadingOfferOpenPid } = useSelector(state => state?.collectible)
    const { profile } = useSelector(state => state.user)
    const [isOpenAcceptModal, setIsOpenAcceptModal] = useState(false)
    const [isOpenCancelBidModal, setIsOpenCancelBidModal] = useState(false)
    const [isOpenProcessModal, setIsOpenProcessModal] = useState(false)
    const [selectedOfferData, setSelectedOfferData] = useState()
    const [transferData, setTransferData] = useState({})
    const [cancelBidData, setCancelBidData] = useState(null)

    const [renderNftOffers, setRenderNftOffers] = useState([])

    const forceCloseProcessModal = useRef(false);

    useEffect(() => {
        if (offers && offers.length) {
            const handleExchange = async () => {
                const convertedList = await Promise.all(offers?.map(async (offer) => {
                    const exchangeRate = await getRate(offer.currencyToken)
                    return {
                        ...offer,
                        exchangedPrice: offer.price * exchangeRate
                    }
                }))
                setRenderNftOffers([...convertedList])
            }
            handleExchange();
        }
    }, [meta])

    const onOpenAcceptOffer = async offer => {
        if (!isUserAuthenticated()) {
            const res = await handleSignWallet()
            if (!res) return
        }

        setSelectedOfferData(offer)
        setIsOpenAcceptModal(true)
        forceCloseProcessModal.current = false;
    }

    const onClickCancelBid = async offer => {
        if (!isUserAuthenticated()) {
            const res = await handleSignWallet()
            if (!res) return
        }
        setCancelBidData(offer)
        setIsOpenCancelBidModal(true)
    }

    const onOpenProcessModal = (hash, collectionAddress, bidId) => {
        setIsOpenAcceptModal(false)
        setTransferData({ hash, collectionAddress, bidId })
        if (forceCloseProcessModal.current === false) {
            setIsOpenProcessModal(true);
        }
    }

    const onChangePage = value => dispatch(changePageOfferOpenBid({ value }))

    const getList = async () => {
        dispatch(getOfferList({ nftId: id, page: pageOfferOpenBid, limit: 5 }))
    }

    useEffect(() => {
        getList()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pageOfferOpenBid, id, profile?.walletAddress])

    useEffect(() => {
        setIsOpenAcceptModal(false)
        setIsOpenCancelBidModal(false)
    }, [profile])


    return (
        <div className={`offers__container ${theme === 'dark-theme' ? 'offers__container' : ''}`}>
            {loadingOfferOpenPid ? (
                <ReactLoading type="spinningBubbles" color="#002979" className="offers__loading" />
            ) : offers?.length > 0 ? (
                <>
                    <PaginationCustom total={meta.totalItem} value={pageOfferOpenBid} onChangePage={onChangePage} />
                    {width > 992 ? (
                        <div className={`offers__table ${theme === 'dark-theme' ? 'dark-offers__table' : ''}`}>
                            <Row align="center" justify="space-between">
                                <Col>{t('nftDetail.price')}</Col>
                                <Col>{t('nftDetail.usdPrice')}</Col>
                                <Col>{t('common.quantity')}</Col>
                                <Col>{t('nftDetail.floorDiff')}</Col>
                                <Col>{t('nftDetail.expiration')}</Col>
                                <Col>{t('nftDetail.from')}</Col>
                                <Col></Col>
                            </Row>
                            {renderNftOffers?.map(bid => (
                                <Row align="center" justify="space-between" key={bid?.id}>
                                    <Col>
                                        {!!Number(bid?.price) ? `${BigNumber(bid?.price * 1 || 0)
                                            .decimalPlaces(6)
                                            .toFormat()
                                            .toString()} ${bid.currencyToken.toUpperCase()}` : "--"}
                                    </Col>
                                    <Col>
                                        {!!Number(bid?.price)
                                            ? `$ ${BigNumber(bid?.exchangedPrice)
                                                .decimalPlaces(8)
                                                .toFormat()
                                                .toString()}`
                                            : '__'}
                                    </Col>
                                    <Col>{bid.quantity}</Col>
                                    <Col>{bid.quantity}</Col>
                                    <Col>{bid?.expireDate ? bid?.expireDate : '--'}</Col>
                                    <Col>
                                        {bid?.fromUser && (
                                            <div className="d-flex user-info">
                                                <span
                                                    onClick={e => {
                                                        history.push(
                                                            bid?.fromUser?.shortUrl ? `/${bid?.fromUser?.shortUrl}` : `/user/${bid?.fromUser?.walletAddress}`
                                                        )
                                                        handleClickUser(e, bid?.fromUser?.walletAddress)
                                                    }}
                                                    className="cursor-pointer"
                                                >
                                                    <div className="avatar">
                                                        <Avatar alt="example" src={convertImageAvatar(bid?.fromUser?.avatarImg)} />
                                                        {bid?.fromUser?.isVerify ? <CustomVerifyIcon className="icon" /> : null}
                                                    </div>
                                                </span>
                                                <Tooltip
                                                    title={
                                                        bid?.fromUser?.username
                                                            ? bid?.fromUser?.username
                                                            : bid?.fromUser?.walletAddress
                                                    }
                                                    overlayClassName="custom-tooltip"
                                                >
                                                    <Link
                                                        to={
                                                            bid?.fromUser?.shortUrl
                                                                ? `/${bid?.fromUser?.shortUrl}`
                                                                : `/user/${bid?.fromUser?.walletAddress}`
                                                        }
                                                    >
                                                        {bid?.fromUser?.walletAddress === profile?.walletAddress
                                                            ? t('nftDetail.you')
                                                            : bid?.fromUser?.username
                                                                ? bid?.fromUser?.username
                                                                : shrinkAddress(bid?.fromUser?.walletAddress)}
                                                    </Link>
                                                </Tooltip>
                                            </div>
                                        )}
                                    </Col>
                                    {/* <Col>
                                        {bid?.toUser ? (
                                            <Tooltip
                                                title={
                                                    bid?.toUser?.username
                                                        ? bid?.toUser?.username
                                                        : bid?.toUser?.walletAddress
                                                }
                                                overlayClassName="custom-tooltip"
                                            >
                                                <Link
                                                    to={
                                                        bid?.toUser?.shortUrl
                                                            ? `/${bid?.toUser?.shortUrl}`
                                                            : `/user/${bid?.toUser?.walletAddress}`
                                                    }
                                                >
                                                    {bid?.toUser?.walletAddress === profile?.walletAddress
                                                        ? t('nftDetail.you')
                                                        : bid?.toUser?.username
                                                            ? bid?.toUser?.username
                                                            : shrinkAddress(bid?.toUser?.walletAddress)}
                                                </Link>
                                            </Tooltip>
                                        ) : null}
                                    </Col> */}
                                    <Col>
                                        {bid?.toUser?.id === profile?.id ? (
                                            <ButtonCustom
                                                color="gray"
                                                iconRight={<img src={RightArrow} alt="RightArrow" />}
                                                className="offers__buttons"
                                                onClick={() => onOpenAcceptOffer(bid)}
                                            >
                                                <span>{t('nftDetail.accept')}</span>
                                            </ButtonCustom>
                                        ) : bid?.fromUser?.id === profile?.id ? (
                                            <ButtonCustom
                                                color="gray"
                                                iconRight={<img src={RightArrow} alt="RightArrow" />}
                                                className="offers__buttons"
                                                onClick={() => onClickCancelBid(bid)}
                                            >
                                                <span>{t('nftDetail.cancel')}</span>
                                            </ButtonCustom>
                                        ) : null}
                                    </Col>
                                </Row>
                            ))}
                        </div>
                    ) : (
                        <div className="offers__table-mobile">
                            {renderNftOffers.map((bid, index) => (
                                <Col key={index}>
                                    <Row align="center" justify="space-between">
                                        <Col>{t('nftDetail.price')}</Col>
                                        <Col>
                                            {`${BigNumber(bid?.price * 1 || 0)
                                                .decimalPlaces(6)
                                                .toFormat()
                                                .toString()} ${bid.currencyToken.toUpperCase()}`}
                                        </Col>
                                    </Row>
                                    <Row align="center" justify="space-between">
                                        <Col>{t('nftDetail.usdPrice')}</Col>
                                        <Col>
                                            {!!Number(bid?.exchangedPrice)
                                                ? `$ ${BigNumber(bid?.price * 1)
                                                    .decimalPlaces(8)
                                                    .toFormat()
                                                    .toString()}`
                                                : ''}
                                        </Col>
                                    </Row>
                                    <Row align="center" justify="space-between">
                                        <Col>{t('common.quantity')}</Col>
                                        <Col>{bid.quantity}</Col>
                                    </Row>
                                    <Row align="center" justify="space-between">
                                        <Col>{t('nftDetail.floorDiff')}</Col>
                                        <Col>{bid.quantity}</Col>
                                    </Row>
                                    <Row align="center" justify="space-between">
                                        <Col>{t('nftDetail.expiration')}</Col>
                                        <Col>{bid?.expireDate ? bid?.expireDate : "--"}</Col>
                                    </Row>

                                    <Row align="center" justify="space-between">
                                        <Col>{t('nftDetail.from')}</Col>
                                        <Col>
                                            {bid?.fromUser && (
                                                <div className="d-flex user-info">
                                                    <span
                                                        onClick={e => {
                                                            history.push(
                                                                bid?.fromUser?.shortUrl ? `/${bid?.fromUser?.shortUrl}` : `/user/${bid?.fromUser?.walletAddress}`
                                                            )
                                                            handleClickUser(e, bid?.fromUser?.walletAddress)
                                                        }}
                                                        className="cursor-pointer"
                                                    >
                                                        <div className="avatar">
                                                            <Avatar alt="example" src={convertImageAvatar(bid?.fromUser?.avatarImg)} />
                                                            {bid?.fromUser?.isVerify ? <CustomVerifyIcon className="icon" /> : null}
                                                        </div>
                                                    </span>
                                                    <Tooltip
                                                        title={
                                                            bid?.fromUser?.name
                                                                ? bid?.fromUser?.name
                                                                : bid?.fromUser?.walletAddress
                                                        }
                                                        overlayClassName="custom-tooltip"
                                                    >
                                                        <Link
                                                            to={
                                                                bid?.fromUser?.shortUrl
                                                                    ? `/${bid?.fromUser?.shortUrl}`
                                                                    : `/user/${bid?.fromUser?.walletAddress}`
                                                            }
                                                        >
                                                            {bid?.fromUser?.walletAddress === profile?.walletAddress
                                                                ? t('nftDetail.you')
                                                                : bid?.fromUser?.name
                                                                    ? bid?.fromUser?.name
                                                                    : shrinkAddress(bid?.fromUser?.walletAddress)}
                                                        </Link>
                                                    </Tooltip>
                                                </div>)
                                            }
                                        </Col>
                                    </Row>
                                    <Row align="center" justify="space-between">
                                        <Col>
                                            {bid?.toUser?.id === profile?.id ? (
                                                <ButtonCustom
                                                    color="gray"
                                                    iconRight={<img src={RightArrow} alt="RightArrow" />}
                                                    className="offers__buttons"
                                                    onClick={() => onOpenAcceptOffer(bid)}
                                                >
                                                    <span>{t('nftDetail.accept')}</span>
                                                </ButtonCustom>
                                            ) : bid?.fromUser?.id === profile?.id ? (
                                                <ButtonCustom
                                                    color="gray"
                                                    iconRight={<img src={RightArrow} alt="RightArrow" />}
                                                    className="offers__buttons"
                                                    onClick={() => onClickCancelBid(bid)}
                                                >
                                                    <span>{t('nftDetail.cancel')}</span>
                                                </ButtonCustom>
                                            ) : null}
                                        </Col>
                                    </Row>
                                </Col>
                            ))}
                        </div>
                    )}
                </>
            ) : (
                <div className={`offers__no-item ${theme === 'dark-theme' ? 'dark-offers__no-item' : ''}`}>
                    <NoData mainText={t('nftDetail.noRecords')} />
                </div>
            )}

            {isOpenAcceptModal && (
                <AcceptOfferModal
                    offerData={selectedOfferData}
                    onCloseModal={() => setIsOpenAcceptModal(false)}
                    onOpenProcessModal={onOpenProcessModal}
                    onCloseProcessModal={() => {
                        forceCloseProcessModal.current = true;
                        setIsOpenProcessModal(false);
                    }}
                />
            )}

            {isOpenCancelBidModal && (
                <BidCancelModal data={cancelBidData} onCloseModal={() => setIsOpenCancelBidModal(false)} />
            )}

            {isOpenProcessModal && (
                <AcceptProcessModal
                    isOpen={isOpenProcessModal}
                    hashTransaction={transferData?.hash}
                    collectionAddress={transferData?.collectionAddress}
                    bidId={transferData?.bidId}
                    isDone={false}
                />
            )}
        </div>
    )
}

export default Offers
