import { Card, Avatar, Tooltip } from 'antd'
import { useHistory } from 'react-router-dom'
import { useSelector } from 'react-redux'

import { convertImageAvatar } from 'utils/image'
import { convertTimeToDateTime } from 'utils/convertDate'
import IconFollowing from 'assets/icons/activity-following-icon-color.svg'
import CustomVerifyIcon from 'components/common/custom-verify-icon'
import React, { useContext } from 'react'
import { ThemeContext } from 'ThemeProvider'
import { useTranslation } from 'react-i18next'

const FollowingActivity = props => {
    const { t } = useTranslation();
    const { theme } = useContext(ThemeContext)
    const history = useHistory()
    const { user, user2, createdAt, walletAddress } = props
    const { profile } = useSelector(state => state.user)
    const handleClickUser = (e, address) => {
        e.stopPropagation()
        if (address === walletAddress) {
            e.preventDefault()
        }
    }

    const handleRouterUser = () => {
        history.push(user?.shortUrl ? `/${user?.shortUrl}` : `/user/${user?.walletAddress}`)
    }

    return (
        <div className="cursor-pointer" onClick={handleRouterUser}  style={{ height: '100%' }}>
            <Card className="card-activity-custom">
                <div className="card-activity-custom_item-image-following">
                    <img src={convertImageAvatar(user?.avatarImg)} alt="" />
                    {user?.isVerify ? <CustomVerifyIcon className="icon" /> : null}
                </div>
                <div className="card-activity-custom-content">
                    <Tooltip overlayClassName="blue" placement="topLeft" title={user?.username || user?.walletAddress}>
                        <div className="item-name">
                            <span>
                                {profile?.walletAddress && user?.walletAddress === profile?.walletAddress
                                    ? t('activity.you')
                                    : user?.username !== null
                                        ? user?.username
                                        : user?.walletAddress}
                            </span>
                        </div>
                    </Tooltip>
                    <div className="card-activity-custom-content_type">
                        <img className="type-icon" src={IconFollowing} alt="" />
                        <span>{t('activity.startedFollowing')}</span>
                    </div>
                    <div className="card-activity-custom-content_user">
                        <div className="card-activity-custom-content_user-wrapper">
                            <div className="d-flex">
                                <span
                                    onClick={e => {
                                        handleClickUser(e, user2?.walletAddress)
                                        history.push(
                                            user2?.shortUrl ? `/${user2?.shortUrl}` : `/user/${user2?.walletAddress}`
                                        )
                                    }}
                                    className="cursor-pointer"
                                >
                                    <div className="avatar">
                                        <Avatar alt="example" src={convertImageAvatar(user2?.avatarImg)} />
                                        {user2?.isVerify ? <CustomVerifyIcon className="icon" /> : null}
                                    </div>
                                </span>
                                <Tooltip
                                    overlayClassName="blue"
                                    placement="topLeft"
                                    title={user2?.username || user2?.walletAddress}
                                >
                                    <span
                                        onClick={e => {
                                            handleClickUser(e, user2?.walletAddress)
                                            history.push(
                                                user2?.shortUrl
                                                    ? `/${user2?.shortUrl}`
                                                    : `/user/${user2?.walletAddress}`
                                            )
                                        }}
                                        className="cursor-pointer"
                                    >
                                        <div
                                            className={`user-name-long ${theme === 'dark-theme' && 'dark-user-name-long'
                                                }`}
                                        >
                                            {/* <span>{user2?.username !== null ? user2?.username : user2?.walletAddress}</span> */}
                                            <span>
                                                {profile?.walletAddress && user2?.walletAddress === profile?.walletAddress
                                                    ? t('activity.you')
                                                    : user2?.username !== null
                                                        ? user2?.username
                                                        : user2?.walletAddress}
                                            </span>
                                        </div>
                                    </span>
                                </Tooltip>
                            </div>
                        </div>
                    </div>
                    <div className="card-activity-custom-content_date">
                        <span>{convertTimeToDateTime(createdAt)}</span>
                    </div>
                </div>
            </Card>
        </div>
    )
}

export default FollowingActivity
