import { useTranslation } from 'react-i18next'

import DefaultLayout from 'components/layout/default-layout'
import { TabsCustom } from 'components/common'

import './style.scss'
import './responsive.scss'
import { Helmet } from 'react-helmet'
import { MAIN_TITLE } from 'constants/index'
import { useParams } from 'react-router-dom'
import ItemsGroups from './components/ItemsGroups'
import CollectionsGroups from './components/CollectionsGroups'
import UsersGroups from './components/UsersGroups'
import React, { useContext } from 'react'
import { ThemeContext } from '../../ThemeProvider'

const { TabPane } = TabsCustom

const SearchPage = () => {
    const { theme } = useContext(ThemeContext)
    const { t } = useTranslation()
    const { key } = useParams()

    return (
        <DefaultLayout>
            <Helmet>
                <meta charSet="utf-8" />
                <title>{`${MAIN_TITLE} - Search`}</title>
                <meta name="description" content={`${MAIN_TITLE} - Search`} />
            </Helmet>
            <div className="search-page-container">
                <div className="search-page-header">
                    <span className="search-page-title">
                        {t('search.title')} "{key}"
                    </span>
                </div>
                <div className={`search-page-content ${theme === 'dark-theme' ? 'dark-search-page-content' : ''}`}>
                    <TabsCustom defaultActiveKey="1">
                        <TabPane tab={t('search.tab_collections')} key="1">
                            <CollectionsGroups keyword={key} />
                        </TabPane>
                        <TabPane tab={t('search.tab_items')} key="2">
                            <ItemsGroups keyword={key} />
                        </TabPane>
                        <TabPane tab={t('search.tab_users')} key="3">
                            <UsersGroups keyword={key} />
                        </TabPane>
                    </TabsCustom>
                </div>
            </div>
        </DefaultLayout>
    )
}

export default SearchPage
