import { Col, Row } from "antd";
import ReactLoading from "react-loading";
import { useIntersection } from "react-use";
import React, {
  useEffect,
  useState,
  useCallback,
  useMemo,
  useRef,
} from "react";
import { useTranslation } from "react-i18next";
import { useWindowSize } from "react-use";
import { useSelector } from "react-redux";

import activityService from "service/activityService";
import * as message from "utils/custom-message";
import NoActivity from "../NoActivity";
import {
  CardActivity,
  FilterCustom,
  FilterMobileCustom,
} from "components/modules";
import { ButtonCustom, NoData } from "components/common";
import "./style.scss";
import { useMultiLanguageConstant } from "hooks/useMultiLanguageConstant";

const STEP = 10;

const ActivityProfile = ({ active, addressUrl, walletAddress }) => {
  const { t } = useTranslation();
  const { width } = useWindowSize();
  const { profile } = useSelector((state) => state.user);
  const [limit, setLimit] = useState(STEP);
  const [page] = useState(1);
  const [total, setTotal] = useState(0);
  const [type, setType] = useState();
  const [tab] = useState(1);
  const [dataActivities, setDataActivities] = useState([]);

  const [autoFetch, setAutoFetch] = useState(false);
  const [loading, setLoading] = useState(false);
  const anchorEl = useRef(null);
  const intersection = useIntersection(anchorEl, {
    root: null,
    rootMargin: "0px",
    threshold: 1,
  });
  const inView =
    intersection &&
    intersection.isIntersecting &&
    intersection.intersectionRatio >= 1;
  const { listFilterActivity } = useMultiLanguageConstant();

  const isLoadMore = useMemo(() => {
    return dataActivities?.length < total;
  }, [dataActivities?.length, total]);

  const isAcceptOffer = useMemo(() => type === 6, [type]);

  const infinity = autoFetch && inView && isLoadMore;

  const handeLoadMore = useCallback(
    () => {
      if (loading) {
        return;
      }
      const next = limit + STEP;
      const _activityCount = next > total ? total : limit + STEP;
      setLimit(_activityCount);
    }, // eslint-disable-next-line react-hooks/exhaustive-deps
    [total, loading]
  );
  useEffect(() => {
    if (infinity) {
      handeLoadMore();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [infinity]);

  const handleSetType = (id) => {
    setType(id);
  };
  const fetchDataActivities = useCallback(
    async () => {
      setLoading(true);
      const [res, err] = await activityService.getDataActivities({
        page,
        limit,
        // walletAddress: profile?.walletAddress,
        walletAddress: walletAddress, //if you use profile?.walletAddress, it will display activity data of user who login in this website. Change to walletAddress to just display data of user of this my profile page
        activityType: type,
      });
      if (res) {
        setDataActivities(res?.items);
        setTotal(res?.meta?.totalItem);
      }
      if (err) {
        message.error(t("error.message.somethingWentWrong"));
      }
      setLoading(false);
    }, // eslint-disable-next-line react-hooks/exhaustive-deps
    [limit, page, tab, type, addressUrl]
  );

  useEffect(() => {
    fetchDataActivities();
  }, [limit, page, tab, type, fetchDataActivities, addressUrl, active]);

  useEffect(
    () => {
      setLimit(STEP);
      if (width <= 992) setAutoFetch(true);
    }, // eslint-disable-next-line react-hooks/exhaustive-deps
    [addressUrl, type]
  );

  useEffect(() => { }, [active]);

  useEffect(() => {
    if (width <= 992) setAutoFetch(true);
  }, [width]);

  return (
    <div className="activity-profile container">
      {width > 992 ? (
        <FilterCustom
          alignCenter={false}
          handleSetActiveFilter={handleSetType}
          type={type}
          listFilter={listFilterActivity}
        />
      ) : (
        <FilterMobileCustom
          handleSetActiveFilter={handleSetType}
          type={type}
          listFilter={listFilterActivity}
        />
      )}
      {dataActivities?.length || loading ? (
        <div className="activity-content-profile">
          <ActivityContent
            isAcceptOffer={isAcceptOffer}
            data={dataActivities}
            walletAddress={addressUrl}
          />
          {!loading && isLoadMore && !autoFetch && (
            <div className="d-flex justify-content-center mt-40">
              <ButtonCustom
                color="blue"
                className="btn-load"
                onClick={async () => {
                  if (autoFetch) return;
                  await handeLoadMore();
                  setAutoFetch(true);
                }}
              >
                {t("profile.loadMore")}
              </ButtonCustom>
            </div>
          )}
          {loading && (
            <div className="d-flex justify-content-center mt-40">
              <ReactLoading
                className="loading-more"
                type="spinningBubbles"
                color="#002979"
              />
            </div>
          )}
          <div ref={anchorEl}></div>
        </div>
      ) : (
        <div className="activity-content-profile">
          {/* <NoActivity /> */}
          <NoData />
        </div>
      )}
    </div>
  );
};
const ActivityContent = (props) => {
  const { data, walletAddress, isAcceptOffer } = props;
  return (
    <Row className="" gutter={[{ lg: 16, xl: 24, xxl: 24 }, 24]}>
      {data?.map((activity, index) => {
        return (
          <Col key={index} xxl={12} xl={12} lg={12} md={24} sm={24} xs={24}>
            <CardActivity
              isAcceptOffer={isAcceptOffer}
              className="card-activity"
              data={activity}
              walletAddress={walletAddress}
            />
          </Col>
        );
      })}
    </Row>
  );
};
export default ActivityProfile;
