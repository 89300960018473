import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  data: {},
  profile: {
    walletAddress: '',
  },
  currentUserProfile: {
      address: '',
      customUrl: ''
  },
  loading: false,
  error: '',
  updateProfileSuccess: null,
  previewAvatar: null,
  uploadingAvatar: false,
  uploadingCoverImage: false,
  uploadCoverImageSuccess: null,
  uploadingCollectionCoverImage: false,
  uploadCollectionCoverImageSuccess: null,
  verifyTwitterStatus: true
};

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setLoadingLoginWallet: (state) => {
      state.loading = true;
    },
    setLoginWalletSuccess: (state, data) => {
      state.data = {...state.data, ...data.payload};
      state.loading = false;
    },
    setLoginWalletFailure: (state, data) => {
      state.error = data.payload;
      state.loading = false;
    },
    logoutWallet: () => {
      return initialState;
    },
    setUserEditProfileLoading: (state) => {
      state.loading = true;
    },
    setUserEditProfileSuccess: (state, data) => {
      state.profile = data.payload;
      state.loading = false;
      state.updateProfileSuccess = true;
    },
    setUserEditProfileFailure: (state, data) => {
      state.error = data.payload;
      state.loading = false;
      state.updateProfileSuccess = false;
    },
    setUserGetProfileLoading: (state) => {
      state.loading = true;
    },
    setUserGetProfileSuccess: (state, data) => {
      state.profile = data.payload;
      state.loading = false;
    },
    setUserGetProfileFailure: (state, data) => {
      state.error = data.payload;
    },
    setUserUpdateProfileState: (state, data) => {
      state.updateProfileSuccess = data.payload;
      state.uploadCoverImageSuccess = data.payload;
    },
    setConfirmVerifiedLoading: (state) => {
      state.loading = true;
    },
    setConfirmVerifiedSuccess: (state, data) => {
      state.profile.twitterVerify = data.payload;
      state.loading = false;
      state.verifyTwitterStatus = true;
    },
    setConfirmVerifiedFailure: (state, data) => {
      state.error = data.payload;
      state.loading = false;
      state.verifyTwitterStatus = false;
    },
    unlinkTwitter: (state) => {
      state.profile.twitterVerify = null;
      state.verifyTwitterStatus = false;
    },
    clearErrorVerifyTwitter: (state) => {
      state.error = '';
      state.loading = false;
      state.verifyTwitterStatus = true;
    },
    setUploadAvatarLoading: (state) => {
      state.uploadingAvatar = true;
    },
    setUploadAvatarSuccess: (state, data) => {
      state.previewAvatar = data.payload;
      state.uploadingAvatar = false;
    },
    setUploadAvatarFailure: (state, data) => {
      state.error = data.payload;
      state.uploadingAvatar = false;
      state.updateProfileSuccess = false;
    },
    setUploadCoverFailure: (state, data) => {
      state.error = data.payload;
      state.uploadingCoverImage = false;
      state.uploadCoverImageSuccess = false;
    },
    setUploadCoverLoading: (state) => {
      state.uploadingCoverImage = true;
    },
    setCloseUploadCover: (state) => {
      state.uploadingCoverImage = false;
    },
    setUploadCoverSuccess: (state) => {
      state.uploadingCoverImage = false;
      state.uploadCoverImageSuccess = true;
    },
    setUploadCollectionCoverFailure: (state, data) => {
      state.error = data.payload;
      state.uploadingCollectionCoverImage = false;
      state.uploadCollectionCoverImageSuccess = false;
    },
    setUploadCollectionCoverLoading: (state) => {
      state.uploadingCollectionCoverImage = true;
      state.uploadCollectionCoverImageSuccess = false;
    },
    setUploadCollectionCoverSuccess: (state) => {
      state.uploadingCollectionCoverImage = false;
      state.uploadCollectionCoverImageSuccess = true;
    },
    updateBalance: (state, data) => {
      state.data = {...state.data, ...data.payload};
    },
    setCurrentUserProfile: (state, data) => {
      state.currentUserProfile.address = data.payload?.walletAddress;
      state.currentUserProfile.customUrl = data.payload?.customUrl;
    }
  },
});

export const {
  setLoadingLoginWallet,
  setLoginWalletSuccess,
  setLoginWalletFailure,
  logoutWallet,
  setUserEditProfileLoading,
  setUserEditProfileSuccess,
  setUserEditProfileFailure,
  setUserGetProfileLoading,
  setUserGetProfileSuccess,
  setUserGetProfileFailure,
  setUserUpdateProfileState,
  setConfirmVerifiedLoading,
  setConfirmVerifiedSuccess,
  setConfirmVerifiedFailure,
  unlinkTwitter,
  clearErrorVerifyTwitter,
  setUploadAvatarLoading,
  setUploadAvatarSuccess,
  setUploadAvatarFailure,
  setUploadCoverFailure,
  setUploadCoverLoading,
  setUploadCoverSuccess,
  setCloseUploadCover,
  setUploadCollectionCoverFailure,
  setUploadCollectionCoverLoading,
  setUploadCollectionCoverSuccess,
  updateBalance,
  setCurrentUserProfile,
} = userSlice.actions;

export default userSlice.reducer;
