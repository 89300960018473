import React, { useState, useMemo, useEffect, useCallback } from 'react'
import { Form, Modal } from 'antd'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import moment from 'moment'
import BigNumber from 'bignumber.js'

import { Label } from 'components/modules'
import { ButtonCustom, InputCustom, SelectCustom } from 'components/common'
import { PAYMENT_OPTIONS, SALE_TYPE } from 'constants/index'
import { getPrice } from 'service/getPrice'
import { MarketPlaceBtnGroups } from '../../../../../create-nft/components'
import './style.scss'

const MAX_VALUE = 100000000000000

const SaleModal = ({ onCloseModal, onOpenSaleStepModal, owner, onOpenBidStepModal }) => {
    const { t } = useTranslation()
    const { MARKET_FEE } = useSelector(state => state.system)
    const [form] = Form.useForm()
    const { id, tokenId, collection } = useSelector(state => state.collectible.data)
    const [saleType, setSaleType] = useState(SALE_TYPE.FIX_PRICE)
    const [price, setPrice] = useState(0)
    const [quantity, setQuantity] = useState(1)
    const [currentTokenType, setCurrentTokenType] = useState(PAYMENT_OPTIONS[collection.networkType][0].value)
    const [currentStartDate, setCurrentStartDate] = useState(1)
    const [isGettingLatestInfo, setIsGettingLatestInfo] = useState(false)

    const renderCurrentStartDate = useMemo(() => {
        if (saleType !== SALE_TYPE.FIX_PRICE && currentStartDate) {
            if (typeof currentStartDate === 'number') {
                return moment().unix()
            }
            return moment(currentStartDate).unix()
        }

        return null
    }, [currentStartDate, saleType])

    const onChangeSaleType = type => {
        setSaleType(type)
    }

    const onFinishForm = async () => {
        setIsGettingLatestInfo(true)

        switch (saleType) {
            case SALE_TYPE.FIX_PRICE: {
                const params = {
                    price: form.getFieldValue('price'),
                    quantity: Number(form.getFieldValue('quantity')),
                    tokenType: form.getFieldValue('tokenType'),
                    saleType: SALE_TYPE.FIX_PRICE,
                    nftId: id
                }

                onOpenSaleStepModal(params)
                setIsGettingLatestInfo(false)
                onCloseModal()
                break
            }

            case SALE_TYPE.OPEN_FOR_OFFER: {
                const params = {
                    quantity: Number(form.getFieldValue('quantity')),
                    startDate: renderCurrentStartDate,
                    type: SALE_TYPE.OPEN_FOR_OFFER,
                    nftId: id
                }
                onOpenBidStepModal(params)
                break
            }
            default:
                break
        }
    }

    const onChangeValueInput = (value, name, maxValue = MAX_VALUE, decimal = 6) => {
        if (value === '.') return form.setFieldsValue({ [name]: null })
        let number = value
            .toString()
            .replace(/[^0-9.]/g, '')
            .replace(/(\..*?)\..*/g, '$1')
        if (Number(number) >= maxValue) {
            number = number.slice(0, -1)
        }
        if (number.includes('.')) {
            const numString = number.toString().split('.')
            if (numString[1].length > decimal) {
                return form.setFieldsValue({ [name]: number.substring(0, number.length - 1) })
            }
        }
        form.setFieldsValue({ [name]: number })
    }
    const onValuesChange = changedValues => {
        if (!isNaN(changedValues?.startDate)) {
            setCurrentStartDate(changedValues?.startDate)
        }
    }

    const notZeroValidator = useCallback(
        async (rule, value) => {
            const priceRate = await getPrice(currentTokenType.toUpperCase())

            if (value && collection.floorPrice && Number(value) * priceRate <= collection.floorPrice) {
                return Promise.reject(t('error.message.priceFloorPrice', {floorPrice: collection.floorPrice}))
            }

            if (value && Number(value) === 0) {
                return Promise.reject(t('createNFT.field_price_rule_invalid'))
            }

            return Promise.resolve()
        },
        [t, collection.floorPrice, currentTokenType]
    )

    useEffect(() => {
        if (!tokenId) {
            setQuantity(owner.offSaleAmount)
            form.setFieldsValue({ quantity: owner.offSaleAmount })
        }
    }, [tokenId, owner.offSaleAmount, form])

    return (
        <Modal
            className="custom-sale-modal"
            visible={true}
            onOk={onCloseModal}
            onCancel={onCloseModal}
            footer={null}
            closable={false}
        >
            <div className="sale-modal-container">
                <p className="sale-modal-title">{t('nftDetail.saleDetail')}</p>
                <Form
                    form={form}
                    className="create-nft-form"
                    onFinish={onFinishForm}
                    onValuesChange={onValuesChange}
                    initialValues={{ tokenType: PAYMENT_OPTIONS[collection.networkType][0].value }}
                >
                    <div className="create-nft-marketPlace">
                        <Form.Item initialValue={SALE_TYPE.FIX_PRICE} name="type">
                            <MarketPlaceBtnGroups
                                isShowAuction={false}
                                options={SALE_TYPE}
                                onChange={onChangeSaleType}
                                value={saleType}
                            />
                        </Form.Item>
                    </div>
                    {saleType === SALE_TYPE.FIX_PRICE && (
                        <div className="create-nft-block">
                            <Label
                                title={t('createNFT.field_price')}
                                description={
                                    <span>
                                        Service fee{' '}
                                        <span className="create-nft_text__highlight">{MARKET_FEE * 100}%</span>. You
                                        will receive{' '}
                                        <span className="create-nft_text__highlight">{`${
                                            price
                                                ? BigNumber(price - (MARKET_FEE / 100) * price)
                                                      .decimalPlaces(8)
                                                      .toFormat()
                                                      .toString()
                                                : 0
                                        } ${
                                            PAYMENT_OPTIONS[collection.networkType].find(
                                                option => option.value === currentTokenType
                                            )?.label
                                        }`}</span>
                                    </span>
                                }
                            >
                                <div className="create-nft-price price-container">
                                    <Form.Item
                                        rules={[
                                            {
                                                required: true,
                                                message: t('createNFT.field_price_rule_required')
                                            },
                                            {
                                                validator: notZeroValidator
                                            }
                                        ]}
                                        name="price"
                                        validateTrigger="onBlur"
                                        className="create-nft-price_input"
                                    >
                                        <InputCustom
                                            placeholder={t('createNFT.field_price_placeholder')}
                                            onChange={e => {
                                                onChangeValueInput(e.target.value, 'price')
                                                setPrice(e.target.value)
                                            }}
                                        />
                                    </Form.Item>
                                    <Form.Item
                                        rules={[{ required: true, message: t('form.required') }]}
                                        name="tokenType"
                                        className="create-nft-price_currency"
                                    >
                                        <SelectCustom
                                            placeholder={t('form.select_placeholder')}
                                            options={PAYMENT_OPTIONS[collection.networkType]}
                                            onChange={e => {
                                                setCurrentTokenType(e)
                                            }}
                                        />
                                    </Form.Item>
                                </div>
                            </Label>
                        </div>
                    )}

                    <div className="create-nft-block">
                        <Label title="Quantity" description="Quantity of tokens">
                            <Form.Item
                                rules={[
                                    {
                                        required: true,
                                        message: 'Quantity must be required'
                                    },
                                    {
                                        validator: (rule, value) => {
                                            if (value && Number(value) > owner.offSaleAmount)
                                                return Promise.reject(
                                                    'Quantity must be less than or equal ' + owner.offSaleAmount
                                                )
                                            if (value && Number(value) === 0)
                                                return Promise.reject('Quantity cannot be zero value')
                                            return Promise.resolve()
                                        }
                                    }
                                ]}
                                initialValue={quantity}
                                name="quantity"
                            >
                                <InputCustom
                                    onChange={e => onChangeValueInput(e.target.value, 'quantity', 999999999999, 0)}
                                    placeholder="E. g. 10"
                                    disabled={!tokenId}
                                />
                            </Form.Item>
                        </Label>
                    </div>

                    <ButtonCustom color="blue" htmlType="submit" fullWidth={true} disabled={isGettingLatestInfo}>
                        {t('nftDetail.confirm')}
                    </ButtonCustom>
                </Form>
            </div>
        </Modal>
    )
}

export default SaleModal
