import React, { useState, useEffect, useMemo, useRef } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Row, Col, Checkbox, Modal, Form, Tooltip } from 'antd'
import BigNumber from 'bignumber.js'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { useWindowSize } from 'react-use'
import { useChainId } from 'wagmi'

import { ButtonCustom, InputCustom } from 'components/common'
import { SvgIcon } from 'components/modules'
import * as message from 'utils/custom-message'
import { roundNumber } from 'utils/func'
import { convertBigNumberValueToNumber } from 'blockchain/ether'
import './styles.scss'
import {
    handleOrderCanMatch,
    handleAtomicMatch,
    isUserApprovedERC20,
    handleUserApproveERC20,
    getERC20AmountBalance,
    changeNetwork
} from 'blockchain/utils'
import { getBalance } from 'blockchain/instance'
import {
    PAYMENT_TOKEN,
    ERROR_CODE_USER_DENIED_METAMASK,
    FILE_EXTENSION,
    DECIMAL_TOKENS,
    SOCKET_EVENTS
} from 'constants/index'
import { socket } from 'config'
import { CONTRACT_ADDRESS, NETWORK_ID_TYPE } from 'constants/envs'
import { exportFileFormat } from 'utils/file'
import { convertImage } from 'utils/image'

const BuyModal = ({
    onCloseModal,
    onOpenProcessModal,
    buyFixPriceMetadata,
    setIsDoneProcessing,
    onCloseProcessModal
}) => {
    const { t } = useTranslation()
    const { width } = useWindowSize()
    const chainId = useChainId();
    const dispatch = useDispatch()
    const { previewImage, collection, title, id, tokenId, nftUrl, nftImagePreview, royalty } = useSelector(
        state => state.collectible.data
    )
    const profile = useSelector(state => state.user?.profile)
    const { MARKET_FEE } = useSelector(state => state.system)
    const [quantity, setQuantity] = useState(1)
    const [isCheckTerm, setIsCheckTerm] = useState(false)
    const { walletAddress, id: userId } = useSelector(state => state.user?.profile)
    const [isEnoughBalance, setIsEnoughBalance] = useState(false)
    const [isApproving, setIsApproving] = useState(false)
    const [isTransferring, setIsTransferring] = useState(false)
    const [isUserApproveTradingToken, setIsUserApproveTradingToken] = useState(true)
    const [userBalance, setUserBalance] = useState()
    const [form] = Form.useForm()

    const isSwitchingNetwork = useRef(false);

    const marketFee = useMemo(
        () =>
            buyFixPriceMetadata.signatureSale?.takerRelayerFee
                ? buyFixPriceMetadata.signatureSale?.takerRelayerFee / 10000
                : MARKET_FEE,
        [MARKET_FEE, buyFixPriceMetadata.signatureSale?.takerRelayerFee]
    )

    const onCheckTerm = () => {
        setIsCheckTerm(!isCheckTerm)
    }

    const transfer = async () => {
        setIsTransferring(true)
        const [isOrderCanMatch, error] = await handleOrderCanMatch(
            {
                ...buyFixPriceMetadata.signatureSale,
                quantity: quantity
            },
            chainId
        )
        if (!isOrderCanMatch || error) {
            setIsTransferring(false)
            onCloseModal()
            return message.error(t('error.message.failedToMatchOrder'))
        }
        const [resultAtomicMatch, errorAtomicMatch] = await handleAtomicMatch(
            {
                ...buyFixPriceMetadata.signatureSale,
                quantity: quantity
            },
            chainId
        )
        if (resultAtomicMatch) {
            setIsTransferring(false)
            setIsDoneProcessing(true);
            onOpenProcessModal(resultAtomicMatch?.hash)
        }
        if (errorAtomicMatch) {
            setIsTransferring(false)
            if (errorAtomicMatch?.code === ERROR_CODE_USER_DENIED_METAMASK) {
                message.error(t('error.message.declinedActionWallet'))
            } else {
                message.error(t('error.message.canNotContinueAction'))
            }
            onCloseModal()
            return
        }
    }

    const onChangeValueInput = (value, name, decimal = 6, cb) => {
        if (value === '.') return form.setFieldsValue({ [name]: null })
        const number = value
            .toString()
            .replace(/[^0-9.]/g, '')
            .replace(/(\..*?)\..*/g, '$1')
        if (number.includes('.')) {
            const numString = number.toString().split('.')
            if (numString[1].length > decimal) {
                cb(number.substring(0, numString[0].length + decimal + 1))
                return form.setFieldsValue({ [name]: number.substring(0, numString[0].length + decimal + 1) })
            }
        }

        cb(number)
        form.setFieldsValue({ [name]: number })
    }

    const onClickUserApprove = async () => {
        setIsApproving(true)
        const [approveRes, approveError] = await handleUserApproveERC20(
            CONTRACT_ADDRESS[collection.networkType].proxy,
            CONTRACT_ADDRESS[collection.networkType][buyFixPriceMetadata.currencyToken],
            chainId,
            collection.networkType
        )
        if (approveRes) {
            setIsUserApproveTradingToken(true)
        }
        if (approveError) {
            if (approveError?.code === ERROR_CODE_USER_DENIED_METAMASK) {
                onCloseModal()
                message.error(t('error.message.declinedActionWallet'))
            }
        }
        setIsApproving(false)
    }

    const getDisplayImage = () => {
        const fileExtension = exportFileFormat(nftUrl)
        if (fileExtension === FILE_EXTENSION.MP4 || fileExtension === FILE_EXTENSION.MPEG)
            return convertImage(previewImage)
        return convertImage(nftUrl)
    }

    const checkApprove = async () => {
        if (NETWORK_ID_TYPE[chainId] !== collection.networkType && !isSwitchingNetwork.current) {
            isSwitchingNetwork.current = true;
            await changeNetwork(collection.networkType)
            isSwitchingNetwork.current = false;
        }

        if (
            profile?.walletAddress &&
            buyFixPriceMetadata.currencyToken !== PAYMENT_TOKEN.BNB &&
            buyFixPriceMetadata.currencyToken !== PAYMENT_TOKEN.MATIC &&
            buyFixPriceMetadata.currencyToken !== PAYMENT_TOKEN.ETH
        ) {
            const isApproved = await isUserApprovedERC20(
                CONTRACT_ADDRESS[collection.networkType].proxy,
                CONTRACT_ADDRESS[collection.networkType][buyFixPriceMetadata.currencyToken],
                profile?.walletAddress,
                chainId,
                collection.networkType
            )
            setIsUserApproveTradingToken(!!isApproved)
        }
    };

    const checkBalance = async walletAddress => {
        if (NETWORK_ID_TYPE[chainId] !== collection.networkType) {
            return;
        }
        let balance
        if (
            buyFixPriceMetadata.currencyToken === PAYMENT_TOKEN.BNB ||
            buyFixPriceMetadata.currencyToken === PAYMENT_TOKEN.MATIC ||
            buyFixPriceMetadata.currencyToken === PAYMENT_TOKEN.ETH
        ) {
            balance = await getBalance(walletAddress, chainId);
        } else {
            const [xtrToken] = await getERC20AmountBalance(
                CONTRACT_ADDRESS[collection.networkType][buyFixPriceMetadata.currencyToken],
                walletAddress,
                chainId,
                collection.networkType
            )
            balance = convertBigNumberValueToNumber(
                xtrToken,
                DECIMAL_TOKENS[collection.networkType][buyFixPriceMetadata.currencyToken]
            )
        }

        setUserBalance(balance)

        setIsEnoughBalance(
            Number(balance) >= parseFloat(Number(buyFixPriceMetadata.price) * quantity * (1 + MARKET_FEE))
        )
    };

    useEffect(() => {
        (async () => {
            await checkApprove();
            if (walletAddress) {
                checkBalance(walletAddress);
            }
        })();
    }, [walletAddress, chainId]);

    useEffect(() => {
        if (socket) {
            socket.on(SOCKET_EVENTS.TRANSFER_NFT_SUCCESS, res => {
                if (
                    (res?.data?.toUserId === userId || res?.data?.fromUserId === userId) &&
                    res?.data?.sellHash === buyFixPriceMetadata.sellHash
                ) {
                    onCloseProcessModal()
                }
                return () => socket.off(SOCKET_EVENTS.TRANSFER_NFT_SUCCESS)
            })
        }
    }, [dispatch, onCloseProcessModal, userId]);

    return (
        <Modal
            visible={true}
            onOk={onCloseModal}
            onCancel={!isTransferring && !isApproving ? onCloseModal : () => { }}
            footer={null}
            closable={false}
            className={'custom-buy-modal'}
        >
            <Form form={form}>
                <div className="buy-modal-container">
                    <p className="checkout-title">{t('nftDetail.completeCheckout')}</p>
                    <Col className="checkout-detail">
                        <div className="checkout-detail__header">
                            <p className="bold-text">{t('nftDetail.item')}</p>

                            <p>
                                {`${t('common.walletBalance')}: ${!isNaN(userBalance)
                                    ? `${roundNumber(
                                        userBalance
                                    )} ${buyFixPriceMetadata.currencyToken.toUpperCase()}`
                                    : t('common.loading')
                                    }`}
                            </p>
                        </div>

                        {width >= 576 ? (
                            <Row align="center" justify="space-between">
                                <div className="nft-info">
                                    {title ? (
                                        <img alt="asset" src={getDisplayImage()} className="asset-img" />
                                    ) : (
                                        <div className="loading-img-placeholder">
                                            <SvgIcon name="loading-icon" className="loading-icon" />
                                        </div>
                                    )}
                                    <div className="nft-field">
                                        <p className="collection-text">
                                            <span>{`${t('nftDetail.collection')} `}</span>
                                            <Link
                                                to={`/collection/${collection?.shortUrl || collection?.id || collection?.address
                                                    }`}
                                            >
                                                {collection?.name}
                                            </Link>
                                        </p>
                                        <p className="nft-info-name">{title}</p>
                                        <p className="royalty-display">
                                            <span className="text">{t('common.royalties')}:</span>
                                            <span className="royalty">{` ${parseFloat(
                                                ((Number(royalty) || Number(collection.royalty)) * 1).toFixed(2)
                                            )}%`}</span>
                                            <Tooltip title={t('nftDetail.royalties')} overlayClassName="custom-tooltip">
                                                <i className="ri-error-warning-line"></i>
                                            </Tooltip>
                                        </p>
                                    </div>
                                </div>
                                <div>
                                    <p>{`${BigNumber(buyFixPriceMetadata.price * 1)
                                        .decimalPlaces(6)
                                        .toFormat()
                                        .toString()} ${buyFixPriceMetadata.currencyToken.toUpperCase()}`}</p>
                                </div>
                            </Row>
                        ) : (
                            <div className="nft-info-mobile">
                                {title ? (
                                    <img alt="asset" src={getDisplayImage()} className="asset-img" />
                                ) : (
                                    <img alt="icon" src={DefaultImage} />
                                )}
                                <div>
                                    <p>{title}</p>
                                    <div>
                                        <p className="collection-text">
                                            <span>{`${t('nftDetail.collection')} `}</span>
                                            <Link
                                                to={`/collection/${collection?.shortUrl || collection?.id || collection?.address
                                                    }`}
                                            >
                                                {collection?.name}
                                            </Link>
                                            <p className="royalty-display">
                                                <span className="text">{t('common.royalties')}:</span>
                                                <span className="royalty">{` ${parseFloat(
                                                    ((Number(royalty) || Number(collection.royalty)) * 1).toFixed(2)
                                                )}%`}</span>
                                                <Tooltip
                                                    title={t('nftDetail.royalties')}
                                                    overlayClassName="custom-tooltip"
                                                >
                                                    <i className="ri-error-warning-line"></i>
                                                </Tooltip>
                                            </p>
                                        </p>
                                        <p>
                                            <span>{`${BigNumber(buyFixPriceMetadata.price * 1)
                                                .decimalPlaces(6)
                                                .toFormat()
                                                .toString()} ${buyFixPriceMetadata.currencyToken.toUpperCase()}`}</span>
                                        </p>
                                    </div>
                                </div>
                            </div>
                        )}

                        <Row align="center" justify="space-between">
                            <p className="light-text label-input-form">{t('common.quantity')}</p>
                            <div className="quantity-container">
                                <Form.Item
                                    rules={[
                                        {
                                            required: true,
                                            message: t('error.message.quantityRequired')
                                        },
                                        {
                                            validator: (rule, value) => {
                                                if (value && Number(value) > buyFixPriceMetadata.quantity)
                                                    return Promise.reject(
                                                        t('error.message.quantityLessOrEqual', { quantity: buyFixPriceMetadata.quantity })
                                                    )
                                                if (value && Number(value) === 0)
                                                    return Promise.reject(t('error.message.quantityZero'))
                                                return Promise.resolve()
                                            }
                                        }
                                    ]}
                                    initialValue={quantity}
                                    name="quantity"
                                    className="quantity-input"
                                >
                                    <InputCustom
                                        onChange={e => {
                                            onChangeValueInput(e.target.value, 'quantity', 0, p =>
                                                setQuantity(Number(p))
                                            )
                                        }}
                                        value={quantity}
                                        placeholder={t('nftDetail.enterQuantityValue')}
                                    />
                                </Form.Item>

                                <ButtonCustom
                                    color="red"
                                    onClick={() => {
                                        setQuantity(buyFixPriceMetadata.quantity)
                                        form.setFieldsValue({ quantity: buyFixPriceMetadata.quantity })
                                    }}
                                    size="small"
                                    className="max-btn"
                                >
                                    <span>{t('common.max')}</span>
                                </ButtonCustom>
                            </div>
                        </Row>
                        <Row align="center" justify="space-between border-top">
                            <p className="light-text">{t('nftDetail.subtotal')}</p>
                            <div>
                                <p className="light-text">{`${BigNumber(buyFixPriceMetadata.price * Number(quantity))
                                    .decimalPlaces(8)
                                    .toFormat()
                                    .toString()} ${buyFixPriceMetadata.currencyToken.toUpperCase()}`}</p>
                            </div>
                        </Row>
                        <Row align="center" justify="space-between">
                            <p className="light-text">{t('nftDetail.serviceFee')}</p>
                            <div>
                                <p className="light-text">{`${BigNumber(
                                    buyFixPriceMetadata.price * Number(quantity) * marketFee
                                )
                                    .decimalPlaces(8)
                                    .toFormat()
                                    .toString()} ${buyFixPriceMetadata.currencyToken.toUpperCase()}`}</p>
                            </div>
                        </Row>
                        <Row align="center" justify="space-between">
                            <p className="bold-text">{t('nftDetail.total')}</p>
                            <div>
                                <p>{`${BigNumber(buyFixPriceMetadata.price * (1 + marketFee) * Number(quantity))
                                    .decimalPlaces(8)
                                    .toFormat()
                                    .toString()} ${buyFixPriceMetadata.currencyToken.toUpperCase()}`}</p>
                            </div>
                        </Row>
                        <Row align="center" justify="space-between">
                            <div className="term-of-service">
                                <Checkbox checked={isCheckTerm} onChange={onCheckTerm} />
                                <span className="term-of-service-text">
                                    <span>{t('nftDetail.agree')}</span>
                                    <a href={`https://smapocke.com/TermsofUse`} target="_blank" rel="noreferrer">
                                        {t(`nftDetail.termOfService`)}
                                    </a>
                                </span>
                            </div>
                        </Row>
                    </Col>
                    {userBalance !== undefined && !isEnoughBalance && isUserApproveTradingToken && (
                        <p className="error-balance">{t('error.message.notEnoughBalance')}</p>
                    )}

                    {isUserApproveTradingToken ? (
                        <ButtonCustom
                            color="blue"
                            disabled={!isCheckTerm || !isEnoughBalance || isTransferring}
                            onClick={transfer}
                            loading={isTransferring}
                        >
                            <span>{t('nftDetail.checkout')}</span>
                        </ButtonCustom>
                    ) : (
                        <ButtonCustom
                            disabled={isApproving || !isCheckTerm}
                            loading={isApproving}
                            color="blue"
                            onClick={onClickUserApprove}
                        >
                            <span>{isApproving ? t('common.waitingForApprove') : t('common.approve')}</span>
                        </ButtonCustom>
                    )}
                </div>
            </Form>
        </Modal>
    )
}

export default BuyModal
