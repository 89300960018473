import {createSlice, createAsyncThunk} from '@reduxjs/toolkit';

const initialState = {
  isConfirmDeploy: false,
  isDeployPending: false,
  isDeploySuccess: false,
  isDeployFailed: false,
  createPending: false,
  createSuccess: false,
  createFailed: false,
  collection: {},
  verifyTwitterStatus: true,
  error: '',
  loading: false
};

export const confirmVerifiedCollection = createAsyncThunk(
  'collection/confirmVerifiedCollection',
  async ({address, twitterUsername}, thunkApi) => {
    const { dispatch } = thunkApi;
    dispatch(confirmVerifiedLoadingCollection())
    const [response, err] = await collectionService.confirmVerified(address, twitterUsername)
    if (response) {
        dispatch(confirmVerifiedSuccessCollection());
    } else if (err) {
        dispatch(confirmVerifiedFailureCollection());
    }
  },
);

export const collectionSlice = createSlice({
  name: 'collection',
  initialState,
  reducers: {
    deployCollectionPending: (state) => {
      state.isConfirmDeploy = true;
      state.isDeployPending = true;
    },
    deployCollectionSuccess: (state) => {
      state.isDeploySuccess = true;
      state.isDeployPending = false;
    },
    deployCollectionFailed: (state) => {
      state.isDeployFailed = true;
    },
    createCollectionPending: (state) => {
      state.createPending = true;
    },
    createCollectionSuccess: (state) => {
      state.createSuccess = true;
    },
    createCollectionFailed: (state) => {
      state.createFailed = true;
    },
    resetCollection: () => initialState,
    setCollection: (state, data) => {
      state.collection = {
        ...data.payload
      };
    },
    confirmVerifiedLoadingCollection: (state) => {
      state.loading = true;
    },
    confirmVerifiedSuccessCollection: (state, data) => {
      state.collection.twitterVerify = data.payload;
      state.loading = false;
      state.verifyTwitterStatus = true;
    },
    confirmVerifiedFailureCollection: (state, data) => {
      state.error = data.payload;
      state.loading = false;
      state.verifyTwitterStatus = false;
    },
    unlinkTwitterCollection: (state) => {
      state.collection.twitterVerify = null;
      state.verifyTwitterStatus = false;
    },
    clearErrorVerifyTwitterCollection: (state) => {
      state.error = '',
      state.loading = false;
      state.verifyTwitterStatus = true;
    }
  }
});

export const {
  clearErrorVerifyTwitterCollection,
  confirmVerifiedFailureCollection,
  confirmVerifiedLoadingCollection,
  confirmVerifiedSuccessCollection,
  createCollectionFailed,
  createCollectionPending,
  createCollectionSuccess,
  deployCollectionFailed,
  deployCollectionPending,
  deployCollectionSuccess,
  resetCollection,
  setCollection,
  unlinkTwitterCollection
} = collectionSlice.actions;

export default collectionSlice.reducer;
