export const validateFile = extension => {
    const acceptableFormats = ['jpg', 'png', 'jpeg', 'gif']
    if (acceptableFormats.includes(extension)) {
        return true
    }
    return false
}

export const exportFileFormat = link => {
    return link ? `.${link?.split('.').reverse()[0]}` : ''
}
