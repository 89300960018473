import React, { useRef, useState, useEffect, useCallback } from 'react'
import PropTypes from 'prop-types'
import cls from 'classnames'

//components
import PlayIcon from 'assets/icons/play-fill-icon.svg'
import PauseIcon from 'assets/icons/pause-fill-icon.svg'
import ExpandIcon from 'assets/icons/expand-fill-icon.svg'
import SoundIcon from 'assets/icons/sound-fill-icon.svg'
import MuteIcon from 'assets/icons/sound-mute-icon.svg'

import { Slider } from 'antd'

import './style.scss'

const VIDEO_STATUS = {
    play: 'play',
    paused: 'paused'
}

const formatDuration = duration => {
    if (duration) {
        let minutes = Math.floor((duration % (60 * 60)) / 60)

        let seconds = Math.floor(duration % 60)

        if (minutes < 10) {
            minutes = '0' + minutes
        }
        if (seconds < 10) {
            seconds = '0' + seconds
        }

        return `${minutes}:${seconds}`
    }
    return '00:00'
}

const VideoPlayer = ({ src, type }) => {
    const videoRef = useRef(null)
    const previousVolume = useRef(0)

    const [videoStatus, setVideoStatus] = useState(VIDEO_STATUS.paused)
    const [duration, setDuration] = useState(0)
    const [currentTime, setCurrentTime] = useState(0)
    const [currentVolume, setCurrentVolumne] = useState(30)
    const [isSoundOpen, setIsSoundOpen] = useState(false)

    const onSliderChange = value => {
        setCurrentTime(value)
        if (videoRef?.current?.currentTime) {
            videoRef.current.currentTime = value
        }
    }

    const onVolumeChange = value => {
        setCurrentVolumne(value)
        previousVolume.current = value
        if (!isNaN(videoRef?.current?.volume)) {
            videoRef.current.volume = value / 100
        }
    }

    const mute = useCallback(() => {
        if (!isNaN(videoRef?.current?.volume)) {
            setCurrentVolumne(0)
            videoRef.current.volume = 0
        }
    }, [])

    const unMute = useCallback(() => {
        if (!isNaN(videoRef?.current?.volume)) {
            const previousVolumeValue = previousVolume.current || 30
            setCurrentVolumne(previousVolumeValue)
            videoRef.current.volume = previousVolumeValue / 100
        }
    }, [])

    const tooglePlay = useCallback(() => {
        if (videoStatus === VIDEO_STATUS.paused) {
            videoRef?.current?.play()
            setVideoStatus(VIDEO_STATUS.play)
        }

        if (videoStatus === VIDEO_STATUS.play) {
            videoRef?.current?.pause()
            setVideoStatus(VIDEO_STATUS.paused)
        }
    }, [videoStatus])

    const onPlay = useCallback(() => {
        videoRef?.current?.play()
        setVideoStatus(VIDEO_STATUS.play)
    }, [])

    const onPaused = useCallback(() => {
        videoRef?.current?.pause()
        setVideoStatus(VIDEO_STATUS.paused)
    }, [])

    const onExpand = useCallback(() => {
        videoRef?.current?.requestFullscreen()
    }, [])

    const openSound = useCallback(() => {
        setIsSoundOpen(true)
    }, [])

    const closeSound = useCallback(() => {
        setIsSoundOpen(false)
    }, [])

    useEffect(() => {
        onPlay()
        if (videoRef?.current?.volume) {
            videoRef.current.volume = 30 / 100
        }
        const interval = setInterval(() => {
            if (videoRef?.current?.duration) {
                setDuration(videoRef?.current?.duration)
            }

            if (videoRef?.current?.currentTime) {
                setCurrentTime(videoRef?.current?.currentTime)
            }

            if (videoRef?.current?.currentTime === videoRef?.current?.duration) {
                setVideoStatus(VIDEO_STATUS.paused)
            }
        }, 1000)

        return () => {
            clearInterval(interval)
        }
    }, [])

    return (
        <div className="video-player_wrap">
            <video ref={videoRef} className="video-player_video" onClick={tooglePlay}>
                <source src={src} />
            </video>
            <div
                className={cls('video-player_controls', {
                    'video-player_controls_mp3': type === 'audio/mpeg'
                })}
            >
                <div className="video-player_controls_control">
                    {videoStatus === VIDEO_STATUS.paused && (
                        <img src={PlayIcon} alt="PlayIcon" className="video-player_controls_item" onClick={onPlay} />
                    )}
                    {videoStatus === VIDEO_STATUS.play && (
                        <img src={PauseIcon} alt="PauseIcon" className="video-player_controls_item" onClick={onPaused} />
                    )}
                </div>
                <div className="video-player_controls_sliders">
                    <Slider
                        onChange={onSliderChange}
                        tooltipVisible={false}
                        className="slider-custom"
                        value={currentTime}
                        min={0}
                        max={duration}
                    />
                </div>
                <div className="video-player_controls_times">
                    <span>{formatDuration(currentTime)}</span>
                </div>
                <div className="video-player_controls_expand">
                    <img src={ExpandIcon} alt="ExpandIcon" className="video-player_controls_item" onClick={onExpand} />
                </div>
                <div className="video-player_controls_sound" onMouseEnter={openSound} onMouseLeave={closeSound}>
                    {currentVolume > 0 ? (
                        <img src={SoundIcon} alt="SoundIcon" className="video-player_controls_item" onClick={mute} />
                    ) : (
                        <img src={MuteIcon} alt="MuteIcon" className="video-player_controls_item" onClick={unMute} />
                    )}
                    {isSoundOpen && (
                        <Slider
                            onChange={onVolumeChange}
                            vertical
                            value={currentVolume}
                            className="slider-custom video-player_controls_sound_picker"
                        />
                    )}
                </div>
            </div>
        </div>
    )
}

VideoPlayer.propTypes = {
    src: PropTypes.any,
    type: PropTypes.any
}

export default VideoPlayer
