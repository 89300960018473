import React, { useCallback, useState, useRef } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { Upload } from 'antd'

//components
import { VideoPlayer } from './components'

//utils
import * as message from 'utils/custom-message'
import { SUPPORTED_FILE_TYPE, PREVIEW_SUPPORTED_FILE_TYPE, VIDEO_FILE_TYPE } from 'constants/index.js'

//style
import './style.scss'

//image
import CancelIcon from 'assets/icons/cancel-icon.svg'
import UploadIcon from 'assets/icons/upload.svg'
import { useTranslation } from 'react-i18next'
import { ButtonCustom } from '..'

const FILE_MAX_SIZE = 100000000

// function getBase64(img, callback) {
//     const reader = new FileReader()
//     reader.addEventListener('load', () => callback(reader.result))
//     reader.readAsDataURL(img)
// }

const CustomDragger = ({ className, onChange, setUploadFile, setPreviewFile, ...restProps }) => {
    const { t, i18n } = useTranslation()
    const videoTypRef = useRef(null)
    const [image, setImage] = useState(null)
    const [video, setVideo] = useState(null)
    const [preview, setPreview] = useState(null)

    const beforeUpload = useCallback((file) => {
        if (!SUPPORTED_FILE_TYPE.includes(file.type)) {
            message.error(t('error.message.fileFormatNotSupported'))
            return Upload.LIST_IGNORE
        }

        if (file.size > FILE_MAX_SIZE) {
            message.error(t('error.message.fileSizeExceeded', { size: 100 }))
            return Upload.LIST_IGNORE
        }

        return true
    }, [t, i18n.language])

    const previewBeforeUpload = useCallback((file) => {
        if (!PREVIEW_SUPPORTED_FILE_TYPE.includes(file.type)) {
            message.error(t('error.message.fileFormatNotSupported'))
            return Upload.LIST_IGNORE
        }

        if (file.size > FILE_MAX_SIZE) {
            message.error(t('error.message.fileSizeExceeded', { size: 100 }))
            return Upload.LIST_IGNORE
        }

        return true
    }, [t, i18n.language])

    const handleChange = useCallback(
        info => {
            const blob = new Blob([info.file.originFileObj], { type: info.file.type })
            const url = URL.createObjectURL(blob)
            if (VIDEO_FILE_TYPE.includes(info.file.type)) {
                setVideo(url)
                setImage(null)
                videoTypRef.current = info.file.type
            } else {
                setImage(url)
                setVideo(null)
                if (onChange) {
                    onChange(url)
                }
            }
            setUploadFile(info.file.originFileObj)
        },
        [onChange, setUploadFile]
    )

    const handleChangePreview = useCallback(
        info => {
            const blob = new Blob([info.file.originFileObj], { type: info.file.type })
            const url = URL.createObjectURL(blob)
            setPreview(url)
            if (onChange) {
                onChange(url)
            }
            setPreviewFile(info.file.originFileObj)
        },
        [onChange, setPreviewFile]
    )

    const clearImage = useCallback(() => {
        setImage(null)
        onChange(null)
    }, [onChange])

    const clearVideo = useCallback(() => {
        setVideo(null)
        setPreview(null)
        setPreviewFile(null)
        onChange(null)
    }, [onChange, setPreviewFile])

    const clearPreview = useCallback(() => {
        setPreview(null)
        onChange(null)
        setPreviewFile(null)
    }, [onChange, setPreviewFile])

    const dummyRequest = ({ file, onSuccess }) => {
        setTimeout(() => {
            onSuccess('ok')
        }, 0)
    }

    const uploadDraggerClass = classNames({
        'upload-dragger-custom': true,
        active: !!image || !!preview,
        [className]: !!className
    })

    return (
        <div>
            {video ? (
                <>
                    <div className="upload-dragger-custom_video_wrap">
                        <div className="upload-dragger-custom_video__container">
                            <VideoPlayer src={video} type={videoTypRef.current} />
                            <div className="upload-dragger-custom_cancelIcon" onClick={clearVideo}>
                                <img src={CancelIcon} alt="cancel-icon" name="cancel-icon" />
                            </div>
                        </div>
                    </div>
                    <Upload.Dragger
                        // accept=".png,.jpg,.jpeg,.mp3,.mp4,.webp,.gif"
                        showUploadList={false}
                        disabled={!!preview}
                        onChange={handleChangePreview}
                        className={uploadDraggerClass}
                        customRequest={dummyRequest}
                        beforeUpload={previewBeforeUpload}
                        {...restProps}
                    >
                        {preview ? (
                            <div className="upload-dragger-custom_wrap">
                                <img alt="dragger" className="upload-dragger-custom_image" src={preview} />
                                <div className="upload-dragger-custom_cancelIcon" onClick={clearPreview}>
                                    <img src={CancelIcon} alt="cancel-icon" name="cancel-icon" />
                                </div>
                            </div>
                        ) : (
                            <>
                                <img className="upload-icon" src={UploadIcon} alt="upload-icon" />
                                <p className="upload-title">{t('common.dragger.title')}</p>
                                <p className="upload-dragger-custom_title" dangerouslySetInnerHTML={{ __html: t('common.dragger.helpText1') }}>
                                </p>
                                <ButtonCustom size="md" variant="outline" className="upload-dragger-custom_btn">{t('common.dragger.btnText1')}</ButtonCustom>
                            </>
                        )}
                    </Upload.Dragger>
                </>
            ) : (
                <Upload.Dragger
                    // accept=".png,.jpg,.jpeg,.mp3,.mp4,.webp,.gif"
                    showUploadList={false}
                    disabled={!!image}
                    onChange={handleChange}
                    className={uploadDraggerClass}
                    customRequest={dummyRequest}
                    beforeUpload={beforeUpload}
                    {...restProps}
                >
                    {image ? (
                        <div className="upload-dragger-custom_wrap">
                            <img alt="dragger" className="upload-dragger-custom_image" src={image} />
                            <div className="upload-dragger-custom_cancelIcon" onClick={clearImage}>
                                <img src={CancelIcon} alt="cancel-icon" name="cancel-icon" />
                            </div>
                        </div>
                    ) : (
                        <>
                            <img className="upload-icon" src={UploadIcon} alt="upload-icon" />
                            <p className="upload-title">{t('common.dragger.title')}</p>
                            <p className="upload-dragger-custom_title" dangerouslySetInnerHTML={{ __html: t('common.dragger.helpText2') }}>

                            </p>
                            <ButtonCustom size="md" variant="outline" className="upload-dragger-custom_btn">
                                {t('common.dragger.btnText2')}
                            </ButtonCustom>
                        </>
                    )}
                </Upload.Dragger>
            )}
        </div>
    )
}

CustomDragger.propTypes = {
    className: PropTypes.string,
    onChange: PropTypes.func,
    setUploadFile: PropTypes.func
}

export default CustomDragger
