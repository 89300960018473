import React from 'react'
import { Modal } from 'antd'
import { useConnect } from 'wagmi'
import './style.scss'
import { useSelector, useDispatch } from 'react-redux'
import { get } from 'lodash'

import { SvgIcon } from 'components/modules'
import { getWalletConnect } from 'blockchain/utils'
import { closeUserClosedModal } from 'store/modal.slice'
import { ButtonCustom } from '..'
import { useTranslation } from 'react-i18next'

const UserClosedModal = () => {
    const { t } = useTranslation();
    const { connect, chainId, connectors } = useConnect();

    const modal = useSelector(state => state.modal)

    const openUserClosedModal = get(modal, 'openUserClosedModal')

    const dispatch = useDispatch()

    const onCancel = () => {
        dispatch(closeUserClosedModal())
    }

    const handleWalletConnect = async () => {
        // setActiveProtocol(PROTOCOL_TYPE.WALLETCONNECT)
        const walletConnect = getWalletConnect(connectors);
        connect({ connector: walletConnect, chainId }, {
            onSuccess: () => {
                dispatch(closeUserClosedModal());
            }
        })
    }

    return (
        <Modal className="reject-sign-modal" centered footer={null} open={openUserClosedModal}>
            <div className="modal-wrapper">
                <div>
                    <SvgIcon name="wrong-network-icon" />
                </div>
                <span className="title">{t('error.error')}</span>
                <span className="description" dangerouslySetInnerHTML={{ __html: t('error.closedModal.description') }}>
                </span>

                <div className="btn-sign-wallet">
                    <ButtonCustom onClick={handleWalletConnect} color="blue">
                        <span>{t('common.tryAgain')}</span>
                    </ButtonCustom>
                    <ButtonCustom onClick={onCancel} color="white" className="btn-disconnect">
                        <span>{t('common.cancel')}</span>
                    </ButtonCustom>
                </div>
            </div>
        </Modal>
    )
}

export default UserClosedModal
