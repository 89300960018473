import React, { useMemo } from "react";
import PropTypes from "prop-types";

import ListingsActivity from "./ListingsActivity";
import PurchasesActivity from "./PurchasesActivity";
import LikesActivity from "./LikesActivity";
import FollowingActivity from "./FollowingActivity";
import BidsActivity from "./BidsActivity";
import { ACTIVITY_STATUS, FILE_EXTENSION } from "constants/index";
import { convertImage } from "utils/image";
import "./style.scss";
import { exportFileFormat } from "utils/file";
import AcceptPurchasesActivity from "./AcceptPurchasesActivity";

const CardActivity = ({ data, walletAddress, isAcceptOffer }) => {
  const {
    createdAt,
    user,
    follow,
    nftHistory,
    like,
    transactionType,
    account = like?.account,
  } = data || {};
  const follower = follow?.follower;
  const following = follow?.following;
  const fromUser = nftHistory?.fromUser;
  const toUser = nftHistory?.toUser;
  //display preview image
  const displayPreviewImage = (url, imagePreview) => {
    if (
      exportFileFormat(url) === FILE_EXTENSION.MP4 ||
      exportFileFormat(url) === FILE_EXTENSION.MPEG
    ) {
      return convertImage(imagePreview);
    } else {
      return convertImage(url);
    }
  };

  const RenderType = (action) => {
    switch (action) {
      case ACTIVITY_STATUS.LISTING:
        return (
          <ListingsActivity
            user={user}
            saleNft={nftHistory}
            displayPreviewImageSale={displayPreviewImage(
              nftHistory?.nft?.nftUrl,
              nftHistory?.nft?.nftImagePreview
            )}
            createdAt={createdAt}
            walletAddress={walletAddress}
          />
        );
      case ACTIVITY_STATUS.PURCHASE:
        return (
          <PurchasesActivity
            user={toUser}
            user2={fromUser}
            saleNft={nftHistory}
            displayPreviewImage={displayPreviewImage(
              nftHistory?.nft?.nftUrl,
              nftHistory?.nft?.nftImagePreview
            )}
            createdAt={createdAt}
            walletAddress={walletAddress}
          />
        );
      case ACTIVITY_STATUS.LIKE:
        return (
          <LikesActivity
            user={account}
            nft={like?.infoLike}
            displayPreviewImage={displayPreviewImage(
              like?.infoLike?.nftUrl,
              like?.infoLike?.nftImagePreview
            )}
            createdAt={createdAt}
            walletAddress={walletAddress}
          />
        );
      case ACTIVITY_STATUS.FOLLOW:
        return (
          <FollowingActivity
            user={follower}
            user2={following}
            createdAt={createdAt}
            walletAddress={walletAddress}
          />
        );
      case ACTIVITY_STATUS.BID:
      case ACTIVITY_STATUS.OPEN_FOR_OFFER:
        return (
          <BidsActivity
            user={toUser}
            saleNft={nftHistory}
            isAcceptOffer={isAcceptOffer}
            user2={fromUser}
            displayPreviewImage={displayPreviewImage(
              nftHistory?.nft?.nftUrl,
              nftHistory?.nft?.nftImagePreview
            )}
            createdAt={createdAt}
            walletAddress={walletAddress}
          />
        );
      case ACTIVITY_STATUS.ACCEPT_BID_PURCHASE:
      case ACTIVITY_STATUS.ACCEPT_OFFER_PURCHASE:
        return (
          <AcceptPurchasesActivity
            user={fromUser}
            user2={toUser}
            saleNft={nftHistory}
            displayPreviewImage={displayPreviewImage(
              nftHistory?.nft?.nftUrl,
              nftHistory?.nft?.nftImagePreview
            )}
            createdAt={createdAt}
            walletAddress={walletAddress}
            type={action}
          />
        );
      default:
        return (
          <ListingsActivity
            user={user}
            saleNft={nftHistory}
            displayPreviewImageSale={displayPreviewImage(
              nftHistory?.nft?.nftUrl,
              nftHistory?.nft?.nftImagePreview
            )}
            createdAt={createdAt}
            walletAddress={walletAddress}
          />
        );
    }
  };

  return <>{RenderType(transactionType)}</>;
};

CardActivity.propTypes = {
  data: PropTypes.object.isRequired,
};

export default CardActivity;
