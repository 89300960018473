import { useTranslation } from "react-i18next"
import Owner from "../../Overview/Owner"
import { useSelector } from "react-redux"
import './style.scss'

export default function Description() {
    const { t } = useTranslation()
    const {
        description,
        owners,
        creator,
    } = useSelector(state => state.collectible.data)

    return (
        <div className="description-section">
            <span>{t('nftDetail.description')}</span>
            <div className="desc-content">
                <Owner />
                <p>{description || t('nftDetail.noDescription')}</p>
            </div>
        </div>
    )
}