import React, { useState, useCallback, useMemo, useEffect } from 'react'
import { Form, Modal } from 'antd'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import moment from 'moment'
import BigNumber from 'bignumber.js'

import { Label } from 'components/modules'
import { ButtonCustom, InputCustom, SelectCustom, DateTimeModal } from 'components/common'
import { PAYMENT_OPTIONS, PAYMENT_OPTIONS_AUCTION, SALE_TYPE } from 'constants/index'
import { getPrice } from 'service/getPrice'
import { START_DATE_OPTIONS, EXPIRE_DATE_OPTIONS } from '../../../../../create-nft/constant'
import { MarketPlaceBtnGroups } from '../../../../../create-nft/components'
import './style.scss'

const MAX_VALUE = 100000000000000

const SaleModal = ({ onCloseModal, onOpenSaleStepModal, onOpenAuctionStepModal, onOpenBidStepModal }) => {
    const { t } = useTranslation()
    const [form] = Form.useForm()
    const { id, collection } = useSelector(state => state.collectible.data)
    const { MARKET_FEE } = useSelector(state => state?.system)
    const [saleType, setSaleType] = useState(SALE_TYPE.FIX_PRICE)
    const [price, setPrice] = useState(0)
    const [currentTokenType, setCurrentTokenType] = useState(PAYMENT_OPTIONS[collection.networkType][0].value)
    const [currentStartDate, setCurrentStartDate] = useState(1)
    const [currentExpireDate, setCurrentExpireDate] = useState(1)
    const [isGettingLatestInfo, setIsGettingLatestInfo] = useState(false)

    const minimumBidPriceValue = form.getFieldValue('minimumBid')

    const renderCurrentExpireDate = useMemo(() => {
        if (saleType !== SALE_TYPE.FIX_PRICE && currentExpireDate && currentStartDate) {
            if (typeof currentExpireDate === 'number') {
                if (typeof currentStartDate === 'string') {
                    return moment(currentStartDate).add(currentExpireDate, 'days').unix()
                }
                return moment().add(currentExpireDate, 'days').unix()
            }
            return moment(currentExpireDate).unix()
        }

        return null
    }, [currentExpireDate, saleType, currentStartDate])

    const renderCurrentStartDate = useMemo(() => {
        if (saleType !== SALE_TYPE.FIX_PRICE && currentStartDate) {
            if (typeof currentStartDate === 'number') {
                return moment().unix()
            }
            return moment(currentStartDate).unix()
        }

        return null
    }, [currentStartDate, saleType])

    const onChangeSaleType = type => {
        setSaleType(type)
    }

    const onFinishForm = async () => {
        setIsGettingLatestInfo(true)

        switch (saleType) {
            case SALE_TYPE.FIX_PRICE: {
                onOpenSaleStepModal({
                    price: form.getFieldValue('price'),
                    tokenType: currentTokenType,
                    saleType: SALE_TYPE.FIX_PRICE,
                    nftId: id
                })
                setIsGettingLatestInfo(false)
                onCloseModal()
                break
            }

            case SALE_TYPE.ENGLISH_AUCTION: {
                onOpenAuctionStepModal({
                    expireDate: renderCurrentExpireDate,
                    startDate: renderCurrentStartDate,
                    minBid: Number(minimumBidPriceValue),
                    saleType: SALE_TYPE.ENGLISH_AUCTION,
                    nftId: id,
                    tokenType: currentTokenType
                })
                break
            }

            case SALE_TYPE.OPEN_FOR_OFFER: {
                onOpenBidStepModal({
                    startDate: renderCurrentStartDate,
                    type: SALE_TYPE.OPEN_FOR_OFFER,
                    nftId: id
                })
                break
            }
            default:
                break
        }
    }
    const onChangeValueInput = (value, name, maxValue = MAX_VALUE, decimal = 6) => {
        if (value === '.') return form.setFieldsValue({ [name]: null })
        let number = value
            .toString()
            .replace(/[^0-9.]/g, '')
            .replace(/(\..*?)\..*/g, '$1')
        if (Number(number) >= maxValue) {
            number = number.slice(0, -1)
        }
        if (number.includes('.')) {
            const numString = number.toString().split('.')
            if (numString[1].length > decimal) {
                setPrice(number.substring(0, number.length - 1))
                return form.setFieldsValue({ [name]: number.substring(0, number.length - 1) })
            }
        }
        form.setFieldsValue({ [name]: number })
        setPrice(number)
    }
    const onValuesChange = changedValues => {
        if (!isNaN(changedValues?.startDate)) {
            setCurrentStartDate(changedValues?.startDate)
        }
        if (!isNaN(changedValues?.expireDate)) {
            setCurrentExpireDate(changedValues?.expireDate)
        }
    }

    const onMinimumBidStartDateChange = useCallback(value => {
        form.setFieldsValue({ startDate: value })
        form.validateFields(['startDate', 'expireDate'])
        setCurrentStartDate(value)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const onMinimumBidStartDateValidator = useCallback(() => {
        if (isNaN(currentStartDate)) {
            if (moment().unix() > moment(currentStartDate).unix()) {
                return Promise.reject(t('createNFT.field_startDate_rule_after_present'))
            }
        }
        return Promise.resolve()
    }, [currentStartDate, t])

    const onMinimumBidExpireDateChange = useCallback(value => {
        form.setFieldsValue({ expireDate: value })
        form.validateFields(['expireDate', 'startDate'])
        setCurrentExpireDate(value)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const onMinimumBidExpireDateValidator = useCallback(() => {
        if (isNaN(currentExpireDate)) {
            if (moment().unix() >= moment(currentExpireDate).unix()) {
                return Promise.reject(t('createNFT.field_expireDate_rule_after_present'))
            }
        }
        if (isNaN(currentExpireDate) && isNaN(currentStartDate)) {
            if (moment(currentStartDate).unix() >= moment(currentExpireDate).unix()) {
                return Promise.reject(t('createNFT.field_expireDate_rule_after'))
            }
        }

        return Promise.resolve()
    }, [currentExpireDate, currentStartDate, t])

    const notZeroValidator = useCallback(
        async (rule, value) => {
            const priceRate = await getPrice(currentTokenType.toUpperCase())

            if (value && collection.floorPrice && Number(value) * priceRate <= collection.floorPrice) {
                return Promise.reject(t('error.message.priceFloorPrice', {floorPrice: collection.floorPrice}))
            }

            if (value && Number(value) === 0) {
                return Promise.reject(t('createNFT.field_price_rule_invalid'))
            }

            return Promise.resolve()
        },
        [t, collection.floorPrice, currentTokenType]
    )

    useEffect(() => {
        if (saleType === SALE_TYPE.ENGLISH_AUCTION) {
            setCurrentTokenType(PAYMENT_OPTIONS_AUCTION[collection.networkType][0].value)
        }
        if (saleType === SALE_TYPE.FIX_PRICE) {
            setCurrentTokenType(PAYMENT_OPTIONS[collection.networkType][0].value)
        }
    }, [saleType, collection.networkType])

    return (
        <Modal
            className="custom-sale-modal"
            visible={true}
            onOk={onCloseModal}
            onCancel={onCloseModal}
            footer={null}
            closable={false}
        >
            <div className="sale-modal-container">
                <p className="sale-modal-title">
                    {t('nftDetail.saleDetail')} {currentTokenType.toUpperCase()}
                </p>
                <Form form={form} className="create-nft-form" onFinish={onFinishForm} onValuesChange={onValuesChange}>
                    <div className="create-nft-marketPlace">
                        <Form.Item initialValue={SALE_TYPE.FIX_PRICE} name="type">
                            <MarketPlaceBtnGroups options={SALE_TYPE} onChange={onChangeSaleType} value={saleType} />
                        </Form.Item>
                    </div>
                    {saleType === SALE_TYPE.FIX_PRICE && (
                        <div className="create-nft-block">
                            <Label
                                title={t('createNFT.field_price')}
                                description={
                                    <span>
                                        Service fee{' '}
                                        <span className="create-nft_text__highlight">{MARKET_FEE * 100}%</span>. You
                                        will receive{' '}
                                        <span className="create-nft_text__highlight">{`${
                                            price
                                                ? BigNumber(price - MARKET_FEE * price)
                                                      .decimalPlaces(8)
                                                      .toFormat()
                                                      .toString()
                                                : 0
                                        } ${
                                            PAYMENT_OPTIONS[collection.networkType].find(
                                                option => option.value === currentTokenType
                                            )?.label
                                        }`}</span>
                                    </span>
                                }
                            >
                                <div className="create-nft-price price-container">
                                    <Form.Item
                                        rules={[
                                            {
                                                required: true,
                                                message: t('createNFT.field_price_rule_required')
                                            },
                                            {
                                                validator: notZeroValidator
                                            }
                                        ]}
                                        name="price"
                                        validateTrigger="onBlur"
                                        className="create-nft-price_input"
                                    >
                                        <InputCustom
                                            placeholder={t('createNFT.field_price_placeholder')}
                                            onChange={e => onChangeValueInput(e.target.value, 'price')}
                                        />
                                    </Form.Item>
                                    <Form.Item
                                        rules={[{ required: true, message: t('form.required') }]}
                                        className="create-nft-price_currency"
                                    >
                                        <SelectCustom
                                            placeholder={t('form.select_placeholder')}
                                            options={PAYMENT_OPTIONS[collection.networkType]}
                                            value={currentTokenType}
                                            onChange={e => {
                                                setCurrentTokenType(e)
                                            }}
                                        />
                                    </Form.Item>
                                </div>
                            </Label>
                        </div>
                    )}

                    {saleType === SALE_TYPE.ENGLISH_AUCTION && (
                        <>
                            <div className="create-nft-block">
                                <Label
                                    title={t('createNFT.field_minimumBid')}
                                    description={t('createNFT.field_minimumBid_desc')}
                                >
                                    <div className="create-nft-minimumBid">
                                        <Form.Item
                                            rules={[
                                                {
                                                    required: true,
                                                    message: t('createNFT.field_minimumBid_rule_required')
                                                },
                                                {
                                                    validator: notZeroValidator
                                                }
                                            ]}
                                            validateTrigger="onBlur"
                                            name="minimumBid"
                                            className="create-nft-minimumBid_price_input"
                                        >
                                            <InputCustom
                                                onChange={e => onChangeValueInput(e.target.value, 'minimumBid')}
                                                placeholder={t('createNFT.field_minimumBid_placeholder')}
                                                className="create-nft-minimumBid_price_input_custom"
                                            />
                                        </Form.Item>
                                        <Form.Item
                                            rules={[{ required: true, message: t('form.required') }]}
                                            className="create-nft-price_currency"
                                        >
                                            <SelectCustom
                                                placeholder={t('form.select_placeholder')}
                                                options={PAYMENT_OPTIONS_AUCTION[collection.networkType]}
                                                value={currentTokenType}
                                                onChange={e => {
                                                    setCurrentTokenType(e)
                                                }}
                                            />
                                        </Form.Item>
                                    </div>
                                </Label>
                            </div>
                            <div className="create-nft-block">
                                <div className="create-nft-minimumBid">
                                    <Label
                                        title={t('createNFT.field_startDate')}
                                        className="create-nft-minimumBid_label"
                                    >
                                        <Form.Item
                                            rules={[
                                                { required: true, message: t('form.required') },
                                                { validator: onMinimumBidStartDateValidator }
                                            ]}
                                            name="startDate"
                                            initialValue={1}
                                            className="create-nft-startDate"
                                        >
                                            <SelectCustom
                                                placeholder={t('form.select_placeholder')}
                                                options={START_DATE_OPTIONS.map(option => ({ ...option, label: t('startDateOption.' + option.label) }))}
                                                getPopupContainer={triggerNode => triggerNode.parentNode}
                                            />
                                        </Form.Item>
                                    </Label>
                                    <Label
                                        title={t('createNFT.field_expireDate')}
                                        className="create-nft-minimumBid_label expireDate_label"
                                    >
                                        <Form.Item
                                            rules={[
                                                { required: true, message: t('form.required') },
                                                { validator: onMinimumBidExpireDateValidator }
                                            ]}
                                            name="expireDate"
                                            initialValue={1}
                                            className="create-nft-expireDate"
                                        >
                                            <SelectCustom
                                                placeholder={t('form.select_placeholder')}
                                                options={EXPIRE_DATE_OPTIONS.map(option => ({ ...option, label: t('expiredDateOption.' + option.label) }))}
                                                getPopupContainer={triggerNode => triggerNode.parentNode}
                                            />
                                        </Form.Item>
                                    </Label>
                                </div>
                            </div>
                        </>
                    )}

                    <ButtonCustom color="blue" htmlType="submit" fullWidth={true} disabled={isGettingLatestInfo}>
                        {t('nftDetail.confirm')}
                    </ButtonCustom>
                </Form>
            </div>
            {currentStartDate === 0 && <DateTimeModal onChange={onMinimumBidStartDateChange} visible={true} />}
            {currentExpireDate === 0 && <DateTimeModal onChange={onMinimumBidExpireDateChange} visible={true} />}
        </Modal>
    )
}

export default SaleModal
