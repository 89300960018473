import React, { useState, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { Avatar } from 'antd'

import { ButtonCustom } from 'components/common'
import * as message from 'utils/custom-message'
import { validateFile } from 'utils/file'
import { useResizeWindow } from 'utils/hook'
import './style.scss'

const FILE_MAX_SIZE = 100000000

const DisplayImagePicker = ({ placeholder, onChange }) => {
    const { t } = useTranslation()
    const [isMobile] = useResizeWindow(768) // TODO

    const [displayImage, setDisplayImage] = useState(null)

    const uploadFileBtn = useRef(null)

    const onClickUpload = () => uploadFileBtn.current.click()

    const getAvatarPlaceHolder = () => {
        if (displayImage) return URL.createObjectURL(displayImage)
        return null
    }

    const onChangeAvatar = e => {
        const file = e.target.files[0]
        if (!file && !displayImage) return onChange(null)

        if (file?.size > FILE_MAX_SIZE) {
            uploadFileBtn.current.value = ''
            return message.error(t('error.message.fileSizeExceeded', { size: 100 }))
        }
        if (file && validateFile(file.type.split('/')[1])) {
            onChange(file)
            return setDisplayImage(file)
        }
        if (!validateFile(file?.type?.split('/')[1]) && file) {
            message.error(t('editProfile.fileNotSupported'))
        }
    }
    return (
        <div className="select-avatar">
            <Avatar size={isMobile ? 90 : 100} icon={<img alt="avatar" src={getAvatarPlaceHolder() || placeholder} />} />
            <div className="content">
                <span className="select-avatar_title">{t('createCollection.field_displayimage')}</span>
                <span className="recommend">{t('createCollection.field_displayimage_desc')}</span>
                <ButtonCustom variant="outline" size="md" onClick={onClickUpload}>
                    <span>{t('createCollection.field_displayimage_choosefile')}</span>
                </ButtonCustom>

                <input type="file" id="actual-btn" ref={uploadFileBtn} onChange={onChangeAvatar} hidden />
            </div>
        </div>
    )
}

export default DisplayImagePicker
