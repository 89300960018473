import React, { useContext } from 'react'
import { Carousel } from 'antd'

import styles from './style.module.scss'
import { useResizeWindow } from 'utils/hook'
import { SCREEN_SIZE } from 'constants/index'
import { ThemeContext } from '../../../ThemeProvider'
import { SvgIcon } from '..'

const NextArrow = ({ className, style, onClick, arrowOffset }) => {
    const { theme } = useContext(ThemeContext)
    if (!onClick) return null

    return (
        <div className={className} style={{ ...style, right: `${arrowOffset}px` }} onClick={onClick}>
            <div className={`${styles.iconWrapper} ${theme === 'dark-theme' ? styles.iconWrapperDark : ''}`}>
                <SvgIcon name="arrow-next" />
            </div>
        </div>
    )
}

const PrevArrow = ({ className, style, onClick, arrowOffset }) => {
    const { theme } = useContext(ThemeContext)
    if (!onClick) return null

    return (
        <div className={className} style={{ ...style, left: `${arrowOffset}px` }} onClick={onClick}>
            <div className={`${styles.iconWrapper} ${theme === 'dark-theme' ? styles.iconWrapperDark : ''}`}>
                <SvgIcon name="arrow-prev" />
            </div>
        </div>
    )
}

const CardCarousel = ({
    children,
    carouselProps,
    arrowOffset = 12,
    countData = 4,
    hotCollections = false,
    row = 1,
    amoutItem,
    scroll,
    isLogo,
    isAuto
}) => {
    const [isBigSize] = useResizeWindow(SCREEN_SIZE.xl)
    const [isDesktopSize] = useResizeWindow(SCREEN_SIZE.desktop)
    const [isTabletSize] = useResizeWindow(SCREEN_SIZE.tablet)
    const [isMobileSize] = useResizeWindow(SCREEN_SIZE.mobile)

    const setSlidesToShow = () => {
        if (hotCollections && isMobileSize) {
            return compareNumber(2, countData)
        } else if (isTabletSize) {
            return compareNumber(1, countData)
        } else if (isDesktopSize) {
            return compareNumber(2, countData)
        } else if (isBigSize) {
            return compareNumber(3, countData)
        }
        return compareNumber(amoutItem ? amoutItem : 4, countData)
    }

    const setSlideToShowLogo = () => {
        if (isMobileSize) {
            return compareNumber(1, countData)
        } else if (isTabletSize) {
            return compareNumber(2, countData)
        } else if (isDesktopSize) {
            return compareNumber(5, countData)
        }
        return compareNumber(amoutItem ? amoutItem : 4, countData)
    }

    const compareNumber = (a, b) => {
        if (a < b) return a
        return b
    }

    return (
        <Carousel
            arrows={true}
            nextArrow={<NextArrow arrowOffset={arrowOffset} />}
            prevArrow={<PrevArrow arrowOffset={arrowOffset} />}
            dots={false}
            rows={isTabletSize ? 1 : row}
            slidesToShow={isLogo ? setSlideToShowLogo() : setSlidesToShow()}
            slidesToScroll={scroll || setSlidesToShow()}
            className={styles.customCarousel}
            autoplay={isAuto}
            {...carouselProps}
        >
            {children}
        </Carousel>
    )
}

export default CardCarousel
