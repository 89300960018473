import React, { useCallback, useEffect, useState, useMemo, useRef, useContext } from 'react'
// import ReactLoading from 'react-loading'
import { Avatar, Col, Divider, Row, Tooltip } from 'antd'
import { useSelector } from 'react-redux'
// import { useIntersection } from 'react-use'

// import collectionService from 'service/collectionService'
// import * as message from 'utils/custom-message'
import { NETWORK_OPTIONS, SCREEN_SIZE, SORT_COLLECTION_VALUE } from 'constants/index'
// import { CardCollectionTwo } from 'components/modules'
import { ButtonCustom, NoData, RadioGroupCustom, SelectCustom, TabsCustom } from 'components/common'
import { useResizeWindow } from 'utils/hook'
import { ThemeContext } from '../../../../ThemeProvider'
import PolygonIcon from 'assets/icons/polygon-icon.svg'
import FilterIcon from 'assets/icons/filter-icon.svg'
import CustomSwitch from 'components/common/switch'
import './styles.scss'
import { useTranslation } from 'react-i18next'
import { useMultiLanguageConstant } from 'hooks/useMultiLanguageConstant'
// import { Link } from 'react-router-dom'
// import CustomVerifyIcon from 'components/common/custom-verify-icon'
// import { convertToIncreaseOrDecreasePercent, stakingRoundNumber } from 'utils/formatNumber'
// import { NETWORKS } from 'constants/envs'
// import { convertImage } from 'utils/image'
// import { abbreviateNumber } from 'utils/currencyFormat'
import CollectionListPart from './CollectionList.part'
import { OutSideClick } from 'components/modules'

const STEP = 12

const optionSortBy = [{ label: 'Recent', value: SORT_COLLECTION_VALUE.RECENT }]

const optionFilter = [
    { label: 'Verified Only', key: 'verification' },
    { label: 'Show NSFW', key: 'nsfw' }
]

const CollectionsProfile = props => {
    const { t, i18n } = useTranslation();
    const { theme } = useContext(ThemeContext)
    const { periodSelections } = useMultiLanguageConstant();
    // const history = useHistory()

    const categories = useSelector(state => state.categories?.data || [])

    const { userId } = props
    const [isTablet] = useResizeWindow(SCREEN_SIZE.tablet)
    const [limit] = useState(STEP)
    const [page, setPage] = useState(1)
    const [sortBy, setSortBy] = useState(SORT_COLLECTION_VALUE.RECENT)
    const [filterBy, setFilterBy] = useState({
        hasListPrice: false,
        hasOpenOffer: false,
        ownedByCreator: false,
        hasSold: false
    })
    const [total, setTotal] = useState(0)
    const [loading, setLoading] = useState(false)
    // const [collection, setCollection] = useState([])
    // const [autoFetch, setAutoFetch] = useState(isTablet)
    const [filterByOption, setFilterByOption] = useState({
        verification: false,
        nsfw: false
    })
    const [listSelectedChain, setListSelectedChain] = useState([])
    const [networkType, setNetworkType] = useState("");
    const [categoryId, setCategoryId] = useState(-1)
    const [openBlockchain, setOpenBlockchain] = useState(false)

    // const isLoadMore = useMemo(() => {
    //     return collection?.length > 0 && collection?.length < total
    // }, [collection?.length, total])

    // const anchorEl = useRef(null)
    const chainDropdown = useRef(null)
    const outSideClick = useCallback(() => {
        setOpenBlockchain(false)
    }, [])
    // const saleTypeDropdown = useRef(null)
    // const intersection = useIntersection(anchorEl, { root: null, rootMargin: '0px', threshold: 1 })
    // const inView = intersection && intersection.isIntersecting && intersection.intersectionRatio >= 1
    // const infinity = autoFetch && inView && isLoadMore;

    // const FILTER_BY = [
    //     { label: t('saleType.hasListPrice'), value: 'hasListPrice' },
    //     { label: t('saleType.hasOpenOffer'), value: 'hasOpenOffer' },
    //     { label: t('saleType.ownedByCreator'), value: 'ownedByCreator' },
    //     { label: t('saleType.hasSold'), value: 'hasSold' }
    // ];

    const categoriesConverted = useMemo(() => {
        return [
            { id: -1, value: -1, label: t('category.allCategories'), code: 'all' },
            ...[...categories].map(category => {
                return {
                    ...category,
                    icon: category.icon,
                    label: t(`category.${category.name}`),
                    value: category.id
                }
            })
        ]
    }, [categories, i18n.language])

    const handleChangeCategory = value => {
        setCategoryId(value);
        // if (value === -1) {
        //     history.push('/explore/all')
        //     return
        // }
        // history.push('/explore/' + categoriesConverted.find(c => c.id === value)?.code)
    }

    // const fetchTopCollection = useCallback(
    //     async dataProp => {
    //         setLoading(true)
    //         const verification = dataProp?.verification ? dataProp?.verification : filterByOption?.verification
    //         const nsfw = dataProp?.nsfw ? dataProp?.nsfw : filterByOption?.nsfw
    //         const dataApi = {
    //             limit,
    //             page: dataProp?.isReload ? 1 : page,
    //             userId: userId,
    //             hasListPrice: dataProp?.filterBy?.hasListPrice || filterBy.hasListPrice,
    //             hasOpenOffer: dataProp?.filterBy?.hasOpenOffer || filterBy.hasOpenOffer,
    //             ownedByCreator: dataProp?.filterBy?.ownedByCreator || filterBy.ownedByCreator,
    //             hasSold: dataProp?.filterBy?.hasSold || filterBy.hasSold,
    //             sortBy: dataProp?.sortBy || sortBy || SORT_COLLECTION_VALUE.RECENT,
    //             networkTypes:
    //                 (dataProp?.listSelectedChain?.length > 0 &&
    //                     dataProp?.listSelectedChain?.map(item => item.value).join(',')) ||
    //                 (listSelectedChain?.length > 0 && listSelectedChain?.map(item => item?.value).join(',')),
    //             isVerify: verification ? 1 : 0,
    //             notEmpty: true,
    //             onSaleStatus: true,
    //             nsfw: nsfw ? 1 : 0,
    //             owner: props.ownerAddress
    //         }

    //         if (!props.ownerAddress) {
    //             delete dataApi.owner
    //         }
    //         const [data, err] = await collectionService.getAllCollection(dataApi)

    //         setLoading(false)
    //         if (err) {
    //             message.error(t('error.message.somethingWentWrong'))
    //             return
    //         }

    //         if (dataProp?.isReload || page === 1) {
    //             setCollection(data?.items)
    //         } else {
    //             setCollection([...collection, ...data?.items])
    //         }
    //         setTotal(data?.meta?.totalItem)
    //     },
    //     [
    //         limit,
    //         page,
    //         filterBy.hasListPrice,
    //         filterBy.hasOpenOffer,
    //         filterBy.ownedByCreator,
    //         filterBy.hasSold,
    //         sortBy,
    //         listSelectedChain,
    //         filterByOption,
    //         collection,
    //         userId
    //     ]
    // )

    // useEffect(() => {
    //     if (infinity) {
    //         handeLoadMore()
    //     }
    // }, [infinity])

    // useEffect(() => {
    //     fetchTopCollection()
    // }, [page, limit, filterByOption, filterBy])

    // const handeLoadMore = useCallback(() => {
    //     if (loading) {
    //         return
    //     }
    //     const next = page * limit
    //     next < total && setPage(page + 1)
    // }, [loading, page, total, limit])

    const handleClickChainFilter = network => {
        if (listSelectedChain.includes(network)) {
            setListSelectedChain(listSelectedChain.filter(n => n !== network))
        } else {
            setListSelectedChain([...listSelectedChain, network])
        }
    }

    // const handleClickSaleTypeFilter = filter => {
    //     setFilterBy({ ...filterBy, [filter]: !filterBy[filter] })
    //     setPage(1)
    // }

    const handleClearChainFilter = () => {
        setListSelectedChain([])
    }

    const handleApplyChainFilter = () => {
        setNetworkType(listSelectedChain.map((e) => e.value).join(","));
        setOpenBlockchain(false)
        // fetchTopCollection({ listSelectedChain, isReload: true })
        setPage(1)
    }

    // const handleClearSaleTypeFilter = () => {
    //     setFilterBy({ hasListPrice: false, hasOpenOffer: false, ownedByCreator: false, hasSold: false })
    //     setPage(1)
    // }

    // const handleApplySaleTypeFilter = () => {
    //     // fetchTopCollection({ filterBy, isReload: true })
    //     setPage(1)
    // }

    // const dropDownFilterBy = useCallback(() => {
    //     return (
    //         <div
    //             className={`dropdown-collection dropdown-explorer ${theme === 'dark-theme' ? 'dropdown-explore-dark-antd' : ''
    //                 }`}
    //             ref={saleTypeDropdown}
    //         >
    //             <div className="collection-holder">
    //                 {FILTER_BY?.map((item, index) => {
    //                     return (
    //                         <div
    //                             className="collection-item-holder"
    //                             onClick={() => handleClickSaleTypeFilter(item.value)}
    //                             key={index}
    //                         >
    //                             <div className="collection-name">
    //                                 <div className="collection-name-text-holder">
    //                                     <span className="collection-name-text">{item.label}</span>
    //                                 </div>
    //                             </div>
    //                             <div>{filterBy[item.value] && <i className="ri-check-line"></i>}</div>
    //                         </div>
    //                     )
    //                 })}
    //             </div>
    //             <Divider />
    //             <div className="sale-type-filter-button">
    //                 <ButtonCustom size="small" color="gray" onClick={handleClearSaleTypeFilter}>
    //                     {t('common.clear')}
    //                 </ButtonCustom>
    //                 <ButtonCustom size="small" color="blue" onClick={handleApplySaleTypeFilter}>
    //                     {t('common.apply')}
    //                 </ButtonCustom>
    //             </div>
    //         </div>
    //     )
    // }, [filterBy, theme])

    const dropdownChain = useCallback(() => {
        return (
            <div
                className={`dropdown-collection dropdown-explorer ${theme === 'dark-theme' ? 'dropdown-explore-dark-antd' : ''
                    }`}
                ref={chainDropdown}
            >
                <OutSideClick onOutsideClick={outSideClick}>
                    <div className="collection-holder">
                        {NETWORK_OPTIONS?.map((network, index) => {
                            return (
                                <div
                                    className="collection-item-holder"
                                    onClick={() => handleClickChainFilter(network)}
                                    key={index}
                                >
                                    <div className="collection-name">
                                        <img src={network.icon} alt="chain logo" className="chain-logo" />
                                        <div className="collection-name-text-holder">
                                            <span className="collection-name-text">{network.label}</span>
                                        </div>
                                    </div>
                                    <div>{listSelectedChain?.includes(network) && <i className="ri-check-line"></i>}</div>
                                </div>
                            )
                        })}
                    </div>
                    <Divider />
                    <div className="sale-type-filter-button">
                        <ButtonCustom size="small" color="gray" onClick={handleClearChainFilter}>
                            {t('common.clear')}
                        </ButtonCustom>
                        <ButtonCustom size="small" color="blue" onClick={handleApplyChainFilter}>
                            {t('common.apply')}
                        </ButtonCustom>
                    </div>
                </OutSideClick>
            </div>

        )
    }, [listSelectedChain, theme])

    const handleClickSortFilter = sort => {
        setSortBy(sort.value)
        // fetchTopCollection({ sortBy: sort.value, isReload: true })
        setPage(1)
    }

    const handleFilterBy = useCallback(
        key => {
            setFilterByOption({ ...filterByOption, [key]: !filterByOption[key] })
            setPage(1)
        },
        [filterByOption]
    )

    const dropDownSortBy = useCallback(
        () => (
            <div
                className={`dropdown-collection dropdown-explorer dropdown-sortby ${theme === 'dark-theme' ? 'dropdown-explore-dark-antd' : ''
                    }`}
            >
                <div className="collection-holder">
                    <div className="filter-title">{t('common.sortAndFilter.sort.title')}</div>
                    <RadioGroupCustom
                        options={optionSortBy.map(item => ({ value: item.value, label: t(`common.sortAndFilter.sort.${item.value}`) }))}
                        value={sortBy}
                        onChange={handleClickSortFilter}
                    />
                    <div className="filter-title">{t('common.sortAndFilter.options.title')}</div>
                    {optionFilter?.map((item, index) => (
                        <div key={index} className="verification-holder">
                            {t(`common.sortAndFilter.options.${item.key}`)}
                            <CustomSwitch
                                onChange={() => handleFilterBy(item.key)}
                                value={filterByOption[item.key]}
                                disableLabel={true}
                                className="switch-filter"
                            />
                        </div>
                    ))}
                </div>
            </div>
        ),

        [sortBy, handleClickSortFilter, filterByOption, handleFilterBy, theme]
    );

    return (
        <div className="collection-container">
            <div className="option-filter collection-page-filter container">
                <div className="header-filter-by">
                    <SelectCustom
                        className="select left"
                        categoryIcon={true}
                        getPopupContainer={triggerNode => triggerNode.parentNode}
                        dropdownRender={dropdownChain}
                        placeholder={
                            <span className='select-placeholder'>
                                <img src={PolygonIcon} alt="polygon-icon" />
                                <span>{t('explore.blockchain')}</span>
                            </span>
                        }
                        onFocus={() => setOpenBlockchain(true)}
                        open={openBlockchain}
                    />
                    {/* <SelectCustom
                        className="select left"
                        categoryIcon={true}
                        getPopupContainer={triggerNode => triggerNode.parentNode}
                        dropdownRender={dropDownFilterBy}
                        placeholder={t('saleType.title')}
                    /> */}
                    <SelectCustom
                        value={categoryId}
                        onChange={handleChangeCategory}
                        options={categoriesConverted}
                        className="select left"
                        showIconSvg={true}
                        getPopupContainer={triggerNode => triggerNode.parentNode}
                    />
                </div>
                <div className="header-sort-by">
                    <SelectCustom
                        className="select left"
                        dropdownClassName="select__dropdown--filter"
                        categoryIcon={true}
                        getPopupContainer={triggerNode => triggerNode.parentNode}
                        dropdownRender={dropDownSortBy}
                        placeholder={
                            <span className='select-placeholder'>
                                <img src={FilterIcon} alt="filter-icon" />
                                <span>{t('common.sortAndFilter.title')}</span>
                            </span>
                        }
                    />
                </div>
            </div>
            {/* <div className="container">
                <div className="collection-container__content collection-container__profile-content">
                    <Row gutter={[{ xs: 6, sm: 6, md: 20, lg: 20, xl: 20 }, 20]}>
                        {collection.map((el, index) => (
                            <Col
                                xs={{ span: 24 }}
                                sm={{ span: 12 }}
                                md={{ span: 12 }}
                                lg={{ span: 8 }}
                                xl={{ span: 8 }}
                                xxl={{ span: 6 }}
                                key={`${el.id}-${index}`}
                            >
                                <CardCollectionTwo data={el} />
                            </Col>
                        ))}
                        <div ref={anchorEl}></div>
                    </Row>
                    <NoData loading={loading} data={collection} />
                    {isLoadMore && !autoFetch && (
                        <ButtonCustom
                            className="collection-list-btn br-12"
                            color="blue"
                            onClick={async () => {
                                if (autoFetch) return
                                await handeLoadMore()
                                setAutoFetch(true)
                            }}
                        >
                            {t('common.loadingMore')}
                        </ButtonCustom>
                    )}
                    {loading && <ReactLoading className="collection-list-btn" type="spinningBubbles" color="#002979" />}
                </div>
            </div> */}
            <CollectionListPart
                networkType={networkType}
                categoryId={categoryId}
                filterByOption={filterByOption}
            />
        </div>
    )
}

export default CollectionsProfile
