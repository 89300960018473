import React from 'react'
import { useEffect, useLayoutEffect } from 'react'
import { BrowserRouter as Router, Switch, withRouter, useLocation } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { NotificationContainer, NotificationManager } from 'react-notifications'

import ErrorBoundary from 'components/common/error-boundary/index'
import 'react-notifications/lib/notifications.css'
import AppContainer from '../AppContainer'
import {
    Error,
    Market,
    HomePage,
    NotFound,
    EditProfile,
    ConnectWallet,
    NFTDetail,
    Profile,
    CreateNft,
    Following,
    SearchPage,
    TermsOfUsePage,
    PrivacyPolicyPage,
    Activity,
    CollectionDetail,
    Explore,
    Collection,
    ComingSoon
} from '../pages'
import PrivateRoute from './PrivateRoute'
import PublicRoute from './PublicRoute'
import InstallMetameask from 'components/common/install-metamask'
import TermOfService from 'components/common/termOfService'
import ConnectWrongNetwork from 'components/common/wrongNetwork'
import RejectSignModal from 'components/common/reject-sign-modal'
import UserClosedModal from 'components/common/user-closed-modal'
import ErrorLockMetamask from 'components/common/error-lock-metamask-modal'
import HowItWorks from 'pages/how-it-works'
import ChooseCollectibleType from 'pages/choose-collectible-type'
import ListCollection from 'pages/list-collection'
import About from 'pages/about'
import HelpCenter from 'pages/help-center'
import SnailTokenPage from 'pages/snail-token'

const Routes = () => {
    const alert = useSelector(state => state.alert)
    const location = useLocation()

    useLayoutEffect(() => {
        window.scrollTo(0, 0)
    }, [location.pathname])

    useEffect(() => {
        const { type, message } = alert
        if (type && message) {
            NotificationManager[type](message)
        }
    }, [alert])

    return (
        <div>
            <Switch>
                <PrivateRoute path="/edit-profile" component={EditProfile} />
                <PrivateRoute path="/create/:type" component={CreateNft} />
                {/* <PrivateRoute path="/following" component={Following} /> */}
                <PrivateRoute path="/choose-type" component={ChooseCollectibleType} />
                <PublicRoute exact path="/" component={HomePage} />
                <PublicRoute path="/nft/:path" component={NFTDetail} />
                <PublicRoute path="/user/:address" component={Profile} />
                <PublicRoute path="/market" component={Market} />
                <PublicRoute path="/collections" component={Collection} />
                <PublicRoute path="/search/:key" component={SearchPage} />
                <PublicRoute path="/search/" component={SearchPage} />
                <PublicRoute path="/connect" component={ConnectWallet} />
                <PublicRoute path="/snail-token" component={SnailTokenPage} />
                <PublicRoute path="/terms-of-use" component={TermsOfUsePage} />
                <PublicRoute path="/about" component={About} />
                <PublicRoute path="/help-center" component={HelpCenter} />
                <PublicRoute path="/privacy-policy" component={PrivacyPolicyPage} />
                <PublicRoute path="/how-it-work" component={HowItWorks} />
                <PublicRoute path="/stats" component={ListCollection} />
                <PublicRoute path="/explore/:category" component={Explore} />
                <PublicRoute path="/activity" component={Activity} />
                <PublicRoute path="/error" component={Error} />
                <PublicRoute path="/coming-soon" component={ComingSoon} />
                <PublicRoute path="/collection/:shortUrl" component={CollectionDetail} />
                <PublicRoute path="/:customUrl" component={Profile} />
                <PublicRoute component={NotFound} />
            </Switch>
        </div>
    )
}

const RoutesHistory = withRouter(Routes)

const routing = function createRouting() {
    return (
        <>
            <InstallMetameask />
            <TermOfService />
            <RejectSignModal />
            <ErrorLockMetamask />
            <ConnectWrongNetwork />
            <UserClosedModal />
            <NotificationContainer />
            <Router>
                <AppContainer>
                    <ErrorBoundary>
                        <RoutesHistory />
                    </ErrorBoundary>
                </AppContainer>
            </Router>
        </>
    )
}

export default routing
