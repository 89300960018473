import React from 'react'
import { useTranslation } from 'react-i18next'
import { Modal } from 'antd'
import { useSelector, useDispatch } from 'react-redux'

import { ButtonCustom } from 'components/common'
import { getNFTDetail, getTradingHistory, getOfferList, changePageHistory, changePageOffer } from 'store/actions/nft'
import './style.scss'

const BidSuccessModal = ({ onCloseModal, isOpen }) => {
    const { t } = useTranslation()
    const dispatch = useDispatch()
    const { id, collection, tokenId } = useSelector(state => state.collectible?.data)
    const onClickOk = async () => {
        onCloseModal()
        await dispatch(getNFTDetail({ tokenId, collectionAddress: collection.id || collection.address }))
        dispatch(changePageOffer({value: 1}))
        dispatch(changePageHistory({value: 1}))
        await dispatch(getTradingHistory({ nftId: id }))
        await dispatch(getOfferList({ nftId: id }))
    }
    return (
        <Modal visible={isOpen} footer={null} closable={false} className={'custom-process-modal'}>
            <div className="bid-success-modal">
                <p>{t('nftDetail.bidPlaced')}</p>
                <p>{t('nftDetail.clickOk')}</p>
                <ButtonCustom fullWidth={true} color="blue" onClick={onClickOk}>
                    OK
                </ButtonCustom>
            </div>
        </Modal>
    )
}

export default BidSuccessModal
