import { Button, Form, Input, notification } from 'antd'
import DefaultLayout from 'components/layout/default-layout'
import { MAIN_TITLE } from 'constants/index'
import React, { useContext } from 'react'
import { Helmet } from 'react-helmet'
import { ThemeContext } from 'ThemeProvider'
import Avatar1 from '../../assets/images/about/avatar1.png'
import Avatar2 from '../../assets/images/about/avatar2.png'
import Avatar3 from '../../assets/images/about/avatar3.png'
import Avatar4 from '../../assets/images/about/avatar4.png'
import Avatar5 from '../../assets/images/about/avatar5.png'
import Avatar6 from '../../assets/images/about/avatar6.png'
import Avatar7 from '../../assets/images/about/avatar7.png'
import Avatar8 from '../../assets/images/about/avatar8.png'
import CanUsImage from '../../assets/images/about/can_us.png'
import Banner1 from '../../assets/images/about/banner1.png'
import Banner2 from '../../assets/images/about/banner2.png'
import Vision from '../../assets/images/about/eye.png'
import Mission from '../../assets/images/about/mission.png'
import Value from '../../assets/images/about/value.png'
import './styles.scss'
import userService from 'service/userService'
import * as message from 'utils/custom-message'
import { useTranslation } from 'react-i18next'

const NUMBER_AVATAR = [Avatar1, Avatar2, Avatar3, Avatar4, Avatar5, Avatar6, Avatar7, Avatar8]

const About = () => {
    const { theme } = useContext(ThemeContext)
    const { t } = useTranslation();
    const [form] = Form.useForm()

    const onFinish = async values => {
        try {
            await userService.postUserContact(values)
            message.success(t('error.message.receivedMessage'))
            form.resetFields()
        } catch (error) {
            message.error(t('error.message.somethingWentWrong'))
        }
    }
    return (
        <DefaultLayout>
            <Helmet>
                <meta charSet="utf-8" />
                <title>{`${MAIN_TITLE} - About`}</title>
                <meta name="description" content={`${MAIN_TITLE} - About`} />
            </Helmet>

            <div className="container">
                <div className="about-holder">
                    <div className="text-holder about-content-container">
                        <h1 className={`title-page ${theme === 'dark-theme' && 'text-dark-about'}`}>
                            {t('about.title')}
                        </h1>
                        <div className="video-holder">
                            <img src={Banner1} alt="logo-icon" />
                        </div>
                    </div>
                    <div className="box-our-story about-content-container">
                        <div className={`title-story ${theme === 'dark-theme' && 'text-dark-about'}`}>{t('about.ourStory.title')}</div>
                        <div className="our-story">
                            <div className="vertical-line"></div>
                            <div className={`content-story ${theme === 'dark-theme' && 'text-dark-about'}`}>
                                {t('about.ourStory.content')}
                            </div>
                        </div>
                    </div>
                    <div className="box-vision about-content-container">
                        <div className="box-content">
                            <img src={Vision} alt="error" />
                            <div className={`vision-title ${theme === 'dark-theme' && 'text-dark-about'}`}>{t('about.vision.title')}</div>
                            <p className={`${theme === 'dark-theme' && 'text-dark-about'}`}>
                                {t('about.vision.content')}
                            </p>
                        </div>
                        <div className="box-content">
                            <img src={Mission} alt="error" />
                            <div className={`vision-title ${theme === 'dark-theme' && 'text-dark-about'}`}>{t('about.mission.title')}</div>
                            <p className={`${theme === 'dark-theme' && 'text-dark-about'}`}>
                                {t('about.mission.content')}
                            </p>
                        </div>
                        <div className="box-content">
                            <img src={Value} alt="error" />
                            <div className={`vision-title ${theme === 'dark-theme' && 'text-dark-about'}`}>{t('about.value.title')}</div>
                            <p className={`${theme === 'dark-theme' && 'text-dark-about'}`}>
                                {t('about.value.content')}
                            </p>
                        </div>
                    </div>
                    <div className="box-our-team about-content-container">
                        <div className={`title-our-team ${theme === 'dark-theme' && 'text-dark-about'}`}>{t('about.ourTeam')}</div>
                        <div className="list-avatar">
                            {NUMBER_AVATAR.map((image, index) => (
                                <div key={index} className="box-avatar">
                                    <img src={image} alt="err" />
                                </div>
                            ))}
                        </div>
                    </div>
                    <div className="text-holder about-content-container">
                        <div className="video-holder">
                            <img src={Banner2} alt="logo-icon" />
                        </div>
                    </div>
                    <div className="box-support-us  about-content-container">
                        <div className="box-sp-form">
                            <div className={`box-sp-form-title ${theme === 'dark-theme' && 'text-dark-about'}`} dangerouslySetInnerHTML={{ __html: t('about.spMsg') }}>
                            </div>
                            <Form form={form} name="nest-messages" onFinish={onFinish}>
                                <Form.Item name="name" rules={[{ required: true }]}>
                                    <Input placeholder={t('about.form.fullName')} />
                                </Form.Item>
                                <Form.Item
                                    name="email"
                                    rules={[
                                        { required: true, type: 'email', message: t('error.invalidEmail') }
                                    ]}
                                >
                                    <Input placeholder={t('about.form.enterEmailAddress')} />
                                </Form.Item>
                                <Form.Item name="subject" rules={[{ required: true }]}>
                                    <Input placeholder={t('about.form.enterSubject')} />
                                </Form.Item>
                                <Form.Item name="message" rules={[{ required: true }]}>
                                    <Input.TextArea placeholder={t('about.form.contactFor')} />
                                </Form.Item>
                                <Form.Item>
                                    <Button
                                        style={{
                                            background:'#EE811A !important'
                                        }}
                                        type="primary"
                                        htmlType="submit"
                                    >
                                        {t('common.send')}
                                    </Button>
                                </Form.Item>
                            </Form>
                        </div>
                        <div>
                            <img src={CanUsImage} alt="can us" />
                        </div>
                    </div>
                </div>
            </div>
        </DefaultLayout>
    )
}

export default About
