import { Avatar, Button, Col, Divider, Dropdown, message, Row, Tooltip } from 'antd'
import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react'
import { Helmet } from 'react-helmet'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { Link } from 'react-router-dom'

import ArrowDown from 'assets/icons/arrow-down.svg'
import { ButtonCustom, SelectCustom } from 'components/common'
import CustomVerifyIcon from 'components/common/custom-verify-icon'
import DefaultLayout from 'components/layout/default-layout'
import { MAIN_TITLE, NETWORK_OPTIONS } from 'constants/index'
import collectionService from 'service/collectionService'
import { addCommaToNumber } from 'utils/currencyFormat'
import { convertToIncreaseOrDecreasePercent, stakingRoundNumber } from 'utils/formatNumber'
import { useOnClickOutside } from 'utils/hook'
import { ThemeContext } from '../../ThemeProvider'
import './style.scss'
import { convertImage } from 'utils/image'
import { useMultiLanguageConstant } from 'hooks/useMultiLanguageConstant'
import { setGlobalLoading, setCloseGlobalLoading } from 'store/system.slice'

const SORT_TYPES = {
    ASC: 'ASC',
    DESC: 'DESC'
}

const SORT_FIELDS = {
    COLLECTION: 'Name',
    VOLUME: 'volumnTraded',
    GAIN_DAY: 'volumnTradedChange',
    GAIN_WEEK: 'volumnTradedChange7Day',
    MIN_PRICE_USD: 'floorPrice',
    OWNERS: 'totalOwner',
    ASSETS: 'nftCount'
}

const ListCollection = () => {
    const { theme } = useContext(ThemeContext);
    const {timeRange} = useMultiLanguageConstant();
    const convertedTimeRange = useMemo(() => {
        return timeRange.slice(1)
    }, [timeRange]);

    const [isChainDropdown, setIsChainDropdown] = useState(false)
    const chainDropdown = useRef(null)
    const buttonChainRef = useRef(null)
    const [listSelectedChain, setListSelectedChain] = useState([])
    const [networkType, setNetworkType] = useState()
    const [sortParams, setSortParams] = useState({
        order: SORT_TYPES.DESC,
        key: SORT_FIELDS.VOLUME
    })

    const { t } = useTranslation()
    const dispatch = useDispatch()
    const [collections, setCollections] = useState([])
    const [day, setDay] = useState(convertedTimeRange[0].value)

    const handleChainClickOutsideDropdown = () => {
        setIsChainDropdown(false)
    }
    useOnClickOutside(chainDropdown, handleChainClickOutsideDropdown, buttonChainRef)

    const fetchTopCollection = useCallback(
        async metadata => {
            dispatch(setGlobalLoading())
            const [data, err] = await collectionService.getListRanking({
                day: metadata?.day || day,
                networkType: metadata?.networkType || networkType,
                sortType: sortParams.order,
                sortName: sortParams.key
                // categoryId: metadata && metadata?.categoryId,
            })

            if (data) {
                setCollections(data)
            }
            if (err) {
                message.error(t('error.message.somethingWentWrong'))
            }
            dispatch(setCloseGlobalLoading())
        },
        [dispatch, networkType, day, sortParams]
    )

    useEffect(() => {
        fetchTopCollection()
    }, [fetchTopCollection, sortParams])

    const handleChangeDay = value => {
        setDay(value)
    }

    const handleClickChainFilter = network => {
        if (listSelectedChain.includes(network)) {
            setListSelectedChain(listSelectedChain.filter(n => n !== network))
        } else {
            setListSelectedChain([...listSelectedChain, network])
        }
    }

    const handleApplyChainFilter = () => {
        setNetworkType(listSelectedChain.map(e => e.value).join(','))
        setIsChainDropdown(false)
    }

    const handleClearChainFilter = () => {
        setListSelectedChain([])
        setNetworkType([])
        fetchTopCollection({ networkType: [] })
    }

    const renderSortIcon = useCallback(type => {
        switch (type) {
            case SORT_TYPES.ASC: {
                return <i className="ri-arrow-up-s-fill" />
            }
            case SORT_TYPES.DESC: {
                return <i className="ri-arrow-down-s-fill" />
            }
            default:
                return null
        }
    }, [])

    const handleSortCollection = key => {
        setSortParams({
            key,
            order: sortParams.order === SORT_TYPES.ASC ? SORT_TYPES.DESC : SORT_TYPES.ASC
        })
    }

    const dropdownChain = useCallback(() => {
        return (
            <div
                className={`dropdown-collection dropdown-explorer ${
                    theme === 'dark-theme' ? 'dropdown-explore-dark-antd' : ''
                }`}
                ref={chainDropdown}
            >
                <div className="collection-holder">
                    {NETWORK_OPTIONS?.map((network, index) => {
                        return (
                            <div
                                className="collection-item-holder"
                                onClick={() => handleClickChainFilter(network)}
                                key={index}
                            >
                                <div className="collection-name">
                                    <img src={network.icon} alt="chain logo" className="chain-logo" />
                                    <div className="collection-name-text-holder">
                                        <span className="collection-name-text">{network.label}</span>
                                    </div>
                                </div>
                                <div>{listSelectedChain?.includes(network) && <i className="ri-check-line"></i>}</div>
                            </div>
                        )
                    })}
                </div>
                <Divider />
                <div className="sale-type-filter-button">
                    <ButtonCustom size="small" onClick={handleClearChainFilter}>
                        {t('common.clear')}
                    </ButtonCustom>
                    <ButtonCustom size="small" color="blue" onClick={handleApplyChainFilter}>
                        {t('common.apply')}
                    </ButtonCustom>
                </div>
            </div>
        )
    }, [listSelectedChain, theme])

    return (
        <DefaultLayout>
            <Helmet>
                <meta charSet="utf-8" />
                <title>{`${MAIN_TITLE} - List collection`}</title>
                <meta name="description" content={`${MAIN_TITLE} - List collection`} />
            </Helmet>
            <div className="list-collections">
                <div className="d-flex flex-row justify-content-center align-items-center collections-container">
                    <h1 className={`header-text ${theme === 'dark-theme' ? 'header-text-dark' : ''}`}>
                        {t('listCollection.header')}
                    </h1>
                    <div className={`filter ${theme === 'dark-theme' ? 'dark-filter' : ''}`}>
                        <Dropdown overlay={dropdownChain} visible={isChainDropdown}>
                            <Button
                                onClick={() => setIsChainDropdown(!isChainDropdown)}
                                innerRef={buttonChainRef}
                                className={`btn-filter   ${theme === 'dark-theme' ? 'btn-filter-custom-dark' : ''}`}
                            >
                                {t('explore.blockchain')}
                                <img src={ArrowDown} alt="ArrowDown" />
                            </Button>
                        </Dropdown>
                        <SelectCustom
                            value={day}
                            options={convertedTimeRange}
                            className={'select'}
                            onChange={handleChangeDay}
                            classNames="select-home"
                        />
                    </div>
                </div>
                <div className="container">
                    <div className="list-collections__table">
                        <div className="list-collections__table__td list-collections__table__td-header">
                            <Row align="center" justify="space-between">
                                <Col span={5} md={5}>
                                    <span
                                        className="cursor-pointer"
                                        onClick={() => handleSortCollection(SORT_FIELDS.COLLECTION)}
                                    >
                                        {t('listCollection.collection')}
                                    </span>{' '}
                                    {sortParams.key === SORT_FIELDS.COLLECTION && (
                                        <span
                                            className="cursor-pointer"
                                            onClick={() => handleSortCollection(SORT_FIELDS.COLLECTION)}
                                        >
                                            {renderSortIcon(sortParams.order)}
                                        </span>
                                    )}
                                </Col>
                                <Col span={3} md={3} className="number">
                                    <span
                                        className="cursor-pointer"
                                        onClick={() => handleSortCollection(SORT_FIELDS.VOLUME)}
                                    >
                                        {t('listCollection.volume')}
                                    </span>
                                    {sortParams.key === SORT_FIELDS.VOLUME && (
                                        <span
                                            className="cursor-pointer"
                                            onClick={() => handleSortCollection(SORT_FIELDS.VOLUME)}
                                        >
                                            {renderSortIcon(sortParams.order)}
                                        </span>
                                    )}
                                </Col>
                                <Col span={2} md={2} className="number">
                                    <span
                                        className="cursor-pointer"
                                        onClick={() => handleSortCollection(SORT_FIELDS.GAIN_DAY)}
                                    >
                                        {t('listCollection.gainDay')}
                                    </span>
                                    {sortParams.key === SORT_FIELDS.GAIN_DAY && (
                                        <span
                                            className="cursor-pointer"
                                            onClick={() => handleSortCollection(SORT_FIELDS.GAIN_DAY)}
                                        >
                                            {renderSortIcon(sortParams.order)}
                                        </span>
                                    )}
                                </Col>
                                <Col span={2} md={2} className="number">
                                    <span
                                        className="cursor-pointer"
                                        onClick={() => handleSortCollection(SORT_FIELDS.GAIN_WEEK)}
                                    >
                                        {t('listCollection.gainWeek')}
                                    </span>
                                    {sortParams.key === SORT_FIELDS.GAIN_WEEK && (
                                        <span
                                            className="cursor-pointer"
                                            onClick={() => handleSortCollection(SORT_FIELDS.GAIN_WEEK)}
                                        >
                                            {renderSortIcon(sortParams.order)}
                                        </span>
                                    )}
                                </Col>
                                <Col span={2.5} md={2.5} className="number">
                                    <span
                                        className="cursor-pointer"
                                        onClick={() => handleSortCollection(SORT_FIELDS.MIN_PRICE_USD)}
                                    >
                                        {t('listCollection.floorPrice')}
                                    </span>
                                    {sortParams.key === SORT_FIELDS.MIN_PRICE_USD && (
                                        <span
                                            className="cursor-pointer"
                                            onClick={() => handleSortCollection(SORT_FIELDS.MIN_PRICE_USD)}
                                        >
                                            {renderSortIcon(sortParams.order)}
                                        </span>
                                    )}
                                </Col>
                                <Col span={1.5} md={1.5} className="number">
                                    <span
                                        className="cursor-pointer"
                                        onClick={() => handleSortCollection(SORT_FIELDS.OWNERS)}
                                    >
                                        {t('listCollection.owners')}
                                    </span>
                                    {sortParams.key === SORT_FIELDS.OWNERS && (
                                        <span
                                            className="cursor-pointer"
                                            onClick={() => handleSortCollection(SORT_FIELDS.OWNERS)}
                                        >
                                            {renderSortIcon(sortParams.order)}
                                        </span>
                                    )}
                                </Col>
                                <Col span={2} md={2} className="number">
                                    <span
                                        className="cursor-pointer"
                                        onClick={() => handleSortCollection(SORT_FIELDS.ASSETS)}
                                    >
                                        {t('listCollection.assets')}
                                    </span>
                                    {sortParams.key === SORT_FIELDS.ASSETS && (
                                        <span
                                            className="cursor-pointer"
                                            onClick={() => handleSortCollection(SORT_FIELDS.ASSETS)}
                                        >
                                            {renderSortIcon(sortParams.order)}
                                        </span>
                                    )}
                                </Col>
                            </Row>
                        </div>
                        {collections.map((item, index) => {
                            return (
                                <div className="list-collections__table__td" key={index}>
                                    <Row align="center" justify="space-between">
                                        <Col span={5} md={5}>
                                            <div className="d-flex flex-column align-items-center">
                                                <Link to={`collection/${item?.shortUrl || item?.address}`}>
                                                    <Avatar size={72} src={convertImage(item?.thumbnailUrl)} />
                                                    {item?.verification ? (
                                                        <CustomVerifyIcon className="verify-icon" />
                                                    ) : null}
                                                </Link>
                                                <Tooltip title={item?.name}>
                                                    <Link
                                                        to={`collection/${item?.shortUrl || item?.address}`}
                                                        className="name-holder collection-name"
                                                    >
                                                        <span className="collection-name">{item?.name}</span>
                                                    </Link>
                                                </Tooltip>
                                            </div>
                                        </Col>
                                        <Col span={3} md={3} className="align-items-center name-holder number">
                                            $
                                            {Number(item?.volumnTraded) > 0 && Number(item?.volumnTraded) < 1
                                                ? item?.volumnTraded
                                                : item?.volumnTraded
                                                ? stakingRoundNumber(item?.volumnTraded)
                                                : 0}
                                        </Col>
                                        <Col
                                            span={2}
                                            md={2}
                                            className={`align-items-center name-holder number ${
                                                item?.volumnTradedChange > 0 ? 'increase' : 'decrease'
                                            }`}
                                        >
                                            {convertToIncreaseOrDecreasePercent(
                                                item?.volumnTradedChange,
                                                item?.volumnTraded
                                            )}
                                        </Col>
                                        <Col
                                            span={2}
                                            md={2}
                                            className={`align-items-center name-holder number ${
                                                item?.volumnTradedChange7Day > 0 ? 'increase' : 'decrease'
                                            }`}
                                        >
                                            {convertToIncreaseOrDecreasePercent(
                                                item?.volumnTradedChange7Day,
                                                item?.volumnTraded
                                            )}
                                        </Col>
                                        <Col span={2.5} md={2.5} className="align-items-center name-holder number">
                                            $
                                            {Number(item?.floorPrice) > 0 && Number(item?.floorPrice) < 1
                                                ? item?.floorPrice
                                                : item?.floorPrice
                                                ? stakingRoundNumber(item?.floorPrice)
                                                : 0}
                                        </Col>
                                        <Col span={1.5} md={1.5} className="align-items-center name-holder number">
                                            {`${addCommaToNumber(item?.totalOwner)}`}
                                        </Col>
                                        <Col span={2} md={2} className="align-items-center name-holder number">
                                            {`${addCommaToNumber(item?.nftCount)}`}
                                        </Col>
                                    </Row>
                                </div>
                            )
                        })}
                    </div>
                    {/* {collections?.length && (
                        <PaginationCustom
                            pageSize={LIMIT}
                            total={total}
                            value={pageOffer}
                            onChangePage={onChangePage}
                        />
                    )} */}
                </div>
            </div>
        </DefaultLayout>
    )
}

export default ListCollection
