import { Avatar, Col, Row, Tooltip } from 'antd';
import { NoData, TabsCustom } from 'components/common';
import CustomVerifyIcon from 'components/common/custom-verify-icon';
import { useMultiLanguageConstant } from 'hooks/useMultiLanguageConstant';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import ReactLoading from 'react-loading'

import collectionService from 'service/collectionService';
import { abbreviateNumber, addCommaToNumber } from 'utils/currencyFormat';
import * as message from 'utils/custom-message';
import { convertImage } from 'utils/image';
import './styles.scss';
import { useWindowSize } from 'react-use';

const { TabPane } = TabsCustom;

const TABS_RANK = {
  TRENDING: 'TRENDING',
  TOP: 'TOP',
};

const CollectionListPart = ({
  networkType,
  categoryId,
  filterByOption,
  limit,
}) => {
  const { t } = useTranslation();
  const { periodSelections } = useMultiLanguageConstant();
  const { width } = useWindowSize();

  const [activeRank, setActiveRank] = useState(TABS_RANK.TRENDING);
  const [activePeriod, setActivePeriod] = useState(periodSelections[0].value);
  const [collections, setCollections] = useState([]);
  const [loading, setLoading] = useState(false);

  const fetchTrendingCollections = async () => {
    setLoading(true);
    const [data, err] = await collectionService.getTrendingCollections({
      type: activePeriod,
      networkType,
      isVerified: filterByOption?.verification ? '1' : '',
      isNSFW: filterByOption?.nsfw ? '1' : '',
      ...(categoryId > 0 && { categoryId }),
      limit,
    });

    setLoading(false);
    if (data) {
      setCollections(data);
    }
    if (err) {
      message.error(t('error.message.somethingWentWrong'));
    }
  };

  const fetchTopCollections = async () => {
    setLoading(true);
    const [data, err] = await collectionService.getListRanking({
      type: activePeriod,
      networkType,
      isVerified: filterByOption?.verification ? '1' : '',
      isNSFW: filterByOption?.nsfw ? '1' : '',
      ...(categoryId > 0 && { categoryId }),
      limit,
    });

    setLoading(false);
    if (data) {
      setCollections(data);
    }
    if (err) {
      message.error(t('error.message.somethingWentWrong'));
    }
  };

  const fetchData = () => {
    if (activeRank === TABS_RANK.TRENDING) {
      fetchTrendingCollections();
    } else {
      fetchTopCollections();
    }
  };

  useEffect(() => {
    fetchData();
  }, [activeRank, activePeriod, networkType, categoryId, filterByOption]);

  const _renderChange = (price, historyPrice) => {
    if (!historyPrice) {
      return <span className="change--up">▲ 0%</span>;
    }

    const percent = Number((price - historyPrice) / historyPrice) * 100;
    if (percent >= 0) {
      return <span className="change--up">▲ {abbreviateNumber(percent)}%</span>;
    }

    if (percent < 0) {
      return (
        <span className="change--down">▼ {abbreviateNumber(percent)}%</span>
      );
    }

    return <span className="change--up">▲ 0%</span>;
  };

  const convertDecimal = (value) => {
    return Number(value.toString().match(/^-?\d+(?:\.\d{0,6})?/)).toFixed(6);
  };

  return (
    <div className="collection-list container">
      <div className="collection-list__tabs">
        <TabsCustom
          defaultActiveKey={activeRank}
          onChange={(key) => setActiveRank(key)}
        >
          <TabPane tab={t("common.trending")} key={TABS_RANK.TRENDING} />
          <TabPane tab={t("common.top")} key={TABS_RANK.TOP} />
        </TabsCustom>
        <TabsCustom
          defaultActiveKey={activePeriod}
          onChange={(key) => setActivePeriod(key)}
        >
          {periodSelections.map((period) => (
            <TabPane tab={period.label} key={period.value} />
          ))}
        </TabsCustom>
      </div>
      {width > 992 ? (
        <div className="collection-list__content">
          <div className="collection-list__content__header">
            <Row align="center" justify="space-between">
              <Col span={5} md={5}>
                <span>{t('listCollection.collection')}</span>{' '}
              </Col>
              <Col span={3} md={3} className="number">
                <span>{t('common.floorPrice')}</span>
              </Col>
              <Col span={2} md={2} className="number">
                <span>{t('listCollection.change')}</span>
              </Col>
              <Col span={3} md={3} className="number">
                <span>{t('listCollection.totalVol')}</span>
              </Col>
              <Col span={3} md={3} className="number">
                <span>{t('nftDetail.topOffer')}</span>
              </Col>
              <Col span={2} md={2} className="number">
                <span>{t('activity.sales')}</span>
              </Col>
            </Row>
          </div>
          <div className="collection-list__content__body">
            {loading && <ReactLoading className="loading-data" type="spinningBubbles" color="#002979" />}
            {!loading && collections.map((item) => {
              return (
                <Row align="center" justify="space-between" key={item?.id}>
                  <Col span={5} md={5}>
                    <div className="owner-avatar">
                      <Link
                        className="avatar-holder"
                        to={`collection/${item?.shortUrl || item?.address}`}
                      >
                        <Avatar
                          size={48}
                          src={convertImage(item?.thumbnailUrl)}
                        />
                        {item?.verification ? (
                          <CustomVerifyIcon className="verify-icon" />
                        ) : null}
                      </Link>
                      <Tooltip title={item?.name}>
                        <Link
                          to={`collection/${item?.shortUrl || item?.address}`}
                          className="name-holder collection-name"
                        >
                          <span className="collection-name">{item?.name}</span>
                        </Link>
                      </Tooltip>
                    </div>
                  </Col>
                  <Col
                    span={3}
                    md={3}
                    className="align-items-center name-holder number"
                    style={{ textAlign: 'right' }}
                  >
                    {`$${convertDecimal(item?.floorPrice)}`}
                  </Col>
                  <Col
                    span={2}
                    md={2}
                    className={`align-items-center name-holder number ${
                      item?.volumnTradedChange > 0 ? 'increase' : 'decrease'
                    }`}
                  >
                    {_renderChange(item?.floorPrice, item?.historyFloorPrice)}
                  </Col>
                  <Col
                    span={3}
                    md={3}
                    className="align-items-center name-holder number"
                    style={{ textAlign: 'right' }}
                  >
                    {`$${convertDecimal(item?.volumnTraded)}`}
                  </Col>
                  <Col
                    span={3}
                    md={3}
                    className={`align-items-center name-holder number`}
                    style={{ textAlign: 'right' }}
                  >
                    {`$${convertDecimal(item?.topOffer)}`}
                  </Col>
                  <Col
                    span={2}
                    md={2}
                    className="align-items-center name-holder number"
                  >
                    {`${addCommaToNumber(item?.nftSales)}`}
                  </Col>
                </Row>
              );
            })}
            <NoData loading={loading} data={collections} />
          </div>
        </div>
      ) : (
        <div className="collection-list__content-mobile">
          {collections.map((item, index) => (
            <Col key={index}>
              <Row align="middle" justify="space-between">
                <Col>
                  <span>{t('listCollection.collection')}</span>{' '}
                </Col>
                <Col>
                  <div className="owner-avatar">
                    <Link
                      className="avatar-holder"
                      to={`collection/${item?.shortUrl || item?.address}`}
                    >
                      <Avatar
                        size={48}
                        src={convertImage(item?.thumbnailUrl)}
                      />
                      {item?.verification ? (
                        <CustomVerifyIcon className="verify-icon" />
                      ) : null}
                    </Link>
                    <Tooltip title={item?.name}>
                      <Link
                        to={`collection/${item?.shortUrl || item?.address}`}
                        className="name-holder collection-name"
                      >
                        <span className="collection-name">{item?.name}</span>
                      </Link>
                    </Tooltip>
                  </div>
                </Col>
              </Row>
              <Row align="middle" justify="space-between">
                <Col className="number">
                  <span>{t('common.floorPrice')}</span>
                </Col>
                <Col
                  className="align-items-center name-holder number"
                  style={{ textAlign: 'right' }}
                >
                  {`$${convertDecimal(item?.floorPrice)}`}
                </Col>
              </Row>
              <Row align="middle" justify="space-between">
                <Col className="number">
                  <span>{t('listCollection.change')}</span>
                </Col>
                <Col
                  className={`align-items-center name-holder number ${
                    item?.volumnTradedChange > 0 ? 'increase' : 'decrease'
                  }`}
                >
                  {_renderChange(item?.floorPrice, item?.historyFloorPrice)}
                </Col>
              </Row>
              <Row align="middle" justify="space-between">
                <Col className="number">
                  <span>{t('listCollection.totalVol')}</span>
                </Col>
                <Col
                  className="align-items-center name-holder number"
                  style={{ textAlign: 'right' }}
                >
                  {`$${convertDecimal(item?.volumnTraded)}`}
                </Col>
              </Row>
              <Row align="middle" justify="space-between">
                <Col span={3} md={3} className="number">
                  <span>{t('nftDetail.topOffer')}</span>
                </Col>
                <Col
                  className={`align-items-center name-holder number`}
                  style={{ textAlign: 'right' }}
                >
                  {`$${convertDecimal(item?.topOffer)}`}
                </Col>
              </Row>
              <Row align="middle" justify="space-between">
                <Col span={2} md={2} className="number">
                  <span>{t('activity.sales')}</span>
                </Col>
                <Col className="align-items-center name-holder number">
                  {`${addCommaToNumber(item?.nftSales)}`}
                </Col>
              </Row>
            </Col>
          ))}
        </div>
      )}
    </div>
  );
};

export default CollectionListPart;
