import { ethers, Interface, keccak256, Signature } from 'ethers'
import { BigNumber } from 'bignumber.js'
import { getAccount, switchChain } from '@wagmi/core'
import { Buffer } from 'buffer';
import { wagmiConfig } from './wagmi'

import {
    signMessage,
    estimateGas,
    getReadContract,
    getWriteContract,
    getErc20Contract,
    getExchangeContract,
    getRegistryContract,
    getRoyaltyContract,
} from 'blockchain/instance'
import { convertPriceToBigDecimals, getEtherSigner } from 'blockchain/ether'
import { DECIMAL_TOKENS, LOGIN_SIGN_MESSAGE, NFT_TYPE, MAX_INT } from 'constants/index'
import { CONTRACT_ADDRESS, NETWORKS, NETWORK_ID_TYPE } from 'constants/envs'
import { SERVICE_FEE_ADDRESS, SERVICE_FEE_PERCENT } from 'constants/local-storage-keys'
import MultipleCollectableABI from 'blockchain/abi/multipleCollectable.json'
import SingleCollectableABI from 'blockchain/abi/singleCollectable.json'

const STATIC_TARGET_ADDRESS = '0x0000000000000000000000000000000000000000'
const NULL_ADDRESS = '0x0000000000000000000000000000000000000000'
const HOW_TO_CALL = 0
const FEE_METHOD = 1
const STATIC_EXTRA_DATA = '0x00'
const EXTRA = '0'

export const WALLETCONNECT = "walletConnect";
export const METAMASK = "io.metamask";

const singleCollectableABIIFace = new Interface(SingleCollectableABI)
const multipleCollectableABIIFace = new Interface(MultipleCollectableABI)

export const getConnectorById = (connectors, id) => {
    if (!connectors || !connectors.length) {
        return null;
    }

    return connectors.find(c => c.id === id);
};

export const getWalletConnect = (connectors) => getConnectorById(connectors, WALLETCONNECT);

export const getMetaMask = (connectors) => getConnectorById(connectors, METAMASK);

export const signWallet = () => {
    return signMessage(LOGIN_SIGN_MESSAGE);
}

export const changeNetwork = async networkType => {
    if (!window.ethereum) throw new Error('No crypto wallet found')
    const NETWORKS_TEMP = JSON.parse(JSON.stringify(NETWORKS))
    
    const chainId = NETWORKS_TEMP[networkType].id;
    delete NETWORKS_TEMP[networkType].id;
    delete NETWORKS_TEMP[networkType].chainId;
    
    try {
        await switchChain(wagmiConfig, {
            chainId: chainId,
            addEthereumChainParameter: {...NETWORKS_TEMP[networkType]},
        });
    } catch (e) {
        if (e.code === -32002) {
            throw Error('A network switch request is already pending. Please wait.');
        }

        throw Error(`Something went wrong. Switch to ${networkType} network ERROR!`);
    }
}

export const checkUserHasProxy = async (addressContract, userAddress, chainId, networkType) => {
    try {
        if (NETWORK_ID_TYPE[chainId] !== networkType) await changeNetwork(networkType);

        const contract = await getRegistryContract(addressContract);

        const res = await contract.proxies(userAddress)
        if (res !== NULL_ADDRESS) {
            return [res, null]
        }
        const proxy = await contract.registerProxy()

        const result = await proxy.wait(1)

        return [result, null]
    } catch (error) {
        return [null, error]
    }
}

export const isUserApprovedERC20 = async (proxy, contractAddress, userAddress, chainId, networkType) => {
    try {
        if (NETWORK_ID_TYPE[chainId] !== networkType) await changeNetwork(networkType)
        const contract = await getErc20Contract(contractAddress)
        const allowance = await contract.allowance(userAddress, proxy)

        if (allowance.toString() !== '0') {
            return true
        }
        return false
    } catch (error) {
        console.error('isUserApprovedERC20', error)
        return false
    }
}

export const handleUserApproveERC20 = async (proxy, contractAddress, chainId, networkType) => {
    try {
        if (NETWORK_ID_TYPE[chainId] !== networkType) await changeNetwork(networkType)

        const contract = await getErc20Contract(contractAddress);
        const approve = await contract.approve(proxy, MAX_INT)
        const transaction = await approve.wait(1)

        return [transaction, null]
    } catch (error) {
        return [null, error]
    }
}

export const handleUpdateRoyaltyNFT = async (
    { tokenId, collectionAddress, fee, userAddress },
    contractAddress,
    chainId,
    networkType
) => {
    fee = (fee * 100).toString()
    try {
        if (NETWORK_ID_TYPE[chainId] !== networkType) await changeNetwork(networkType)

        const contract = await getRoyaltyContract(contractAddress);
        const tx = await contract.updateRoyaltyInfoForNFTCollection(
            collectionAddress,
            tokenId,
            userAddress,
            userAddress,
            fee
        )

        const res = await tx.wait(1)
        return [res, null]
    } catch (error) {
        console.error('error', error)
        return [null, error]
    }
}

export const checkApprovedCollection = async (
    { userAddress, registryContractAddress, collectionAddress },
    chainId,
    networkType
) => {
    try {
        if (NETWORK_ID_TYPE[chainId] !== networkType) await changeNetwork(networkType)

        const contract = await getRegistryContract(registryContractAddress);
        const proxy = await contract.proxies(userAddress)

        const collectionABI = [
            {
                inputs: [
                    {
                        internalType: 'address',
                        name: 'owner',
                        type: 'address'
                    },
                    {
                        internalType: 'address',
                        name: 'operator',
                        type: 'address'
                    }
                ],
                name: 'isApprovedForAll',
                outputs: [
                    {
                        internalType: 'bool',
                        name: '',
                        type: 'bool'
                    }
                ],
                stateMutability: 'view',
                type: 'function'
            },
            {
                inputs: [
                    {
                        internalType: 'address',
                        name: 'operator',
                        type: 'address'
                    },
                    {
                        internalType: 'bool',
                        name: 'approved',
                        type: 'bool'
                    }
                ],
                name: 'setApprovalForAll',
                outputs: [],
                stateMutability: 'nonpayable',
                type: 'function'
            }
        ];

        const isApproved = await getReadContract(collectionABI, collectionAddress, {
            functionName: 'isApprovedForAll',
            args: [userAddress, proxy]
        });
        if (isApproved) {
            return [true, null]
        }
        await getWriteContract(collectionABI, collectionAddress, {
            functionName: 'setApprovalForAll',
            args: [proxy, true],
        });
        return [true, null]
    } catch (error) {
        return [null, error]
    }
}

export const getERC20AmountBalance = async (contractAddress, walletAddress, chainId, networkType) => {
    try {
        if (NETWORK_ID_TYPE[chainId] !== networkType) await changeNetwork(networkType)

        const contract = await getErc20Contract(contractAddress)
        const response = await contract.balanceOf(walletAddress)
        return [response, null]
    } catch (error) {
        console.error('getERC20AmountBalance', error)
        return [null, error]
    }
}

export const genTokenIdForMainStore = (address, supply) => {
    address = address.toLowerCase()
    supply = supply || 1

    const id = new Date().getTime()
    const hex = address + id.toString(16).padStart(14, '0') + supply.toString(16).padStart(10, '0')
    const number = new BigNumber(hex.toLowerCase())
    return String(number.toFixed())
}

export const signPutDataOnSale = async (initData, chainId) => {
    const RECIPIENT_FEE_ADDRESS = localStorage.getItem(SERVICE_FEE_ADDRESS)
    const MARKET_RAW_FEE = Number(localStorage.getItem(SERVICE_FEE_PERCENT))

    const { collectionAddress, price, tokenType, quantity, networkType } = initData

    try {
        if (NETWORK_ID_TYPE[chainId] !== networkType) await changeNetwork(networkType)

        let callDataEncoded = ''
        let replacementPattern = ''

        const signer = await getEtherSigner({ chainId });
        const makerAddress = getAccount(wagmiConfig).address.toLowerCase()

        const paymentToken = {
            tokenAddress: CONTRACT_ADDRESS[networkType][tokenType] || NULL_ADDRESS,
            numberOfDecimals: DECIMAL_TOKENS[networkType][tokenType] || 18
        }

        const listingTimeValue = (Math.floor(Date.now() / 1000) - 120).toString()
        const saltValue = 
            keccak256(Buffer.from(Math.floor(Date.now() / 1000).toString(16), 'hex').subarray(2))
            .toString()

        if (initData.isExternalCollection) {
            if (initData.nftType === NFT_TYPE.ERC721) {
                callDataEncoded = singleCollectableABIIFace.encodeFunctionData('safeTransferFrom', [
                    makerAddress,
                    NULL_ADDRESS,
                    initData.tokenId
                ])
                replacementPattern =
                    '0x000000000000000000000000000000000000000000000000000000000000000000000000ffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffff0000000000000000000000000000000000000000000000000000000000000000'
            } else {
                callDataEncoded = multipleCollectableABIIFace.encodeFunctionData('safeTransferFrom', [
                    makerAddress,
                    NULL_ADDRESS,
                    initData.tokenId,
                    quantity,
                    '0x00'
                ])
                replacementPattern =
                    '0x000000000000000000000000000000000000000000000000000000000000000000000000ffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffff0000000000000000000000000000000000000000000000000000000000000000ffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffff000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000'
            }
        } else {
            if (initData.nftType === NFT_TYPE.ERC721) {
                callDataEncoded = singleCollectableABIIFace.encodeFunctionData('mintAndTransfer', [
                    makerAddress,
                    NULL_ADDRESS,
                    initData.tokenId,
                    initData.tokenId
                ])
                replacementPattern =
                    '0x000000000000000000000000000000000000000000000000000000000000000000000000ffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffff0000000000000000000000000000000000000000000000000000000000000000ffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffff0000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000'
            } else {
                callDataEncoded = multipleCollectableABIIFace.encodeFunctionData('transfer', [
                    makerAddress,
                    NULL_ADDRESS,
                    initData.tokenId,
                    quantity,
                    '0x00',
                    initData.tokenId
                ])
                replacementPattern =
                    '0x000000000000000000000000000000000000000000000000000000000000000000000000ffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffff0000000000000000000000000000000000000000000000000000000000000000ffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffff00000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000'
            }
        }

        const contract = await getExchangeContract(CONTRACT_ADDRESS[networkType].exchange);
        
        const makerHashOrder = await contract.hashOrder_(
            [
                CONTRACT_ADDRESS[networkType].exchange, // sc address
                makerAddress, // maker address
                NULL_ADDRESS, // taker address
                RECIPIENT_FEE_ADDRESS, // protocolFee
                collectionAddress, //collection address
                STATIC_TARGET_ADDRESS,
                paymentToken.tokenAddress // payment token address
            ],
            [
                MARKET_RAW_FEE * 100,
                MARKET_RAW_FEE * 100,
                convertPriceToBigDecimals(price, paymentToken.numberOfDecimals), // base price - token decimals
                EXTRA, // extra
                listingTimeValue, // listingTime
                '0', // expirationTime for auction
                saltValue // salt
            ],
            FEE_METHOD, // feeMethod default: 1
            1, // side - 1: sell, 0: buy
            0, // saleKind - 0: fixed price, 1: auction
            HOW_TO_CALL, // howToCall default: 0
            callDataEncoded,
            replacementPattern,
            STATIC_EXTRA_DATA // staticExtradata, default: 0x00
        )

        const hashOrderMessage = Buffer.from(makerHashOrder.slice(2), 'hex');
        const signedMessage = await signer.signMessage(hashOrderMessage);

        const decodedSignature = Signature.from(signedMessage);

        const dataPutOnSale = {
            basePrice: convertPriceToBigDecimals(price, paymentToken.numberOfDecimals), // base price - token decimals
            calldata: callDataEncoded,
            replacementPattern,
            exchangeAddress: CONTRACT_ADDRESS[networkType].exchange,
            expirationTime: '0', // fixed-price: 0
            extra: EXTRA, // = 0 default
            feeMethod: FEE_METHOD, // = 1 default
            feeRecipient: RECIPIENT_FEE_ADDRESS, // set in env address
            hash: makerHashOrder, // makerHashOrder
            howToCall: HOW_TO_CALL, // = 0 default

            maker: makerAddress, // user who create nft
            makerRelayerFee: (MARKET_RAW_FEE * 100).toString(),

            taker: NULL_ADDRESS,
            takerRelayerFee: (MARKET_RAW_FEE * 100).toString(),

            saleKind: 0, // saleKind - 0: fixed price, 1: auction,
            listingTime: listingTimeValue,
            salt: saltValue,

            side: 1, // side:  sell = 1, buy = 0,
            staticExtraData: STATIC_EXTRA_DATA, // default
            staticTarget: '0x0000000000000000000000000000000000000000', // default

            tokenId: initData.tokenId,
            collectionAddress,

            networkType,
            collectionType: initData.nftType,

            erc20Address: paymentToken.tokenAddress,
            decimal: paymentToken.numberOfDecimals,

            quantity,
            isExternalCollection: initData.isExternalCollection,

            r: decodedSignature?.r,
            v: decodedSignature?.v,
            s: decodedSignature?.s
        }
        return [dataPutOnSale, null]
    } catch (error) {
        console.error('signPutDataOnSale', error)
        return [null, error]
    }
}

export const handleCancelListingOrder = async (listingData, chainId) => {
    try {
        if (NETWORK_ID_TYPE[chainId] !== listingData.networkType) await changeNetwork(listingData.networkType)

        const contract = await getExchangeContract(listingData.exchangeAddress);

        const transaction = await contract.cancelOrder_(
            [
                listingData.exchangeAddress,
                listingData.maker, // maker address
                NULL_ADDRESS, // taker address
                listingData.feeRecipient, // protocolFee
                listingData.collectionAddress, //collection address
                STATIC_TARGET_ADDRESS,
                listingData.erc20Address // payment token address
            ],
            [
                listingData.makerRelayerFee,
                listingData.takerRelayerFee,
                listingData.basePrice, // base price - token decimals
                EXTRA, // extra
                listingData.listingTime, // listingTime
                0, // expirationTime for auction
                listingData.salt // salt
            ],
            FEE_METHOD, // feeMethod default: 1
            1, // side - 1: sell, 0: buy
            0, // saleKind - 0: fixed price, 1: auction
            HOW_TO_CALL, // howToCall default: 0
            listingData.calldata,
            listingData.replacementPattern,
            STATIC_EXTRA_DATA,
            listingData.v,
            listingData.r,
            listingData.s
        )

        const result = await transaction.wait(1)
        return [result, null]
    } catch (error) {
        console.error('handleCancelListingOrder', error)
        return [null, error]
    }
}

export const handleOrderCanMatch = async (dataListingOnSale, chainId) => {
    try {
        if (NETWORK_ID_TYPE[chainId] !== dataListingOnSale.networkType)
            await changeNetwork(dataListingOnSale.networkType)

        let callDataEncodedBuyer = ''
        let replacementPatternBuyer = ''

        const signerAddressForBuyer = getAccount(wagmiConfig).address.toLowerCase()

        if (dataListingOnSale.isExternalCollection) {
            if (dataListingOnSale.collectionType === NFT_TYPE.ERC721) {
                callDataEncodedBuyer = singleCollectableABIIFace.encodeFunctionData('safeTransferFrom', [
                    dataListingOnSale.maker,
                    signerAddressForBuyer,
                    dataListingOnSale.tokenId
                ])
                replacementPatternBuyer =
                    '0x00000000ffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffff00000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000'
            } else {
                callDataEncodedBuyer = multipleCollectableABIIFace.encodeFunctionData('safeTransferFrom', [
                    dataListingOnSale.maker,
                    signerAddressForBuyer,
                    dataListingOnSale.tokenId,
                    dataListingOnSale.quantity,
                    '0x00'
                ])
                replacementPatternBuyer =
                    '0x00000000ffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffff000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000'
            }
        } else {
            if (dataListingOnSale.collectionType === NFT_TYPE.ERC721) {
                callDataEncodedBuyer = singleCollectableABIIFace.encodeFunctionData('mintAndTransfer', [
                    NULL_ADDRESS,
                    signerAddressForBuyer,
                    dataListingOnSale?.tokenId,
                    dataListingOnSale?.tokenId
                ])
                replacementPatternBuyer =
                    '0x00000000ffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffff0000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000'
            } else {
                callDataEncodedBuyer = multipleCollectableABIIFace.encodeFunctionData('transfer', [
                    NULL_ADDRESS,
                    signerAddressForBuyer,
                    dataListingOnSale?.tokenId,
                    dataListingOnSale?.quantity,
                    '0x00',
                    dataListingOnSale?.tokenId
                ])
                replacementPatternBuyer =
                    '0x00000000ffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffff00000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000'
            }
        }

        const contract = await getExchangeContract(dataListingOnSale.exchangeAddress);

        const canBeMatched = await contract.ordersCanMatch_(
            [
                // buyer data
                dataListingOnSale.exchangeAddress,
                signerAddressForBuyer, // maker address
                dataListingOnSale.maker, // taker address
                '0x0000000000000000000000000000000000000000', // fee recipient
                dataListingOnSale.collectionAddress, // collection address
                '0x0000000000000000000000000000000000000000', // default
                dataListingOnSale.erc20Address,

                // seller data
                dataListingOnSale.exchangeAddress,
                dataListingOnSale.maker, // maker address
                '0x0000000000000000000000000000000000000000', // taker address
                dataListingOnSale.feeRecipient, // fee recipient
                dataListingOnSale.collectionAddress,
                '0x0000000000000000000000000000000000000000',
                dataListingOnSale.erc20Address
            ],
            [
                // data of buyer
                dataListingOnSale.makerRelayerFee, // makerRelayerFee
                dataListingOnSale.takerRelayerFee, // takerRelayerFee
                dataListingOnSale.basePrice, //basePrice
                0, // extra // default: 0
                dataListingOnSale.listingTime, // buy time
                0,
                dataListingOnSale.salt, // salt example

                // data of seller
                dataListingOnSale.makerRelayerFee,
                dataListingOnSale.takerRelayerFee,
                dataListingOnSale.basePrice,
                0, //extra
                dataListingOnSale.listingTime,
                0, // expirationTime
                dataListingOnSale.salt // salt example
            ],
            [1, 0, 0, 0, 1, 1, 0, 0],
            callDataEncodedBuyer,
            dataListingOnSale.calldata,
            replacementPatternBuyer,
            dataListingOnSale.replacementPattern,
            '0x00',
            '0x00'
        )
        return [canBeMatched, null]
    } catch (error) {
        return [null, error]
    }
}

export const handleAtomicMatch = async (dataListingOnSale, chainId) => {
    try {
        if (NETWORK_ID_TYPE[chainId] !== dataListingOnSale.networkType)
            await changeNetwork(dataListingOnSale.networkType)

        let callDataEncodedBuyer = ''
        let replacementPatternBuyer = ''

        const signerAddressForBuyer = getAccount(wagmiConfig).address.toLowerCase()

        if (dataListingOnSale.isExternalCollection) {
            if (dataListingOnSale.collectionType === NFT_TYPE.ERC721) {
                callDataEncodedBuyer = singleCollectableABIIFace.encodeFunctionData('safeTransferFrom', [
                    dataListingOnSale.maker,
                    signerAddressForBuyer,
                    dataListingOnSale.tokenId
                ])
                replacementPatternBuyer =
                    '0x00000000ffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffff00000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000'
            } else {
                callDataEncodedBuyer = multipleCollectableABIIFace.encodeFunctionData('safeTransferFrom', [
                    dataListingOnSale.maker,
                    signerAddressForBuyer,
                    dataListingOnSale.tokenId,
                    dataListingOnSale.quantity,
                    '0x00'
                ])
                replacementPatternBuyer =
                    '0x00000000ffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffff000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000'
            }
        } else {
            if (dataListingOnSale.collectionType === NFT_TYPE.ERC721) {
                callDataEncodedBuyer = singleCollectableABIIFace.encodeFunctionData('mintAndTransfer', [
                    NULL_ADDRESS,
                    signerAddressForBuyer,
                    dataListingOnSale?.tokenId,
                    dataListingOnSale?.tokenId
                ])
                replacementPatternBuyer =
                    '0x00000000ffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffff0000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000'
            } else {
                callDataEncodedBuyer = multipleCollectableABIIFace.encodeFunctionData('transfer', [
                    NULL_ADDRESS,
                    signerAddressForBuyer,
                    dataListingOnSale?.tokenId,
                    dataListingOnSale?.quantity,
                    '0x00',
                    dataListingOnSale?.tokenId
                ])
                replacementPatternBuyer =
                    '0x00000000ffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffff00000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000'
            }
        }

        const contract = await getExchangeContract(dataListingOnSale.exchangeAddress);
        const totalETHForTaker = new BigNumber(dataListingOnSale.basePrice * dataListingOnSale.quantity).plus(
            new BigNumber(dataListingOnSale.basePrice * dataListingOnSale.quantity)
                .multipliedBy(dataListingOnSale.takerRelayerFee)
                .div(10000)
        )

        const transaction = await contract.atomicMatch_(
            [
                // buyer data
                dataListingOnSale.exchangeAddress,
                signerAddressForBuyer, // maker address
                dataListingOnSale.maker, // taker address
                '0x0000000000000000000000000000000000000000', // fee recipient
                dataListingOnSale.collectionAddress, // collection address
                '0x0000000000000000000000000000000000000000', // default
                dataListingOnSale.erc20Address,

                // seller data
                dataListingOnSale.exchangeAddress,
                dataListingOnSale.maker, // maker address
                '0x0000000000000000000000000000000000000000', // taker address
                dataListingOnSale.feeRecipient, // fee recipient
                dataListingOnSale.collectionAddress,
                '0x0000000000000000000000000000000000000000',
                dataListingOnSale.erc20Address
            ],
            [
                // data of buyer
                dataListingOnSale.makerRelayerFee, // makerRelayerFee
                dataListingOnSale.takerRelayerFee, // takerRelayerFee
                dataListingOnSale.basePrice, //basePrice
                0, // extra // default: 0
                dataListingOnSale.listingTime, // buy time
                0,
                dataListingOnSale.salt, // salt example

                // data of seller
                dataListingOnSale.makerRelayerFee,
                dataListingOnSale.takerRelayerFee,
                dataListingOnSale.basePrice,
                0, //extra
                dataListingOnSale.listingTime,
                0, // expirationTime
                dataListingOnSale.salt // salt example
            ],
            [1, 0, 0, 0, 1, 1, 0, 0],
            callDataEncodedBuyer,
            dataListingOnSale.calldata,
            replacementPatternBuyer,
            dataListingOnSale.replacementPattern,
            '0x00',
            '0x00',
            [dataListingOnSale.v, dataListingOnSale.v],
            [
                dataListingOnSale.r,
                dataListingOnSale.s,
                dataListingOnSale.r,
                dataListingOnSale.s,
                '0x0000000000000000000000000000000000000000000000000000000000000000'
            ],
            {
                value: dataListingOnSale.erc20Address === NULL_ADDRESS ? totalETHForTaker.toString() : '0',
            }
        )

        const tx = await transaction.wait(1)
        return [tx, null]
    } catch (error) {
        return [null, error]
    }
}

export const buildDataBid = async (initData, chainId) => {
    const RECIPIENT_FEE_ADDRESS = localStorage.getItem(SERVICE_FEE_ADDRESS)
    const MARKET_RAW_FEE = Number(localStorage.getItem(SERVICE_FEE_PERCENT))

    const { collectionAddress, price, nftType, networkType, tokenType, quantity, tokenId } = initData

    try {
        if (NETWORK_ID_TYPE[chainId] !== networkType) await changeNetwork(networkType)

        const signer = await getEtherSigner({ chainId });
        const bidderAddress = getAccount(wagmiConfig).address.toLowerCase()
        let callDataEncodedBuyer = ''
        let replacementPattern = ''

        const paymentToken = {
            tokenAddress: CONTRACT_ADDRESS[networkType][tokenType] || NULL_ADDRESS,
            numberOfDecimals: DECIMAL_TOKENS[networkType][tokenType] || 18
        }

        const saltValue =
            keccak256(Buffer.from(Math.floor(Date.now() / 1000).toString(), 'hex').subarray(2))
            .toString()

        const listingTimeValue = (Math.floor(Date.now() / 1000) - 120).toString()

        if (initData.isExternalCollection) {
            if (nftType === NFT_TYPE.ERC721) {
                callDataEncodedBuyer = singleCollectableABIIFace.encodeFunctionData('safeTransferFrom', [
                    NULL_ADDRESS,
                    bidderAddress,
                    tokenId
                ])
                replacementPattern =
                    '0x00000000ffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffff00000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000'
            } else {
                callDataEncodedBuyer = multipleCollectableABIIFace.encodeFunctionData('safeTransferFrom', [
                    NULL_ADDRESS,
                    bidderAddress,
                    tokenId,
                    quantity,
                    STATIC_EXTRA_DATA
                ])
                replacementPattern =
                    '0x00000000ffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffff000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000'
            }
        } else {
            if (nftType === NFT_TYPE.ERC721) {
                callDataEncodedBuyer = singleCollectableABIIFace.encodeFunctionData('mintAndTransfer', [
                    NULL_ADDRESS,
                    bidderAddress,
                    tokenId,
                    tokenId
                ])

                replacementPattern =
                    '0x00000000ffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffff0000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000'
            } else {
                callDataEncodedBuyer = multipleCollectableABIIFace.encodeFunctionData('transfer', [
                    NULL_ADDRESS,
                    bidderAddress,
                    tokenId,
                    quantity,
                    '0x00',
                    tokenId
                ])
                replacementPattern =
                    '0x00000000ffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffff00000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000'
            }
        }

        const contract = await getExchangeContract(CONTRACT_ADDRESS[networkType].exchange);

        const makerHashOrder = await contract.hashOrder_(
            [
                CONTRACT_ADDRESS[networkType].exchange, // sc address
                bidderAddress, // bidder address
                NULL_ADDRESS, // auction owner address
                RECIPIENT_FEE_ADDRESS, // protocolFee address
                collectionAddress, //collection address
                STATIC_TARGET_ADDRESS,
                paymentToken.tokenAddress // payment token address
            ],
            [
                MARKET_RAW_FEE * 100,
                MARKET_RAW_FEE * 100,
                convertPriceToBigDecimals(price, paymentToken.numberOfDecimals), // base price - token decimals
                EXTRA, // extra
                listingTimeValue, // listingTime
                '0', // expirationTime for auction
                saltValue // salt
            ],
            1, // feeMethod default: 1
            0, // side - 1: sell, 0: buy
            0, // saleKind - 0: fixed price, 1: auction
            0, // howToCall default: 0
            callDataEncodedBuyer,
            replacementPattern,
            STATIC_EXTRA_DATA
        )


        const hashOrderMessage = Buffer.from(makerHashOrder.slice(2), 'hex');
        const signedMessage = await signer.signMessage(hashOrderMessage);

        const decodedSignature = Signature.from(signedMessage);

        const dataPutOnSale = {
            basePrice: convertPriceToBigDecimals(price, paymentToken.numberOfDecimals), // base price - token decimals
            calldata: callDataEncodedBuyer,
            replacementPattern,
            exchangeAddress: CONTRACT_ADDRESS[networkType].exchange,
            expirationTime: '0',
            extra: EXTRA, // = 0 default
            feeMethod: FEE_METHOD, // = 1 default
            feeRecipient: RECIPIENT_FEE_ADDRESS, // set in env address
            hash: makerHashOrder, // makerHashOrder
            howToCall: HOW_TO_CALL, // = 0 default

            maker: bidderAddress, // user who create nft
            makerRelayerFee: (MARKET_RAW_FEE * 100).toString(),

            taker: NULL_ADDRESS,
            takerRelayerFee: (MARKET_RAW_FEE * 100).toString(),

            saleKind: 0, // saleKind - 0: fixed price, 1: auction,
            listingTime: listingTimeValue,
            salt: saltValue,

            side: 1, // side:  sell = 1, buy = 0,
            staticExtraData: STATIC_EXTRA_DATA, // default
            staticTarget: '0x0000000000000000000000000000000000000000', // default

            tokenId: initData.tokenId,
            collectionAddress,

            networkType,
            collectionType: initData.nftType,

            erc20Address: paymentToken.tokenAddress,
            decimal: paymentToken.numberOfDecimals,

            quantity,
            isExternalCollection: initData.isExternalCollection,

            r: decodedSignature?.r,
            v: decodedSignature?.v,
            s: decodedSignature?.s
        }

        return [dataPutOnSale, null]
    } catch (error) {
        return [null, error]
    }
}

export const handleAtomicMatchForAcceptBid = async (dataPlaceBid, chainId) => {
    try {
        if (NETWORK_ID_TYPE[chainId] !== dataPlaceBid.networkType) await changeNetwork(dataPlaceBid.networkType)
        const signerAddress = getAccount(wagmiConfig).address.toLowerCase()

        let callDataEncodedBuyer = ''
        let replacementPatternBuyer = ''

        if (dataPlaceBid.isExternalCollection) {
            if (dataPlaceBid.collectionType === NFT_TYPE.ERC721) {
                callDataEncodedBuyer = singleCollectableABIIFace.encodeFunctionData('safeTransferFrom', [
                    signerAddress,
                    dataPlaceBid.maker,
                    dataPlaceBid.tokenId
                ])
                replacementPatternBuyer =
                    '0x000000000000000000000000000000000000000000000000000000000000000000000000ffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffff0000000000000000000000000000000000000000000000000000000000000000'
            } else {
                callDataEncodedBuyer = multipleCollectableABIIFace.encodeFunctionData('safeTransferFrom', [
                    signerAddress,
                    dataPlaceBid.maker,
                    dataPlaceBid.tokenId,
                    dataPlaceBid.quantity,
                    '0x00'
                ])
                replacementPatternBuyer =
                    '0x000000000000000000000000000000000000000000000000000000000000000000000000ffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffff0000000000000000000000000000000000000000000000000000000000000000ffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffff000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000'
            }
        } else {
            if (dataPlaceBid.collectionType === NFT_TYPE.ERC721) {
                callDataEncodedBuyer = singleCollectableABIIFace.encodeFunctionData('mintAndTransfer', [
                    signerAddress,
                    NULL_ADDRESS,
                    dataPlaceBid.tokenId,
                    dataPlaceBid.tokenId
                ])
                replacementPatternBuyer =
                    '0x000000000000000000000000000000000000000000000000000000000000000000000000ffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffff0000000000000000000000000000000000000000000000000000000000000000ffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffff0000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000'
            } else {
                callDataEncodedBuyer = multipleCollectableABIIFace.encodeFunctionData('transfer', [
                    signerAddress,
                    NULL_ADDRESS,
                    dataPlaceBid.tokenId,
                    dataPlaceBid.quantity,
                    '0x00',
                    dataPlaceBid.tokenId
                ])
                replacementPatternBuyer =
                    '0x000000000000000000000000000000000000000000000000000000000000000000000000ffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffff0000000000000000000000000000000000000000000000000000000000000000ffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffff00000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000'
            }
        }

        const contract = await getExchangeContract(dataPlaceBid.exchangeAddress);

        const transaction = await contract.atomicMatch_(
            [
                dataPlaceBid.exchangeAddress,
                dataPlaceBid.maker, // taker address -  user who place a bid
                NULL_ADDRESS, // maker address - owner of auction
                dataPlaceBid.feeRecipient, // fee recipient
                dataPlaceBid.collectionAddress, // collection address
                '0x0000000000000000000000000000000000000000', // default
                dataPlaceBid.erc20Address, // weth address

                dataPlaceBid.exchangeAddress,
                signerAddress, // user who place a bid
                NULL_ADDRESS, // owner of auction
                NULL_ADDRESS, // fee recipient
                dataPlaceBid.collectionAddress,
                '0x0000000000000000000000000000000000000000',
                dataPlaceBid.erc20Address // weth address
            ],
            [
                // data of buyer
                dataPlaceBid.makerRelayerFee, // makerRelayerFee
                dataPlaceBid.takerRelayerFee, // takerRelayerFee
                dataPlaceBid.basePrice, //basePrice
                EXTRA, // extra // default: 0
                dataPlaceBid.listingTime, // buy time
                '0',
                dataPlaceBid.salt, // salt example

                // data of seller
                dataPlaceBid.makerRelayerFee,
                dataPlaceBid.takerRelayerFee,
                dataPlaceBid.basePrice,
                EXTRA, //extra
                dataPlaceBid.listingTime,
                '0',
                dataPlaceBid.salt // salt example
            ],
            [
                1, // fee method
                0, // side: buy
                0, // sale kind:
                0, // how to call

                1, //
                1, //side: sell
                0, // sale kind:
                0
            ],

            dataPlaceBid.calldata,
            callDataEncodedBuyer,
            dataPlaceBid.replacementPattern,
            replacementPatternBuyer,
            '0x00',
            '0x00',

            [dataPlaceBid.v, dataPlaceBid.v],
            [
                dataPlaceBid.r,
                dataPlaceBid.s,
                dataPlaceBid.r,
                dataPlaceBid.s,
                '0x0000000000000000000000000000000000000000000000000000000000000000'
            ],
        )
        const result = await transaction.wait(1)
        return [result, null]
    } catch (error) {
        return [null, error]
    }
}

export const handleCancelBid = async (bidInfo, chainId) => {
    const { collectionAddress, basePrice, expirationTime, listingTime, salt, networkType } = bidInfo

    try {
        if (NETWORK_ID_TYPE[chainId] !== networkType) await changeNetwork(networkType)

        const signerAddress = getAccount(wagmiConfig).address.toLowerCase()

        const contract = await getExchangeContract(bidInfo.exchangeAddress);
        const transaction = await contract.cancelOrder_(
            [
                bidInfo.exchangeAddress,
                signerAddress, // bidder address
                NULL_ADDRESS, // auction owner address
                bidInfo.feeRecipient, // protocolFee address
                collectionAddress, //collection address
                STATIC_TARGET_ADDRESS,
                bidInfo.erc20Address // payment token address
            ],
            [
                bidInfo.makerRelayerFee,
                bidInfo.takerRelayerFee,
                basePrice, // base price - token decimals
                EXTRA, // extra
                listingTime, // listingTime
                expirationTime, // expirationTime for auction
                salt // salt
            ],
            1, // feeMethod default: 1
            0, // side - 1: sell, 0: buy
            0, // saleKind - 0: fixed price, 1: auction
            0, // howToCall default: 0
            bidInfo.calldata,
            bidInfo.replacementPattern,
            STATIC_EXTRA_DATA,
            bidInfo.v,
            bidInfo.r,
            bidInfo.s
        );
        
        const result = await transaction.wait(1)
        return [result, null]
    } catch (error) {
        return [null, error]
    }
}
