import production from './production'
import develop from './develop'
import uat from './uat'
import localhost from './localhost'

const env = process.env.REACT_APP_ENV

const configs = { develop, uat, production, localhost }

const config = configs[env]

export default config
