import { useCallback } from 'react'
import { useDispatch } from 'react-redux'
import { useAccount } from 'wagmi'

import { signWallet } from 'blockchain/utils'
import { loginApi } from 'store/login.slice'
import * as message from 'utils/custom-message'
import { ERROR_CODE_USER_DENIED_METAMASK } from 'constants/index'
import { useRouter } from './useRouter'
import { openLockMetamaskModal } from 'store/modal.slice'
import { useTranslation } from 'react-i18next'

export const useAuth = ({ afterLogin: { redirect = '' } = {} } = {}) => {
    const {t} = useTranslation();
    const { address } = useAccount();
    const dispatch = useDispatch()
    const router = useRouter()

    const login = useCallback(async () => {
        if (!address) {
            return;
        }
        try {
            const signature = await signWallet()
            if (!signature) {
                return;
            }
            const credentials = {
                walletAddress: address,
                signature
            }
            
            dispatch(loginApi(credentials));

            if (redirect) {
                router.push(redirect)
                return
            }

            return true;
        } catch (err) {
            if (err.code === -32602) {
                dispatch(openLockMetamaskModal())
            } else if (err.code === ERROR_CODE_USER_DENIED_METAMASK) {
                message.error(t('error.message.declinedActionWallet'))
            } else {
                message.error(t('error.message.declinedActionWallet'))
            }
            return false
        }
    }, [address, dispatch, redirect, router])

    return [address, login]
}
