import React from 'react'
import PropTypes from 'prop-types'

//components
import { Switch } from 'antd'
import './style.scss';

const CustomSwitch = ({ onChange, value, disableLabel, className, ...restProps }) => {
    return (
        <Switch
            className={`switch-custom ${className}`}
            onChange={onChange}
            checkedChildren={!disableLabel && 'ON'}
            unCheckedChildren={!disableLabel && 'OFF'}
            checked={value}
            {...restProps}
        />
    )
}

CustomSwitch.propTypes = {
    onChange: PropTypes.func,
    value: PropTypes.bool
}

export default CustomSwitch
