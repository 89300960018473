import React from 'react'
import { Avatar } from 'antd'
import PropTypes from 'prop-types'
import CustomVerifyIcon from '../custom-verify-icon'
import AvatarPlaceholder from 'assets/icons/avatar-default.svg'
import LogoAvatarDefault from 'assets/images/smapocke.png'
import './style.scss'
import { AWS_CLOUDFRONT_ENDPOINT } from 'constants/envs'

const CustomAvatar = ({ src, size = 40, verified = false, isDefaultCollection, verifiedClassName, ...rest }) => {
    const getAvatar = () => {
        if (isDefaultCollection) return LogoAvatarDefault
        if (src?.includes('http')) {
            return src
        } else if (src?.includes('ipfs://ipfs/')) {
            const ipfsIndex = src.indexOf('//ipfs/')
            const urlHash = src.substring(ipfsIndex + 7)
            return `https://ipfs.io/ipfs/${urlHash}`
        } else if (src?.includes('ipfs://')) {
            const ipfsIndex = src.indexOf('//')
            const urlHash = src.substring(ipfsIndex + 2)
            return `https://ipfs.io/ipfs/${urlHash}`
        }
        return src ? `${AWS_CLOUDFRONT_ENDPOINT}/${src}` : AvatarPlaceholder
    }
    return (
        <div className="custom-avatar" style={{ width: size, height: size }}>
            <Avatar src={getAvatar()} size={size} {...rest} />
            {verified ? <CustomVerifyIcon className={verifiedClassName} /> : null}
        </div>
    )
}

CustomAvatar.propTypes = {
    src: PropTypes.string,
    size: PropTypes.number,
    verified: PropTypes.bool,
    isDefaultCollection: PropTypes.bool
}

export default CustomAvatar
