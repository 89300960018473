import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

//components
import { Card, Avatar } from 'antd'

//image

import coverPlaceholder from 'assets/images/default.svg'
import avatarPlaceholder from 'assets/icons/avatar-default.svg'

//hooks
import { useTranslation } from 'react-i18next'

//utils
import { convertImage } from 'utils/image'

//styles
import './style.scss'
import CustomVerifyIcon from 'components/common/custom-verify-icon'
import { roundingNumber } from 'utils/formatNumber'
import { shortenAddress, shortenNameNoti } from 'utils/func'
import { TooltipCustom } from 'components/common'
import { Link } from 'react-router-dom'

const CardUser = ({ data, className }) => {
    const { t } = useTranslation()

    const { username, coverImg, avatarImg, isVerify, walletAddress, shortUrl, followers } = data || {}

    if (!data) {
        return null
    }

    return (
        <Card className={classNames({ 'card-user-custom': true, [className]: !!className })}>
            <Link to={shortUrl ? `/user/${shortUrl}` : `/user/${walletAddress}`}>
                <div className="card-user-custom-header">
                    <Avatar
                        className={`card-user-custom-avatar ${coverImg ? "" : "default"}`}
                        alt="user"
                        src={coverImg ? convertImage(coverImg) : coverPlaceholder}
                    />
                    <div className="card-user-custom-user">
                        <div className="card-user-custom-user_wrapper">
                            <Avatar
                                className="card-user-custom-user_avatar"
                                alt="example"
                                src={avatarImg ? convertImage(avatarImg) : avatarPlaceholder}
                            />
                            {isVerify ? <CustomVerifyIcon className="card-user-custom-user_icon__last" /> : null}
                        </div>
                    </div>
                </div>
                <div className="card-user-custom-content">
                    <TooltipCustom title={username ? username : shortenAddress(walletAddress)}>
                        <span className="card-user-custom-content_name">
                            {username ? shortenNameNoti(username, 20) : shortenAddress(walletAddress)}
                        </span>
                    </TooltipCustom>

                    <span className="card-user-custom-content_followers">
                        {roundingNumber(followers?.length) || 0} {t('common.followers')}
                    </span>
                </div>
            </Link>
        </Card>
    )
}

CardUser.propTypes = {
    data: PropTypes.object.isRequired,
    className: PropTypes.string
}

export default CardUser
