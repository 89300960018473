import React, { useContext } from 'react'

import LogoSmartPocket from 'assets/images/smart-pocket-logo.svg'
import DarkLogoSmartPocket from 'assets/images/dark-smart-pocket-logo.svg'
import { ThemeContext } from 'ThemeProvider'
import './style.scss'

export default function Logo() {
    const { theme } = useContext(ThemeContext)
    return (
        <div className="cursor-pointer">
            <img
                src={theme === 'light-theme' ? LogoSmartPocket : DarkLogoSmartPocket}
                alt="logo-icon"
                className="logo-image"
            />
        </div>
    )
}
