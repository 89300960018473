import React, { useCallback, useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import PropTypes from "prop-types";
import { Col, Row } from "antd";

import { CardCarousel, CardCollectionTwo } from "components/modules";
import collectionService from "service/collectionService";
import * as message from "utils/custom-message";

import "./style.scss";
import { useResizeWindow } from "utils/hook";
import { SCREEN_SIZE } from "constants/index";
import { useTranslation } from "react-i18next";
import { setGlobalLoading, setCloseGlobalLoading } from "store/system.slice";
import { ButtonCustom, NoData } from "components/common";

const HotCollections = ({
  isExploreType = false,
  gridView = false,
  networkTypes,
  saleTypes,
  keyword,
  amountItemDefault = 3,
  categoryId,
  showLoadMore = false,
}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const LIMIT = 12;
  const [collections, setCollections] = useState([]);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [isTablet] = useResizeWindow(SCREEN_SIZE.tablet);

  const fetchCollections = async (page = 1, prevItems = []) => {
    dispatch(setGlobalLoading());
    const [data, err] = await collectionService.getAllCollection({
      page,
      limit: LIMIT,
      networkTypes,
      saleTypes,
      keyword,
      categoryId,
    });
    if (data) {
      setCollections([...prevItems, ...data.items]);
      setTotalPages(data.meta?.totalPages);
    }

    if (err) {
      message.error(t("error.message.somethingWentWrong"));
    }
    dispatch(setCloseGlobalLoading());
  };

  useEffect(() => {
    fetchCollections();
    setPage(1);
  }, [networkTypes, saleTypes, keyword, categoryId]);

  const onClickLoadMore = () => {
    const nextPage = page + 1;
    fetchCollections(nextPage, collections);
    setPage(nextPage);
  };

  const _renderLoadMore = () => {
    if (!showLoadMore || page >= totalPages) {
      return null;
    }

    return (
      <ButtonCustom className="collections-load-more" variant="outline" onClick={onClickLoadMore}>
        {t("editProfile.showMore")}
      </ButtonCustom>
    );
  };

  const _renderCarousel = useCallback(() => {
    if (gridView || collections?.length < 4) {
      return (
        <>
          <Row gutter={[{ xs: 6, sm: 6, md: 20, lg: 20, xl: 20 }, 20]}>
            {collections.map((el, index) => (
              <Col
                xs={{ span: 24 }}
                sm={{ span: 12 }}
                md={{ span: 12 }}
                lg={{ span: 8 }}
                xl={{ span: 8 }}
                xxl={{ span: 8 }}
                key={`${el.id}-${index}`}
              >
                <CardCollectionTwo isExploreType={isExploreType} data={el} />
              </Col>
            ))}
          </Row>
          {_renderLoadMore()}
        </>
      );
    } else if (isTablet) {
      return (
        <CardCarousel
          infinite={true}
          lazyLoad={true}
          countData={6}
          amoutItem={1}
          scroll={1}
          arrowOffset={20}
          isAuto={true}
          className="custom-carousel carousel-desktop"
        >
          {collections.map((obj, index) => {
            return (
              <div className="card-container" key={index}>
                <CardCollectionTwo
                  isExploreType={isExploreType}
                  data={obj}
                  key={index}
                />
              </div>
            );
          })}
        </CardCarousel>
      );
    } else {
      return (
        <CardCarousel
          infinite={true}
          lazyLoad={true}
          countData={6}
          amoutItem={
            collections.length < 3 ? collections.length : amountItemDefault
          }
          scroll={1}
          arrowOffset={0}
          isAuto={true}
          className="custom-carousel carousel-desktop"
        >
          {collections.map((obj, index) => {
            return (
              <div className="card-container" key={index}>
                <CardCollectionTwo
                  isExploreType={isExploreType}
                  data={obj}
                  key={index}
                />
              </div>
            );
          })}
        </CardCarousel>
      );
    }
  }, [collections, isTablet]);

  return (
    <div className="hot-collections">
      {_renderCarousel()}
      <NoData data={collections} />
    </div>
  );
};

HotCollections.propTypes = {
  isExploreType: PropTypes.bool,
  gridView: PropTypes.bool,
  networkTypes: PropTypes.string,
  saleTypes: PropTypes.string,
  keyword: PropTypes.string,
};

export default HotCollections;
