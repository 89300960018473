import ArtCategoryIcon from 'assets/icons/art-category-icon.svg'
import TattooCategoryIcon from 'assets/icons/tattoo-category-icon.svg'
import GameCategoryIcon from 'assets/icons/category-game-icon.svg'
import MusicCategoryIcon from 'assets/icons/category-music-icon.svg'
import DomainCategoryIcon from 'assets/icons/domain-category-icon.svg'
import TradingCategoryIcon from 'assets/icons/trading-category-icon.svg'
import MemesCategoryIcon from 'assets/icons/meme-category-icon.svg'
import CollectibleCategoryIcon from 'assets/icons/collectible-category-icon.svg'
import MetaverseCategoryIcon from 'assets/icons/category-metaverse-icon.svg'
import VideoCategoryIcon from 'assets/icons/video2-category-icon.svg'
import PhotoGraphCategoryIcon from 'assets/icons/category-photography-icon.svg'
import ToyCategoryIcon from 'assets/icons/category-toys-icon.svg'
import BrandCategoryIcon from 'assets/icons/category-brand-icon.svg'
import PhysicalCategoryIcon from 'assets/icons/category-physical-icon.svg'
import PFPsCategoryIcon from 'assets/icons/category-pfps-icon.svg'
import OfficialCharacterCategoryIcon from 'assets/icons/category-official-character-icon.svg'
import ThreeDCategoryIcon from 'assets/icons/category-3d-icon.svg'
import MembershipCategoryIcon from 'assets/icons/category-memberships-icon.svg'

import CancelSaleIcon from 'assets/icons/cancel-sale-icon.svg'
import ListForSaleIcon from 'assets/icons/list-for-sale-icon.svg'
import SaleIcon from 'assets/icons/sale-icon.svg'
import BidIcon from 'assets/icons/bid-icon.svg'
import CreateIcon from 'assets/icons/create-icon.svg'

// import ETHIcon from 'assets/icons/eth-icon.svg'
// import POLIcon from 'assets/icons/polygon-icon.png'
// import BSCIcon from 'assets/icons/bnb-icon.svg'
import ETHIcon from 'assets/images/eth-network.png'
import POLIcon from 'assets/images/polygon-network.png'
import BSCIcon from 'assets/images/bsc-network.png'
import LogoIcon from 'assets/images/dark-logo.svg'
import UsdtIcon from 'assets/images/staking-usdt.svg'
import BusdIcon from 'assets/icons/busd-logo.png'

import BnbIcon from 'assets/icons/bnb-icon.svg'
import WbnbIcon from 'assets/icons/wbnb.png'
import EthIcon from 'assets/icons/eth-icon.svg'
import WethIcon from 'assets/icons/weth-icon.png'

export const LOGIN_SIGN_MESSAGE = 'Welcome to SmartPocket! Click Sign to sign in. No password needed!'

export const ERROR_CODE_USER_DENIED_METAMASK = 4001

export const SOCKET_EVENT_ACTIVITY = 'NOTIFICATION'

export const ACTIVITY_STATUS = {
    PURCHASE: 'purchase',
    SALE: 'sales',
    LIKE: 'liked',
    FOLLOW: 'follow',
    BID: 'bid',
    OPEN_FOR_OFFER: 'offer',
    LISTING: 'listing',
    ACCEPT_OFFER_PURCHASE: 'accept_offer_purchase',
    ACCEPT_BID_PURCHASE: 'accept_bid_purchase'
}

export const DECIMALS = {
    BNB_DECIMALS: 18,
    WBNB_DECIMALS: 18,
    ETH_DECIMALS: 18,
    WETH_DECIMALS: 18,
    MATIC_DECIMALS: 18,
    SP_DECIMALS: 18,
    USDT_DECIMALS: 6,
    BUSD_DECIMALS: 18
}

export const TOKEN = {
    BNB: 'BNB',
    WBNB: 'WBNB',
    SP: 'SP',
    ETH: 'ETH',
    WETH: 'WETH',
    USDT: 'USDT',
    BUSD: 'BUSD',
    MATIC: 'MATIC'
}

export const SALE_TYPE = {
    FIX_PRICE: 'fix_price',
    ENGLISH_AUCTION: 'english_auction',
    OPEN_FOR_OFFER: 'open_for_offer'
}

export const COLLECTIBLE_TYPE = {
    MULTIPLE: 'multiple',
    SINGLE: 'single'
}

export const NFT_TYPE = {
    ERC1155: 'ERC1155',
    ERC721: 'ERC721'
}

export const COLLECTIBLE_CODE = {
    [COLLECTIBLE_TYPE.MULTIPLE]: 'ERC1155',
    [COLLECTIBLE_TYPE.SINGLE]: 'ERC721'
}

export const MAX_INT = '0xffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffff'

export const CATEGORIES_ICONS_MAPPING = {
    Game: {
        icon: 'category-game-icon',
        code: 'game',
        img: GameCategoryIcon
    },
    Art: {
        icon: 'art-category-icon',
        code: 'art',
        img: ArtCategoryIcon
    },
    Collectibles: {
        icon: 'collectible-category-icon',
        code: 'collectible',
        img: CollectibleCategoryIcon
    },
    Metaverse: {
        icon: 'category-metaverse-icon',
        code: 'metaverse',
        img: MetaverseCategoryIcon
    },
    Toys: {
        icon: 'category-toys-icon',
        code: 'toy',
        img: ToyCategoryIcon
    },
    Brand: {
        icon: 'category-brand-icon',
        code: 'brand',
        img: BrandCategoryIcon
    },
    Physical: {
        icon: 'category-physical-icon',
        code: 'physical',
        img: PhysicalCategoryIcon
    },
    'Trading Cards': {
        icon: 'trading-category-icon',
        code: 'trading',
        img: TradingCategoryIcon
    },
    Music: {
        icon: 'category-music-icon',
        code: 'music',
        img: MusicCategoryIcon
    },
    'Domain Names': {
        icon: 'domain-category-icon',
        code: 'domain',
        img: DomainCategoryIcon
    },
    Memes: {
        icon: 'meme-category-icon',
        code: 'meme',
        img: MemesCategoryIcon
    },
    Videos: {
        icon: 'video-category-icon',
        code: 'videos',
        img: VideoCategoryIcon
    },
    Utility: {
        icon: 'tattoo-category-icon',
        code: 'utility',
        img: TattooCategoryIcon
    },
    Photography: {
        icon: 'category-photography-icon',
        code: 'photography',
        img: PhotoGraphCategoryIcon
    },
    PFPs: {
        icon: 'category-pfps-icon',
        code: 'pfps',
        img: PFPsCategoryIcon
    },
    '3D': {
        icon: 'category-3d-icon',
        code: '3d',
        img: ThreeDCategoryIcon
    },
    'OfficialCharacter': {
        icon: 'category-official-character-icon',
        code: 'official_character',
        img: OfficialCharacterCategoryIcon
    },
    'Memberships': {
        icon: 'category-memberships-icon',
        code: 'memberships',
        img: MembershipCategoryIcon
    }
}

export const TRADING_ACTIONS = [
    { label: 'All', value: '' },
    { label: 'Offer', value: 'offer', icon: BidIcon },
    { label: 'Bid', value: 'bid', icon: BidIcon },
    { label: 'List for sale', value: 'list', icon: ListForSaleIcon },
    { label: 'Transfer', value: 'transfer', icon: SaleIcon },
    { label: 'Sale', value: 'sale', icon: SaleIcon },
    { label: 'Minted', value: 'mint', icon: CreateIcon },
    { label: 'Cancel List', value: 'cancel_list', icon: CancelSaleIcon }
]

export const SUPPORTED_FILE_TYPE = [
    'image/png',
    'image/jpg',
    'image/jpeg',
    'image/webp',
    'image/gif',
    'audio/mpeg',
    'video/mp3',
    'video/mp4'
]

export const PREVIEW_SUPPORTED_FILE_TYPE = ['image/png', 'image/jpg', 'image/jpeg', 'image/webp', 'image/gif']

export const VIDEO_FILE_TYPE = ['video/mp3', 'video/mp4', 'audio/mpeg']

export const FILE_VIDEO_EXTENSION = {
    MP4: 'video/mp4',
    MPEG: 'audio/mpeg'
}

export const FILE_EXTENSION = {
    MP4: '.mp4',
    MPEG: '.mpeg'
}
// Same define in BE
export const IMAGE_TYPE_UPLOAD = {
    USER_AVATAR: 'user_avatar',
    USER_COVER: 'user_cover',
    NFT_IMG: 'nft_img',
    NFT_VIDEO: 'nft_video',
    NFT_AUDIO: 'nft_audio',
    COLLECTION: 'collection',
    COLLECTION_COVER: 'collection_cover'
}

export const CREATE_TYPE = {
    SINGLE: 'single',
    MULTIPLE: 'multiple'
}

export const SCREEN_SIZE = {
    smMobile: 320,
    mMobile: 375,
    mobile: 425,
    tablet: 768,
    desktop: 1024,
    xl: 1200,
    xxl: 1440
}

export const INSTAGRAM_LINK = 'https://www.instagram.com/snailhouse_io/'
export const TWITTER_LINK = 'https://twitter.com/smapocke'
export const TELEGRAM_LINK = 'https://t.me/SnailHouse_market'
export const DISCORD_LINK = 'https://discord.gg/smartpocket'
export const GITHUB_LINK = 'https://github.com/Smartpocket'
export const FACEBOOK_LINK = 'https://www.facebook.com/Smartpocket.official'
export const YOUTUBE_LINK = 'https://www.youtube.com/channel/UCVd-tNMW7gJexccZ2jjHKFQ'
export const MEDIUM_LINK = 'https://snailhouse-official.medium.com'
export const GITBOOK_LINK =
    'https://app.gitbook.com/o/62AkIclKEl7kiUnFJhfD/s/xdhgd909gStwm2bUbtGj/welcome/contact-us/partnerships'
export const LINKEDIN_LINK = 'https://www.linkedin.com/in/snailhouse/'
export const WALLET_LINK = '/connect'
export const MY_COLLECTION = '/create/single'
export const CREATE_NFT = 'choose-type'
export const MY_ITEM = 'user/owner'

export const SOCIAL_LINK = {
    INSTAGRAM: 'https://www.instagram.com',
    TWITTER: 'https://twitter.com',
    TELEGRAM: 'https://t.me',
    DISCORD: 'https://discord.gg',
    GITHUB: 'https://github.com',
    FACEBOOK: 'https://www.facebook.com',
    YOUTUBE: 'https://www.youtube.com',
    TIKTOK: 'https://www.tiktok.com',
    MEDIUM: 'https://medium.com'
}

export const MAIN_TITLE = 'Smartpocket - Create, Sell or Collect NFTs, Digital Items Secured with Blockchain'

export const PROCESS_STATUS = {
    NOT_RUN: 0,
    PENDING: 1,
    FULLFIL: 2
}

export const SORT_BY = [
    {
        value: 'recently',
        label: 'Recent'
    },
    {
        value: 'ending_soon',
        label: 'Auction due soon'
    },
    {
        value: 'price_low_to_high',
        label: 'Cheapest Price'
    },
    {
        value: 'price_high_to_low',
        label: 'Highest Price'
    }
]

export const SORT_BY_TWO = [
    {
        value: 'RECENT',
        label: 'Recent'
    },
    {
        value: 'RECENT_SOLD',
        label: 'Recently Sold'
    },
    {
        value: 'AUCTION_DUE_SOON',
        label: 'Auction due soon'
    },
    {
        value: 'price_low_to_high',
        label: 'Cheapest Price'
    },
    {
        value: 'price_high_to_low',
        label: 'Highest Price'
    },
    {
        value: 'MOST_LIKED',
        label: 'Most Liked'
    },
    {
        value: 'OLDEST',
        label: 'Oldest'
    }
]

export const NETWORK_TYPES = {
    ethereum: 'ethereum',
    polygon: 'polygon',
    bsc: 'bsc'
}

export const NETWORK_OPTIONS = [
    { value: 'ethereum', label: 'Ethereum', icon: ETHIcon },
    { value: 'bsc', label: 'Bsc', icon: BSCIcon },
    { value: 'polygon', label: 'Polygon', icon: POLIcon }
]

export const NETWORK_ICON = { bsc: BSCIcon, ethereum: ETHIcon, polygon: POLIcon }

export const PAYMENT_OPTIONS = {
    ethereum: [
        { value: 'eth', label: 'ETH' },
        { value: 'weth', label: 'WETH' },
        { value: 'sp', label: 'SP' },
        { value: 'usdt', label: 'USDT' }
    ],
    bsc: [
        { value: 'bnb', label: 'BNB' },
        { value: 'wbnb', label: 'WBNB' },
        { value: 'sp', label: 'SP' },
        { value: 'busd', label: 'BUSD' }
    ],
    polygon: [
        { value: 'matic', label: 'MATIC' },
        { value: 'sp', label: 'SP' },
        { value: 'weth', label: 'WETH' }
    ]
}

export const PAYMENT_OPTIONS_AUCTION = {
    ethereum: [
        { value: 'weth', label: 'WETH' },
        { value: 'sp', label: 'SP' },
        { value: 'usdt', label: 'USDT' }
    ],
    bsc: [
        { value: 'wbnb', label: 'WBNB' },
        { value: 'sp', label: 'SP' },
        { value: 'busd', label: 'BUSD' }
    ],
    polygon: [
        { value: 'sp', label: 'SP' },
        { value: 'weth', label: 'WETH' }
    ]
}

export const PAYMENT_TOKEN = {
    BNB: 'bnb',
    WBNB: 'wbnb',
    ETH: 'eth',
    WETH: 'weth',
    MATIC: 'matic',
    BUSD: 'busd',
    USDT: 'usdt',
    SP: 'sp'
}

export const DECIMAL_TOKENS = {
    ethereum: {
        [PAYMENT_TOKEN.ETH]: 18,
        [PAYMENT_TOKEN.WETH]: 18,
        [PAYMENT_TOKEN.USDT]: 6,
        [PAYMENT_TOKEN.SP]: 18,
    },
    bsc: { [PAYMENT_TOKEN.BNB]: 18, [PAYMENT_TOKEN.WBNB]: 18, [PAYMENT_TOKEN.SP]: 18, [PAYMENT_TOKEN.BUSD]: 18 },
    polygon: { 
        [PAYMENT_TOKEN.MATIC]: 18, 
        [PAYMENT_TOKEN.SP]: 18,
        [PAYMENT_TOKEN.WETH]: 18 
    }
}

export const TOKEN_ICON = {
    [PAYMENT_TOKEN.BNB]: BnbIcon,
    [PAYMENT_TOKEN.WBNB]: WbnbIcon,
    [PAYMENT_TOKEN.BUSD]: BusdIcon,
    [PAYMENT_TOKEN.USDT]: UsdtIcon,
    [PAYMENT_TOKEN.ETH]: EthIcon,
    [PAYMENT_TOKEN.WETH]: WethIcon,
    [PAYMENT_TOKEN.MATIC]: POLIcon,
    [PAYMENT_TOKEN.SP]: LogoIcon
}

export const USER_IS_BANNED_CODE = 'USER_IS_BANNED'

export const SOCIAL_NAME = {
    twitter: 'Twitter',
    instagram: 'Instagram'
}

export const VERIFI_STATUS = {
    NONE: 'NONE',
    PENDING: 'PENDING',
    APPROVE: 'APPROVE'
}

export const SORT_COLLECTION_VALUE = {
    RECENT: 'createdAt'
}

export const SOCKET_EVENTS = {
    CANCEL_INTERNAL_SALE: 'CANCEL_INTERNAL_SALE',
    CANCEL_OFFER_OR_BID: 'CANCEL_OFFER_OR_BID',
    NOTIFICATION: 'NOTIFICATION',
    TRANSFER_NFT_SUCCESS: 'TRANSFER_NFT_SUCCESS',
    UPDATED_PAYOUT_ADDRESS: 'UPDATED_PAYOUT_ADDRESS'
}

export const defaultLanguage = 'en';
