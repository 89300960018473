import React, { useState, useEffect, useMemo, useRef } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Row, Col, Checkbox, Modal, Form, Tooltip } from 'antd'
import BigNumber from 'bignumber.js'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { useWindowSize } from 'react-use'
import { useChainId } from 'wagmi'

import { ButtonCustom } from 'components/common'
import { SvgIcon } from 'components/modules'
import * as message from 'utils/custom-message'
import { roundNumber } from 'utils/func'
import { convertBigNumberValueToNumber } from 'blockchain/ether'
import {
    handleOrderCanMatch,
    handleAtomicMatch,
    handleUserApproveERC20,
    getERC20AmountBalance,
    isUserApprovedERC20,
    changeNetwork
} from 'blockchain/utils'
import { getBalance } from 'blockchain/instance'
import {
    PAYMENT_TOKEN,
    ERROR_CODE_USER_DENIED_METAMASK,
    FILE_EXTENSION,
    DECIMAL_TOKENS,
    SOCKET_EVENTS
} from 'constants/index'
import { CONTRACT_ADDRESS, NETWORK_ID_TYPE } from 'constants/envs'
import { exportFileFormat } from 'utils/file'
import { socket } from 'config'
import './styles.scss'
import { convertImage } from 'utils/image'
import DefaultImage from 'assets/images/default.svg'

const BuyModal = ({ onCloseModal, token, onOpenProcessModal, isOpen, setIsDoneProcessing, onCloseProcessModal }) => {
    const { t } = useTranslation()
    const { width } = useWindowSize()
    const dispatch = useDispatch()
    const chainId = useChainId();
    const { nftImagePreview, nftUrl, collection, title, id, tokenId, nftSales, royalty } = useSelector(
        state => state.collectible.data
    )
    const profile = useSelector(state => state.user?.profile)
    const { MARKET_FEE } = useSelector(state => state.system)
    const [isCheckTerm, setIsCheckTerm] = useState(false)
    const { walletAddress } = useSelector(state => state.user?.profile)
    const [isEnoughBalance, setIsEnoughBalance] = useState(false)
    const [isApproving, setIsApproving] = useState(false)
    const [isTransferring, setIsTransferring] = useState(false)
    const [userBalance, setUserBalance] = useState()
    const [isUserApproveTradingToken, setIsUserApproveTradingToken] = useState()
    const [form] = Form.useForm()

    const isSwitchingNetwork = useRef(false);

    const nftSale = useMemo(() => {
        if (nftSales && nftSales[0]) {
            setIsUserApproveTradingToken(
                nftSales[0].currencyToken === PAYMENT_TOKEN.BNB ||
                nftSales[0].currencyToken === PAYMENT_TOKEN.ETH ||
                nftSales[0].currencyToken === PAYMENT_TOKEN.MATIC
            )
            return nftSales[0]
        }
    }, [nftSales])

    const marketFee = useMemo(() => {
        if (nftSale) {
            return nftSale?.signatureSale?.takerRelayerFee
                ? Number(nftSale.signatureSale?.takerRelayerFee) / 10000
                : MARKET_FEE
        }
    }, [MARKET_FEE, nftSale])

    const onCheckTerm = () => {
        setIsCheckTerm(!isCheckTerm)
    }

    const transfer = async () => {
        setIsTransferring(true)

        const [isOrderCanMatch, error] = await handleOrderCanMatch(
            {
                ...nftSale.signatureSale
            },
            chainId
        )
        if (!isOrderCanMatch || error) {
            setIsTransferring(false)
            onCloseModal()
            return message.error(t('error.message.failedToMatchOrder'))
        }

        const [resultAtomicMatch, errorAtomicMatch] = await handleAtomicMatch(
            {
                ...nftSale.signatureSale
            },
            chainId
        )
        if (resultAtomicMatch) {
            setIsTransferring(false)
            setIsDoneProcessing(true);
            onOpenProcessModal(resultAtomicMatch?.hash)
        }
        
        if (errorAtomicMatch) {
            setIsTransferring(false)
            if (errorAtomicMatch?.code === ERROR_CODE_USER_DENIED_METAMASK) {
                message.error(t('error.message.declinedActionWallet'))
            } else {
                message.error(t('error.message.cannotContinueAction'))
            }
            onCloseModal()
            return
        }
    }

    const onClickUserApprove = async () => {
        setIsApproving(true)
        const [approveRes, approveError] = await handleUserApproveERC20(
            CONTRACT_ADDRESS[collection.networkType].proxy,
            CONTRACT_ADDRESS[collection.networkType][nftSale.currencyToken],
            chainId,
            collection.networkType
        )
        if (approveRes) {
            setIsUserApproveTradingToken(true)
        }
        if (approveError) {
            if (approveError?.code === ERROR_CODE_USER_DENIED_METAMASK) {
                onCloseModal()
                message.error(t('error.message.declinedActionWallet'))
            }
        }
        setIsApproving(false)
    }

    const getDisplayImage = () => {
        const fileExtension = exportFileFormat(nftUrl)
        if (fileExtension === FILE_EXTENSION.MP4 || fileExtension === FILE_EXTENSION.MPEG)
            return convertImage(nftImagePreview)
        return convertImage(nftUrl)
    }

    useEffect(() => {
        const checkBalance = async walletAddress => {
            let balance
            if (
                nftSale.currencyToken === PAYMENT_TOKEN.BNB ||
                nftSale.currencyToken === PAYMENT_TOKEN.ETH ||
                nftSale.currencyToken === PAYMENT_TOKEN.MATIC
            ) {
                balance = await getBalance(walletAddress, chainId);
            } else {
                const [xtrToken] = await getERC20AmountBalance(
                    CONTRACT_ADDRESS[collection.networkType][nftSale.currencyToken],
                    walletAddress,
                    chainId,
                    collection.networkType
                )
                balance = convertBigNumberValueToNumber(
                    xtrToken,
                    DECIMAL_TOKENS[collection.networkType][nftSale.currencyToken]
                )
            }

            setUserBalance(balance)
            if (balance >= parseFloat(nftSale.price * (1 + MARKET_FEE))) {
                setIsEnoughBalance(true)
            }
        }
        if (walletAddress && isOpen) {
            checkBalance(walletAddress)
        }
    }, [walletAddress, isOpen, collection.networkType, nftSale?.price, nftSale?.currencyToken, chainId, MARKET_FEE])

    useEffect(() => {
        if (!isOpen) return
        const checkApprove = async () => {
            if (NETWORK_ID_TYPE[chainId] !== collection.networkType && !isSwitchingNetwork.current) {
                isSwitchingNetwork.current = true;
                await changeNetwork(collection.networkType)
                isSwitchingNetwork.current = false;
            }
            if (
                profile?.walletAddress &&
                nftSale.currencyToken !== PAYMENT_TOKEN.BNB &&
                nftSale.currencyToken !== PAYMENT_TOKEN.ETH &&
                nftSale.currencyToken !== PAYMENT_TOKEN.MATIC
            ) {
                const isApproved = await isUserApprovedERC20(
                    CONTRACT_ADDRESS[collection.networkType].proxy,
                    CONTRACT_ADDRESS[collection.networkType][nftSale.currencyToken],
                    profile?.walletAddress,
                    chainId,
                    collection.networkType
                )
                setIsUserApproveTradingToken(!!isApproved)
            }
        }

        checkApprove()
    }, [profile, nftSale?.currencyToken, collection.networkType, chainId, isOpen])

    useEffect(() => {
        if (socket) {
            socket.on(SOCKET_EVENTS.TRANSFER_NFT_SUCCESS, res => {
                if (
                    (res?.data?.toUserId === profile?.id || res?.data?.fromUserId === profile?.id) &&
                    res?.data?.nftId === id
                ) {
                    onCloseProcessModal()
                }
                return () => socket.off(SOCKET_EVENTS.TRANSFER_NFT_SUCCESS)
            })
        }
    }, [profile?.id, id, onCloseProcessModal])

    return (
        <Modal
            visible={isOpen}
            onOk={onCloseModal}
            onCancel={!isTransferring && !isApproving ? onCloseModal : () => { }}
            footer={null}
            closable={false}
            className={'custom-buy-modal'}
        >
            <Form form={form}>
                <div className="buy-modal-container">
                    <p className="checkout-title">{t('nftDetail.completeCheckout')}</p>
                    <Col className="checkout-detail">
                        <div className="checkout-detail__header">
                            <p className="bold-text">{t('nftDetail.item')}</p>

                            <p>
                                {`${t('common.walletBalance')}: ${!isNaN(userBalance)
                                    ? `${roundNumber(userBalance)} ${nftSale.currencyToken.toUpperCase()}`
                                    : t('common.loading')
                                    }`}
                            </p>
                        </div>

                        {width >= 576 ? (
                            <Row align="center" justify="space-between">
                                <div className="nft-info">
                                    {title ? (
                                        <img alt="asset" src={getDisplayImage()} className="asset-img" />
                                    ) : (
                                        <div className="loading-img-placeholder">
                                            <SvgIcon name="loading-icon" className="loading-icon" />
                                        </div>
                                    )}
                                    <div>
                                        <p className="collection-text">
                                            <span>{`${t('nftDetail.collection')} `}</span>
                                            <Link
                                                to={`/collection/${collection?.shortUrl || collection?.id || collection?.address
                                                    }`}
                                            >
                                                {collection?.name}
                                            </Link>
                                        </p>
                                        <p className="nft-info-name">{`${title}`}</p>
                                        <p className="royalty-display">
                                            <span className="text">{t('nftDetail.royalties')}:</span>
                                            <span className="royalty">{` ${parseFloat(
                                                ((Number(royalty) || Number(collection.royalty)) * 1).toFixed(2)
                                            )}%`}</span>
                                            <Tooltip title={t('nftDetail.royalties')} overlayClassName="custom-tooltip">
                                                <i className="ri-error-warning-line"></i>
                                            </Tooltip>
                                        </p>
                                    </div>
                                </div>
                                <div>
                                    <p>{`${BigNumber(nftSale?.price * 1)
                                        .decimalPlaces(6)
                                        .toFormat()
                                        .toString()} ${token}`}</p>
                                </div>
                            </Row>
                        ) : (
                            <div className="nft-info-mobile">
                                {nftUrl || nftImagePreview ? (
                                    <img alt="asset" src={getDisplayImage()} className="asset-img" />
                                ) : (
                                    <img alt="icon" src={DefaultImage} />
                                )}
                                <div>
                                    <p>{title}</p>
                                    <div>
                                        <p className="collection-text">
                                            <span>{`${t('nftDetail.collection')} `}</span>
                                            <Link
                                                to={`/collection/${collection?.shortUrl || collection?.id || collection?.address
                                                    }`}
                                            >
                                                {collection?.name}
                                            </Link>
                                            <p className="royalty-display">
                                                <span className="text">{t('common.royalties')}:</span>
                                                <span className="royalty">{` ${parseFloat(
                                                    ((Number(royalty) || Number(collection.royalty)) * 1).toFixed(2)
                                                )}%`}</span>
                                                <Tooltip
                                                    title={t('nftDetail.royalties')}
                                                    overlayClassName="custom-tooltip"
                                                >
                                                    <i className="ri-error-warning-line"></i>
                                                </Tooltip>
                                            </p>
                                        </p>
                                        <p>
                                            <span>{`${BigNumber(nftSale.price * 1)
                                                .decimalPlaces(6)
                                                .toFormat()
                                                .toString()} ${token}`}</span>
                                        </p>
                                    </div>
                                </div>
                            </div>
                        )}

                        <Row align="center" justify="space-between" className="border-top">
                            <p className="light-text">{t('nftDetail.subtotal')}</p>
                            <div>
                                <p className="light-text">
                                    {`${BigNumber(nftSale?.price || 0)
                                        .decimalPlaces(8)
                                        .toFormat()
                                        .toString()} ${token}`}
                                </p>
                            </div>
                        </Row>
                        <Row align="center" justify="space-between">
                            <p className="light-text">{t('nftDetail.serviceFee')}</p>
                            <div>
                                <p className="light-text">{`${BigNumber(Number(nftSale?.price) * marketFee)
                                    .decimalPlaces(8)
                                    .toFormat()
                                    .toString()} ${token}`}</p>
                            </div>
                        </Row>
                        <Row align="center" justify="space-between">
                            <p className="bold-text">{t('nftDetail.total')}</p>
                            <div>
                                <p>{`${BigNumber(Number(nftSale?.price) + Number(nftSale?.price) * marketFee)
                                    .decimalPlaces(8)
                                    .toFormat()
                                    .toString()} ${token}`}</p>
                            </div>
                        </Row>
                        <Row align="center" justify="space-between">
                            <div className="term-of-service">
                                <Checkbox checked={isCheckTerm} onChange={onCheckTerm} />
                                <span className="term-of-service-text">
                                    <span>{t('nftDetail.agree')}</span>
                                    <a href={`https://smapocke.com/TermsofUse`} target="_blank" rel="noreferrer">
                                        {t(`nftDetail.termOfService`)}
                                    </a>
                                </span>
                            </div>
                        </Row>
                    </Col>
                    {userBalance !== undefined && !isEnoughBalance && isUserApproveTradingToken && (
                        <p className="error-balance">{t('error.message.notEnoughBalance')}</p>
                    )}

                    {isUserApproveTradingToken ? (
                        <ButtonCustom
                            color="blue"
                            disabled={!isCheckTerm || !isEnoughBalance || isTransferring}
                            onClick={transfer}
                            loading={isTransferring}
                        >
                            <span>{t('nftDetail.checkout')}</span>
                        </ButtonCustom>
                    ) : (
                        <ButtonCustom
                            disabled={isApproving || !isCheckTerm}
                            loading={isApproving}
                            color="blue"
                            onClick={onClickUserApprove}
                        >
                            <span>{isApproving ? t('common.waitingForApprove') : t('common.approve')}</span>
                        </ButtonCustom>
                    )}
                </div>
            </Form>
        </Modal>
    )
}

export default BuyModal
