import Request from '../request'
import { SERVER_API_ENDPOINT } from 'constants/envs'

const notificationService = {
    getNotification: async ({ page = 1, limit = 10 }, walletAddress) => {
        try {
            const response = await Request.get(`${SERVER_API_ENDPOINT}/notification`, {
                page,
                limit,
                walletAddress,
                isNotification: 1
            })
            return [response.data, null]
        } catch (error) {
            console.error('getNotification', error.response)
            return [null, error]
        }
    },

    getCountNotification: async () => {
        try {
            const response = await Request.get(`${SERVER_API_ENDPOINT}/notification/get-count-notification`)
            return [response.data, null]
        } catch (error) {
            console.error('getCountNotification', error.response)
            return [null, error]
        }
    },
    updateNotification: async () => {
        try {
            const response = await Request.put(`${SERVER_API_ENDPOINT}/activity/update`)
            return [response.data, null]
        } catch (error) {
            console.error('updateNotification', error.response)
            return [null, error]
        }
    },
    readNotification: async id => {
        try {
            const response = await Request.put(`${SERVER_API_ENDPOINT}/notification/${id}`)
            return [response.data, null]
        } catch (error) {
            console.error('readNotification', error.response)
            return [null, error]
        }
    },
    readAllNotification: async () => {
        try {
            const response = await Request.put(`${SERVER_API_ENDPOINT}/notification/mark-read`)
            return [response.data, null]
        } catch (error) {
            console.error('readAllNotification', error.response)
            return [null, error]
        }
    }
}

export default notificationService
