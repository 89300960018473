import React from 'react'
import { Tooltip } from 'antd'
import CustomVerifyIcon from 'components/common/custom-verify-icon'
import { COLLECTIBLE_CODE } from 'constants/index'
import { convertImageAvatar } from 'utils/image'
import './style.scss'
import { useTranslation } from 'react-i18next'

const CreateCollectionSelected = ({
    onCreateCollection,
    onChange,
    value,
    listOfCollections,
    collectibleType,
    networkType
}) => {
    const { t } = useTranslation();
    const filteredCollection = listOfCollections.filter(
        item => item.type === COLLECTIBLE_CODE[collectibleType] && item.networkType === networkType
    )

    return (
        <div className="create-nft-collections">
            <div className="create-nft-collections_item create-nft-collections_item-bg" onClick={onCreateCollection}>
                <div className="create-nft-collections_item__icon">
                    <i className="ri-add-line" />
                </div>
                <span className="create-nft-collections_item__title new-collection-title">
                    {t('createNFT.newCollectionTitle', { type: COLLECTIBLE_CODE[collectibleType] })}
                </span>
            </div>

            {filteredCollection.map(item => (
                    <div
                        key={item.id}
                        onClick={() => onChange(item)}
                        className={`create-nft-collections_item ${value === item.id ? 'active' : ''}`}
                    >
                        <img
                            alt="collection"
                            className="create-nft-collections_item__icon"
                            src={convertImageAvatar(item?.bannerUrl)}
                        />
                        {item?.isVerify ? <CustomVerifyIcon className="verify-icon" /> : null}
                        <Tooltip title={item?.title || item?.name}>
                            <span className="create-nft-collections_item__title">{item?.title || item?.name}</span>
                        </Tooltip>
                    </div>
                ))}
        </div>
    )
}

export default CreateCollectionSelected
