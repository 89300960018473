import React from 'react'
import { Modal } from 'antd'
import { ButtonCustom } from 'components/common'
import './style.scss'
import { useTranslation } from 'react-i18next'

const CancelModal = ({ onConfirm, onCloseModal, isCanceling }) => {
    const { t } = useTranslation();

    return (
        <Modal
            visible={true}
            onOk={!isCanceling && onCloseModal}
            onCancel={!isCanceling && onCloseModal}
            footer={null}
            centered
            closable={false}
            className="custom-process-modal"
        >
            <div className="cancel-sale">
                <p>{t("nftDetail.cancelSaleModal.title")}</p>
                <p className="cancel-fixed-price-text">
                    {t("nftDetail.cancelSaleModal.desc")}
                </p>
                <ButtonCustom
                    disabled={isCanceling}
                    fullWidth
                    color="blue"
                    onClick={onCloseModal}
                    className="btn-never"
                >
                    <span>{t("nftDetail.never")}</span>
                </ButtonCustom>
                <ButtonCustom fullWidth color="white" onClick={onConfirm} loading={isCanceling}>
                    <span>{t("nftDetail.cancelOpenOffer")}</span>
                </ButtonCustom>
            </div>
        </Modal>
    )
}

export default CancelModal
