import React, { useState, useEffect, useMemo, useCallback, useContext } from 'react'
import { useDispatch } from 'react-redux'
import { Row, Col, Skeleton, Dropdown, Menu } from 'antd'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import classNames from 'classnames'
import moment from 'moment'
import Modal from 'antd/lib/modal/Modal'

import { ButtonCustom, InputCustom, ShareCustom } from 'components/common'
import { SvgIcon } from 'components/modules'
import Auction from './Auction'
import FixedPrice from './FixedPrice'
import NotForSale from './NotForSale'
import NotForSaleMultiple from './NotForSaleMultiple/index'
import OpenForPid from './OpenForPid'
import { SALE_TYPE, FILE_EXTENSION, NFT_TYPE } from 'constants/index'
import * as message from 'utils/custom-message'
import { getPrice } from 'service/getPrice'
import { isUserAuthenticated } from 'utils/auth'
import { VideoPlayer } from 'components/common/dragger/components'
import nftService from 'service/nftService'
import { useAuth } from 'hooks/useAuth'
import Owner from './Owner'
import { getNFTDetail, gotoSalesTab } from 'store/actions/nft'
import { AWS_CLOUDFRONT_ENDPOINT, NETWORKS } from 'constants/envs'
import ReportModal from './ReportModal'
import { convertImage } from 'utils/image'
import { exportFileFormat } from 'utils/file'
import { ThemeContext } from '../../../../ThemeProvider'
import './style.scss'
import { updateDescriptionSuccess } from 'store/collectible.slice'

import LoadingIcon from 'assets/icons/loading-icon.svg'
import UnlockableIcon from 'assets/icons/unlockable-icon.svg'
import { ReactComponent as FlagIcon } from 'assets/icons/flag-icon.svg'

const MAX_DESCRIPTION_LENGTH = 1000

const Overview = props => {
    const { scrollToTab } = props
    const { t } = useTranslation()
    const dispatch = useDispatch()
    const userProfile = useSelector(state => state.user)
    const {
        id,
        title,
        description,
        view,
        likes,
        liked,
        categories,
        owners,
        creator,
        maxQuantity,
        nftImagePreview,
        nftUrl,
        nftSales,
        tokenId,
        isUnlockableContent,
        bestBidSale = 0,
        bestFixPrice,
        bestOfferSale,
        minFixPrice
    } = useSelector(state => state.collectible.data)

    const a = useSelector(state => state.collectible.data)
    const categoriesMapped = useSelector(state => state.categories.data)
    const collection = useSelector(state => state.collectible?.data.collection)
    const [, handleSignWallet] = useAuth()
    const { profile } = useSelector(state => state.user)
    const history = useHistory()
    const [visibleUnlockableModal, setVisibleUnlockableModal] = useState(false)
    const [viewCount, setViewCount] = useState(view || 0)
    const [likeCount, setLikeCount] = useState(likes || 0)
    const [exchangeRate, setExchangeRate] = useState(0)
    const [isLiked, setIsLiked] = useState(false)
    const [isAuctionEnded, setIsAuctionEnded] = useState(false)
    const [isLiking, setIsLiking] = useState(false)
    const [unlockableContent, setUnlockableContent] = useState('')
    const [isZoomImage, setIsZoomImage] = useState(false)
    const [scaleImage, setScaleImage] = useState()
    const [isImageLoaded, setIsImageLoaded] = useState(false)
    const [showMore, setShowMore] = useState(true)
    const [isEditDescription, setIsEditDescription] = useState(false)
    const [openReportModal, setOpenReportModal] = useState(false)
    const [descriptionValue, setDescriptionValue] = useState('')
    const { theme } = useContext(ThemeContext)
    const toggleShowMore = () => {
        setShowMore(!showMore)
    }

    const isOwner = useMemo(
        () => owners && !![...owners].find(ownerNft => ownerNft.user.id === profile?.id),
        [owners, profile?.id]
    )

    const owner = useMemo(() => {
        if (!owners) return
        return [...owners].find(ownerNft => ownerNft.user.id === profile?.id)
    }, [owners, profile?.id])

    const totalSale = useMemo(() => {
        if (!nftSales) return
        return nftSales?.reduce((prev, cur) => prev + cur.quantity, 0)
    }, [nftSales])

    const nftSale = useMemo(() => {
        if (nftSales && nftSales[0]) {
            const getRate = async () => {
                const price = await getPrice(nftSales[0].currencyToken)
                setExchangeRate(price)
            }
            getRate()
            return nftSales[0]
        }
    }, [nftSales])

    const likeNft = async () => {
        setIsLiking(true)
        const [res, err] = await nftService.likeNft({ id })
        setIsLiking(false)
        if (err) {
            return message.error(err.message)
        }
        setIsLiked(!isLiked)
        return setLikeCount(res.totalLikes)
    }

    const onClickLike = async () => {
        if (!profile?.id) {
            return history.push({ pathname: '/connect', state: { from: 'collectible' } })
        }
        if (!isUserAuthenticated()) {
            const res = await handleSignWallet()
            if (res === false) return
        }
        await likeNft()
    }
    const onClickCategory = category => {
        history.push({
            pathname: `/explore/` + category
        })
    }

    const displayNFTImage = useMemo(() => {
        const fileExtension = exportFileFormat(nftUrl)

        if (fileExtension === FILE_EXTENSION.MP4) {
            return (
                <>
                    {nftUrl ? (
                        <div className="video-display">
                            <VideoPlayer src={`${AWS_CLOUDFRONT_ENDPOINT}/${nftUrl}`} />
                        </div>
                    ) : (
                        <div className="loading-img-placeholder">
                            <img src={LoadingIcon} alt="LoadingIcon" className="custom-loading-icon" />
                        </div>
                    )}
                </>
            )
        } else if (fileExtension === FILE_EXTENSION.MPEG) {
            return (
                <>
                    <div
                        className={classNames({
                            'audio-display': true,
                            'vertical-scale': scaleImage === 0 && isImageLoaded,
                            'horizontal-scale': scaleImage === 1 && isImageLoaded
                        })}
                    >
                        {nftImagePreview ? (
                            <img
                                alt="audio-preview"
                                src={convertImage(nftImagePreview)}
                                onLoad={({ target: img }) => {
                                    img.naturalWidth / 652 >= img.naturalHeight / 493
                                        ? setScaleImage(1)
                                        : setScaleImage(0)
                                    setIsImageLoaded(true)
                                }}
                            />
                        ) : (
                            <></>
                        )}
                        <VideoPlayer src={`${AWS_CLOUDFRONT_ENDPOINT}/${nftUrl}`} />
                    </div>
                </>
            )
        } else {
            return (
                <>
                    {title ? (
                        <img
                            alt="asset"
                            src={convertImage(nftImagePreview || nftUrl)}
                            className={classNames({
                                'asset-img': true,
                                'vertical-scale': scaleImage === 0 && isImageLoaded,
                                'horizontal-scale': scaleImage === 1 && isImageLoaded
                            })}
                            onClick={() => setIsZoomImage(true)}
                            onLoad={({ target: img }) => {
                                img.naturalWidth / 652 >= img.naturalHeight / 493 ? setScaleImage(1) : setScaleImage(0)
                                setIsImageLoaded(true)
                            }}
                        />
                    ) : (
                        <div className="loading-img-placeholder">
                            <img src={LoadingIcon} alt="LoadingIcon" className="custom-loading-icon" />
                        </div>
                    )}
                </>
            )
        }
    }, [nftUrl, nftImagePreview, isImageLoaded, scaleImage])

    useEffect(() => {
        setLikeCount(likes)
        setIsLiked(liked)
    }, [likes, liked])

    useEffect(() => {
        setDescriptionValue(description)
    }, [description])

    useEffect(() => {
        if (!Number(nftSale?.expireDate)) return
        const checkExpired = setInterval(() => {
            setIsAuctionEnded(moment().unix() >= nftSale?.expireDate)
            if (moment().unix() + 10 >= nftSale?.expireDate) {
                dispatch(getNFTDetail({ collectionAddress: collection.id || collection.address, tokenId }))
            }
        }, 1000)
        return () => clearInterval(checkExpired)
    }, [nftSale?.expireDate, collection.address, collection.id, tokenId, dispatch])

    const showUnlockableContent = async () => {
        if (isOwner) {
            if (!isUserAuthenticated()) {
                const res = await handleSignWallet()
                if (res === false) return
            }
            if (unlockableContent === '') getUnlockableContent(id)
        }
        setVisibleUnlockableModal(true)
    }

    const handleUnlockableModalCancel = () => {
        setVisibleUnlockableModal(false)
    }

    const getUnlockableContent = async id => {
        const [response, err] = await nftService.getUnlockContent(id)
        if (response) setUnlockableContent(response?.unlockableContent || '')
        else if (err) {
            setUnlockableContent('')
            message.error(t('error.message.somethingWentWrong'))
        }
    }

    const gotoSales = useCallback(() => {
        dispatch(gotoSalesTab({ value: true }))
        scrollToTab()
    }, [dispatch, scrollToTab])

    const handleReportModalCancel = () => {
        setOpenReportModal(false)
    }

    const handleInputDescription = e => {
        setDescriptionValue(e.target.value)
    }

    const handleEditDescription = useCallback(async () => {
        const [data, err] = await nftService.updateNft({ id, description: descriptionValue })
        dispatch(getNFTDetail({ collectionAddress: collection.id || collection.address, tokenId }))
        if (data) {
            dispatch(updateDescriptionSuccess(data?.description));
            message.success('Edit description success!')
            setIsEditDescription(false)
            return
        }
        if (err) {
            message.error(t('error.message.somethingWentWrong'))
            return
        }
    }, [descriptionValue, dispatch, id, collection.address, collection.id, tokenId])

    const handleOpenEditDescription = useCallback(async () => {
        if (!isUserAuthenticated()) {
            const response = await handleSignWallet()
            if (response === false) return
        }
        setIsEditDescription(true)
    }, [handleSignWallet])

    const viewProofOfAuthen = useMemo(
        () => (
            <Menu className="menu-view-proof">
                <Menu.Item>
                    <a
                        target="_blank"
                        href={`${NETWORKS[collection.networkType].blockExplorerUrls[0]}/address/${collection?.address}`}
                        rel="noreferrer"
                    >
                        {t('nftDetail.viewOnExplorer')} <i className="ri-external-link-line color_brand" />
                    </a>
                </Menu.Item>
            </Menu>
        ),
        [collection?.address, collection.networkType]
    )

    return (
        <div className="overview">
            <div className="left-container">
                <div className="nft-image">{displayNFTImage}</div>
            </div>

            <div className="right-container">
                {title ? (
                    <div className={`asset-detail ${theme === 'dark-theme' ? 'dark-asset-detail' : ''}`}>
                        <div className="action">
                            {/* <div
                                className='refresh-btn tag cursor-pointer'

                            >
                                <i className="ri-loop-left-fill"></i>
                            </div>
                            <div className='tag'>
                                <i className="ri-eye-line"></i>
                                {viewCount}
                            </div> */}
                            <ButtonCustom
                                className='tag'
                                color="white"
                                onClick={onClickLike}
                                disabled={isLiking}
                                iconLeft={isLiked ? <i className="ri-heart-fill"></i> : <i className="ri-heart-line"></i>}
                            >
                                <span>{likeCount}</span>
                            </ButtonCustom>
                            <div className="share-icon-container">
                                <ShareCustom
                                    title={t('common.share')}
                                    content={`Look what I found! ${title} collectible ${window.location.href}  #ethereum #nonfungible  #nft`}
                                    url={window.location.href}
                                    horizontalMode
                                />
                            </div>
                            <div className="report-icon-container cursor-pointer" onClick={() => setOpenReportModal(true)}>
                                <FlagIcon />
                                <span className="text">{t('nftDetail.report')}</span>
                            </div>
                        </div>
                        <div className="detail-label">
                            <p className="detail-name">{title}</p>
                        </div>
                        {isUnlockableContent && (
                            <div className="unlockable-content">
                                <ButtonCustom
                                    className="unlockable-btn"
                                    color="white"
                                    iconLeft={<img src={UnlockableIcon} alt="unlockable icon" />}
                                    onClick={showUnlockableContent}
                                >
                                    {t('nftDetail.includesUnlockable')}
                                </ButtonCustom>
                            </div>
                        )}

                        {nftSale &&
                            nftSale?.type === SALE_TYPE.OPEN_FOR_OFFER &&
                            collection.type === NFT_TYPE.ERC721 && (
                                <OpenForPid isOwner={isOwner} signWallet={handleSignWallet} />
                            )}
                        {nftSale && nftSale?.type === SALE_TYPE.FIX_PRICE && collection.type === NFT_TYPE.ERC721 && (
                            <FixedPrice
                                isOwner={isOwner}
                                isSelling={!!nftSale}
                                owner={owner}
                                exchangeRate={exchangeRate}
                                signWallet={handleSignWallet}
                            />
                        )}

                        {nftSale &&
                            nftSale?.type === SALE_TYPE.ENGLISH_AUCTION &&
                            collection.type === NFT_TYPE.ERC721 ? (
                            isAuctionEnded ? (
                                <NotForSale isOwner={isOwner} signWallet={handleSignWallet} />
                            ) : (
                                <Auction isOwner={isOwner} signWallet={handleSignWallet} />
                            )
                        ) : null}

                        {!nftSale && collection.type === NFT_TYPE.ERC721 && (
                            <NotForSale isOwner={isOwner} signWallet={handleSignWallet} />
                        )}

                        {isOwner &&
                            owner?.offSaleAmount > 0 &&
                            owner?.remainAmount > 0 &&
                            owner?.offSaleAmount === owner?.remainAmount &&
                            collection.type === NFT_TYPE.ERC1155 ? (
                            <NotForSaleMultiple owner={owner} signWallet={handleSignWallet} />
                        ) : null}

                        {nftSale && collection.type === NFT_TYPE.ERC1155 && (
                            <div className="action-buy">
                                <ButtonCustom size="lg" className="action-btn br-12" onClick={gotoSales} color="blue">
                                    {t('nftDetail.goToSales')}
                                </ButtonCustom>
                            </div>
                        )}
                        <div className='price-info-container'>
                            <div className='price-info-container__box'>
                                <span className='price-title'>{t('nftDetail.floorPrice')}</span>
                                <span className='price'>
                                    <strong>{minFixPrice ?? "--"}</strong>
                                    $
                                </span>
                            </div>
                            <div className='price-info-container__box'>
                                <span className='price-title'>{t('nftDetail.lastPrice')}</span>
                                <span className='price'>
                                    <strong>{bestFixPrice?.price ?? "--"}</strong>
                                    &nbsp;
                                    {bestFixPrice ? bestFixPrice?.currencyToken?.toUpperCase() : ''}
                                </span>
                            </div>
                            <div className='price-info-container__box'>
                                <span className='price-title'>{t('nftDetail.topOffer')}</span>
                                <span className='price'>
                                    <strong>{bestBidSale?.price ?? "--"}</strong>
                                    &nbsp;
                                    {bestBidSale ? bestBidSale?.currencyToken?.toUpperCase() : ''}
                                </span>
                            </div>
                        </div>

                        {/* {isEditDescription ? (
                            <>
                                <InputCustom
                                    type='textarea'
                                    classNames={['nft-description']}
                                    value={descriptionValue}
                                    autoSize={true}
                                    onChange={handleInputDescription}
                                    maxLength={MAX_DESCRIPTION_LENGTH}
                                />
                                <div className="action-edit-description">
                                    <ButtonCustom onClick={() => setIsEditDescription(false)}>Cancel</ButtonCustom>
                                    <ButtonCustom onClick={handleEditDescription} color="blue">
                                        {t('common.save')}
                                    </ButtonCustom>
                                </div>
                            </>
                        ) : (
                            <div className="nft-description">
                                {description?.length > 240 ? (
                                    <div>
                                        {showMore ? description.substring(0, 240) + '...' : description}
                                        <span className="expand-text" onClick={toggleShowMore}>
                                            {showMore ? t('collection.readMore') : t('collection.showLess')}
                                        </span>
                                    </div>
                                ) : (
                                    description || t('collection.noDescription')
                                )}
                                {userProfile?.profile?.id && creator?.id && userProfile?.profile?.id === creator?.id && (
                                    <div
                                        className={`btn-edit-description ${theme === 'dark-theme' && 'btn-edit-description-dark'
                                            }`}
                                        onClick={handleOpenEditDescription}
                                    >
                                        <i className="ri-edit-box-line" />
                                    </div>
                                )}
                            </div>
                        )} */}
                        {/* <Owner /> */}
                    </div>
                ) : (
                    <Skeleton active paragraph={{ rows: 10 }} />
                )}
            </div>
            <Modal
                className="unlockable-content-modal"
                centered
                footer={null}
                visible={visibleUnlockableModal}
                onCancel={handleUnlockableModalCancel}
            >
                <div className="unlockable-container">
                    <div className="unlockable-header">{t('nftDetail.unlockableContent')}</div>
                    {isOwner ? (
                        <div className="content-unlockable">{unlockableContent}</div>
                    ) : (
                        <div>
                            <div className="hide-content">
                                ******************************************************************************
                            </div>
                            <div className="copyright">{t('nftDetail.unlockableCopyright')}</div>
                        </div>
                    )}
                    <ButtonCustom className="close-unlockable-btn" color="blue" onClick={handleUnlockableModalCancel}>
                        {t('nftDetail.close')}
                    </ButtonCustom>
                </div>
            </Modal>

            <Modal
                className="custom-zoom-modal"
                footer={null}
                visible={isZoomImage}
                onCancel={() => setIsZoomImage(false)}
            >
                <>
                    {nftImagePreview || nftUrl ? (
                        <img
                            alt="asset"
                            src={convertImage(nftImagePreview || nftUrl)}
                            className="zoom-img"
                            onClick={() => setIsZoomImage(false)}
                        />
                    ) : (
                        <div className="loading-img-placeholder">
                            <img src={LoadingIcon} alt="LoadingIcon" className="custom-loading-icon" />
                        </div>
                    )}
                </>
            </Modal>
            <ReportModal
                visible={openReportModal}
                onCancel={handleReportModalCancel}
                nftId={id}
                collectionId={collection?.id}
            />
        </div>
    )
}

export default Overview
