import { Tooltip } from 'antd'

import { getCustomDiffTime } from 'utils/convertDate'
import { convertImageAvatar } from 'utils/image'
import CustomVerifyIcon from 'components/common/custom-verify-icon'
import React, { useContext } from 'react'
import { ThemeContext } from '../../../../../ThemeProvider'
import '../style.scss'
import { ACTIVITY_STATUS } from 'constants/index'
import NotificationNftImage from './NotificationNftImage'
import { shortenAddress, shortenNameNoti } from 'utils/func'
import { useTranslation } from 'react-i18next'

const NotificationAccept = ({ noty, onClickNoti, type }) => {
    const {t} = useTranslation();
    const { theme } = useContext(ThemeContext)
    const nftHistory = noty?.nftHistory
    const routerChange = () => {
        onClickNoti(noty?.id)
    }
    return (
        <div onClick={routerChange} className="cursor-pointer">
            <div className="d-flex">
                <div className="item-image my-auto">
                    <NotificationNftImage noty={nftHistory} />
                    {noty?.follow?.follower?.isVerify ? <CustomVerifyIcon className="icon-checked" /> : null}
                </div>
                <div className={`my-auto ${theme === 'dark-theme' ? 'dark-my-auto' : ''}`}>
                    <div>
                        <Tooltip placement="topLeft">
                            <span className="text-highlight">
                                {' '}
                                {noty?.follow?.follower?.username
                                    ? shortenNameNoti(noty?.fromUser?.username)
                                    : shortenAddress(noty?.fromUser?.walletAddress, 4)}{' '}
                            </span>
                        </Tooltip>
                        <span className="action">
                            {type === ACTIVITY_STATUS.ACCEPT_OFFER_PURCHASE
                                ? t('header.menu.acceptedYourOffer')
                                : t('header.menu.acceptedYourBid')}
                        </span>
                    </div>
                    <div className="time-noti">{getCustomDiffTime(noty?.createdAt)}</div>
                </div>
            </div>
        </div>
    )
}

export default NotificationAccept
