import axios from 'axios'
import { SERVER_API_ENDPOINT } from 'constants/envs'
import { PAYMENT_TOKEN } from 'constants/index'

export const getPrice = async sym => {
    if (!sym) return '0'
    let symbol = ''

    switch (sym.toLowerCase()) {
        case PAYMENT_TOKEN.BNB:
            symbol = 'binancecoin'
            break
        case PAYMENT_TOKEN.WBNB:
            symbol = 'wbnb'
            break
        case PAYMENT_TOKEN.WETH:
            symbol = 'weth'
            break
        case PAYMENT_TOKEN.ETH:
            symbol = 'ethereum'
            break
        case PAYMENT_TOKEN.MATIC:
            symbol = 'matic-network'
            break
        default:
            symbol = ''
    }

    return new Promise(resolve => {
        try {
            axios.get(`${SERVER_API_ENDPOINT}/coingecko`).then(data => {
                if (data.data[symbol]) {
                    resolve(data.data[symbol].usd)
                    return
                }
                resolve(1)
            })
        } catch (error) {
            resolve(1)
        }
    })
}

// export const getPriceFooter = async () => {
//     return await axios.get(`${SERVER_API_ENDPOINT}/coingecko`)
// }
