import React, { useContext, memo } from 'react'
import PropTypes from 'prop-types'
import { Modal } from 'antd'
import ReactLoading from 'react-loading'
import { useSelector } from 'react-redux'

import { ThemeContext } from '../../../ThemeProvider'
import HeaderNav from './header'
import Footer from './footer'
import './style.scss'
import Sidebar from './sidebar'

const DefaultLayout = ({ children }) => {
    const { theme } = useContext(ThemeContext)
    const globalLoading = useSelector(state => state.system?.loading)

    return (
        <div className="default-layout" id={theme}>
            <HeaderNav />
            <div className="layout-body container">
                <Sidebar />
                <div className="layout-body__content">{children}</div>
            </div>
            <Footer />
            {globalLoading && (
                <Modal closable={false} className="custom-loading-modal" centered footer={null} visible={true}>
                    <ReactLoading type="spinningBubbles" color="#002979" />
                </Modal>
            )}
        </div>
    )
}

DefaultLayout.propTypes = {
    isActiveFooter: PropTypes.bool
}

export default memo(DefaultLayout)
