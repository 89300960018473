import {createAsyncThunk} from '@reduxjs/toolkit';

import userService from 'service/userService'
import {toggleUserExist} from 'store/signup.slice'
import { setUserGetProfileLoading, setUserGetProfileSuccess } from 'store/user.slice';

export const getUserInfoApi = createAsyncThunk('publicUserInfo/getUserInfoApi', async ({
  credentials
}, thunkApi) => {
  const {dispatch} = thunkApi;
  dispatch(setUserGetProfileLoading());

  const [data, error] = await userService.getPublicUserInfo(credentials)

  if (data) {
    dispatch(setUserGetProfileSuccess(data));
    return
  }

  if (error) {
    dispatch(toggleUserExist());
  }
});
