import React, { useMemo, useContext } from 'react'
import { ThemeContext } from '../../ThemeProvider'
import { Collapse } from 'antd'
import parse from 'html-react-parser'
import { useSelector } from 'react-redux'

import DefaultLayout from 'components/layout/default-layout'
import SvgIcon from 'components/modules/svg-icon'
import { MAIN_TITLE } from 'constants/index'
import { Helmet } from 'react-helmet'
import './style.scss'
import { useTranslation } from 'react-i18next'
const { Panel } = Collapse

const CollapseIconLight = props => {
    return <SvgIcon className="collapseIcon" name={props.isActive ? 'arrow-up' : 'arrow-down-blue'} />
}

const CollapseIconDark = props => {
    return <SvgIcon className="collapseIcon" name={props.isActive ? 'arrow-up-white' : 'arrow-down-white'} />
}

const stringToHTML = str => {
    return parse(str)
}

const HowItWorks = () => {
    const { t, i18n } = useTranslation();
    const { theme } = useContext(ThemeContext)
    const { MARKET_FEE } = useSelector(state => state.system)

    const MARKETPLACE = useMemo(
        () => [
            {
                question: t('faq.marketplace.one.question'),
                answer: t('faq.marketplace.one.answer')
            },
            {
                question: t('faq.marketplace.two.question'),
                answer: t('faq.marketplace.two.answer')
            },
            {
                question: t('faq.marketplace.three.question'),
                answer: t('faq.marketplace.three.answer')
            },
            {
                question: t('faq.marketplace.four.question'),
                answer: t('faq.marketplace.four.answer')
            },
            {
                question: t('faq.marketplace.five.question'),
                answer: t('faq.marketplace.five.answer')
            },
            {
                question: t('faq.marketplace.six.question'),
                answer: t('faq.marketplace.six.answer')
            },
            {
                question: t('faq.marketplace.seven.question'),
                answer: t('faq.marketplace.seven.answer')
            },
            {
                question: t('faq.marketplace.eight.question'),
                answer: t('faq.marketplace.eight.answer')
            }
        ],
        [MARKET_FEE, t, i18n.language]
    )

    return (
        <DefaultLayout>
            <Helmet>
                <meta charSet="utf-8" />
                <title>{`${MAIN_TITLE} - How it works`}</title>
                <meta name="description" content={`${MAIN_TITLE} - How it works`} />
            </Helmet>
            <div className="banner">
                <div className="banner-lens">
                    <p className="title" dangerouslySetInnerHTML={{ __html: t('faq.title') }}>
                    </p>
                </div>
            </div>
            <div className="content">
                <div className="box-left">
                    <Collapse
                        accordion
                        bordered={false}
                        ghost
                        expandIcon={theme === 'dark-theme' ? CollapseIconDark : CollapseIconLight}
                        expandIconPosition="right"
                    >
                        {MARKETPLACE.slice(0, 4).map((item, index) => (
                            <Panel key={index} header={item.question} className="custom-panel">
                                {stringToHTML(item.answer)}
                            </Panel>
                        ))}
                    </Collapse>
                </div>
                <div className="box-left">
                    <Collapse
                        accordion
                        bordered={false}
                        ghost
                        expandIcon={theme === 'dark-theme' ? CollapseIconDark : CollapseIconLight}
                        expandIconPosition="right"
                    >
                        {MARKETPLACE.slice(4, 8).map((item, index) => (
                            <Panel key={index} header={item.question} className="custom-panel">
                                {stringToHTML(item.answer)}
                            </Panel>
                        ))}
                    </Collapse>
                </div>
            </div>
        </DefaultLayout>
    )
}

export default HowItWorks
