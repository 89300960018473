import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  type: '',
  message: ''
}

export const alertSlice = createSlice({
  name: 'alert',
  initialState,
  reducers: {
    alertSuccess: (state, data) => {
      state.type = 'success';
      state.message = data.payload;
    },
    alertWarning: (state, data) => {
      state.type = 'warning';
      state.message = data.payload;
    },
    alertFailure: (state, data) => {
      state.type = 'error';
      state.message = data.payload;
    },
    clearAlert: (state) => {
      state.type = '';
      state.message = '';
    },
  }
});

export const { alertSuccess, alertWarning, alertFailure, clearAlert } = alertSlice.actions;

export default alertSlice.reducer;