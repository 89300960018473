import React from 'react'
import { Route, Redirect, useHistory } from 'react-router-dom'

import { useSelector } from 'react-redux'

const PrivateRoute = ({ component: Component, ...rest }) => {
    const { profile } = useSelector(state => state.user)
    const { location } = useHistory()
    const checkLocation = () => {
        if (location.pathname.includes('create')) {
            return '/create'
        } else if (location.pathname.includes('edit')) {
            return '/edit-profile'
        } else if (location.pathname.includes('following')) {
            return '/following'
        } else return '/'
    }
    return (
        <Route
            {...rest}
            render={props =>
                !profile?.walletAddress ? (
                    <Redirect to={{ pathname: '/connect', state: { from: checkLocation() } }} />
                ) : (
                    <Component {...props} />
                )
            }
        />
    )
}

export default PrivateRoute
