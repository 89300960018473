import React, { useEffect, useState, useCallback } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Modal } from 'antd'
import { useChainId } from 'wagmi'

import * as message from 'utils/custom-message'
import { checkApprovedCollection, checkUserHasProxy, signPutDataOnSale } from 'blockchain/utils'
import { getNFTDetail, getTradingHistory, changePageHistory, changePageOffer, getOfferList } from 'store/actions/nft'
import CheckedIcon from 'assets/icons/checked-outline-icon.svg'
import { ERROR_CODE_USER_DENIED_METAMASK, PROCESS_STATUS, SALE_TYPE } from 'constants/index'
import { CONTRACT_ADDRESS } from 'constants/envs'
import saleNftService from 'service/saleNftService'
import './style.scss'
import { useTranslation } from 'react-i18next'

const BidStepModal = ({ onClose, saleData }) => {
    const { t } = useTranslation();
    const dispatch = useDispatch()
    const chainId = useChainId();
    const [isPuttingNftOnSale, setIsPuttingNftOnSale] = useState(PROCESS_STATUS.NOT_RUN)
    const [isCheckingUserHasProxy, setIsCheckingUserHasProxy] = useState(PROCESS_STATUS.NOT_RUN)
    const { collection, tokenId, id } = useSelector(state => state.collectible.data)
    const [isApprovedCollectionStatus, setIsApprovedCollectionStatus] = useState(PROCESS_STATUS.NOT_RUN)
    const { profile } = useSelector(state => state.user)

    const checkApproveCollection = async () => {
        setIsApprovedCollectionStatus(PROCESS_STATUS.PENDING)
        const [, err] = await checkApprovedCollection(
            {
                userAddress: profile.walletAddress,
                registryContractAddress: CONTRACT_ADDRESS[collection.networkType].registry,
                collectionAddress: collection.address
            },
            chainId,
            collection.networkType
        )
        setIsApprovedCollectionStatus(PROCESS_STATUS.FULLFIL)

        if (err) {
            if (err?.code === ERROR_CODE_USER_DENIED_METAMASK) {
                message.error(t('error.message.declinedActionWallet'))
            } else {
                message.error(t('error.message.somethingWentWrong'))
            }
            onClose()
            return false
        }
        return true
    }

    const createAuction = useCallback(async () => {
        if (profile.walletAddress) {
            setIsCheckingUserHasProxy(PROCESS_STATUS.PENDING)
            const [, err] = await checkUserHasProxy(
                CONTRACT_ADDRESS[collection?.networkType].registry,
                profile.walletAddress,
                chainId,
                collection?.networkType
            )
            setIsCheckingUserHasProxy(PROCESS_STATUS.FULLFIL)
            if (err) {
                if (err?.code === ERROR_CODE_USER_DENIED_METAMASK) {
                    message.error(t('error.message.declinedActionWallet'))
                } else {
                    message.error(t('error.message.somethingWentWrong'))
                }
                return onClose()
            }

            if (collection.isImport) {
                const isApproveCollection = await checkApproveCollection()
                if (!isApproveCollection) return
            }

            setIsPuttingNftOnSale(PROCESS_STATUS.PENDING)

            const [putOnSale, error] = await signPutDataOnSale(
                {
                    collectionAddress: collection.address,
                    price: 1,
                    quantity: 1,
                    nftType: collection.type,
                    tokenId,
                    networkType: collection.networkType,
                    isExternalCollection: collection.isImport
                },
                chainId
            )

            if (error) {
                onClose()
                if (error.code === ERROR_CODE_USER_DENIED_METAMASK) {
                    return message.error(t('error.message.declinedActionWallet'))
                }
                return message.error(t('error.message.somethingWentWrong'))
            }
            await saleNftService.putOnSale({
                metadata: putOnSale,
                nftId: id,
                quantity: 1,
                type: SALE_TYPE.OPEN_FOR_OFFER
            })

            setIsPuttingNftOnSale(PROCESS_STATUS.FULLFIL)

            await dispatch(getNFTDetail({ tokenId, collectionAddress: collection.id || collection.address }))
            dispatch(changePageOffer({ value: 1 }))
            dispatch(changePageHistory({ value: 1 }))
            return
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        const listOnSaleAuction = async () => {
            if (saleData) {
                await createAuction(saleData)
                await dispatch(getTradingHistory({ nftId: saleData?.nftId }))
                await dispatch(getOfferList({ nftId: saleData?.nftId }))
                return onClose()
            }
        }
        listOnSaleAuction()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [createAuction, saleData])

    return (
        <Modal className="sale-flow_custom" footer={null} closable={false} keyboard={false} visible={true}>
            <div className="create-nft-flow_header">
                <span className="create-nft-flow_title">{t('createNFT.followStep')}</span>
            </div>
            <div className="create-nft-steps">
                <div className="create-nft-step">
                    <div className="create-nft-loading">
                        {isCheckingUserHasProxy === PROCESS_STATUS.PENDING && (
                            <div className="create-nft-step_loading" />
                        )}
                        {isCheckingUserHasProxy === PROCESS_STATUS.FULLFIL && (
                            <img className="create-nft-step_icon" src={CheckedIcon} alt="checked-outline-icon" />
                        )}
                    </div>
                    <div className="create-nft-step_content">
                        <span className="create-nft-step_content__title">{t('createNFT.step.init.title')}</span>
                        <span className="create-nft-step_content__desc">
                            {t('createNFT.step.init.desc')}
                        </span>
                    </div>
                </div>

                {collection.isImport && (
                    <div className="create-nft-step">
                        <div className="create-nft-loading">
                            {isApprovedCollectionStatus === PROCESS_STATUS.PENDING && (
                                <div className="create-nft-step_loading" />
                            )}
                            {isApprovedCollectionStatus === PROCESS_STATUS.FULLFIL && (
                                <img className="create-nft-step_icon" src={CheckedIcon} alt="checked-outline-icon" />
                            )}
                        </div>
                        <div className="create-nft-step_content">
                            <span className="create-nft-step_content__title">{t('createNFT.step.approve.title')}</span>
                            <span className="create-nft-step_content__desc">
                                {t('createNFT.step.approve.desc')}
                            </span>
                        </div>
                    </div>
                )}

                <div className="create-nft-step">
                    <div className="create-nft-loading">
                        {isPuttingNftOnSale === PROCESS_STATUS.PENDING && <div className="create-nft-step_loading" />}
                        {isPuttingNftOnSale === PROCESS_STATUS.FULLFIL && (
                            <img className="create-nft-step_icon" src={CheckedIcon} alt="checked-outline-icon" />
                        )}
                    </div>
                    <div className="create-nft-step_content">
                        <span className="create-nft-step_content__title">{t('createNFT.listingForSale')}</span>
                        <span className="create-nft-step_content__desc">{t('createNFT.listingCollectibleForSale')}</span>
                    </div>
                </div>
            </div>
        </Modal>
    )
}

export default BidStepModal
