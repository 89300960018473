import React, {
    useEffect,
    useState,
    useRef,
    useCallback,
    useMemo,
    useContext,
} from "react";
import { useParams, useHistory } from "react-router-dom";
import ReactLoading from "react-loading";
import copy from "copy-to-clipboard";
import { useIntersection } from "react-use";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import {
    Avatar,
    Col,
    Row,
    Modal,
    Divider,
    Dropdown,
    Button,
    Tooltip,
    notification,
    Tabs,
} from "antd";
import { Helmet } from "react-helmet";
import _ from "lodash";
import moment from "moment";

import DefaultLayout from "components/layout/default-layout";
import {
    ButtonCustom,
    InputCustom,
    NoData,
    SelectCustom,
    ShareCustom,
    TabsCustom,
    TooltipCustom,
    WalletAddress,
} from "components/common";
import EditSocialLinkModal from "./components/EditSocialLinkModal";
import { SvgIcon, CardItem } from "components/modules";
import { convertImage, convertImageCover } from "utils/image";
import { isUserAuthenticated } from "utils/auth";
import * as message from "utils/custom-message";
import {
    updateCollectionAvatar,
    updateCollectionCover,
} from "store/actions/user";
import collectionService from "service/collectionService";
import { formatWalletAddress, stakingRoundNumber } from "utils/formatNumber";
import nftService from "service/nftService";
import { useAuth } from "hooks/useAuth";
import { MAIN_TITLE, SORT_BY_TWO, SOCIAL_LINK } from "constants/index";
import CustomVerifyIcon from "components/common/custom-verify-icon";
import { useOnClickOutside } from "utils/hook";
import ArrowDown from "assets/icons/arrow-down.svg";
import LightSearchIcon from "assets/icons/search-icon-mobile-blue.svg";
import DarkSearchIcon from "assets/icons/search-icon.svg";
import CopyIcon from "assets/icons/CopyIcon";
import EditIcon from "assets/icons/EditIcon";
import TagIcon from "assets/icons/tag-icon.svg";
import GlobalIcon from "assets/icons/global-icon.svg";
import CalendarIcon from "assets/icons/calendar-icon.svg";
import UserIcon from "assets/icons/user-icon.svg";
import CustomSwitch from "components/common/switch";
import { CustomAvatar } from "components/common";
import { capitalizeLetter, shortenNameNoti } from "utils/func";
import styles from "./style.module.scss";
import "./styles.scss";
import { ThemeContext } from "../../ThemeProvider";
import { NoItemFound } from "pages/profile/components";
import { setCollection } from "store/collection.slice";
import { setGlobalLoading, setCloseGlobalLoading } from "store/system.slice";
import { setUploadCollectionCoverFailure } from "store/user.slice";
import { NETWORKS } from "constants/envs";
import ListViewIcon from "assets/icons/ListViewIcon";
import GridViewIcon from "assets/icons/GridViewIcon";
import ComingSoon from "components/common/coming-soon";

const { TabPane } = TabsCustom;

const COVER_MAX_SIZE = 15728640;
const AVATAR_MAX_SIZE = 5242880;
const AVATAR_SIZE = {
    width: 300,
    height: 300,
};
const COVER_SIZE = {
    width: 1440,
    height: 260,
};
const PROPERTY_MIN_AMOUNT = 0.000001;
const MAX_DESCRIPTION_LENGTH = 1000;
const STEP = 20;

const VIEW_MODE = {
    LIST: "LIST",
    GRID: "GRID",
};

const TABS = {
    ITEMS: "ITEMS",
    OFFERS: "OFFERS",
    ANALYTICS: "ANALYTICS",
    ACTIVITY: "ACTIVITY",
};

const CollectionDetail = () => {
    const { t, i18n } = useTranslation();
    const { theme } = useContext(ThemeContext);
    const [collectionData, setCollectionData] = useState({});

    const [collectionProperties, setCollectionProperties] = useState([{}]);
    const [copyAddress, setCopyAddress] = useState(false);
    const [isCollectionExist, setIsCollectionExist] = useState(true);
    const [err, setError] = useState();
    const [page, setPage] = useState(1);
    const [showMore, setShowMore] = useState(true);
    const { shortUrl } = useParams();
    const uploadFileBtn = useRef();
    const [categoryId, setCategoryId] = useState(-1);
    const [sortBy, setSortBy] = useState(SORT_BY_TWO[0]?.value);
    const categories = useSelector((state) => state.categories?.data || []);
    const [isOpenPropertiesModal, setIsOpenPropertiesModal] = useState(false);
    const [listProperties, setListProperties] = useState([]);
    const [searchedProps, setSearchedProps] = useState([]);
    const [listSelectedProps, setListSelectedProps] = useState([]);
    const [isEditDescription, setIsEditDescription] = useState(false);
    const [description, setDescription] = useState("");
    const [valueShortUrl, setValueShortUrl] = useState("");
    const [verification, setVerification] = useState(false);
    const [loading, setLoading] = useState(false);
    const [isEdit, setIsEdit] = useState(false);
    const [isEditShortUrl, setIsEditShortUrl] = useState(false);
    const [cover, setCover] = useState();
    const [avatarUrl, setAvatarUrl] = useState();
    const [keyword, setKeyword] = useState("");
    const [isUploadCover, setIsUploadCover] = useState(false);
    const [activeTab, setActiveTab] = useState(TABS.ITEMS);
    const [viewMode, setViewMode] = useState(VIEW_MODE.GRID);
    const [originalDetails, setOriginalDetails] = useState({});
    const [previewEditAvatar, setPreviewEditAvatar] = useState("");

    const [isOpenEditSocialLinkModal, setIsOpenEditSocialLinkModal] =
        useState(false);
    const isLoadMore = useMemo(() => {
        return collectionData.nfts?.length < collectionData?.amountAsset;
    }, [collectionData.nfts?.length, collectionData?.amountAsset]);
    const propertyDropdown = useRef(null);
    const buttonPropertyRef = useRef(null);
    const uploadAvatarCollection = useRef(null);
    const anchorEl = useRef(null);
    const intersection = useIntersection(anchorEl, {
        root: null,
        rootMargin: "0px",
        threshold: 1,
    });
    const inView =
        intersection &&
        intersection.isIntersecting &&
        intersection.intersectionRatio >= 1;
    const infinity = inView && isLoadMore;
    const dispatch = useDispatch();
    const history = useHistory();
    const { profile, uploadingCollectionCoverImage } = useSelector(
        (state) => state.user
    );
    const [, handleSignWallet] = useAuth();

    const isEditable =
        collectionData?.creator?.id &&
        profile?.id &&
        profile?.id === collectionData?.creator?.id;

    const socialChannels = useMemo(
        () =>
            collectionData?.socialChannels?.filter(
                (item) => item.name !== "Twitter" && item?.url
            ),
        [collectionData?.socialChannels]
    );

    useEffect(() => {
        getCollectionDetail();
    }, [page]);

    useEffect(() => {
        getCollectionDetail({ isReload: true });
    }, [shortUrl]);

    useEffect(() => {
        if (infinity) {
            handleLoadMore();
        }
    }, [infinity]);
    const handleLoadMore = useCallback(() => {
        if (loading) {
            return;
        }
        const next = page * STEP;
        next < collectionData?.amountAsset && setPage(page + 1);
    }, [loading, page, collectionData?.amountAsset]);

    const handlePropertyClickOutsideDropdown = () => {
        setIsOpenPropertiesModal(false);
    };

    useOnClickOutside(
        propertyDropdown,
        handlePropertyClickOutsideDropdown,
        buttonPropertyRef
    );

    const categoriesConverted = useMemo(() => {
        return [
            { id: -1, label: t("category.allCategories"), code: "all", value: -1 },
            ...categories.map((category) => {
                return {
                    ...category,
                    name: category.icon,
                    nameActive: category.icon,
                    label: t(`category.${category.label}`),
                    value: category.id,
                };
            }),
        ];
    }, [categories, t, i18n.language]);

    const fetchNftProperty = useCallback(async (collectionId) => {
        const [res, err] = await nftService.getNftPropertyCollection({
            collectionId,
        });
        if (res) {
            setListProperties(res);
            setSearchedProps(res);
        }
        if (err) {
        }
    }, []);

    useEffect(() => {
        if (collectionData?.id) {
            fetchNftProperty(collectionData?.id);
        }
    }, [fetchNftProperty, collectionData?.id]);

    const getCollectionDetail = useCallback(
        async (dataProp) => {
            if (shortUrl || dataProp?.shortUrl) {
                dispatch(setGlobalLoading());
                const currentCategoryId = dataProp?.categoryId || categoryId;
                const [response, error] =
                    await collectionService.getInfoCollectionDetail(
                        shortUrl || dataProp?.shortUrl
                    );
                const [responseNFTs, err] =
                    await collectionService.getListNftsCollection({
                        shortUrl: dataProp?.shortUrl || shortUrl,
                        limit: STEP,
                        page: dataProp?.isReload ? 1 : page,
                        properties:
                            dataProp?.properties ||
                            (listSelectedProps?.length > 0 &&
                                listSelectedProps?.map((item) => item?.ids)?.join(",")) ||
                            null,
                        sortField: dataProp?.sortBy || sortBy || SORT_BY_TWO[0]?.value,
                        categoryId: currentCategoryId === -1 ? "" : currentCategoryId,
                        collectionAddress: shortUrl || dataProp?.shortUrl,
                        title: dataProp?.keyword || keyword || "",
                        // verification: verification ? 1 : 0
                    });
                if (response) {
                    const data = responseNFTs?.items;
                    // const [res, err] = await nftService.getCollectionProperties(response?.items[0].collection?.address)
                    // if (res) {
                    //     const data = [
                    //         { amount: res[0], propertyName: 'Items' },
                    //         { amount: res[1], propertyName: 'Owners' },
                    //         { amount: res[2], propertyName: 'Floor Price' },
                    //         { amount: res[3], propertyName: 'Trade Volume' },
                    //         { amount: res[4], propertyName: 'Highest Sale' },
                    //         { amount: res[5], propertyName: 'Market Cap' }
                    //     ]
                    //     setCollectionProperties(data)
                    // } else if (err) {
                    //     setIsCollectionExist(false)
                    //     setError(err)
                    //     dispatch(setCloseGlobalLoading())
                    //     return
                    // }
                    setCover(response?.bannerUrl);
                    setAvatarUrl(response?.thumbnailUrl);
                    setValueShortUrl(response?.shortUrl);
                    setDescription(response?.description);
                    setOriginalDetails({ ...response });
                    dispatch(
                        setCollection({
                            collectionId: response?.id,
                            shortUrl: response?.shortUrl,
                        })
                    );
                    setIsCollectionExist(true);
                    if (dataProp?.isReload || page === 1) {
                        setCollectionData({
                            ...response,
                            nfts: data,
                            meta: responseNFTs.meta,
                        });
                    } else {
                        if (_.isEmpty(collectionData)) {
                            setCollectionData({
                                ...response,
                                nfts: data,
                                meta: responseNFTs.meta,
                            });
                        } else {
                            setCollectionData({
                                ...collectionData,
                                nfts: [...collectionData?.nfts, ...data],
                                meta: responseNFTs.meta,
                            });
                        }
                    }
                } else if (err) {
                    setIsCollectionExist(false);
                    setError(err);
                }
                dispatch(setCloseGlobalLoading());
            }
        },
        [
            categoryId,
            dispatch,
            page,
            shortUrl,
            sortBy,
            verification,
            keyword,
            listSelectedProps,
        ]
    );

    const toggleShowMore = () => {
        setShowMore(!showMore);
    };

    const onClickUpload = () => {
        uploadFileBtn.current.click();
        setIsUploadCover(true);
    };

    const onClickUploadAvatar = () => {
        uploadAvatarCollection.current.click();
        setIsUploadCover(false);
    };

    const validateImage = (extension) => {
        const acceptableFormats = ["jpg", "png", "jpeg", "gif"];
        if (acceptableFormats.includes(extension)) {
            return true;
        }
        return false;
    };

    const onCoverChange = async (e) => {
        if (!e.target.value.length) return;
        const file = e.target.files[0];
        let img = new Image();
        img.src = window.URL.createObjectURL(file);
        if (!file) return;
        img.onload = async () => {
            if (!validateImage(file.type.split("/")[1])) {
                return message.error(t("collection.formatError"));
            }
            if (file?.size > COVER_MAX_SIZE) {
                uploadFileBtn.current.value = "";
                return message.error(t("error.message.fileSizeExceeded", { size: 5 }));
            }
            if (img.width < COVER_SIZE?.width || img.height < COVER_SIZE.height) {
                uploadFileBtn.current.value = "";
                return message.error(
                    t("error.message.fileSizeAtLeast", { width: 1440, height: 260 })
                );
            }
            await handleUploadCoverImage(file);
        };
    };

    const onAvatarChange = async (e) => {
        if (!e.target.value.length) return;
        const file = e.target.files[0];
        let img = new Image();
        img.src = window.URL.createObjectURL(file);
        if (!file) return;
        img.onload = async () => {
            if (!validateImage(file.type.split("/")[1])) {
                return message.error(t("collection.formatError"));
            }
            if (file?.size > AVATAR_MAX_SIZE) {
                uploadFileBtn.current.value = "";
                return message.error(t("error.message.fileSizeExceeded", { size: 5 }));
            }
            if (img.width < AVATAR_SIZE?.width || img.height < AVATAR_SIZE.height) {
                uploadFileBtn.current.value = "";
                return message.error(
                    t("error.message.fileSizeAtLeast", { width: 300, height: 300 })
                );
            }
            await handleUploadCoverImage(file);
        };
    };

    const handleUploadCoverImage = async (file) => {
        if (!isUserAuthenticated()) {
            const res = await handleSignWallet();
            if (res === false) return;
        }
        const uploadParams = {
            userId: profile?.id,
            imgFile: file,
            collectionId: collectionData?.id,
        };
        if (isUploadCover) {

            await dispatch(updateCollectionCover(uploadParams));
            setCover(URL.createObjectURL(file));
            return;
        } else {
            await dispatch(updateCollectionAvatar(uploadParams));
            setAvatarUrl(URL.createObjectURL(file));
            setPreviewEditAvatar(URL.createObjectURL(file));
        }
    };
    useEffect(() => {
        dispatch(setUploadCollectionCoverFailure(err));
    }, []);

    useEffect(() => {
        if (!uploadingCollectionCoverImage) {
            dispatch(setCloseGlobalLoading());
        } else {
            dispatch(setGlobalLoading());
        }
    }, [uploadingCollectionCoverImage])

    const copyAddressToClipboard = () => {
        copy(collectionData?.address || "");
        message.success(t("success.message.copySuccessfully"));
    };

    const handleChangeSort = useCallback(
        (value) => {
            setSortBy(value);
            getCollectionDetail({
                shortUrl,
                categoryId,
                sortBy: value,
                keyword,
                verification,
                isReload: true,
            });
            setPage(1);
        },
        [categoryId, getCollectionDetail, shortUrl, verification, keyword]
    );

    const handleChangeCategory = (value) => {
        setCategoryId(value);
        getCollectionDetail({
            shortUrl,
            categoryId: value,
            sortBy,
            keyword,
            verification,
            isReload: true,
        });
        setPage(1);
    };

    const handleApplyProperties = () => {
        setIsOpenPropertiesModal(false);
        getCollectionDetail({
            shortUrl,
            properties:
                listSelectedProps?.length > 0 &&
                listSelectedProps?.map((item) => item?.ids)?.join(","),
            sortBy,
            categoryId,
            verification,
            isReload: true,
            keyword,
        });
        setPage(1);
    };

    const handleClickProperty = (item) => {
        if (listSelectedProps.includes(item)) {
            setListSelectedProps(
                listSelectedProps.filter(
                    (i) => i.name !== item.name || i.value !== item.value
                )
            );
        } else {
            setListSelectedProps([...listSelectedProps, item]);
        }
    };

    const handleSearchProperties = (e) => {
        setSearchedProps(
            listProperties.filter((item) =>
                item.name.toLowerCase().includes(e.target.value.toLowerCase())
            )
        );
    };

    const handleClearPropertiesFilter = () => {
        setListSelectedProps([]);
        getCollectionDetail({
            shortUrl,
            sortBy,
            categoryId,
            keyword,
            verification,
            isReload: true,
            properties: [],
        });
        setPage(1);
    };

    const handleSaveClick = async (isSaveDescription) => {
        setLoading(true);
        const [res, err] = await collectionService.patchCollection({
            id: collectionData?.id,
            description,
            shortUrl: valueShortUrl || collectionData?.shortUrl,
        });
        setLoading(false);
        if (res) {
            if (isSaveDescription) {
                setIsEditShortUrl(false);
                setValueShortUrl(res?.shortUrl);
                history.push(`/collection/${res?.shortUrl}`);
                getCollectionDetail({ isReload: true });
                setPreviewEditAvatar("");
                setIsEdit(false);
            } else {
                setIsEditDescription(false);
                setDescription(res?.description);
            }
        }

        if (err) {
            if (err.response.data.message === "SHORT_URL_ALREADY_EXISTED") {
                message.error(t("error.message.usernameInUse"));
                return;
            } else if (err.response.data.message === "URL blocked") {
                message.error(t("error.message.urlBlocked"));
                return;
            }
            message.error(t("error.message.somethingWentWrong"));
        }
    };

    const handleClickEditSocialLinks = async () => {
        if (!isUserAuthenticated()) {
            const response = await handleSignWallet();
            if (!response) return;
        }
        setIsOpenEditSocialLinkModal(true);
    };

    const handleClickEditDescription = async () => {
        if (!isUserAuthenticated()) {
            const response = await handleSignWallet();
            if (!response) return;
        }
        setIsEditDescription(true);
    };

    // const handleClickEditShortUrl = async () => {
    //     if (!isUserAuthenticated()) {
    //         const response = await handleSignWallet()
    //         if (!response) return
    //     }
    //     setIsEditShortUrl(true)
    // }

    const handleClickEdit = () => setIsEdit(true);

    const handleInputDescription = (e) => {
        setDescription(e.target.value);
    };

    // const handleChangeShortUrl = e => {
    //     setValueShortUrl(e.target.value)
    // }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const debounceSearch = useCallback(
        _.debounce(async (value) => {
            getCollectionDetail({
                shortUrl,
                categoryId,
                sortBy,
                verification,
                isReload: true,
                keyword: value,
            });
        }, 300),
        [shortUrl, categoryId, sortBy, verification]
    );

    const handleChangeSearch = (e) => {
        setKeyword(e.target.value);
        debounceSearch(e.target.value);
    };

    const handleSearchMobile = useCallback(() => {
        getCollectionDetail({
            shortUrl,
            categoryId,
            sortBy,
            verification,
            isReload: true,
            keyword,
        });
    }, [
        categoryId,
        getCollectionDetail,
        keyword,
        shortUrl,
        sortBy,
        verification,
    ]);

    // const dropdownProperties = useCallback(() => {
    //     return (
    //         <div
    //             className={`dropdown-collection dropdown-explorer   ${theme === 'dark-theme' ? 'dropdown-explore-dark-antd' : ''
    //                 }`}
    //             ref={propertyDropdown}
    //         >
    //             <div className="collection-search-input-holder">
    //                 <InputCustom placeholder={t('explore.searchProperties')} onChange={handleSearchProperties} />
    //             </div>
    //             <div className="collection-holder">
    //                 {searchedProps?.map((property, index) => {
    //                     return (
    //                         <div
    //                             className="property-filter-modal_item"
    //                             key={index}
    //                             onClick={() => handleClickProperty(property)}
    //                         >
    //                             <div className="property-filter-modal_item-left">
    //                                 <div className="property-filter-modal_item-name">{property.name}</div>
    //                                 <div className="property-filter-modal_item-value">{`${property?.value?.slice(
    //                                     0,
    //                                     4
    //                                 )}${property?.value?.length > 4 ? '...' : ''}`}</div>
    //                             </div>
    //                             <div>{listSelectedProps?.includes(property) && <i className="ri-check-line"></i>}</div>
    //                         </div>
    //                     )
    //                 })}
    //                 {searchedProps?.length === 0 && <NoItemFound />}
    //             </div>
    //             <Divider />
    //             <div className="sale-type-filter-button">
    //                 <ButtonCustom className="filter-btn" size="small" onClick={handleClearPropertiesFilter}>
    //                     {t('common.clear')}
    //                 </ButtonCustom>
    //                 <ButtonCustom className="filter-btn" size="small" color="blue" onClick={handleApplyProperties}>
    //                     {t('common.apply')}
    //                 </ButtonCustom>
    //             </div>
    //         </div>
    //     )
    // }, [listSelectedProps, searchedProps, t, i18n.language])

    // const PropRender = props => {
    //     const { property } = props
    //     if (property?.amount < PROPERTY_MIN_AMOUNT) {
    //         return <div className={styles.propertyAmount}>0</div>
    //     }
    //     if (
    //         property?.propertyName === 'Trade Volume' ||
    //         property?.propertyName === 'Floor Price' ||
    //         property?.propertyName === 'Highest Sale' ||
    //         property?.propertyName === 'Market Cap'
    //     ) {
    //         return (
    //             <div className={`${styles.wrapNumber} d-flex`}>
    //                 <div className={styles.propertyAmount}>
    //                     ${property?.amount < 1 ? property?.amount : stakingRoundNumber(property?.amount)}
    //                 </div>
    //             </div>
    //         )
    //     } else {
    //         return <div className={styles.propertyAmount}>{stakingRoundNumber(property?.amount)}</div>
    //     }
    // }

    const handleFilterBy = useCallback(() => {
        setVerification(!verification);
        setPage(1);
    }, [verification]);

    const dropDownSortBy = useCallback(
        () => (
            <div
                className={`dropdown-collection dropdown-explorer dropdown-sortby  ${theme === "dark-theme" ? "dropdown-explore-dark-antd" : ""
                    }`}
            >
                <div className="collection-holder">
                    <div className="filter-title">
                        {t("common.sortAndFilter.sort.title")}
                    </div>
                    {SORT_BY_TWO.map((sort, index) => {
                        return (
                            <div
                                className="collection-item-holder"
                                onClick={() => handleChangeSort(sort?.value)}
                                key={index}
                            >
                                <div className="collection-name">
                                    <div className="collection-name-text-holder">
                                        <span className="collection-name-text">
                                            {t(`common.sortAndFilter.sort.${sort.value}`)}
                                        </span>
                                    </div>
                                </div>
                                <div>
                                    {sortBy === sort.value && <i className="ri-check-line"></i>}
                                </div>
                            </div>
                        );
                    })}
                    <div className="filter-title">
                        {t("common.sortAndFilter.options.title")}
                    </div>
                    <div className="verification-holder">
                        {t("common.sortAndFilter.options.verification")}
                        <CustomSwitch
                            onChange={handleFilterBy}
                            value={verification}
                            disableLabel={true}
                            className="switch-filter"
                        />
                    </div>
                </div>
            </div>
        ),

        [handleChangeSort, handleFilterBy, sortBy]
    );

    // const renderIconSocial = name => {
    //     switch (name) {
    //         case 'Instagram':
    //             return <i className="ri-instagram-line social-icon" />
    //         case 'Medium':
    //             return <i className="ri-medium-line social-icon" />
    //         case 'Personal Site':
    //             return <i className="ri-global-line social-icon"></i>
    //         default:
    //             break
    //     }
    // }

    // const renderLinkSocial = name => {
    //     switch (name) {
    //         case 'Instagram':
    //             return SOCIAL_LINK.INSTAGRAM
    //         case 'Medium':
    //             return SOCIAL_LINK.MEDIUM
    //         default:
    //             break
    //     }
    // }

    const _renderCollectionAvatar = () => (
        <div className="avatar avatar-collection">
            <Avatar
                size={100}
                src={convertImage(avatarUrl, avatarUrl ? false : true)}
                className={styles.avatar}
            />
            {collectionData?.isVerify ? (
                <CustomVerifyIcon className={styles.verify} />
            ) : null}
            {isEdit && (
                <div
                    onClick={onClickUploadAvatar}
                    className="btn-edit-avatar-collection"
                    style={{ backgroundImage: previewEditAvatar ? "" : `url(${convertImage(avatarUrl, avatarUrl ? false : true)})` }}
                >
                    {/* <i className="ri-edit-line"></i> */}
                    {previewEditAvatar && (
                        <Avatar
                            size={100}
                            src={previewEditAvatar}
                            className={styles.avatar}
                        />
                    )}
                    <i className="ri-edit-line"></i>
                </div>
            )}
        </div>
    );

    const _renderCollectionInfo = () => (
        <div className="collection-info">
            <div className="collection-info__section">
                <div className="collection-name">{collectionData?.name}</div>
                <div className="collection-description">
                    {[
                        {
                            title: t("listCollection.floorPrice"),
                            value: (
                                <span>
                                    {collectionData?.floorPrice}{" "}
                                    <span className="sub-value">$</span>
                                </span>
                            ),
                        },
                        {
                            title: t("nftDetail.topOffer"),
                            value: (
                                <span>
                                    {collectionData?.topOffer}{" "}
                                    <span className="sub-value">
                                        {NETWORKS[collectionData?.networkType]?.nativeCurrency.name}
                                    </span>
                                </span>
                            ),
                        },
                        {
                            // TODO: translation
                            title: t("nftDetail.24hVol"),
                            value: (
                                <span>
                                    {collectionData?.volumeTraded24h}{" "}
                                    <span className="sub-value">$</span>
                                </span>
                            ),
                        },
                        {
                            // TODO: translation
                            title: t('collection.allVol'),
                            value: (
                                <span>
                                    {collectionData?.volumeTraded}{" "}
                                    <span className="sub-value">$</span>
                                </span>
                            ),
                        },
                        {
                            // TODO: translation
                            title: t('collection.listed/Supply'),
                            value: (
                                <span>
                                    {collectionData?.amountSale} / {collectionData?.amountAsset}{" "}
                                    <span className="sub-value">
                                        {collectionData?.amountAsset
                                            ? Math.round(
                                                (collectionData?.amountSale /
                                                    collectionData?.amountAsset) *
                                                100,
                                                0
                                            )
                                            : ""}
                                        %
                                    </span>
                                </span>
                            ),
                        },
                        {
                            title: t("listCollection.owners"),
                            value: <span>{collectionData?.amountOwner}</span>,
                        },
                    ].map(({ title, value }) => (
                        <div key={title} className="item">
                            <div>{title}</div>
                            <div>{value}</div>
                        </div>
                    ))}
                </div>
            </div>
            <div className="collection-info__section">
                <div className="collection-tags">
                    <ButtonCustom variant="outline-sub" onClick={copyAddressToClipboard}>
                        <div>{formatWalletAddress(collectionData?.address)}</div>
                        <CopyIcon />
                    </ButtonCustom>
                    <ButtonCustom className="tag-only" variant="outline-sub">
                        <img src={TagIcon} alt="tag-icon" />
                        <div>
                            {t("createNFT.field_category")}:{" "}
                            {collectionData?.categories && collectionData?.categories.length
                                ? collectionData?.categories[0]
                                : "N/A"}
                        </div>
                    </ButtonCustom>
                    <ButtonCustom className="tag-only" variant="outline-sub">
                        <img src={UserIcon} alt="user-icon" />
                        <div>
                            {t('collection.creatorFee')}: {collectionData?.royalty}%
                        </div>
                    </ButtonCustom>
                    <ButtonCustom className="tag-only" variant="outline-sub">
                        <img src={GlobalIcon} alt="global-icon" />
                        <div>
                            {t("collection.chain")}: {capitalizeLetter(collectionData?.networkType)}
                        </div>
                    </ButtonCustom>
                    <ButtonCustom className="tag-only" variant="outline-sub">
                        <img src={CalendarIcon} alt="calendar-icon" />
                        <div>
                            {t("profile.created")}:{" "}
                            {moment(collectionData?.createdAt).format("MMM YYYY")}
                        </div>
                    </ButtonCustom>
                </div>
            </div>
            {isEdit ? (
                <InputCustom
                    type="textarea"
                    classNames={["description-input"]}
                    value={description}
                    autoSize={true}
                    onChange={handleInputDescription}
                    maxLength={MAX_DESCRIPTION_LENGTH}
                />
            ) : (
                <div>
                    {description ? (
                        <div className="desc">
                            {description?.length > 100 ? (
                                <div className="desc-content">
                                    {showMore ? (
                                        description?.substring(0, 60) + "..."
                                    ) : (
                                        <p className="desc-text">{description}</p>
                                    )}
                                    <span className={styles.expandBtn} onClick={toggleShowMore}>
                                        {showMore
                                            ? t("collection.readMore")
                                            : t("collection.showLess")}
                                    </span>
                                </div>
                            ) : (
                                <p className="desc-text">{description}</p>
                            )}
                        </div>
                    ) : null}
                </div>
            )}
        </div>
    );

    const _renderCollectionTabs = () => (
        <div className="collection-tabs">
            <TabsCustom
                defaultActiveKey={TABS.ITEMS}
                onChange={(key) => setActiveTab(key)}
            >
                <TabPane tab={t("search.tab_items")} key={TABS.ITEMS} />
                <TabPane tab={t("nftDetail.offers")} key={TABS.OFFERS} />
                <TabPane tab={t("collection.analytics")} key={TABS.ANALYTICS} />
                <TabPane tab={t("activity.header")} key={TABS.ACTIVITY} />
            </TabsCustom>
        </div>
    );

    const handleCancelEdit = () => {
        setValueShortUrl(originalDetails?.shortUrl);
        setDescription(originalDetails?.description);
        if (previewEditAvatar) {
            setAvatarUrl(previewEditAvatar);
            setPreviewEditAvatar("");
        }
        setIsEdit(false);
    };

    const _renderCollectionButtons = () => (
        <div className="collection-buttons">
            {isEditable && !isEdit && (
                <ButtonCustom variant="outline-sub" onClick={handleClickEdit}>
                    <EditIcon />
                    <div>{t("profile.edit")}</div>
                </ButtonCustom>
            )}
            {isEdit && (
                <>
                    <ButtonCustom color="blue" onClick={() => handleSaveClick(true)}>
                        <div>{t("common.save")}</div>
                    </ButtonCustom>
                    <ButtonCustom variant="outline-sub" onClick={handleCancelEdit}>
                        <div>{t("common.cancel")}</div>
                    </ButtonCustom>
                </>
            )}
            <ShareCustom
                content={window.location.href}
                url={window.location.href}
                horizontalMode={true}
            />
        </div>
    );

    const _renderItemsPanel = () => (
        <>
            <div className="collection-detail-filters container">
                <div className={`search ${styles.searchBox}`}>
                    <i className="searchIcon ri-search-line"></i>
                    <InputCustom
                        className={`search-box ${styles.search}`}
                        placeholder={t("common.search")}
                        value={keyword}
                        onPressEnter={handleSearchMobile}
                        onChange={handleChangeSearch}
                    />
                </div>
                <div className="collection-detail-filters__selects">
                    <SelectCustom
                        value={categoryId}
                        onChange={handleChangeCategory}
                        options={categoriesConverted}
                        className={`all-categories-button ${styles.select} ${styles.select_filter}`}
                        dropdownClassName="select__dropdown--categories"
                        showIconSvg={true}
                    />
                    <SelectCustom
                        className={`all-categories-button ${styles.select} ${styles.select_filter}`}
                        placeholder={t(`common.sortAndFilter.sort.${sortBy}`)}
                        categoryIcon={true}
                        dropdownClassName="all-categories-button__dropdown--filter"
                        dropdownRender={dropDownSortBy}
                    />
                    <Tabs
                        className="view-mode"
                        defaultActiveKey={viewMode}
                        onChange={(key) => setViewMode(key)}
                    >
                        <TabPane tab={<ListViewIcon />} key={VIEW_MODE.LIST} />
                        <TabPane tab={<GridViewIcon />} key={VIEW_MODE.GRID} />
                    </Tabs>
                </div>
            </div>

            {viewMode === VIEW_MODE.GRID ? (
                <div className="container">
                    <Row
                        gutter={[{ xs: 6, sm: 6, md: 20, lg: 20, xl: 20 }, 20]}
                        className={styles.itemList}
                    >
                        {collectionData.nfts?.map((item) => (
                            <Col key={item.id} xxl={6} xl={6} lg={8} md={12} sm={12} xs={24}>
                                <CardItem
                                    verification={collectionData?.isVerify}
                                    className={styles.cardItem}
                                    data={item}
                                />
                            </Col>
                        ))}
                        <div ref={anchorEl}></div>
                    </Row>
                </div>
            ) : (
                <ComingSoon directUrl="/explore/all" />
            )}

            {loading || uploadingCollectionCoverImage && (
                <ReactLoading
                    className="collection-list-btn"
                    type="spinningBubbles"
                    color="#002979"
                />
            )}
            {!loading && !collectionData.nfts?.length && <NoData />}
        </>
    );

    return (
        <DefaultLayout>
            <Helmet>
                <meta charSet="utf-8" />
                <title>{`${MAIN_TITLE} - ${collectionData?.name}`}</title>
                <meta
                    name="description"
                    content={`${MAIN_TITLE} - ${collectionData?.name}`}
                />
            </Helmet>
            <div className="collection-detail-container">
                <div className="collection-banner">
                    <div className="collection-banner__img">
                        <img src={convertImageCover(cover)} alt="cover" />
                        {isEdit && (
                            <div className="edit-button">
                                {collectionData?.bannerUrl ? (
                                    <ButtonCustom onClick={onClickUpload}>
                                        {t("collection.editCover")}
                                    </ButtonCustom>
                                ) : (
                                    <ButtonCustom
                                        color="white"
                                        iconLeft={
                                            <SvgIcon
                                                className={styles.addIcon}
                                                name="add-icon"
                                            ></SvgIcon>
                                        }
                                        onClick={onClickUpload}
                                    >
                                        {t("collection.addCover")}
                                    </ButtonCustom>
                                )}
                            </div>
                        )}
                    </div>
                    <div className="collection-banner__profile">
                        <div className="collection-banner__profile__info">
                            {_renderCollectionAvatar()}
                            {_renderCollectionInfo()}
                        </div>
                        <div className="collection-banner__profile__tabs">
                            {_renderCollectionTabs()}
                            {_renderCollectionButtons()}
                        </div>
                    </div>
                    <input
                        type="file"
                        ref={uploadAvatarCollection}
                        onChange={onAvatarChange}
                        hidden
                    />
                    <input
                        type="file"
                        id="actual-btn"
                        ref={uploadFileBtn}
                        onChange={onCoverChange}
                        hidden
                    />
                </div>
                <Modal
                    className="profile-updating-cover-modal"
                    centered
                    footer={null}
                    open={uploadingCollectionCoverImage}
                >
                    <div className="profile-modal-wrapper">
                        <span className="title">
                            {isUploadCover
                                ? t("collection.updateCover")
                                : t("collection.updateAvatar")}
                        </span>
                        <span className="description">{t("collection.uploading")}</span>
                    </div>
                </Modal>
            </div>

            {activeTab === TABS.ITEMS && _renderItemsPanel()}
            {activeTab === TABS.OFFERS && <ComingSoon directUrl="/explore/all" />}
            {activeTab === TABS.ANALYTICS && <ComingSoon directUrl="/explore/all" />}
            {activeTab === TABS.ACTIVITY && <ComingSoon directUrl="/explore/all" />}

            {/** */}
            {/* {!err && isCollectionExist ? (
                <>
                    <div className={styles.header}>
                        <img src={convertImageCover(cover)} alt="cover" className={styles.cover} />
                        <div className={`${styles.avatarWrapper} avatar-collection`}>
                            <Avatar
                                size={160}
                                src={convertImage(avatarUrl, avatarUrl ? false : true)}
                                className={styles.avatar}
                            />
                            {collectionData?.isVerify ? <CustomVerifyIcon className={styles.verify} /> : null}
                            {isEdit && (
                                <div onClick={onClickUploadAvatar} className="btn-edit-avatar-collection">
                                    <i className="ri-edit-line"></i>
                                </div>
                            )}
                        </div>
                        {isEdit && (
                            <div className={styles.editButton}>
                                {collectionData?.bannerUrl ? (
                                    <ButtonCustom onClick={onClickUpload}>{t('collection.editCover')}</ButtonCustom>
                                ) : (
                                    <ButtonCustom
                                        color="white"
                                        iconLeft={<SvgIcon className={styles.addIcon} name="add-icon"></SvgIcon>}
                                        onClick={onClickUpload}
                                    >
                                        {t('collection.addCover')}
                                    </ButtonCustom>
                                )}
                            </div>
                        )}
                        <input type="file" ref={uploadAvatarCollection} onChange={onAvatarChange} hidden />
                        <input type="file" id="actual-btn" ref={uploadFileBtn} onChange={onCoverChange} hidden />
                    </div>
                    <Modal
                        className="profile-updating-cover-modal"
                        centered
                        footer={null}
                        open={uploadingCollectionCoverImage}
                    >
                        <div className="profile-modal-wrapper">
                            <span className="title">
                                {isUploadCover ? t('collection.updateCover') : t('collection.updateAvatar')}
                            </span>
                            <span className="description">{t('collection.uploading')}</span>
                        </div>
                    </Modal>
                    <div className={`${styles.content} ${theme === 'dark-theme' ? styles.darkContent : ''}`}>
                        <div className={styles.name}>{collectionData?.name}</div>

                        <div className={styles.addressHolder}>
                            {isEditShortUrl ? (
                                <InputCustom
                                    type='textarea'
                                    classNames={['description-input short-url-input']}
                                    value={valueShortUrl}
                                    autoSize={true}
                                    maxLength={50}
                                    onChange={handleChangeShortUrl}
                                />
                            ) : (
                                <TooltipCustom
                                    title={collectionData?.shortUrl ? collectionData?.shortUrl : t('common.noShortUrl')}
                                >
                                    <span className={styles.tag}>
                                        {collectionData?.shortUrl
                                            ? `@${shortenNameNoti(collectionData?.shortUrl, 22)}`
                                            : t('common.noShortUrl')}
                                        {isEdit && (
                                            <i
                                                className={`ri-edit-box-line ${styles.editShortUrlIcon}`}
                                                onClick={handleClickEditShortUrl}
                                            />
                                        )}
                                    </span>
                                </TooltipCustom>
                            )}
                            {isEditShortUrl && isEdit && (
                                <div className="action-edit">
                                    <ButtonCustom
                                        size="small"
                                        color="blue"
                                        className="edit-description-button"
                                        onClick={() => handleSaveClick(true)}
                                        disabled={loading}
                                    >
                                        {t('common.save')}
                                    </ButtonCustom>
                                    <ButtonCustom
                                        size="small"
                                        color="white"
                                        className="edit-description-button"
                                        onClick={() => setIsEditShortUrl(false)}
                                        disabled={loading}
                                    >
                                        {t('common.cancel')}
                                    </ButtonCustom>
                                </div>
                            )}
                            {!isEditShortUrl && (
                                <div className={styles.address}>
                                    <ButtonCustom
                                        color="gray"
                                        size="small"
                                        onClick={copyAddressToClipboard}
                                        className="address-button"
                                    >
                                        <WalletAddress
                                            address={collectionData?.address || ''}
                                            className={`${copyAddress ? 'content' : ''}`}
                                        />
                                    </ButtonCustom>
                                </div>
                            )}
                            {collectionData?.creator ? (
                                <div
                                    className={`collection-creator ${theme === 'dark-theme' ? 'dark-collection-creator' : ''
                                        }`}
                                >
                                    <span>by </span>
                                    {collectionData?.creator?.avatarImg ? (
                                        <CustomAvatar
                                            className="user-avatar"
                                            src={collectionData?.creator?.avatarImg}
                                            verified={collectionData?.creator?.isVerify}
                                            size={30}
                                        />
                                    ) : (
                                        <CustomAvatar
                                            className="user-avatar"
                                            size={30}
                                            verified={collectionData?.creator?.isVerify}
                                        />
                                    )}
                                    <p
                                        className="collection-creator-name"
                                        onClick={() =>
                                            history.push(
                                                collectionData?.creator?.shortUrl
                                                    ? `/${collectionData?.creator?.shortUrl}`
                                                    : `/user/${collectionData?.creator?.walletAddress}`
                                            )
                                        }
                                    >
                                        {collectionData?.creator?.username ? (
                                            collectionData?.creator?.username
                                        ) : (
                                            <WalletAddress
                                                address={collectionData?.creator?.walletAddress || ''}
                                                onClick={() =>
                                                    history.push(
                                                        collectionData?.creator?.shortUrl
                                                            ? `/${collectionData?.creator?.shortUrl}`
                                                            : `/user/${collectionData?.creator?.walletAddress}`
                                                    )
                                                }
                                            />
                                        )}
                                    </p>
                                </div>
                            ) : null}
                        </div>

                        <div className={styles.socialLink}>
                            {collectionData?.twitterLink ? (
                                <Tooltip title={collectionData?.twitterLink}>
                                    <span className={styles.tag}>
                                        <a
                                            href={`${SOCIAL_LINK.TWITTER}/${collectionData?.twitterLink}`}
                                            target="_blank'"
                                            rel="noopener"
                                            className={`twitter-link ${collectionData.isVerifyTwitter ? '' : 'unconfirmed-twitter-link'
                                                }`}
                                        >
                                            <i className="ri-twitter-fill" />
                                            {collectionData?.twitterLink}
                                        </a>
                                    </span>
                                </Tooltip>
                            ) : (isEdit && (
                                    <span
                                        className={`no-twitter-text ${theme === 'dark-theme' && 'no-twitter-text-dark'
                                            }`}
                                    >
                                        No Link For Twitter
                                    </span>
                                )
                            )}
                        </div>
                        {collectionProperties?.length > 1 && (
                            <div className={styles.collectionProperties}>
                                {collectionProperties.map((property, index) => {
                                    return (
                                        <div key={index} className={styles.propertyContent}>
                                            <div>
                                                <div className={styles.propertyName}>{property?.propertyName}</div>
                                                <PropRender property={property} />
                                            </div>
                                        </div>
                                    )
                                })}
                            </div>
                        )}
                        {isEditDescription ? (
                            <InputCustom
                                type='textarea'
                                classNames={['description-input']}
                                value={description}
                                autoSize={true}
                                onChange={handleInputDescription}
                                maxLength={MAX_DESCRIPTION_LENGTH}
                            />
                        ) : (
                            <div>
                                {description ? (
                                    <div className={`${styles.desc} desc`}>
                                        {description?.length > 240 ? (
                                            <div className="desc-content">
                                                {showMore ? (
                                                    description?.substring(0, 240) + '...'
                                                ) : (
                                                    <p className="desc-text">{description}</p>
                                                )}
                                                <span className={styles.expandBtn} onClick={toggleShowMore}>
                                                    {showMore ? t('collection.readMore') : t('collection.showLess')}
                                                </span>
                                            </div>
                                        ) : (
                                            <p className="desc-text">{description}</p>
                                        )}
                                        {isEdit && (
                                            <i
                                                className="ri-edit-box-line edit-description-icon"
                                                onClick={handleClickEditDescription}
                                            />
                                        )}
                                    </div>
                                ) : (
                                    <div className={`${styles.desc} desc`}>
                                        <p className="desc-text">{t('collection.noDescription')}</p>
                                        {isEdit && (
                                            <i
                                                className="ri-edit-box-line edit-description-icon"
                                                onClick={handleClickEditDescription}
                                            />
                                        )}
                                    </div>
                                )}
                            </div>
                        )}
                        {isEditDescription && isEdit && (
                            <div className={`action-edit ${theme === 'dark-theme' ? 'dark-action-edit' : ''}`}>
                                <ButtonCustom
                                    size="small"
                                    color="blue"
                                    className="edit-description-button"
                                    onClick={() => handleSaveClick(false)}
                                    disabled={loading}
                                >
                                    {t('common.save')}
                                </ButtonCustom>
                                <ButtonCustom
                                    size="small"
                                    color="white"
                                    className="edit-description-button cancel-edit-button"
                                    onClick={() => setIsEditDescription(false)}
                                    disabled={loading}
                                >
                                    {t('common.cancel')}
                                </ButtonCustom>
                            </div>
                        )}
                        <div className="button-holder">
                            {socialChannels?.length > 0 &&
                                socialChannels?.map(item => (
                                    <div className="icon-container" key={item?.id}>
                                        <Tooltip title={item?.url}>
                                            <div>
                                                <a
                                                    href={`${item?.name === 'Personal Site'
                                                        ? item?.url
                                                        : `${renderLinkSocial(item.name)}/${item.url}`
                                                        }`}
                                                    target="_blank'"
                                                    rel="noopener"
                                                >
                                                    {renderIconSocial(item.name)}
                                                </a>
                                            </div>
                                        </Tooltip>
                                    </div>
                                ))}
                            <div className="icon-container">
                                <ShareCustom
                                    content={`Look what I found! ${collectionData?.name} Collection ${window.location.href}  #ethereum #nonfungible  #nft`}
                                    url={window.location.href}
                                />
                            </div>
                        </div>
                        {isEdit && (
                            <ButtonCustom
                                onClick={handleClickEditSocialLinks}
                                className="ant-btn ant-btn-default ant-btn-custom ant-btn-color-blue btn-create ant-btn-small"
                            >
                                {t('collection.editSocialLinks')}
                            </ButtonCustom>
                        )}
                    </div>
                    <div className={`container ${theme === 'dark-theme' ? 'dark-container' : ''}`}>
                        <div className={styles.filter}>
                            <div className={styles.leftFilter}>
                                <SelectCustom
                                    value={categoryId}
                                    onChange={handleChangeCategory}
                                    options={categoriesConverted}
                                    className={`all-categories-button ${styles.select} ${styles.select_filter} select-home`}
                                    categoryIcon={true}
                                />
                                <div className={`search ${styles.searchBox}`}>
                                    <InputCustom
                                        className={`search-box ${styles.search}`}
                                        placeholder={t('common.search')}
                                        value={keyword}
                                        onPressEnter={handleSearchMobile}
                                        onChange={handleChangeSearch}
                                    />
                                    <img
                                        onClick={handleSearchMobile}
                                        className={styles.searchIcon}
                                        src={theme === 'dark-theme' ? DarkSearchIcon : LightSearchIcon}
                                        alt=""
                                    />
                                </div>
                            </div>
                            <div className={styles.rightFilter}>
                                <Dropdown overlay={dropdownProperties} open={isOpenPropertiesModal}>
                                    <Button
                                        onClick={() => setIsOpenPropertiesModal(!isOpenPropertiesModal)}
                                        className={`btn-filter properties-btn ${styles.btnFilter}  ${theme === 'dark-theme' ? 'btn-filter-custom-dark' : ''
                                            }`}
                                    >
                                        {t('explore.properties')}
                                        <i className="ri-arrow-down-s-line icon"></i>
                                    </Button>
                                </Dropdown>
                                <div className="header-sort-by">
                                    <Dropdown trigger={['click']} overlay={dropDownSortBy}>
                                        <Button
                                            className={`btn-filter sort-filter-btn btn-filter-custom  ${theme === 'dark-theme' ? 'btn-filter-custom-dark' : ''
                                                }`}
                                        >
                                            {t('common.sortAndFilter.title')}
                                            <img src={ArrowDown} alt="ArrowDown" />
                                        </Button>
                                    </Dropdown>
                                </div>
                            </div>
                        </div>
                        <Row gutter={[{ xs: 6, sm: 6, md: 20, lg: 20, xl: 20 }, 20]} className={styles.itemList}>
                            {collectionData.nfts?.map(item => (
                                <Col key={item.id} xxl={6} xl={6} lg={8} md={12} sm={12} xs={24}>
                                    <CardItem
                                        verification={collectionData?.isVerify}
                                        className={styles.cardItem}
                                        data={item}
                                    />
                                </Col>
                            ))}
                            <div ref={anchorEl}></div>
                        </Row>

                        {loading && (
                            <ReactLoading className="collection-list-btn" type="spinningBubbles" color="#002979" />
                        )}
                    </div>
                </>
            ) : (
                <div className="not-found-container">
                    <h2 className="not-found-title">{t('pageNotFound')}</h2>
                    <p className="not-found-description">{t('pageNotFound_description')}</p>
                    <ButtonCustom onClick={() => history.goBack()} color="blue">
                        {t('common.goBack')}
                    </ButtonCustom>
                </div>
            )} */}

            {/** */}

            <EditSocialLinkModal
                visible={isOpenEditSocialLinkModal}
                onCloseEditSocialLinkModal={() => setIsOpenEditSocialLinkModal(false)}
                collectionData={collectionData}
                reloadCollection={() => {
                    getCollectionDetail({ shortUrl, isReload: true });
                }}
            />
        </DefaultLayout>
    );
};

export default CollectionDetail;
