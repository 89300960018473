import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'

import './styles.scss'

const SvgIcon = ({ name, className }) => {
    const [icon, setIcon] = useState(null)

    useEffect(() => {
        const getIcon = async () => {
            const defaultIcon = await import(`assets/icons/${name}.svg`)
            setIcon(defaultIcon.default)
        }
        if (!name) return
        getIcon()
    }, [name])

    if (!icon) {
        return null
    }

    return (
        <>
            <img className={`art-icon ${className}`} alt="icon" src={icon} />
        </>
    )
}

SvgIcon.propTypes = {
    name: PropTypes.string.isRequired,
    className: PropTypes.string
}

export default SvgIcon
