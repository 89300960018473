import React, { useState, useEffect, useMemo } from 'react'
import { Modal, Checkbox, Row } from 'antd'
import { useWindowSize } from 'react-use'
import { useTranslation } from 'react-i18next'
import BigNumber from 'bignumber.js'
import { useChainId } from 'wagmi'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'

import { InputCustom, ButtonCustom } from 'components/common'
import { convertBigNumberValueToNumber } from 'blockchain/ether'
import * as message from 'utils/custom-message'
import { buildDataBid, getERC20AmountBalance, handleUserApproveERC20, isUserApprovedERC20 } from 'blockchain/utils'
import { ERROR_CODE_USER_DENIED_METAMASK, DECIMAL_TOKENS } from 'constants/index'
import auctionService from 'service/auctionService'
import { CONTRACT_ADDRESS } from 'constants/envs'
import './style.scss'

const MAX_VALUE = 100000000000000

const BidModal = props => {
    const { isOpen, onCloseModal, minimumBid, previousBid, openBidSuccessModal, exchangeRate } = props
    const { t } = useTranslation()
    const { walletAddress } = useSelector(state => state.user?.profile)
    const { MARKET_FEE } = useSelector(state => state.system)
    const { width } = useWindowSize()
    const chainId = useChainId();

    const { collection, nftSales, tokenId, id } = useSelector(state => state?.collectible.data)
    const [isCheckTerm, setIsCheckTerm] = useState(false)
    const [isPlacingBid, setIsPlacingBid] = useState(false)
    const [priceErr, setPriceErr] = useState('')
    const [bidPrice, setBidPrice] = useState(
        parseFloat((Number(previousBid?.price) * 1.05 || minimumBid * 1.05).toFixed(6))
    )
    const [currentBalance, setCurrentBalance] = useState()

    const onChangeBidPrice = (value, name, maxValue = MAX_VALUE, decimal = 6) => {
        if (value === '.') return setBidPrice()
        let number = value
            .toString()
            .replace(/[^0-9.]/g, '')
            .replace(/(\..*?)\..*/g, '$1')
        if (Number(number) >= maxValue) {
            number = number.slice(0, -1)
        }
        if (number.includes('.')) {
            const numString = number.toString().split('.')
            if (numString[1].length > decimal) {
                return setBidPrice(number.substring(0, number.length - 1))
            }
        }
        return setBidPrice(number)
    }

    const nftSale = useMemo(() => {
        if (nftSales && nftSales[0]) return nftSales[0]
    }, [nftSales])

    const handleUserApproveTokenSC = async tokenType => {
        const isApproved = await isUserApprovedERC20(
            CONTRACT_ADDRESS[collection?.networkType].proxy,
            CONTRACT_ADDRESS[collection?.networkType][tokenType],
            walletAddress,
            chainId,
            collection?.networkType
        )

        if (!isApproved) {
            const [, error] = await handleUserApproveERC20(
                CONTRACT_ADDRESS[collection?.networkType].proxy,
                CONTRACT_ADDRESS[collection?.networkType][tokenType],
                chainId,
                collection?.networkType,
            )
            if (error) {
                onCloseModal()
                message.error(t('error.message.declinedActionWallet'))
                return false
            }
        }
        return true
    }

    const onPlaceBid = async () => {
        setIsPlacingBid(true)

        const paramForBidder = {
            collectionAddress: collection.address,
            tokenType: nftSale?.currencyToken,
            nftType: collection.type,
            price: bidPrice,
            expirationTime: '0',
            quantity: 1,
            tokenId: tokenId,
            networkType: collection.networkType,
            isExternalCollection: collection.isImport
        }
        const isApproveTokenProcess = await handleUserApproveTokenSC(nftSale?.currencyToken)
        if (!isApproveTokenProcess) return

        const [dataBuildParams, errorBuildParams] = await buildDataBid(paramForBidder, chainId)

        if (errorBuildParams) {
            onCloseModal()
            if (errorBuildParams.code === ERROR_CODE_USER_DENIED_METAMASK) {
                return message.error(t('error.message.declinedActionWallet'))
            }

            return message.error(t('error.message.placeBidFailed', { err: errorBuildParams }))
        }

        const [, errorMetaData] = await auctionService.makeOffer({
            price: Number(bidPrice),
            metadata: dataBuildParams,
            currencyToken: nftSale?.currencyToken,
            quantity: 1,
            nftSaleId: nftSale.id,
            toAddress: nftSale.user.walletAddress,
            nftId: id
        })
        if (errorMetaData) {
            onCloseModal()
            return message.error(t('error.message.placeBidFailedReload'))
        }

        openBidSuccessModal()
    }

    const convertTokenType = useMemo(() => {
        if (nftSale) return nftSale?.currencyToken?.toUpperCase()
    }, [nftSale])

    useEffect(() => {
        try {
            const getBalance = async () => {
                const [tokenBalance] = await getERC20AmountBalance(
                    CONTRACT_ADDRESS[collection?.networkType][nftSale?.currencyToken],
                    walletAddress,
                    chainId,
                    collection?.networkType
                )
                const tokenAmount =
                    tokenBalance &&
                    convertBigNumberValueToNumber(
                        tokenBalance,
                        DECIMAL_TOKENS[collection?.networkType][nftSale?.currencyToken]
                    )
                setCurrentBalance(tokenAmount)
            }

            getBalance()
        } catch (error) {
            console.error('error', error)
        }
    }, [walletAddress, chainId, collection?.networkType, nftSale?.currencyToken])

    useEffect(() => {
        const validateBidPrice = () => {
            if (bidPrice) {
                const totalPrice = parseFloat((bidPrice * (1 + MARKET_FEE)).toFixed(7))
                if (totalPrice > currentBalance) {
                    setPriceErr(t("error.message.notEnoughBalance"))
                } else if (bidPrice < parseFloat((Number(previousBid?.price) * 1.05).toFixed(6))) {
                    setPriceErr(t("error.message.mustBidMoreThanLastBid"))
                } else if (bidPrice < minimumBid) {
                    setPriceErr(t("error.message.mustBidMoreThanMinimumBid"))
                } else if (priceErr?.length > 0) {
                    setPriceErr('')
                }
            } else {
                setPriceErr('')
            }
        }
        validateBidPrice()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [bidPrice, currentBalance, minimumBid, previousBid])

    return (
        <Modal
            visible={isOpen}
            onCancel={isPlacingBid ? () => { } : onCloseModal}
            onOk={onCloseModal}
            footer={null}
            closable={false}
            className={'custom-bid-modal'}
        >
            <div className="bid-modal-container">
                <h3 className="title-modal">{t('nftDetail.placeABid')}</h3>
                <p className="previous-bid">
                    {t("error.message.mustBidAtLeast")}{' '}
                    {parseFloat((Number(previousBid?.price) * 1.05 || minimumBid * 1.05).toFixed(6))} {convertTokenType}
                </p>
                <p className="tip-bid">{t('nftDetail.bidCondition')}</p>

                <div className="input-form">
                    <div className="balance-amount">
                        <p>{t('nftDetail.bidAmount')}</p>
                        <p>{`${t('nftDetail.balance')}: ${currentBalance ? new BigNumber(
                            +currentBalance
                        ).toFormat() : ''} ${convertTokenType}`}</p>
                    </div>
                    <InputCustom
                        prefix={
                            <div className="bid-input-prefix">{width >= 576 && <span>{convertTokenType}</span>}</div>
                        }
                        value={bidPrice}
                        onChange={e => onChangeBidPrice(e.target.value)}
                    />
                </div>
                <Row align="center" justify="space-between" className="service-fee-row border-top">
                    <p className="light-text">{t("nftDetail.subtotal")}</p>
                    <div>
                        <p className="light-text">{`${BigNumber(bidPrice || 0)
                            .decimalPlaces(8)
                            .toFormat()
                            .toString()} ${convertTokenType}`}</p>
                        <p>{`($${BigNumber((bidPrice || 0) * exchangeRate)
                            .decimalPlaces(8)
                            .toFormat()
                            .toString()})`}</p>
                    </div>
                </Row>
                <Row align="center" justify="space-between" className="service-fee-row">
                    <p className="light-text">{t('nftDetail.serviceFee')}</p>
                    <div>
                        <p className="light-text">{`${BigNumber((bidPrice || 0) * MARKET_FEE)
                            .decimalPlaces(8)
                            .toFormat()
                            .toString()} ${convertTokenType}`}</p>
                        <p>{`($${BigNumber((bidPrice || 0) * MARKET_FEE * exchangeRate)
                            .decimalPlaces(8)
                            .toFormat()
                            .toString()})`}</p>
                    </div>
                </Row>
                <Row align="center" justify="space-between" className="total-fee-row">
                    <p className="bold-text">{t('nftDetail.total')}</p>
                    <div>
                        <p>{`${BigNumber((Number(bidPrice) || 0) + (bidPrice || 0) * MARKET_FEE)
                            .decimalPlaces(8)
                            .toFormat()
                            .toString()} ${convertTokenType}`}</p>
                        <p>{`($${BigNumber((bidPrice || 0) * (1 + MARKET_FEE) * exchangeRate)
                            .decimalPlaces(8)
                            .toFormat()
                            .toString()})`}</p>
                    </div>
                </Row>
                <div className="term-of-service">
                    <Checkbox checked={isCheckTerm} onChange={() => setIsCheckTerm(!isCheckTerm)} />
                    <p className="term-of-service-text">
                        <span>{t('nftDetail.agree')}</span>
                        <a href={`https://smapocke.com/TermsofUse`} target="_blank" rel="noreferrer">
                            {t(`nftDetail.termOfService`)}
                        </a>
                    </p>
                </div>
                <div className="bid-modal-actions">
                    <ButtonCustom
                        color="blue"
                        disabled={!bidPrice || !isCheckTerm || priceErr?.length !== 0}
                        loading={isPlacingBid}
                        onClick={onPlaceBid}
                        className="br-12"
                        isBuble
                    >
                        <span>{t('nftDetail.placeBid')}</span>
                    </ButtonCustom>
                </div>
                {currentBalance !== null && priceErr && <p className="error-balance">{priceErr}</p>}
            </div>
        </Modal>
    )
}

export default BidModal
