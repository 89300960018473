import {createAsyncThunk} from '@reduxjs/toolkit';

import userService from 'service/userService'
import collectionService from 'service/collectionService'
import {ERROR_CODE_USER_DENIED_METAMASK, IMAGE_TYPE_UPLOAD} from 'constants/index'
import nftService from 'service/nftService'
import {getAmountToken} from 'utils/index'
import {NETWORKS, NETWORK_ID_TYPE} from 'constants/envs'
import {getUserInfoApi} from './publicUserInfo'
import {setCloseLoadingConnectWallet} from 'store/login.slice'
import {closeTermOfServiceModal, openTermOfServiceModal, openRejectConnectModal} from 'store/modal.slice'
import {
  logoutWallet,
  setCloseUploadCover,
  setConfirmVerifiedFailure,
  setConfirmVerifiedLoading,
  setConfirmVerifiedSuccess,
  setLoadingLoginWallet,
  setLoginWalletFailure,
  setLoginWalletSuccess,
  setUploadAvatarFailure,
  setUploadAvatarLoading,
  setUploadAvatarSuccess,
  setUploadCollectionCoverLoading,
  setUploadCollectionCoverSuccess,
  setUploadCoverFailure,
  setUploadCoverLoading,
  setUploadCoverSuccess,
  setUserEditProfileFailure,
  setUserEditProfileLoading,
  setUserEditProfileSuccess,
  setUserGetProfileFailure,
  setUserGetProfileLoading,
  setUserGetProfileSuccess,
  setUserUpdateProfileState
} from 'store/user.slice'

export const loginWallet = createAsyncThunk(
  'user/loginWallet',
  async ({account, chainId}, thunkApi) => {
    const {dispatch} = thunkApi;
    dispatch(setLoadingLoginWallet());
    try {
      const tokenAmount = await getAmountToken(chainId, account);
      localStorage.setItem('wallet-address', account)

      const [profile, error] = await userService.getPublicUserInfo({walletAddress: account})

      if (profile) {
        localStorage.setItem('userId', profile?.id)
        dispatch(setLoginWalletSuccess({...tokenAmount}));
        dispatch(setUserGetProfileSuccess(profile));
        dispatch(setCloseLoadingConnectWallet());
        if (Number(chainId) === NETWORKS[NETWORK_ID_TYPE[chainId]].id) {
          dispatch(closeTermOfServiceModal())
        }

        return
      }

      if (error.response.status === 404) {
        dispatch(logoutWallet());
        dispatch(setCloseLoadingConnectWallet());

        if (Number(chainId) === NETWORKS[NETWORK_ID_TYPE[chainId]].id) {
          dispatch(openTermOfServiceModal())
        }
      }
    } catch (error) {
      if (error.code === ERROR_CODE_USER_DENIED_METAMASK) {
        dispatch(openRejectConnectModal())
      }
      dispatch(setLoginWalletFailure(error));
    }
  }
);

export const setUpdateProfileState = createAsyncThunk(
  'user/setUpdateProfileState',
  ({state}, thunkApi) => {
    const {dispatch} = thunkApi;
    dispatch(setUserUpdateProfileState(state));
  }
);

export const editUserProfile = createAsyncThunk(
  'user/editUserProfile',
  async (params, thunkApi) => {
    const {dispatch} = thunkApi;
    dispatch(setUserEditProfileLoading());
    const [profile, error] = await userService.editUserProfile(params)
    if (profile) {
      dispatch(setUserEditProfileSuccess(profile));
    } else if (error) {
      dispatch(setUserEditProfileFailure(error));
    }
  }
);

export const getUserData = createAsyncThunk(
  'user/getUserData',
  async (params, thunkApi) => {
    const {dispatch} = thunkApi;
    dispatch(setUserGetProfileLoading());
    const [profile, err] = await userService.getPublicUserInfo(params)
  
    if (profile) {
      dispatch(setUserGetProfileSuccess(profile));
    } else if (err) {
      dispatch(setUserGetProfileFailure(err));
    }
  }
);

export const confirmVerified = createAsyncThunk(
  'user/confirmVerified',
  async ({data}, thunkApi) => {
    const {dispatch} = thunkApi;
    dispatch(setConfirmVerifiedLoading());
    const [respone, err] = await userService.confirmVerified(data)
    if (respone) {
      dispatch(setConfirmVerifiedSuccess({
        ...respone,
        twitterUsername: data
      }));
    } else if (err) {
      dispatch(setConfirmVerifiedFailure(err));
    }
  }
);

export const updateUserAvatar = createAsyncThunk(
  'user/updateUserAvatar',
  async (params, thunkApi) => {
    const {dispatch} = thunkApi;
    dispatch(setUploadAvatarLoading());
    const [res, err] = await userService.getUploadAvatarUrl({
      ...params,
      type: IMAGE_TYPE_UPLOAD.USER_AVATAR
    })
    const [, errCover] = await nftService.putNftImage({
      imgFile: params?.imgFile,
      nftId: '',
      uploadUrl: res?.upload_url,
      previewImgId: ''
    })
    await userService.updateUserImage({avatarImg: res.path})
    if (errCover) {
      dispatch(setUploadAvatarFailure(err));
      return
    }
    dispatch(setUploadAvatarSuccess());
  }
);

export const updateUserCover = createAsyncThunk(
  'user/updateUserCover',
  async ({params, account}, thunkApi) => {
    const {dispatch} = thunkApi;
    dispatch(setUploadCoverLoading());
  
    const [res, err] = await userService.getUploadAvatarUrl({
      ...params,
      type: IMAGE_TYPE_UPLOAD.USER_COVER
    })
    const [, errCover] = await nftService.putNftImage({
      imgFile: params?.imgFile,
      nftId: '',
      uploadUrl: res?.upload_url,
      previewImgId: ''
    })
  
    await userService.updateUserImage({coverImg: res.path})
    if (errCover) {
      dispatch(setUploadCoverFailure(err));
      dispatch(setCloseUploadCover());
      return
    }
  
    dispatch(getUserInfoApi({credentials: account}))
    dispatch(setUploadCoverSuccess());
  }
);

export const closeUploadCover = createAsyncThunk(
  'user/closeUploadCover',
  ({state}, thunkApi) => {
    const {dispatch} = thunkApi;
    dispatch(setCloseUploadCover(state));
  }
);

export const updateCollectionCover = createAsyncThunk(
  'user/updateCollectionCover',
  async (params, thunkApi) => {
    const {dispatch} = thunkApi;
    dispatch(setUploadCollectionCoverLoading());
    const [res] = await collectionService.getPresignUrlCollectionCover(params)
    // TBD: need to update?
    await nftService.putNftImage({
      imgFile: params?.imgFile,
      nftId: '',
      collectionId: params?.collectionId,
      uploadUrl: res?.upload_url,
      previewImgId: ''
    })
  
    await collectionService.updateImageCollection({
      id: params.collectionId,
      bannerUrl: res?.path
    })
    dispatch(setUploadCollectionCoverSuccess());
  }
);

export const updateCollectionAvatar = createAsyncThunk(
  'user/updateCollectionAvatar',
  async (params, thunkApi) => {
    const {dispatch} = thunkApi;
    dispatch(setUploadCollectionCoverLoading());
    const [res] = await collectionService.getPresignUrlCollectionAvatar(params)
  
    await nftService.putNftImage({
      imgFile: params?.imgFile,
      nftId: '',
      collectionId: params?.collectionId,
      uploadUrl: res?.upload_url,
      previewImgId: ''
    })
    await collectionService.updateImageCollection({
      id: params.collectionId,
      thumbnailUrl: res?.path
    })
    dispatch(setUploadCollectionCoverSuccess());
  }
);
