import React from 'react'
import { Modal } from 'antd'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'

import { ButtonCustom } from 'components/common'
import './style.scss'

const CancelModal = ({ onConfirmCancelSale, onCloseModal, isCanceling }) => {
    const { t } = useTranslation()

    return (
        <Modal
            visible={true}
            onOk={!isCanceling && onCloseModal}
            onCancel={!isCanceling && onCloseModal}
            footer={null}
            centered
            closable={false}
            className="custom-process-modal"
        >
            <div className="cancel-sale">
                <p>{t('nftDetail.cancelSale')}</p>
                <p className="cancel-fixed-price-text">
                    {t("nftDetail.cancelSaleModal.desc")}
                </p>
                <ButtonCustom
                    disabled={isCanceling}
                    fullWidth={true}
                    color="blue"
                    onClick={onCloseModal}
                    className="btn-never"
                >
                    <span>{t("nftDetail.cancelSaleModal.never")}</span>
                </ButtonCustom>
                <ButtonCustom fullWidth={true} color="white" onClick={onConfirmCancelSale} loading={isCanceling}>
                    <span>{t("nftDetail.cancelSaleModal.cancelListing")}</span>
                </ButtonCustom>
            </div>
        </Modal>
    )
}

CancelModal.propTypes = {
    onConfirmCancelSale: PropTypes.func,
    onCloseModal: PropTypes.func,
}

export default CancelModal
