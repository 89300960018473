import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { Col, Row, Modal, Tooltip } from 'antd'

import LoadingIcon from 'assets/icons/loading-icon.svg'
import shrinkAddress from 'utils/shrinkAddress'

import { NETWORKS } from 'constants/envs'
import CustomVerifyIcon from 'components/common/custom-verify-icon'

const AcceptProcessModal = ({ hashTransaction, isDone, isOpen }) => {
    const { t } = useTranslation()
    const { name, collection } = useSelector(state => state.collectible?.data)

    return (
        <Modal visible={isOpen} footer={null} closable={false} keyboard={false} className={'custom-process-modal'}>
            <div className="process-modal-container">
                <p>{t(isDone ? 'nftDetail.acceptOfferProcessed' : 'nftDetail.acceptOfferProcessing')}</p>
                <p>
                    {t(isDone ? 'nftDetail.acceptOfferConfirmed' : 'nftDetail.acceptOfferConfirming', { name: name })}
                </p>
                <Col className="process-detail">
                    <Row align="center" justify="space-between">
                        <p>{t('nftDetail.status')}</p>
                        <p>{t('nftDetail.transactionHash')}</p>
                    </Row>
                    <Row align="center" justify="space-between">
                        <div>
                            {isDone ? (
                                <CustomVerifyIcon />
                            ) : (
                                <img alt="process" src={LoadingIcon} className="custom-loading-icon" />
                            )}
                            <p>{t(isDone ? 'nftDetail.processed' : 'nftDetail.processing')}</p>
                        </div>
                        <p>
                            {hashTransaction ? (
                                <Tooltip title={t('nftDetail.viewOnExplorer')}>
                                    <a
                                        target="_blank"
                                        rel="noreferrer"
                                        href={`${
                                            NETWORKS[collection.networkType].blockExplorerUrls[0]
                                        }/tx/${hashTransaction}`}
                                    >
                                        {shrinkAddress(hashTransaction)}
                                    </a>
                                </Tooltip>
                            ) : (
                                '...'
                            )}
                        </p>
                    </Row>
                </Col>
            </div>
        </Modal>
    )
}

export default AcceptProcessModal
