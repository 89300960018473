import { ThemeContext } from 'ThemeProvider'
import { Menu, Dropdown, Radio, Space } from 'antd'
import React, { useContext, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useOnClickOutside } from 'utils/hook'

const languages = [
    {
        value: 'en',
        label: 'English'
    },
    {
        value: 'jp',
        label: 'Japanese'
    },
    {
        value: 'cn',
        label: 'Chinese'
    }
]

const LanguageSwitcher = () => {
    const { i18n } = useTranslation()
    const [language, setLanguage] = useState(i18n.language)
    const [visible, setVisible] = useState(false)

    const multiLanguageDropdown = useRef(null)
    const buttonPropertyRef = useRef(null)

    const handlePropertyClickOutsideDropdown = () => {
        setVisible(false)
    }

    useOnClickOutside(multiLanguageDropdown, handlePropertyClickOutsideDropdown, buttonPropertyRef)

    const handleChangeLanguage = lang => {
        i18n.changeLanguage(lang);
        setLanguage(lang);
        localStorage.setItem('locale', lang)
        setVisible(false)
    }


    const dropdownMultiLanguage = () => {
        return (
            <div ref={multiLanguageDropdown} className='multi-lang-dropdown-menu'>
                <Radio.Group value={language} onChange={(event) => {
                    event.preventDefault();
                    handleChangeLanguage(event.target.value)
                }}>
                    <Space direction="vertical" align="start">
                        {languages.map((lang, index) => (
                            <Radio key={index} value={lang.value}>
                                {lang.label}
                            </Radio>
                            // <Menu.Item key={index} onClick={() => handleChangeLanguage(lang.value)}>
                            //     {lang.label}
                            // </Menu.Item>
                        ))}
                    </Space>
                </Radio.Group>
            </div>

        )
    }

    useEffect(() => {
        const currentLanguage = localStorage.getItem('locale');
        if (currentLanguage) {
            handleChangeLanguage(currentLanguage)
        } else localStorage.setItem('locale', i18n.language)
    }, [])

    return (
        <Dropdown visible={visible} trigger={['click']} overlay={dropdownMultiLanguage} getPopupContainer={triggerNode => triggerNode.parentNode}>
            <div className="language-switcher cursor-pointer" onClick={() => setVisible(true)}>
                <i className="ri-global-line"></i>
            </div>
        </Dropdown>
    )

}

export default LanguageSwitcher;