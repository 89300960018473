import { Helmet } from 'react-helmet'
import React from 'react'
import { MAIN_TITLE } from 'constants/index'
import DefaultLayout from 'components/layout/default-layout'
import './styles.scss'
import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

const SnailTokenPage = () => {
    const { t } = useTranslation();
    const history = useHistory()

    const handleClickConnect = () => {
        history.push('/connect')
    }

    return (
        <DefaultLayout>
            <Helmet>
                <meta charSet="utf-8" />
                <title>{`${MAIN_TITLE} - SNAIL`}</title>
                <meta name="description" content={`${MAIN_TITLE} - SNAIL`} />
            </Helmet>
            <div className="terms-of-use-page static-page">
                <div className="container">
                    <div className="snail-holder">
                        <div className="text-holder">
                            <h1 className="title-page">{t('snailToken.title')}</h1>
                            <p className="content-snail" dangerouslySetInnerHTML={{ __html: t('snailToken.content.first') }}>
                            </p>
                            <p className="content-snail">
                                {t('snailToken.content.second')}
                            </p>
                            <p className="content-snail">
                                {t('snailToken.content.third')}
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </DefaultLayout>
    )
}

export default SnailTokenPage
