import {createSlice} from '@reduxjs/toolkit';

const initialState = {
  open: false,
  openModalWrongNetwork: false,
  openModalRejectSign: false,
  openUserClosedModal: false,
  openModalLockMetamask: false,
  openModalRejectConnect: false
}

export const modalSlice = createSlice({
  name: 'modal',
  initialState,
  reducers: {
    openTermOfServiceModal: (state) => {
      state.open = true;
    },
    closeTermOfServiceModal: (state) => {
      state.open = false;
    },
    openWrongNetworkModal: (state) => {
      state.openModalWrongNetwork = true;
    },
    closeWrongNetworkModal: (state) => {
      state.openModalWrongNetwork = false;
    },
    openRejectSignModal: (state) => {
      state.openModalRejectSign = true;
    },
    closeRejectSignModal: (state) => {
      state.openModalRejectSign = false;
    },
    openUserClosedModal: (state) => {
      state.openUserClosedModal = true;
    },
    closeUserClosedModal: (state) => {
      state.openUserClosedModal = false;
    },
    openLockMetamaskModal: (state) => {
      state.openModalLockMetamask = true;
    },
    closeLockMetamaskModal: (state) => {
      state.openModalLockMetamask = false;
    },
    openRejectConnectModal: (state) => {
      state.openModalRejectConnect = true;
    },
    closeRejectConnectModal: (state) => {
      state.openModalRejectConnect = false;
    },
  }
});

export const {closeLockMetamaskModal, closeRejectConnectModal, closeRejectSignModal, closeTermOfServiceModal, closeUserClosedModal, closeWrongNetworkModal, openLockMetamaskModal, openRejectConnectModal, openRejectSignModal, openTermOfServiceModal, openUserClosedModal, openWrongNetworkModal} = modalSlice.actions;

export default modalSlice.reducer;
