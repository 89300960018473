import {createSlice} from '@reduxjs/toolkit';

const initialState = {
  isUserExist: true
}

export const signupSlice = createSlice({
  name: 'signup',
  initialState,
  reducers: {
    toggleUserExist: (state) => {
      state.isUserExist = false;
    }
  }
});

export const {toggleUserExist} = signupSlice.actions;

export default signupSlice.reducer;
