import React, { useState, useEffect, useCallback, useContext } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { Tooltip, Row, message } from 'antd'
import copy from 'copy-to-clipboard'

import { shortenAddress } from 'utils/func'
import shrinkAddress from 'utils/shrinkAddress'
import { useResizeWindow } from 'utils/hook'
import { COLLECTIBLE_CODE, COLLECTIBLE_TYPE, NFT_TYPE, SCREEN_SIZE } from 'constants/index'
import { NETWORKS } from 'constants/envs'
// import nftService from 'service/nftService'
import './style.scss'
import { ThemeContext } from '../../../../../ThemeProvider'
// import { setGlobalLoading, setCloseGlobalLoading } from 'store/system.slice'

const Details = () => {
    const { theme } = useContext(ThemeContext)
    const { t } = useTranslation()
    const { owners, tokenId, collection } = useSelector(state => state.collectible?.data)
    const [copyText, setCopyText] = useState(tokenId)
    const [isLarge] = useResizeWindow(SCREEN_SIZE.xl)
    // const [nftProperties, setNftProperties] = useState([])
    // const dispatch = useDispatch()

    // const getUserNftProperties = useCallback(
    //     async data => {
    //         if (data?.collectionId && data?.tokenId) {
    //             dispatch(setGlobalLoading())
    //             const [propertiesRes] = await nftService.getNftProperties(data)
    //             dispatch(setCloseGlobalLoading())
    //             if (propertiesRes) {
    //                 return setNftProperties(propertiesRes)
    //             }
    //             message.error(t('error.message.somethingWentWrong'))
    //         }
    //     },
    //     [dispatch]
    // )
    // useEffect(() => {
    //     getUserNftProperties({ tokenId, collectionId: collection?.id })
    // }, [getUserNftProperties, tokenId, collection])

    const copyToClipboard = tokenId => {
        setCopyText('Copied!')
        copy(tokenId || '')
        setTimeout(() => {
            setCopyText(tokenId)
        }, 1500)
    }

    if (!owners) return null

    return (
        <div className={`details-tab__container ${theme === 'dark-theme' ? 'dark-details-tab__container' : ''}`}>
            <span>{t('nftDetail.details')}</span>
            {/* {nftProperties?.length > 0 && (
                <div className="details-tab__properties">
                    <p>{t('nftDetail.properties')}</p>
                    <Row>
                        {nftProperties?.map((item, index) => (
                            <div className="details-tab__property" key={index}>
                                <p>{item.name}</p>
                                <p style={{ color: `${theme === 'dark-theme' && 'white'}` }}>{item.value}</p>
                            </div>
                        ))}
                    </Row>
                </div>
            )} */}
            {tokenId && (
                <div className="contract-info">
                    <div className="d-flex">
                        <div className="contract-title">{t('nftDetail.contactAddress')}</div>
                        <Tooltip title={t('nftDetail.viewOnExplorer')} color="blue">
                            <a
                                className=""
                                target="_blank"
                                rel="noreferrer"
                                href={`${NETWORKS[collection.networkType].blockExplorerUrls[0]}/address/${collection?.address
                                    }`}
                            >
                                <div className="contract-value">
                                    {isLarge ? shortenAddress(collection?.address, 6) : collection?.address}
                                </div>
                            </a>
                        </Tooltip>
                    </div>
                    <div className="d-flex">
                        <div className="contract-title">{t('nftDetail.tokenId')}</div>
                        <Tooltip title={copyText}>
                            <div className="contract-value cursor-pointer" onClick={() => copyToClipboard(tokenId)}>
                                {tokenId.length >= 16 ? shrinkAddress(tokenId) : tokenId}
                            </div>
                        </Tooltip>
                    </div>
                    <div className="d-flex">
                        <div className="contract-title">{t('nftDetail.tokenStandard')}</div>
                        <div className="contract-value cursor-pointer">
                            {collection.type === COLLECTIBLE_CODE[COLLECTIBLE_TYPE.SINGLE]
                                ? NFT_TYPE.ERC721
                                : NFT_TYPE.ERC1155}
                        </div>
                    </div>
                    <div className="d-flex">
                        <div className="contract-title">{t('nftDetail.blockchain')}</div>
                        <Tooltip title={collection?.networkType}>
                            <div className="contract-value cursor-pointer">{collection?.networkType.toUpperCase()}</div>
                        </Tooltip>
                    </div>
                </div>
            )}
        </div>
    )
}

export default Details
