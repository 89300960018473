import { SERVER_API_ENDPOINT } from 'constants/envs'
import Request from '../request'

const systemConfigService = {
    getSystemConfig: async () => {
        try {
            const response = await Request.get(`${SERVER_API_ENDPOINT}/system-settings`)
            return [response.data, null]
        } catch (error) {
            return [null, error]
        }
    }
}

export default systemConfigService
