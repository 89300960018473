import React from 'react'
import { Form } from 'antd'
import { useEffect, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'

import BNBIcon from 'assets/icons/bnb-icon.png'
import ETHIcon from 'assets/icons/eth-icon-circle.png'
import MATICIcon from 'assets/icons/polygon-icon.png'
import Logo from 'assets/images/white-smart-pocket-logo.svg'
import { ButtonCustom, InputCustom } from 'components/common'
import {
    DISCORD_LINK,
    FACEBOOK_LINK,
    GITBOOK_LINK,
    INSTAGRAM_LINK,
    LINKEDIN_LINK,
    MEDIUM_LINK,
    TELEGRAM_LINK,
    TWITTER_LINK,
    YOUTUBE_LINK
} from 'constants/index'
// import { getPriceFooter } from 'service/getPrice'
import userService from 'service/userService'
import * as message from 'utils/custom-message'
import { roundNumber } from 'utils/func'
import './style.scss'
import { NFT_STATUS } from 'store/constants/nft'
import { useTranslation } from 'react-i18next'

export const listSocial = [
    {
        name: 'twitter',
        link: TWITTER_LINK,
        icon: 'ri-twitter-x-line'
    },
    {
        name: 'instagram',
        link: INSTAGRAM_LINK,
        icon: 'ri-instagram-line'
    },
    {
        name: 'discord',
        link: DISCORD_LINK,
        icon: 'ri-discord-line'
    },
]

const Footer = () => {
    const { t } = useTranslation();
    const { profile } = useSelector(state => state.user)
    const accountStore = useSelector(state => state?.user?.profile?.walletAddress)
    // const [prices, setPrices] = useState([])
    const [form] = Form.useForm()
    const categories = useSelector(state => state.categories?.data || [])

    const categoriesConverted = useMemo(() => {
        return [
            { label: t('category.allNFTs'), url: '/explore/all' },
            ...[...categories].map(category => {
                return {
                    label: t(`category.${category?.label}`),
                    url: `/explore/${category?.code}`
                }
            })
        ]
    }, [categories, t])

    const handleClickSubscribe = async () => {
        form.validateFields().then(async values => {
            const data = { ...values, active: 1 }
            const [res, error] = await userService.postReceiveEmailUpdate(data)
            if (res) {
                message.success(t('error.message.emailRegistered'))
                form.resetFields()
            }
            if (error) {
                message.error(t('error.message.alreadySubscribed'))
            }
        }).catch(() => { })
    }

    // useEffect(() => {
    //     const getPrices = async () => {
    //         const { data } = await getPriceFooter()

    //         const pricesConverted = []
    //         for (const key in data) {
    //             if (Object.hasOwnProperty.call(data, key)) {
    //                 switch (key) {
    //                     case 'binancecoin':
    //                         pricesConverted.push({
    //                             name: 'BNB',
    //                             price: data[key].usd,
    //                             logo: BNBIcon
    //                         })
    //                         break
    //                     case 'ethereum':
    //                         pricesConverted.push({
    //                             name: 'ETH',
    //                             price: data[key].usd,
    //                             logo: ETHIcon
    //                         })
    //                         break

    //                     case 'matic-network':
    //                         pricesConverted.push({
    //                             name: 'MATIC',
    //                             price: data[key].usd,
    //                             logo: MATICIcon
    //                         })
    //                         break

    //                     default:
    //                         break
    //                 }
    //             }
    //         }
    //         setPrices(pricesConverted.sort(() => -1))
    //     }

    //     getPrices()
    // }, [])

    return (
        <div className="footer">
            {/* <div className="footer-top">
                {prices.map((item, index) => (
                    <div className="currency-price" key={index}>
                        <img src={item.logo} className="coin-logo" alt="" />{' '}
                        <div className="price">
                            {item.name} <span className="value">{roundNumber(item.price)} $</span>
                        </div>
                        <a href="https://cex.io/buysell" target="_blank" rel="noreferrer" className="buy-now-link">
                            Buy
                        </a>
                    </div>
                ))}
            </div> */}
            <div className="container-box">
                <div className="container">
                    <div className="container_social-link">
                        <img className="footer-logo" src={Logo} alt="logo"></img>
                        <div className="links-container">
                            {listSocial.map((social, index) => {
                                return (
                                    <a href={social.link} rel="noreferrer" target="_blank" key={index}>
                                        <i className={social.icon}></i>
                                    </a>
                                )
                            })}
                        </div>
                        <div className="email-subscribe">
                            <div className="footer__text">{t('footer.getLatest')}</div>
                            <Form form={form} validateTrigger="onChange">
                                <Form.Item
                                    rules={[{ required: true, type: 'email', message: t('error.invalidEmail') }]}
                                    name="email"
                                >
                                    <InputCustom
                                        suffix={
                                            <ButtonCustom
                                                size="xs"
                                                className="btn"
                                                variant="gradient"
                                                onClick={handleClickSubscribe}
                                            >
                                                {t('footer.imIn')}
                                            </ButtonCustom>
                                        }
                                        variant="filled"
                                        className="input-subscribe"
                                    />
                                </Form.Item>
                            </Form>
                        </div>
                    </div>

                    <div className="container_page-link">
                        <div className="container_page-link-item">
                            <div className="item-title">{t('footer.marketplace')}</div>
                            <div className="item-link">
                                {categoriesConverted?.length > 0 &&
                                    categoriesConverted?.map((category, index) => (
                                        <Link key={index} to={category?.url}>
                                            {category?.label}
                                        </Link>
                                    ))}
                            </div>
                        </div>
                        <div className="container_page-link-item">
                            <div className="item-title">{t('footer.myAccount')}</div>
                            <div className="item-link">
                                <Link
                                    to={
                                        accountStore
                                            ? profile?.shortUrl
                                                ? `/user/${profile?.shortUrl}`
                                                : `/user/${accountStore}`
                                            : `/user/owner`
                                    }
                                >
                                    {t('footer.profile')}
                                </Link>
                                <Link
                                    to={{
                                        pathname: accountStore
                                            ? profile?.shortUrl
                                                ? `/user/${profile?.shortUrl}`
                                                : `/user/${accountStore}`
                                            : `/user/owner`,
                                        state: { activeTabSub: NFT_STATUS.FAVORITE }
                                    }}
                                >
                                    {t('footer.favourites')}
                                </Link>
                            </div>
                            <div className="item-title sub-title">{t('footer.stats')}</div>
                            <div className="item-link">
                                <Link to="/collections">{t('footer.ranking')}</Link>
                                <Link to="/activity">{t('footer.activity')}</Link>
                            </div>
                        </div>
                        <div className="container_page-link-item">
                            <div className="item-title">{t('footer.resources')}</div>
                            <div className="item-link">
                                <Link to="/help-center">{t('footer.help')}</Link>
                            </div>
                            <div className="item-link">
                                <a href="https://smapocke.com/contact" target="_blank" rel="noreferrer">{t('footer.contact')}</a>
                            </div>
                            <div className="item-link">
                                <a href="https://smapocke.com/SP" target="_blank" rel="noreferrer">{t('footer.snailToken')}</a>
                            </div>
                        </div>
                        <div className="container_page-link-item">
                            <div className="item-title">{t('footer.company')}</div>
                            <div className="item-link">
                                <a href="https://izana.world/" target="_blank" rel="noreferrer">{t('footer.about')}</a>
                            </div>
                        </div>
                    </div>
                    <div className="footer-bottom">
                        <p className="footer_copyright">{t('footer.copyright')}</p>
                        <div className="footer-bottom-link">
                            <a href={`https://smapocke.com/TermsofUse`} target="_blank" rel="noreferrer">
                                {t('footer.terms')}
                            </a>
                            <a href={'https://smapocke.com/PrivacyPolicy'} target="_blank" rel="noreferrer">
                                {t('footer.privacy')}
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Footer
