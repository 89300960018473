import React, { useState, useMemo, useContext, useEffect } from 'react'
import { Tooltip } from 'antd'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, Prompt } from 'react-router-dom'
import BigNumber from 'bignumber.js'
import { useWindowSize } from 'react-use'
import { useChainId } from 'wagmi'

import { ButtonCustom } from 'components/common'
import BuyModal from './BuyModal'
import CancelModal from '../CancelModal'
import ProcessModal from './ProcessModal'
import { ERROR_CODE_USER_DENIED_METAMASK, SOCKET_EVENTS, TOKEN_ICON } from 'constants/index'
import { handleCancelListingOrder } from 'blockchain/utils'
import { isUserAuthenticated } from 'utils/auth'
import * as message from 'utils/custom-message'
import { socket } from 'config'

import './style.scss'
import { ThemeContext } from '../../../../../ThemeProvider'
import { getNFTDetail } from 'store/actions/nft'

const FixedPrice = ({ isOwner, exchangeRate, signWallet, spExchangeRate = 1, bonusSP = 1000 }) => {
    const { theme } = useContext(ThemeContext)
    const { t } = useTranslation()
    const dispatch = useDispatch()
    const chainId = useChainId();
    const { width } = useWindowSize()
    const { nftSales, id, collection, tokenId } = useSelector(state => state.collectible.data)
    const { profile } = useSelector(state => state.user)
    const history = useHistory()
    const [isOpenCancelModal, setIsOpenCancelModal] = useState(false)
    const [isOpenBuyModal, setIsOpenBuyModal] = useState(false)
    const [isOpenProcessModal, setIsOpenProcessModal] = useState(false)
    const [transferData, setTransferData] = useState({})
    const [isDoneProcessing, setIsDoneProcessing] = useState(false)
    const [isCancelingSale, setIsCancelingSale] = useState(false)

    const nftSale = useMemo(() => {
        if (nftSales && nftSales[0]) {
            return nftSales[0]
        }
    }, [nftSales])

    const onClickCancelSale = async () => {
        if (!isUserAuthenticated()) {
            const res = await signWallet()
            if (res === false) return
        }
        setIsOpenCancelModal(true)
    }
    const onClickBuy = async () => {
        if (!profile?.id) {
            return history.push({ pathname: '/connect', state: { from: 'collectible', tokenId } })
        }
        if (!isUserAuthenticated()) {
            const res = await signWallet()
            if (!res) return
        }
        setIsOpenBuyModal(true)
    }

    const onConfirmCancelSale = async () => {
        setIsCancelingSale(true)

        const [, error] = await handleCancelListingOrder(nftSale.signatureSale, chainId)

        if (error) {
            setIsOpenCancelModal(false)
            setIsCancelingSale(false)
            if (error?.code === ERROR_CODE_USER_DENIED_METAMASK)
                return message.error(t('error.message.declinedActionWallet'))

            return message.error(t('error.message.buyerDoesNotHaveEnoughMoney'))
        }
    }

    const onOpenProcessModal = (hash, buyDataTarget) => {
        setIsOpenBuyModal(false)
        setTransferData({ hash, buyDataTarget })
        setIsOpenProcessModal(true)
    }

    useEffect(() => {
        if (socket) {
            socket.on(SOCKET_EVENTS.CANCEL_INTERNAL_SALE, res => {
                if (res?.data?.userId === profile?.id && res?.data?.nftId === id) {
                    setIsOpenCancelModal(false)
                    setIsCancelingSale(false)
                    dispatch(getNFTDetail({ collectionAddress: collection.id || collection.address, tokenId }))
                    message.success(t('success.message.cancelSuccessfully'))
                }
            })
        }
        return () => socket.off(SOCKET_EVENTS.CANCEL_INTERNAL_SALE)
    }, [profile?.id, id, tokenId, collection, dispatch])

    return (
        <div className={`bid ${theme === 'dark-theme' ? 'dark-bid' : ''}`}>
            {width > 768 && (
                <div>
                    <div className="bid-left">
                        <p className="text-fixed-price">{t('nftDetail.fixedPrice')}</p>
                        <div className="bid-info">
                            <Tooltip title={nftSale?.currencyToken.toUpperCase()} overlayClassName="custom-tooltip">
                                <img className="token-icon" src={TOKEN_ICON[nftSale?.currencyToken]} alt="Token Icon" />
                            </Tooltip>

                            <div className="price-info">
                                <p>{`${BigNumber(nftSale?.price * 1)
                                    .decimalPlaces(6)
                                    .toFormat()
                                    .toString()} ${nftSale?.currencyToken.toUpperCase()}`}</p>
                                <p>
                                    &nbsp;
                                    {/* {`/${BigNumber(spExchangeRate * nftSale?.price).decimalPlaces(6)
                                        .toFormat()
                                        .toString()} SP : $${BigNumber(exchangeRate * nftSale?.price)
                                            .decimalPlaces(6)
                                            .toFormat()
                                            .toString()}`} */}
                                    {`/$${BigNumber(exchangeRate * nftSale?.price)
                                            .decimalPlaces(6)
                                            .toFormat()
                                            .toString()}`}
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            )}
            {isOwner ? (
                <div className="action-buy">
                    <ButtonCustom
                        fullWidth
                        isBuble
                        color="white"
                        className="action-btn br-16"
                        onClick={onClickCancelSale}
                    >
                        {t('nftDetail.cancel')}
                    </ButtonCustom>
                </div>
            ) : (
                <div className="action-buy">
                    {/* <div className='buy-with-sp'>
                        <ButtonCustom color='blue' className='br-16'>{t('nftDetail.buyWith', { currency: 'SP' })}</ButtonCustom>
                        <span className="bonus-sp">+{bonusSP} SP</span>
                    </div> */}

                    <ButtonCustom
                        color="blue"
                        className="action-btn br-16"
                        onClick={onClickBuy}
                    >
                        {width > 768 ? (
                            t('nftDetail.buy')
                        ) : (
                            <p>
                                {t('nftDetail.buyFor')}{' '}
                                <span>{`${BigNumber(nftSale.price * 1)
                                    .decimalPlaces(6)
                                    .toFormat()
                                    .toString()} ${nftSale?.currencyToken.toUpperCase()}`}</span>{' '}
                            </p>
                        )}
                    </ButtonCustom>
                </div>
            )}
            <CancelModal
                isOpen={isOpenCancelModal}
                onConfirmCancelSale={onConfirmCancelSale}
                isCanceling={isCancelingSale}
                onCloseModal={() => setIsOpenCancelModal(false)}
            />
            <BuyModal
                isOpen={isOpenBuyModal}
                exchangeRate={exchangeRate}
                onCloseModal={() => setIsOpenBuyModal(false)}
                token={nftSale?.currencyToken.toUpperCase()}
                onOpenProcessModal={onOpenProcessModal}
                setIsDoneProcessing={value => setIsDoneProcessing(value)}
                onCloseProcessModal={() => setIsOpenProcessModal(false)}
            />
            <ProcessModal
                isOpen={isOpenProcessModal}
                onCloseModal={() => setIsOpenProcessModal(false)}
                hashTransaction={transferData?.hash}
                isDone={isDoneProcessing}
            />
            <Prompt when={isCancelingSale} message={t("nftDetail.areYouSureToLeave")} />
        </div>
    )
}

export default FixedPrice
