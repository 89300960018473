import React from 'react'
import { Tooltip } from 'antd'
import { useHistory } from 'react-router-dom'

import { getCustomDiffTime } from 'utils/convertDate'
import { roundNumber, shortenAddress, shortenNameNoti } from 'utils/func'
import NotificationNftImage from './NotificationNftImage'
import { useTranslation } from 'react-i18next'

const NotificationBids = ({ noty, onClickNoti }) => {
    const { t } = useTranslation();
    const history = useHistory()
    const nftHistory = noty?.nftHistory
    const routerCollection = () => {
        onClickNoti(noty?.id, () => {
            if (nftHistory?.nft?.collection.id && nftHistory?.nft?.tokenId) {
                history.push({
                    pathname: `/nft/${nftHistory?.nft?.collection?.id}:${nftHistory?.nft?.tokenId}`,
                    state: { bid: '4' }
                })
            }
        })
    }
    const routerUser = e => {
        e.stopPropagation()
        onClickNoti(noty?.id)
        nftHistory?.fromUser?.walletAddress && history.push(`/user/${nftHistory?.fromUser?.walletAddress}`)
    }
    return (
        <div onClick={routerCollection} className="cursor-pointer">
            <div className="d-flex">
                <div className="item-image my-auto">
                    <NotificationNftImage noty={nftHistory} />
                </div>
                <div className="my-auto">
                    <div className="my-auto">
                        <span className="text-highlight">
                            <Tooltip title={nftHistory?.nft?.title} placement="topLeft">
                                {nftHistory?.nft?.title ? shortenNameNoti(nftHistory?.nft?.title) : ''}{' '}
                            </Tooltip>
                        </span>
                        <span className="">{t('header.menu.offeredBy')}{' '}</span>
                        <Tooltip
                            title={nftHistory?.fromUser?.username || nftHistory?.fromUser?.walletAddress}
                            placement="topLeft"
                        >
                            <span className="text-highlight" onClick={routerUser}>
                                {nftHistory?.fromUser?.username
                                    ? shortenNameNoti(nftHistory?.fromUser?.username)
                                    : shortenAddress(nftHistory?.fromUser?.walletAddress, 4)}{' '}
                            </span>
                        </Tooltip>

                        <span>
                            {t('header.menu.for')}{' '}{roundNumber(noty?.nftHistory?.price) || 0}
                            {noty?.nftHistory?.currencyToken.toUpperCase()}
                        </span>
                    </div>
                    <div className="time-noti">{getCustomDiffTime(noty?.createdAt)}</div>
                </div>
            </div>
        </div>
    )
}

export default NotificationBids
